import * as React from 'react';
import {ReactNode} from 'react';
import {useTranslation} from "react-i18next";
import "./ProcessComplete.scss";
import Button, {BUTTON_TYPE} from "../../../components/Common/Button/Button";
import mail_sent from "../../../assets/Icons/mail_sent.svg";

export interface Props {
    title?: string
    children?: ReactNode
    description?: string | any
    buttonLabel?: string
    icon?: string
    onButtonClick?(): void
    onPreviousButtonClick?(): void
}

const ProcessComplete: React.FC<Props> = ({
                                              title,
                                              children,
                                              description,
                                              buttonLabel,
                                              icon,
                                              onButtonClick,
                                              onPreviousButtonClick
                                          }) => {
    const {t} = useTranslation();

    return (
        <div className="shadowCard processComplete">
            <div className="processComplete__content">
                <img
                    className="processComplete__img"
                    src={icon || mail_sent}
                    alt="email_sent"
                    width={"65px"}
                />
                <div className="processComplete__title">
                    {title || t("admin.processCompleted.title")}
                </div>
                {children ||
                <div className="processComplete__bottomSection">
                    <div className="processComplete__description">
                        {description || t("admin.processCompleted.description")}
                    </div>
                    <div className="processComplete__buttonContainer">
                        {onPreviousButtonClick &&
                        <div className="processComplete__button processComplete__button--back">
                            <Button
                                onClick={onPreviousButtonClick}
                                buttonType={BUTTON_TYPE.primaryInverted}
                                label={t("common.button.back")}
                            />
                        </div>}
                        {onButtonClick &&
                        <div className="processComplete__button">
                            <Button
                                onClick={onButtonClick}
                                label={buttonLabel || t("admin.processCompleted.buttonLabel")}
                            />
                        </div>
                        }
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default ProcessComplete;

