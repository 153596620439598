import * as React from 'react';
import OnBoardingCard, {menuItemsIndividual} from "../../../components/OnBoardingComponent/onBoardingCard/OnBoardingCard";
import {useTranslation} from "react-i18next";
import {AppState} from "../../../redux/store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect} from "react-redux";
import {OnBoardingStep, UpdateStepRequest} from "../../../model/UserDTO";
import {setCurrentOnboardingStep, setOnboardingData, setOnBoardingError, setOnBoardingStep} from "../../../redux/auth";
import i18n from "i18next";
import DocumentData from "../../common/documentData/DocumentData";

export const documentTypeList = () => [
    {key: "DRIVING_LICENSE", label: i18n.t("onBoarding.buyer.documentData.document.DRIVING_LICENSE")},
    {key: "ID_CARD", label: i18n.t("onBoarding.buyer.documentData.document.ID_CARD")},
    {key: "PASSPORT", label: i18n.t("onBoarding.buyer.documentData.document.PASSPORT")},
];


const OnBoardingDocumentData: React.FC<any> = ({
                                                   setCurrentOnboardingStep,
                                                   setOnBoardingStep,
                                                   setOnboardingData,
                                                   onBoardingData,
                                                   error,
                                                   setOnboardingError,
                                                   onConfirm,
                                                   percentage,
                                                   subtitle,
                                                   menuItems,
                                                   menuItemsActive
                                               }) => {

    const {t} = useTranslation();
    const [componentData, setComponentData] = React.useState(onBoardingData);
    const [formValid, setFormValid] = React.useState(false);
    const {documentType, documentNumber, documentEmitter, documentCityEmitter, documentProvinceEmitter, documentReleaseDate, documentExpiryDate, documentNationEmitter} = onBoardingData;
    const isItalian = componentData?.residenceCountry === "IT";

    React.useEffect(() => {
        if (!isItalian) {
            setComponentData({...onBoardingData, documentType: "PASSPORT"});
            setOnboardingData({documentType: "PASSPORT"});
        }
        // eslint-disable-next-line
    }, []);

    const updateComponentState = (inputData: {}) => {
        const updatedData = {...componentData, ...inputData};
        setComponentData(updatedData);
    };

    const onConfirmButtonClick = async () => {
        await setOnboardingData(componentData);
        if (!error) onConfirm?.() || setOnBoardingStep({onBoardingStep: OnBoardingStep[OnBoardingStep.STEP_TERMS_AND_CONDITIONS]} as UpdateStepRequest);
    };

    const onPreviousButtonClick = () => {
        setOnboardingError("");
        setCurrentOnboardingStep({onBoardingStep: OnBoardingStep.STEP_PERSONAL_DATA} as UpdateStepRequest);
    };

    React.useEffect(() => {
        error && setOnboardingError("");
        // eslint-disable-next-line
    }, [setOnboardingError, documentType, documentNumber, documentEmitter, documentCityEmitter, documentNationEmitter, documentProvinceEmitter, documentReleaseDate, documentExpiryDate]);


    return (
        <OnBoardingCard
            title={t("onBoarding.buyer.documentData.title")}
            subtitle={subtitle}
            onPreviousButtonClick={onPreviousButtonClick}
            onConfirmButtonClick={onConfirmButtonClick}
            description={t("onBoarding.buyer.documentData.description")}
            confirmButtonDisabled={!formValid || error}
            percentage={percentage || 40}
            error={error}
            menuItems={menuItems}
            activeMenuItem={(menuItemsActive || menuItemsActive === 0) ? menuItemsActive : menuItemsIndividual.personalData}
        >
            <DocumentData
                componentData={componentData}
                onBlur={setOnboardingData}
                onChange={(newData: any) => {
                    setOnboardingData(newData);
                    updateComponentState(newData);
                }}
                onLocalChange={updateComponentState}
                onFormValidChange={setFormValid}
            />
        </OnBoardingCard>
    );
};

const mapStateToProps = (store: AppState) => {
    return {
        error: store.auth.errorOnBoardingData,
        onBoardingData: store.auth.onBoardingData,
        language: store?.auth?.onBoardingData?.language
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        setOnboardingError: (payload: string) => dispatch(setOnBoardingError(payload)),
        setOnboardingData: (payload: {}) => dispatch(setOnboardingData(payload)),
        setOnBoardingStep: (payload: UpdateStepRequest) => dispatch(setOnBoardingStep(payload)),
        setCurrentOnboardingStep: (payload: UpdateStepRequest) => dispatch(setCurrentOnboardingStep(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnBoardingDocumentData);
