import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {errorExtractor, handleResponse} from "../reduxUtils";
import {userService} from "../../service/UserService";
import {GET_ALL_USERS, GET_ALL_USERS_FAIL, GET_ALL_USERS_SUCCESS, GET_USER_FILES_FAIL, GET_USER_FILES_REQUEST, GET_USER_FILES_SUCCESS, SAVE_PAID_INVOICES, SAVE_PAID_INVOICES_FAIL, SAVE_PAID_INVOICES_SUCCESS, SET_COMPANY_DATA, SET_COMPANY_DATA_FAIL, SET_COMPANY_DATA_SUCCESS, SET_TERMS_ACCEPTANCE, SET_TERMS_ACCEPTANCE_FAIL, SET_TERMS_ACCEPTANCE_SUCCESS, SET_USER_ERROR, SET_USER_FILES, SET_USER_FILES_FAIL, SET_USER_FILES_SUCCESS, SET_VERSION, UPDATE_USER_ROLE, UPDATE_USER_ROLE_FAIL, UPDATE_USER_ROLE_SUCCESS, USER_INFO, USER_INFO_FAIL, USER_INFO_SUCCESS, UserAction} from "./user-interface";
import {UpdateRoleRequestDTO, UserFilesDTORequest} from "../../model/UserDTO";
import {AuthAction} from "../auth/auth-interface";
import {companyService} from "../../service/CompanyService";
import {CompanyDetailsDTO} from "../../model/CompanyDTO";
import {buyerService} from "../../service/BuyerService";
import {SET_ONBOARDING_DATA} from "../auth/auth-reducers";

export const setCompanyData = (companyData: CompanyDetailsDTO, companyId: string): ThunkAction<void, {}, {}, UserAction> =>
    async (dispatch: ThunkDispatch<{}, {}, UserAction | AuthAction>) => {
        dispatch({type: SET_COMPANY_DATA, payload: null});
        companyData = {...companyData, vatNumber: companyId};
        const responsePromise = companyService.setCompanyDetails(companyId, companyData);
        const success = await handleResponse(dispatch, SET_COMPANY_DATA_SUCCESS, SET_COMPANY_DATA_FAIL, responsePromise, errorExtractor);
        success && dispatch({type: SET_ONBOARDING_DATA, data: companyData});
        return success;
    };

export const setTermsConditions = (): ThunkAction<void, {}, {}, UserAction> =>
    async (dispatch: ThunkDispatch<{}, {}, UserAction>) => {
        dispatch({type: SET_TERMS_ACCEPTANCE, payload: null});
        const responsePromise = userService.setTermsCondition();
        return await handleResponse(dispatch, SET_TERMS_ACCEPTANCE_SUCCESS, SET_TERMS_ACCEPTANCE_FAIL, responsePromise);
    };

export const setUserFilesData = (userDataFiles: UserFilesDTORequest): ThunkAction<void, {}, {}, UserAction> =>
    async (dispatch: ThunkDispatch<{}, {}, UserAction>) => {
        dispatch({type: SET_USER_FILES, payload: null});
        const responsePromise = userService.updateUserFiles(userDataFiles);
        return await handleResponse(dispatch, SET_USER_FILES_SUCCESS, SET_USER_FILES_FAIL, responsePromise, undefined, undefined, undefined, undefined, true);
    };

export enum UserFilesTypes {
    USER,
    LEGAL_REPRESENTATIVE,
    EFFECTIVE_OWNER
}

export const getUserFiles = (type: UserFilesTypes = UserFilesTypes.USER): ThunkAction<void, {}, {}, UserAction> =>
    async (dispatch: ThunkDispatch<{}, {}, UserAction>) => {
        dispatch({type: GET_USER_FILES_REQUEST, payload: null});
        let responsePromise = userService.getUserFiles();
        if (type === UserFilesTypes.LEGAL_REPRESENTATIVE) responsePromise = userService.getLegalRepresentativeFiles();
        return await handleResponse(dispatch, GET_USER_FILES_SUCCESS, GET_USER_FILES_FAIL, responsePromise);
    };

export const savePaidInvoices = (invoices: string[]): ThunkAction<void, {}, {}, UserAction> =>
    async (dispatch: ThunkDispatch<{}, {}, UserAction>) => {
        dispatch({type: SAVE_PAID_INVOICES, payload: null});
        const responsePromise = buyerService.savePaidInvoices(invoices);
        return await handleResponse(dispatch, SAVE_PAID_INVOICES_SUCCESS, SAVE_PAID_INVOICES_FAIL, responsePromise);
    };

export const userInfo = (): ThunkAction<void, {}, {}, UserAction> =>
    async (dispatch: ThunkDispatch<{}, {}, UserAction>) => {
        dispatch({type: USER_INFO, payload: false});
        const responsePromise = userService.userInfo();
        return await handleResponse(dispatch, USER_INFO_SUCCESS, USER_INFO_FAIL, responsePromise);
    };

export const getVersion = (): ThunkAction<void, {}, {}, UserAction> =>
    async (dispatch: ThunkDispatch<{}, {}, UserAction>) => {
        const responsePromise = userService.getVersion();
        return await handleResponse(dispatch, SET_VERSION, "null", responsePromise);
    };

export const setUserError = (payload: string) => (dispatch: ThunkDispatch<{}, {}, UserAction>) => {
    dispatch({type: SET_USER_ERROR, payload});
};

export const getAllUsers = (): ThunkAction<void, {}, {}, UserAction> =>
    async (dispatch: ThunkDispatch<{}, {}, UserAction>): Promise<UserAction> => {
        dispatch({type: GET_ALL_USERS, payload: null});
        const responsePromise = userService.getAllUsers();
        return await handleResponse(dispatch, GET_ALL_USERS_SUCCESS, GET_ALL_USERS_FAIL, responsePromise);
    };

export const updateUserRole = (data: UpdateRoleRequestDTO): ThunkAction<void, {}, {}, UserAction> =>
    async (dispatch: ThunkDispatch<{}, {}, UserAction>): Promise<UserAction> => {
        dispatch({type: UPDATE_USER_ROLE, payload: null});
        const responsePromise = userService.updateUserRole(data);
        return await handleResponse(dispatch, UPDATE_USER_ROLE_SUCCESS, UPDATE_USER_ROLE_FAIL, responsePromise);
    };
