import {InfoCertAction} from "./infocert-interface";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {
    ACTIONS_FAIL,
    ACTIONS_REQUEST,
    ACTIONS_SILENT_FAIL,
    ACTIONS_SILENT_REQUEST,
    ACTIONS_SILENT_SUCCESS,
    ACTIONS_SUCCESS,
    ADD_DOCUMENTS_FAIL,
    ADD_DOCUMENTS_REQUEST,
    ADD_DOCUMENTS_SUCCESS,
    ADD_TO_CHAT_HISTORY,
    ADMIN_ARCHIVE_FAIL,
    ADMIN_ARCHIVE_REQUEST,
    ADMIN_ARCHIVE_SUCCESS,
    ARCHIVE_FAIL,
    ARCHIVE_REQUEST,
    ARCHIVE_SUCCESS,
    AUTHTOKEN_FAIL,
    AUTHTOKEN_REQUEST,
    AUTHTOKEN_SUCCESS,
    CLEAR_ARCHIVE,
    CLEAR_FAIL_ERROR,
    COMBINATIONS_FAIL,
    COMBINATIONS_REQUEST,
    COMBINATIONS_SUCCESS,
    CREATE_DOSSIER_FAIL,
    CREATE_DOSSIER_REQUEST,
    CREATE_DOSSIER_SUCCESS,
    DELEGATED_COMBINATIONS_FAIL,
    DELEGATED_COMBINATIONS_REQUEST,
    DELEGATED_COMBINATIONS_SUCCESS,
    DELETE_INFO_CERT_DOSSIER_FAIL,
    DELETE_INFO_CERT_DOSSIER_REQUEST,
    DELETE_INFO_CERT_DOSSIER_SUCCESS,
    GET_NEW_TOKEN_FAIL,
    GET_NEW_TOKEN_REQUEST,
    GET_NEW_TOKEN_SUCCESS,
    INFOCERT_PROCESS_STATUS_FAIL,
    INFOCERT_PROCESS_STATUS_REQUEST,
    INFOCERT_PROCESS_STATUS_SUCCESS,
    LOGOUT_INFO_CERT_FAIL,
    LOGOUT_INFO_CERT_REQUEST,
    LOGOUT_INFO_CERT_SUCCESS,
    ONE_SHOT_ISSUE_SIGN_FAIL,
    ONE_SHOT_ISSUE_SIGN_REQUEST,
    ONE_SHOT_ISSUE_SIGN_SUCCESS,
    ONE_SHOT_REGISTER_FAIL,
    ONE_SHOT_REGISTER_REQUEST,
    ONE_SHOT_REGISTER_SUCCESS,
    ONE_SHOT_SEND_OTP_FAIL,
    ONE_SHOT_SEND_OTP_REQUEST,
    ONE_SHOT_SEND_OTP_SUCCESS,
    ONE_SHOT_START_FAIL,
    ONE_SHOT_START_REQUEST,
    ONE_SHOT_START_SUCCESS,
    PROCESS_START_FAIL,
    PROCESS_START_REQUEST,
    PROCESS_START_SUCCESS,
    REMOTE_SIGN_PREPARE_SIGNATURE_FAIL,
    REMOTE_SIGN_PREPARE_SIGNATURE_REQUEST,
    REMOTE_SIGN_PREPARE_SIGNATURE_SUCCESS,
    REMOTE_SIGN_SEND_OTP_FAIL,
    REMOTE_SIGN_SEND_OTP_REQUEST,
    REMOTE_SIGN_SEND_OTP_SUCCESS,
    REMOTE_SIGN_SIGN_CONTRACTS_FAIL,
    REMOTE_SIGN_SIGN_CONTRACTS_REQUEST,
    REMOTE_SIGN_SIGN_CONTRACTS_SUCCESS,
    REMOTE_SIGN_START_FAIL,
    REMOTE_SIGN_START_REQUEST,
    REMOTE_SIGN_START_SUCCESS,
    SET_CHAT_HISTORY,
    SET_CURRENT_DOCUMENT_SIGN_STEP,
    SET_INFO_CERT_DOSSIER_TOKEN,
    SET_INFO_CERT_ERROR,
    SET_INFO_CERT_SESSION_KEY,
    SET_INFO_CERT_TASK_ID,
    SIGN_DOCUMENT_FAIL,
    SIGN_DOCUMENT_REQUEST,
    SIGN_DOCUMENT_SUCCESS,
    SIGN_FAIL,
    SIGN_REQUEST,
    SIGN_SUCCESS,
    STATUS_FAIL,
    STATUS_REQUEST,
    STATUS_SUCCESS,
    WEB_ID_DOSSIER_FAIL,
    WEB_ID_DOSSIER_REQUEST,
    WEB_ID_DOSSIER_SUCCESS,
    WEB_ID_QUEUE_FAIL,
    WEB_ID_QUEUE_REQUEST,
    WEB_ID_QUEUE_SUCCESS,
    WEB_ID_START_FAIL,
    WEB_ID_START_REQUEST,
    WEB_ID_START_SUCCESS,
} from "./infocert-reducers";
import {errorExtractor, handleResponse} from "../reduxUtils";
import {infoCertService} from "../../service/InfoCertService";
import {ActionSigningStatus, ActionTypes, OneShotIssueSignRequest, OneShotRegisterRequest, RemoteSignSignContractsRequest, UpdateDocumentSignStep} from "../../model/InfoCertDTO";
import {SET_CURRENT_ONBOARDING_STEP, SET_USER_ON_BOARDING_STEP_SUCCESS} from "../auth/auth-reducers";
import {OnBoardingStep} from "../../model/UserDTO";
import {AuthAction} from "../auth/auth-interface";
import {toastUtils} from "../../utils/toastUtils";
import {CommonAction} from "../common/common-interface";

export const createDossier = (dossierType: string): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>): Promise<InfoCertAction> => {
        dispatch({type: CREATE_DOSSIER_REQUEST, payload: dossierType});
        const response = infoCertService.createDossier(dossierType);
        return handleResponse(dispatch, CREATE_DOSSIER_SUCCESS, CREATE_DOSSIER_FAIL, response);
    };

export const getNewToken = (dossierType: string): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>): Promise<InfoCertAction> => {
        dispatch({type: GET_NEW_TOKEN_REQUEST, payload: null});
        const response = infoCertService.getNewToken(dossierType);
        return handleResponse(dispatch, GET_NEW_TOKEN_SUCCESS, GET_NEW_TOKEN_FAIL, response);
    };

export const addOnBoardingDocuments = (dossierType: string): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>): Promise<InfoCertAction> => {
        dispatch({type: ADD_DOCUMENTS_REQUEST, payload: null});
        const response = infoCertService.addOnBoardingDocuments(dossierType);
        return handleResponse(dispatch, ADD_DOCUMENTS_SUCCESS, ADD_DOCUMENTS_FAIL, response);
    };

export const signDocuments = (): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>): Promise<InfoCertAction> => {
        dispatch({type: SIGN_DOCUMENT_REQUEST, payload: null});
        const response = infoCertService.signDocuments();
        return handleResponse(dispatch, SIGN_DOCUMENT_SUCCESS, SIGN_DOCUMENT_FAIL, response);
    };

export const processStart = (dossierId: string, dossierType: string): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>): Promise<InfoCertAction> => {
        dispatch({type: PROCESS_START_REQUEST, payload: null});
        const response = infoCertService.processStart(dossierId, dossierType);
        // const pdf = infoCertService.getContractPdf();
        // await handleResponse(dispatch, DOWNLOAD_PDF_SUCCESS, DOWNLOAD_PDF_FAIL, pdf, undefined);
        return handleResponse(dispatch, PROCESS_START_SUCCESS, PROCESS_START_FAIL, response, undefined, getLogoutCallback(dispatch));
    };

export const getAuthToken = (): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>): Promise<InfoCertAction> => {
        dispatch({type: AUTHTOKEN_REQUEST, payload: null});
        const response = infoCertService.getAuthToken();
        return handleResponse(dispatch, AUTHTOKEN_SUCCESS, AUTHTOKEN_FAIL, response, undefined, getLogoutCallback(dispatch));
    };

export const webIdStart = (sessionKey: string, taskId: string): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>): Promise<InfoCertAction> => {
        dispatch({type: WEB_ID_START_REQUEST, payload: null});
        const response = infoCertService.webIdStart(sessionKey, taskId);
        return handleResponse(dispatch, WEB_ID_START_SUCCESS, WEB_ID_START_FAIL, response, undefined, getLogoutCallback(dispatch));
    };

export const webIdQueue = (sessionKey: string, taskId: string): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>): Promise<InfoCertAction> => {
        dispatch({type: WEB_ID_QUEUE_REQUEST, payload: null});
        const response = infoCertService.webIdQueue(sessionKey, taskId);
        return handleResponse(dispatch, WEB_ID_QUEUE_SUCCESS, WEB_ID_QUEUE_FAIL, response, undefined, getLogoutCallback(dispatch));
    };

export const webIdDossier = (sessionKey: string, taskId: string): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>): Promise<InfoCertAction> => {
        dispatch({type: WEB_ID_DOSSIER_REQUEST, payload: null});
        const response = infoCertService.webIdDossier(sessionKey, taskId);
        return handleResponse(dispatch, WEB_ID_DOSSIER_SUCCESS, WEB_ID_DOSSIER_FAIL, response, undefined, getLogoutCallback(dispatch));
    };

export const oneShotStart = (sessionKey: string, taskId: string, dossierType: string): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction | AuthAction>): Promise<InfoCertAction> => {
        dispatch({type: ONE_SHOT_START_REQUEST, payload: null});
        const response = infoCertService.oneShotStart(sessionKey, taskId, dossierType);
        const onFail = () => {
            dispatch({type: SET_CURRENT_ONBOARDING_STEP, payload: {onBoardingStep: OnBoardingStep.START_ONBOARD}});
            dispatch({type: SET_USER_ON_BOARDING_STEP_SUCCESS, payload: {onBoardingStep: OnBoardingStep.START_ONBOARD}});
            dispatch({type: LOGOUT_INFO_CERT_SUCCESS, payload: ""});
        };
        return handleResponse(dispatch, ONE_SHOT_START_SUCCESS, ONE_SHOT_START_FAIL, response, undefined, getLogoutCallback(dispatch), onFail);
    };

export const oneShotRegister = (sessionKey: string, taskId: string, oneShotRegisterRequest: OneShotRegisterRequest, dossierType: string): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>): Promise<InfoCertAction> => {
        dispatch({type: ONE_SHOT_REGISTER_REQUEST, payload: null});
        const response = infoCertService.oneShotRegister(sessionKey, taskId, oneShotRegisterRequest, dossierType);
        return handleResponse(dispatch, ONE_SHOT_REGISTER_SUCCESS, ONE_SHOT_REGISTER_FAIL, response, undefined, getLogoutCallback(dispatch));
    };

export const oneShotIssueSign = (sessionKey: string, taskId: string, oneShotIssueSignRequest: OneShotIssueSignRequest, dossierType: string): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>): Promise<InfoCertAction> => {
        dispatch({type: ONE_SHOT_ISSUE_SIGN_REQUEST, payload: null});
        const response = infoCertService.oneShotIssueSign(sessionKey, taskId, oneShotIssueSignRequest, dossierType);
        return handleResponse(dispatch, ONE_SHOT_ISSUE_SIGN_SUCCESS, ONE_SHOT_ISSUE_SIGN_FAIL, response, undefined, getLogoutCallback(dispatch));
    };

export const oneShotSendOTP = (sessionKey: string, taskId: string, dossierType: string): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>): Promise<InfoCertAction> => {
        dispatch({type: ONE_SHOT_SEND_OTP_REQUEST, payload: null});
        const response = infoCertService.oneShotSendOTP(sessionKey, taskId, dossierType);
        return handleResponse(dispatch, ONE_SHOT_SEND_OTP_SUCCESS, ONE_SHOT_SEND_OTP_FAIL, response, undefined, getLogoutCallback(dispatch));
    };

export const remoteSignStart = (sessionKey: string, taskId: string): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>): Promise<InfoCertAction> => {
        dispatch({type: REMOTE_SIGN_START_REQUEST, payload: null});
        const response = infoCertService.remoteSignStart(sessionKey, taskId);
        return handleResponse(dispatch, REMOTE_SIGN_START_SUCCESS, REMOTE_SIGN_START_FAIL, response, undefined, getLogoutCallback(dispatch));
    };

export const remoteSignPrepareSignature = (sessionKey: string, taskId: string): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>): Promise<InfoCertAction> => {
        dispatch({type: REMOTE_SIGN_PREPARE_SIGNATURE_REQUEST, payload: null});
        const response = infoCertService.remoteSignPrepareSignature(sessionKey, taskId);
        return handleResponse(dispatch, REMOTE_SIGN_PREPARE_SIGNATURE_SUCCESS, REMOTE_SIGN_PREPARE_SIGNATURE_FAIL, response, undefined, getLogoutCallback(dispatch));
    };

export const remoteSignSignContracts = (sessionKey: string, taskId: string, remoteSignSignContractsRequest: RemoteSignSignContractsRequest): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>): Promise<InfoCertAction> => {
        dispatch({type: REMOTE_SIGN_SIGN_CONTRACTS_REQUEST, payload: null});
        const response = infoCertService.remoteSignSignContracts(sessionKey, taskId, remoteSignSignContractsRequest);
        return handleResponse(dispatch, REMOTE_SIGN_SIGN_CONTRACTS_SUCCESS, REMOTE_SIGN_SIGN_CONTRACTS_FAIL, response, undefined, getLogoutCallback(dispatch));
    };

export const remoteSignSendOTP = (sessionKey: string, taskId: string): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>): Promise<InfoCertAction> => {
        dispatch({type: REMOTE_SIGN_SEND_OTP_REQUEST, payload: null});
        const response = infoCertService.remoteSignSendOTP(sessionKey, taskId);
        return handleResponse(dispatch, REMOTE_SIGN_SEND_OTP_SUCCESS, REMOTE_SIGN_SEND_OTP_FAIL, response, undefined, getLogoutCallback(dispatch));
    };

export const setDocumentSignStep = (payload: UpdateDocumentSignStep) => (dispatch: ThunkDispatch<{}, {}, InfoCertAction>) => {
    dispatch({type: SET_CURRENT_DOCUMENT_SIGN_STEP, payload});
};

export const setInfoCertError = (payload: string) => {
    return {type: SET_INFO_CERT_ERROR, payload};
};

export const setInfoCertDossierToken = (payload: string) => {
    return {type: SET_INFO_CERT_DOSSIER_TOKEN, payload};
};

export const setInfoCertSessionKey = (payload: string) => {
    return {type: SET_INFO_CERT_SESSION_KEY, payload};
};

export const setInfoCertTaskId = (payload: string) => {
    return {type: SET_INFO_CERT_TASK_ID, payload};
};

export const setChatHistory = (payload: []) => {
    return {type: SET_CHAT_HISTORY, payload};
};

export const addToChatHistory = (payload: any) => {
    return {type: ADD_TO_CHAT_HISTORY, payload};
};

const getLogoutCallback = (dispatch: ThunkDispatch<{}, {}, any>) => () => {
    dispatch({type: SET_CURRENT_ONBOARDING_STEP, payload: {onBoardingStep: OnBoardingStep.STEP_SIGNATURE}});
    dispatch({type: LOGOUT_INFO_CERT_REQUEST, payload: null});
    const response = infoCertService.logout();
    return handleResponse(dispatch, LOGOUT_INFO_CERT_SUCCESS, LOGOUT_INFO_CERT_FAIL, response, undefined, () => ({}));
};

export const logoutInfoCert = (): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>): Promise<InfoCertAction> => {
        dispatch({type: LOGOUT_INFO_CERT_REQUEST, payload: null});
        const response = infoCertService.logout();
        return handleResponse(dispatch, LOGOUT_INFO_CERT_SUCCESS, LOGOUT_INFO_CERT_FAIL, response, undefined, () => ({}));
    };

export const deleteInfoCertDossier = (): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>): Promise<InfoCertAction> => {
        dispatch({type: DELETE_INFO_CERT_DOSSIER_REQUEST, payload: null});
        const response = infoCertService.deleteDossier();
        return handleResponse(dispatch, DELETE_INFO_CERT_DOSSIER_SUCCESS, DELETE_INFO_CERT_DOSSIER_FAIL, response, undefined, () => ({}));
    };

export const getActions = (silent: boolean = false): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>) => {
        dispatch({type: silent ? ACTIONS_SILENT_REQUEST : ACTIONS_REQUEST, payload: null});
        const responsePromise = infoCertService.getActions();
        return await handleResponse(dispatch, silent ? ACTIONS_SILENT_SUCCESS : ACTIONS_SUCCESS, silent ? ACTIONS_SILENT_FAIL : ACTIONS_FAIL, responsePromise);
    };

export const getCombinations = (type: ActionTypes, actionData: string, referenceId?: string): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>) => {
        dispatch({type: COMBINATIONS_REQUEST, payload: null});
        const responsePromise = infoCertService.getSignersCombinations(type, actionData, referenceId);
        return await handleResponse(dispatch, COMBINATIONS_SUCCESS, COMBINATIONS_FAIL, responsePromise, errorExtractor);
    };

export const getDelegatedCombinations = (type: ActionTypes, actionData: string, referenceId?: string): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>) => {
        dispatch({type: DELEGATED_COMBINATIONS_REQUEST, payload: null});
        const responsePromise = infoCertService.getSignersCombinations(type, actionData, referenceId);
        return await handleResponse(dispatch, DELEGATED_COMBINATIONS_SUCCESS, DELEGATED_COMBINATIONS_FAIL, responsePromise, errorExtractor);
    };

export const getArchive = (actionId?: string, referenceId?: string): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>) => {
        dispatch({type: ARCHIVE_REQUEST, payload: null});
        const responsePromise = infoCertService.getArchive(actionId, referenceId);
        return await handleResponse(dispatch, ARCHIVE_SUCCESS, ARCHIVE_FAIL, responsePromise);
    };
export const clearArchive = (): ThunkAction<void, {}, {}, CommonAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>) => {
        dispatch({type: CLEAR_ARCHIVE, payload: ""});
    };
};
export const getArchiveDocumentsAdmin = (companyID: string, actionType?: string): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>) => {
        dispatch({type: ADMIN_ARCHIVE_REQUEST, payload: null});
        const responsePromise = infoCertService.getArchiveDocumentsAdmin(companyID, actionType);
        return await handleResponse(dispatch, ADMIN_ARCHIVE_SUCCESS, ADMIN_ARCHIVE_FAIL, responsePromise);
    };

export const setStatus = (token: string, status: ActionSigningStatus, delay?: number): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>) => {
        dispatch({type: STATUS_REQUEST, payload: null});
        const responsePromise = infoCertService.updateSignStatus(token, status);
        return await handleResponse(dispatch, STATUS_SUCCESS, STATUS_FAIL, responsePromise, undefined, undefined, undefined, delay);
    };

export const getInfocertStatus = (): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>) => {
        dispatch({type: INFOCERT_PROCESS_STATUS_REQUEST, payload: null});
        const responsePromise = infoCertService.getStatus();
        return await handleResponse(dispatch, INFOCERT_PROCESS_STATUS_SUCCESS, INFOCERT_PROCESS_STATUS_FAIL, responsePromise, undefined, undefined, undefined);
    };

export const signDocument = (actionData: any, combinationId: string, type?: ActionTypes): ThunkAction<void, {}, {}, InfoCertAction> =>
    async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>) => {
        dispatch({type: SIGN_REQUEST, payload: null});
        const responsePromise = infoCertService.signDocument(actionData, combinationId);
        const success = await handleResponse(dispatch, SIGN_SUCCESS, SIGN_FAIL, responsePromise);
        success && type && toastUtils.successToast(ActionTypes[type]);
        return success;
    };

export const clearFailError = (): ThunkAction<void, {}, {}, CommonAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, InfoCertAction>) => {
        dispatch({type: CLEAR_FAIL_ERROR, payload: ""});
    };
};
