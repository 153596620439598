import {networkService} from "./NetworkService";
import {
    ForgotPasswordDTORequest,
    ForgotPasswordDTOResponse,
    UserDTORequest,
    UserLoginResponse,
    VerifyPasswordDTORequest,
    VerifyPasswordDTOResponse,
} from "../model/UserDTO";
import {MyResponse} from "../model/ServiceDTO";
import {configService} from "./ConfigService";

const BASE_URL = configService.getApiPath();

class AuthService {

    login = (data: UserDTORequest): Promise<MyResponse<UserLoginResponse>> => {
        return networkService.post<UserDTORequest, UserLoginResponse>(BASE_URL + "/login", data);
    };

    forgotPassword = (data: ForgotPasswordDTORequest): Promise<MyResponse<ForgotPasswordDTOResponse>> => {
        return networkService.post<ForgotPasswordDTORequest, ForgotPasswordDTOResponse>(BASE_URL + "/user/forgotPassword", data);
    };

    verifyPassword = (data: VerifyPasswordDTORequest): Promise<MyResponse<VerifyPasswordDTOResponse>> => {
        return networkService.post<VerifyPasswordDTORequest, VerifyPasswordDTOResponse>(BASE_URL + "/user/verifyPassword", data);
    };

    //call it from the navBar and when the be send 401
    logout = () => {
        return networkService.post<any, any>(BASE_URL + "/logout", null);
    };
}

export const authService = new AuthService();
