import * as React from 'react';
import "./Button.scss";
import {AppState} from "../../../redux/store";
import {Roles} from "../../../model/UserDTO";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {backArrow} from "../../../utils/dynamicSVG";

export enum BUTTON_TYPE {
    primaryInverted = "button--inverted",
    closeButton = "button--close",
    buttonDisabled = "button--disabled",
    buttonWhite = "button--white",
    buttonDisabledWhite = "button--disabledWhite",
    buttonBlack = "button--black",
    buttonBlue = "button--blue",
    buttonFilter = "button--filter",
    buttonFilterClicked = "button--filter--clicked",
    buttonQuestionnaire = "button--filter button--filter--height40",
    buttonQuestionnaireClicked = "button--filter--clicked button--filter--clicked--height40",
    buttonBordeaux = "button--bordeaux",
    AuthButton = "button--authButton",
    greenButton = "button--greenButton",
    redButton = "button--redButton",
    primarySmallerFont = "button--primarySmallerFont",
    noBorder = "button--noBorder"
}

interface Props {
    label?: any,
    buttonType?: BUTTON_TYPE
    className?: string
    icon?: string | null,
    disabled?: boolean,
    openNewTab?: boolean,
    width?: string,
    height?: string,
    iconOnTheLeft?: boolean,
    loading?: boolean,
    href?: string
    nameFileToDownload?: string,
    svgPath?: any,
    onClick?(input?: any): void;
    blockable?: boolean;
    roleList: Roles[]
    title?: string,
    [index: string]: any,
    role: Roles,
    style?:any
}

const Button: React.FC<Props> = ({
                                     label,
                                     buttonType,
                                     width,
                                     icon,
                                     disabled,
                                     iconOnTheLeft,
                                     href,
                                     nameFileToDownload,
                                     openNewTab,
                                     svgPath,
                                     height,
                                     className,
                                     onClick,
                                     blockable,
                                     roleList,
                                     title = "",
                                     role,
                                     loading = false,
                                     style
                                 }) => {
    const {t} = useTranslation();
    let styleBlocked = "";
    if (blockable && roleList.indexOf(Roles.BLOCKED) > -1) {
        disabled = true;
        title = t("common.button.blockedTitle");
        styleBlocked = "button--blocked";
    }
    const styleButton = disabled ? BUTTON_TYPE.buttonDisabled : buttonType;
    const styleIconOnTheLeft = iconOnTheLeft && "button__content--displayFlex";

    return (
        <button
            className={`button ${styleButton || ""} ${className} ${styleBlocked}`}
            style={width ? {width} : height ? {height} : style}
            onClick={!disabled ? onClick : () => ({})}
            title={title}
        >
            <div className={`button__content ${styleIconOnTheLeft}`}>
                {nameFileToDownload &&
                <>
                    <a
                        className="button__linkDownload"
                        download={nameFileToDownload}
                        href={href}
                        target={openNewTab ? "_blank" : ""}
                    >
                        {label}
                        {icon &&
                        <img
                            className="button__img"
                            src={icon}
                            alt=""
                        />
                        }
                    </a>
                </>
                }
                {iconOnTheLeft &&
                <div className="button__img--previousArrow">
                    {backArrow(role, "18px", "19px")}
                </div>
                }
                {!nameFileToDownload && (loading === true ? t("common.loading") : label)}
                {icon && iconOnTheLeft === undefined && !nameFileToDownload &&
                <img
                    className="button__img"
                    src={icon}
                    alt=""
                />}
                {svgPath && <span className="button__img"> {svgPath} </span>}
            </div>
        </button>
    );
};


const mapStateToProps = (store: AppState) => ({
    roleList: store.auth.roles,
    role: store.auth.role,
});

export default connect(mapStateToProps)(Button);
