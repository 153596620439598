import {languageUtils} from "./languageUtils";
import i18n from "../i18n";
import {currencyUtils} from "./currencyUtils";

class NumberUtils {
    group = "";
    decimal = "";


    getSeparators = () => {
        const locale = languageUtils.getLocaleLanguage();
        this.group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
        this.decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');
        return {group: this.group, decimal: this.decimal};
    };


    formatNumberWithZero = (number: number, length_required: number): string => {
        let formatted_number = number.toString();
        let number_length = formatted_number.toString().length;
        while (number_length < length_required) {
            number_length++;
            formatted_number = "0" + formatted_number;
        }
        return formatted_number;
    };

    getNumberFormattedWithZero = (number: string): string => {
        let number_length = number.length;
        for (let i = 0; i < number_length; i++) {
            if (number[i] !== "0") {
                return number.slice(i);
            }
        }
        return "0";
    };


    formatToLocaleNumber = (number: any, fractionDigits: number = 2, withPlusSign?: boolean): string => {
        if (typeof number === "number") {
            const formatted = Intl.NumberFormat(languageUtils.getLocaleLanguage(),
                {
                    style: 'decimal',
                    useGrouping: true,
                    minimumFractionDigits: fractionDigits > 4 ? 4 : fractionDigits,
                    maximumFractionDigits: fractionDigits > 4 ? 4 : fractionDigits
                }).format(number);
            return (withPlusSign && number > 0) ? "+" + formatted : formatted;
        } else
            return this.formatToLocaleNumber(0);
    };

    formatToPercent = (number: any, withSign = true, notDefinedMessage = i18n.t("common.notDefined")): string => {
        return typeof number === "number"
            ?
            `${this.formatToLocaleNumber(number, 3, withSign)} %`
            :
            notDefinedMessage;
    };

    getRatePlaceholder = () => "xx" + this.getSeparators().decimal + "xxx";

    getNumberPlaceholder = () => "x" + this.getSeparators().decimal + "xx";

    reverseFromLocaleToNumber = (val: any): number => {
        if (typeof val === "string") {
            const {decimal, group} = this.getSeparators();
            let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');
            reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
            return Number.isNaN(+reversedVal) ? 0 : +reversedVal;
        } else return val;
    };

    reverseAndFormatToLocaleNumber = (val: any, fractionDigits?: number, withPlusSign?: boolean): string => {
        const valueAsNumber = this.reverseFromLocaleToNumber(val);
        return this.formatToLocaleNumber(valueAsNumber, fractionDigits, withPlusSign);
    };

    /**
     * Returns a random integer between min (inclusive) and max (inclusive).
     * The value is no lower than min (or the next integer greater than min
     * if min isn't an integer) and no greater than max (or the next integer
     * lower than max if max isn't an integer).
     * Using Math.round() will give you a non-uniform distribution!
     */
    getRandomInt = (min: number, max: number): number => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    formatWithCurrency = (number: number | undefined | null, currency: string | null | undefined) => typeof number === "number"
        ? (currency && currencyUtils.getSymbolFromCurrencyISO(currency) + " " + this.formatToLocaleNumber(number))
        : "--";

    isValidNumber = (value: any): boolean => {
        return value !== undefined && value !== null && value !== '' && !isNaN(parseFloat(value));
    };
}

export const numberUtils = new NumberUtils();
