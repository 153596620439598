import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {MoneylenderAction} from "./moneylender-interface";
import {
    CLEAR_DETAILS_CONFIGURATION,
    CONFIGURATION_PRICES_STEP,
    EDIT_VENDOR_INFO,
    EDIT_VENDOR_INFO_FAIL,
    EDIT_VENDOR_INFO_SUCCESS,
    GET_ACTIVE_INVOICES,
    GET_ACTIVE_INVOICES_FAIL,
    GET_ACTIVE_INVOICES_SUCCESS,
    GET_ALL_OPERATIONS,
    GET_ALL_OPERATIONS_FAIL,
    GET_ALL_OPERATIONS_SUCCESS,
    GET_CONFIGURATIONS,
    GET_CONFIGURATIONS_FAIL,
    GET_CONFIGURATIONS_SUCCESS,
    GET_CREDIT_LINE_DETAILS,
    GET_CREDIT_LINE_DETAILS_FAIL,
    GET_CREDIT_LINE_DETAILS_SUCCESS,
    GET_CREDIT_LINES,
    GET_CREDIT_LINES_FAIL,
    GET_CREDIT_LINES_FAIL_SILENT,
    GET_CREDIT_LINES_SILENT,
    GET_CREDIT_LINES_SUCCESS,
    GET_CREDIT_LINES_SUCCESS_SILENT,
    GET_DETAILS_CONFIGURATION,
    GET_DETAILS_CONFIGURATION_FAIL,
    GET_DETAILS_CONFIGURATION_SUCCESS,
    GET_INVOICE_DETAILS,
    GET_INVOICE_DETAILS_FAIL,
    GET_INVOICE_DETAILS_SUCCESS,
    GET_INVOICE_OPERATIONS,
    GET_INVOICE_OPERATIONS_FAIL,
    GET_INVOICE_OPERATIONS_SUCCESS,
    GET_INVOICES,
    GET_INVOICES_BY_AMOUNT,
    GET_INVOICES_FAIL,
    GET_INVOICES_FAIL_BY_AMOUNT,
    GET_INVOICES_SUCCESS,
    GET_INVOICES_SUCCESS_BY_AMOUNT,
    GET_MONEYLENDER,
    GET_MONEYLENDER_FAIL,
    GET_MONEYLENDER_SUCCESS,
    GET_OPERATIONS,
    GET_OPERATIONS_FAIL,
    GET_OPERATIONS_SUCCESS,
    GET_PROGRAMS_WITHOUT_CONFIGURATION,
    GET_PROGRAMS_WITHOUT_CONFIGURATION_FAIL,
    GET_PROGRAMS_WITHOUT_CONFIGURATION_SUCCESS,
    GET_VENDOR_REGISTRY,
    GET_VENDOR_REGISTRY_FAIL,
    GET_VENDOR_REGISTRY_SUCCESS,
    INCOME_OPERATIONS_FAIL,
    INCOME_OPERATIONS_REQUEST,
    INCOME_OPERATIONS_SUCCESS,
    RETRIEVE_VENDOR,
    RETRIEVE_VENDOR_FAIL,
    RETRIEVE_VENDOR_INFO,
    RETRIEVE_VENDOR_INFO_FAIL,
    RETRIEVE_VENDOR_INFO_SUCCESS,
    RETRIEVE_VENDOR_PROGRAM,
    RETRIEVE_VENDOR_PROGRAM_FAIL,
    RETRIEVE_VENDOR_PROGRAM_SUCCESS,
    RETRIEVE_VENDOR_SUCCESS,
    SAVE_PROGRAMS_CONFIGURATION,
    SAVE_PROGRAMS_CONFIGURATION_FAIL,
    SAVE_PROGRAMS_CONFIGURATION_SUCCESS,
    SET_ALL_PROGRAMS_FLAG,
    SET_CREDIT_CURRENCY,
    SET_CREDIT_LINE_ID,
    SET_SELECTED_PROGRAMS_TO_CONFIGURE
} from "./moneylender-reducers";
import {moneylenderService} from "../../service/MoneylenderService";
import {handleResponse} from "../reduxUtils";
import {RequestEditVendorInfoDTO} from "../../model/MoneylenderDTO";
import {vendorService} from "../../service/VendorService";

export const getMoneyLender = (): ThunkAction<void, {}, {}, MoneylenderAction> =>
    async (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
        dispatch({type: GET_MONEYLENDER, payload: null});
        const responsePromise = moneylenderService.getMoneylender();
        return await handleResponse(dispatch, GET_MONEYLENDER_SUCCESS, GET_MONEYLENDER_FAIL, responsePromise);
    };

export const getCreditLines = (isSilent = false, vatNumber?: string): ThunkAction<void, {}, {}, MoneylenderAction> =>
    async (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
        dispatch({type: isSilent ? GET_CREDIT_LINES_SILENT : GET_CREDIT_LINES, payload: null});
        const responsePromise = moneylenderService.getCreditLines(vatNumber);
        return await handleResponse(dispatch, isSilent ? GET_CREDIT_LINES_SUCCESS_SILENT : GET_CREDIT_LINES_SUCCESS, isSilent ? GET_CREDIT_LINES_FAIL_SILENT : GET_CREDIT_LINES_FAIL, responsePromise);
    };

export const getCreditLineDetails = (creditLineId: number): ThunkAction<void, {}, {}, MoneylenderAction> =>
    async (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
        dispatch({type: GET_CREDIT_LINE_DETAILS, payload: null});
        const responsePromise = moneylenderService.getCreditLineInfo(creditLineId);
        return await handleResponse(dispatch, GET_CREDIT_LINE_DETAILS_SUCCESS, GET_CREDIT_LINE_DETAILS_FAIL, responsePromise);
    };

export const retrieveProgramsWithoutConfiguration = (creditLineId: number): ThunkAction<void, {}, {}, MoneylenderAction> =>
    async (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
        dispatch({type: GET_PROGRAMS_WITHOUT_CONFIGURATION, payload: null});
        const responsePromise = moneylenderService.retrieveProgramsWithoutConfiguration(creditLineId);
        await handleResponse(dispatch, GET_PROGRAMS_WITHOUT_CONFIGURATION_SUCCESS, GET_PROGRAMS_WITHOUT_CONFIGURATION_FAIL, responsePromise);
        return responsePromise;
    };

export const saveConfigurationPrices = (creditLineId: number, programs: any[]): ThunkAction<void, {}, {}, MoneylenderAction> =>
    async (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
        dispatch({type: SAVE_PROGRAMS_CONFIGURATION, payload: null});
        const responsePromise = moneylenderService.saveProgramsConfiguration(creditLineId, programs);
        return await handleResponse(dispatch, SAVE_PROGRAMS_CONFIGURATION_SUCCESS, SAVE_PROGRAMS_CONFIGURATION_FAIL, responsePromise);
    };

export const getAllConfigurations = (creditLineId: number): ThunkAction<void, {}, {}, MoneylenderAction> =>
    async (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
        dispatch({type: GET_CONFIGURATIONS, payload: null});
        const responsePromise = moneylenderService.getAllConfigurations(creditLineId);
        return await handleResponse(dispatch, GET_CONFIGURATIONS_SUCCESS, GET_CONFIGURATIONS_FAIL, responsePromise);
    };

export const getDetailsConfiguration = (configurationId: number): ThunkAction<void, {}, {}, MoneylenderAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
        dispatch({type: GET_DETAILS_CONFIGURATION, payload: null});
        const responsePromise = moneylenderService.getDetailsConfiguration(configurationId);
        return await handleResponse(dispatch, GET_DETAILS_CONFIGURATION_SUCCESS, GET_DETAILS_CONFIGURATION_FAIL, responsePromise);
    };
};

export const getActiveInvoices = (): ThunkAction<void, {}, {}, MoneylenderAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
        dispatch({type: GET_ACTIVE_INVOICES, payload: false});
        const responsePromise = moneylenderService.getActiveInvoices();
        return await handleResponse(dispatch, GET_ACTIVE_INVOICES_SUCCESS, GET_ACTIVE_INVOICES_FAIL, responsePromise);
    };
};

export const getInvoiceDetails = (id: number): ThunkAction<void, {}, {}, MoneylenderAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
        dispatch({type: GET_INVOICE_DETAILS, payload: false});
        const responsePromise = moneylenderService.getInvoiceDetails(id);
        return await handleResponse(dispatch, GET_INVOICE_DETAILS_SUCCESS, GET_INVOICE_DETAILS_FAIL, responsePromise);
    };
};

export const getOperations = (id: number): ThunkAction<void, {}, {}, MoneylenderAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
        dispatch({type: GET_OPERATIONS, payload: false});
        const responsePromise = moneylenderService.getOperations(id);
        return await handleResponse(dispatch, GET_OPERATIONS_SUCCESS, GET_OPERATIONS_FAIL, responsePromise);
    };
};

export const getInvoiceOperations = (id: number): ThunkAction<void, {}, {}, MoneylenderAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
        dispatch({type: GET_INVOICE_OPERATIONS, payload: false});
        const responsePromise = moneylenderService.getInvoiceOperations(id);
        return await handleResponse(dispatch, GET_INVOICE_OPERATIONS_SUCCESS, GET_INVOICE_OPERATIONS_FAIL, responsePromise);
    };
};

export const retrieveVendor = (): ThunkAction<void, {}, {}, MoneylenderAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
        dispatch({type: RETRIEVE_VENDOR, payload: false});
        const responsePromise = moneylenderService.retrieveVendor();
        return await handleResponse(dispatch, RETRIEVE_VENDOR_SUCCESS, RETRIEVE_VENDOR_FAIL, responsePromise);
    };
};

export const editVendorInfo = (data: RequestEditVendorInfoDTO): ThunkAction<void, {}, {}, MoneylenderAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
        dispatch({type: EDIT_VENDOR_INFO, payload: false});
        const responsePromise = moneylenderService.editVendorInfo(data);
        return await handleResponse(dispatch, EDIT_VENDOR_INFO_SUCCESS, EDIT_VENDOR_INFO_FAIL, responsePromise);
    };
};

export const retrieveVendorProgram = (vatNumber: string): ThunkAction<void, {}, {}, MoneylenderAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
        dispatch({type: RETRIEVE_VENDOR_PROGRAM, payload: false});
        const responsePromise = moneylenderService.retrieveVendorProgram(vatNumber);
        return await handleResponse(dispatch, RETRIEVE_VENDOR_PROGRAM_SUCCESS, RETRIEVE_VENDOR_PROGRAM_FAIL, responsePromise);
    };
};
export const retrieveVendorInfo = (vatNumber: string): ThunkAction<void, {}, {}, MoneylenderAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
        dispatch({type: RETRIEVE_VENDOR_INFO, payload: false});
        const responsePromise = moneylenderService.retrieveVendorInfo(vatNumber);
        return await handleResponse(dispatch, RETRIEVE_VENDOR_INFO_SUCCESS, RETRIEVE_VENDOR_INFO_FAIL, responsePromise);
    };
};

export const getMoneylenderInvoices = (buyerId: string, programName: string): ThunkAction<void, {}, {}, MoneylenderAction> =>
    async (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
        dispatch({type: GET_INVOICES, payload: null});
        const responsePromise = vendorService.getInvoices(buyerId, programName);
        return await handleResponse(dispatch, GET_INVOICES_SUCCESS, GET_INVOICES_FAIL, responsePromise);
    };

export const getMoneylenderInvoicesByAmount = (buyerId: string, programName: string, amount: number): ThunkAction<void, {}, {}, MoneylenderAction> =>
    async (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
        dispatch({type: GET_INVOICES_BY_AMOUNT, payload: null});
        const responsePromise = vendorService.getInvoicesByAmount(buyerId, amount, programName);
        return await handleResponse(dispatch, GET_INVOICES_SUCCESS_BY_AMOUNT, GET_INVOICES_FAIL_BY_AMOUNT, responsePromise);
    };

export const getVendorRegistry = (buyerId: string): ThunkAction<void, {}, {}, MoneylenderAction> =>
    async (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
        dispatch({type: GET_VENDOR_REGISTRY, payload: null});
        const responsePromise = moneylenderService.getVendorRegistry(buyerId);
        return await handleResponse(dispatch, GET_VENDOR_REGISTRY_SUCCESS, GET_VENDOR_REGISTRY_FAIL, responsePromise);
    };


export const getIncomeOperations = (): ThunkAction<void, {}, {}, MoneylenderAction> =>
    async (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
        dispatch({type: INCOME_OPERATIONS_REQUEST});
        const responsePromise = moneylenderService.getIncomeOperationList();
        return await handleResponse(dispatch, INCOME_OPERATIONS_SUCCESS, INCOME_OPERATIONS_FAIL, responsePromise);
    };

export const getAllOperations = (): ThunkAction<void, {}, {}, MoneylenderAction> =>
    async (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
        dispatch({type: GET_ALL_OPERATIONS, payload: null});
        const responsePromise = moneylenderService.getAllOperations();
        return await handleResponse(dispatch, GET_ALL_OPERATIONS_SUCCESS, GET_ALL_OPERATIONS_FAIL, responsePromise);
    };

export const setConfigurationPricesStep = (payload: string) => (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
    dispatch({type: CONFIGURATION_PRICES_STEP, payload});
};

export const setCreditLineId = (payload: string, name?: string) => (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
    dispatch({type: SET_CREDIT_LINE_ID, payload: {creditLineId: payload, creditLineName: name}});
};

export const setCreditLineCurrency = (curr: string) => (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
    dispatch({type: SET_CREDIT_CURRENCY, payload: curr});
};

export const setSelectedProgramsToConfigure = (payload: any[]) => (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
    dispatch({type: SET_SELECTED_PROGRAMS_TO_CONFIGURE, payload});
};
export const setAllProgramsFlag = (payload: boolean) => (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
    dispatch({type: SET_ALL_PROGRAMS_FLAG, payload});
};

export const clearDetailsConfiguration = (payload: any) => (dispatch: ThunkDispatch<{}, {}, MoneylenderAction>) => {
    dispatch({type: CLEAR_DETAILS_CONFIGURATION, payload});
};
