import {GET_ALL_USERS, GET_ALL_USERS_FAIL, GET_ALL_USERS_SUCCESS, GET_USER_FILES_FAIL, GET_USER_FILES_REQUEST, GET_USER_FILES_SUCCESS, RESET_USER, SAVE_PAID_INVOICES, SAVE_PAID_INVOICES_FAIL, SAVE_PAID_INVOICES_SUCCESS, SET_COMPANY_DATA, SET_COMPANY_DATA_FAIL, SET_COMPANY_DATA_SUCCESS, SET_TERMS_ACCEPTANCE, SET_TERMS_ACCEPTANCE_FAIL, SET_TERMS_ACCEPTANCE_SUCCESS, SET_USER_ERROR, SET_USER_FILES, SET_USER_FILES_FAIL, SET_USER_FILES_SUCCESS, SET_VERSION, UPDATE_USER_ROLE, UPDATE_USER_ROLE_FAIL, UPDATE_USER_ROLE_SUCCESS, USER_INFO, USER_INFO_FAIL, USER_INFO_SUCCESS, UserAction, UserState} from "./user-interface";
import {CompanyDetailsDTO} from "../../model/CompanyDTO";
import {UserFilesDTO, UserInfoDTO, UserManagementDTO} from "../../model/UserDTO";

const defaultState: UserState = {
    companyDataResponse: {} as CompanyDetailsDTO,
    isFetchingSetCompanyData: false,
    termsConditionResponse: "",
    isFetchingTermsCondition: false,
    userFilesResponse: "",
    isFetchingUserFiles: false,
    error: "",
    isFetchingGetUserFiles: false,
    getUserFilesSuccess: {} as UserFilesDTO,
    getUserFilesFail: "",
    getUsersByRoleFail: [],
    isFetchingSavePaidInvoices: false,
    savePaidInvoicesResponse: "",
    savePaidInvoicesFail: "",
    isFetchingUserInfo: false,
    userInfoResponse: null,
    userInfoFail: "",
    version: "",
    isFetchingAllUsers: null,
    getAllUsersSuccess: [] as UserManagementDTO[],
    getAllUsersFail: "",
    isFetchingUpdateUserRole: null,
    updateUserRoleSuccess: null,
    updateUserRoleFail: null
};

export function userReducer(state = defaultState, action: UserAction): UserState {
    switch (action.type) {
        case SET_VERSION:
            return {
                ...state,
                version: action.payload,
            };
        case SET_COMPANY_DATA:
            return {
                ...state,
                isFetchingSetCompanyData: true
            };
        case SET_COMPANY_DATA_SUCCESS:
            return {
                ...state,
                error: "",
                companyDataResponse: action.payload,
                isFetchingSetCompanyData: false,
            };
        case SET_COMPANY_DATA_FAIL:
            return {
                ...state,
                error: action.payload,
                companyDataResponse: {} as CompanyDetailsDTO,
                isFetchingSetCompanyData: false,
            };
        case SET_USER_FILES:
            return {
                ...state,
                isFetchingUserFiles: true
            };
        case SET_USER_FILES_SUCCESS:
            return {
                ...state,
                error: "",
                userFilesResponse: action.payload,
                isFetchingUserFiles: false,
            };
        case SET_USER_FILES_FAIL:
            return {
                ...state,
                error: action.payload,
                userFilesResponse: "",
                isFetchingUserFiles: false,
            };
        case SET_TERMS_ACCEPTANCE:
            return {
                ...state,
                isFetchingTermsCondition: true
            };
        case SET_TERMS_ACCEPTANCE_SUCCESS:
            return {
                ...state,
                termsConditionResponse: action.payload,
                isFetchingTermsCondition: false,
            };
        case SET_TERMS_ACCEPTANCE_FAIL:
            return {
                ...state,
                termsConditionResponse: action.payload,
                isFetchingTermsCondition: false,
            };
        case SET_USER_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case GET_USER_FILES_REQUEST:
            return {
                ...state,
                isFetchingGetUserFiles: true,
                getUserFilesSuccess: defaultState.getUserFilesSuccess,
                getUserFilesFail: defaultState.getUserFilesFail
            };
        case GET_USER_FILES_SUCCESS:
            return {
                ...state,
                isFetchingGetUserFiles: false,
                getUserFilesSuccess: action.payload,
                getUserFilesFail: defaultState.getUserFilesFail
            };
        case GET_USER_FILES_FAIL:
            return {
                ...state,
                isFetchingGetUserFiles: false,
                getUserFilesSuccess: defaultState.getUserFilesSuccess,
                getUserFilesFail: action.payload
            };
        case SAVE_PAID_INVOICES:
            return {
                ...state,
                isFetchingSavePaidInvoices: true,
                savePaidInvoicesResponse: [],
                savePaidInvoicesFail: defaultState.savePaidInvoicesFail
            };
        case SAVE_PAID_INVOICES_SUCCESS:
            return {
                ...state,
                isFetchingSavePaidInvoices: false,
                savePaidInvoicesResponse: action.payload,
                savePaidInvoicesFail: defaultState.savePaidInvoicesFail
            };
        case SAVE_PAID_INVOICES_FAIL:
            return {
                ...state,
                isFetchingSavePaidInvoices: false,
                savePaidInvoicesResponse: [],
                savePaidInvoicesFail: action.payload
            };

        case USER_INFO:
            return {
                ...state,
                isFetchingUserInfo: true,
                userInfoResponse: null,
                userInfoFail: ""
            };
        case USER_INFO_SUCCESS:
            return {
                ...state,
                isFetchingUserInfo: false,
                userInfoResponse: action.payload as UserInfoDTO,
                userInfoFail: ""
            };
        case USER_INFO_FAIL:
            return {
                ...state,
                isFetchingUserInfo: false,
                userInfoResponse: null,
                userInfoFail: action.payload
            };
        case RESET_USER:
            return {
                ...state,
                ...defaultState
            };

        case GET_ALL_USERS:
            return {
                ...state,
                isFetchingAllUsers: true,
                getAllUsersSuccess: defaultState.getAllUsersSuccess,
                getAllUsersFail: defaultState.getAllUsersFail,
            };
        case GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                isFetchingAllUsers: false,
                getAllUsersSuccess: action.payload as UserManagementDTO[],
                getAllUsersFail: defaultState.getAllUsersFail,
            };
        case GET_ALL_USERS_FAIL:
            return {
                ...state,
                isFetchingAllUsers: false,
                getAllUsersSuccess: defaultState.getAllUsersSuccess,
                getAllUsersFail: action.payload,
            };

        case UPDATE_USER_ROLE:
            return {
                ...state,
                isFetchingUpdateUserRole: true,
                updateUserRoleSuccess: defaultState.updateUserRoleSuccess,
                updateUserRoleFail: defaultState.updateUserRoleFail,
            };
        case UPDATE_USER_ROLE_SUCCESS:
            return {
                ...state,
                isFetchingUpdateUserRole: false,
                updateUserRoleSuccess: action.payload,
                updateUserRoleFail: defaultState.updateUserRoleFail,
            };
        case UPDATE_USER_ROLE_FAIL:
            return {
                ...state,
                isFetchingUpdateUserRole: false,
                updateUserRoleSuccess: defaultState.updateUserRoleSuccess,
                updateUserRoleFail: action.payload,
            };
        default:
            return state;
    }
}
