import {
  BuyerAction,
  BuyerState,
  GET_BUYER_ACTIVE_INVOICES,
  GET_BUYER_ACTIVE_INVOICES_FAIL,
  GET_BUYER_ACTIVE_INVOICES_FAIL_SILENT,
  GET_BUYER_ACTIVE_INVOICES_SILENT,
  GET_BUYER_ACTIVE_INVOICES_SUCCESS,
  GET_BUYER_ACTIVE_INVOICES_SUCCESS_SILENT,
  GET_BUYERS_AMOUNT_INVOICES,
  GET_BUYERS_AMOUNT_INVOICES_REQUEST,
  GET_BUYERS_INVOICES,
  GET_BUYERS_INVOICES_DELAYED,
  GET_BUYERS_INVOICES_DELAYED_REQUEST,
  GET_BUYERS_INVOICES_FAIL,
  GET_BUYERS_INVOICES_REQUEST,
  GET_CURRENCIES,
  GET_CURRENCIES_FAIL,
  GET_CURRENCIES_REQUEST,
  GET_DYNAMIC_DISCOUNT_ASSOCIATED,
  GET_DYNAMIC_DISCOUNT_ASSOCIATED_FAIL,
  GET_DYNAMIC_DISCOUNT_ASSOCIATED_SUCCESS,
  BUYER_NEW_COMPANY_STEP,
  EDITABLE_INVOICES_REQUEST,
  EDITABLE_INVOICES_SUCCESS,
  EDITABLE_INVOICES_FAIL,
  CESSION_OPERATIONS_REQUEST,
  CESSION_OPERATIONS_SUCCESS,
  CESSION_OPERATIONS_FAIL,
  RETRIEVE_MONEYLENDERS_REQUEST,
  RETRIEVE_MONEYLENDERS_SUCCESS,
  RETRIEVE_MONEYLENDERS_FAIL,
  RETRIEVE_MONEYLENDER_INFO_REQUEST,
  RETRIEVE_MONEYLENDER_INFO_SUCCESS,
  RETRIEVE_MONEYLENDER_INFO_FAIL,
  EDIT_MONEYLENDER_INFO_REQUEST,
  EDIT_MONEYLENDER_INFO_SUCCESS,
  EDIT_MONEYLENDER_INFO_FAIL,
  RETRIEVE_MONEYLENDER_CREDIT_LINE_SUCCESS,
  RETRIEVE_MONEYLENDER_CREDIT_LINE_FAIL,
  RETRIEVE_MONEYLENDER_CREDIT_LINE_REQUEST,
} from "./buyer-interface";
import {
  BuyerActiveInvoiceDTO,
  BuyerInvoiceDTO,
  BuyerEditableInvoiceDTO,
  BuyerCessionOperationsDTO,
  BuyerMoneylenderInfoDTO,
  EditMoneylenderInfoDTO,
  BuyerMoneyLenderCreditLineDTO,
} from "../../model/BuyerDTO";
import { onlyUniqueElements } from "../../utils/commonUtils";

const defaultState: BuyerState = {
  invoicesCurrencies: [] as string[],
  invoicesCurrenciesIsFetching: false,
  invoicesCurrenciesFail: "",
  invoices: null,
  invoicesSelected: null,
  invoicesDelayed: null,
  computedInvoicesCurrencies: [] as string[],
  invoicesIsFetching: null,
  invoicesFail: "",
  isFetchingDynamicDiscountAssociated: null,
  getDynamicDiscountAssociatedSuccess: null,
  getDynamicDiscountAssociatedFail: "",
  activeInvoice: [],
  isFetchingActiveInvoices: false,
  isFetchingActiveInvoicesSilent: false,
  computedCurrencyFilters: [],
  buyerNewCompanyStep: null,
  isFetchingEditableInvoices: false,
  editableInvoices: [] as BuyerEditableInvoiceDTO[],
  editableInvoicesFail: null,
  isFetchingCessionOperations: false,
  cessionOperations: [] as BuyerCessionOperationsDTO[],
  cessionOperationsFail: null,
  isFetchingRetrieveMoneylenders: false,
  retrieveMoneylenders: [] as BuyerMoneylenderInfoDTO[],
  retrieveMoneylendersFail: null,
  isFetchingRetrieveMoneylenderInfo: false,
  retrieveMoneylenderInfo: null,
  retrieveMoneylenderInfoFail: null,
  isFetchingRetrieveMoneylenderCreditLine: false,
  retrieveMoneylenderCreditLine: null,
  retrieveMoneylenderCreditLineFail: null,
  isFetchingEditMoneylenderInfo: false,
  editMoneylenderInfoResponse: "",
  editMoneylenderInfoFail: "",
};

const computedInvoicesCurrencies = (action: BuyerAction) =>
  (action &&
    action.payload &&
    Array.isArray(action.payload) &&
    action.payload
      .map((item: BuyerInvoiceDTO) => item.currency)
      .filter(onlyUniqueElements)) ||
  ([] as string[]);

const getCurrenciesFilters = (invoicesData: BuyerActiveInvoiceDTO[]) =>
  invoicesData?.map?.((item) => item.currency).filter(onlyUniqueElements) || [];

export function buyerReducer(
  state = defaultState,
  action: BuyerAction
): BuyerState {
  switch (action.type) {
    case BUYER_NEW_COMPANY_STEP:
      return {
        ...state,
        buyerNewCompanyStep: action.payload,
      };
    case GET_BUYERS_INVOICES:
      return {
        ...state,
        invoices: action.payload,
        computedInvoicesCurrencies: computedInvoicesCurrencies(action),
        invoicesIsFetching: false,
        invoicesFail: "",
      };
    case GET_BUYERS_AMOUNT_INVOICES:
      action.payload.forEach((item: BuyerInvoiceDTO) => (item.selected = true));
      return {
        ...state,
        invoicesSelected: action.payload,
        invoicesIsFetching: false,
        invoicesFail: "",
      };
    case GET_BUYERS_INVOICES_DELAYED:
      return {
        ...state,
        invoicesDelayed: action.payload,
        computedInvoicesCurrencies: computedInvoicesCurrencies(action),
        invoicesIsFetching: false,
        invoicesFail: "",
      };
    case GET_BUYERS_INVOICES_REQUEST:
      return {
        ...state,
        invoices: defaultState.invoices,
        invoicesSelected: defaultState.invoicesSelected,
        invoicesDelayed: defaultState.invoicesDelayed,
        invoicesIsFetching: true,
        invoicesFail: "",
      };
    case GET_BUYERS_INVOICES_DELAYED_REQUEST:
    case GET_BUYERS_AMOUNT_INVOICES_REQUEST:
      return {
        ...state,
        invoicesSelected: defaultState.invoicesSelected,
        invoicesDelayed: defaultState.invoicesDelayed,
        invoicesIsFetching: true,
        invoicesFail: "",
      };

    case GET_BUYERS_INVOICES_FAIL:
      return {
        ...state,
        invoices: defaultState.invoices,
        invoicesIsFetching: false,
        invoicesFail: action.payload,
      };
    case GET_CURRENCIES:
      return {
        ...state,
        invoicesCurrencies: action.payload,
        invoicesCurrenciesIsFetching: false,
        invoicesCurrenciesFail: "",
      };
    case GET_CURRENCIES_REQUEST:
      return {
        ...state,
        invoicesCurrencies: defaultState.invoicesCurrencies,
        invoicesCurrenciesIsFetching: true,
        invoicesCurrenciesFail: "",
      };
    case GET_CURRENCIES_FAIL:
      return {
        ...state,
        invoicesCurrencies: defaultState.invoicesCurrencies,
        invoicesCurrenciesIsFetching: false,
        invoicesCurrenciesFail: action.payload,
      };
    case GET_DYNAMIC_DISCOUNT_ASSOCIATED:
      return {
        ...state,
        isFetchingDynamicDiscountAssociated: true,
        getDynamicDiscountAssociatedSuccess:
          defaultState.getDynamicDiscountAssociatedSuccess,
        getDynamicDiscountAssociatedFail:
          defaultState.getDynamicDiscountAssociatedFail,
      };
    case GET_DYNAMIC_DISCOUNT_ASSOCIATED_SUCCESS:
      return {
        ...state,
        isFetchingDynamicDiscountAssociated: false,
        getDynamicDiscountAssociatedSuccess: action.payload,
        getDynamicDiscountAssociatedFail:
          defaultState.getDynamicDiscountAssociatedFail,
      };
    case GET_DYNAMIC_DISCOUNT_ASSOCIATED_FAIL:
      return {
        ...state,
        isFetchingDynamicDiscountAssociated: false,
        getDynamicDiscountAssociatedSuccess:
          defaultState.getDynamicDiscountAssociatedSuccess,
        getDynamicDiscountAssociatedFail: action.payload,
      };
    case GET_BUYER_ACTIVE_INVOICES:
      return {
        ...state,
        isFetchingActiveInvoices: true,
      };
    case GET_BUYER_ACTIVE_INVOICES_SUCCESS:
      return {
        ...state,
        activeInvoice: action.payload,
        computedCurrencyFilters: getCurrenciesFilters(
          action.payload.invoicesData
        ),
        isFetchingActiveInvoices: false,
      };
    case GET_BUYER_ACTIVE_INVOICES_FAIL:
      return {
        ...state,
        activeInvoice: null,
        isFetchingActiveInvoices: false,
      };

    case GET_BUYER_ACTIVE_INVOICES_SILENT:
      return {
        ...state,
        isFetchingActiveInvoicesSilent: true,
      };
    case GET_BUYER_ACTIVE_INVOICES_SUCCESS_SILENT:
      return {
        ...state,
        activeInvoice: action.payload,
        computedCurrencyFilters: getCurrenciesFilters(
          action.payload.invoicesData
        ),
        isFetchingActiveInvoicesSilent: false,
      };
    case GET_BUYER_ACTIVE_INVOICES_FAIL_SILENT:
      return {
        ...state,
        activeInvoice: null,
        isFetchingActiveInvoicesSilent: false,
      };
    case EDITABLE_INVOICES_REQUEST:
      return {
        ...state,
        isFetchingEditableInvoices: true,
        editableInvoices: null,
        editableInvoicesFail: [],
      };
    case EDITABLE_INVOICES_SUCCESS:
      return {
        ...state,
        isFetchingEditableInvoices: false,
        editableInvoices: action.payload as BuyerEditableInvoiceDTO[],
        editableInvoicesFail: [],
      };
    case EDITABLE_INVOICES_FAIL:
      return {
        ...state,
        isFetchingEditableInvoices: false,
        editableInvoices: {} as BuyerEditableInvoiceDTO[],
        editableInvoicesFail: action.payload,
      };
    case CESSION_OPERATIONS_REQUEST:
      return {
        ...state,
        isFetchingCessionOperations: true,
        cessionOperations: null,
        cessionOperationsFail: [],
      };
    case CESSION_OPERATIONS_SUCCESS:
      return {
        ...state,
        isFetchingCessionOperations: false,
        cessionOperations: action.payload as BuyerCessionOperationsDTO[],
        cessionOperationsFail: [],
      };
    case CESSION_OPERATIONS_FAIL:
      return {
        ...state,
        isFetchingCessionOperations: false,
        cessionOperations: {} as BuyerCessionOperationsDTO[],
        cessionOperationsFail: action.payload,
      };
    case RETRIEVE_MONEYLENDERS_REQUEST:
      return {
        ...state,
        isFetchingRetrieveMoneylenders: true,
        retrieveMoneylenders: [],
        retrieveMoneylendersFail: [],
      };
    case RETRIEVE_MONEYLENDERS_SUCCESS:
      return {
        ...state,
        isFetchingCessionOperations: false,
        retrieveMoneylenders: action.payload as BuyerMoneylenderInfoDTO[],
        retrieveMoneylendersFail: [],
      };
    case RETRIEVE_MONEYLENDERS_FAIL:
      return {
        ...state,
        isFetchingCessionOperations: false,
        retrieveMoneylenders: [] as BuyerMoneylenderInfoDTO[],
        retrieveMoneylendersFail: action.payload,
      };
      case RETRIEVE_MONEYLENDER_INFO_REQUEST:
        return {
          ...state,
          isFetchingRetrieveMoneylenderInfo: true,
          retrieveMoneylenderInfo: null,
          retrieveMoneylenderInfoFail: [],
        };
      case RETRIEVE_MONEYLENDER_INFO_SUCCESS:
        return {
          ...state,
          isFetchingRetrieveMoneylenderInfo: false,
          retrieveMoneylenderInfo: action.payload as EditMoneylenderInfoDTO,
          retrieveMoneylenderInfoFail: [],
        };
      case RETRIEVE_MONEYLENDER_INFO_FAIL:
        return {
          ...state,
          isFetchingRetrieveMoneylenderInfo: false,
          retrieveMoneylenderInfo: null,
          retrieveMoneylenderInfoFail: action.payload,
        };
        case RETRIEVE_MONEYLENDER_CREDIT_LINE_REQUEST:
          return {
            ...state,
            isFetchingRetrieveMoneylenderInfo: true,
            retrieveMoneylenderCreditLine: null,
            retrieveMoneylenderCreditLineFail: [],
          };
        case RETRIEVE_MONEYLENDER_CREDIT_LINE_SUCCESS:
          return {
            ...state,
            isFetchingRetrieveMoneylenderInfo: false,
            retrieveMoneylenderCreditLine: action.payload as BuyerMoneyLenderCreditLineDTO[],
            retrieveMoneylenderCreditLineFail: [],
          };
        case RETRIEVE_MONEYLENDER_CREDIT_LINE_FAIL:
          return {
            ...state,
            isFetchingRetrieveMoneylenderInfo: false,
            retrieveMoneylenderCreditLine: null,
            retrieveMoneylenderCreditLineFail: action.payload,
          };
    case EDIT_MONEYLENDER_INFO_REQUEST:
      return {
        ...state,
        isFetchingEditMoneylenderInfo: true,
        editMoneylenderInfoResponse: [],
        editMoneylenderInfoFail: "",
      };

    case EDIT_MONEYLENDER_INFO_SUCCESS:
      return {
        ...state,
        isFetchingEditMoneylenderInfo: false,
        editMoneylenderInfoResponse: action.payload as EditMoneylenderInfoDTO[],
        editMoneylenderInfoFail: "",
      };

    case EDIT_MONEYLENDER_INFO_FAIL:
      return {
        ...state,
        isFetchingEditMoneylenderInfo: false,
        editMoneylenderInfoResponse: [],
        editMoneylenderInfoFail: action.payload,
      };
    default:
      return state;
  }
}
