import * as React from 'react';
import { ProgressBar } from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import "./MyProgressBar.scss";

interface Props {
    percentage: number;
}

const MyProgressBar: React.FC<Props> = ({percentage}) => {

    const {t} = useTranslation();

    return (
        <div className="progressBar" data-testid="progressBar">
            <div className="progressBar__title">
                {percentage }% {t("onBoarding.completed")}
            </div>
            <ProgressBar now={percentage}/>
        </div>
    );
};

export default MyProgressBar;

