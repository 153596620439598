export enum TicketTopicEnum {
    LOST_PASSWORD = "LOST_PASSWORD",
    SALE_INVOICE = "SALE_INVOICE",
    NEW_USER_INVITE = "NEW_USER_INVITE",
    SCF_PROGRAM = "SCF_PROGRAM",
    DYNAMIC_DISCOUNT = "DYNAMIC_DISCOUNT",
    TREASURY = "TREASURY",
    USERS = "USERS",
    ARCHIVE = "ARCHIVE",
    SIGNATURE = "SIGNATURE",
    CREDIT_LINE = "CREDIT_LINE",
    NO_PAYMENT = "NO_PAYMENT",
    OTHER = "OTHER"
}

export enum TicketStatus{
    OPEN = "OPEN",
    ASSIGNED = "ASSIGNED",
    CLOSED = "CLOSED"
}

export interface TicketRequestDTO {
    topic: TicketTopicEnum
    idProtocol?: string
    message: string
    solved?: boolean
}

export interface TicketDTO {
    companyVatNumber: string,
    businessName: string
    idProtocol: string,
    idProtocolNumber: string,
    topic: TicketTopicEnum,
    status: TicketStatus,
    chat: TicketChatMessageDTO[]
}

export interface TicketChatMessageDTO {
    author: string
    creationDate: Date
    email: string
    message: string
    role: string
}
