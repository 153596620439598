class ConfigService {

    getApiPath = () => {
        return process.env.REACT_APP_API_CLIENT_SERVER_URL;
    };

    isProdEnvironment = () => {
        return process.env.REACT_APP_ENVIRONMENT === 'production';
    };

    isDemo = () => {
        return process.env.REACT_APP_DEMO;
    };

    getInfoCertPath = () => {
        return process.env.REACT_APP_INFO_CERT_SERVER_URL;
    }

    getInfoCertSPIDPath = () => {
        return process.env.REACT_APP_INFO_CERT_SPID_SERVER_URL;
    }

    getInfoCertAuthorization = () => {
        return process.env.REACT_APP_INFO_CERT_AUTH;
    }

    getDefaultTenantId = () => {
        return process.env.REACT_APP_DEFAULT_TENANT_ID;
    }
}

export const configService = new ConfigService();
