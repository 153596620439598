import * as React from 'react';
import ContainerRow from "../../Common/ContainerRow/ContainerRow";
import MyDropdown from "../../Common/MyDropdown/MyDropdown";
import DatePicker, {DatePickerTypes} from "../../Common/DatePicker/DatePicker";
import pdf_icon from "../../../assets/Icons/pdf_icon.svg";
import document_upload from "../../../assets/Icons/document_upload.svg";
import {useTranslation} from "react-i18next";
import Button, {BUTTON_TYPE} from "../../Common/Button/Button";
import forward_arrow_right from "../../../assets/Icons/forward_arrow_right.svg";
import "../../../screens/onBoarding/company/definitionRole/OnBoardingDefinitionRole.scss";
import "../../Common/CardContainerWithButtons/CardContainerWithButtons.scss";
import {toastUtils} from "../../../utils/toastUtils";
import {setFile} from "../../Common/CustomUploadFile/CustomUploadFile";
import {DocumentTypes, LegalFilesDTO} from "../../../model/UserDTO";
import "./OnBoardingUploadPdf.scss";

const types = [
    DocumentTypes.STATUTE,
    DocumentTypes.COMMERCE_CERTIFICATE,
    DocumentTypes.DELEGATION_POWERS,
    DocumentTypes.ATTORNEY_POWERS,
];

const OnBoardingUploadPdf: React.FC<{
    [index: string]: any,
    uploadPdf: (data: LegalFilesDTO) => any
}> = ({
          uploadPdf,
          cancelUpload,
          error,
          resetError
      }) => {
    const {t} = useTranslation();
    const [openCalendar, setOpenCalendar] = React.useState(false);
    const [openCalendar1, setOpenCalendar1] = React.useState(false);
    const [pdfValues, setPdfValues] = React.useState<LegalFilesDTO>({} as LegalFilesDTO);

    const mySetFile = (event: any) => {
        resetError("");
        setFile(event,
            20971520,
            ["application/pdf"],
            (base64, name = "") => setPdfValues({...pdfValues, base64, name}),
            (error: string) => {
                toastUtils.warningToast(error);
                setPdfValues({...pdfValues, base64: "", name: ""} as LegalFilesDTO);
            }
        );
    };


    const disabledButton = !(
        pdfValues?.documentType &&
        pdfValues?.name &&
        (pdfValues?.documentType === DocumentTypes.STATUTE ? true : pdfValues?.documentExpiryDate) &&
        pdfValues?.documentDate &&
        pdfValues?.base64
    );

    return (
        <div className="uploadPDF__bottomSection" style={{marginBottom: "-80px"}}>
            <span className="uploadPDF__contentBottomSection">
                <div className="uploadPDF__pdfImageContainer" onChange={mySetFile}>
                    <input
                        style={{display: 'none', cursor: "pointer"}}
                        type="file"
                        id="file"
                    />
                    <label htmlFor="file">
                        {!pdfValues?.base64 ?
                            <>
                                <div className="uploadPDF__imgContainer">
                                    <img
                                        src={document_upload}
                                        alt="plus_icon_black"
                                        className="uploadPDF__pdfImage"
                                    />
                                </div>
                                <div className="uploadPDF__pdfName">
                                    {t("onBoarding.company.definitionRole.uploadDocument")}
                                </div>
                            </>
                            :
                            <>
                                <div className="uploadPDF__imgContainer">
                                    <img
                                        src={pdf_icon}
                                        alt="pdf_icon"
                                        className="uploadPDF__pdfImage"
                                    />
                                </div>
                                <div className="uploadPDF__pdfName">
                                    {pdfValues?.name}
                                </div>
                            </>
                        }
                    </label>
                </div>
                <span className="uploadPDF__contentRight">
                    <ContainerRow>
                        <div className="inputContainer">
                            <MyDropdown
                                links={types}
                                title={t("onBoarding.company.definitionRole.documentType")}
                                labelExtractor={(type: DocumentTypes) => t("onBoarding.company.definitionRole.docTypes." + type)}
                                inputValue={pdfValues.documentType}
                                onChange={(documentType) => setPdfValues({...pdfValues, documentType})}
                            />
                        </div>
                    </ContainerRow>
                    <ContainerRow>
                        <div className="inputContainer">
                            <DatePicker
                                title={t("onBoarding.company.definitionRole.documentData")}
                                dateInput={pdfValues.documentDate}
                                onDateChange={(documentDate) => setPdfValues({...pdfValues, documentDate})}
                                setOpenCalendar={({focused}) => setOpenCalendar(focused as boolean)}
                                openCalendar={openCalendar}
                                type={DatePickerTypes.TODAY_AND_PAST}
                                required
                            />
                        </div>
                        <div className="inputContainer">
                            <DatePicker
                                title={t("onBoarding.company.definitionRole.documentDataDeadline")}
                                dateInput={pdfValues.documentExpiryDate}
                                onDateChange={(documentExpiryDate) => setPdfValues({...pdfValues, documentExpiryDate})}
                                setOpenCalendar={({focused}) => setOpenCalendar1(focused as boolean)}
                                openCalendar={openCalendar1}
                                type={DatePickerTypes.FUTURE_NO_TODAY}
                                required
                            />
                        </div>
                    </ContainerRow>
                </span>
            </span>

            <div className="uploadPDF__containerButtons">
                {error &&
                <div className="cardContainerWithButtons__error uploadPDF__error">
                    {t(error)}
                </div>
                }
                <div
                    className="containerButtonConfirm containerButtonConfirm--marginRight20"
                >
                    <Button
                        label={t("common.button.abortButton")}
                        onClick={cancelUpload}
                        buttonType={BUTTON_TYPE.primaryInverted}
                        width={"100%"}
                    />
                </div>
                <div className="containerButtonConfirm uploadPDF__confirmButton">
                    <Button
                        label={t("onBoarding.company.definitionRole.uploadDocument")}
                        onClick={() => uploadPdf(pdfValues)}
                        icon={forward_arrow_right}
                        disabled={disabledButton}
                        width={"100%"}
                    />
                </div>
            </div>
        </div>
    );
};

export default OnBoardingUploadPdf;
