import * as React from 'react';
import pdf_icon from "../../../assets/Icons/pdf_icon.svg";
import "./DownloadPDF.scss";
import {downloadDocument} from "../../../utils/commonUtils";
import Spinner from "../Spinner/Spinner";
import {INFO_CERT_SPID_URL, INFO_CERT_URL,} from "../../../service/InfoCertService";
import {InfoCertDocumentDownload, InfocertDossierType} from "../../../model/InfoCertDTO";

const DownloadPDF: React.FC<{ fileData?: any, infocertDocument?: InfoCertDocumentDownload, actualDossierType?: InfocertDossierType | undefined }> =
    ({fileData, infocertDocument, actualDossierType}) => {
        const [busy, setBusy] = React.useState<boolean>(false);
        let {name} = fileData || infocertDocument || {};
        let href;
        if (infocertDocument?.url) href = (actualDossierType === InfocertDossierType.SPID ? INFO_CERT_SPID_URL : INFO_CERT_URL) + infocertDocument.url;
        if (infocertDocument?.base64) href = infocertDocument.base64;
        return (
            <div key={name} style={{position: "relative", cursor: "pointer"}}>
                {busy && <Spinner/>}
                <a
                    download={name}
                    onClick={async () => {
                        if (fileData) {
                            setBusy(true);
                            await downloadDocument(fileData);
                            setBusy(false);
                        }
                    }}
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className="downloadPDF__downloadContainer">
                        <img src={pdf_icon} alt="pdf_icon"/>
                        <div className="downloadPDF__downloadTitle">{name?.split("_")[0]}</div>
                    </div>
                </a>
            </div>
        );
    };

export default DownloadPDF;

