import {networkService} from "./NetworkService";
import {CompanyRoleData, LegalDataDTORequest, LegalFilesDTO, OnboardedUserDataDTO, OtherFilesDTORequest, UpdateOtherDataRequest, UpdateRoleRequestDTO, UpdateStepRequest, UserDataDTO, UserFilesDTO, UserFilesDTORequest, UserInfoDTO, UserManagementDTO} from "../model/UserDTO";
import {MyResponse} from "../model/ServiceDTO";
import {configService} from "./ConfigService";

const BASE_URL = configService.getApiPath();


class UserService {

    updateUserData = (data: UserDataDTO): Promise<MyResponse<number>> => {
        return networkService.post<UserDataDTO, number>(BASE_URL + "/user", data);
    };

    userInfo = (): Promise<MyResponse<UserInfoDTO[]>> => {
        return networkService.get<UserInfoDTO[]>(BASE_URL + "/user/userInfo");
    };

    getVersion = (): Promise<MyResponse<string>> => {
        return networkService.get<string>(BASE_URL + "/config/version");
    };

    setOnBoardingStep = (data: UpdateStepRequest): Promise<MyResponse<UpdateStepRequest>> => {
        return networkService.post<UpdateStepRequest, UpdateStepRequest>(BASE_URL + "/user/step", data);
    };

    setTermsCondition = (): Promise<MyResponse<string>> => {
        return networkService.put<any, any>(BASE_URL + "/user/termsCondition", null);
    };

    updateUserFiles = (data: UserFilesDTORequest): Promise<MyResponse<number>> => {
        return networkService.post<UserFilesDTORequest, number>(BASE_URL + "/user/userFiles", data, undefined, undefined, 240000);
    };

    getUserFiles = (): Promise<MyResponse<UserFilesDTO>> => {
        return networkService.get<UserFilesDTO>(BASE_URL + "/user/userFiles");
    };

    getUserData = (userId: string) => {
        return networkService.get<OnboardedUserDataDTO>(`${BASE_URL}/user/onboarded/${userId}`);
    };

    getLegalRepresentativeFiles = (): Promise<MyResponse<UserFilesDTO>> => {
        return networkService.get<UserFilesDTO>(BASE_URL + "/user/userFiles/legalRepresentative");
    };

    updateLegalFiles = (data: LegalFilesDTO, vat?: string): Promise<MyResponse<number>> => {
        return networkService.post<LegalFilesDTO, number>(`${BASE_URL}/user/legalFiles${vat ? `/${vat}` : ""}`, data, undefined, undefined, 240000);
    };

    getLegalFiles = (vat?: string): Promise<MyResponse<LegalFilesDTO[]>> => {
        return networkService.get<LegalFilesDTO[]>(`${BASE_URL}/user/legalFiles${vat ? `/${vat}` : ""}`);
    };

    updateLegalData = (data: LegalDataDTORequest): Promise<MyResponse<number>> => {
        return networkService.post<LegalDataDTORequest, number>(BASE_URL + "/user/legalData", data);
    };

    deleteLegalFile = (data: LegalFilesDTO): Promise<MyResponse<number>> => {
        return networkService.post<LegalFilesDTO, number>(BASE_URL + "/user/legalFiles/delete", data);
    };

    uploadOtherUserFiles = (data: OtherFilesDTORequest): Promise<MyResponse<number>> => {
        return networkService.post<OtherFilesDTORequest, number>(BASE_URL + "/user/otherUserFiles", data);
    };

    uploadOtherUserData = (data: UpdateOtherDataRequest): Promise<MyResponse<number>> => {
        return networkService.post<UpdateOtherDataRequest, number>(BASE_URL + "/user/otherUserData", data);
    };
    getAllUsers = (): Promise<MyResponse<Array<UserManagementDTO>>> => {
        return networkService.get<UserManagementDTO[]>(BASE_URL + "/user/userRoles");
    };

    updateUserRole = (data: UpdateRoleRequestDTO): Promise<MyResponse<any>> => {
        return networkService.post<any, UpdateRoleRequestDTO>(BASE_URL + "/user/userRoles", data);
    };

    switchUserRole = (data: CompanyRoleData): Promise<MyResponse<any>> => {
        return networkService.post<any, CompanyRoleData>(BASE_URL + "/user/switchCompany", data);
    };

}

export const userService = new UserService();
