import * as React from 'react';
import {AppState} from "../../../redux/store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Roles} from "../../../model/UserDTO";
import ScreenWrapper from "../../../components/AdminComponent/ScreenWrapper/ScreenWrapper";
import Table, {TableHeader} from "../../../components/Common/Table/Table";
import NoDataBox from "../../../components/Common/NoDataBox/NoDataBox";
import {getCompanyList} from "../../../redux/company";
import {CompanyDTO} from "../../../model/CompanyDTO";
import {countryUtils} from "../../../utils/countryUtils";
import proceed from "../../../assets/Icons/proceed.svg";
import DocumentSection from "./DocumentSection/DocumentSection";
import "../../common/actionRequests/ActionRequests.scss";
import {setAdminActionDispatcher} from "../../../redux/admin/admin-actions";
import "../../../components/Common/Table/Table.scss";
import Ellapsis from "../../../components/Common/Ellapsis/Ellapsis";

export interface Props {
    role: Roles,
    companyList: CompanyDTO[]
    isFetchingGetCompanyList: boolean,
    [index: string]: any
}

const styles = {
    businessName: {flex: 1},
    vatNumber: {flex: "none", width: "14em"},
    businessCountry: {flex: "none", width: "14em"},
    button: {flex: "none", width: "40px"},
};

export enum admin_archive_step {
    ARCHIVE = "archive",
    DOCUMENT_SECTION = "documentSection",
}

const AdminArchive: React.FC<Props> = ({
                                           role,
                                           getCompanyList,
                                           companyList,
                                           isFetchingGetCompanyList,
                                           setAdminActionDispatcher,
                                           adminArchiveStep
                                       }) => {

    const {t} = useTranslation();
    const [vatNumber, setVatNumber] = React.useState<boolean>(false);
    const [name, setName] = React.useState<string>();

    React.useEffect(() => {
        getCompanyList(role);
    }, [getCompanyList, role]);

    const headers: TableHeader[] = [
        {
            label: t("admin.adminArchive.businessName"),
            sortingAccessor: ({businessName}: any) => businessName,
            isPrimary: true,
            style: styles.businessName,
            isDefault: true
        },
        {
            label: t("admin.adminArchive.vatNumber"),
            style: styles.vatNumber,
            sortingAccessor: ({vatNumber}: any) => vatNumber,
        },
        {
            label: t("admin.adminArchive.country"),
            sortingAccessor: ({businessCountry}: any) => businessCountry,
            style: styles.businessCountry,
        },
        {style: styles.button}
    ];

    const renderTableRow = (item: any, index: number) => {
        const {businessName, vatNumber, businessCountry} = item;
        return (
            <div key={index}>
                <div key={index} className="commonTableRow">
                    <span className="table__item" style={styles.businessName}>
                        <Ellapsis title={businessName}>{businessName}</Ellapsis>
                    </span>
                    <span className="table__item" style={styles.vatNumber}>
                        <Ellapsis title={vatNumber || "-"}>{vatNumber || "-"}</Ellapsis>
                    </span>
                    <span className="table__item" style={styles.businessCountry}>
                        <Ellapsis title={businessCountry}>{businessCountry}</Ellapsis>
                    </span>
                    <span className="table__item" style={styles.button}>
                        <img
                            style={{cursor: "pointer"}}
                            width="28px"
                            src={proceed}
                            alt="proceed"
                            onClick={() => {
                                setAdminActionDispatcher(admin_archive_step.DOCUMENT_SECTION);
                                setVatNumber(vatNumber);
                                setName(businessName);
                            }}
                        />
                    </span>
                </div>
                <div className="table__line table__line--withMargin" style={{opacity: 0.2}}/>
            </div>
        );
    };

    const searchableCompanyList = companyList.map(({businessCountry, ...rest}: CompanyDTO, index: number) => ({
        id: {value: index + ""},
        businessCountry: countryUtils.fromCodeToCountryName(businessCountry),
        ...rest
    }));

    return (
        adminArchiveStep === admin_archive_step.ARCHIVE ?
            <ScreenWrapper title={t("admin.adminArchive." + role)}>
                <Table
                    searchBarStyle={{display: "flex", flex: 1}}
                    noItemsRender={
                        ((companyList && companyList.length === 0) && isFetchingGetCompanyList === false) &&
                        <NoDataBox noDataTitle={t("admin.blockUser.noUsers")}/>
                    }
                    searchableListToDisplay={searchableCompanyList}
                    accessorId={(item: any) => item.index}
                    defaultSortingFn={(a: any, b: any) => a.businessName > b.businessName ? 1 : -1}
                    renderItem={renderTableRow}
                    headers={headers}
                    className="shadowCard"
                    itemForPage={15}
                    headerClassNameModifier="table__header--actions"
                    headerItemModifier="table__headerItem--actions"
                />
            </ScreenWrapper>
            :
            <DocumentSection
                vatNumber={vatNumber}
                role={role}
                title={t(`admin.adminArchive.${role}`) + " > " + name}
                onPreviousButtonClick={() => setAdminActionDispatcher(admin_archive_step.ARCHIVE)}
            />
    );
};

const mapStateToProps = (store: AppState) => {
    return {
        companyList: store.company.companyList,
        isFetchingGetCompanyList: store.company.isFetchingGetCompanyList,
        adminArchiveStep: store.admin.adminActionDispatcher
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        getCompanyList: (role: Roles) => dispatch(getCompanyList(role)),
        setAdminActionDispatcher: (step: string) => dispatch(setAdminActionDispatcher(step))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminArchive);
