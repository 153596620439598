import { AdminAction } from './admin-interface';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  CompanyInfoSteps, EsgConfigurationDTO,
  InviteCustomerDTORequest,
  PendingUserRequest,
  UsersSteps,
} from '../../model/AdminDTO';
import {
  BLOCK_COMPANIES_FAIL,
  BLOCK_COMPANIES_REQUEST,
  BLOCK_COMPANIES_SUCCESS,
  BLOCK_USER_FAIL,
  BLOCK_USER_REQUEST,
  BLOCK_USER_SUCCESS,
  CASH_IMPROVEMENT_TARIFF_FAIL,
  CASH_IMPROVEMENT_TARIFF_REQUEST,
  CASH_IMPROVEMENT_TARIFF_SUCCESS,
  CLEAR_TARIFF_ERROR,
  COMPANY_INFO_STEP, DELETE_SAP_ERROR, DELETE_SAP_ERROR_FAIL, DELETE_SAP_ERROR_SUCCESS,
  GET_ADMIN_INVOICES,
  GET_ADMIN_INVOICES_FAIL,
  GET_ADMIN_INVOICES_FAIL_SILENT,
  GET_ADMIN_INVOICES_SILENT,
  GET_ADMIN_INVOICES_SUCCESS,
  GET_ADMIN_INVOICES_SUCCESS_SILENT,
  GET_BLOCKED_USERS,
  GET_BLOCKED_USERS_FAIL,
  GET_BLOCKED_USERS_SUCCESS,
  GET_COMPANIES_FAIL,
  GET_COMPANIES_REQUEST,
  GET_COMPANIES_SUCCESS,
  GET_CUSTOMERS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  GET_ONBOARDING_STATUS,
  GET_ONBOARDING_STATUS_FAIL,
  GET_ONBOARDING_STATUS_SUCCESS,
  GET_SAP_ERROR,
  GET_SAP_ERROR_FAIL,
  GET_SAP_ERROR_SUCCESS,
  GET_SAP_ERROR_SUCCESS_SILENT,
  GET_SIGNERS,
  GET_SIGNERS_FAIL,
  GET_SIGNERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  INVITE_CUSTOMER_FAIL,
  INVITE_CUSTOMER_REQUEST,
  INVITE_CUSTOMER_SUCCESS,
  NEW_USER_STEP,
  REFUSE_PENDING,
  REFUSE_PENDING_FAIL,
  REFUSE_PENDING_SUCCESS,
  RETRY_SAP_ERROR,
  RETRY_SAP_ERROR_FAIL,
  RETRY_SAP_ERROR_SUCCESS,
  SET_ADMIN_ACTION_DISPATCHER,
  SET_LAST_DATE_SAP_ARRIVAL,
  SET_LAST_DATE_SAP_ARRIVAL_HIGHLIGHT,
  SET_ROLE,
  SET_TARIFF_FAIL,
  SET_TARIFF_REQUEST,
  SET_TARIFF_SUCCESS,
  STOCK_CREDIT_LINES_FAIL,
  STOCK_CREDIT_LINES_REQUEST,
  STOCK_CREDIT_LINES_SUCCESS,
  TARIFF_FAIL,
  TARIFF_REQUEST,
  TARIFF_SUCCESS,
  UNBLOCK_COMPANIES_FAIL,
  UNBLOCK_COMPANIES_REQUEST,
  UNBLOCK_COMPANIES_SUCCESS,
  UNBLOCK_PENDING,
  UNBLOCK_PENDING_FAIL,
  UNBLOCK_PENDING_SUCCESS,
  UNBLOCK_USER_FAIL,
  UNBLOCK_USER_REQUEST,
  UNBLOCK_USER_SUCCESS,
  VENDORS_WITHOUT_RELATIONS,
  VENDORS_WITHOUT_RELATIONS_FAIL,
  VENDORS_WITHOUT_RELATIONS_SUCCESS,
  GET_RATING_ESG,
  GET_RATING_ESG_FAIL,
  GET_RATING_ESG_SUCCESS,
  SET_RATING_ESG_FAIL,
  SET_RATING_ESG_REQUEST,
  SET_RATING_ESG_SUCCESS,
} from './admin-reducers';
import { handleResponse } from '../reduxUtils';
import { adminService } from '../../service/AdminService';
import { companyService } from '../../service/CompanyService';
import { TariffDTO } from '../../model/CompanyDTO';
import { Roles } from '../../model/UserDTO';

export const inviteCustomer =
  (data: InviteCustomerDTORequest): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    dispatch({ type: INVITE_CUSTOMER_REQUEST, payload: null });
    const response = adminService.inviteCustomer(data);
    return await handleResponse(
      dispatch,
      INVITE_CUSTOMER_SUCCESS,
      INVITE_CUSTOMER_FAIL,
      response
    );
  };

export const getBlockedUsers =
  (): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    dispatch({ type: GET_BLOCKED_USERS, payload: null });
    const response = adminService.getBlockedUsers();
    return handleResponse(
      dispatch,
      GET_BLOCKED_USERS_SUCCESS,
      GET_BLOCKED_USERS_FAIL,
      response
    );
  };

export const unblockPending =
  (data: PendingUserRequest): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    dispatch({ type: UNBLOCK_PENDING, payload: null });
    const response = adminService.unblockUser(data);
    return await handleResponse(
      dispatch,
      UNBLOCK_PENDING_SUCCESS,
      UNBLOCK_PENDING_FAIL,
      response
    );
  };

export const refuseUser =
  (data: PendingUserRequest): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    dispatch({ type: REFUSE_PENDING, payload: null });
    const response = adminService.refuseUser(data);
    return await handleResponse(
      dispatch,
      REFUSE_PENDING_SUCCESS,
      REFUSE_PENDING_FAIL,
      response
    );
  };

export const getSigners =
  (): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    dispatch({ type: GET_SIGNERS, payload: null });
    const response = adminService.getSigners();
    return await handleResponse(
      dispatch,
      GET_SIGNERS_SUCCESS,
      GET_SIGNERS_FAIL,
      response
    );
  };
export const getCustomers =
  (): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    dispatch({ type: GET_CUSTOMERS, payload: null });
    const response = adminService.getCustomers();
    return await handleResponse(
      dispatch,
      GET_CUSTOMERS_SUCCESS,
      GET_CUSTOMERS_FAIL,
      response
    );
  };

export const setNewUserStep =
  (payload: UsersSteps | null) =>
  (dispatch: ThunkDispatch<{}, {}, AdminAction>) => {
    dispatch({ type: NEW_USER_STEP, payload });
  };

export const setCompanyInfoStep = (payload: CompanyInfoSteps | null) => ({
  type: COMPANY_INFO_STEP,
  payload,
});

export const getOnBoardingStatus =
  (): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    dispatch({ type: GET_ONBOARDING_STATUS, payload: null });
    const response = adminService.getOnBoardingStatus();
    return await handleResponse(
      dispatch,
      GET_ONBOARDING_STATUS_SUCCESS,
      GET_ONBOARDING_STATUS_FAIL,
      response
    );
  };

export const setRole =
  (payload: string) => (dispatch: ThunkDispatch<{}, {}, AdminAction>) => {
    dispatch({ type: SET_ROLE, payload });
  };

export const clearInviteCustomerResponse =
  (payload: string) => (dispatch: ThunkDispatch<{}, {}, AdminAction>) => {
    dispatch({ type: INVITE_CUSTOMER_FAIL, payload });
  };

export const getTariff =
  (vatNumber: string, role: Roles): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    dispatch({ type: TARIFF_REQUEST, payload: null });
    const response = adminService.getTariff(vatNumber, role);
    return await handleResponse(
      dispatch,
      TARIFF_SUCCESS,
      TARIFF_FAIL,
      response
    );
  };

export const getCashImprovementTariff =
  (vatNumber: string): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    dispatch({ type: CASH_IMPROVEMENT_TARIFF_REQUEST, payload: null });
    const response = adminService.getCashImprovementTariff(vatNumber);
    return await handleResponse(
      dispatch,
      CASH_IMPROVEMENT_TARIFF_SUCCESS,
      CASH_IMPROVEMENT_TARIFF_FAIL,
      response
    );
  };

export const setTariff =
  (
    data: TariffDTO[],
    vatNumber: string,
    role: Roles
  ): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    dispatch({ type: SET_TARIFF_REQUEST, payload: null });
    const response = adminService.setTariff(data, vatNumber, role);
    return await handleResponse(
      dispatch,
      SET_TARIFF_SUCCESS,
      SET_TARIFF_FAIL,
      response
    );
  };

export const updateTariff =
  (data: any): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    dispatch({ type: SET_TARIFF_REQUEST, payload: null });
    const response = adminService.updateTariff(data);
    return await handleResponse(
      dispatch,
      SET_TARIFF_SUCCESS,
      SET_TARIFF_FAIL,
      response
    );
  };

export const getUsers =
  (vat?: string): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    dispatch({ type: GET_USERS_REQUEST, payload: null });
    const response = companyService.getCompanyUsers(vat);
    return await handleResponse(
      dispatch,
      GET_USERS_SUCCESS,
      GET_USERS_FAIL,
      response
    );
  };

export const blockUser =
  (userId: string, vat: string): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    dispatch({ type: BLOCK_USER_REQUEST, payload: null });
    const response = companyService.blockUser(userId, vat);
    return await handleResponse(
      dispatch,
      BLOCK_USER_SUCCESS,
      BLOCK_USER_FAIL,
      response
    );
  };

export const unblockUser =
  (userId: string, vat: string): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    dispatch({ type: UNBLOCK_USER_REQUEST, payload: null });
    const response = companyService.unblockUser(userId, vat);
    return await handleResponse(
      dispatch,
      UNBLOCK_USER_SUCCESS,
      UNBLOCK_USER_FAIL,
      response
    );
  };

export const getCompanies =
  (): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    dispatch({ type: GET_COMPANIES_REQUEST, payload: null });
    const response = companyService.getCompanies();
    return await handleResponse(
      dispatch,
      GET_COMPANIES_SUCCESS,
      GET_COMPANIES_FAIL,
      response
    );
  };

export const blockCompany =
  (vatNumber: string): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    dispatch({ type: BLOCK_COMPANIES_REQUEST, payload: null });
    const response = companyService.blockCompany(vatNumber);
    return await handleResponse(
      dispatch,
      BLOCK_COMPANIES_SUCCESS,
      BLOCK_COMPANIES_FAIL,
      response
    );
  };

export const unblockCompany =
  (vatNumber: string): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    dispatch({ type: UNBLOCK_COMPANIES_REQUEST, payload: null });
    const response = companyService.unblockCompany(vatNumber);
    return await handleResponse(
      dispatch,
      UNBLOCK_COMPANIES_SUCCESS,
      UNBLOCK_COMPANIES_FAIL,
      response
    );
  };

export const getStockCreditLines =
  (): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    dispatch({ type: STOCK_CREDIT_LINES_REQUEST, payload: null });
    const response = adminService.stockCreditLines();
    return await handleResponse(
      dispatch,
      STOCK_CREDIT_LINES_SUCCESS,
      STOCK_CREDIT_LINES_FAIL,
      response
    );
  };
export const vendorWithoutRelations =
  (): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    dispatch({ type: VENDORS_WITHOUT_RELATIONS, payload: null });
    const response = adminService.vendorWithoutRelations();
    return await handleResponse(
      dispatch,
      VENDORS_WITHOUT_RELATIONS_SUCCESS,
      VENDORS_WITHOUT_RELATIONS_FAIL,
      response
    );
  };

export const getSapErrors =
  (
    fromDate?: Date,
    toDate?: Date,
    quantity?: number
  ): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    dispatch({ type: GET_SAP_ERROR, payload: null });
    const response = adminService.getSapErrors(fromDate, toDate, quantity);
    return await handleResponse(
      dispatch,
      GET_SAP_ERROR_SUCCESS,
      GET_SAP_ERROR_FAIL,
      response
    );
  };

export const retrySapErrors =
  (taskId: number): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    dispatch({ type: RETRY_SAP_ERROR, payload: null });
    const response = adminService.retrySapErrors(taskId);
    return await handleResponse(
      dispatch,
      RETRY_SAP_ERROR_SUCCESS,
      RETRY_SAP_ERROR_FAIL,
      response
    );
  };

export const deleteSapErrors =
  (taskId: number): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    dispatch({ type: DELETE_SAP_ERROR, payload: null });
    const response = adminService.deleteSapErrors(taskId);
    return await handleResponse(
      dispatch,
      DELETE_SAP_ERROR_SUCCESS,
      DELETE_SAP_ERROR_FAIL,
      response
    );
  };

export const getAdminActiveInvoices =
  (isSilent = false): ThunkAction<void, {}, {}, AdminAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AdminAction>) => {
    dispatch({
      type: isSilent ? GET_ADMIN_INVOICES_SILENT : GET_ADMIN_INVOICES,
      payload: null,
    });
    const responsePromise = adminService.getAdminInvoices();
    return await handleResponse(
      dispatch,
      isSilent ? GET_ADMIN_INVOICES_SUCCESS_SILENT : GET_ADMIN_INVOICES_SUCCESS,
      isSilent ? GET_ADMIN_INVOICES_FAIL_SILENT : GET_ADMIN_INVOICES_FAIL,
      responsePromise
    );
  };

//silent aggiorna only date
export const getSapErrorsSilent =
  (
    fromDate?: Date,
    toDate?: Date,
    quantity?: number
  ): ThunkAction<void, {}, {}, AdminAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AdminAction>
  ): Promise<AdminAction> => {
    const response = adminService.getSapErrors(fromDate, toDate, quantity);
    return await handleResponse(
      dispatch,
      GET_SAP_ERROR_SUCCESS_SILENT,
      GET_SAP_ERROR_FAIL,
      response,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
  };
export const setAdminActionDispatcher = (step: string) => {
  return { type: SET_ADMIN_ACTION_DISPATCHER, payload: step };
};

export const setLastErrorTimestamp = (lastDateSapArrival: number) => {
  return { type: SET_LAST_DATE_SAP_ARRIVAL, payload: lastDateSapArrival };
};

export const setLastErrorTimestampForHighlight = (
  lastDateSapArrival: number
) => {
  return {
    type: SET_LAST_DATE_SAP_ARRIVAL_HIGHLIGHT,
    payload: lastDateSapArrival,
  };
};

export const clearTariffError = () => {
  return { type: CLEAR_TARIFF_ERROR, payload: '' };
};

export const getRatingESG =
  (): ThunkAction<void, {}, {}, AdminAction> =>
      async (dispatch: ThunkDispatch<{}, {}, AdminAction>) => {
        dispatch({
          type: GET_RATING_ESG,
          payload: null,
        });
        const responsePromise = adminService.getRatingESG();
        return await handleResponse(
            dispatch,
            GET_RATING_ESG_SUCCESS,
            GET_RATING_ESG_FAIL,
            responsePromise
        );
      };

export const setRatingEsg =
    (
        data: EsgConfigurationDTO,
    ): ThunkAction<void, {}, {}, AdminAction> =>
        async (
            dispatch: ThunkDispatch<{}, {}, AdminAction>
        ): Promise<AdminAction> => {
          dispatch({ type: SET_RATING_ESG_REQUEST, payload: null });
          const response = adminService.setRatingEsg(data);
          return await handleResponse(
              dispatch,
              SET_RATING_ESG_SUCCESS,
              SET_RATING_ESG_FAIL,
              response
          );
        };

export const setRatingEsgDelete =
    (
        id: number
    ): ThunkAction<void, {}, {}, AdminAction> =>
        async (
            dispatch: ThunkDispatch<{}, {}, AdminAction>
        ): Promise<AdminAction> => {
          dispatch({type: SET_RATING_ESG_REQUEST, payload: null});
          const response = adminService.setRatingEsgDelete(id);
          return await handleResponse(
              dispatch,
              SET_RATING_ESG_SUCCESS,
              SET_RATING_ESG_FAIL,
              response
          );
        };
