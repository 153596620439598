import {DynamicDiscountAction, DynamicDiscountState} from "./dynamicDiscount-interface";
import {DynamicDiscountDTO, OperationDTO} from "../../model/DynamicDiscountDTO";

export const GET_DYNAMIC_DISCOUNT = 'getDynamicDiscountAction';
export const GET_DYNAMIC_DISCOUNT_SUCCESS = 'getDynamicDiscountSuccessAction';
export const GET_DYNAMIC_DISCOUNT_FAIL = 'getDynamicDiscountFailAction';
export const SET_DYNAMIC_DISCOUNT_ERROR = 'setErrorAction';
export const GET_DYNAMIC_DISCOUNT_DETAILS = 'getDynamicDiscountDetails';
export const GET_DYNAMIC_DISCOUNT_DETAILS_SUCCESS = 'getDynamicDiscountDetailsSuccess';
export const GET_DYNAMIC_DISCOUNT_DETAILS_FAIL = 'getDynamicDiscountDetailsFail';
export const CLEAR_DYNAMIC_DISCOUNT_DETAILS = 'clearDynamicDiscountDetails';
export const GET_OPERATIONS = 'getOperations';
export const GET_OPERATIONS_SUCCESS = 'getOperationsSuccess';
export const GET_OPERATIONS_FAIL = 'getOperationsFail';
export const RESET_DYNAMIC_DISCOUNT = "resetDynamicDiscount";

const defaultState: DynamicDiscountState = {
    allDynamicDiscountResponse: "",
    isFetchingAllDynamicDiscount: null,
    error: '',
    currencyFilters: [],
    isFetchingGetDynamicDiscountDetails: false,
    dynamicDiscountDetailsResponse: "",
    dynamicDiscountDetailsFail: "",
    isFetchingGetOperationsResponse: false,
    getOperationsSuccess: null,
    getOperationsFail: "",
};

export const onlyUniqueCurrency = (value: string, index: number, self: string[]) => (self.indexOf(value as never) === index);

export function dynamicDiscountReducer(state = defaultState, action: DynamicDiscountAction): DynamicDiscountState {
    switch (action.type) {
        case GET_DYNAMIC_DISCOUNT:
            return {
                ...state,
                isFetchingAllDynamicDiscount: true,
            };
        case GET_DYNAMIC_DISCOUNT_SUCCESS:
            const currencyFilters = action.payload && action.payload.map((item: DynamicDiscountDTO) => item.currency).filter(onlyUniqueCurrency);
            return {
                ...state,
                currencyFilters,
                error: "",
                allDynamicDiscountResponse: action.payload,
                isFetchingAllDynamicDiscount: false,
            };
        case GET_DYNAMIC_DISCOUNT_FAIL:
            return {
                ...state,
                error: action.payload,
                allDynamicDiscountResponse: [],
                isFetchingAllDynamicDiscount: false,
            };

        case GET_DYNAMIC_DISCOUNT_DETAILS:
            return {
                ...state,
                isFetchingGetDynamicDiscountDetails: true,
                dynamicDiscountDetailsResponse: "",
                dynamicDiscountDetailsFail: ""
            };

        case GET_DYNAMIC_DISCOUNT_DETAILS_SUCCESS:
            return {
                ...state,
                isFetchingGetDynamicDiscountDetails: false,
                dynamicDiscountDetailsResponse: action.payload,
                dynamicDiscountDetailsFail: ""
            };

        case GET_DYNAMIC_DISCOUNT_DETAILS_FAIL:
            return {
                ...state,
                isFetchingGetDynamicDiscountDetails: false,
                dynamicDiscountDetailsResponse: "",
                dynamicDiscountDetailsFail: action.payload
            };

        case SET_DYNAMIC_DISCOUNT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case CLEAR_DYNAMIC_DISCOUNT_DETAILS:
            return {
                ...state,
                dynamicDiscountDetailsResponse: action.payload,
            };

        case GET_OPERATIONS:
            return {
                ...state,
                isFetchingGetOperationsResponse: true,
                getOperationsSuccess: null,
                getOperationsFail: ""
            };

        case GET_OPERATIONS_SUCCESS:
            return {
                ...state,
                isFetchingGetOperationsResponse: false,
                getOperationsSuccess: action.payload as OperationDTO[] | null,
                getOperationsFail: ""
            };
        case GET_OPERATIONS_FAIL:
            return {
                ...state,
                isFetchingGetOperationsResponse: false,
                getOperationsSuccess: null,
                getOperationsFail: action.payload
            };

        case RESET_DYNAMIC_DISCOUNT:
            return {
                ...state,
                ...defaultState,
            };
        default:
            return state;
    }
}
