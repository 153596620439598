import { buyerService } from "../../service/BuyerService";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { handleResponse } from "../reduxUtils";
import {
  BuyerAction,
  GET_BUYERS_AMOUNT_INVOICES,
  GET_BUYERS_INVOICES,
  GET_BUYERS_INVOICES_DELAYED,
  GET_BUYERS_INVOICES_DELAYED_REQUEST,
  GET_BUYERS_INVOICES_FAIL,
  GET_BUYERS_INVOICES_REQUEST,
  GET_DYNAMIC_DISCOUNT_ASSOCIATED,
  GET_DYNAMIC_DISCOUNT_ASSOCIATED_FAIL,
  GET_DYNAMIC_DISCOUNT_ASSOCIATED_SUCCESS,
  GET_CURRENCIES,
  GET_CURRENCIES_FAIL,
  GET_CURRENCIES_REQUEST,
  GET_BUYER_ACTIVE_INVOICES_SILENT,
  GET_BUYER_ACTIVE_INVOICES,
  GET_BUYER_ACTIVE_INVOICES_SUCCESS,
  GET_BUYER_ACTIVE_INVOICES_SUCCESS_SILENT,
  GET_BUYER_ACTIVE_INVOICES_FAIL_SILENT,
  GET_BUYER_ACTIVE_INVOICES_FAIL,
  BUYER_NEW_COMPANY_STEP,
  EDITABLE_INVOICES_REQUEST,
  EDITABLE_INVOICES_SUCCESS,
  EDITABLE_INVOICES_FAIL,
  CESSION_OPERATIONS_REQUEST,
  CESSION_OPERATIONS_SUCCESS,
  CESSION_OPERATIONS_FAIL,
  RETRIEVE_MONEYLENDERS_REQUEST,
  RETRIEVE_MONEYLENDERS_SUCCESS,
  RETRIEVE_MONEYLENDERS_FAIL,
  RETRIEVE_MONEYLENDER_INFO_REQUEST,
  RETRIEVE_MONEYLENDER_INFO_SUCCESS,
  RETRIEVE_MONEYLENDER_INFO_FAIL,
  EDIT_MONEYLENDER_INFO_REQUEST,
  EDIT_MONEYLENDER_INFO_FAIL,
  EDIT_MONEYLENDER_INFO_SUCCESS,
  RETRIEVE_MONEYLENDER_CREDIT_LINE_REQUEST,
  RETRIEVE_MONEYLENDER_CREDIT_LINE_SUCCESS,
  RETRIEVE_MONEYLENDER_CREDIT_LINE_FAIL,
} from "./buyer-interface";
import { BuyerInvoicesSelectedMethod, EditMoneylenderInfoDTO } from "../../model/BuyerDTO";
import { BuyerNewCompanySteps } from "../../model/AdminDTO";

export const getInvoicesCurrencies =
  (): ThunkAction<void, {}, {}, BuyerAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, BuyerAction>
  ): Promise<BuyerAction> => {
    dispatch({ type: GET_CURRENCIES_REQUEST, payload: "" });
    const responsePromise = buyerService.getInvoiceCurrencies();
    return await handleResponse(
      dispatch,
      GET_CURRENCIES,
      GET_CURRENCIES_FAIL,
      responsePromise
    );
  };

export const getDynamicDiscountAssociated =
  (): ThunkAction<void, {}, {}, BuyerAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, BuyerAction>
  ): Promise<BuyerAction> => {
    dispatch({ type: GET_DYNAMIC_DISCOUNT_ASSOCIATED, payload: null });
    const responsePromise = buyerService.getDynamicDiscountAssociated();
    return await handleResponse(
      dispatch,
      GET_DYNAMIC_DISCOUNT_ASSOCIATED_SUCCESS,
      GET_DYNAMIC_DISCOUNT_ASSOCIATED_FAIL,
      responsePromise
    );
  };

export const getBuyerInvoices =
  (
    selectionMethod: BuyerInvoicesSelectedMethod,
    amount?: number,
    currency?: string
  ): ThunkAction<void, {}, {}, BuyerAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, BuyerAction>
  ): Promise<BuyerAction> => {
    dispatch({
      type:
        selectionMethod === BuyerInvoicesSelectedMethod.SELECTION_METHOD_NORMAL
          ? GET_BUYERS_INVOICES_REQUEST
          : GET_BUYERS_INVOICES_DELAYED_REQUEST,
      payload: "",
    });
    const responsePromise = buyerService.getInvoices(
      selectionMethod,
      amount,
      currency
    );
    const successType =
      (selectionMethod ===
        BuyerInvoicesSelectedMethod.SELECTION_METHOD_AMOUNT &&
        GET_BUYERS_AMOUNT_INVOICES) ||
      (selectionMethod ===
        BuyerInvoicesSelectedMethod.SELECTION_METHOD_DELAYED &&
        GET_BUYERS_INVOICES_DELAYED) ||
      GET_BUYERS_INVOICES;
    return await handleResponse(
      dispatch,
      successType,
      GET_BUYERS_INVOICES_FAIL,
      responsePromise
    );
  };

export const getActiveBuyerInvoices =
  (isSilent = false): ThunkAction<void, {}, {}, BuyerAction> =>
  async (dispatch: ThunkDispatch<{}, {}, BuyerAction>) => {
    dispatch({
      type: isSilent
        ? GET_BUYER_ACTIVE_INVOICES_SILENT
        : GET_BUYER_ACTIVE_INVOICES,
      payload: null,
    });
    const responsePromise = buyerService.getBuyerActiveInvoices();
    return await handleResponse(
      dispatch,
      isSilent
        ? GET_BUYER_ACTIVE_INVOICES_SUCCESS_SILENT
        : GET_BUYER_ACTIVE_INVOICES_SUCCESS,
      isSilent
        ? GET_BUYER_ACTIVE_INVOICES_FAIL_SILENT
        : GET_BUYER_ACTIVE_INVOICES_FAIL,
      responsePromise
    );
  };

export const setBuyerNewCompanyStep =
  (payload: BuyerNewCompanySteps | null) =>
  (dispatch: ThunkDispatch<{}, {}, BuyerAction>) => {
    dispatch({ type: BUYER_NEW_COMPANY_STEP, payload });
  };

export const getEditableInvoices =
  (): ThunkAction<void, {}, {}, BuyerAction> =>
  async (dispatch: ThunkDispatch<{}, {}, BuyerAction>) => {
    dispatch({ type: EDITABLE_INVOICES_REQUEST });
    const responsePromise = buyerService.getEditableInvoices();
    return await handleResponse(
      dispatch,
      EDITABLE_INVOICES_SUCCESS,
      EDITABLE_INVOICES_FAIL,
      responsePromise
    );
  };

export const getCessionOperations =
  (): ThunkAction<void, {}, {}, BuyerAction> =>
  async (dispatch: ThunkDispatch<{}, {}, BuyerAction>) => {
    dispatch({ type: CESSION_OPERATIONS_REQUEST });
    const responsePromise = buyerService.getBuyerCessionOperations();
    return await handleResponse(
      dispatch,
      CESSION_OPERATIONS_SUCCESS,
      CESSION_OPERATIONS_FAIL,
      responsePromise
    );
  };

export const retrieveMoneylenders =
  (): ThunkAction<void, {}, {}, BuyerAction> =>
  async (dispatch: ThunkDispatch<{}, {}, BuyerAction>) => {
    dispatch({ type: RETRIEVE_MONEYLENDERS_REQUEST, payload: false });
    const responsePromise = buyerService.retrieveMoneylenders();
    return await handleResponse(
      dispatch,
      RETRIEVE_MONEYLENDERS_SUCCESS,
      RETRIEVE_MONEYLENDERS_FAIL,
      responsePromise
    );
  };

export const retrieveMoneylenderInfo =
  (vatNumber: string): ThunkAction<void, {}, {}, BuyerAction> =>
  async (dispatch: ThunkDispatch<{}, {}, BuyerAction>) => {
    dispatch({ type: RETRIEVE_MONEYLENDER_INFO_REQUEST });
    const responsePromise = buyerService.retrieveMoneylenderInfo(vatNumber);
    return await handleResponse(
      dispatch,
      RETRIEVE_MONEYLENDER_INFO_SUCCESS,
      RETRIEVE_MONEYLENDER_INFO_FAIL,
      responsePromise
    );
  };

  export const retrieveMoneyLenderCreditLine = (vatNumber: string): ThunkAction<void, {}, {}, BuyerAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, BuyerAction>) => {
        dispatch({type: RETRIEVE_MONEYLENDER_CREDIT_LINE_REQUEST, payload: false});
        const responsePromise = buyerService.retrieveMoneyLenderCreditLine(vatNumber);
        return await handleResponse(dispatch, RETRIEVE_MONEYLENDER_CREDIT_LINE_SUCCESS, RETRIEVE_MONEYLENDER_CREDIT_LINE_FAIL, responsePromise);
    };
  }

  export const editMoneylenderInfo = (data: EditMoneylenderInfoDTO): ThunkAction<void, {}, {}, BuyerAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, BuyerAction>) => {
        dispatch({type: EDIT_MONEYLENDER_INFO_REQUEST, payload: false});
        const responsePromise = buyerService.editMoneylenderInfo(data);
        return await handleResponse(dispatch, EDIT_MONEYLENDER_INFO_SUCCESS, EDIT_MONEYLENDER_INFO_FAIL, responsePromise);
    };
};
