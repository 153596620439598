import * as React from 'react';
import "./Spinner.scss";

const Spinner: React.FC<any> = () => {

    return (
        <div className="spinner__overlay">
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Spinner;
