import * as React from 'react';
import CardList from "../../../components/Common/CardList/CardList";
import {useTranslation} from "react-i18next";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect} from "react-redux";
import {getCompanyList} from "../../../redux/company";
import {AppState} from "../../../redux/store";
import {CompanyDTO} from "../../../model/CompanyDTO";
import {SET_BUSINESS_NAME, SET_COMPANY_ID} from "../../../redux/company/company-reducers";
import ScreenWrapper from "../../../components/AdminComponent/ScreenWrapper/ScreenWrapper";
import Button, {BUTTON_TYPE} from "../../../components/Common/Button/Button";
import forward_arrow_right from "../../../assets/Icons/forward_arrow_right.svg";
import Card from "../../../components/Common/Card/Card";
import {countryUtils} from "../../../utils/countryUtils";
import {Roles} from "../../../model/UserDTO";
import NoDataBox from "../../../components/Common/NoDataBox/NoDataBox";

const NewUserSetCompany: React.FC<any> = ({
                                              retrieveCompanyList,
                                              companyList,
                                              setCompanyId,
                                              role,
                                              setBusinessName,
                                              title,
                                              description,
                                              onConfirm,
                                              onPrevious,
                                              onShowUsers,
                                          }) => {
    const {t} = useTranslation();

    React.useEffect(() => {
        retrieveCompanyList(role);
    }, [retrieveCompanyList, role]);

    const onSelect = (companyId: string, businessName: string) => {
        setCompanyId(companyId);
        setBusinessName(businessName);
        onConfirm?.(companyId);
    };

    const onUsers = (companyId: string, businessName: string) => {
        setCompanyId(companyId);
        setBusinessName(businessName);
        onShowUsers(companyId);
    };

    const renderItem = (company: CompanyDTO, index: any) => {
        const {
            businessCountry,
            businessName,
            companyRegistry,
            province,
            vatNumber,
            marketplaceCode
        } = company;

        const objToDisplay = [
            {key: t("onBoarding.buyer.companyData.registerOfCompanies"), value: companyRegistry || "-"},
            {key: t("onBoarding.buyer.companyData.vatNumber"), value: vatNumber || "-"},
            {key: t("onBoarding.buyer.companyData.nation"), value: businessCountry || "-"},
            {key: t("onBoarding.buyer.companyData.province"), value: province || "-"},
            {key: t("onBoarding.buyer.companyData.marketplaceCode"), value: marketplaceCode || "-"},
        ];
        return (
            <Card
                key={index}
                list={objToDisplay}
                titleValue={businessName}
                rightChildren={
                    <div style={{display: "flex", flexDirection: "column", flex: 1, justifyContent: "center", alignItems: "center", width: "100px"}}>
                        {onShowUsers &&
                        <Button
                            style={{marginTop: "30px", width: "200px"}}
                            label={t("common.button.users")}
                            onClick={() => onUsers(vatNumber, businessName)}
                        />}
                        <Button
                            style={{marginTop: "10px", marginBottom: "30px", width: "200px"}}
                            label={t("common.button.select")}
                            onClick={() => onSelect(vatNumber, businessName)}
                        />
                    </div>}
            />
        );
    };

    const searchableCompany = companyList.map(({businessCountry, ...rest}: CompanyDTO, index: number) => ({
        index,
        businessCountry: countryUtils.fromCodeToCountryName(businessCountry),
        ...rest
    }));

    return (
        <ScreenWrapper
            title={title || `${t("admin.chooseTypeOfUser.title")} > ${t(`admin.chooseTypeOfUser.${role.toLowerCase()}`)}`}
            description={description || t("admin.setCompany.description")}
        >
            <CardList
                noItemsRender={companyList?.length === 0 &&
                <NoDataBox
                    description={t("admin.inviteUser.noDataTitle")}
                    noDataTitle={t("admin.inviteUser.noDataDesc")}
                />}
                defaultSortingFn={(a: any, b: any) => a.businessName < b.businessName ? 1 : -1}
                searchableListToDisplay={searchableCompany}
                accessorId={(item: any) => item.index}
                renderItem={renderItem}
                sortingList={
                    [
                        {
                            key: "0",
                            sortingAccessor: (a: any) => a.businessName,
                            label: t("supplier.sellInvoices.buyersList.buyerAZ"),
                            direction: "asc"
                        },
                        {
                            key: "1",
                            sortingAccessor: (a: any) => a.businessCountry,
                            label: t("supplier.sellInvoices.chooseMoneylenders.country"),
                            direction: "asc"
                        }
                    ]
                }
            />
            <div className="containerButtons">
                <div className="containerButtons">
                    <Button
                        label={t("onBoarding.previous")}
                        onClick={() => onPrevious?.()}
                        icon={forward_arrow_right}
                        buttonType={BUTTON_TYPE.primaryInverted}
                        iconOnTheLeft
                    />
                </div>
            </div>
        </ScreenWrapper>
    );
};

const mapStateToProps = (store: AppState) => ({
    companyList: store.company.companyList,
    role: store.admin.role
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    retrieveCompanyList: (role: Roles) => dispatch(getCompanyList(role)),
    setCompanyId: (companyId: string) => dispatch({type: SET_COMPANY_ID, payload: companyId}),
    setBusinessName: (name: string) => dispatch({type: SET_BUSINESS_NAME, payload: name}),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewUserSetCompany);
