import {networkService} from "./NetworkService";
import {MyResponse} from "../model/ServiceDTO";
import {BankInfoResponseDTO, BuyerDTO, DisposalDataDTO, EligibilityRequest, VendorInvoiceDTO, InvoicesHistoryDTO, MoneylenderDTO, MoneylenderOfferRequest, PendingInvoiceDTO, SellInvoiceStatus, StockHistoryDTO} from "../model/VendorDTO";
import {configService} from "./ConfigService";
import {DocumentTypeDownload} from "../screens/moneylender/DownloadVendorRegistry/DownloadVendorRegistry";

const BASE_URL = configService.getApiPath();

class VendorService {

    getInvoices = (buyerId?: string, programName?: string): Promise<MyResponse<VendorInvoiceDTO[]>> => {
        return (programName && buyerId)
            ? networkService.get(BASE_URL + `/vendor/invoices/${buyerId}/${programName}`)
            : networkService.get(BASE_URL + "/invoice");
    };

    getInvoicesByAmount = (buyerId: string, amount: number, programName: string): Promise<MyResponse<VendorInvoiceDTO[]>> => {
        return networkService.get(BASE_URL + `/vendor/retrieveInvoices/${buyerId}/${programName}/${amount}`);
    };

    getMoneylenders = (data: MoneylenderOfferRequest): Promise<MyResponse<Array<MoneylenderDTO>>> => {
        return networkService.post<MoneylenderOfferRequest, Array<MoneylenderDTO>>(BASE_URL + `/vendor/retrieveMoneylendersWithOffer`, data);
    };

    getPendingInvoices = (): Promise<MyResponse<Array<PendingInvoiceDTO>>> => {
        return networkService.get<Array<PendingInvoiceDTO>>(BASE_URL + `/vendor/retrievePendingInvoices`);
    };

    getBuyers = (userId?: string): Promise<MyResponse<BuyerDTO | BuyerDTO[]>> => {
        return userId ?
            networkService.get<BuyerDTO>(BASE_URL + `/vendor/buyers/${userId}`)
            :
            networkService.get<BuyerDTO[]>(BASE_URL + "/vendor/buyers");
    };

    saveDisposalInvoices = (disposalData: DisposalDataDTO): Promise<MyResponse<any>> => {
        return networkService.post(BASE_URL + `/vendor/disposalData`, disposalData);
    };

    getInvoiceDetailsDataDocuments = (type: DocumentTypeDownload): Promise<MyResponse<any>> => {
        return networkService.post<any, any>(BASE_URL + `/vendor/invoiceDetails/${type}`, null);
    };

    getBarCharData = async (): Promise<MyResponse<InvoicesHistoryDTO>> => {
        return networkService.get(BASE_URL + `/invoice/history`);
    };

    getStockData = async (): Promise<MyResponse<StockHistoryDTO>> => {
        return networkService.get(BASE_URL + `/invoice/stockHistory`);
    };

    getCreditLineHistory = async (creditLineId: string): Promise<MyResponse<StockHistoryDTO>> => {
        return networkService.get(BASE_URL + `/invoice/creditLineHistory/${creditLineId}`);
    };

    getBanksInfo = (): Promise<MyResponse<BankInfoResponseDTO>> => {
        return networkService.get<BankInfoResponseDTO>(BASE_URL + `/vendor/retrieve/banksInfo`);
    };

    getMPFee = (buyerId: string, programId: string): Promise<MyResponse<number>> => {
        return networkService.get<number>(`${BASE_URL}/vendor/MPFee/${buyerId}/${programId}`);
    };

    getChartData = (): Promise<MyResponse<any>> => {
        return networkService.get<any>(BASE_URL + `/invoice/vendorHistory`);
    };

    checkEligibleInvoices = (data: EligibilityRequest): Promise<MyResponse<{status: SellInvoiceStatus, preselectedCreditNotes: string[]}>> => {
        return networkService.post<any, any>(BASE_URL + `/vendor/checkEligibleInvoices`, data);
    };
}

export const vendorService = new VendorService();
