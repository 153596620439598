import * as React from 'react';
import "./SortingBar.scss";
import Button, {BUTTON_TYPE} from "../Button/Button";
import {useTranslation} from "react-i18next";

export interface SortingObj {
    sortingAccessor?(a: any): any,
    label: string
    direction: string
    key: string
    sortingFn?(a: any): any,
}


interface Props {
    sortingList?: SortingObj[],
    onUpdateSorting: any
    defaultSortingFn?: any
}


const SortingBar: React.FC<Props> = ({sortingList, onUpdateSorting, defaultSortingFn}) => {
    const {t} = useTranslation();
    const [sorting, setSorting] = React.useState<SortingObj | null>(null);
    const changeSorting = (sortingObj: SortingObj, isClicked: boolean) => {
        const {label, key, sortingAccessor} = sortingObj;
        let direction: string = "asc";
        let sortingFn: any;
        if (isClicked && sorting?.direction === sortingObj.direction) {
            direction = sortingObj.direction === "asc" ? "desc" : "asc";
        }
        sortingFn = (a: any, b: any) => {
            const aValue = sortingAccessor?.(a);
            const bValue = sortingAccessor?.(b);
            if (aValue === null && bValue === null) return defaultSortingFn?.(a, b) || 0;
            if (aValue === null && bValue !== null) return 1;
            if (aValue !== null && bValue === null) return -1;
            return direction === "asc" ? aValue > bValue ? 1 : -1 : aValue < bValue ? 1 : -1;
        };
        onUpdateSorting({sortingFn, label, direction, key});
        setSorting({sortingFn, label, direction, key});
    };
    return (<>
        <div className="sortingBar__orderByTitle">
            {t("supplier.sellInvoices.chooseMoneylenders.orderBy")}
        </div>
        <div className="sortingBar__orderByButtonsContainer">
            {sortingList && sortingList.map((sortingObj: SortingObj, index: number) => {
                const {key, label} = sortingObj;
                const isClicked = sorting && (sorting.key === key);
                return (
                    <span key={index} className="sortingBar__filterButton">
                        <Button
                            buttonType={isClicked ? BUTTON_TYPE.buttonFilterClicked : BUTTON_TYPE.buttonFilter}
                            label={label}
                            onClick={() => changeSorting(sortingObj, !!isClicked)}
                            className="sortingBar__orderByButton"
                            svgPath={isClicked ?
                                (sorting?.direction === "desc" ?
                                        <span className="sortingBar__triangle"/> :
                                        <span className="sortingBar__triangle sortingBar__triangle--downTriangle"/>
                                )
                                : <span style={{marginRight: "-10px"}}/>
                            }
                        />
                    </span>
                );
            })}
        </div>
    </>);
};

export default SortingBar;
