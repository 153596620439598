import {CompanyDetailsDTO} from "../../model/CompanyDTO";
import {UserFilesDTO, UserInfoDTO, UserManagementDTO} from "../../model/UserDTO";

export const SET_COMPANY_DATA = "setCompanyData";
export const SET_COMPANY_DATA_SUCCESS = "setCompanyDataSuccess";
export const SET_COMPANY_DATA_FAIL = "setCompanyDataFailed";
export const SET_USER_FILES = "setUserFiles";
export const SET_USER_FILES_SUCCESS = "setUserFilesSuccess";
export const SET_USER_FILES_FAIL = "setUserFilesFailed";
export const SET_TERMS_ACCEPTANCE = "setTermsAcceptanceDate";
export const SET_TERMS_ACCEPTANCE_SUCCESS = "setTermsAcceptanceDateSuccess";
export const SET_TERMS_ACCEPTANCE_FAIL = "setTermsAcceptanceDateFailed";
export const SET_USER_ERROR = "setUserError";
export const GET_USER_FILES_REQUEST = "getUserFilesRequest";
export const GET_USER_FILES_SUCCESS = "getUserFilesSuccess";
export const GET_USER_FILES_FAIL = "getUserFilesFail";
export const SAVE_PAID_INVOICES = "savePaidInvoices";
export const SAVE_PAID_INVOICES_SUCCESS = "savePaidInvoicesSuccess";
export const SAVE_PAID_INVOICES_FAIL = "savePaidInvoicesFail";
export const USER_INFO = "userInfo";
export const USER_INFO_SUCCESS = "userInfoSuccess";
export const USER_INFO_FAIL = "userInfoFail";
export const SET_VERSION = 'setVersion';
export const RESET_USER = 'resetUser';
export const GET_ALL_USERS = 'getAllUsers';
export const GET_ALL_USERS_SUCCESS = 'getAllUsersSuccess';
export const GET_ALL_USERS_FAIL = 'getAllUsersFail';
export const UPDATE_USER_ROLE = 'updateUserRole';
export const UPDATE_USER_ROLE_SUCCESS = 'updateUserRoleSuccess';
export const UPDATE_USER_ROLE_FAIL = 'updateUserRoleFail';

export interface UserState {
    companyDataResponse: CompanyDetailsDTO
    isFetchingSetCompanyData: boolean
    termsConditionResponse: string
    isFetchingTermsCondition: boolean
    userFilesResponse: number | string
    isFetchingUserFiles: boolean
    error: string
    isFetchingGetUserFiles: boolean
    getUserFilesSuccess: UserFilesDTO
    getUserFilesFail: any
    getUsersByRoleFail: any
    isFetchingSavePaidInvoices: boolean
    savePaidInvoicesResponse: any
    savePaidInvoicesFail: any
    isFetchingUserInfo: boolean
    userInfoResponse: UserInfoDTO | null
    userInfoFail: any
    version: string
    isFetchingAllUsers: boolean | null
    getAllUsersSuccess: UserManagementDTO[]
    getAllUsersFail: any
    isFetchingUpdateUserRole: boolean | null
    updateUserRoleSuccess: any
    updateUserRoleFail: any
}


export interface setVersion {
    type: typeof SET_VERSION
    payload: string
}

export interface setCompanyData {
    type: typeof SET_COMPANY_DATA
    payload: null
}

export interface setCompanyDataSuccess {
    type: typeof SET_COMPANY_DATA_SUCCESS
    payload: CompanyDetailsDTO
}

export interface setCompanyDataFail {
    type: typeof SET_COMPANY_DATA_FAIL
    payload: string
}

export interface setUserFiles {
    type: typeof SET_USER_FILES
    payload: null
}

export interface setUserFilesSuccess {
    type: typeof SET_USER_FILES_SUCCESS
    payload: number | string
}

export interface setUserFilesFail {
    type: typeof SET_USER_FILES_FAIL
    payload: string
}

export interface setTermsCondition {
    type: typeof SET_TERMS_ACCEPTANCE
    payload: null
}

export interface setTermsConditionSuccess {
    type: typeof SET_TERMS_ACCEPTANCE_SUCCESS
    payload: string
}

export interface setTermsConditionFail {
    type: typeof SET_TERMS_ACCEPTANCE_FAIL
    payload: string
}

export interface setUserError {
    type: typeof SET_USER_ERROR
    payload: string
}

export interface getUserFiles {
    type: typeof GET_USER_FILES_REQUEST
    payload: any
}

export interface getUserFilesSuccess {
    type: typeof GET_USER_FILES_SUCCESS
    payload: any
}

export interface getUserFilesFail {
    type: typeof GET_USER_FILES_FAIL
    payload: any
}

export interface savePaidInvoices {
    type: typeof SAVE_PAID_INVOICES
    payload: any
}

export interface savePaidInvoicesSuccess {
    type: typeof SAVE_PAID_INVOICES_SUCCESS
    payload: any
}

export interface savePaidInvoicesFail {
    type: typeof SAVE_PAID_INVOICES_FAIL
    payload: any
}

export interface userInfo {
    type: typeof USER_INFO
    payload: boolean
}

export interface userInfoSuccess {
    type: typeof USER_INFO_SUCCESS
    payload: UserInfoDTO | null
}

export interface userInfoFail {
    type: typeof USER_INFO_FAIL
    payload: any
}

export interface reset {
    type: typeof RESET_USER
    payload: any
}


export interface getAllUsers {
    type: typeof GET_ALL_USERS
    payload: boolean | null
}

export interface getAllUsersSuccess {
    type: typeof GET_ALL_USERS_SUCCESS
    payload: UserManagementDTO[] | null

}

export interface getAllUsersFail {
    type: typeof GET_ALL_USERS_FAIL
    payload: any
}

export interface updateUserRole {
    type: typeof UPDATE_USER_ROLE
    payload: boolean | null
}

export interface updateUserRoleSuccess {
    type: typeof UPDATE_USER_ROLE_SUCCESS
    payload: UserManagementDTO[] | null

}

export interface updateUserRoleFail {
    type: typeof UPDATE_USER_ROLE_FAIL
    payload: any
}

export type UserAction =
    setCompanyData |
    setCompanyDataSuccess |
    setCompanyDataFail |
    setUserFiles |
    setUserFilesSuccess |
    setUserFilesFail |
    setTermsCondition |
    setTermsConditionSuccess |
    setTermsConditionFail |
    getUserFiles |
    getUserFilesSuccess |
    getUserFilesFail |
    setUserError |
    savePaidInvoices |
    savePaidInvoicesSuccess |
    savePaidInvoicesFail |
    userInfo |
    userInfoSuccess |
    userInfoFail |
    setVersion |
    reset |
    getAllUsers |
    getAllUsersSuccess |
    getAllUsersFail |
    updateUserRole |
    updateUserRoleSuccess |
    updateUserRoleFail

