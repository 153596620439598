import { AuthAction, AuthState } from './auth-interface';
import { setTenantId, setToken } from '../../service/NetworkService';
import {
  Roles,
  UserLoginResponse,
  VerifyPasswordDTOResponse,
} from '../../model/UserDTO';
import { REHYDRATE } from 'redux-persist';
import { roleUtils } from '../../utils/roleUtils';
import i18n from 'i18next';
import { configService } from '../../service/ConfigService';

export const LOGIN_REQUEST = 'login';
export const LOGIN_FAIL = 'loginFail';
export const LOGIN_SUCCESS = 'loginSuccess';
export const LOGOUT = 'logout';
export const LOGOUT_FAIL = 'logoutFail';
export const LOGOUT_SUCCESS = 'logoutSuccess';
export const SWITCH_REQUEST = 'switchRequest';
export const SWITCH_FAIL = 'switchFail';
export const FORGOT_PASSWORD_REQUEST = 'forgotPassword';
export const FORGOT_PASSWORD_FAIL = 'forgotPasswordFail';
export const FORGOT_PASSWORD_SUCCESS = 'forgotPasswordSuccess';
export const VERIFY_PASSWORD_REQUEST = 'verifyPassword';
export const VERIFY_PASSWORD_FAIL = 'verifyPasswordFail';
export const VERIFY_PASSWORD_SUCCESS = 'verifyPasswordSuccess';
export const SET_AUTHENTICATED = 'setAuthenticated';
export const SET_USER_COLOR = 'setUserThemeColor';
export const SET_USER_ON_BOARDING_STEP = 'setUserOnBoardingStep';
export const SET_USER_ON_BOARDING_STEP_SUCCESS = 'setUserOnBoardingStepSuccess';
export const SET_USER_ON_BOARDING_STEP_FAIL = 'setUserOnBoardingStepFailed';
export const SET_CSRF = 'setCSRFToken';
export const SET_ONBOARDING_DATA = 'setOnboardingData';
export const SET_ONBOARDING_LR_DATA = 'setOnboardingLegalRepresentativeData';
export const ONBOARDING_DATA_FAIL = 'onboardingDataFail';
export const SET_CURRENT_ONBOARDING_STEP = 'setCurrentOnBoardingStep';
export const SET_ONBOARDING_ERROR = 'setOnBoardingError';
export const SET_EXPIRATION_SESSION = 'setExpirationSession';
export const UPDATE_LEGAL_FILE = 'updateLegalFile';
export const UPDATE_LEGAL_FILE_SUCCESS = 'updateLegalFilesSuccess';
export const UPDATE_LEGAL_FILE_FAIL = 'updateLegalFileError';
export const GET_LEGAL_FILE_REQUEST = 'getLegalFileRequest';
export const GET_LEGAL_FILE_SUCCESS = 'getLegalFilesSuccess';
export const GET_LEGAL_FILE_FAIL = 'getLegalFileError';
export const UPDATE_LEGAL_DATA = 'updateLegalData';
export const UPDATE_LEGAL_DATA_SUCCESS = 'updateLegalDataSuccess';
export const UPDATE_LEGAL_DATA_FAIL = 'updateLegalDataError';
export const DELETE_LEGAL_FILE = 'updateLegalData';
export const DELETE_LEGAL_FILE_SUCCESS = 'updateLegalDataSuccess';
export const DELETE_LEGAL_FILE_FAIL = 'updateLegalDataError';
export const UPDATE_OTHER_USER_FILE = 'updateOtherUseFile';
export const UPDATE_OTHER_USER_FILE_SUCCESS = 'updateOtherUserFileSuccess';
export const UPDATE_OTHER_USER_FILE_FAIL = 'updateOtherUserFileError';
export const UPDATE_OTHER_USER_DATA = 'updateOtherUserData';
export const UPDATE_OTHER_USER_DATA_SUCCESS = 'updateOtherUserDataSuccess';
export const UPDATE_OTHER_USER_DATA_FAIL = 'updateOtherUserDataError';
export const SET_ACTIVE_OWNERS = 'setActiveOwner';
export const SET_LEGAL = 'setLegalRepresentative';
export const REMOVE_OTHER_USER_DATA_ERROR = 'removeOtherUserDataError';
export const RESET_AUTH = 'resetAuth';
export const SET_PROFILE_PIC = 'setProfilePic';

const defaultState: AuthState = {
  blockPreviousStep: false,
  isFetchingLogin: false,
  isAuthenticated: false,
  onBoardingData: {},
  errorLogin: '',
  forgotPasswordError: '',
  isFetchingForgotPassword: false,
  forgotPasswordResponse: '',
  verifyPasswordError: '',
  isFetchingVerifyPassword: false,
  verifyPasswordResponse: {},
  themeColor: 'purple',
  onBoardingStep: '',
  role: Roles.NONE,
  roles: [],
  verifyPasswordSuccess: false,
  csrf: '',
  tenantId: configService.getDefaultTenantId() || '',
  errorOnBoardingData: '',
  currentOnBoardingStep: '',
  isFetchingStepOnBoarding: false,
  stepOnBoardingError: '',
  expirationSession: 0,
  isCompany: false,
  isFetchingUpdateLegalFile: false,
  updateLegalFileResponse: '',
  updateLegalFileError: '',
  getLegalFileIsFetching: false,
  getLegalFileResponse: [],
  getLegalFileError: '',
  isFetchingUpdateLegalData: false,
  updateLegalDataResponse: '',
  updateLegalDataError: '',
  isFetchingDeleteLegalFile: false,
  deleteLegalFileResponse: '',
  deleteLegalFileError: '',
  isFetchingUpdateOtherUserData: false,
  updateOtherUserDataResponse: '',
  updateOtherUserDataError: '',
  isFetchingUpdateOtherUserFile: false,
  updateOtherUserFileResponse: '',
  updateOtherUserFileError: '',
  companyRoles: [],
};

export function authReducer(
  state = defaultState,
  action: AuthAction
): AuthState {
  switch (action.type) {
    case LOGIN_REQUEST:
      //we need the token in both the network variable and redux store
      //in NetworkService for use in POST,
      //and in redux to save it in persist and pass it again to the NetworkService on page refresh
      setToken('');
      setTenantId(configService.getDefaultTenantId() || '');

      return {
        ...state,
        isFetchingLogin: true,
        isAuthenticated: false,
        onBoardingData: {},
        csrf: '',
        tenantId: configService.getDefaultTenantId() || '',
        errorLogin: '',
        onBoardingStep: '',
        role: defaultState.role,
        roles: [],
      };
    case LOGIN_SUCCESS:
      const { roles, csrf, companyRoles, tenantId } = action.payload;
      setToken(csrf);
      setTenantId(tenantId);
      const role = roleUtils.extractMainRole(roles);

      return {
        ...state,
        onBoardingData: { ...action.payload.onBoardingData },
        errorLogin: '',
        isFetchingLogin: false,
        isAuthenticated: true,
        onBoardingStep: action.payload.onBoardingStep,
        currentOnBoardingStep: action.payload.onBoardingStep,
        roles,
        role,
        themeColor: roleUtils.getThemeColor(role),
        csrf: (action.payload as UserLoginResponse).csrf,
        tenantId: tenantId,
        verifyPasswordSuccess: false,
        isCompany: action.payload.isCompany,
        companyRoles,
        blockPreviousStep: action.payload.blockPreviousStep,
      };
    case LOGIN_FAIL:
      setToken('');
      setTenantId(configService.getDefaultTenantId() || '');
      return {
        ...state,
        onBoardingData: {},
        errorLogin:
          action?.payload?.toString?.() || i18n.t('errors.login.common'),
        isFetchingLogin: false,
      };
    case SWITCH_REQUEST:
      return {
        ...state,
        isFetchingLogin: true,
      };
    case SWITCH_FAIL:
      return {
        ...state,
        errorLogin: action?.payload?.toString?.(),
        isFetchingLogin: false,
      };
    case LOGOUT:
      return {
        ...state,
        errorLogin: action.payload,
        isAuthenticated: false,
        onBoardingStep: '',
        roles: [],
        csrf: '',
        tenantId: configService.getDefaultTenantId() || '',
      };
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isFetchingForgotPassword: true,
        forgotPasswordResponse: '',
        forgotPasswordError: '',
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetchingForgotPassword: false,
        forgotPasswordResponse: action.payload,
        forgotPasswordError: '',
      };
    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        isFetchingForgotPassword: false,
        forgotPasswordResponse: '',
        forgotPasswordError: action.payload && action.payload.toString(),
      };
    case VERIFY_PASSWORD_REQUEST:
      return {
        ...state,
        isFetchingVerifyPassword: true,
        verifyPasswordSuccess: false,
        verifyPasswordError: '',
      };
    case VERIFY_PASSWORD_SUCCESS:
      const { status } = action.payload as VerifyPasswordDTOResponse;
      return {
        ...state,
        isFetchingVerifyPassword: false,
        verifyPasswordSuccess: status === 200,
        verifyPasswordError: '',
      };
    case VERIFY_PASSWORD_FAIL:
      return {
        ...state,
        isFetchingVerifyPassword: false,
        verifyPasswordSuccess: false,
        verifyPasswordError: action.payload && action.payload.toString(),
      };
    case SET_USER_COLOR:
      return {
        ...state,
        themeColor: action.payload,
      };
    case SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case SET_CSRF:
      return {
        ...state,
        csrf: action.payload,
      };
    case SET_ONBOARDING_DATA:
      return {
        ...state,
        onBoardingData: { ...state.onBoardingData, ...action.data },
      };
    case SET_ONBOARDING_LR_DATA:
      return {
        ...state,
        onBoardingData: {
          ...state.onBoardingData,
          legalRepresentativeData: {
            ...state.onBoardingData.legalRepresentativeData,
            ...action.payload,
          },
        },
      };
    case ONBOARDING_DATA_FAIL:
      return {
        ...state,
        errorOnBoardingData: action.payload,
      };

    case SET_CURRENT_ONBOARDING_STEP:
      return {
        ...state,
        currentOnBoardingStep: action.payload.onBoardingStep,
      };

    case SET_USER_ON_BOARDING_STEP:
      return {
        ...state,
        isFetchingStepOnBoarding: true,
        stepOnBoardingError: '',
      };
    case SET_USER_ON_BOARDING_STEP_SUCCESS:
      return {
        ...state,
        isFetchingStepOnBoarding: false,
        onBoardingStep: action.payload.onBoardingStep,
        stepOnBoardingError: '',
        blockPreviousStep: action.payload.blockPreviousStep,
      };
    case SET_USER_ON_BOARDING_STEP_FAIL:
      return {
        ...state,
        isFetchingStepOnBoarding: false,
        stepOnBoardingError: action.payload,
      };
    case SET_ONBOARDING_ERROR:
      return {
        ...state,
        errorOnBoardingData: action.payload,
      };
    case SET_EXPIRATION_SESSION:
      return {
        ...state,
        expirationSession: action.payload,
      };
    case UPDATE_LEGAL_FILE:
      return {
        ...state,
        isFetchingUpdateLegalFile: true,
        updateLegalFileError: defaultState.updateLegalFileError,
      };
    case UPDATE_LEGAL_FILE_SUCCESS:
      return {
        ...state,
        updateLegalFileResponse: action.payload,
        updateLegalFileError: defaultState.updateLegalFileError,
        isFetchingUpdateLegalFile: false,
      };
    case UPDATE_LEGAL_FILE_FAIL:
      return {
        ...state,
        updateLegalFileResponse: '',
        updateLegalFileError: action.payload,
        isFetchingUpdateLegalFile: false,
      };
    case GET_LEGAL_FILE_REQUEST:
      return {
        ...state,
        getLegalFileIsFetching: true,
        getLegalFileResponse: defaultState.getLegalFileResponse,
        getLegalFileError: defaultState.getLegalFileError,
      };
    case GET_LEGAL_FILE_SUCCESS:
      return {
        ...state,
        getLegalFileIsFetching: false,
        getLegalFileResponse: action.payload,
        getLegalFileError: defaultState.getLegalFileError,
      };
    case GET_LEGAL_FILE_FAIL:
      return {
        ...state,
        getLegalFileIsFetching: defaultState.getLegalFileIsFetching,
        getLegalFileResponse: defaultState.getLegalFileResponse,
        getLegalFileError: action.payload,
      };
    case UPDATE_LEGAL_DATA:
      return {
        ...state,
        updateLegalDataResponse: '',
        isFetchingUpdateLegalData: true,
      };
    case UPDATE_LEGAL_DATA_SUCCESS:
      return {
        ...state,
        updateLegalDataResponse: action.payload,
        isFetchingUpdateLegalData: false,
      };
    case UPDATE_LEGAL_DATA_FAIL:
      return {
        ...state,
        updateLegalDataResponse: '',
        updateLegalDataError: action.payload,
        isFetchingUpdateLegalData: false,
      };
    case DELETE_LEGAL_FILE:
      return {
        ...state,
        deleteLegalFileResponse: '',
        isFetchingDeleteLegalFile: true,
      };
    case DELETE_LEGAL_FILE_SUCCESS:
      return {
        ...state,
        deleteLegalFileResponse: action.payload,
        isFetchingDeleteLegalFile: false,
      };
    case DELETE_LEGAL_FILE_FAIL:
      return {
        ...state,
        deleteLegalFileResponse: '',
        deleteLegalFileError: action.payload,
        isFetchingDeleteLegalFile: false,
      };
    case UPDATE_OTHER_USER_FILE:
      return {
        ...state,
        isFetchingUpdateOtherUserFile: true,
        updateOtherUserFileResponse: '',
        updateOtherUserFileError: '',
      };
    case UPDATE_OTHER_USER_FILE_SUCCESS:
      return {
        ...state,
        isFetchingUpdateOtherUserFile: false,
        updateOtherUserFileResponse: action.payload,
        updateOtherUserFileError: '',
      };
    case UPDATE_OTHER_USER_FILE_FAIL:
      return {
        ...state,
        isFetchingUpdateOtherUserFile: false,
        updateOtherUserFileResponse: '',
        updateOtherUserFileError: action.payload,
      };
    case UPDATE_OTHER_USER_DATA:
      return {
        ...state,
        isFetchingUpdateOtherUserData: true,
        updateOtherUserDataResponse: '',
        updateOtherUserDataError: '',
      };
    case UPDATE_OTHER_USER_DATA_SUCCESS:
      return {
        ...state,
        isFetchingUpdateOtherUserData: false,
        updateOtherUserDataResponse: action.payload,
        updateOtherUserDataError: '',
      };
    case UPDATE_OTHER_USER_DATA_FAIL:
      return {
        ...state,
        isFetchingUpdateOtherUserData: false,
        updateOtherUserDataResponse: '',
        updateOtherUserDataError: action.payload,
      };
    case SET_ACTIVE_OWNERS: {
      const newOnBoardingData = {
        ...state.onBoardingData,
        effectiveOwners: action.payload,
      };
      return {
        ...state,
        onBoardingData: newOnBoardingData,
      };
    }
    case SET_LEGAL: {
      const newOnBoardingData = {
        ...state.onBoardingData,
        legalRepresentativeData: action.payload,
      };
      return {
        ...state,
        onBoardingData: newOnBoardingData,
      };
    }

    case SET_PROFILE_PIC: {
      const newOnBoardingData = {
        ...state.onBoardingData,
        profilePic: action.payload,
      };
      return {
        ...state,
        onBoardingData: newOnBoardingData,
      };
    }
    case REMOVE_OTHER_USER_DATA_ERROR: {
      return {
        ...state,
        updateOtherUserDataError: '',
      };
    }
    case RESET_AUTH: {
      return {
        ...state,
        ...defaultState,
      };
    }
    case REHYDRATE:
      action.payload && action.payload.csrf && setToken(action.payload.csrf);
      action.payload &&
        action.payload.tenantId &&
        setTenantId(action.payload.tenantId);
      if (action.payload && action.payload.key === 'auth')
        return {
          ...state,
          ...action.payload,
        };
      else return state;
    default:
      return state;
  }
}
