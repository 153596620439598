export {infoCertReducer} from './infocert-reducers';

export {
    createDossier,
    processStart,
    webIdStart,
    webIdQueue,
    webIdDossier,
    oneShotStart,
    oneShotRegister,
    oneShotIssueSign,
    oneShotSendOTP,
    remoteSignStart,
    remoteSignPrepareSignature,
    remoteSignSignContracts,
    remoteSignSendOTP,
    addOnBoardingDocuments,
    signDocuments,
    getNewToken,
    setDocumentSignStep,
    setInfoCertError,
    setInfoCertDossierToken,
    setInfoCertSessionKey,
    setInfoCertTaskId,
    addToChatHistory,
    setChatHistory,
    logoutInfoCert
} from './infocert-actions';
