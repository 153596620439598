import { CompanyDetailsDTO } from './CompanyDTO';

export interface UserDataDTO {
  email?: string;
  phoneNumber?: string;
  name?: string;
  surname?: string;
  birthPlace?: string;
  region?: string;
  birthDate?: string;
  cf?: string;
  sex?: string;
  nationality?: string;
  residenceCountry?: string;
  residenceProvince?: string;
  residenceCity?: string;
  residenceAddress?: string;
  residenceHouseNumber?: string;
  residenceZipCode?: string;
  documentType?: string;
  documentNumber?: string;
  documentEmitter?: string;
  documentCityEmitter?: string;
  documentProvinceEmitter?: string;
  documentNationEmitter?: string;
  documentReleaseDate?: string;
  documentExpiryDate?: string;
  politicallyExposed?: boolean;
  institutionalPosition?: string;
  institutionalPositionCountry?: string;
  mainIncome?: string;
  publicOfficeHeld?: boolean;
  officeHeld?: string;
  isCompany?: boolean;
  legalRepresentative?: boolean;
  legalRepresentativeData?: UserDataDTO;
  effectiveOwners?: any;
  role?: any;
  profilePic?: string;
}

export enum OnBoardingStep {
  START_ONBOARD = 'START_ONBOARD',
  STEP_COMPANY_DATA = 'STEP_COMPANY_DATA',
  STEP_PERSONAL_DATA = 'STEP_PERSONAL_DATA',
  STEP_ID_DOCUMENT = 'STEP_ID_DOCUMENT',
  STEP_TERMS_AND_CONDITIONS = 'STEP_TERMS_AND_CONDITIONS',
  STEP_QUESTION_NAIRE = 'STEP_QUESTION_NAIRE',
  STEP_SUMMARY_DATA = 'STEP_SUMMARY_DATA',
  STEP_DOCUMENT_UPLOAD = 'STEP_DOCUMENT_UPLOAD',
  STEP_SIGNATURE = 'STEP_SIGNATURE',
  END_ONBOARD = 'END_ONBOARD',
  // STEP used only client side
  STEP_SIGNATURE_IDENTIFICATION = 'STEP_SIGNATURE_IDENTIFICATION',
  STEP_SIGNATURE_REQUEST_CERTIFICATE = 'STEP_SIGNATURE_REQUEST_CERTIFICATE',
  STEP_SIGNATURE_SIGN_CONTRACT = 'STEP_SIGNATURE_SIGN_CONTRACT',
  STEP_SIGNATURE_OTP = 'STEP_SIGNATURE_OTP',
  STEP_SIGNATURE_DOWNLOAD_DOCUMENT = 'STEP_SIGNATURE_DOWNLOAD_DOCUMENT',
  STEP_SIGNATURE_SPID_IDENTIFICATION = 'STEP_SIGNATURE_SPID_IDENTIFICATION',
}

export enum OnBoardingCompanyStep {
  START_ONBOARD = 'START_ONBOARD',
  STEP_PERSONAL_DATA = 'STEP_PERSONAL_DATA',
  STEP_ID_DOCUMENT = 'STEP_ID_DOCUMENT',
  STEP_TERMS_AND_CONDITIONS = 'STEP_TERMS_AND_CONDITIONS',
  STEP_QUESTION_NAIRE = 'STEP_QUESTION_NAIRE',
  STEP_DEFINITION_ROLE = 'STEP_DEFINITION_ROLE',
  STEP_COMPANY_DATA = 'STEP_COMPANY_DATA',
  STEP_OTHER_COMPANY_DATA = 'STEP_OTHER_COMPANY_DATA',
  STEP_LEGAL_REPRESENTATIVE = 'STEP_LEGAL_REPRESENTATIVE',
  STEP_EFFECTIVE_OWNERS = 'STEP_EFFECTIVE_OWNERS',
  ADD_EFFECTIVE_OWNER = 'ADD_EFFECTIVE_OWNER',
  STEP_SUMMARY_DATA = 'STEP_SUMMARY_DATA',
  STEP_DOCUMENT_UPLOAD = 'STEP_DOCUMENT_UPLOAD',
  STEP_WAIT_FOR_APPROVAL = 'STEP_WAIT_FOR_APPROVAL',
  STEP_SIGNATURE = 'STEP_SIGNATURE',
}

export enum Roles {
  SIGNER = 'SIGNER',
  BLOCKED = 'BLOCKED',
  ADMIN = 'ADMIN',
  BUYER = 'BUYER',
  BUYER_ADMIN = 'BUYER_ADMIN',
  BUYER_PROGRAM = 'BUYER_PROGRAM',
  BUYER_DYNAMIC_DISCOUNT = 'BUYER_DYNAMIC_DISCOUNT',
  BUYER_PAYMENT = 'BUYER_PAYMENT',
  BUYER_NO_SAP = "BUYER_NO_SAP",
  MONEYLENDER = 'MONEYLENDER',
  MONEYLENDER_ADMIN = 'MONEYLENDER_ADMIN',
  MONEYLENDER_CREDIT_LINE = 'MONEYLENDER_CREDIT_LINE',
  MONEYLENDER_VENDOR = 'MONEYLENDER_VENDOR',
  MONEYLENDER_SALE_OF_CREDIT = 'MONEYLENDER_SALE_OF_CREDIT',
  MONEYLENDER_BUYER_INCOME = 'MONEYLENDER_BUYER_INCOME',
  VENDOR = 'VENDOR',
  VENDOR_ADMIN = 'VENDOR_ADMIN',
  VENDOR_SALE_OF_CREDIT = 'VENDOR_SALE_OF_CREDIT',
  NONE = 'NONE',
}

export interface CompanyRole {
  active?: boolean;
  label: string;
  role: Roles;
  vatNumber: string;
  tenant: string;
}

export enum DefinitionRoles {
  LEGAL_REPRESENTATIVE = 'LEGAL_REPRESENTATIVE',
  PARTNER = 'PARTNER',
  ATTORNEY = 'ATTORNEY',
  EMPLOYEE = 'EMPLOYEE',
  NOT_AUTHORIZED_OPERATOR = 'NOT_AUTHORIZED_OPERATOR',
  EFFECTIVE_OWNER = 'EFFECTIVE_OWNER',
}

export enum DocumentTypes {
  STATUTE = 'STATUTE',
  COMMERCE_CERTIFICATE = 'COMMERCE_CERTIFICATE',
  DELEGATION_POWERS = 'DELEGATION_POWERS',
  ATTORNEY_POWERS = 'ATTORNEY_POWERS',
}

export interface UserDTORequest {
  username: string;
  password: string;
}

export interface VerifyPasswordDTORequest {
  email: string;
  token: string;
}

export interface UserLoginResponse {
  roles: string[];
  onBoardingStep: OnBoardingStep;
  onBoardingData: UserDataDTO;
  isCompany: boolean;
  csrf: string;
  tenantId: string;
  blockPreviousStep: boolean;
}

export interface ForgotPasswordDTORequest {
  email: string;
}

export interface LoginDTOResponse {
  onBoardingStep: OnBoardingStep;
  onBoardingData: LoginResponse;
  role: string;
}

export interface ForgotPasswordDTOResponse {
  status: number;
  errorMessage: string;
}

export interface VerifyPasswordDTORequest {
  token: string;
  password: string;
}

export interface VerifyPasswordDTOResponse {
  status: number;
  errorMessage: string;
}

export interface UpdateStepRequest {
  onBoardingStep: string;
}

export interface LoginResponse {
  companyId?: string;
  businessName?: string;
  companyRegistry?: string;
  vatNumber?: string;
  address?: string;
  businessCountry?: string;
  city?: string;
  province?: string;
  zipCode?: string;
  taxCode?: string;
  email?: string;
  pec?: string;
  ateco?: string;
  phoneNumber?: string;
  name?: string;
  surname?: string;
  birthPlace?: string;
  region?: string;
  birthDate?: string;
  cf?: string;
  sex?: string;
  nationality?: string;
  residenceCountry?: string;
  residenceProvince?: string;
  residenceCity?: string;
  residenceAddress?: string;
  residenceHouseNumber?: string;
  residenceZipCode?: string;
  documentType?: string;
  documentNumber?: string;
  documentEmitter?: string;
  documentCityEmitter?: string;
  documentProvinceEmitter?: string;
  documentNationEmitter?: string;
  documentReleaseDate?: string;
  documentExpiryDate?: string;
  politicallyExposed?: boolean;
  institutionalPosition?: string;
  institutionalPositionCountry?: string;
  mainIncome?: string;
  publicOfficeHeld?: boolean;
  officeHeld?: string;
}

export interface UserFilesDTORequest {
  frontIdScan?: string;
  backIdScan?: string;
  frontFiscalCodeScan?: string;
  backFiscalCodeScan?: string;
  profilePic?: string;
}

export interface LegalFilesDTO {
  id?: string;
  name: string;
  base64: string;
  documentType: DocumentTypes;
  documentDate: string;
  documentExpiryDate: string;
}

export interface LegalDataDTORequest {
  role?: string;
  otherRole?: string;
  hasPowerToSign?: boolean;
  companyActivities?: string;
  isClientTypeBuyer?: boolean;
  isClientTypeCompany?: boolean;
  isClientTypePublic?: boolean;
  hasGambling?: boolean;
  trustClass?: string;
  noProfitSector?: string;
}

export interface OtherFilesDTORequest {
  userId: string;
  userFiles: UserFilesDTORequest;
}

export interface UpdateOtherDataRequest {
  userId: string;
  companyId: string;
  userData: UserDataDTO;
}

export interface UserDataResponseDTO {
  user: UserDataDTO;
  userFiles: any;
  legalRepresentative: UserDataDTO;
  legalRepresentativeFiles: any;
  effectiveOwner: UserDataDTO;
  effectiveOwnerFiles: any;
  documents: [];
  companyData: CompanyDetailsDTO;
}

export interface UserInfoDTO {
  user: UserDataDTO;
  documents: { name: string; base64: string }[];
}

export interface UserFilesDTO {
  backFiscalCodeScan: any;
  backIdScan: any;
  frontFiscalCodeScan: any;
  frontIdScan: any;
  id: any;
  profilePic: any;
  userId: any;
}

export interface OnboardedUserDataDTO {
  userData: any;
  userFiles: UserFilesDTO[];
}

export interface UserManagementDTO {
  profileIcon: string;
  name: string;
  type: string;
  roles: Roles[];
  blocked: boolean;
  userId: string;
}

export interface UpdateRoleRequestDTO {
  userId: string;
  roles: string[];
}

export interface CompanyRoleData {
  vatNumber: string;
  role: Roles;
}
