import * as React from "react";
import { lazy } from "react";
import "./components/NavBar/NavBar.scss";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "react-dates/initialize";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-dates/lib/css/_datepicker.css";
import "./styles/Common.scss";
import { Provider } from "react-redux";
import storeInit from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Roles } from "./model/UserDTO";
import { rolePaths } from "./components/NavBar/NavBar";
import { ActionTypes } from "./model/InfoCertDTO";
import DocumentsManagementDispatcher from "./components/AdminComponent/UserInfoDispatcher/DocumentsManagementDispatcher";
import ESGCertificateList from "./components/SupplierComponent/esgCertificatesManagement/EsgCertificateList";

const Program = lazy(() => import("./screens/buyer/Program/Program"));
const Inquiry = lazy(() => import("./screens/common/inquiry/Inquiry"));
const RatingESG = lazy(() => import("./screens/admin/RatingESG/RatingESG"));
const Credit = lazy(
  () => import("./screens/buyer/DynamicDiscount/DynamicDiscount")
);
const Login = lazy(() => import("./screens/authentication/login/Login"));
const Report = lazy(() => import("./screens/common/report/Report"));
const TreasuryHome = lazy(() => import("./screens/buyer/Treasury/Treasury"));
const BuyerActiveInvoice = lazy(
  () => import("./screens/buyer/ActiveInvoice/BuyerActiveInvoice")
);
const BuyerCreateInvoice = lazy(
  () => import("./screens/buyer/CreateInvoice/BuyerCreateInvoice")
);
const ActionRequests = lazy(
  () => import("./screens/common/actionRequests/ActionRequests")
);
const OnBoardingCommonPage = lazy(
  () =>
    import(
      "./components/OnBoardingComponent/onBoardingDispatcher/OnBoardingDispatcher"
    )
);
const ForgotPassword = lazy(
  () => import("./screens/authentication/sendEmail/ForgotPassword")
);
const ResetPassword = lazy(
  () => import("./screens/authentication/resetPassword/ResetPassword")
);
const MoneylenderHomePage = lazy(
  () => import("./screens/moneylender/MoneylenderHomePage/MoneylenderHomePage")
);
const BuyerHomePage = lazy(
  () => import("./screens/buyer/BuyerHomePage/BuyerHomePage")
);
const MainContainer = lazy(
  () => import("./components/MainContainer/MainContainer")
);
const PrivateRoute = lazy(
  () => import("./components/PrivateRoute/PrivateRoute")
);
const AdminHomePage = lazy(
  () => import("./screens/admin/AdminHomePage/AdminHomePage")
);
const ActiveInvoices = lazy(
  () => import("./screens/supplier/VendorHomePage/VendorHomePage")
);
const NewUserDispatcher = lazy(
  () =>
    import("./components/AdminComponent/NewUserDispatcher/NewUserDispatcher")
);
const SwapUser = lazy(
  () => import("./components/AdminComponent/SwapUser/SwapUser")
);
const CompanyInfoDispatcher = lazy(
  () =>
    import(
      "./components/AdminComponent/CompanyInfoDispatcher/CompanyInfoDispatcher"
    )
);
const OnBoardingStatus = lazy(
  () => import("./screens/admin/OnBoardingStatus/OnBoardingStatus")
);
const SAPLogs = lazy(() => import("./screens/admin/SAPLogs/SAPLogs"));
const PendingRequest = lazy(
  () => import("./screens/admin/PendingRequest/PendingRequest")
);
const DocumentSignDispatcher = lazy(
  () =>
    import(
      "./components/DocumentSignComponent/documentSignDispatcher/DocumentSignDispatcher"
    )
);
const ConfigurationPricesDispatcher = lazy(
  () =>
    import(
      "./components/ConfigurationPricesComponent/ConfigurationPricesDispatcher/ConfigurationPricesDispatcher"
    )
);
const SellInvoicesDispatcher = lazy(
  () =>
    import(
      "./components/SupplierComponent/sellInvoicesDispatcher/SellInvoicesDispatcher"
    )
);
const Archive = lazy(() => import("./screens/common/archiveScreen/Archive"));
const MoneylenderActiveInvoices = lazy(
  () =>
    import(
      "./screens/moneylender/MoneylenderActiveInvoices/MoneylenderActiveInvoices"
    )
);
const DelegatedManagement = lazy(
  () =>
    import("./screens/buyer/DelegatedManagment/DelegatedManagementContainer")
);
const MoneylenderVendorRegistry = lazy(
  () => import("./screens/moneylender/VendorRegistry/MoneylenderVendorRegistry")
);
const DownloadVendorRegistry = lazy(
  () =>
    import(
      "./screens/moneylender/DownloadVendorRegistry/DownloadVendorRegistry"
    )
);
const PersonalInfo = lazy(
  () => import("./screens/common/personalInfo/PersonalInfo")
);
const InviteNewCustomerWrapper = lazy(
  () =>
    import("./screens/common/inviteNewCustomerWrapper/InviteNewCustomerWrapper")
);
const PendingInvoices = lazy(
  () => import("./screens/supplier/PendingInvoices/PendingInvoices")
);
const SignWrapper = lazy(
  () => import("./components/Common/SignersCombinations/SignWrapper")
);
const TariffManagement = lazy(
  () => import("./screens/admin/TariffManagement/TariffManagement")
);
const ModifyUserDispatcher = lazy(
  () =>
    import(
      "./components/AdminComponent/ModifyUserDispatcher/ModifyUserDispatcher"
    )
);
const UserManagement = lazy(
  () => import("./screens/common/userManagement/UserManagement")
);
const ContactUs = lazy(() => import("./screens/common/contactUs/ContactUs"));
const GenerateReport = lazy(
  () => import("./screens/common/report/GenerateReport")
);
const PageNotFound = lazy(
  () => import("./screens/common/pageNotFound/PageNotFound")
);
const AdminArchive = lazy(
  () => import("./screens/admin/AdminArchive/AdminArchive")
);
const AdminActiveInvoice = lazy(
  () => import("./screens/admin/ActiveInvoice/AdminActiveInvoice")
);
const IncomeOperations = lazy(
  () => import("./screens/moneylender/IncomeOperations/IncomeOperations")
);
const CessionOperation = lazy(
  () => import("./screens/moneylender/CessionOperation/CessionOperations")
);

const DelegationManagementWithSignature = () => (
  <SignWrapper
    wrapComponent={DelegatedManagement}
    actionType={ActionTypes.DELEGATION}
  />
);

const BuyerMoneylenderRegistry = lazy(
  () => import("./screens/buyer/BuyerRegistry/BuyerMoneylenderRegistry")
);
const BuyerNewVendorDispatcher = lazy(
  () =>
    import(
      "./components/BuyerRegistryComponent/VendorRegistry/BuyerNewVendorDispatcher"
    )
);

const BuyerInvoiceManagement = lazy(
  () => import("./screens/buyer/InvoiceManagement/InvoiceManagement")
);

const BuyerCessionOperations = lazy(
  () => import("./screens/buyer/CessionOperations/CessionOperations")
);

export default function App() {
  React.useEffect(() => {
    const spinner = document.querySelector(".spinnerOverlay");
    spinner && spinner.classList.add("hide");
  }, []);

  const { store, persistor } = storeInit();
  return (
    <React.Suspense fallback={null}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <MainContainer>
              <div className="pageContainer">
                <Switch>
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.BUYER].inquiry}
                    component={Inquiry}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.BUYER].program}
                    component={Program}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.BUYER].credit}
                    component={Credit}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.BUYER].sign}
                    component={ActionRequests}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.BUYER].archive}
                    component={Archive}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.BUYER].home}
                    component={BuyerHomePage}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.BUYER].treasury}
                    component={TreasuryHome}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.BUYER].delegatedManagement}
                    component={DelegationManagementWithSignature}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.BUYER].inviteNewCustomer}
                    component={InviteNewCustomerWrapper}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.BUYER].pendingCustomer}
                    component={UserManagement}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.BUYER].generateReport}
                    component={GenerateReport}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.BUYER].activeInvoice}
                    component={BuyerActiveInvoice}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.BUYER].newInvoice}
                    component={BuyerCreateInvoice}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.BUYER].editInvoice}
                    component={BuyerInvoiceManagement}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.BUYER].moneylenderRegistry}
                    component={BuyerMoneylenderRegistry}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.BUYER].cession}
                    component={BuyerCessionOperations}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.BUYER].vendorRegistry}
                    component={BuyerNewVendorDispatcher}
                    componentProps={{ resetStep: true }}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.VENDOR].inquiry}
                    component={Inquiry}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.VENDOR].invoice}
                    component={ActiveInvoices}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.VENDOR].pending}
                    component={PendingInvoices}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.VENDOR].home}
                    component={ActiveInvoices}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.VENDOR].sell}
                    component={SellInvoicesDispatcher}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.VENDOR].inviteNewCustomer}
                    component={InviteNewCustomerWrapper}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.VENDOR].delegatedManagement}
                    component={DelegationManagementWithSignature}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.VENDOR].pendingCustomer}
                    component={UserManagement}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.VENDOR].sign}
                    component={ActionRequests}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.VENDOR].archive}
                    component={Archive}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.VENDOR].incomeOperations}
                    component={IncomeOperations}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.VENDOR].generateReport}
                    component={GenerateReport}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.MONEYLENDER].inquiry}
                    component={Inquiry}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.MONEYLENDER].home}
                    component={MoneylenderHomePage}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.MONEYLENDER].credit}
                    component={ConfigurationPricesDispatcher}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.MONEYLENDER].vendorRegistry}
                    component={MoneylenderVendorRegistry}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.MONEYLENDER].downloadVendorRegistry}
                    component={DownloadVendorRegistry}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.MONEYLENDER].sign}
                    component={ActionRequests}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.MONEYLENDER].archive}
                    component={Archive}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.MONEYLENDER].activeInvoices}
                    component={MoneylenderActiveInvoices}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.MONEYLENDER].sell}
                    component={SellInvoicesDispatcher}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.MONEYLENDER].delegatedManagement}
                    component={DelegationManagementWithSignature}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.MONEYLENDER].inviteNewCustomer}
                    component={InviteNewCustomerWrapper}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.MONEYLENDER].pendingCustomer}
                    component={UserManagement}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.MONEYLENDER].pending}
                    component={PendingInvoices}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.MONEYLENDER].cessionOperations}
                    component={CessionOperation}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.MONEYLENDER].incomeOperations}
                    component={IncomeOperations}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.MONEYLENDER].cessionOperations}
                    component={CessionOperation}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.MONEYLENDER].generateReport}
                    component={GenerateReport}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.ADMIN].inquiry}
                    component={Inquiry}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.ADMIN].ratingESG}
                    component={RatingESG}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.ADMIN].home}
                    component={AdminHomePage}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.ADMIN].newUser}
                    component={NewUserDispatcher}
                    componentProps={{ resetStep: true }}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.ADMIN].companyInfo}
                    component={CompanyInfoDispatcher}
                    componentProps={{ resetStep: true }}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.ADMIN].onBoardingStatus}
                    component={OnBoardingStatus}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.ADMIN].customers}
                    component={TariffManagement}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.ADMIN].modifyBlockUser}
                    component={ModifyUserDispatcher}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.ADMIN].pendingRequest}
                    component={PendingRequest}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.ADMIN].invoice}
                    component={AdminActiveInvoice}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.ADMIN].sap}
                    component={SAPLogs}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.ADMIN].archiveMoneylender}
                    component={AdminArchive}
                    componentProps={{ role: Roles.MONEYLENDER }}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.ADMIN].archiveBuyer}
                    component={AdminArchive}
                    componentProps={{ role: Roles.BUYER }}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.ADMIN].archiveVendor}
                    component={AdminArchive}
                    componentProps={{ role: Roles.VENDOR }}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.ADMIN].generateReport}
                    component={GenerateReport}
                  />
                  <PrivateRoute
                    noNavbar
                    path={rolePaths.common.onBoarding}
                    component={OnBoardingCommonPage}
                    componentProps={{ comp: "common.onBoarding" }}
                  />
                  <PrivateRoute
                    noNavbar
                    path={rolePaths.common.onBoardingSpid}
                    component={OnBoardingCommonPage}
                    componentProps={{ isSpidProcess: true }}
                  />
                  <PrivateRoute
                    noNavbar
                    path={rolePaths.common.onBoardingSpidFail}
                    component={OnBoardingCommonPage}
                    componentProps={{ isSpidFailProcess: true }}
                  />
                  <PrivateRoute
                    noNavbar
                    path={rolePaths.common.documentSign}
                    component={DocumentSignDispatcher}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths.common.personalInfo}
                    component={PersonalInfo}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths.common.contactUs}
                    component={ContactUs}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths.common.swapUser}
                    component={SwapUser}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths.common.documentsManagement}
                    component={DocumentsManagementDispatcher}
                    componentProps={{ resetStep: true }}
                  />
                  <PrivateRoute
                    exact
                    path={rolePaths[Roles.ADMIN].ESGCertificateList}
                    component={ESGCertificateList}
                    componentProps={{ resetStep: true }}
                  />
                  <Route
                    exact
                    path={rolePaths.common.login}
                    component={Login}
                  />
                  <Route
                    exact
                    path={rolePaths.common.report}
                    component={Report}
                  />
                  <Route
                    exact
                    path={rolePaths.common.forgotPassword}
                    component={ForgotPassword}
                  />
                  <Route
                    path={rolePaths.common.resetPassword + "/:email/:tkn"}
                    component={ResetPassword}
                  />
                  <PrivateRoute path="*" component={PageNotFound} />
                </Switch>
              </div>
            </MainContainer>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </React.Suspense>
  );
}
