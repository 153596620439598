import * as React from 'react';
import { ReactNode } from 'react';
import './OnBoardingCard.scss';
import { useTranslation } from 'react-i18next';
import MyProgressBar from '../../Common/MyProgressBar/MyProgressBar';
import ProgressNavBar from '../../Common/ProgressNavBar/ProgressNavBar';
import CardContainerWithButtons from '../../Common/CardContainerWithButtons/CardContainerWithButtons';
import ProfilePic from '../../Common/ProfilePic/ProfilePic';
import Polaris_logo_flat_white from '../../../assets/Images/Polaris_logo_flat_white.svg';
import { useSelector } from 'react-redux';

interface Props {
  title?: string;
  children?: ReactNode;
  description?: string;
  percentage: number;
  activeMenuItem: any;
  confirmButtonDisabled?: boolean;
  error?: any;
  confirmDataLabel?: string;
  previousDataLabel?: string;
  arrowRightIcon?: boolean;
  style?: any;
  menuItems?: any;
  subtitle?: string;
  loading?: boolean;
  onPreviousButtonClick?(): void;
  onConfirmButtonClick?(): void;
}

export enum menuItemsIndividual {
  companyData,
  personalData,
  marketplaceMembership,
  questionnaire,
  digitalSignature,
}

export enum menuItemsCompanyOnboarding {
  personalData,
  marketplaceMembership,
  questionnaire,
  companyData,
  digitalSignature,
}

export enum menuItemsDocumentSign {
  introduction,
  documentsClauses,
  insertOTP,
  downloadDocument,
}

const OnBoardingCard: React.FC<Props> = ({
  onPreviousButtonClick,
  onConfirmButtonClick,
  title,
  activeMenuItem,
  percentage,
  confirmButtonDisabled,
  error,
  children,
  description,
  previousDataLabel,
  confirmDataLabel,
  style,
  subtitle,
  menuItems,
  loading,
}) => {
  const { t } = useTranslation();

  let blockPreviousStep = useSelector(
    (state: any) => state.auth.blockPreviousStep
  );

  return (
    <>
      <div className="onBoardingCard__orangeStripe" />
      <div className="onBoardingCard__topBar">
        <div className="onBoardingCard__topBarContainer">
          <div className="onBoardingCard__logoAndSubtitle">
            <div className="onBoardingCard__logo">
              <img
                style={{ verticalAlign: 'inherit' }}
                src={Polaris_logo_flat_white}
                alt="logo"
                width={'128px'}
              />
            </div>
            <div className="onBoardingCard__subtitle">
              {subtitle || t('onBoarding.title')}
            </div>
            <div className="onBoardingCard__profile">
              <ProfilePic
                style={{ color: 'white' }}
                className="onBoardingCard__navName"
                showName
              />
            </div>
          </div>
        </div>

        <div className="onBoardingCard__navBarAndProgressBarContainer">
          <ProgressNavBar
            items={menuItems || menuItemsIndividual}
            activeItem={activeMenuItem}
            translationPrefix={'onBoarding.'}
          />
          <div className="onBoardingCard__progressBarContainer">
            <MyProgressBar percentage={percentage} />
          </div>
        </div>
      </div>
      <CardContainerWithButtons
        title={title}
        description={description}
        onPreviousButtonClick={
          !blockPreviousStep ? onPreviousButtonClick : undefined
        }
        onConfirmButtonClick={onConfirmButtonClick}
        error={error}
        confirmButtonDisabled={confirmButtonDisabled}
        previousButtonLabel={previousDataLabel || t('onBoarding.previous')}
        confirmButtonLabel={confirmDataLabel || t('onBoarding.confirmData')}
        style={style}
        loading={loading}
      >
        {children || null}
      </CardContainerWithButtons>
    </>
  );
};

export default OnBoardingCard;
