import { networkService } from './NetworkService';
import {
  BlockedUserDTO,
  CustomerDTO,
  InviteCustomerDTORequest,
  InviteCustomerDTOResponse,
  SapErrorDTO,
  StockCreditLine,
  PendingUserRequest,
  AdminInvoiceDTO, EsgConfigurationDTO,
} from '../model/AdminDTO';
import { MyResponse } from '../model/ServiceDTO';
import { configService } from './ConfigService';
import { CashImprovementTariff, TariffDTO } from '../model/CompanyDTO';
import { Roles } from '../model/UserDTO';
import { DocumentTypeDownload } from '../screens/moneylender/DownloadVendorRegistry/DownloadVendorRegistry';

const BASE_URL = configService.getApiPath();

class AdminService {
  inviteCustomer = (
    data: InviteCustomerDTORequest
  ): Promise<MyResponse<InviteCustomerDTOResponse>> => {
    return networkService.post<
      InviteCustomerDTORequest,
      InviteCustomerDTOResponse
    >(BASE_URL + '/admin/inviteCustomer', data);
  };

  getBlockedUsers = (): Promise<MyResponse<BlockedUserDTO>> => {
    return networkService.get<BlockedUserDTO>(
      BASE_URL + '/admin/onboarding/blockedUser'
    );
  };

  unblockUser = (data: PendingUserRequest): Promise<MyResponse<any>> => {
    return networkService.post<any, BlockedUserDTO>(
      BASE_URL + '/admin/onboarding/unblockUser',
      data
    );
  };

  refuseUser = (data: PendingUserRequest): Promise<MyResponse<any>> => {
    return networkService.post<any, BlockedUserDTO>(
      BASE_URL + '/admin/onboarding/refuseUnblockUser',
      data
    );
  };

  getSigners = (): Promise<MyResponse<Array<string>>> => {
    return networkService.get<Array<string>>(
      BASE_URL + '/admin/retrieveSigners'
    );
  };

  getTariff = (
    vatNumber: string,
    role: Roles
  ): Promise<MyResponse<TariffDTO[]>> => {
    return networkService.get<TariffDTO[]>(
      `${BASE_URL}/admin/tariff/${vatNumber}/${role}`
    );
  };

  updateTariff = (updatedTariff: any): Promise<MyResponse<any>> => {
    return networkService.post<any, any>(
      `${BASE_URL}/admin/tariff/update`,
      updatedTariff
    );
  };

  getCashImprovementTariff = (
    vatNumber?: string
  ): Promise<MyResponse<CashImprovementTariff[]>> => {
    return networkService.get<CashImprovementTariff[]>(
      `${BASE_URL}/admin/tariff/${vatNumber}`
    );
  };

  getCustomers = (): Promise<MyResponse<Array<CustomerDTO>>> => {
    return networkService.get<Array<CustomerDTO>>(`${BASE_URL}/admin/tariff`);
  };

  getOnBoardingStatus = (): Promise<MyResponse<Array<any>>> => {
    return networkService.get<Array<any>>(`${BASE_URL}/admin/onboardingStatus`);
  };

  setTariff = (
    data: TariffDTO[],
    vatNumber: string,
    role: Roles
  ): Promise<MyResponse<any>> => {
    return networkService.post<TariffDTO[], any>(
      BASE_URL + `/admin/tariff/${vatNumber}/${role}`,
      data
    );
  };

  stockCreditLines = (): Promise<MyResponse<StockCreditLine[]>> => {
    return networkService.get<StockCreditLine[]>(
      `${BASE_URL}/company/stockCreditLines`
    );
  };

  vendorWithoutRelations = (): Promise<MyResponse<string[]>> => {
    return networkService.get<string[]>(
      `${BASE_URL}/company/vendorWithoutOffer`
    );
  };

  getSapErrors = (
    fromDate?: Date,
    toDate?: Date,
    quantity?: number
  ): Promise<MyResponse<SapErrorDTO>> => {
    return networkService.post<any, SapErrorDTO>(
      BASE_URL + `/admin/sapErrors`,
      { fromDate, toDate, quantity }
    );
  };

  retrySapErrors = (taskId: number): Promise<MyResponse<any>> => {
    return networkService.post<any, SapErrorDTO>(
      BASE_URL + `/admin/retryError`,
      { taskId }
    );
  };

  deleteSapErrors = (taskId: number): Promise<MyResponse<any>> => {
    return networkService.post<any, SapErrorDTO>(
      BASE_URL + `/admin/deleteError`,
      { taskId }
    );
  };

  getAdminInvoices = (): Promise<MyResponse<AdminInvoiceDTO[]>> => {
    return networkService.get<AdminInvoiceDTO[]>(
      BASE_URL + '/admin/activeInvoice'
    );
  };

  getInvoiceDetailsDataDocuments = (
    type: DocumentTypeDownload
  ): Promise<MyResponse<any>> => {
    return networkService.post<any, any>(
      BASE_URL + `/admin/invoiceDetails/${type}`,
      null
    );
  };

  getRatingESG = (): Promise<MyResponse<EsgConfigurationDTO[]>> => {
    return networkService.get<EsgConfigurationDTO[]>(
        BASE_URL + '/admin/esgConfiguration'
    );
  };

  setRatingEsg = (
      data: EsgConfigurationDTO,
  ): Promise<MyResponse<any>> => {
    return networkService.post<EsgConfigurationDTO, any>(
        BASE_URL + `/admin/esgConfiguration`,
        data
    );
  };

  setRatingEsgDelete = (id: number): Promise<MyResponse<any>> => {
    return networkService.post<any, any>(
        BASE_URL + `/admin/esgConfiguration/${id}`,
        { id }
    );
  };
}

export const adminService = new AdminService();
