import {
  InquiryDataRequestDTO,
  InquiryDataResponseDTO,
  InquiryRolesDTO,
} from '../model/InquiryDTO';
import { MyResponse } from '../model/ServiceDTO';
import { configService } from './ConfigService';
import { networkService } from './NetworkService';

const BASE_URL = configService.getApiPath();
class InquiryService {
  getInquiryRoles = (): Promise<MyResponse<InquiryRolesDTO>> => {
    return networkService.get<InquiryRolesDTO>(BASE_URL + '/inquiry');
  };

  inquiryData = (
    request: InquiryDataRequestDTO
  ): Promise<MyResponse<InquiryDataResponseDTO>> => {
    return networkService.post<InquiryDataRequestDTO, InquiryDataResponseDTO>(
      BASE_URL + '/inquiry',
      request
    );
  };
}

export const inquiryService = new InquiryService();
