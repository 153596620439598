import {MoneylenderAction, MoneylenderState} from "./moneylender-interface";
import {onlyUniqueCurrency} from "../dynamicDiscount/dynamicDiscount-reducers";
import {CreateCreditLineMoneylenderDTO, DownloadVendorRegistryRequestDTO, MoneylenderInvoiceDetailsDTO, IncomeOperationDTO, ProgramsWithoutConfigurationDTO, ResponseVendorProgramDTO, VendorInfo} from "../../model/MoneylenderDTO";
import {VendorInvoiceDTO} from "../../model/VendorDTO";
import {getNested, onlyUniqueElements} from "../../utils/commonUtils";
import {OperationDTO} from "../../model/DynamicDiscountDTO";

export const GET_CREDIT_LINES = 'getCreditLinesRequest';
export const GET_CREDIT_LINES_SUCCESS = 'getCreditLinesSuccess';
export const GET_CREDIT_LINES_FAIL = 'getCreditLinesFailed';
export const GET_CREDIT_LINES_SILENT = 'getCreditLinesRequestSilent';
export const GET_CREDIT_LINES_SUCCESS_SILENT = 'getCreditLinesSuccessSilent';
export const GET_CREDIT_LINES_FAIL_SILENT = 'getCreditLinesFailedSilent';
export const GET_MONEYLENDER = 'getMoneylenderRequest';
export const GET_MONEYLENDER_SUCCESS = 'getMoneylenderSuccess';
export const GET_MONEYLENDER_FAIL = 'getMoneylenderFailed';
export const GET_PROGRAMS_WITHOUT_CONFIGURATION = "getProgramsWithoutConfiguration";
export const GET_PROGRAMS_WITHOUT_CONFIGURATION_SUCCESS = "getProgramsWithoutConfigurationSuccess";
export const GET_PROGRAMS_WITHOUT_CONFIGURATION_FAIL = "getProgramsWithoutConfigurationFail";
export const SAVE_PROGRAMS_CONFIGURATION = "saveProgramsConfiguration";
export const SAVE_PROGRAMS_CONFIGURATION_SUCCESS = "saveProgramsConfigurationSuccess";
export const SAVE_PROGRAMS_CONFIGURATION_FAIL = "saveProgramsConfigurationFail";
export const CONFIGURATION_PRICES_STEP = "ConfigurationPricesStep";
export const SET_CREDIT_LINE_ID = "setCreditLineId";
export const SET_SELECTED_PROGRAMS_TO_CONFIGURE = "setSelectedProgramsToConfigure";
export const GET_CONFIGURATIONS = "getConfigurations";
export const GET_CONFIGURATIONS_SUCCESS = "getConfigurationsSuccess";
export const GET_CONFIGURATIONS_FAIL = "getConfigurationsFail";
export const GET_DETAILS_CONFIGURATION = "getDetailsConfiguration";
export const GET_DETAILS_CONFIGURATION_SUCCESS = "getDetailsConfigurationSuccess";
export const GET_DETAILS_CONFIGURATION_FAIL = "getDetailsConfigurationFail";
export const CLEAR_DETAILS_CONFIGURATION = "clearDetailsConfiguration";
export const GET_ACTIVE_INVOICES = "getActiveInvoices";
export const GET_ACTIVE_INVOICES_SUCCESS = "getActiveInvoicesSuccess";
export const GET_ACTIVE_INVOICES_FAIL = "getActiveInvoicesFail";
export const GET_INVOICE_DETAILS = "getInvoiceDetails";
export const GET_INVOICE_DETAILS_SUCCESS = "getInvoiceDetailsSuccess";
export const GET_INVOICE_DETAILS_FAIL = "getInvoiceDetailsFail";
export const GET_OPERATIONS = "getOperations";
export const GET_OPERATIONS_SUCCESS = "getOperationsSuccess";
export const GET_OPERATIONS_FAIL = "getOperationsFail";
export const GET_INVOICE_OPERATIONS = "getInvoiceOperations";
export const GET_INVOICE_OPERATIONS_SUCCESS = "getInvoiceOperationsSuccess";
export const GET_INVOICE_OPERATIONS_FAIL = "getInvoiceOperationsFail";
export const RETRIEVE_VENDOR = "retrieveVendor";
export const RETRIEVE_VENDOR_SUCCESS = "retrieveVendorSuccess";
export const RETRIEVE_VENDOR_FAIL = "retrieveVendorFail";
export const EDIT_VENDOR_INFO = "editVendorInfo";
export const EDIT_VENDOR_INFO_SUCCESS = "editVendorInfoSuccess";
export const EDIT_VENDOR_INFO_FAIL = "editVendorInfoFail";
export const RETRIEVE_VENDOR_PROGRAM = "retrieveVendorProgram";
export const RETRIEVE_VENDOR_PROGRAM_SUCCESS = "retrieveVendorProgramSuccess";
export const RETRIEVE_VENDOR_PROGRAM_FAIL = "retrieveVendorProgramFail";
export const RETRIEVE_VENDOR_INFO = "retrieveVendorInfo";
export const RETRIEVE_VENDOR_INFO_SUCCESS = "retrieveVendorInfoSuccess";
export const RETRIEVE_VENDOR_INFO_FAIL = "retrieveVendorInfoFail";
export const RESET_MONEYLENDER = "resetMoneylender";
export const GET_INVOICES = 'invoices';
export const GET_INVOICES_SUCCESS = 'getInvoicesSuccess';
export const GET_INVOICES_FAIL = 'getInvoicesFailed';
export const GET_INVOICES_BY_AMOUNT = 'getInvoicesByAmountAction';
export const GET_INVOICES_SUCCESS_BY_AMOUNT = 'getInvoicesByAmountSuccessAction';
export const GET_INVOICES_FAIL_BY_AMOUNT = 'getInvoicesByAmountFailAction';
export const GET_CREDIT_LINE_DETAILS = 'getCreditLineDetails';
export const GET_CREDIT_LINE_DETAILS_SUCCESS = 'getCreditLineDetailsSuccess';
export const GET_CREDIT_LINE_DETAILS_FAIL = 'getCreditLineDetailsFail';
export const SET_ALL_PROGRAMS_FLAG = 'setAllProgramsFlag';
export const SET_CREDIT_CURRENCY = 'setCreditCurrency';
export const GET_VENDOR_REGISTRY = 'getVendorRegistry';
export const GET_VENDOR_REGISTRY_SUCCESS = 'getVendorRegistrySuccess';
export const GET_VENDOR_REGISTRY_FAIL = 'getVendorRegistryFail';
export const GET_VENDOR_REGISTRY_DOWNLOAD = 'getVendorRegistryDownload';
export const GET_VENDOR_REGISTRY_SUCCESS_DOWNLOAD = 'getVendorRegistrySuccessDownload';
export const GET_VENDOR_REGISTRY_FAIL_DOWNLOAD = 'getVendorRegistryFailDownload';
export const INCOME_OPERATIONS_REQUEST = 'income_operations_request';
export const INCOME_OPERATIONS_SUCCESS = 'income_operations_success';
export const INCOME_OPERATIONS_FAIL = 'income_operations_fail';
export const GET_ALL_OPERATIONS = 'getAllOperations';
export const GET_ALL_OPERATIONS_SUCCESS = 'getAllOperationsSuccess';
export const GET_ALL_OPERATIONS_FAIL = 'getAllOperationFail';

const defaultState: MoneylenderState = {
    moneylenderResponse: "",
    isFetchingMoneylender: false,
    getCreditLineResponse: [],
    isFetchingGetCreditLine: null,
    getCreditLineError: "",
    getCreditLineResponseSilent: [],
    isFetchingGetCreditLineSilent: false,
    getCreditLineErrorSilent: "",
    currencyFilters: [],
    programWithoutConfigurationResponse: null,
    isFetchingProgramWithoutConfiguration: false,
    programWithoutConfigurationError: "",
    configurationPricesStep: "",
    creditLineId: -1,
    isFetchingSaveProgramsConfiguration: false,
    saveProgramsConfigurationResponse: "",
    saveProgramsConfigurationFail: "",
    selectedPrograms: [],
    isFetchingGetConfigurations: false,
    getConfigurationsResponse: [],
    getConfigurationsFail: "",
    isFetchingDetailsConfiguration: false,
    detailsConfigurationResponse: "",
    detailsConfigurationFail: "",
    isFetchingGetActiveInvoices: false,
    getActiveInvoicesSuccess: [],
    computedCurrencyFilters: null,
    getActiveInvoicesError: "",
    isFetchingGetInvoiceDetails: false,
    getInvoiceDetailsSuccess: {} as MoneylenderInvoiceDetailsDTO,
    getInvoiceDetailsFail: "",
    isFetchingGetOperations: false,
    getOperationsSuccess: [],
    getOperationsFail: "",
    isFetchingRetrieveVendors: null,
    retrieveVendorsResponse: [],
    retrieveVendorsFail: "",
    isFetchingEditVendorInfo: false,
    editVendorInfoResponse: "",
    editVendorInfosFail: "",
    isFetchingRetrieveVendorProgram: false,
    vendorProgramResponse: null,
    vendorProgramFail: "",
    isFetchingRetrieveVendorInfo: false,
    vendorInfoResponse: null,
    vendorInfoFail: null,
    invoices: [],
    isFetchingInvoicesMoneylender: false,
    isFetchingInvoicesByAmount: false,
    invoicesByAmountResponse: [],
    invoicesByAmountResponseFail: null,
    isFetchingCreditLineDetails: null,
    creditLineDetailsResponse: null,
    creditLineDetailsFail: null,
    creditLineName: "",
    allProgramsFlag: null,
    currencyCreditLine: "",
    isFetchingGetInvoiceOperations: null,
    getInvoiceOperationsSuccess: [],
    getInvoiceOperationsFail: "",
    isFetchingVendors: null,
    vendorRegistrySuccess: {} as DownloadVendorRegistryRequestDTO,
    vendorRegistryFail: null,
    isFetchingIncomeOperations: false,
    incomeOperations: [] as IncomeOperationDTO[],
    incomeOperationsFail: null,
    isFetchingAllOperations: false,
    getAllOperationsSuccess: [] as OperationDTO[],
    getAllOperationsFail: null
};

export function moneylenderReducer(state = defaultState, action: MoneylenderAction): MoneylenderState {
    switch (action.type) {
        case GET_MONEYLENDER:
            return {
                ...state,
                isFetchingMoneylender: true
            };

        case SET_CREDIT_CURRENCY:
            return {
                ...state,
                currencyCreditLine: action.payload
            };
        case GET_MONEYLENDER_SUCCESS:
            return {
                ...state,
                moneylenderResponse: action.payload,
                isFetchingMoneylender: false

            };
        case GET_MONEYLENDER_FAIL:
            return {
                ...state,
                moneylenderResponse: "",
                isFetchingMoneylender: false,
            };

        case GET_CREDIT_LINES:
            return {
                ...state,
                getCreditLineResponse: [],
                isFetchingGetCreditLine: true,
                getCreditLineError: "",
            };
        case GET_CREDIT_LINES_SUCCESS:
            const currencyFilters = action.payload && action.payload.map((item: any) => item.currency).filter(onlyUniqueCurrency);
            return {
                ...state,
                currencyFilters,
                getCreditLineResponse: action.payload,
                isFetchingGetCreditLine: false,
                getCreditLineError: "",
            };
        case GET_CREDIT_LINES_FAIL:
            return {
                ...state,
                getCreditLineResponse: [],
                isFetchingGetCreditLine: false,
                getCreditLineError: action.payload,
            };

        case GET_CREDIT_LINES_SILENT:
            return {
                ...state,
                getCreditLineResponseSilent: [],
                isFetchingGetCreditLineSilent: true,
                getCreditLineErrorSilent: "",
            };
        case GET_CREDIT_LINES_SUCCESS_SILENT:
            return {
                ...state,
                getCreditLineResponseSilent: action.payload,
                isFetchingGetCreditLineSilent: false,
                getCreditLineErrorSilent: "",
            };
        case GET_CREDIT_LINES_FAIL_SILENT:
            return {
                ...state,
                getCreditLineResponseSilent: [],
                isFetchingGetCreditLineSilent: false,
                getCreditLineErrorSilent: action.payload,
            };

        case GET_CREDIT_LINE_DETAILS:
            return {
                ...state,
                isFetchingCreditLineDetails: true,
                creditLineDetailsResponse: defaultState.creditLineDetailsResponse,
                creditLineDetailsFail: defaultState.creditLineDetailsFail
            };

        case GET_CREDIT_LINE_DETAILS_SUCCESS:
            return {
                ...state,
                isFetchingCreditLineDetails: false,
                creditLineDetailsResponse: action.payload as CreateCreditLineMoneylenderDTO,
                creditLineDetailsFail: defaultState.creditLineDetailsFail
            };

        case GET_CREDIT_LINE_DETAILS_FAIL:
            return {
                ...state,
                isFetchingCreditLineDetails: false,
                creditLineDetailsResponse: defaultState.creditLineDetailsResponse,
                creditLineDetailsFail: action.payload
            };

        case GET_PROGRAMS_WITHOUT_CONFIGURATION:
            return {
                ...state,
                programWithoutConfigurationResponse: {} as ProgramsWithoutConfigurationDTO,
                isFetchingProgramWithoutConfiguration: true,
                programWithoutConfigurationError: "",
            };

        case GET_PROGRAMS_WITHOUT_CONFIGURATION_SUCCESS:
            return {
                ...state,
                programWithoutConfigurationResponse: action.payload as ProgramsWithoutConfigurationDTO,
                isFetchingProgramWithoutConfiguration: false,
                programWithoutConfigurationError: "",
            };
        case GET_PROGRAMS_WITHOUT_CONFIGURATION_FAIL:
            return {
                ...state,
                programWithoutConfigurationResponse: {} as ProgramsWithoutConfigurationDTO,
                isFetchingProgramWithoutConfiguration: false,
                programWithoutConfigurationError: action.payload
            };
        case SAVE_PROGRAMS_CONFIGURATION:
            return {
                ...state,
                isFetchingSaveProgramsConfiguration: true,
                saveProgramsConfigurationResponse: "",
                saveProgramsConfigurationFail: ""
            };

        case SAVE_PROGRAMS_CONFIGURATION_SUCCESS:
            return {
                ...state,
                isFetchingSaveProgramsConfiguration: false,
                saveProgramsConfigurationResponse: action.payload,
                saveProgramsConfigurationFail: ""
            };
        case SAVE_PROGRAMS_CONFIGURATION_FAIL:
            return {
                ...state,
                isFetchingSaveProgramsConfiguration: false,
                saveProgramsConfigurationResponse: "",
                saveProgramsConfigurationFail: action.payload
            };
        case CONFIGURATION_PRICES_STEP:
            return {
                ...state,
                configurationPricesStep: action.payload
            };

        case SET_CREDIT_LINE_ID:
            return {
                ...state,
                creditLineId: +getNested(action, "payload", "creditLineId"),
                creditLineName: getNested(action, "payload", "creditLineName")
            };

        case SET_SELECTED_PROGRAMS_TO_CONFIGURE:
            return {
                ...state,
                selectedPrograms: action.payload
            };

        case SET_ALL_PROGRAMS_FLAG:
            return {
                ...state,
                allProgramsFlag: action.payload
            };
        case GET_CONFIGURATIONS:
            return {
                ...state,
                isFetchingGetConfigurations: true,
                getConfigurationsResponse: [],
                getConfigurationsFail: "",
            };

        case GET_CONFIGURATIONS_SUCCESS:
            return {
                ...state,
                isFetchingGetConfigurations: false,
                getConfigurationsResponse: action.payload,
                getConfigurationsFail: "",
            };
        case GET_CONFIGURATIONS_FAIL:
            return {
                ...state,
                isFetchingGetConfigurations: false,
                getConfigurationsResponse: "",
                getConfigurationsFail: action.payload,
            };

        case GET_DETAILS_CONFIGURATION:
            return {
                ...state,
                isFetchingDetailsConfiguration: true,
                detailsConfigurationResponse: "",
                detailsConfigurationFail: "",
            };

        case GET_DETAILS_CONFIGURATION_SUCCESS:
            return {
                ...state,
                isFetchingDetailsConfiguration: false,
                detailsConfigurationResponse: action.payload,
                detailsConfigurationFail: "",
            };
        case GET_DETAILS_CONFIGURATION_FAIL:
            return {
                ...state,
                isFetchingDetailsConfiguration: false,
                detailsConfigurationResponse: "",
                detailsConfigurationFail: action.payload,
            };

        case GET_ACTIVE_INVOICES:
            return {
                ...state,
                isFetchingGetActiveInvoices: true,
                getActiveInvoicesSuccess: null,
                getActiveInvoicesError: "",
            };

        case GET_ACTIVE_INVOICES_SUCCESS:
            const computedCurrencyFilters = ((action && action.payload && Array.isArray(action.payload)) &&
                action.payload.map((item: VendorInvoiceDTO) => item.currency).filter(onlyUniqueElements)) || [];
            return {
                ...state,
                isFetchingGetActiveInvoices: false,
                getActiveInvoicesSuccess: action.payload,
                computedCurrencyFilters,
                getActiveInvoicesError: "",
            };
        case GET_ACTIVE_INVOICES_FAIL:
            return {
                ...state,
                isFetchingGetActiveInvoices: false,
                getActiveInvoicesSuccess: null,
                getActiveInvoicesError: action.payload,
            };

        case GET_INVOICE_DETAILS:
            return {
                ...state,
                isFetchingGetInvoiceDetails: true,
                getInvoiceDetailsSuccess: null,
                getInvoiceDetailsFail: "",
            };

        case GET_INVOICE_DETAILS_SUCCESS:
            return {
                ...state,
                isFetchingGetInvoiceDetails: false,
                getInvoiceDetailsSuccess: action.payload as MoneylenderInvoiceDetailsDTO,
                getInvoiceDetailsFail: "",
            };
        case GET_INVOICE_DETAILS_FAIL:
            return {
                ...state,
                isFetchingGetInvoiceDetails: false,
                getInvoiceDetailsSuccess: null,
                getInvoiceDetailsFail: action.payload,
            };

        case GET_OPERATIONS:
            return {
                ...state,
                isFetchingGetOperations: true,
                getOperationsSuccess: null,
                getOperationsFail: "",
            };

        case GET_OPERATIONS_SUCCESS:
            return {
                ...state,
                isFetchingGetOperations: false,
                getOperationsSuccess: action.payload as OperationDTO[],
                getOperationsFail: "",
            };
        case GET_OPERATIONS_FAIL:
            return {
                ...state,
                isFetchingGetOperations: false,
                getOperationsSuccess: null,
                getOperationsFail: action.payload,
            };
        case GET_INVOICE_OPERATIONS:
            return {
                ...state,
                isFetchingGetInvoiceOperations: true,
                getInvoiceOperationsSuccess: null,
                getInvoiceOperationsFail: "",
            };

        case GET_INVOICE_OPERATIONS_SUCCESS:
            return {
                ...state,
                isFetchingGetInvoiceOperations: false,
                getInvoiceOperationsSuccess: action.payload as OperationDTO[],
                getInvoiceOperationsFail: "",
            };
        case GET_INVOICE_OPERATIONS_FAIL:
            return {
                ...state,
                isFetchingGetInvoiceOperations: false,
                getInvoiceOperationsSuccess: null,
                getInvoiceOperationsFail: action.payload,
            };
        case RETRIEVE_VENDOR:
            return {
                ...state,
                isFetchingRetrieveVendors: true,
                retrieveVendorsResponse: [],
                retrieveVendorsFail: "",
            };

        case RETRIEVE_VENDOR_SUCCESS:
            return {
                ...state,
                isFetchingRetrieveVendors: false,
                retrieveVendorsResponse: action.payload as VendorInfo[],
                retrieveVendorsFail: "",
            };

        case RETRIEVE_VENDOR_FAIL:
            return {
                ...state,
                isFetchingRetrieveVendors: false,
                retrieveVendorsResponse: [],
                retrieveVendorsFail: action.payload,
            };

        case EDIT_VENDOR_INFO:
            return {
                ...state,
                isFetchingEditVendorInfo: true,
                editVendorInfoResponse: [],
                editVendorInfosFail: "",
            };

        case EDIT_VENDOR_INFO_SUCCESS:
            return {
                ...state,
                isFetchingEditVendorInfo: false,
                editVendorInfoResponse: action.payload as VendorInfo[],
                editVendorInfosFail: "",
            };

        case EDIT_VENDOR_INFO_FAIL:
            return {
                ...state,
                isFetchingEditVendorInfo: false,
                editVendorInfoResponse: [],
                editVendorInfosFail: action.payload,
            };

        case RETRIEVE_VENDOR_PROGRAM:
            return {
                ...state,
                isFetchingRetrieveVendorProgram: true,
                vendorProgramResponse: null,
                vendorProgramFail: "",
            };

        case RETRIEVE_VENDOR_PROGRAM_SUCCESS:
            return {
                ...state,
                isFetchingRetrieveVendorProgram: false,
                vendorProgramResponse: action.payload as ResponseVendorProgramDTO[],
                vendorProgramFail: "",
            };

        case RETRIEVE_VENDOR_PROGRAM_FAIL:
            return {
                ...state,
                isFetchingRetrieveVendorProgram: false,
                vendorProgramResponse: null,
                vendorProgramFail: action.payload,
            };

        case RETRIEVE_VENDOR_INFO:
            return {
                ...state,
                isFetchingRetrieveVendorInfo: true,
                vendorInfoResponse: null,
                vendorInfoFail: "",
            };

        case RETRIEVE_VENDOR_INFO_SUCCESS:
            return {
                ...state,
                isFetchingRetrieveVendorInfo: false,
                vendorInfoResponse: action.payload,
                vendorInfoFail: "",
            };

        case RETRIEVE_VENDOR_INFO_FAIL:
            return {
                ...state,
                isFetchingRetrieveVendorInfo: false,
                vendorInfoResponse: null,
                vendorInfoFail: action.payload,
            };

        case CLEAR_DETAILS_CONFIGURATION:
            return {
                ...state,
                detailsConfigurationResponse: {},
            };

        case RESET_MONEYLENDER:
            return {
                ...state,
                ...defaultState,
            };
        case GET_INVOICES:
            return {
                ...state,
                isFetchingInvoicesMoneylender: true
            };
        case GET_INVOICES_SUCCESS:
            return {
                ...state,
                invoices: action.payload,
                isFetchingInvoicesMoneylender: false
            };
        case GET_INVOICES_FAIL:
            return {
                ...state,
                invoices: defaultState.invoices,
                isFetchingInvoicesMoneylender: false,
            };

        case GET_INVOICES_BY_AMOUNT:
            return {
                ...state,
                isFetchingInvoicesByAmount: true,
                invoicesByAmountResponse: [],
                invoicesByAmountResponseFail: []
            };

        case GET_INVOICES_SUCCESS_BY_AMOUNT:
            return {
                ...state,
                isFetchingInvoicesByAmount: false,
                invoicesByAmountResponse: action.payload,
                invoicesByAmountResponseFail: defaultState.invoicesByAmountResponseFail
            };

        case GET_INVOICES_FAIL_BY_AMOUNT:
            return {
                ...state,
                isFetchingInvoicesByAmount: false,
                invoicesByAmountResponse: defaultState.invoicesByAmountResponse,
                invoicesByAmountResponseFail: action.payload,
            };

        case GET_VENDOR_REGISTRY:
            return {
                ...state,
                isFetchingVendors: true,
                vendorRegistrySuccess: {} as DownloadVendorRegistryRequestDTO,
                vendorRegistryFail: null
            };

        case GET_VENDOR_REGISTRY_SUCCESS:
            return {
                ...state,
                isFetchingVendors: false,
                vendorRegistrySuccess: action.payload as DownloadVendorRegistryRequestDTO,
                vendorRegistryFail: null
            };

        case GET_VENDOR_REGISTRY_FAIL:
            return {
                ...state,
                isFetchingVendors: false,
                vendorRegistrySuccess: {} as DownloadVendorRegistryRequestDTO,
                vendorRegistryFail: action.payload,
            };
        case GET_ALL_OPERATIONS:
            return {
                ...state,
                isFetchingAllOperations: true,
                getAllOperationsSuccess: [] as OperationDTO[],
                getAllOperationsFail: null
            };

        case GET_ALL_OPERATIONS_SUCCESS:
            return {
                ...state,
                isFetchingAllOperations: false,
                getAllOperationsSuccess: action.payload as OperationDTO[],
                getAllOperationsFail: null
            };

        case GET_ALL_OPERATIONS_FAIL:
            return {
                ...state,
                isFetchingAllOperations: false,
                getAllOperationsSuccess: [] as OperationDTO[],
                getAllOperationsFail: action.payload,
            };

        case INCOME_OPERATIONS_REQUEST:
            return {
                ...state,
                isFetchingIncomeOperations: true,
                incomeOperations: null,
                incomeOperationsFail: []
            };

        case INCOME_OPERATIONS_SUCCESS:
            return {
                ...state,
                isFetchingIncomeOperations: false,
                incomeOperations: action.payload as IncomeOperationDTO[],
                incomeOperationsFail: []
            };

        case INCOME_OPERATIONS_FAIL:
            return {
                ...state,
                isFetchingIncomeOperations: false,
                incomeOperations: {} as IncomeOperationDTO[],
                incomeOperationsFail: action.payload,
            };

        default:
            return state;
    }
}
