import {BuyerInvoiceDTO} from "./BuyerDTO";
import {Roles} from "./UserDTO";

export interface VendorInvoiceDTO {
    documentId: string
    id: string[]
    buyer: string
    expiryDate: string
    effectiveExpireDate: string
    currency: string
    salable: boolean
    delayRateType: string
    amount: number
    payableAmount: number
    delayRate: number
    selected?: boolean
    creditor?: string
    status: PaymentStatus
    creditNote: boolean
    cessionDate: string
}

export enum PaymentStatus {
    NOT_PAYABLE = "NOT_PAYABLE",
    NOT_PAID = "NOT_PAID",
    PENDING = "PENDING",
    PAYABLE = "PAYABLE",
    PAID = "PAID"
}

export interface programDataDTO {
    programName: string
    programCode: string
    currency: string
    allInvoiceNumber: number
    availableAmount: number
    totalAmount: number
    availableInvoiceNumber: number
}

export interface PendingInvoiceDTO {
    operationId: string
    businessName: string
    amount: number
    disposalDate: Date
    purchaseAmount: number
    currency: string
}

export interface MoneylenderOfferRequest {
    buyerId: string
    programName: string
    invoices: string[]
}

export interface MoneylenderDTO {
    businessName: string
    country: string
    amount: number
    currency: string
    percentage: number
    saleAmount: number
    vatNumber: string
    offerType: string
    iban: string,
    teg: number | null
}

export interface MoneylenderPaymentOperationDTO {
    operationDate: string
    country: string
    disposalId: string
    targetCompany: string
    operationType: string
    amount: number
    currency: string
}

export interface BuyerDTO {
    buyerName: string
    vatNumber?: string
    invoices?: Array<programDataDTO>
    programs?: Array<programDataDTO>
}

export interface DisposalDataDTO {
    invoices: number[]
    moneylenderId: string
    type: string
    rateApplied: number
    bankInfoId: number
    iban: string
}

export enum SellInvoicesStep {
    CHOOSE_BUYER = "chooseBuyer",
    CHOOSE_PROGRAMS = "choosePrograms",
    CHOOSE_INVOICES = "chooseInvoices",
    CHOOSE_MONEYLENDER = "chooseMoneylender",
    CHECKOUT = "checkout",
    SALES_CONTRACT = "salesContract",
    PROCESS_COMPLETE = "processComplete",
}

export interface InvoicesHistoryDTO {
    month: InvoicesHistoryDetails
    year: InvoicesHistoryDetails
}

export interface InvoicesHistoryDetails {
    data: HistoryGroupedDTO[]
    totalInvoiceIn: number
    totalInvoiceOut: number
    totalInvoicePending: number
}

export interface HistoryGroupedDTO {
    date: number
    monthNumber?: number
    incoming: number
    outcoming: number
}

export interface StockHistoryDTO {
    moneylenders: number
    vendors: number
    moneylendersInvoices: number
    vendorsInvoices: number
    currency: string
}

export interface BankInfoResponseDTO {
    businessName: string
    address: string
    reference: string
    iban: string
    id: number
    selected?: boolean
}

export interface CheckoutData {
    nominalValue?: any
    numberOfInvoices?: any
    businessName?: any
    saleAmount?: any
    offerType?: Roles
    rateApplied?: any
    moneyLenderIban?: any
    invoices?: string[]
    moneylenderVatNumber?: any
    buyerVatNumber?: any
    buyerName?: any
    selectedProgram?: {
        programName: any
        currency: any
        availableInvoicesNumber: any
        availableInvoicesAmount: any
        programCode?: string
    }
}

export interface MPfeeDTO {
    flatFee?: number
    bpFee?: number
    iva?: number
}


export enum SellInvoiceStatus {
    ELIGIBLE = "ELIGIBLE",
    NOT_ELIGIBLE = "NOT_ELIGIBLE",
    BLOCKED = "BLOCKED"
}

export interface SellInvoicesResponse {
    totalAmount?: number
    invoicesData: VendorInvoiceDTO[] | BuyerInvoiceDTO[]
    preselectedCreditNotes: string[]
    status: SellInvoiceStatus | null
}

export interface EligibilityRequest {
    selectedInvoices: string[]
}

export interface EligibilityResponse {
    preselectedCreditNotes: string[]
    status: SellInvoiceStatus
}
