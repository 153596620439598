import * as React from 'react';
import "./DocumentData.scss";
import moment from "moment";
import TextInput from "../../../components/Common/TextInput/TextInput";
import MyDropdown from "../../../components/Common/MyDropdown/MyDropdown";
import DatePicker, {DatePickerTypes} from "../../../components/Common/DatePicker/DatePicker";
import OnboardingPlaceInputs from "../../../components/Common/OnboardingPlaceInputs/OnboardingPlaceInputs";
import {documentTypeList} from "../../onBoarding/onBoardingDocumentData/OnBoardingDocumentData";
import {countryUtils} from "../../../utils/countryUtils";
import ContainerRow from "../../../components/Common/ContainerRow/ContainerRow";
import i18n from "i18next";
import {extractFromKeyToLabel} from "../../../utils/commonUtils";
import {useTranslation} from "react-i18next";

interface Props {
    onFormValidChange: any,
    componentData: any,
    onBlur?: any,
    onChange: any,
    onLocalChange: any,
    style?: any,
}

const DocumentData: React.FC<Props> = ({
                                           onFormValidChange,
                                           componentData,
                                           onBlur,
                                           onChange,
                                           onLocalChange,
                                           style
                                       }) => {
    const {t} = useTranslation();
    const [openCalendarRelease, setOpenCalendarRelease] = React.useState(false);
    const [openCalendarExpiry, setOpenCalendarExpiry] = React.useState(false);

    const isItalian = componentData?.residenceCountry === "IT";


    React.useEffect(() => {
        const formIsValid =
            componentData.documentType
            && componentData.documentNumber
            && componentData.documentEmitter
            && componentData.documentCityEmitter
            && componentData.documentProvinceEmitter
            && componentData.documentNationEmitter
            && componentData.documentReleaseDate
            && componentData.documentExpiryDate;

        onFormValidChange?.(formIsValid);
        // eslint-disable-next-line
    }, [componentData, onFormValidChange]);

    return <div style={style}>
        <ContainerRow >
            <div className="inputContainer">
                <MyDropdown
                    links={isItalian ? documentTypeList() : [{key: "PASSPORT", label: i18n.t("onBoarding.buyer.documentData.document.PASSPORT")}]}
                    title={t("onBoarding.buyer.documentData.documentType")}
                    inputValue={extractFromKeyToLabel(componentData?.documentType)}
                    labelExtractor={({label}: any) => label}
                    onChange={({key}) => onChange({documentType: key})}
                />
            </div>
            <div className="inputContainer">
                <TextInput
                    title={t("onBoarding.buyer.documentData.documentNumber")}
                    value={componentData.documentNumber}
                    placeholder={t("common.inputField.insert") + " " + t("onBoarding.buyer.documentData.documentNumber")}
                    onBlur={(documentNumber) => onBlur?.({documentNumber})}
                    onChange={(documentNumber) => onLocalChange({documentNumber})}
                    delayedOnChange
                />
            </div>
            <div className="inputContainer">
                <TextInput
                    noAccentedLetters
                    title={t("onBoarding.buyer.documentData.documentEmitter")}
                    placeholder={t("common.inputField.insert") + " " + t("onBoarding.buyer.documentData.documentEmitter")}
                    value={componentData.documentEmitter}
                    onBlur={(documentEmitter) => onBlur?.({documentEmitter})}
                    onChange={(documentEmitter) => onLocalChange({documentEmitter})}
                    delayedOnChange
                />
            </div>
        </ContainerRow>

        <ContainerRow>
            <OnboardingPlaceInputs
                country={componentData.documentNationEmitter && countryUtils.fromCodeToCountryName(componentData.documentNationEmitter)}
                countryOnChange={(value: any) => onChange({documentProvinceEmitter: "", documentCityEmitter: "", documentNationEmitter: countryUtils.fromNameToISOCode(value)})}
                countryTitle={t("onBoarding.buyer.documentData.documentCountryEmitter")}
                province={componentData.documentProvinceEmitter && countryUtils.fromCodeToProvinceName(componentData.documentProvinceEmitter)}
                provinceOnChange={(value: any) => {
                    const newData: any = {documentProvinceEmitter: countryUtils.fromProvinceNameToCode(value)};
                    if (componentData.documentNationEmitter === "IT" && countryUtils.fromProvinceNameToCode(value) !== componentData.residenceProvince) {
                        newData.documentCityEmitter = "";
                    }
                    onChange(newData);
                }}
                provinceTitle={t("onBoarding.buyer.documentData.documentProvinceEmitter")}
                place={componentData.documentCityEmitter}
                placeOnChange={(documentCityEmitter: any) => onLocalChange({documentCityEmitter})}
                placeOnBlur={(documentCityEmitter: any) => onBlur?.({documentCityEmitter})}
                placePlaceholder={t("common.inputField.insert") + " " + t("onBoarding.buyer.documentData.documentCityPlaceholder")}
                placeTitle={t("onBoarding.buyer.documentData.documentCityEmitter")}
            />
        </ContainerRow>
        <ContainerRow>
            <div className="inputContainer">
                <DatePicker
                    title={t("onBoarding.buyer.documentData.documentReleaseDate")}
                    dateInput={componentData.documentReleaseDate && moment(componentData.documentReleaseDate)}
                    onDateChange={(documentReleaseDate) => onChange({documentReleaseDate: documentReleaseDate})}
                    openCalendar={openCalendarRelease}
                    setOpenCalendar={({focused}) => setOpenCalendarRelease(focused as boolean)}
                    type={DatePickerTypes.PAST_NO_TODAY}
                    required
                />
            </div>
            <div className="inputContainer">
                <DatePicker
                    title={t("onBoarding.buyer.documentData.documentExpiryDate")}
                    dateInput={componentData.documentExpiryDate && moment(componentData.documentExpiryDate)}
                    onDateChange={(documentExpiryDate) => onChange({documentExpiryDate: documentExpiryDate})}
                    openCalendar={openCalendarExpiry}
                    setOpenCalendar={({focused}) => setOpenCalendarExpiry(focused as boolean)}
                    type={DatePickerTypes.FUTURE_NO_TODAY}
                    required
                />
            </div>
            <div className="inputContainer"/>
        </ContainerRow>

    </div>;
};

export default DocumentData;

