import * as React from 'react';
import {SingleDatePicker} from "react-dates";
import 'react-dates/lib/css/_datepicker.css';
import "./DatePicker.scss";
import moment from 'moment';
import {languageUtils} from "../../../utils/languageUtils";
import "../MyDropdown/MyDropdown.scss";
import {useEffect, useState} from "react";

export enum DatePickerTypes {
    PAST_NO_TODAY,
    FUTURE_NO_TODAY,
    TODAY_AND_FUTURE,
    TODAY_AND_PAST,
    GREATER_THAN_18,
}

interface Props {
    title?: string,
    dateInput: any,
    openCalendar: boolean,
    type?: DatePickerTypes,
    className?: string
    setOpenCalendar(focused: any): void,
    onDateChange(date: any): void,
    afterDate?: any,
    beforeDate?: any,
    showClearDate?: boolean
    required?: boolean
}

const getListOfYears = (): JSX.Element[] => {
    let years = [];
    for (let i = moment().year() - 100; i <= moment().year() + 50; i++) {
        years.push(<option key={i} value={i}>{i}</option>);
    }
    return years;
};

const renderMonthElement = ({month, onMonthSelect, onYearSelect}: any) =>
    <div className={"SingleDatePickerLabel"}>
        <div>
            <select
                className="SingleDatePickerLabel__text"
                value={month.month()}
                onChange={(e) => onMonthSelect(month, e.target.value)}
            >
                {moment.months().map((label, value) => (
                    <option key={value} value={value}>
                        {label.charAt(0).toUpperCase() + label.substr(1).toLowerCase()}
                    </option>
                ))}
            </select>
        </div>
        <div>
            <select
                className="SingleDatePickerLabel__text"
                value={month.year()}
                onChange={(e) => onYearSelect(month, e.target.value)}
            >
                {getListOfYears()}
            </select>
        </div>
    </div>;


const DatePicker: React.FC<Props> = ({
                                         className,
                                         title = "",
                                         dateInput,
                                         type,
                                         setOpenCalendar,
                                         openCalendar,
                                         onDateChange,
                                         afterDate,
                                         beforeDate,
                                         showClearDate = false,
                                         required = false
                                     }) => {
    const [opened, setOpened] = useState(false);
    useEffect(() => {
        if (openCalendar) setOpened(true);
    }, [openCalendar]);
    let isOutsideRangeList: any[] = [];
    switch (type) {
        case DatePickerTypes.FUTURE_NO_TODAY:
            isOutsideRangeList.push((d: moment.Moment) => d.isBefore(moment().add(1, "day"), "day"));
            break;
        case DatePickerTypes.PAST_NO_TODAY:
            isOutsideRangeList.push((d: moment.Moment) => d.isAfter(moment().subtract(1, "day"), "day"));
            break;
        case DatePickerTypes.TODAY_AND_FUTURE:
            isOutsideRangeList.push((d: moment.Moment) => d.isBefore(moment(), "day"));
            break;
        case DatePickerTypes.TODAY_AND_PAST:
            isOutsideRangeList.push((d: moment.Moment) => d.isAfter(moment(), "day"));
            break;
        case DatePickerTypes.GREATER_THAN_18:
            isOutsideRangeList.push((d: moment.Moment) => d.isAfter(moment().subtract(18, "year"), "day"));
            break;
    }

    if (beforeDate) {
        isOutsideRangeList.push((d: moment.Moment) => !d.isBefore(moment(beforeDate).add(1, "day"), "day"));
    }

    if (afterDate) {
        isOutsideRangeList.push((d: moment.Moment) => !d.isAfter(moment(afterDate).subtract(1, "day"), "day"));
    }
    const isOutsideRange = (d: moment.Moment) => isOutsideRangeList?.reduce((acc, item) => (acc || item(d)), false);
    let classModifier = "";
    let titleModifier = "";
    if (title?.indexOf("*") > -1||required) {
        classModifier = (opened && !dateInput) ? " datePicker--wrong" : "";
        titleModifier = (opened && !dateInput) ? " (!)" : "";
    }
    return (
        <div className={className}>
            <div className={"customDropdown__titleContainer"}>
                <div className={"customDropdown__title" + classModifier}>{title + titleModifier}</div>
            </div>
            <SingleDatePicker
                showClearDate={showClearDate}
                // readOnly={true}
                date={dateInput && moment(dateInput)}
                onDateChange={onDateChange}
                onFocusChange={setOpenCalendar}
                focused={openCalendar}
                numberOfMonths={1}
                id={"CustomSingleDatePicker" + new Date().toISOString() + classModifier}
                placeholder={languageUtils.getLocaleDateFormat()}
                hideKeyboardShortcutsPanel={true}
                isOutsideRange={isOutsideRange}
                renderMonthElement={renderMonthElement}
                initialVisibleMonth={() => {
                    let out = moment();
                    if (dateInput) {
                        out = moment(dateInput);
                    } else if (type === DatePickerTypes.GREATER_THAN_18) {
                        out = moment().subtract(18, "year");
                    } else if (type === DatePickerTypes.FUTURE_NO_TODAY) {
                        out = moment().add(1, "day");
                    } else if (type === DatePickerTypes.PAST_NO_TODAY) {
                        out = moment().subtract(1, "day");
                    } else if (afterDate) {
                        out = moment(afterDate).add(1, "day");
                    } else if (beforeDate) {
                        out = moment(beforeDate).subtract(1, "day");
                    }
                    return out;
                }}
            />
        </div>
    );
};

export default DatePicker;
