import * as React from 'react';
import {useCallback} from 'react';
import "./AddUserData.scss";
import {useTranslation} from "react-i18next";
import Button, {BUTTON_TYPE} from "../../../../components/Common/Button/Button";
import ContainerRow from "../../../../components/Common/ContainerRow/ContainerRow";
import TextInput, {TextInputType} from "../../../../components/Common/TextInput/TextInput";
import MyDropdown from "../../../../components/Common/MyDropdown/MyDropdown";
import DatePicker, {DatePickerTypes} from "../../../../components/Common/DatePicker/DatePicker";
import moment from "moment";
import {countryUtils} from "../../../../utils/countryUtils";
import {extractFromKeyToLabel, isEmpty} from "../../../../utils/commonUtils";
import {documentTypeList} from "../../onBoardingDocumentData/OnBoardingDocumentData";
import forward_arrow_right from "../../../../assets/Icons/forward_arrow_right.svg";
import CustomUploadFile from "../../../../components/Common/CustomUploadFile/CustomUploadFile";
import tick from "../../../../assets/Icons/tick.svg";
import warning from "../../../../assets/Icons/warning.svg";
import OnboardingPlaceInputs from "../../../../components/Common/OnboardingPlaceInputs/OnboardingPlaceInputs";
import {UserFilesDTO} from "../../../../model/UserDTO";
import "../../onBoardingQuestionaire/OnBoardingQuestionnaire.scss";
import {generateOrValidateFiscalCode} from "../../../../utils/fiscalCodeUtils";
import {toastUtils} from "../../../../utils/toastUtils";
import {toast} from "react-toastify";

const maxFileSize = 4194304;

interface UploadProps {
    setError: (value: (((prevState: any) => any) | any)) => void,
    files: UserFilesDTO,
    onFileChange?: (files: UserFilesDTO) => any
}

export const UploadUserFiles = ({
                                    setError,
                                    files,
                                    onFileChange
                                }: UploadProps) => {
    const {t} = useTranslation();
    const [fileImages, setFileImages] = React.useState({} as UserFilesDTO);
    const [fileImagesToSend, setFileImagesToSend] = React.useState({} as UserFilesDTO);
    React.useEffect(() => {
        if (!isEmpty(files))
            setFileImages(files);
        // eslint-disable-next-line
    }, [files]);

    const setFiles = useCallback((newFiles: any) => {
        setFileImages({...fileImages, ...newFiles});
        setFileImagesToSend({...fileImagesToSend, ...newFiles});
        onFileChange?.({...fileImagesToSend, ...newFiles});
    }, [setFileImages, setFileImagesToSend, fileImages, fileImagesToSend, onFileChange]);

    return <>
        <CustomUploadFile
            title={t("onBoarding.company.legalRepresentative.uploadDocumentIdFront")}
            onClickUpload={(frontIdScan: string) => setFiles({frontIdScan})}
            maxSize={maxFileSize}
            setError={setError}
            fileData={fileImages.frontIdScan}
        />
        <CustomUploadFile
            title={t("onBoarding.company.legalRepresentative.uploadDocumentIdBack")}
            onClickUpload={(backIdScan: string) => setFiles({backIdScan})}
            maxSize={maxFileSize}
            setError={setError}
            fileData={fileImages.backIdScan}
        />
        <CustomUploadFile
            title={t("onBoarding.company.legalRepresentative.uploadFiscalCodeFront")}
            onClickUpload={(frontFiscalCodeScan: string) => setFiles({frontFiscalCodeScan})}
            maxSize={maxFileSize}
            setError={setError}
            fileData={fileImages.frontFiscalCodeScan}
        />
        <CustomUploadFile
            title={t("onBoarding.company.legalRepresentative.uploadFiscalCodeBack")}
            onClickUpload={(backFiscalCodeScan: string) => setFiles({backFiscalCodeScan})}
            maxSize={maxFileSize}
            setError={setError}
            fileData={fileImages.backFiscalCodeScan}
        />
    </>;
};

const AddUserData: React.FC<any> = ({
                                        onPrevious,
                                        initialData,
                                        precompiledData,
                                        confirmButtonLabel,
                                        previousButtonLabel,
                                        onBlur = () => {
                                        },
                                        onFileChange,
                                        onConfirm,
                                        error,
                                        isOwner,
                                        userFiles,
                                        disableConfirm,
                                        noEmail,
                                        onFormValidChange,
                                        additionalFields
                                    }) => {
    const {t} = useTranslation();
    const [data, setData] = React.useState(initialData || {});
    const [openCalendar, setOpenCalendar] = React.useState(false);
    const [openCalendarRelease, setOpenCalendarRelease] = React.useState(false);
    const [openCalendarExpiry, setOpenCalendarExpiry] = React.useState(false);
    const [errorAddUser, setErrorAddUser] = React.useState(error);
    const [userFilesToSend, setUserFilesToSend] = React.useState({} as UserFilesDTO);
    const sexType = [t("common.sex.M"), t("common.sex.F")];
    const [fiscalCodeError, setFiscalCodeError] = React.useState<any>();
    const [zipCodeNotValid, setZipCodeNotValid] = React.useState<boolean | null>(null);
    const [isEditingCF, setIsEditingCF] = React.useState<boolean | null>(null);

    React.useEffect(() => {
        setErrorAddUser(error);
    }, [error]);

    React.useEffect(() => {
        setData(precompiledData || {});
    }, [precompiledData]);

    const onConfirmButtonClick = onConfirm && (async () => onConfirm({data, fileImage: userFilesToSend}));

    const {
        birthDate,
        birthPlace,
        cf,
        documentCityEmitter,
        documentEmitter,
        documentExpiryDate,
        documentNationEmitter,
        documentNumber,
        documentProvinceEmitter,
        documentReleaseDate,
        documentType,
        email,
        institutionalPosition,
        name,
        nationality,
        politicallyExposed,
        publicOfficeHeld,
        region,
        residenceAddress,
        residenceCity,
        residenceCountry,
        residenceHouseNumber,
        residenceProvince,
        residenceZipCode,
        sex,
        surname,
        institutionalPositionCountry,
        mainIncome,
        officeHeld
    } = data;
    let personalDataDefined = name && surname && sex && birthDate && birthPlace && cf && email && nationality && region && residenceAddress && residenceCity && residenceCountry && residenceHouseNumber && residenceProvince && (residenceCountry === "IT" ? residenceZipCode?.length === 5 : residenceZipCode);
    let documentDataDefined = documentType && documentNumber && documentEmitter && documentCityEmitter && documentExpiryDate && documentNationEmitter && documentProvinceEmitter && documentReleaseDate;
    const politicallyExposedDefined = (politicallyExposed === true && institutionalPosition && institutionalPositionCountry && mainIncome) || politicallyExposed === false;
    const publicHeldDefined = (publicOfficeHeld === true && officeHeld) || publicOfficeHeld === false;
    const {frontFiscalCodeScan = "", frontIdScan = "", backFiscalCodeScan = "", backIdScan = ""} = {...userFiles, ...userFilesToSend};
    const filesAreValid = residenceCountry === "IT" ? (frontIdScan && backIdScan && frontFiscalCodeScan && backFiscalCodeScan) : (frontIdScan && backIdScan);
    const formIsValid = personalDataDefined && documentDataDefined && politicallyExposedDefined && publicHeldDefined && filesAreValid && fiscalCodeError !== "error";

    const updateData = useCallback((inputData: {}) => {
        setErrorAddUser("");
        setData({...data, ...inputData});
    }, [setData, setErrorAddUser, data]);


    const validateCF = useCallback((name: string, surname: string, birthDate: string, birthPlace: string, sex: string, region: string, country: string, cf: string) => {
        setFiscalCodeError(null);
        if (isItalian && data.region !== "EE") {
            let generatedCF = generateOrValidateFiscalCode(name, surname, birthDate, birthPlace, sex, region, country, cf);
            if (generatedCF) {
                if (data.cf !== generatedCF) {
                    onBlur({cf: generatedCF});
                    updateData({cf: generatedCF});
                }
            } else setFiscalCodeError("error");
        }
        // eslint-disable-next-line
    }, [onBlur, updateData, data.cf]);

    React.useEffect(() => {
        validateCF(data.name, data.surname, data.birthDate, data.birthPlace, data.sex, data.region, isItalian ? data.residenceCountry : "external", data.cf);
        // eslint-disable-next-line
    }, [data.name, data.surname, data.birthDate, data.birthPlace, data.sex, data.region, data.residenceCountry, data.cf]);


    React.useEffect(() => {
        onFormValidChange?.(formIsValid);
        // eslint-disable-next-line
    }, [data, formIsValid, onFormValidChange]);


    React.useEffect(() => {
        validateCF(name, surname, birthDate, birthPlace, sex, region, nationality, data.cf);
        // eslint-disable-next-line
    }, [initialData]);

    const isItalian = residenceCountry === "IT" && nationality === "IT";

    const cfTitle = <>
        {t("onBoarding.buyer.personalData.cf")}
        {isItalian && data.region !== "EE" &&
            <span className="underlined"
                  style={{cursor: "pointer", marginLeft: "5px"}}
                  onClick={() => {
                      onBlur({cf: ""});
                      updateData({cf: ""});
                      if (!name || !surname || !birthDate || !birthPlace || !sex || !region || !nationality) {
                          toastUtils.warningToast(t("onBoarding.errorFiscalCode"));
                      } else toast.dismiss();
                  }}
            >
            {t("common.button.recalculate")}
        </span>}
    </>;

    return (
        <>
            {additionalFields}
            <div className="addUser__titleSection">
                {t("onBoarding.buyer.personalData.title")}
            </div>
            <ContainerRow>
                <div className="inputContainer">
                    <TextInput
                        value={data.name}
                        inputType={TextInputType.ONLY_TEXT}
                        placeholder={t("common.inputField.insert") + " " + t("onBoarding.buyer.personalData.name")}
                        title={t("onBoarding.buyer.personalData.name")}
                        onBlur={(name) => onBlur({name})}
                        onChange={(name) => updateData({name})}
                        delayedOnChange
                    />
                </div>
                <div className="inputContainer">
                    <TextInput
                        title={t("onBoarding.buyer.personalData.surname")}
                        value={data.surname}
                        inputType={TextInputType.ONLY_TEXT}
                        placeholder={t("common.inputField.insert") + " " + t("onBoarding.buyer.personalData.surname")}
                        onBlur={(surname) => onBlur({surname})}
                        onChange={(surname) => updateData({surname})}
                        delayedOnChange
                    />
                </div>
                <div className="inputContainer">
                    <MyDropdown
                        links={sexType}
                        title={t("onBoarding.buyer.personalData.sex")}
                        onChange={(value) => {
                            onBlur({sex: value === t("common.sex.M") ? "M" : "F"});
                            updateData({sex: value === t("common.sex.M") ? "M" : "F"});
                        }}
                        inputValue={(data && data.sex && t("common.sex." + data.sex)) || ""}
                    />
                </div>
                <div className="inputContainer">
                    <DatePicker
                        title={t("onBoarding.buyer.personalData.birthday")}
                        dateInput={data.birthDate && moment(data.birthDate)}
                        onDateChange={(birthDate) => {
                            onBlur({birthDate: birthDate});
                            updateData({birthDate: birthDate});
                        }}
                        openCalendar={openCalendar}
                        setOpenCalendar={({focused}) => setOpenCalendar(focused as boolean)}
                        type={DatePickerTypes.GREATER_THAN_18}
                        required
                    />
                </div>
            </ContainerRow>
            <ContainerRow>
                <OnboardingPlaceInputs
                    country={data.residenceCountry && countryUtils.fromCodeToCountryName(data.residenceCountry)}
                    countryOnChange={(value: any) => {
                        onBlur({residenceProvince: "", residenceCity: "", residenceCountry: countryUtils.fromNameToISOCode(value)});
                        updateData({residenceProvince: "", residenceCity: "", residenceCountry: countryUtils.fromNameToISOCode(value)});
                    }}
                    countryTitle={t("onBoarding.buyer.personalData.residenceCountry")}
                    province={data.residenceProvince && countryUtils.fromCodeToProvinceName(data.residenceProvince)}
                    provinceOnChange={(value: any) => {
                        const updatedData: any = {residenceProvince: countryUtils.fromProvinceNameToCode(value)};
                        if (isItalian && data.residenceProvince !== countryUtils.fromProvinceNameToCode(value)) updatedData.residenceCity = "";
                        onBlur(updatedData);
                        updateData(updatedData);
                    }}
                    provinceTitle={t("onBoarding.buyer.personalData.residenceProvince")}
                    place={data.residenceCity}
                    placeOnChange={(residenceCity: any) => updateData({residenceCity})}
                    placeOnBlur={(residenceCity: any) => onBlur({residenceCity})}
                    placePlaceholder={t("common.inputField.insert") + " " + t("onBoarding.buyer.personalData.residenceCity")}
                    placeTitle={t("onBoarding.buyer.personalData.residenceCity")}
                />
                <div className="inputContainer">
                    <TextInput
                        title={t("onBoarding.buyer.personalData.residenceZipCode")}
                        placeholder={t("common.inputField.insert") + " " + t("onBoarding.buyer.personalData.residenceZipCode")}
                        value={data.residenceZipCode}
                        maxLength={isItalian ? 5 : undefined}
                        inputType={isItalian ? TextInputType.ITALIAN_ZIP_CODE : TextInputType.ALL_CHARACTER}
                        onFormatError={setZipCodeNotValid}
                        symbol={isItalian && (zipCodeNotValid === true ? <img src={warning} alt="warning"/>
                            : zipCodeNotValid === false ? <img src={tick} alt="tick "/> : null)}
                        onBlur={(residenceZipCode) => onBlur({residenceZipCode})}
                        onChange={(residenceZipCode) => updateData({residenceZipCode})}
                        delayedOnChange
                    />
                </div>
            </ContainerRow>
            <ContainerRow>
                <OnboardingPlaceInputs
                    country={data.nationality && countryUtils.fromCodeToCountryName(data.nationality)}
                    countryOnChange={(value: any) => {
                        onBlur({cf: "", birthPlace: "", region: "", nationality: countryUtils.fromNameToISOCode(value)});
                        updateData({cf: "", birthPlace: "", region: "", nationality: countryUtils.fromNameToISOCode(value)});
                    }}
                    countryTitle={t("onBoarding.buyer.personalData.nation")}
                    province={data.region && countryUtils.fromCodeToProvinceName(data.region)}
                    provinceOnChange={(value: any) => {
                        const updatedData: any = {region: countryUtils.fromProvinceNameToCode(value)};
                        if (isItalian && countryUtils.fromProvinceNameToCode(value) !== data.region) {
                            updatedData.cf = "";
                            updatedData.birthPlace = "";
                        }
                        onBlur(updatedData);
                        updateData(updatedData);
                    }}
                    provinceTitle={t("onBoarding.buyer.personalData.region")}
                    place={data.birthPlace}
                    placeOnChange={(birthPlace: any) => updateData({birthPlace})}
                    placeOnBlur={(birthPlace: any) => onBlur({birthPlace})}
                    placePlaceholder={t("common.inputField.insert") + " " + t("onBoarding.buyer.personalData.residenceCity")}
                    placeTitle={t("onBoarding.buyer.personalData.birthPlace")}
                    enableAllProvince={true}
                />
                <div className="inputContainer">
                    {isItalian && data.region !== "EE" && <TextInput
                        title={cfTitle}
                        placeholder={t("common.inputField.insert") + " " + t("onBoarding.buyer.personalData.cf")}
                        value={data.cf}
                        maxLength={16}
                        onBlur={(cf) => {
                            !fiscalCodeError && onBlur({cf});
                            setIsEditingCF(false);
                        }}
                        onFocus={() => setIsEditingCF(true)}
                        onChange={(value) => {
                            updateData({cf: value.toUpperCase()});
                        }}
                        symbol={data.cf && !isEditingCF && (!fiscalCodeError ? <img alt="tick" src={tick}/> : <img alt="warning" src={warning}/>)}
                        delayedOnChange
                    />}
                    {(!isItalian || (isItalian && data.region === "EE")) && <TextInput
                        title={cfTitle}
                        placeholder={t("common.inputField.insert") + " " + t("onBoarding.buyer.personalData.cf")}
                        value={data.cf}
                        onBlur={(cf) => {
                            !fiscalCodeError && onBlur({cf});
                            setIsEditingCF(false);
                        }}
                        onChange={(value) => {
                            updateData({cf: value.toUpperCase()});
                        }}
                        delayedOnChange
                    />}
                </div>
            </ContainerRow>
            <ContainerRow>
                <div className="inputContainer">
                    <TextInput
                        title={t("onBoarding.buyer.personalData.residenceAddress")}
                        placeholder={t("common.inputField.insert") + " " + t("onBoarding.buyer.personalData.residenceAddress")}
                        value={data.residenceAddress}
                        maxLength={30}
                        inputType={TextInputType.TEXT_AND_DIGITS}
                        onBlur={(residenceAddress) => onBlur({residenceAddress})}
                        onChange={(residenceAddress) => updateData({residenceAddress})}
                        delayedOnChange
                    />
                </div>
                <div className="inputContainer">
                    <TextInput
                        title={t("onBoarding.buyer.personalData.residenceHouseNumber")}
                        placeholder={t("common.inputField.insert") + " " + t("onBoarding.buyer.personalData.residenceHouseNumber")}
                        value={data.residenceHouseNumber}
                        maxLength={8}
                        onBlur={(residenceHouseNumber) => onBlur({residenceHouseNumber})}
                        onChange={(residenceHouseNumber) => updateData({residenceHouseNumber})}
                        delayedOnChange
                    />
                </div>
                <div className="inputContainer">
                    {!noEmail && <TextInput
                        title={t("onBoarding.buyer.personalData.email")}
                        placeholder={t("common.inputField.insert") + " " + t("onBoarding.buyer.personalData.email")}
                        value={data.email}
                        inputType={TextInputType.ALL_CHARACTER}
                        onBlur={(email) => onBlur({email})}
                        onChange={(email) => updateData({email})}
                        delayedOnChange
                    />}
                </div>
                <div className="inputContainer"/>
            </ContainerRow>

            {/*POLITICALLY EXPOSED SECTION*/}
            <div className="onBoardingQuestionnaire__rowWithButton">
                <div className="onBoardingQuestionnaire__label">
                    {t("onBoarding.buyer.questionnaire.politicallyExposed")}
                </div>
                <div className="onBoardingQuestionnaire__buttonContainer">
                    <div className="onBoardingQuestionnaire__button">
                        <Button
                            buttonType={data.politicallyExposed === true ? BUTTON_TYPE.buttonQuestionnaireClicked : BUTTON_TYPE.buttonQuestionnaire}
                            width="70px"
                            label={t("common.button.yes")}
                            onClick={() => {
                                onBlur({politicallyExposed: true});
                                updateData({politicallyExposed: true});
                            }}
                        />
                    </div>
                    <div className="onBoardingQuestionnaire__button">
                        <Button
                            buttonType={(data.politicallyExposed === false) ? BUTTON_TYPE.buttonQuestionnaireClicked : BUTTON_TYPE.buttonQuestionnaire}
                            width="70px"
                            label={t("common.button.no")}
                            onClick={() => {
                                onBlur({politicallyExposed: false});
                                updateData({
                                    politicallyExposed: false,
                                    institutionalPosition: null,
                                    institutionalPositionCountry: null,
                                    mainIncome: null,
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
            {data.politicallyExposed === true &&
            <div>
                <ContainerRow>
                    <div className="onBoardingQuestionnaire__containerCustomInput">
                        <TextInput
                            title={t("onBoarding.buyer.questionnaire.institutionalPosition")}
                            placeholder={t("common.inputField.insert") + " " + t("onBoarding.buyer.questionnaire.institutionalPositionPlaceholder")}
                            value={data.institutionalPosition}
                            onBlur={(institutionalPosition) => onBlur({institutionalPosition})}
                            onChange={(institutionalPosition) => updateData({institutionalPosition})}
                        />
                    </div>
                    <div className="onBoardingQuestionnaire__containerCustomInput">
                        <MyDropdown
                            links={countryUtils.retrievesCountryName()}
                            title={t("onBoarding.buyer.questionnaire.institutionalPositionCountry")}
                            inputValue={data.institutionalPositionCountry && countryUtils.fromCodeToCountryName(data.institutionalPositionCountry)}
                            onChange={(country) => {
                                onBlur({institutionalPositionCountry: countryUtils.fromNameToISOCode(country)});
                                updateData({institutionalPositionCountry: countryUtils.fromNameToISOCode(country)});
                            }}
                        />
                    </div>
                    <div className="onBoardingQuestionnaire__containerCustomInput">
                        <TextInput
                            title={t("onBoarding.buyer.questionnaire.mainIncome")}
                            placeholder={t("common.inputField.insert") + " " + t("onBoarding.buyer.questionnaire.mainIncomePlaceholder")}
                            value={data.mainIncome}
                            onBlur={(mainIncome) => onBlur({mainIncome})}
                            onChange={(value) => updateData({mainIncome: value})}
                        />
                    </div>
                </ContainerRow>
            </div>
            }
            <div className="onBoardingQuestionnaire__rowWithButton">
                <div className={"onBoardingQuestionnaire__label"}>
                    {t("onBoarding.buyer.questionnaire.publicOfficeHeld")}
                </div>
                <div className="onBoardingQuestionnaire__buttonContainer">
                    <div className="onBoardingQuestionnaire__button">
                        <Button
                            buttonType={(data.publicOfficeHeld === true) ? BUTTON_TYPE.buttonQuestionnaireClicked : BUTTON_TYPE.buttonQuestionnaire}
                            width="70px"
                            label={t("common.button.yes")}
                            onClick={() => {
                                onBlur({publicOfficeHeld: true});
                                updateData({publicOfficeHeld: true});
                            }}
                        />
                    </div>
                    <div className="onBoardingQuestionnaire__button">
                        <Button
                            buttonType={(data.publicOfficeHeld === false) ? BUTTON_TYPE.buttonQuestionnaireClicked : BUTTON_TYPE.buttonQuestionnaire}
                            width="70px"
                            label={t("common.button.no")}
                            onClick={() => {
                                onBlur({publicOfficeHeld: false});
                                updateData({publicOfficeHeld: false, officeHeld: null});
                            }}
                        />
                    </div>
                </div>
            </div>
            {data.publicOfficeHeld === true &&
            <div className="onBoardingQuestionnaire__row">
                <div className="onBoardingQuestionnaire__containerCustomInput">
                    <TextInput
                        title={t("onBoarding.buyer.questionnaire.officeHeld")}
                        placeholder={t("common.inputField.insert") + " " + t("onBoarding.buyer.questionnaire.officeHeld")}
                        value={data.officeHeld}
                        onBlur={(officeHeld) => onBlur({officeHeld})}
                        onChange={(value) => updateData({officeHeld: value})}
                    />
                </div>
            </div>
            }

            {/*IDENTITY SECTION*/}
            <div className="addUser__titleSection">
                {t("onBoarding.buyer.documentData.title")}
            </div>
            <div className="addUser__description">
                {t("onBoarding.company.legalRepresentative.identityDescription")}
            </div>
            <ContainerRow className="addUser__container">
                <div className="inputContainer addUser__quaterInput">
                    <MyDropdown
                        links={documentTypeList()}
                        title={t("onBoarding.buyer.documentData.documentType")}
                        inputValue={extractFromKeyToLabel(data.documentType) || ""}
                        labelExtractor={({label}) => label}
                        onChange={(documentObject) => {
                            updateData({documentType: documentObject.key});
                            onBlur({documentType: documentObject.key});
                        }}
                    />
                </div>
                <div className="inputContainer addUser__quaterInput">
                    <TextInput
                        title={t("onBoarding.buyer.documentData.documentNumber")}
                        value={data.documentNumber}
                        placeholder={t("common.inputField.insert") + " " + t("onBoarding.buyer.documentData.documentNumber")}
                        onBlur={(documentNumber) => onBlur({documentNumber})}
                        onChange={(documentNumber) => updateData({documentNumber})}
                    />
                </div>
                <div className="inputContainer addUser__quaterInput">
                    <DatePicker
                        title={t("onBoarding.buyer.documentData.documentReleaseDate")}
                        dateInput={data.documentReleaseDate && moment(data.documentReleaseDate)}
                        onDateChange={(documentReleaseDate) => {
                            onBlur({documentReleaseDate: documentReleaseDate});
                            updateData({documentReleaseDate: documentReleaseDate});
                        }}
                        openCalendar={openCalendarRelease}
                        setOpenCalendar={({focused}) => setOpenCalendarRelease(focused as boolean)}
                        type={DatePickerTypes.PAST_NO_TODAY}
                        required
                    />
                </div>
                <div className="inputContainer addUser__quaterInput addUser__quaterInput--noMarginRight">
                    <DatePicker
                        title={t("onBoarding.buyer.documentData.documentExpiryDate")}
                        dateInput={data.documentExpiryDate && moment(data.documentExpiryDate)}
                        onDateChange={(documentExpiryDate) => {
                            onBlur({documentExpiryDate: documentExpiryDate});
                            updateData({documentExpiryDate: documentExpiryDate});
                        }}
                        openCalendar={openCalendarExpiry}
                        setOpenCalendar={({focused}) => setOpenCalendarExpiry(focused as boolean)}
                        type={DatePickerTypes.FUTURE_NO_TODAY}
                        required
                    />
                </div>
                <div className="inputContainer addUser__quaterInput addUser__quaterInput--noMargin">
                    <TextInput
                        title={t("onBoarding.buyer.documentData.documentEmitter")}
                        placeholder={t("common.inputField.insert") + " " + t("onBoarding.buyer.documentData.documentEmitter")}
                        value={data.documentEmitter}
                        onBlur={(documentEmitter) => onBlur({documentEmitter})}
                        onChange={(value) => updateData({documentEmitter: value})}
                    />
                </div>
                <OnboardingPlaceInputs
                    itemClassName="inputContainer addUser__quaterInput"
                    country={data.documentNationEmitter && countryUtils.fromCodeToCountryName(data.documentNationEmitter)}
                    countryOnChange={(value: any) => {
                        const newData = {
                            documentNationEmitter: countryUtils.fromNameToISOCode(value),
                            documentCityEmitter: "",
                            documentProvinceEmitter: "",
                        };
                        onBlur(newData);
                        updateData(newData);
                    }}
                    countryTitle={t("onBoarding.buyer.documentData.documentCountryEmitter")}
                    province={countryUtils.fromCodeToProvinceName(data.documentProvinceEmitter)}
                    provinceOnChange={(value: any) => {
                        const newData: any = {documentProvinceEmitter: countryUtils.fromProvinceNameToCode(value)};
                        if (data?.documentNationEmitter === "IT" && countryUtils.fromProvinceNameToCode(value) !== data.documentProvinceEmitter) {
                            newData.documentCityEmitter = "";
                        }
                        onBlur(newData);
                        updateData(newData);
                    }}
                    provinceTitle={t("onBoarding.buyer.documentData.documentProvinceEmitter")}
                    place={data.documentCityEmitter}
                    placeOnChange={(value: any) => updateData({documentCityEmitter: value})}
                    placeOnBlur={(documentCityEmitter: any) => onBlur({documentCityEmitter})}
                    placePlaceholder={t("common.inputField.insert") + " " + t("onBoarding.buyer.documentData.documentCityPlaceholder")}
                    placeTitle={t("onBoarding.buyer.documentData.documentCityEmitter")}
                />

            </ContainerRow>
            <div className="addUser__description">
                {t("onBoarding.company.legalRepresentative.scanDocumentIdDescription")}
            </div>

            <div className="addUser__upload">
                <UploadUserFiles
                    files={userFiles}
                    setError={setErrorAddUser}
                    onFileChange={(files) => {
                        setUserFilesToSend(files);
                        onFileChange?.(files);
                    }}
                />
            </div>
            {errorAddUser &&
            <div className="addUser__error">
                {t(errorAddUser)}
            </div>}
            <div className="containerButtons">
                {onPrevious &&
                <div className="containerButtonConfirm containerButtonConfirm--marginRight20">
                    <Button
                        label={previousButtonLabel || t("onBoarding.previous")}
                        onClick={onPrevious}
                        icon={forward_arrow_right}
                        buttonType={BUTTON_TYPE.primaryInverted}
                        iconOnTheLeft
                    />
                </div>}

                {onConfirmButtonClick &&
                <div className="containerButtonConfirm">
                    <Button
                        label={confirmButtonLabel || t("onBoarding.confirmData")}
                        onClick={onConfirmButtonClick}
                        icon={forward_arrow_right}
                        disabled={!formIsValid || disableConfirm}
                    />
                </div>
                }
            </div>
        </>

    );
};

export default AddUserData;
