import { networkService } from "./NetworkService";
import {
  AlignmentParameterDTO,
  AvailableProgramsResponse,
  BaseRateDTO,
  ProgramDTO,
} from "../model/ProgramDTO";
import { MyResponse } from "../model/ServiceDTO";
import { configService } from "./ConfigService";
import {
  DynamicDiscountDetailsDTO,
  DynamicDiscountDTO,
  OperationDTO,
} from "../model/DynamicDiscountDTO";
import {
  BuyerActiveInvoiceDTO,
  BuyerCessionOperationsDTO,
  BuyerEditableInvoiceDTO,
  BuyerInvoiceDTO,
  BuyerInvoicesRequest,
  BuyerInvoicesSelectedMethod,
  BuyerMoneylenderInfoDTO,
  DynamicDiscountAssociatedDTO,
  EditMoneylenderInfoDTO,
} from "../model/BuyerDTO";
import { DocumentTypeDownload } from "../screens/moneylender/DownloadVendorRegistry/DownloadVendorRegistry";
import { AdminInvoiceDTO } from "../model/AdminDTO";
import { DocumentZippedDTO } from "../model/MoneylenderDTO";
import { VendorRegistryDocumentRequestDTO } from "../model/BuyerDTO";

const BASE_URL = configService.getApiPath();

class BuyerService {
  getPrograms = (
    vatNumber?: string
  ): Promise<MyResponse<Array<ProgramDTO>>> => {
    return vatNumber
      ? networkService.get<Array<ProgramDTO>>(
          BASE_URL + `/programs/${vatNumber}`
        )
      : networkService.get<Array<ProgramDTO>>(BASE_URL + "/programs");
  };

  getProgramsAvailable = (
    currency?: string,
    vatNumber?: string
  ): Promise<MyResponse<AvailableProgramsResponse>> => {
    return networkService.get<AvailableProgramsResponse>(
      BASE_URL +
        `/programs/available/?${currency ? "currency=" + currency : ""}${
          vatNumber ? "&buyerId=" + vatNumber : ""
        }`
    );
  };

  getBaseRate = async (): Promise<MyResponse<Array<BaseRateDTO>>> => {
    return networkService.get<Array<BaseRateDTO>>(
      BASE_URL + "/programs/baseRates"
    );
  };

  getProgramTypes = async (): Promise<MyResponse<Array<number>>> => {
    return networkService.get<Array<number>>(
      BASE_URL + "/programs/enabledTypes"
    );
  };

  getAlignmentParameter = (): Promise<
    MyResponse<Array<AlignmentParameterDTO>>
  > => {
    return networkService.get<Array<AlignmentParameterDTO>>(
      BASE_URL + "/programs/alignmentParameters"
    );
  };

  getEnabledCurrency = (): Promise<MyResponse<Array<string>>> => {
    return networkService.get<Array<string>>(
      BASE_URL + "/programs/enabledCurrencies"
    );
  };

  getDynamicDiscount = (
    vatNumber?: string
  ): Promise<MyResponse<Array<DynamicDiscountDTO>>> => {
    return vatNumber
      ? networkService.get<Array<DynamicDiscountDTO>>(
          `${BASE_URL}/buyer/dynamicDiscount/${vatNumber}`
        )
      : networkService.get<Array<DynamicDiscountDTO>>(
          BASE_URL + "/buyer/dynamicDiscount"
        );
  };

  getDynamicDiscountInfo = (
    dynamicDiscountId: number
  ): Promise<MyResponse<DynamicDiscountDetailsDTO>> => {
    return networkService.get<any>(
      BASE_URL + `/buyer/dynamicDiscountDetails/${dynamicDiscountId}`
    );
  };

  getOperations = (
    dynamicDiscountId: number
  ): Promise<MyResponse<OperationDTO[]>> => {
    return networkService.get<OperationDTO[]>(
      BASE_URL + `/buyer/operations/${dynamicDiscountId}`
    );
  };

  getInvoiceCurrencies = (): Promise<MyResponse<string[]>> => {
    return networkService.get<any>(
      BASE_URL + "/buyer/availableInvoiceCurrencies"
    );
  };

  getDynamicDiscountAssociated = (): Promise<
    MyResponse<DynamicDiscountAssociatedDTO>
  > => {
    return networkService.get<DynamicDiscountAssociatedDTO>(
      BASE_URL + "/buyer/creditLineAssociated"
    );
  };

  getInvoiceDetailsDataDocuments = (
    type: DocumentTypeDownload
  ): Promise<MyResponse<any>> => {
    return networkService.post<any, any>(
      BASE_URL + `/buyer/invoiceDetails/${type}`,
      null
    );
  };

  getInvoices = (
    selectionMethod: BuyerInvoicesSelectedMethod,
    amount?: number,
    currency?: string
  ): Promise<MyResponse<Array<BuyerInvoiceDTO>>> => {
    const request = { selectionMethod } as BuyerInvoicesRequest;
    if (amount) request.amount = amount;
    if (currency) request.currency = currency;
    return networkService.post<BuyerInvoicesRequest, Array<BuyerInvoiceDTO>>(
      BASE_URL + "/buyer/treasury",
      request
    );
  };

  savePaidInvoices = (invoices: string[]): Promise<MyResponse<any>> => {
    const request = { invoices };
    return networkService.post<any, Array<string>>(
      BASE_URL + "/buyer/treasury/invoices",
      request
    );
  };

  getBuyerActiveInvoices = (): Promise<MyResponse<BuyerActiveInvoiceDTO[]>> => {
    return networkService.get<AdminInvoiceDTO[]>(
      BASE_URL + "/buyer/activeInvoice"
    );
  };

  getVendorRegistryDownload = (
    body: VendorRegistryDocumentRequestDTO,
    type: DocumentTypeDownload
  ): Promise<MyResponse<DocumentZippedDTO>> => {
    return networkService.post<any, any>(
      BASE_URL + `/buyer/vendorRegistryDocuments/${type}`,
      body
    );
  };

  getEditableInvoices = (): Promise<MyResponse<BuyerEditableInvoiceDTO[]>> => {
    const url = "/buyer/editableInvoices";
    return networkService.get<BuyerEditableInvoiceDTO[]>(BASE_URL + url);
  };

  getBuyerCessionOperations = (): Promise<
    MyResponse<BuyerCessionOperationsDTO[]>
  > => {
    const url = "/buyer/cessionOperations";
    return networkService.get<BuyerCessionOperationsDTO[]>(BASE_URL + url);
  };

  getBuyerCessionDocuments = (
    body: string[],
    type: DocumentTypeDownload
  ): Promise<MyResponse<any>> => {
    return networkService.post<any, any>(
      BASE_URL + `/buyer/cessionOperationsDocuments/${type}`,
      body
    );
  };

  retrieveMoneylenders = (): Promise<MyResponse<BuyerMoneylenderInfoDTO[]>> => {
    const url = "/buyer/retrieveMoneylender";
    return networkService.get<BuyerMoneylenderInfoDTO[]>(BASE_URL + url);
  };

  retrieveMoneylenderInfo = (
    vatNumber: string
  ): Promise<MyResponse<EditMoneylenderInfoDTO>> => {
    return networkService.get<EditMoneylenderInfoDTO>(
      BASE_URL + `/buyer/retrieveMoneylender/${vatNumber}`
    );
  };

  retrieveMoneyLenderCreditLine = (
    vatNumber: string
  ): Promise<MyResponse<any>> => {
    return networkService.get<any>(
      BASE_URL + `/buyer/retrieveCreditLines/${vatNumber}`
    );
  }

  editMoneylenderInfo = (
    requestEditMoneylenderInfo: EditMoneylenderInfoDTO
  ): Promise<MyResponse<any>> => {
    return networkService.post<any, any>(
      BASE_URL + `/buyer/editMoneylenderInfo`,
      requestEditMoneylenderInfo
    );
  };
}

export const buyerService = new BuyerService();
