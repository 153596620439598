import isoCountries from "i18n-iso-countries";
import italian_province from "../assets/italian_province.json";
import {languageUtils} from "./languageUtils";
import i18n from "i18next";
import {COMUNI} from "../assets/comuni.js"

class CountryUtils {

    initCountry = () => {
        isoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
        isoCountries.registerLocale(require("i18n-iso-countries/langs/it.json"));
    };

    fromCodeToCountryName = (code: string): string => {
        this.initCountry();
        if (isoCountries.isValid(code)) {
            return isoCountries.getName(code, languageUtils.getLocaleLanguage());
        } else {
            return '';
        }
    };

    fromNameToISOCode = (country: string): string => {
        this.initCountry();
        return isoCountries.getAlpha2Code(country, languageUtils.getLocaleLanguage());
    };

    retrievesCountryName = (): string[] => {
        this.initCountry();
        let localizedCountryNames = isoCountries.getNames(languageUtils.getLocaleLanguage());
        let ISONames: string[] = [];
        Object.keys(localizedCountryNames).forEach((key: string) => {
            ISONames.push(localizedCountryNames[key]);
        });
        return ISONames;
    };

    retrievePrefixCountry = (): string[] => {
        this.initCountry();
        let localizedCountryNames = isoCountries.getNames(languageUtils.getLocaleLanguage());
        return Object.keys(localizedCountryNames);
    };

    getAllItalianProvince = (): string[] => {
        let provinceKey = Object.keys(italian_province.province);
        let provinceName: string[] = [];
        //provinceName.push(" " + i18n.t(italian_province.EE));
        provinceKey.forEach((key: string) => {
            let name = (italian_province.province as any)[key] as string;
            provinceName.push(name);
        });
        return provinceName;
    };

    fromCodeToProvinceName = (code: string): string => {
        return code === "EE" ? i18n.t(italian_province.EE) : (italian_province.province as any)[code] || code;
    };

    fromProvinceNameToCode = (name: string): string => {
        let provinceCode = "EE";
        Object.keys(italian_province.province).forEach((key: string) => {
            if (name === (italian_province.province as any)[key])
                provinceCode = key;
        });
        return provinceCode;
    };

    getAllComuni = (province: string):Array<any> => {
        return province !== "EE" ? COMUNI.filter((item) => item[1] === province).map((item) => item[2].charAt(0).toUpperCase() + item[2].slice(1).toLowerCase()) : [];
    }
}

export const countryUtils = new CountryUtils();
