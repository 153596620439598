import {CommonAction, CommonState} from "./common-interface";
import {ReminderResponseDTO} from "../../model/MoneylenderDTO";
import {TicketDTO} from "../../model/TicketDTO";
import {OnboardedUserDataDTO} from "../../model/UserDTO";

export const SET_REMINDER = 'setReminder';
export const SET_REMINDER_SUCCESS = 'setReminderSuccess';
export const SET_REMINDER_FAIL = 'setReminderFail';
export const GET_REMINDER = 'getReminder';
export const GET_REMINDER_SUCCESS = 'getReminderSuccess';
export const GET_REMINDER_FAIL = 'getReminderFail';
export const GET_TICKET = 'getTicket';
export const GET_TICKET_SUCCESS = 'getTicketResponse';
export const GET_TICKET_FAIL = 'getTicketFail';
export const GET_TICKET_SILENT = 'getTicketSilent';
export const GET_TICKET_SUCCESS_SILENT = 'getTicketResponseSilent';
export const GET_TICKET_FAIL_SILENT = 'getTicketFailSilent';
export const CREATE_TICKET = 'createTicket';
export const CREATE_TICKET_SUCCESS = 'createTicketResponse';
export const CREATE_TICKET_FAIL = 'createTicketFail';
export const CALL_REQUEST = 'call_request';
export const CALL_SUCCESS = 'call_success';
export const CALL_FAIL = 'call_fail';
export const SET_BUSY = 'setBusy';
export const RESET_COMMON = 'resetCommon';
export const GET_USER_DATA_REQUEST = "getUserDataRequest";
export const GET_USER_DATA_SUCCESS = "getUserDataSuccess";
export const GET_USER_DATA_FAIL = "getUserDataFail";



const defaultState: CommonState = {
    isFetchingSetReminder: null,
    setReminderSuccess: "",
    setReminderFail: "",
    isFetchingGetReminder: null,
    getReminderSuccess: {} as ReminderResponseDTO,
    getReminderFail: "",
    isFetchingGetTicket: null,
    getTicketSuccess: [] as TicketDTO[],
    getTicketFail: "",
    isFetchingCreateTicket: null,
    createTicketFail: "",
    createTicketSuccess: "",
    ticketPresented: false,
    isFetchingCommonCall: null,
    commonCallFail: "",
    commonCallSuccess: "",
    busy: false,
    isFetchingGetUserData: false,
    getUserDataSuccess: {} as OnboardedUserDataDTO,
    getUserDataFail: "",
};

export function commonReducer(state = defaultState, action: CommonAction): CommonState {
    switch (action.type) {
        case SET_REMINDER:
            return {
                ...state,
                isFetchingSetReminder: true,
                setReminderSuccess: "",
                setReminderFail: ""
            };
        case SET_REMINDER_SUCCESS:
            return {
                ...state,
                isFetchingSetReminder: false,
                setReminderSuccess: action.payload,
                setReminderFail: ""
            };
        case SET_REMINDER_FAIL:
            return {
                ...state,
                isFetchingSetReminder: false,
                setReminderSuccess: "",
                setReminderFail: action.payload,
            };

        case GET_REMINDER:
            return {
                ...state,
                isFetchingGetReminder: true,
                getReminderSuccess: null,
                getReminderFail: ""
            };
        case GET_REMINDER_SUCCESS:
            return {
                ...state,
                isFetchingGetReminder: false,
                getReminderSuccess: action.payload,
                getReminderFail: ""
            };
        case GET_REMINDER_FAIL:
            return {
                ...state,
                isFetchingGetReminder: false,
                getReminderSuccess: null,
                getReminderFail: action.payload,
            };
        case GET_TICKET:
            return {
                ...state,
                isFetchingGetTicket: true,
                getTicketFail: defaultState.getTicketFail
            };
        case GET_TICKET_SUCCESS:
            return {
                ...state,
                isFetchingGetTicket: false,
                getTicketSuccess: action.payload,
                getTicketFail: defaultState.getTicketFail,
                ticketPresented: action.payload.length
            };
        case GET_TICKET_FAIL:
            return {
                ...state,
                isFetchingGetTicket: false,
                getTicketSuccess: defaultState.getTicketSuccess,
                getTicketFail: action.payload,
            }
        case GET_TICKET_SILENT:
            return {
                ...state
            };
        case GET_TICKET_SUCCESS_SILENT:
            return {
                ...state,
                ticketPresented: action.payload.length
            };
        case GET_TICKET_FAIL_SILENT:
            return {
                ...state,
                ticketPresented: false,
            };
        case CREATE_TICKET:
            return {
                ...state,
                isFetchingCreateTicket: true,
                createTicketFail: defaultState.createTicketFail,
                createTicketSuccess: defaultState.createTicketSuccess,
            };
        case CREATE_TICKET_SUCCESS:
            return {
                ...state,
                isFetchingCreateTicket: false,
                createTicketSuccess: action.payload,
                createTicketFail: defaultState.createTicketFail,
            };
        case CREATE_TICKET_FAIL:
            return {
                ...state,
                isFetchingCreateTicket: false,
                createTicketFail: action.payload,
                createTicketSuccess: defaultState.createTicketSuccess,
            };
        case CALL_REQUEST:
            return {
                ...state,
                isFetchingCommonCall: true,
                commonCallFail: defaultState.commonCallFail,
                commonCallSuccess: defaultState.commonCallSuccess,
            };
        case CALL_SUCCESS:
            return {
                ...state,
                isFetchingCommonCall: false,
                commonCallFail: defaultState.commonCallFail,
                commonCallSuccess: action.payload,
            };
        case CALL_FAIL:
            return {
                ...state,
                isFetchingCommonCall: false,
                commonCallFail: action.payload,
                commonCallSuccess: defaultState.commonCallSuccess,
            };
        case SET_BUSY:
            return {
                ...state,
                busy: action.payload,
            };
        case RESET_COMMON:
            return {
                ...state,
                ...defaultState
            };

        case GET_USER_DATA_REQUEST:
            return {
                ...state,
                isFetchingGetUserData: true,
                getUserDataSuccess: defaultState.getUserDataSuccess,
                getUserDataFail: defaultState.getUserDataFail
            };
        case GET_USER_DATA_SUCCESS:
            return {
                ...state,
                isFetchingGetUserData: false,
                getUserDataSuccess: action.payload,
                getUserDataFail: defaultState.getUserDataFail
            };
        case GET_USER_DATA_FAIL:
            return {
                ...state,
                isFetchingGetUserData: false,
                getUserDataSuccess: defaultState.getUserDataSuccess,
                getUserDataFail: action.payload
            };


        default:
            return state;
    }
}
