import * as React from 'react';
import "./CustomUploadFile.scss";
import {useTranslation} from "react-i18next";
import Button from "../Button/Button";
import download_icon_white from "../../../assets/Icons/download_icon_white.svg";
import i18n from "i18next";
import {toastUtils} from "../../../utils/toastUtils";
import pdf_icon from "../../../assets/Icons/pdf_icon.svg";
import img_icon from "../../../assets/Icons/img_icon.svg";
import {FileToDownloadDTO, MimeTypes} from "../../../model/AdminDTO";
import {downloadDocument} from "../../../utils/commonUtils";
import Spinner from "../Spinner/Spinner";

interface Props {
    title?: string
    acceptedTypes?: string[]
    onClickUpload?(element: any, name?: string): void,
    setError?(error: string): void,
    maxSize?: number
    fileData: any | FileToDownloadDTO
    hasDownload?: boolean
}

export const setFile = (event: any,
                        maxSize: number,
                        acceptedTypes: string[],
                        successCallback: (base64: string, name?: string, type?: string) => void,
                        failCallback: (error: string) => void) => {
    const file = event.target.files[0];
    if (file && acceptedTypes && acceptedTypes.every(type => file.type !== type)) {
        failCallback(file.name.substring(file.name.indexOf(".") + 1) + i18n.t("errors.file.formatNotSupported"));
    } else if (maxSize && file && (file.size > maxSize)) {
        failCallback(i18n.t("onBoarding.company.legalRepresentative.errorMaxUpload"));
    } else if (file) {
        const reader = new FileReader();
        reader.onload = () => successCallback(reader.result as string, file.name, file.type);
        reader.readAsDataURL(file);
    }
};

const CustomUploadFile: React.FC<Props> = ({
                                               title,
                                               acceptedTypes = [MimeTypes.JPEG, MimeTypes.PNG, MimeTypeArray, MimeTypes.PDF] as string[],
                                               onClickUpload,
                                               maxSize = 20971520,
                                               fileData,
                                               setError,
                                               hasDownload = true
                                           }) => {
    const {t} = useTranslation();
    const [fileImage, setFileImage] = React.useState<string | undefined | null>();
    const [busy, setBusy] = React.useState<boolean>(false);

    const mySetFile = (event: any) => {
        setFile(event,
            maxSize,
            acceptedTypes,
            (base64FileSystem, fileName) => {
                onClickUpload && onClickUpload(base64FileSystem, fileName);
                setFileImage(base64FileSystem);
                setError?.("");
            },
            (error: string) => {
                toastUtils.warningToast(error);
                setFileImage(null);
                onClickUpload && onClickUpload(null);
            }
        );
    };
    const isPdf = (fileImage && fileImage.indexOf(MimeTypes.PDF) > -1)
        || (fileData && fileData.mime === MimeTypes.PDF);
    return (
        <div className="customUploadFile">
            {busy && <Spinner/>}
            {title && <div className="customUploadFile__title">{title}</div>}
            <div
                className={`customUploadFile__uploadBox ${!fileData ? "customUploadFile__uploadBox--colored" : ""}`}
            >
                {fileData &&
                <img
                    height={40}
                    width={31}
                    className="customUploadFile__uploadImgBoxText"
                    src={isPdf ? pdf_icon : img_icon}
                    alt={"Document"}
                />
                }
                {!fileData && <div className="customUploadFile__uploadBoxTextBox">
                    <div
                        className="customUploadFile__uploadBoxText"
                        onChange={mySetFile}
                    >
                        <input
                            style={{display: "none", cursor: "pointer"}}
                            type="file"
                            id={"file" + title}
                        />
                        <label style={{cursor: "pointer"}} htmlFor={"file" + title}>
                            {t("onBoarding.buyer.documentUpload.uploadPhoto")}
                        </label>
                    </div>
                </div>}
                {fileData &&
                <div className="customUploadFile__buttonContainer">
                    {onClickUpload && <div className="customUploadFile__buttonDownload customUploadFile__uploadBoxText customUploadFile__uploadBoxText--colored" onChange={mySetFile}>
                        <input
                            style={{display: "none", cursor: "pointer"}}
                            type="file"
                            id={"file" + title}
                        />
                        <label style={{cursor: "pointer"}} htmlFor={"file" + title}>
                            {t("onBoarding.buyer.documentUpload.changePhoto")}
                        </label>
                    </div>}
                    {hasDownload &&  <div className="customUploadFile__buttonDownload">
                        <Button
                            nameFileToDownload={title}
                            onClick={async () => {
                                if (fileData) {
                                    setBusy(true);
                                    await downloadDocument(fileData);
                                    setBusy(false);
                                }
                            }}
                            href={fileImage}
                            label={t("common.button.download")}
                            icon={download_icon_white}
                            openNewTab
                        />
                    </div>}
                </div>}
            </div>

        </div>
    );
};

export default CustomUploadFile;
