import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import {
  persistCombineReducers,
  persistStore,
  persistReducer,
} from "redux-persist";
import { ProgramState } from "./program/program-interface";
import { DynamicDiscountState } from "./dynamicDiscount/dynamicDiscount-interface";
import { UserState } from "./user/user-interface";
import { MoneylenderState } from "./moneylender/moneylender-interface";
import { configService } from "../service/ConfigService";
import { programReducer } from "./program";
import { userReducer } from "./user";
import { moneylenderReducer } from "./moneylender";
import { authReducer } from "./auth";
import { supplierReducer } from "./supplier";
import { AuthState } from "./auth/auth-interface";
import { AdminState } from "./admin/admin-interface";
import { adminReducer } from "./admin";
import { SupplierState } from "./supplier/supplier-interface";
import { CommonState } from "./common/common-interface";
import { commonReducer } from "./common";
import { InfoCertState } from "./infocert/infocert-interface";
import { infoCertReducer } from "./infocert";
import { companyReducer } from "./company";
import { CompanyState } from "./company/company-interface";
import { buyerReducer } from "./buyer";
import { BuyerState } from "./buyer/buyer-interface";
import { dynamicDiscountReducer } from "./dynamicDiscount/dynamicDiscount-reducers";
import * as localForage from "localforage";
import { inquiryReducer } from "./inquiry";
import { InquiryState } from "./inquiry/inquiry-interface";

export interface AppState {
  dynamicDiscount: DynamicDiscountState;
  program: ProgramState;
  auth: AuthState;
  user: UserState;
  moneylender: MoneylenderState;
  admin: AdminState;
  company: CompanyState;
  supplier: SupplierState;
  buyer: BuyerState;
  infoCert: InfoCertState;
  common: CommonState;
  inquiry: InquiryState;
}

const persistConfig = {
  key: "root",
  storage: localForage,
  timeout: 0,
  whitelist: ["user"],
};

const authPersistConfig = {
  key: "auth",
  storage: localForage,
  timeout: 0,
  whitelist: [
    "isAuthenticated",
    "csrf",
    "tenantId",
    "role",
    "roles",
    "themeColor",
    "onBoardingData",
    "onBoardingStep",
    "expirationSession",
    "isCompany",
    "companyRoles",
  ],
};

const infocertPersistConfig = {
  key: "infoCert",
  storage: localForage,
  timeout: 0,
  whitelist: [
    "dossierToken",
    "sessionKey",
    "taskId",
    "documentSignStep",
    "infocertProcessStatusDossierTypeResponse",
    "actionsPresented",
  ],
};

const adminPersistConfig = {
  key: "admin",
  storage: localForage,
  timeout: 0,
  whitelist: ["lastSapErrorTime", "lastSapErrorTimeForHighlight"],
};

const commonPersisConfig = {
  key: "common",
  storage: localForage,
  timeout: 0,
  whitelist: ["ticketPresented"],
};

const companyPersistConfig = {
  key: "company",
  storage: localForage,
  timeout: 0,
  whitelist: ["expiringUserDocuments", "expiringEsgCertificates"],
};

export default () => {
  const rootReducer: any = {
    dynamicDiscount: dynamicDiscountReducer,
    program: programReducer,
    user: userReducer,
    auth: persistReducer(authPersistConfig, authReducer),
    moneylender: moneylenderReducer,
    admin: persistReducer(adminPersistConfig, adminReducer),
    company: persistReducer(companyPersistConfig, companyReducer),
    supplier: supplierReducer,
    buyer: buyerReducer,
    infoCert: persistReducer(infocertPersistConfig, infoCertReducer),
    common: persistReducer(commonPersisConfig, commonReducer),
    inquiry: inquiryReducer,
  };
  let middleware = [thunk];

  if (!configService.isProdEnvironment()) {
    const { logger } = require(`redux-logger`);
    middleware = [...middleware, logger];
  }
  const persistedReducer = persistCombineReducers(persistConfig, rootReducer);

  const store: any = createStore(
    persistedReducer,
    undefined,
    applyMiddleware(...middleware)
  );

  const persistor: any = persistStore(store);

  return { store, persistor };
};
