import {menuItemsAdmin, menuItemsBuyer, menuItemsMoneylender, menuItemsVendor, onBoardingMenu, rolePathPermissions, rolePaths,} from "../components/NavBar/NavBar";
import {Roles} from "../model/UserDTO";


export const filterMenus = (roles: Roles[], menus: any[]) => {
    menus =JSON.parse(JSON.stringify(menus)) ;
    for (const i in menus) {
        if (menus[i].subMenus) {
            menus[i].subMenus = filterMenus(roles, menus[i].subMenus);
        }
    }
    return menus
        .filter(menu => {
            let permitted = false;
            if (menu.permit) {
                menu.permit.forEach((permittedRole: Roles) => {
                    if (!permitted && roles.indexOf(permittedRole) > -1)
                        permitted = true;
                });
            } else permitted = true;
            return permitted;
        });
};

class RoleUtils {

    getRedirectToHome = (userRole: Roles): string => {
        switch (userRole) {
            case Roles.ADMIN:
                return rolePaths[Roles.ADMIN].home;
            case Roles.BUYER:
                return rolePaths[Roles.BUYER].home;
            case Roles.VENDOR:
                return rolePaths[Roles.VENDOR].home;
            case Roles.MONEYLENDER:
                return rolePaths[Roles.MONEYLENDER].home;
            default:
                return "/";
        }
    };

    getNavBarMenuItems = (userRole: Roles, roleList: Roles[], isOnBoardingNavBar: boolean): { id: number, name: string, to: string }[] => {
        if (isOnBoardingNavBar) {
            return onBoardingMenu;
        } else {
            switch (userRole) {
                case Roles.ADMIN:
                    return menuItemsAdmin;
                case Roles.BUYER:
                    return filterMenus(roleList, menuItemsBuyer);
                case Roles.VENDOR:
                    return filterMenus(roleList, menuItemsVendor);
                case Roles.MONEYLENDER:
                    return filterMenus(roleList, menuItemsMoneylender);
                default:
                    return [];
            }
        }
    };

    getThemeColor = (userRole: Roles): string => {
        switch (userRole) {
            case Roles.ADMIN:
                return "darkblue";
            case Roles.BUYER:
                return "purple";
            case Roles.VENDOR:
                return "orange";
            case Roles.MONEYLENDER:
                return "blue";
            default:
                return "purple";
        }
    };

    rolePathIncludes = (role: Roles, path: string) => {
        const paths: any = {...rolePaths[role], ...rolePaths.common} || {};
        const pathList = Object.keys(paths).map((key) => paths[key]);
        return pathList.indexOf(path) >= 0;
    };

    pathIsPermittedForRoles = (roles: Roles[], path: string) => {
        if (roles.indexOf(Roles.ADMIN) > -1) {
            return true;
        } else {
            const permittedPathsForAllRoles =
                roles.reduce((acc, role) => {
                    const rolePaths = (rolePathPermissions as any)[role] || [];
                    return [...acc, ...rolePaths];
                }, rolePathPermissions.common);
            return (permittedPathsForAllRoles && permittedPathsForAllRoles.length)
                ? permittedPathsForAllRoles.indexOf(path) >= 0
                : true;
        }
    };

    isPathPermitted = (mainUserRole: Roles, path: string, roles: Roles[]): boolean => {
        if (path === "*")
            return true;
        if (path === "/documentSign")
            return true;
        return this.rolePathIncludes(mainUserRole, path) && this.pathIsPermittedForRoles(roles, path);
    };

    extractMainRole = (roles: Roles[]): Roles => {
        if (roles.indexOf(Roles.ADMIN) >= 0)
            return Roles.ADMIN;
        else if (roles.indexOf(Roles.BUYER) >= 0)
            return Roles.BUYER;
        else if (roles.indexOf(Roles.VENDOR) >= 0)
            return Roles.VENDOR;
        else if (roles.indexOf(Roles.MONEYLENDER) >= 0)
            return Roles.MONEYLENDER;
        else return Roles.NONE;
    };
}

export const roleUtils = new RoleUtils();
