import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import {labels} from "./assets/Locales/Labels";

i18n
    .use(initReactI18next) // passes i18n down to react-i18nexts
    .use(LanguageDetector)
    .init({
        resources: labels,
        defaultNS: "locales",
        fallbackLng: 'en', //language to use if translations in user language are not available.
        debug: false,
        interpolation: {
            escapeValue: false // react already safes from xss
        },
    });

export default i18n;
