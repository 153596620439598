import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {CommonAction} from "./common-interface";
import {
    CALL_FAIL,
    CALL_REQUEST,
    CALL_SUCCESS,
    CREATE_TICKET,
    CREATE_TICKET_FAIL,
    CREATE_TICKET_SUCCESS,
    GET_REMINDER,
    GET_REMINDER_FAIL,
    GET_REMINDER_SUCCESS,
    GET_TICKET,
    GET_TICKET_FAIL, GET_TICKET_FAIL_SILENT,
    GET_TICKET_SILENT,
    GET_TICKET_SUCCESS, GET_TICKET_SUCCESS_SILENT,
    GET_USER_DATA_FAIL,
    GET_USER_DATA_REQUEST,
    GET_USER_DATA_SUCCESS,
    SET_BUSY,
    SET_REMINDER,
    SET_REMINDER_FAIL,
    SET_REMINDER_SUCCESS
} from "./common-reducers";
import {moneylenderService} from "../../service/MoneylenderService";
import {handleResponse} from "../reduxUtils";
import {ticketService} from "../../service/TicketService";
import {TicketRequestDTO} from "../../model/TicketDTO";
import {MyResponse} from "../../model/ServiceDTO";
import {userService} from "../../service/UserService";

export const setReminder = (period: number): ThunkAction<void, {}, {}, CommonAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, CommonAction>) => {
        dispatch({type: SET_REMINDER, payload: null});
        const responsePromise = moneylenderService.setReminder(period);
        return await handleResponse(dispatch, SET_REMINDER_SUCCESS, SET_REMINDER_FAIL, responsePromise);
    };
};

export const getReminder = (): ThunkAction<void, {}, {}, CommonAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, CommonAction>) => {
        dispatch({type: GET_REMINDER, payload: null});
        const responsePromise = moneylenderService.getReminder();
        return await handleResponse(dispatch, GET_REMINDER_SUCCESS, GET_REMINDER_FAIL, responsePromise);
    };
};

export const retrieveTicket = (silent: boolean = false, isArchive?:boolean): ThunkAction<void, {}, {}, CommonAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, CommonAction>) => {
        dispatch({type: silent ? GET_TICKET_SILENT : GET_TICKET, payload: null});
        const responsePromise = ticketService.retrieveTicket(isArchive);
        return await handleResponse(dispatch, silent ? GET_TICKET_SUCCESS_SILENT : GET_TICKET_SUCCESS, silent ? GET_TICKET_FAIL_SILENT : GET_TICKET_FAIL, responsePromise);
    };
};

export const createNewTicket = (data: TicketRequestDTO): ThunkAction<void, {}, {}, CommonAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, CommonAction>) => {
        dispatch({type: CREATE_TICKET, payload: null});
        const responsePromise = ticketService.createNewTicket(data);
        return await handleResponse(dispatch, CREATE_TICKET_SUCCESS, CREATE_TICKET_FAIL, responsePromise);
    };
};

export const setCreateNewTicketError = (payload: string): ThunkAction<void, {}, {}, CommonAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, CommonAction>) => {
        dispatch({type: CREATE_TICKET_FAIL, payload});
    };
};

export const setBusy = (payload: boolean): ThunkAction<void, {}, {}, CommonAction> =>
    async (dispatch: ThunkDispatch<{}, {}, CommonAction>) => {
        dispatch({type: SET_BUSY, payload});
    };


export const commonCall = (call:()=>Promise<MyResponse<any>>): ThunkAction<void, {}, {}, CommonAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, CommonAction>) => {
        dispatch({type: CALL_REQUEST, payload: null});
        const responsePromise = call();
        return await handleResponse(dispatch, CALL_SUCCESS, CALL_FAIL, responsePromise);
    };
};

export const getUserData = (userId:string): ThunkAction<void, {}, {}, CommonAction> =>
    async (dispatch: ThunkDispatch<{}, {}, CommonAction>) => {
        dispatch({type: GET_USER_DATA_REQUEST, payload: null});
        let responsePromise = userService.getUserData(userId);
        return await handleResponse(dispatch, GET_USER_DATA_SUCCESS, GET_USER_DATA_FAIL, responsePromise);
    };
