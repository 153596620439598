import {ThunkAction, ThunkDispatch} from "redux-thunk";
import { SupplierAction} from "./supplier-interface";
import {
    GET_BAR_CHART_DATA,
    GET_BAR_CHART_DATA_FAIL,
    GET_BAR_CHART_DATA_SUCCESS,
    GET_BUYERS,
    GET_BUYERS_FAIL,
    GET_BUYERS_SUCCESS,
    GET_CREDIT_LINE_HISTORY,
    GET_CREDIT_LINE_HISTORY_FAIL,
    GET_CREDIT_LINE_HISTORY_SUCCESS,
    GET_INVOICES,
    GET_INVOICES_BY_AMOUNT,
    GET_INVOICES_FAIL,
    GET_INVOICES_FAIL_BY_AMOUNT,
    GET_INVOICES_SUCCESS,
    GET_PENDING_FAIL,
    GET_PENDING_REQUEST,
    GET_PENDING_SUCCESS,
    GET_INVOICES_SUCCESS_BY_AMOUNT,
    GET_MONEYLENDERS,
    GET_MONEYLENDERS_FAIL,
    GET_MONEYLENDERS_SUCCESS,
    GET_STOCK_DATA,
    GET_STOCK_DATA_FAIL,
    GET_STOCK_DATA_SUCCESS,
    SAVE_DISPOSAL_INVOICES,
    SAVE_DISPOSAL_INVOICES_FAIL,
    SAVE_DISPOSAL_INVOICES_SUCCESS,
    SELL_INVOICES_STEP,
    SET_CHECKOUT_DATA,
    RETRIEVE_BANKS_INFO_SUCCESS,
    RETRIEVE_BANKS_INFO,
    RETRIEVE_BANKS_INFO_FAIL,
    GET_MP_FEE,
    GET_MP_FEE_FAIL,
    GET_MP_FEE_SUCCESS,
    GET_CHART_DATA_SUCCESS,
    GET_CHART_DATA,
    GET_CHART_DATA_FAIL,
    GET_INVOICES_SILENT,
    GET_INVOICES_SUCCESS_SILENT,
    GET_INVOICES_FAIL_SILENT,
    GET_STOCK_DATA_FAIL_SILENT, GET_BAR_CHART_DATA_FAIL_SILENT, GET_STOCK_DATA_SUCCESS_SILENT, GET_BAR_CHART_DATA_SILENT, GET_BAR_CHART_DATA_SUCCESS_SILENT, GET_STOCK_DATA_SILENT, GET_CREDIT_LINE_HISTORY_FAIL_SILENT, GET_CREDIT_LINE_HISTORY_SUCCESS_SILENT, GET_CREDIT_LINE_HISTORY_SILENT, GET_CHART_DATA_SUCCESS_SILENT, GET_CHART_DATA_FAIL_SILENT, GET_CHART_DATA_SILENT, GET_ELIGIBILITY_FAIL, GET_ELIGIBILITY_SUCCESS, GET_ELIGIBILITY_REQUEST
} from "./supplier-reducers";
import {handleResponse} from "../reduxUtils";
import {vendorService} from "../../service/VendorService";
import {CheckoutData, DisposalDataDTO, EligibilityRequest, MoneylenderOfferRequest, SellInvoicesStep} from "../../model/VendorDTO";

export const getInvoices = (buyerId?: string, programName?: string, isSilent = false): ThunkAction<void, {}, {}, SupplierAction> =>
    async (dispatch: ThunkDispatch<{}, {}, SupplierAction>) => {
        dispatch({type: isSilent ? GET_INVOICES_SILENT : GET_INVOICES, payload: null});
        const responsePromise = vendorService.getInvoices(buyerId, programName);
        return await handleResponse(dispatch, isSilent ? GET_INVOICES_SUCCESS_SILENT : GET_INVOICES_SUCCESS, isSilent ? GET_INVOICES_FAIL_SILENT : GET_INVOICES_FAIL, responsePromise);
    };

export const getInvoicesByAmount = (buyerId: string, amount: number, programName: string): ThunkAction<void, {}, {}, SupplierAction> =>
    async (dispatch: ThunkDispatch<{}, {}, SupplierAction>) => {
        dispatch({type: GET_INVOICES_BY_AMOUNT, payload: null});
        const responsePromise = vendorService.getInvoicesByAmount(buyerId, amount, programName);
        return await handleResponse(dispatch, GET_INVOICES_SUCCESS_BY_AMOUNT, GET_INVOICES_FAIL_BY_AMOUNT, responsePromise);
    };

export const getEligibility = (data: EligibilityRequest): ThunkAction<void, {}, {}, SupplierAction> =>
    async (dispatch: ThunkDispatch<{}, {}, SupplierAction>) => {
        dispatch({type: GET_ELIGIBILITY_REQUEST, payload: null});
        const responsePromise = vendorService.checkEligibleInvoices(data);
        return await handleResponse(dispatch, GET_ELIGIBILITY_SUCCESS, GET_ELIGIBILITY_FAIL, responsePromise);
    };

export const getPendingInvoices = (): ThunkAction<void, {}, {}, SupplierAction> =>
    async (dispatch: ThunkDispatch<{}, {}, SupplierAction>) => {
        dispatch({type: GET_PENDING_REQUEST, payload: null});
        const responsePromise = vendorService.getPendingInvoices();
        return await handleResponse(dispatch, GET_PENDING_SUCCESS, GET_PENDING_FAIL, responsePromise);
    };

export const getBuyers = (userId?: string): ThunkAction<void, {}, {}, SupplierAction> =>
    async (dispatch: ThunkDispatch<{}, {}, SupplierAction>) => {
        dispatch({type: GET_BUYERS, payload: null});
        const responsePromise = vendorService.getBuyers(userId);
        return await handleResponse(dispatch, GET_BUYERS_SUCCESS, GET_BUYERS_FAIL, responsePromise);
    };

export const getMoneylenders = (data: MoneylenderOfferRequest): ThunkAction<void, {}, {}, SupplierAction> =>
    async (dispatch: ThunkDispatch<{}, {}, SupplierAction>) => {
        dispatch({type: GET_MONEYLENDERS, payload: null});
        const responsePromise = vendorService.getMoneylenders(data);
        return await handleResponse(dispatch, GET_MONEYLENDERS_SUCCESS, GET_MONEYLENDERS_FAIL, responsePromise);
    };

export const getBarCharData = (isSilent = false): ThunkAction<void, {}, {}, SupplierAction> =>
    async (dispatch: ThunkDispatch<{}, {}, SupplierAction>) => {
        dispatch({type: isSilent ? GET_BAR_CHART_DATA_SILENT : GET_BAR_CHART_DATA, payload: null});
        const responsePromise = vendorService.getBarCharData();
        return await handleResponse(dispatch, isSilent ? GET_BAR_CHART_DATA_SUCCESS_SILENT : GET_BAR_CHART_DATA_SUCCESS, isSilent ? GET_BAR_CHART_DATA_FAIL_SILENT : GET_BAR_CHART_DATA_FAIL, responsePromise);
    };

export const getStockData = (isSilent = false): ThunkAction<void, {}, {}, SupplierAction> =>
    async (dispatch: ThunkDispatch<{}, {}, SupplierAction>) => {
        dispatch({type: isSilent ? GET_STOCK_DATA_SILENT : GET_STOCK_DATA, payload: null});
        const responsePromise = vendorService.getStockData();
        return await handleResponse(dispatch, isSilent ? GET_STOCK_DATA_SUCCESS_SILENT : GET_STOCK_DATA_SUCCESS, isSilent ? GET_STOCK_DATA_FAIL_SILENT : GET_STOCK_DATA_FAIL, responsePromise);
    };

export const getCreditLineHistory = (creditLineId: string, isSilent: boolean): ThunkAction<void, {}, {}, SupplierAction> =>
    async (dispatch: ThunkDispatch<{}, {}, SupplierAction>) => {
        dispatch({type: isSilent ? GET_CREDIT_LINE_HISTORY_SILENT : GET_CREDIT_LINE_HISTORY, payload: null});
        const responsePromise = vendorService.getCreditLineHistory(creditLineId);
        return await handleResponse(dispatch, isSilent ? GET_CREDIT_LINE_HISTORY_SUCCESS_SILENT : GET_CREDIT_LINE_HISTORY_SUCCESS, isSilent ? GET_CREDIT_LINE_HISTORY_FAIL_SILENT : GET_CREDIT_LINE_HISTORY_FAIL, responsePromise);
    };

export const saveDisposalInvoices = (disposalInvoice: DisposalDataDTO): ThunkAction<void, {}, {}, SupplierAction> =>
    async (dispatch: ThunkDispatch<{}, {}, SupplierAction>) => {
        dispatch({type: SAVE_DISPOSAL_INVOICES, payload: null});
        const responsePromise = vendorService.saveDisposalInvoices(disposalInvoice);
        return await handleResponse(dispatch, SAVE_DISPOSAL_INVOICES_SUCCESS, SAVE_DISPOSAL_INVOICES_FAIL, responsePromise);
    };

export const getBanksInfo = (): ThunkAction<void, {}, {}, SupplierAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, SupplierAction>) => {
        dispatch({type: RETRIEVE_BANKS_INFO, payload: null});
        const responsePromise = vendorService.getBanksInfo();
        return await handleResponse(dispatch, RETRIEVE_BANKS_INFO_SUCCESS, RETRIEVE_BANKS_INFO_FAIL, responsePromise);
    };
};

export const getMPFee = (buyerId: string, programId: string): ThunkAction<void, {}, {}, SupplierAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, SupplierAction>) => {
        dispatch({type: GET_MP_FEE, payload: null});
        const responsePromise = vendorService.getMPFee(buyerId, programId);
        return await handleResponse(dispatch, GET_MP_FEE_SUCCESS, GET_MP_FEE_FAIL, responsePromise);
    };
};
export const getChartData = (isSilent = false): ThunkAction<void, {}, {}, SupplierAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, SupplierAction>) => {
        dispatch({type: isSilent ? GET_CHART_DATA_SILENT : GET_CHART_DATA, payload: null});
        const responsePromise = vendorService.getChartData();
        return await handleResponse(dispatch, isSilent ? GET_CHART_DATA_SUCCESS_SILENT : GET_CHART_DATA_SUCCESS, isSilent ? GET_CHART_DATA_FAIL_SILENT : GET_CHART_DATA_FAIL, responsePromise);
    };
};

export const setSellInvoicesStep = (payload: SellInvoicesStep) => (dispatch: ThunkDispatch<{}, {}, SupplierAction>) => {
    dispatch({type: SELL_INVOICES_STEP, payload});
};

export const setCheckoutData = (payload: CheckoutData) => (dispatch: ThunkDispatch<{}, {}, SupplierAction>) => {
    dispatch({type: SET_CHECKOUT_DATA, payload});
};

export const setInvoicesByAmount = () => (dispatch: ThunkDispatch<{}, {}, SupplierAction>) => {
    dispatch({type: GET_INVOICES_SUCCESS_BY_AMOUNT, payload:[]});
};
