import i18next from "i18next";

class LanguageUtils {

    getLocaleLanguage = () : string => {
        if (!i18next.language) return "en";
        return i18next.language.substring(0, 2)
    };

    isLocaleIt = () : boolean => {
        return this.getLocaleLanguage() === "it"
    };

    isLocaleEn = () : boolean => {
        return this.getLocaleLanguage() === "en"
    };

    getLocaleDateFormat = () : string => {
        return this.isLocaleIt() ? "dd/mm/yyyy" :  "mm/dd/yyyy";
    };

}

export const languageUtils = new LanguageUtils();
