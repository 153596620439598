import * as React from 'react';
import {useEffect, useState} from 'react';
import {AppState} from "../../../../redux/store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import ScreenWrapper from "../../../../components/AdminComponent/ScreenWrapper/ScreenWrapper";
import MyDropdown from "../../../../components/Common/MyDropdown/MyDropdown";
import {download, getActionTypeList} from "../../../../utils/commonUtils";
import {getArchiveDocumentsAdmin} from "../../../../redux/infocert/infocert-actions";
import Table, {TableHeader} from "../../../../components/Common/Table/Table";
import NoDataBox from "../../../../components/Common/NoDataBox/NoDataBox";
import {ActionDTO, DocumentDTO} from "../../../../model/InfoCertDTO";
import {timeUtils} from "../../../../utils/timeUtils";
import pdf_icon from "../../../../assets/Icons/pdf_icon.svg";
import "../../../common/actionRequests/ActionRequests.scss";
import Button, {BUTTON_TYPE} from "../../../../components/Common/Button/Button";
import "./DocumentSection.scss";
import Ellapsis from "../../../../components/Common/Ellapsis/Ellapsis";

const stylesActionHeaderTable = {
    id: {flex: "none", width: "12em"},
    link: {flex: "none", width: "12em", cursor: "pointer"},
    created: {flex: "none", width: "10em"},
    type: {flex: 1},
    signers: {flex: "none", width: "10em"},
    date: {flex: "none", width: "10em", marginRight: "auto"},
};

const DocumentSection: React.FC<any> = ({
                                            role,
                                            vatNumber,
                                            getArchiveDocumentsAdmin,
                                            archiveItems,
                                            archiveStatusFail,
                                            archiveIsFetching,
                                            title,
                                            onPreviousButtonClick
                                        }) => {

    const {t} = useTranslation();
    const [type, setType] = useState({label: t("common.dropdown.all")});

    useEffect(() => {
        getArchiveDocumentsAdmin(vatNumber);
    }, [getArchiveDocumentsAdmin, vatNumber]);

    const actionHeadersTable: TableHeader[] = [
        {
            label: t("common.actions.headers.id"),
            sortingAccessor: (a: ActionDTO) => a.id,
            isPrimary: true,
            style: stylesActionHeaderTable.id
        },
        {
            label: t("common.actions.headers.link"),
            style: stylesActionHeaderTable.link
        },
        {
            label: t("common.actions.headers.created"),
            sortingAccessor: (a: ActionDTO) => a.createdBy,
            style: stylesActionHeaderTable.created
        },
        {
            label: t("common.actions.headers.type"),
            sortingAccessor: (a: ActionDTO) => a.type,
            style: stylesActionHeaderTable.type
        },
        {
            label: t("common.actions.headers.signers"),
            style: stylesActionHeaderTable.signers
        },
        {
            label: t("common.actions.headers.date"),
            sortingAccessor: (a: ActionDTO) => timeUtils.getTimestamp(a.createDateEpoch),
            style: stylesActionHeaderTable.date,
            isDefault: true
        },
    ];

    const renderRow = (item: any, index: any) => {
        return (
            <div key={index}>
                <div className="actions__tableRow">
                     <span style={stylesActionHeaderTable.id} title={item.id} className="table__item documentSection__tableItem">
                         <Ellapsis title={item.id}> {item.id} </Ellapsis>
                    </span>
                    <span
                        style={stylesActionHeaderTable.link}
                        className="actions__pdfContainer table__item documentSection__tableItem"
                    >
                        <span className="actions__documentPDFContainer">
                            {item?.documents?.map?.((document: DocumentDTO, index: number) => <img
                                    onClick={() => download(document.documentId, document.name, item?.type)}
                                    title={document.name}
                                    src={pdf_icon}
                                    alt="pdf_icon"
                                    className="actions__pdfImage"
                                    height="30px"
                                    key={index}
                                />
                            )}
                        </span>
                    </span>
                    <span style={stylesActionHeaderTable.created} title={item.createdBy} className="table__item documentSection__tableItem">
                       <Ellapsis title={item.createdBy}> {item.createdBy}</Ellapsis>
                    </span>
                    <span style={stylesActionHeaderTable.type} className="table__item documentSection__tableItem">
                        <Ellapsis title={item.typeDisplay}> {item.typeDisplay}</Ellapsis>
                    </span>
                    <span style={stylesActionHeaderTable.signers} className="table__item documentSection__tableItem actions__signers">
                        <div><Ellapsis title={item.signer1}>{item.signer1}</Ellapsis></div>
                        <div><Ellapsis title={item.signer2}>{item.signer2}</Ellapsis></div>
                        <div><Ellapsis title={item.signer3}>{item.signer3}</Ellapsis></div>
                    </span>
                    <span style={stylesActionHeaderTable.date} title={item.createDateEpochDisplay} className="table__item documentSection__tableItem">
                        {item.createDateEpochDisplay}
                    </span>
                </div>
                <div className="table__line table__line--withMargin" style={{opacity: 0.2}}/>
            </div>
        );
    };

    const searchableArchiveItems = archiveItems?.map(({type, createDateEpoch, signers, ...rest}: ActionDTO) => ({
        signer1: (signers?.[0]?.name) || "--",
        signer2: (signers?.[1]?.name) || "--",
        signer3: (signers?.[2]?.name) || "--",
        createDateEpochDisplay: timeUtils.formatToLocaleDate(createDateEpoch, "DD/MM/YY HH:mm"),
        typeDisplay: t("common.actions.types." + type),
        type,
        ...rest
    }));

    return (
        <ScreenWrapper title={title}>
            <div className="documentSection__titleContainer">
                <div className="documentSection__dropdownContainer">
                    <MyDropdown
                        links={getActionTypeList(role, true)}
                        title={t("common.actions.actionTypeDropdown")}
                        inputValue={type?.label}
                        labelExtractor={({label}: any) => label}
                        onChange={(input) => {
                            setType(input);
                            getArchiveDocumentsAdmin(vatNumber, input.key);
                        }}
                    />
                </div>
                <div className="documentSection__buttonContainer">
                    <Button
                        label={t("common.button.back")}
                        buttonType={BUTTON_TYPE.primaryInverted}
                        iconOnTheLeft
                        onClick={onPreviousButtonClick}
                    />
                </div>
            </div>
            <Table
                noItemsRender={((archiveItems && archiveItems.length === 0) && archiveIsFetching === false) &&
                <NoDataBox
                    styleContainer={{marginTop: "40px"}}
                    description={t("common.actions.noActions")}
                />}
                searchableListToDisplay={searchableArchiveItems}
                accessorId={(a: ActionDTO) => a.id}
                defaultSortingFn={(a: ActionDTO, b: ActionDTO) => timeUtils.getTimestamp(b.createDateEpoch) > timeUtils.getTimestamp(a.createDateEpoch) ? 1 : -1}
                renderItem={renderRow}
                headers={actionHeadersTable}
                itemForPage={15}
                className="shadowCard actions__marginTop20"
                error={archiveStatusFail}
                headerClassNameModifier="table__header--actions"
                headerItemModifier="table__headerItem--actions"
            />
        </ScreenWrapper>
    );
};

const mapStateToProps = (store: AppState) => {
    return {
        archiveItems: store.infoCert.archiveStatusResponse,
        archiveStatusFail: store.infoCert.archiveStatusFail,
        archiveIsFetching: store.infoCert.archiveIsFetching,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        getArchiveDocumentsAdmin: (companyId: string, actionType: string) => dispatch(getArchiveDocumentsAdmin(companyId, actionType))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSection);
