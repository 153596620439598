import { ProgramAction, ProgramState } from './program-interface';
import {
  AvailableProgramDTO,
  AvailableProgramsResponse,
  ProgramDTO,
} from '../../model/ProgramDTO';

export const GET_PROGRAMS = 'getProgramsRequest';
export const GET_PROGRAMS_SUCCESS = 'getProgramsSuccess';
export const GET_PROGRAMS_FAIL = 'getProgramsFailed';
export const GET_PROGRAMS_AVAILABLE = 'getProgramsAvailableRequest';
export const GET_PROGRAMS_AVAILABLE_SUCCESS = 'getProgramsAvailableSuccess';
export const GET_PROGRAMS_AVAILABLE_FAIL = 'getProgramsAvailableFailed';
export const GET_ALIGNMENT_PARAMETER = 'getAlignmentParameterRequest';
export const GET_ALIGNMENT_PARAMETER_SUCCESS = 'getAlignmentParameterSuccess';
export const GET_ALIGNMENT_PARAMETER_FAIL = 'getAlignmentParameterFailed';
export const GET_BASE_RATE = 'getBaseRateRequest';
export const GET_BASE_RATE_SUCCESS = 'getBaseRateSuccess';
export const GET_BASE_RATE_FAIL = 'getBaseRateFailed';
export const GET_CURRENCY_ENABLED = 'getCurrencyEnabledRequest';
export const GET_CURRENCY_ENABLED_SUCCESS = 'getCurrencyEnabledSuccess';
export const GET_CURRENCY_ENABLED_FAIL = 'getCurrencyEnabledFailed';
export const SET_PROGRAM_ERROR = 'setProgramError';
export const RESET_PROGRAM = 'resetProgram';
export const GET_PROGRAM_TYPES = 'getProgramTypes';
export const GET_PROGRAM_TYPES_SUCCESS = 'getProgramTypesSuccess';
export const GET_PROGRAM_TYPES_FAIL = 'getProgramTypesFail';

const defaultState: ProgramState = {
  programResponse: '',
  programTypes: [],
  isFetchingProgram: false,
  getAllProgramsResponse: null,
  isFetchingAllPrograms: null,
  alignmentParameterResponse: '',
  isFetchingAlignmentParameter: false,
  baseRateResponse: '',
  isFetchingBaseRate: false,
  currencyEnabledResponse: [],
  isFetchingCurrencyEnabled: false,
  error: '',
  currencyFilters: [] as string[],
  currencyFiltersAvailable: [],
  getAllProgramsAvailableResponse: {} as AvailableProgramsResponse,
  isFetchingAllProgramsAvailable: false,
  isFetchingProgramTypes: false,
};

const onlyUniqueCurrency = (value: string, index: number, self: string[]) =>
  self.indexOf(value as string) === index;

export function programReducer(
  state = defaultState,
  action: ProgramAction
): ProgramState {
  switch (action.type) {
    case GET_PROGRAMS:
      return {
        ...state,
        error: '',
        isFetchingAllPrograms: true,
      };
    case GET_PROGRAMS_SUCCESS:
      const currencyFilters = action.payload
        .map((item: ProgramDTO) => item.currency)
        .filter(onlyUniqueCurrency);
      return {
        ...state,
        error: '',
        getAllProgramsResponse: action.payload,
        currencyFilters,
        isFetchingAllPrograms: false,
      };
    case GET_PROGRAMS_FAIL:
      return {
        ...state,
        error: action.payload,
        getAllProgramsResponse: [],
        isFetchingAllPrograms: false,
      };

    case GET_PROGRAMS_AVAILABLE:
      return {
        ...state,
        getAllProgramsAvailableResponse:
          defaultState.getAllProgramsAvailableResponse,
        error: defaultState.error,
        isFetchingAllProgramsAvailable: true,
      };
    case GET_PROGRAMS_AVAILABLE_SUCCESS:
      const currencyFiltersAvailable = action.payload.programs
        .map((item: AvailableProgramDTO) => item.currency)
        .filter(onlyUniqueCurrency);
      return {
        ...state,
        error: '',
        getAllProgramsAvailableResponse: action.payload,
        isFetchingAllProgramsAvailable: false,
        currencyFiltersAvailable,
      };
    case GET_PROGRAMS_AVAILABLE_FAIL:
      return {
        ...state,
        error: action.payload,
        getAllProgramsAvailableResponse:
          defaultState.getAllProgramsAvailableResponse,
        isFetchingAllProgramsAvailable: false,
      };
    case GET_ALIGNMENT_PARAMETER:
      return {
        ...state,
        isFetchingAlignmentParameter: true,
      };
    case GET_ALIGNMENT_PARAMETER_SUCCESS:
      return {
        ...state,
        alignmentParameterResponse: action.payload,
        isFetchingAlignmentParameter: false,
      };
    case GET_ALIGNMENT_PARAMETER_FAIL:
      return {
        ...state,
        error: action.payload,
        alignmentParameterResponse: '',
        isFetchingAlignmentParameter: false,
      };
    case GET_BASE_RATE:
      return {
        ...state,
        isFetchingBaseRate: true,
      };
    case GET_BASE_RATE_SUCCESS:
      return {
        ...state,
        baseRateResponse: action.payload,
        isFetchingBaseRate: false,
      };
    case GET_BASE_RATE_FAIL:
      return {
        ...state,
        isFetchingBaseRate: false,
      };
    case GET_PROGRAM_TYPES:
      return {
        ...state,
        isFetchingProgramTypes: true,
      };
    case GET_PROGRAM_TYPES_SUCCESS:
      return {
        ...state,
        programTypes: action.payload,
        isFetchingProgramTypes: false,
      };
    case GET_PROGRAM_TYPES_FAIL:
      return {
        ...state,
        isFetchingProgramTypes: false,
      };

    case GET_CURRENCY_ENABLED:
      return {
        ...state,
        isFetchingCurrencyEnabled: true,
      };
    case GET_CURRENCY_ENABLED_SUCCESS:
      return {
        ...state,
        currencyEnabledResponse: action.payload as string[],
        isFetchingCurrencyEnabled: false,
      };
    case GET_CURRENCY_ENABLED_FAIL:
      return {
        ...state,
        error: action.payload,
        currencyEnabledResponse: [],
        isFetchingCurrencyEnabled: false,
      };
    case SET_PROGRAM_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case RESET_PROGRAM:
      return {
        ...state,
        ...defaultState,
      };
    default:
      return state;
  }
}
