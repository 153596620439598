import * as React from "react";
import arrow from '../../../assets/Icons/bottom_arrow_dropdown.svg';
import {Dropdown} from "react-bootstrap";
import "./MyDropdown.scss";
import {useTranslation} from "react-i18next";
import {getNested} from "../../../utils/commonUtils";
import i18next from "i18next";
import Ellapsis from "../Ellapsis/Ellapsis";

interface Props {
    links: Array<any>
    title?: string,
    description?: string,
    className?: string,
    inputValue: any,
    editable?: boolean,
    disableSorting?: boolean,
    placeholder?: string | null,
    titleStyle?: string
    classNameDropdown?: string
    classNameTitle?: string
    labelExtractor?(input: any): any
    onChange(input: any): void;
    [index: string]: any
}

const CustomToggle = React.forwardRef(({
                                           children,
                                           onClick,
                                           showModifyLabel,
                                           placeholderColor,
                                           t,
                                           inputValue,
                                           setFilter,
                                           setShowModifyLabel,
                                       }: any, ref: React.Ref<HTMLDivElement>) => (
    <div
        ref={ref}
        title={children}
        className="customDropdown__toggle"
        onClick={e => {
            e.preventDefault();
            onClick(e);
            setFilter("");
        }}
    >
        <div className="customDropdown__linkContainer">
            <div className={`customDropdown__link ${placeholderColor}`}>
                <Ellapsis>{children}</Ellapsis>
            </div>
        </div>
        {(showModifyLabel && inputValue !== null) ?
            <div className="customDropdown__modify" onClick={() => setShowModifyLabel(false)}>
                {t("common.button.modify")}
            </div> :
            <div className="customDropdown__imgContainer">
                <img src={arrow} className="customDropdown__img" alt=""/>
            </div>
        }
    </div>
));

const CustomMenu = React.forwardRef(({
                                         children,
                                         value,
                                         style,
                                         className,
                                         'aria-labelledby': labeledBy,
                                         filter,
                                         setFilter,
                                         t
                                     }: any,
                                     ref: React.Ref<HTMLDivElement>) => {
        const filteredChildren = React.Children.toArray(children).filter(
            (child) => {
                let stingValue = getNested(child, "props", "children");
                if (typeof stingValue !== "string") {
                    stingValue = getNested(stingValue, "props", "children");
                }
                return !filter || stingValue?.toLowerCase?.().startsWith(filter.toLowerCase());
            }
        );
        return (
            <div ref={ref} className={className} aria-labelledby={labeledBy}>
                {children.length > 4 &&
                <input
                    autoFocus={true}
                    className="customDropdown__filter"
                    placeholder={t("common.dropdown.filterPlaceholder")}
                    onChange={(e: any) => setFilter(e.target.value)}
                    value={filter}
                />
                }
                <ul className="customDropdown__list list-unstyled">
                    {(filteredChildren.length && filteredChildren) ||
                    <div className="customDropdown__noItem">{t("common.dropdown.noItems")}</div>}
                </ul>
            </div>
        );
    },
);


const MyDropdown: React.FC<Props> = ({
                                         links,
                                         editable = true,
                                         title,
                                         className,
                                         onChange,
                                         inputValue,
                                         labelExtractor,
                                         placeholder = i18next.t("common.dropdown.placeholder"),
                                         description,
                                         disableSorting,
                                         titleStyle = "",
                                         classNameDropdown,
                                         classNameTitle,
                                         ...props
                                     }) => {

    const placeholderColor = (!inputValue) ? "customDropdown__placeholder" : "";
    const [showModifyLabel, setShowModifyLabel] = React.useState(!editable);
    const [filter, setFilter] = React.useState("");
    const {t} = useTranslation();

    let items = links || [];
    if (!(disableSorting)) items = items?.sort();

    return (
        <div className={className} {...props}>
            {title &&
            <div className={`customDropdown__titleContainer ${classNameTitle}`}>
                <div className={`customDropdown__title ${titleStyle}`}>
                    {title}
                </div>
            </div>
            }
            {description &&
            <div className="customDropdown__titleContainer">
                <div className="customDropdown__description">{description}</div>
            </div>
            }
            <Dropdown className={`customDropdown__dropdown ${classNameDropdown}`}>
                <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components"
                    showModifyLabel={showModifyLabel}
                    placeholderColor={placeholderColor}
                    t={t}
                    inputValue={inputValue}
                    setShowModifyLabel={setShowModifyLabel}
                    setFilter={setFilter}
                >
                    {inputValue ? (labelExtractor?.(inputValue) || inputValue) : placeholder}
                </Dropdown.Toggle>
                {(!showModifyLabel || inputValue === null) &&
                <Dropdown.Menu
                    as={CustomMenu}
                    className="customDropdown__customMenu"
                    t={t}
                    filter={filter}
                    setFilter={setFilter}
                >
                    {items?.map((link: any, index: any) => {
                        const label = labelExtractor?.(link) || link;
                        return <Dropdown.Item
                            onClick={() => setShowModifyLabel(false)}
                            key={index}
                            onSelect={() => onChange(link)}
                            className="customDropdown__item"
                            title={label}
                        >
                            <Ellapsis>{label}</Ellapsis>
                        </Dropdown.Item>;
                    })}
                </Dropdown.Menu>
                }
            </Dropdown>
        </div>
    );
};

export default MyDropdown;
