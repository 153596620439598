import * as React from 'react';
import {ReactNode} from 'react';
import "./CardList.scss";
import {AppState} from "../../../redux/store";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import Filter from "../Filter/Filter";
import {getSearchableList, highlightFilterValue, objectHasFilterValue} from "../Table/Table";
import TextInput from "../TextInput/TextInput";
import {searchIcon} from "../../../utils/dynamicSVG";
import SortingBar, {SortingObj} from "../SortingBar/SortingBar";

interface ListItem {
    id: { value: string },
    [index: string]: any
}

interface Props {
    listToDisplay?: any[],
    searchableListToDisplay?: ListItem[],
    currencyFilters?: string[],
    sortingList?: SortingObj[],
    noItemsRender?: ReactNode,
    newItemButton?: ReactNode,
    renderItem(item: any, index: number, array: any[], original: any): ReactNode,
    filterValueAccessor?(value: any, index: number, array: any[]): any,
    defaultSortingFn?(a: any, b: any): number
    searchInputClassName?: string
    style?: any,
    searchBarPlaceholder?: string,
    [index: string]: any
}

const CardList: React.FC<Props> = ({
                                       listToDisplay,
                                       searchableListToDisplay,
                                       currencyFilters,
                                       sortingList,
                                       noItemsRender,
                                       newItemButton,
                                       renderItem,
                                       filterValueAccessor,
                                       defaultSortingFn,
                                       style = {},
                                       searchInputClassName,
                                       role,
                                       searchBarPlaceholder,
                                       accessorId
                                   }) => {

        const {t} = useTranslation();

        const [filterQuery, setFilterQuery] = React.useState("");
        const [sorting, setSorting] = React.useState({sortingFn: defaultSortingFn, label: "", direction: "asc", key: "0"});
        const [elaboratedDataList, setElaboratedDataList] = React.useState([] as any[]);
        const [filteredAndElaboratedDataList, setFilteredAndElaboratedDataList] = React.useState<any>();
        const [selectedFilterList, setSelectedFiltersList] = React.useState<string[]>();
        //order and filter
        React.useEffect(() => {
            // eslint-disable-next-line
            if (searchableListToDisplay && accessorId) searchableListToDisplay = getSearchableList(searchableListToDisplay, accessorId);
            const list = listToDisplay || searchableListToDisplay;
            let newElaboratedDataList = list?.length ? [...list] : [];
            if (newElaboratedDataList && newElaboratedDataList.length > 0) {
                newElaboratedDataList = newElaboratedDataList.sort(sorting && sorting.sortingFn);
            }
            if (selectedFilterList && selectedFilterList.length > 0 && filterValueAccessor) {
                newElaboratedDataList = newElaboratedDataList.filter((...props) => selectedFilterList.includes(filterValueAccessor(...props)));
            }
            setElaboratedDataList(newElaboratedDataList);
        }, [listToDisplay, searchableListToDisplay, sorting, filterValueAccessor, selectedFilterList]);

        //filter by filter query
        React.useEffect(() => {
            if (filterQuery) {
                let list: any = (elaboratedDataList && [...elaboratedDataList]) || [];
                list = list.filter((item: any) => objectHasFilterValue(item, filterQuery));
                list = list.map((item: any) => highlightFilterValue(item, filterQuery));
                setFilteredAndElaboratedDataList(list);
            }
        }, [filterQuery, elaboratedDataList]);
        const displayTopSection = (!!(currencyFilters?.length) ||
            !!newItemButton ||
            !!(sortingList && searchableListToDisplay?.length) ||
            !!(searchableListToDisplay?.length));

        const listToRender = filterQuery.length === 0 ? elaboratedDataList : filteredAndElaboratedDataList;
        return (
            <div style={style.container}>
                <div className="cardList" >
                    {displayTopSection &&
                    <div className="cardList__topSection">
                        {currencyFilters && currencyFilters.length > 0 &&
                        <Filter
                            title={t("SCFProgram.filterBy")}
                            updateSelectedFilters={(filters: string[]) => setSelectedFiltersList(filters)}
                            filterList={currencyFilters}
                        />
                        }
                        {!!newItemButton && <div className="cardList__newItemButtonContainer">
                            {newItemButton}
                        </div>}
                        {searchableListToDisplay &&
                        <div className="cardList__searchBarContainer" style={style.searchContainer}>
                            <TextInput
                                value={filterQuery}
                                placeholder={searchBarPlaceholder || t("common.button.search")}
                                onChange={setFilterQuery}
                                symbol={searchIcon(role)}
                                className={"searchBar " + searchInputClassName}
                            />
                        </div>}

                        {/*SORTING SECTION*/}
                        {sortingList &&
                        <div className="cardList__orderBySection" style={style.orderingSection}>
                            <SortingBar onUpdateSorting={setSorting} sortingList={sortingList} defaultSortingFn={defaultSortingFn}/>
                        </div>
                        }
                    </div>
                    }
                </div>
                {((listToRender && listToRender.length === 0) || (!listToRender)) ?
                    <div className="cardList__noItemsToRenderContainer">
                        {noItemsRender}
                    </div>
                    : listToRender.map((item: any, index: number, arr: any) => {
                        let originalItem = item;
                        if (searchableListToDisplay) {
                            item = item as ListItem;
                            originalItem = searchableListToDisplay.find((original) => accessorId(original) === item?.id?.value);
                        }
                        return renderItem(item, index, arr, originalItem);
                    })}
            </div>
        );
    }
;

const mapStateToProps = (store: AppState) => {
    return {role: store.auth.role};
};

export default connect(mapStateToProps)(CardList);
