import {networkService} from "./NetworkService";
import {MyResponse} from "../model/ServiceDTO";
import {configService} from "./ConfigService";
import {ConfigurationRequest, CreateCreditLineMoneylenderDTO, CreditLineMoneylenderDTO, DocumentZippedDTO, IncomeInvoiceDTO, IncomesDocumentRequestDTO, MoneylenderDTO, MoneylenderInvoiceDTO, OperationInvoiceDTO, ReminderResponseDTO, RequestEditVendorInfoDTO, ResponseVendorProgramDTO, VendorInfo, VendorRegistryDocumentRequestDTO} from "../model/MoneylenderDTO";
import {ProgramDTO} from "../model/ProgramDTO";
import {OperationDTO} from "../model/DynamicDiscountDTO";
import {DocumentTypeDownload} from "../screens/moneylender/DownloadVendorRegistry/DownloadVendorRegistry";

const BASE_URL = configService.getApiPath();

class MoneylenderService {

    getMoneylender = async (): Promise<MyResponse<MoneylenderDTO[]>> => {
        return await networkService.get<MoneylenderDTO[]>(BASE_URL + "/buyer/moneylender/list");
    };

    getCreditLines = (vatNumber?: string): Promise<MyResponse<CreditLineMoneylenderDTO[]>> => {
        return vatNumber ?
            networkService.get<CreditLineMoneylenderDTO[]>(BASE_URL + `/creditLine/${vatNumber}`)
            :
            networkService.get<CreditLineMoneylenderDTO[]>(BASE_URL + "/creditLine");
    };

    getCreditLineInfo = (id: number): Promise<MyResponse<CreateCreditLineMoneylenderDTO>> => {
        return networkService.get<CreateCreditLineMoneylenderDTO>(BASE_URL + `/moneyLender/creditLineDetails/${id}`);
    };

    retrieveProgramsWithoutConfiguration = (creditLineId: number): Promise<MyResponse<Array<ProgramDTO>>> => {
        return networkService.get<Array<ProgramDTO>>(BASE_URL + `/creditLine/price/${creditLineId}`);
    };

    saveProgramsConfiguration = (creditLineId: number, programs: any[]): Promise<MyResponse<Array<ProgramDTO>>> => {
        return networkService.post<any, any>(BASE_URL + `/creditLine/price/${creditLineId}`, programs);
    };

    getAllConfigurations = (creditLineId: number): Promise<MyResponse<any>> => {
        return networkService.get<any>(BASE_URL + `/creditLine/price/${creditLineId}/configurations`);
    };

    getDetailsConfiguration = (configurationId: number): Promise<MyResponse<ConfigurationRequest>> => {
        return networkService.get<any>(BASE_URL + `/creditLine/price/configuration/${configurationId}/detail`);
    };

    getActiveInvoices = (): Promise<MyResponse<Array<MoneylenderInvoiceDTO>>> => {
        return networkService.get<Array<MoneylenderInvoiceDTO>>(BASE_URL + `/creditLine/moneylender/activeInvoices`);
    };

    getInvoiceDetails = (idInvoice: number): Promise<MyResponse<any>> => {
        return networkService.get<any>(BASE_URL + `/creditLine/moneylender/invoiceDetails/${idInvoice}`);
    };

    getOperations = (dynamicDiscountId: number): Promise<MyResponse<OperationDTO[]>> => {
        return networkService.get<OperationDTO[]>(BASE_URL + `/creditLine/operations/${dynamicDiscountId}`);
    };

    getInvoiceOperations = (invoiceId: number): Promise<MyResponse<OperationInvoiceDTO[]>> => {
        return networkService.get<OperationInvoiceDTO[]>(BASE_URL + `/invoice/operations/${invoiceId}`);
    };

    retrieveVendor = (): Promise<MyResponse<VendorInfo[]>> => {
        return networkService.get<VendorInfo[]>(BASE_URL + `/moneyLender/retrieveVendor`);
    };

    retrieveVendorProgram = (vatNumber: string): Promise<MyResponse<ResponseVendorProgramDTO[]>> => {
        return networkService.get<ResponseVendorProgramDTO[]>(BASE_URL + `/moneyLender/retrieveVendorProgram/${vatNumber}`);
    };

    editVendorInfo = (requestEditVendorInfo: RequestEditVendorInfoDTO): Promise<MyResponse<any>> => {
        return networkService.post<any, any>(BASE_URL + `/moneyLender/editVendorInfo`, requestEditVendorInfo);
    };

    setReminder = (period: number): Promise<MyResponse<any>> => {
        return networkService.post<any, any>(BASE_URL + `/invoice/reminder`, {period});
    };

    getReminder = (): Promise<MyResponse<ReminderResponseDTO>> => {
        return networkService.get<ReminderResponseDTO>(BASE_URL + `/invoice/reminder`);
    };

    retrieveVendorInfo = (vatNumber: string): Promise<MyResponse<VendorInfo>> => {
        return networkService.get<VendorInfo>(BASE_URL + `/moneyLender/retrieveVendorInfo/${vatNumber}`);
    };

    getVendorRegistry = (buyerId: string): Promise<MyResponse<any>> => {
        const url = buyerId ? `/moneyLender/vendorRegistry/${buyerId}` : `/moneyLender/vendorRegistry/`;
        return networkService.get<VendorInfo>(BASE_URL + url);
    };

    getVendorRegistryDownload = (body: VendorRegistryDocumentRequestDTO, type: DocumentTypeDownload): Promise<MyResponse<DocumentZippedDTO>> => {
        return networkService.post<any, any>(BASE_URL + `/moneyLender/vendorRegistryDocuments/${type}`, body);
    };

    incomesDownloadDocuments = (body: IncomesDocumentRequestDTO, type: DocumentTypeDownload): Promise<MyResponse<DocumentZippedDTO>> => {
        return networkService.post<any, any>(BASE_URL + `/moneyLender/incomeOperations/${type}`, body.operations);
    };

    refuseIncomes = (body: string[]=[]): Promise<MyResponse<any>> => {
        return networkService.post<any, any>(BASE_URL + `/moneyLender/incomeOperations/refuseIncome`, body);
    };

    getCessionDocuments = (body: string[], type: DocumentTypeDownload): Promise<MyResponse<any>> => {
        return networkService.post<any, any>(BASE_URL + `/moneyLender/operationsDocuments/${type}`, body);
    };

    getInvoiceDetailsDataDocuments = (type: DocumentTypeDownload): Promise<MyResponse<any>> => {
        return networkService.post<any, any>(BASE_URL + `/moneyLender/invoiceDetails/${type}`, null);
    };

    getIncomeOperationList = (): Promise<MyResponse<VendorInfo>> => {
        const url = "/moneyLender/incomeOperations";
        return networkService.get<VendorInfo>(BASE_URL + url);
    };

    getAllOperations = (): Promise<MyResponse<OperationDTO[]>> => {
        return networkService.get<OperationDTO[]>(BASE_URL + `/moneyLender/operations`);
    };

    getIncomeInvoices = (protocolId: string): Promise<MyResponse<IncomeInvoiceDTO[]>> => {
        return networkService.get<IncomeInvoiceDTO[]>(BASE_URL + `/moneyLender/incomeOperations/invoiceDetails/` + protocolId);
    };
}

export const moneylenderService = new MoneylenderService();
