import {MyResponse} from "../model/ServiceDTO";
import {networkService} from "./NetworkService";
import {configService} from "./ConfigService";
import {TicketDTO, TicketRequestDTO} from "../model/TicketDTO";

const BASE_URL = configService.getApiPath();

class TicketService {

    retrieveTicket = async (isArchive?: boolean): Promise<MyResponse<Array<TicketDTO>>> => {
        return await networkService.get<TicketDTO[]>(BASE_URL + "/ticket/" + (isArchive ? "history" : "retrieve"));
    };

    createNewTicket = (data: TicketRequestDTO): Promise<MyResponse<TicketRequestDTO>> => {
        return networkService.post<any, any>(BASE_URL + `/ticket/create`, data);
    };
}

export const ticketService = new TicketService();
