import { InquiryAction, InquiryState } from './inquiry-interface';

export const GET_INQUIRY_ROLES = 'getInquiryRoles';
export const GET_INQUIRY_ROLES_SUCCESS = 'getInquiryRolesSuccess';
export const GET_INQUIRY_ROLES_FAIL = 'getInquiryRolesFail';

export const INQUIRY_DATA = 'inquiryData';
export const INQUIRY_DATA_SUCCESS = 'inquiryDataSuccess';
export const INQUIRY_DATA_FAIL = 'inquiryDataFail';

export const CLEAN_INQUIRY_DATA = 'cleanInquiryData';

const defaultState: InquiryState = {
  getInquiryRolesResponse: [],
  getInquiryRolesFail: '',
  isFetchingGetInquiryRoles: false,

  inquiryDataResponse: [],
  inquiryDataFail: '',
  isFetchingInquiryData: false,
};

export function inquiryReducer(
  state = defaultState,
  action: InquiryAction
): InquiryState {
  switch (action.type) {
    case GET_INQUIRY_ROLES:
      return {
        ...state,
        isFetchingGetInquiryRoles: true,
        getInquiryRolesFail: '',
        getInquiryRolesResponse: [],
      };

    case GET_INQUIRY_ROLES_SUCCESS:
      return {
        ...state,
        isFetchingGetInquiryRoles: false,
        getInquiryRolesFail: '',
        getInquiryRolesResponse: action.payload.roles,
      };

    case GET_INQUIRY_ROLES_FAIL:
      return {
        ...state,
        isFetchingGetInquiryRoles: false,
        getInquiryRolesFail: action.payload,
        getInquiryRolesResponse: [],
      };

    case INQUIRY_DATA:
      return {
        ...state,
        isFetchingInquiryData: true,
        inquiryDataFail: '',
        inquiryDataResponse: [],
      };

    case INQUIRY_DATA_SUCCESS:
      return {
        ...state,
        isFetchingInquiryData: false,
        inquiryDataFail: '',
        inquiryDataResponse: action.payload.list,
      };

    case INQUIRY_DATA_FAIL:
      return {
        ...state,
        isFetchingInquiryData: false,
        inquiryDataFail: action.payload,
        inquiryDataResponse: [],
      };

    case CLEAN_INQUIRY_DATA:
      return {
        ...state,
        getInquiryRolesResponse: [],
        inquiryDataResponse: [],
      };

    default:
      return state;
  }
}
