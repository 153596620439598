import {DELETE_LEGAL_FILE, DELETE_LEGAL_FILE_FAIL, DELETE_LEGAL_FILE_SUCCESS, FORGOT_PASSWORD_FAIL, FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, GET_LEGAL_FILE_FAIL, GET_LEGAL_FILE_REQUEST, GET_LEGAL_FILE_SUCCESS, LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT, LOGOUT_FAIL, LOGOUT_SUCCESS, ONBOARDING_DATA_FAIL, SET_AUTHENTICATED, SET_CSRF, SET_CURRENT_ONBOARDING_STEP, SET_ONBOARDING_DATA, SET_ONBOARDING_ERROR, SET_PROFILE_PIC, SET_USER_COLOR, SET_USER_ON_BOARDING_STEP, SET_USER_ON_BOARDING_STEP_FAIL, SET_USER_ON_BOARDING_STEP_SUCCESS, SWITCH_FAIL, SWITCH_REQUEST, UPDATE_LEGAL_DATA, UPDATE_LEGAL_DATA_FAIL, UPDATE_LEGAL_DATA_SUCCESS, UPDATE_LEGAL_FILE, UPDATE_LEGAL_FILE_FAIL, UPDATE_LEGAL_FILE_SUCCESS, UPDATE_OTHER_USER_DATA, UPDATE_OTHER_USER_DATA_FAIL, UPDATE_OTHER_USER_DATA_SUCCESS, UPDATE_OTHER_USER_FILE, UPDATE_OTHER_USER_FILE_FAIL, UPDATE_OTHER_USER_FILE_SUCCESS, VERIFY_PASSWORD_FAIL, VERIFY_PASSWORD_REQUEST, VERIFY_PASSWORD_SUCCESS,} from "./auth-reducers";
import {errorExtractor, handleResponse} from "../reduxUtils";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {AuthAction} from "./auth-interface";
import {authService} from "../../service/AuthService";
import {userService} from "../../service/UserService";
import {CompanyRoleData, ForgotPasswordDTORequest, LegalDataDTORequest, LegalFilesDTO, OtherFilesDTORequest, UpdateOtherDataRequest, UpdateStepRequest, UserDTORequest, VerifyPasswordDTORequest} from "../../model/UserDTO";
import i18n from "i18next";

export const login = (data: UserDTORequest): ThunkAction<void, {}, {}, AuthAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AuthAction>): Promise<AuthAction> => {
        dispatch({type: LOGIN_REQUEST, payload: null});
        const response = authService.login(data);
        await handleResponse(dispatch, LOGIN_SUCCESS, LOGIN_FAIL, response);
        return response;
    };

export const switchUser = (data: CompanyRoleData): ThunkAction<void, {}, {}, AuthAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AuthAction>): Promise<AuthAction> => {
        dispatch({type: SWITCH_REQUEST, payload: null});
        const response = userService.switchUserRole(data);
        const errorExtractor = (payload: any) => (i18n.exists(payload.errors.username || "null") && i18n.t(payload.errors.username)) || "";
        await handleResponse(dispatch, LOGIN_SUCCESS, SWITCH_FAIL, response, errorExtractor);
        return response;
    };

export const logout = (): ThunkAction<void, {}, {}, AuthAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AuthAction>): Promise<AuthAction> => {
        dispatch({type: LOGOUT, payload: null});
        const response = authService.logout();
        return await handleResponse(dispatch, LOGOUT_SUCCESS, LOGOUT_FAIL, response);
    };

export const forgotPassword = (data: ForgotPasswordDTORequest): ThunkAction<void, {}, {}, AuthAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AuthAction>): Promise<AuthAction> => {
        dispatch({type: FORGOT_PASSWORD_REQUEST, payload: null});
        const response = authService.forgotPassword(data);
        const errorExtractor = (payload: any) => (i18n.exists(payload.errors.errorMessage || "null") && i18n.t(payload.errors.errorMessage)) || "";
        return handleResponse(dispatch, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAIL, response, errorExtractor);
    };

export const verifyPassword = (data: VerifyPasswordDTORequest): ThunkAction<void, {}, {}, AuthAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AuthAction>): Promise<AuthAction> => {
        dispatch({type: VERIFY_PASSWORD_REQUEST, payload: null});
        const response = authService.verifyPassword(data);
        const errorExtractor = (payload: any) => (i18n.exists(payload.errors.errorMessage) && i18n.t(payload.errors.errorMessage)) || "";
        return handleResponse(dispatch, VERIFY_PASSWORD_SUCCESS, VERIFY_PASSWORD_FAIL, response, errorExtractor);
    };

export const setUserThemeColor = (payload: string) => ({type: SET_USER_COLOR, payload});

export const setOnBoardingStep = (onBoardingStep: UpdateStepRequest, onlyBackend?: boolean): ThunkAction<void, {}, {}, AuthAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AuthAction>) => {
        dispatch({type: SET_USER_ON_BOARDING_STEP, payload: null});
        const responsePromise = userService.setOnBoardingStep(onBoardingStep);
        const success = await handleResponse(dispatch, SET_USER_ON_BOARDING_STEP_SUCCESS, SET_USER_ON_BOARDING_STEP_FAIL, responsePromise, errorExtractor);
        success && !onlyBackend && dispatch({type: SET_CURRENT_ONBOARDING_STEP, payload: onBoardingStep});
        return success;
    };

export const setAuthenticated = (payload: boolean) => ({type: SET_AUTHENTICATED, payload});

export const setOnboardingData = (data: any): ThunkAction<void, {}, {}, AuthAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AuthAction>): Promise<AuthAction> => {
        const response = userService.updateUserData(data);
        const success = await handleResponse(dispatch, null, ONBOARDING_DATA_FAIL, response, errorExtractor);
        success && dispatch({type: SET_ONBOARDING_DATA, data});
        return success;
    };

export const setOnBoardingDataLocallyOnly = (data: any) => (dispatch: ThunkDispatch<{}, {}, AuthAction>) => {
    dispatch({type: SET_ONBOARDING_DATA, data});
};

export const setCsrf = (payload: string) => (dispatch: ThunkDispatch<{}, {}, AuthAction>) => {
    dispatch({type: SET_CSRF, payload});
};

export const setCurrentOnboardingStep = (payload: UpdateStepRequest) => (dispatch: ThunkDispatch<{}, {}, AuthAction>) => {
    dispatch({type: SET_CURRENT_ONBOARDING_STEP, payload});
};

export const setOnBoardingError = (payload: string) => (dispatch: ThunkDispatch<{}, {}, AuthAction>) => {
    dispatch({type: SET_ONBOARDING_ERROR, payload});
};

export const updateLegalFile = (userLegalFiles: LegalFilesDTO,vat?:string): ThunkAction<void, {}, {}, AuthAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AuthAction>) => {
        dispatch({type: UPDATE_LEGAL_FILE, payload: null});
        const responsePromise = userService.updateLegalFiles(userLegalFiles,vat);
        return await handleResponse(dispatch, UPDATE_LEGAL_FILE_SUCCESS, UPDATE_LEGAL_FILE_FAIL, responsePromise);
    };

export const getLegalFiles = (vat?: string): ThunkAction<void, {}, {}, AuthAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AuthAction>) => {
        dispatch({type: GET_LEGAL_FILE_REQUEST, payload: null});
        const responsePromise = userService.getLegalFiles(vat);
        return await handleResponse(dispatch, GET_LEGAL_FILE_SUCCESS, GET_LEGAL_FILE_FAIL, responsePromise);
    };

export const updateLegalData = (legalData: LegalDataDTORequest): ThunkAction<void, {}, {}, AuthAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AuthAction>) => {
        dispatch({type: UPDATE_LEGAL_DATA, payload: null});
        const responsePromise = userService.updateLegalData(legalData);
        return await handleResponse(dispatch, UPDATE_LEGAL_DATA_SUCCESS, UPDATE_LEGAL_DATA_FAIL, responsePromise);
    };

export const deleteLegalFile = (legalFile: LegalFilesDTO): ThunkAction<void, {}, {}, AuthAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AuthAction>) => {
        dispatch({type: DELETE_LEGAL_FILE, payload: null});
        const responsePromise = userService.deleteLegalFile(legalFile);
        return await handleResponse(dispatch, DELETE_LEGAL_FILE_SUCCESS, DELETE_LEGAL_FILE_FAIL, responsePromise);
    };

export const uploadOtherUserFiles = (userLegalFiles: OtherFilesDTORequest): ThunkAction<void, {}, {}, AuthAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AuthAction>) => {
        dispatch({type: UPDATE_OTHER_USER_FILE, payload: null});
        const responsePromise = userService.uploadOtherUserFiles(userLegalFiles);
        return await handleResponse(dispatch, UPDATE_OTHER_USER_FILE_SUCCESS, UPDATE_OTHER_USER_FILE_FAIL, responsePromise, errorExtractor, undefined, undefined, undefined, true);
    };

export const uploadOtherUserData = (data: UpdateOtherDataRequest): ThunkAction<void, {}, {}, AuthAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AuthAction>) => {
        dispatch({type: UPDATE_OTHER_USER_DATA, payload: null});
        const responsePromise = userService.uploadOtherUserData(data);
        return await handleResponse(dispatch, UPDATE_OTHER_USER_DATA_SUCCESS, UPDATE_OTHER_USER_DATA_FAIL, responsePromise, errorExtractor);
    };

export const setProfilePic = (payload: string) => (dispatch: ThunkDispatch<{}, {}, AuthAction>) => {
    dispatch({type: SET_PROFILE_PIC, payload});
};

