import {InfoCertAction, InfoCertState} from "./infocert-interface";
import {
    ActionDTO,
    ArchiveItemDTO,
    CombinationsResponse,
    InfocertDossierType,
    OneShotIssueSignResponse,
    OneShotRegisterResponse,
    OneShotSendOTPResponse,
    OneShotStartResponse,
    ProcessStartResponse,
    RemoteSignPrepareSignatureResponse,
    RemoteSignSendOTPResponse,
    RemoteSignSignContractsResponse,
    RemoteSignStartResponse,
    SignStatusRequest,
    WebIdDossierResponse,
    WebIdQueueResponse,
    WebIdStartResponse
} from "../../model/InfoCertDTO";
import {infoCertService} from "../../service/InfoCertService";
import {toastUtils} from "../../utils/toastUtils";
import i18next from "i18next";

export const CREATE_DOSSIER_REQUEST = 'createDossier';
export const CLEAR_FAIL_ERROR = 'clearFailError';
export const CREATE_DOSSIER_SUCCESS = 'createDossierSuccess';
export const CREATE_DOSSIER_FAIL = 'createDossierFail';
export const GET_NEW_TOKEN_REQUEST = 'getNewTokenRequest';
export const GET_NEW_TOKEN_SUCCESS = 'getNewTokenSuccess';
export const GET_NEW_TOKEN_FAIL = 'getNewTokenFail';
export const SIGN_DOCUMENT_REQUEST = 'signDocumentRequest';
export const SIGN_DOCUMENT_SUCCESS = 'signDocumentSuccess';
export const SIGN_DOCUMENT_FAIL = 'signDocumentFail';
export const PROCESS_START_REQUEST = 'processStart';
export const PROCESS_START_SUCCESS = 'processStartSuccess';
export const PROCESS_START_FAIL = 'processStartFail';
export const AUTHTOKEN_REQUEST = 'getAuthTokenRequest';
export const AUTHTOKEN_SUCCESS = 'getAuthTokenSuccess';
export const AUTHTOKEN_FAIL = 'getAuthTokenFail';
export const WEB_ID_START_REQUEST = 'webIdStart';
export const WEB_ID_START_SUCCESS = 'webIdStartSuccess';
export const WEB_ID_START_FAIL = 'webIdStartFail';
export const WEB_ID_QUEUE_REQUEST = 'webIdQueue';
export const WEB_ID_QUEUE_SUCCESS = 'webIdQueueSuccess';
export const WEB_ID_QUEUE_FAIL = 'webIdQueueFail';
export const WEB_ID_DOSSIER_REQUEST = 'webIdDossier';
export const WEB_ID_DOSSIER_SUCCESS = 'webIdDossierSuccess';
export const WEB_ID_DOSSIER_FAIL = 'webIdDossierFail';
export const ONE_SHOT_START_REQUEST = 'oneShotStart';
export const ONE_SHOT_START_SUCCESS = 'oneShotStartSuccess';
export const ONE_SHOT_START_FAIL = 'oneShotStartFail';
export const ONE_SHOT_REGISTER_REQUEST = 'oneShotRegister';
export const ONE_SHOT_REGISTER_SUCCESS = 'oneShotRegisterSuccess';
export const ONE_SHOT_REGISTER_FAIL = 'oneShotRegisterFail';
export const ONE_SHOT_ISSUE_SIGN_REQUEST = 'oneShotIssueSign';
export const ONE_SHOT_ISSUE_SIGN_SUCCESS = 'oneShotIssueSignSuccess';
export const ONE_SHOT_ISSUE_SIGN_FAIL = 'oneShotIssueSignFail';
export const ONE_SHOT_SEND_OTP_REQUEST = 'oneShotSendOtp';
export const ONE_SHOT_SEND_OTP_SUCCESS = 'oneShotSendOtpSuccess';
export const ONE_SHOT_SEND_OTP_FAIL = 'oneShotSendOtpFail';
export const REMOTE_SIGN_START_REQUEST = 'remoteSignStartRequest';
export const REMOTE_SIGN_START_SUCCESS = 'remoteSignStartSuccess';
export const REMOTE_SIGN_START_FAIL = 'remoteSignStartFail';
export const REMOTE_SIGN_PREPARE_SIGNATURE_REQUEST = 'remoteSignPrepareSignatureRequest';
export const REMOTE_SIGN_PREPARE_SIGNATURE_SUCCESS = 'remoteSignPrepareSignatureSuccess';
export const REMOTE_SIGN_PREPARE_SIGNATURE_FAIL = 'remoteSignPrepareSignatureFail';
export const REMOTE_SIGN_SIGN_CONTRACTS_REQUEST = 'remoteSignSignContractsRequest';
export const REMOTE_SIGN_SIGN_CONTRACTS_SUCCESS = 'remoteSignSignContractsSuccess';
export const REMOTE_SIGN_SIGN_CONTRACTS_FAIL = 'remoteSignSignContractsFail';
export const REMOTE_SIGN_SEND_OTP_REQUEST = 'remoteSignSendOTPRequest';
export const REMOTE_SIGN_SEND_OTP_SUCCESS = 'remoteSignSendOTPSuccess';
export const REMOTE_SIGN_SEND_OTP_FAIL = 'remoteSignSendOTPFail';
export const SET_INFO_CERT_ERROR = 'setInfoCertError';
export const SET_INFO_CERT_DOSSIER_TOKEN = 'setInfoCertDossierToken';
export const SET_INFO_CERT_SESSION_KEY = 'setInfoCertSessionKey';
export const SET_INFO_CERT_TASK_ID = 'setInfoCertTaskId';
export const SET_CHAT_HISTORY = 'setChatHistory';
export const ADD_TO_CHAT_HISTORY = 'addToChatHistory';
export const SET_CURRENT_DOCUMENT_SIGN_STEP = 'setCurrentDocumentSignStep';
export const LOGOUT_INFO_CERT_REQUEST = 'logoutInfoCertRequest';
export const LOGOUT_INFO_CERT_SUCCESS = 'logoutInfoCertSuccess';
export const LOGOUT_INFO_CERT_FAIL = 'logoutInfoCertFail';
export const DELETE_INFO_CERT_DOSSIER_REQUEST = 'deleteInfoCertDossierRequest';
export const DELETE_INFO_CERT_DOSSIER_SUCCESS = 'deleteInfoCertDossierSuccess';
export const DELETE_INFO_CERT_DOSSIER_FAIL = 'deleteInfoCertDossierFail';
export const RESET_INFO_CERT = 'resetInfoCert';
export const DOWNLOAD_PDF_FAIL = 'downloadPdfFail';
export const DOWNLOAD_PDF_REQUEST = 'downloadPdfRequest';
export const DOWNLOAD_PDF_SUCCESS = 'downloadPdfSuccess';
export const ADD_DOCUMENTS_REQUEST = 'addDocumentsRequest';
export const ADD_DOCUMENTS_SUCCESS = 'addDocumentsSuccess';
export const ADD_DOCUMENTS_FAIL = 'addDocumentsFail';
export const ACTIONS_REQUEST = "actionsRequest";
export const ACTIONS_SUCCESS = "actionsSuccess";
export const ACTIONS_FAIL = "actionsError";
export const ACTIONS_SILENT_REQUEST = "actionsSilentRequest";
export const ACTIONS_SILENT_SUCCESS = "actionsSilentSuccess";
export const ACTIONS_SILENT_FAIL = "actionsSilentError";
export const COMBINATIONS_REQUEST = "combinationsRequest";
export const COMBINATIONS_SUCCESS = "combinationsSuccess";
export const COMBINATIONS_FAIL = "combinationsError";
export const DELEGATED_COMBINATIONS_REQUEST = "combinationsDelegatedRequest";
export const DELEGATED_COMBINATIONS_SUCCESS = "combinationsDelegatedSuccess";
export const DELEGATED_COMBINATIONS_FAIL = "combinationsDelegatedError";
export const SET_COMBINATIONS_ERROR = "setCombinationsError";
export const STATUS_REQUEST = "signStatusRequest";
export const STATUS_SUCCESS = "signStatusSuccess";
export const STATUS_FAIL = "signStatusError";
export const INFOCERT_PROCESS_STATUS_REQUEST = "InfocertStatusStatusRequest";
export const INFOCERT_PROCESS_STATUS_SUCCESS = "InfocertStatusStatusSuccess";
export const INFOCERT_PROCESS_STATUS_FAIL = "InfocertStatusStatusError";
export const SIGN_REQUEST = "signRequest";
export const SIGN_SUCCESS = "signSuccess";
export const SIGN_FAIL = "signError";
export const ARCHIVE_REQUEST = "archiveRequest";
export const ARCHIVE_SUCCESS = "archiveSuccess";
export const ARCHIVE_FAIL = "archiveError";
export const ADMIN_ARCHIVE_REQUEST = "archiveRequest";
export const ADMIN_ARCHIVE_SUCCESS = "archiveSuccess";
export const ADMIN_ARCHIVE_FAIL = "archiveError";
export const SET_ACTION_DATA = "setActionData";
export const SET_COMBINATION = "setCombination";
export const RESET_ACTIONS = "resetActionCombination";
export const CLEAR_ARCHIVE = "clearArchive";


const defaultState: InfoCertState = {
    isFetchingDossierToken: false,
    isFetchingNewToken: false,
    isFetchingSignDocumentToken: false,
    getNewTokenResponse: "",
    isFetchingProcessStart: false,
    processStartResponse: "",
    isFetchingDeleteDossier: false,
    isFetchingWebIdStart: false,
    webIdStartResponse: {} as WebIdStartResponse,
    isFetchingWebIdQueue: false,
    webIdQueueResponse: {} as WebIdQueueResponse,
    isFetchingWebIdDossier: false,
    webIdDossierResponse: {} as WebIdDossierResponse,
    isFetchingOneShotStart: false,
    oneShotStartResponse: {} as OneShotStartResponse,
    isFetchingOneShotRegister: false,
    oneShotRegisterResponse: {} as OneShotRegisterResponse,
    isFetchingOneShotIssueSign: false,
    oneShotIssueSignResponse: {} as OneShotIssueSignResponse,
    isFetchingOneShotSendOTP: false,
    oneShotSendOTPResponse: {} as OneShotSendOTPResponse,
    isFetchingRemoteSignStart: false,
    remoteSignStartResponse: {} as RemoteSignStartResponse,
    isFetchingRemoteSignPrepareSignature: false,
    remoteSignPrepareSignatureResponse: {} as RemoteSignPrepareSignatureResponse,
    isFetchingRemoteSignSignContracts: false,
    remoteSignSignContractsResponse: {} as RemoteSignSignContractsResponse,
    isFetchingRemoteSignSendOTP: false,
    remoteSignSendOTPResponse: {} as RemoteSignSendOTPResponse,
    isFetchingAddDocuments: false,
    addDocumentsResponse: 0,
    error: "",
    dossierToken: "",
    redirectUrl: "",
    documents: null,
    sessionKey: "",
    taskId: "",
    chatHistory: [],
    resendOtpTimeout: 0,
    contractBase64: "",
    documentSignStep: "",
    isFetchingActions: false,
    actionsResponse: [] as ActionDTO[],
    actionsFail: "",
    actionsPresented: false,
    isFetchingCombinations: false,
    combinationsResponse: {} as CombinationsResponse,
    combinationsFail: "",
    delegatedCombinationsIsFetching: false,
    delegatedCombinationsResponse: {} as CombinationsResponse,
    delegatedCombinationsFail: "",
    isFetchingSignStatus: false,
    signStatusResponse: {} as SignStatusRequest,
    signStatusFail: "",
    isFetchingInfocertProcessStatus: false,
    infocertProcessStatusResponse: null,
    infocertProcessStatusDossierTypeResponse: null,
    infocertProcessStatusFail: "",
    archiveIsFetching: false,
    archiveStatusResponse: [] as ArchiveItemDTO[],
    archiveStatusFail: "",
    actionRequestData: "",
    isFetchingSign: false,
    signResponse: "",
    signFail: "",
    selectedCombination: null
};

export function infoCertReducer(state = defaultState, action: InfoCertAction): InfoCertState {
    switch (action.type) {
        case CLEAR_FAIL_ERROR:
            return {
                ...state,
                signFail: ""
            };

        case CLEAR_ARCHIVE:
            return {
                ...state,
                archiveStatusResponse: []
            };
        case CREATE_DOSSIER_REQUEST:
            return {
                ...state,
                dossierToken: defaultState.dossierToken,
                documents: defaultState.documents,
                redirectUrl: defaultState.redirectUrl,
                infocertProcessStatusDossierTypeResponse: action.payload as InfocertDossierType,
                isFetchingDossierToken: true
            };
        case CREATE_DOSSIER_SUCCESS:
            const {token: dossierToken, documents, redirectUrl} = action.payload;
            return {
                ...state,
                dossierToken,
                documents,
                redirectUrl,
                isFetchingDossierToken: false
            };
        case CREATE_DOSSIER_FAIL:
            return {
                ...state,
                dossierToken: defaultState.dossierToken,
                documents: defaultState.documents,
                redirectUrl: defaultState.redirectUrl,
                error: action.payload || i18next.t("errors.infocert.internalServerError"),
                isFetchingDossierToken: false,
            };
        case SIGN_DOCUMENT_REQUEST:
            return {
                ...state,
                isFetchingSignDocumentToken: true
            };
        case SIGN_DOCUMENT_SUCCESS:
            return {
                ...state,
                dossierToken: action.payload.token ? action.payload.token : "",
                isFetchingSignDocumentToken: false
            };
        case SIGN_DOCUMENT_FAIL:
            return {
                ...state,
                error: action.payload,
                isFetchingSignDocumentToken: false,
            };

        case GET_NEW_TOKEN_REQUEST:
            return {
                ...state,
                isFetchingNewToken: true
            };
        case GET_NEW_TOKEN_SUCCESS:
            return {
                ...state,
                getNewTokenResponse: action.payload,
                dossierToken: action.payload,
                isFetchingNewToken: false
            };
        case GET_NEW_TOKEN_FAIL:
            return {
                ...state,
                error: action.payload,
                isFetchingNewToken: false,
            };
        case PROCESS_START_REQUEST:
            return {
                ...state,
                signStatusResponse: {} as SignStatusRequest,
                isFetchingProcessStart: true
            };
        case PROCESS_START_SUCCESS:
            return {
                ...state,
                processStartResponse: action.payload,
                taskId: action.payload.taskId,
                sessionKey: action.payload.sessionKey,
                isFetchingProcessStart: false,
                signStatusResponse: {} as SignStatusRequest,
            };
        case PROCESS_START_FAIL:
            console.error('Infocert PROCESS_START_FAIL', action.payload);
            return {
                ...state,
                processStartResponse: {} as ProcessStartResponse,
                error: i18next.t("errors.infocert.signInfocertError"),
                isFetchingProcessStart: false,
                signStatusResponse: {} as SignStatusRequest,
            };
        case AUTHTOKEN_REQUEST:
            return {
                ...state,
                isFetchingProcessStart: true
            };
        case AUTHTOKEN_SUCCESS:
            return {
                ...state,
                taskId: action.payload.taskId,
                sessionKey: action.payload.sessionKey,
                isFetchingProcessStart: false,
                infocertProcessStatusDossierTypeResponse: InfocertDossierType.SPID,
                signStatusResponse: {} as SignStatusRequest,
            };
        case AUTHTOKEN_FAIL:
            console.error('Infocert AUTHTOKEN_FAIL', action.payload);
            return {
                ...state,
                error: i18next.t("errors.infocert.signInfocertError"),
                isFetchingProcessStart: false,
                signStatusResponse: {} as SignStatusRequest,
            };
        case WEB_ID_START_REQUEST:
            return {
                ...state,
                isFetchingWebIdStart: true
            };
        case WEB_ID_START_SUCCESS:
            return {
                ...state,
                webIdStartResponse: action.payload,
                isFetchingWebIdStart: false,
            };
        case WEB_ID_START_FAIL:
            return {
                ...state,
                webIdStartResponse: {} as WebIdQueueResponse,
                error: action.payload,
                isFetchingWebIdStart: false,
            };
        case WEB_ID_QUEUE_REQUEST:
            return {
                ...state,
                isFetchingWebIdQueue: true
            };
        case WEB_ID_QUEUE_SUCCESS:
            return {
                ...state,
                webIdQueueResponse: action.payload,
                isFetchingWebIdQueue: false,
            };
        case WEB_ID_QUEUE_FAIL:
            return {
                ...state,
                webIdQueueResponse: {} as WebIdStartResponse,
                error: action.payload,
                isFetchingWebIdQueue: false,
            };
        case WEB_ID_DOSSIER_REQUEST:
            return {
                ...state,
                isFetchingWebIdDossier: true
            };
        case WEB_ID_DOSSIER_SUCCESS:
            return {
                ...state,
                webIdDossierResponse: action.payload,
                isFetchingWebIdDossier: false
            };
        case WEB_ID_DOSSIER_FAIL:
            return {
                ...state,
                webIdDossierResponse: {} as WebIdDossierResponse,
                error: action.payload,
                isFetchingWebIdDossier: false,
            };
        case ONE_SHOT_START_REQUEST:
            return {
                ...state,
                isFetchingOneShotStart: true
            };
        case ONE_SHOT_START_SUCCESS:
            return {
                ...state,
                oneShotStartResponse: action.payload,
                isFetchingOneShotStart: false
            };
        case ONE_SHOT_START_FAIL:
            infoCertService.deleteDossier();
            toastUtils.warningToast("errors.infocert.documentSignFail");
            return {
                ...state,
                oneShotStartResponse: {} as OneShotStartResponse,
                error: action.payload,
                isFetchingOneShotStart: false,
            };
        case ONE_SHOT_REGISTER_REQUEST:
            return {
                ...state,
                isFetchingOneShotRegister: true
            };
        case ONE_SHOT_REGISTER_SUCCESS:
            return {
                ...state,
                oneShotRegisterResponse: action.payload,
                isFetchingOneShotRegister: false
            };
        case ONE_SHOT_REGISTER_FAIL:
            infoCertService.deleteDossier();
            return {
                ...state,
                oneShotRegisterResponse: {} as OneShotRegisterResponse,
                error: action.payload || i18next.t("errors.infocert.documentSignFail"),
                isFetchingOneShotRegister: false,
            };
        case ONE_SHOT_ISSUE_SIGN_REQUEST:
            return {
                ...state,
                isFetchingOneShotIssueSign: true
            };
        case ONE_SHOT_ISSUE_SIGN_SUCCESS:
            return {
                ...state,
                oneShotIssueSignResponse: action.payload,
                documents: action.payload.enrollment.documents,
                isFetchingOneShotIssueSign: false
            };
        case ONE_SHOT_ISSUE_SIGN_FAIL:
            return {
                ...state,
                oneShotIssueSignResponse: {} as OneShotIssueSignResponse,
                error: action.payload,
                isFetchingOneShotIssueSign: false,
            };
        case ONE_SHOT_SEND_OTP_REQUEST:
            return {
                ...state,
                isFetchingOneShotSendOTP: true
            };
        case ONE_SHOT_SEND_OTP_SUCCESS:
            const d = new Date();
            const resendOtpTimeout = d.getTime() + 1000 * 60;
            return {
                ...state,
                resendOtpTimeout,
                isFetchingOneShotSendOTP: false
            };
        case ONE_SHOT_SEND_OTP_FAIL:
            return {
                ...state,
                isFetchingOneShotSendOTP: false
            };
        case REMOTE_SIGN_START_REQUEST:
            return {
                ...state,
                isFetchingRemoteSignStart: true
            };
        case REMOTE_SIGN_START_SUCCESS:
            return {
                ...state,
                remoteSignStartResponse: action.payload,
                isFetchingRemoteSignStart: false
            };
        case REMOTE_SIGN_START_FAIL:
            return {
                ...state,
                remoteSignStartResponse: {} as RemoteSignStartResponse,
                error: action.payload,
                isFetchingRemoteSignStart: false,
            };
        case REMOTE_SIGN_PREPARE_SIGNATURE_REQUEST:
            return {
                ...state,
                isFetchingRemoteSignPrepareSignature: true
            };
        case REMOTE_SIGN_PREPARE_SIGNATURE_SUCCESS:
            return {
                ...state,
                remoteSignPrepareSignatureResponse: action.payload,
                isFetchingRemoteSignPrepareSignature: false
            };
        case REMOTE_SIGN_PREPARE_SIGNATURE_FAIL:
            return {
                ...state,
                remoteSignPrepareSignatureResponse: {} as RemoteSignPrepareSignatureResponse,
                error: action.payload,
                isFetchingRemoteSignPrepareSignature: false,
            };
        case REMOTE_SIGN_SIGN_CONTRACTS_REQUEST:
            return {
                ...state,
                isFetchingRemoteSignSignContracts: true
            };
        case REMOTE_SIGN_SIGN_CONTRACTS_SUCCESS:
            return {
                ...state,
                remoteSignSignContractsResponse: action.payload,
                documents: action.payload.signatures.documents,
                isFetchingRemoteSignSignContracts: false
            };
        case REMOTE_SIGN_SIGN_CONTRACTS_FAIL:
            return {
                ...state,
                remoteSignSignContractsResponse: {} as RemoteSignSignContractsResponse,
                error: action.payload,
                isFetchingRemoteSignSignContracts: false,
            };
        case REMOTE_SIGN_SEND_OTP_REQUEST:
            return {
                ...state,
                isFetchingRemoteSignSendOTP: true
            };
        case REMOTE_SIGN_SEND_OTP_SUCCESS:
            const today = new Date();
            const resendOtpTimeoutRemoteSign = today.getTime() + 1000 * 60;
            return {
                ...state,
                resendOtpTimeout: resendOtpTimeoutRemoteSign,
                remoteSignSendOTPResponse: action.payload,
                isFetchingRemoteSignSendOTP: false
            };
        case REMOTE_SIGN_SEND_OTP_FAIL:
            return {
                ...state,
                remoteSignSendOTPResponse: {} as RemoteSignSendOTPResponse,
                error: action.payload,
                isFetchingRemoteSignSendOTP: false,
            };
        case SET_CURRENT_DOCUMENT_SIGN_STEP:
            const newState = {
                ...state,
                documentSignStep: action.payload.documentSignStep,
            };
            if (action.payload.token) newState.dossierToken = action.payload.token;
            return newState;
        case SET_INFO_CERT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case SET_INFO_CERT_DOSSIER_TOKEN:
            return {
                ...state,
                dossierToken: action.payload,
            };
        case SET_INFO_CERT_SESSION_KEY:
            return {
                ...state,
                sessionKey: action.payload,
            };
        case SET_INFO_CERT_TASK_ID:
            return {
                ...state,
                taskId: action.payload,
            };
        case SET_CHAT_HISTORY:
            return {
                ...state,
                chatHistory: action.payload,
            };
        case ADD_TO_CHAT_HISTORY:
            const chatHistory: any = [...state.chatHistory];
            const {author, message} = action.payload;
            const last = chatHistory[chatHistory.length - 1] || {};
            const same = last.author === author;
            if (same) {
                last.messages.push(message);
            } else {
                chatHistory.push({author, messages: [message]});
            }
            return {
                ...state,
                chatHistory
            };
        case LOGOUT_INFO_CERT_REQUEST:
        case LOGOUT_INFO_CERT_SUCCESS:
        case LOGOUT_INFO_CERT_FAIL:
            return {
                ...state,
                ...defaultState,
            };
        case DELETE_INFO_CERT_DOSSIER_REQUEST:
            return {
                ...state,
                ...defaultState,
                isFetchingDeleteDossier: true
            };
        case DELETE_INFO_CERT_DOSSIER_SUCCESS:
            return {
                ...state,
                ...defaultState,
                dossierToken: "",
                isFetchingDeleteDossier: false
            };
        case DELETE_INFO_CERT_DOSSIER_FAIL:
            return {
                ...state,
                ...defaultState,
                isFetchingDeleteDossier: false
            };
        case RESET_INFO_CERT:
            return {
                ...state,
                ...defaultState,
                actionsResponse: state.actionsResponse,
            };
        case DOWNLOAD_PDF_SUCCESS:
            return {
                ...state,
                contractBase64: action.payload,
            };
        case ADD_DOCUMENTS_REQUEST:
            return {
                ...state,
                isFetchingAddDocuments: true
            };
        case ADD_DOCUMENTS_SUCCESS:
            return {
                ...state,
                addDocumentsResponse: action.payload,
                isFetchingAddDocuments: false
            };
        case ADD_DOCUMENTS_FAIL:
            return {
                ...state,
                addDocumentsResponse: 0,
                error: action.payload,
                isFetchingAddDocuments: false,
            };

        case ACTIONS_REQUEST:
            return {
                ...state,
                isFetchingActions: true,
                actionsResponse: [],
                actionsFail: []
            };
        case ACTIONS_SUCCESS:
            return {
                ...state,
                isFetchingActions: false,
                actionsPresented: action.payload.filter((a: ActionDTO) => a.token).length,
                actionsResponse: action.payload,
                actionsFail: []
            };
        case ACTIONS_FAIL:
            return {
                ...state,
                isFetchingActions: false,
                actionsResponse: [],
                actionsPresented: false,
                actionsFail: action.payload
            };

        case ACTIONS_SILENT_REQUEST:
            return {
                ...state,
            };
        case ACTIONS_SILENT_SUCCESS:
            const actions: ActionDTO[] = action.payload;
            return {
                ...state,
                actionsPresented: actions.filter((a) => a.token).length
            };
        case ACTIONS_SILENT_FAIL:
            return {
                ...state,
                actionsPresented: false,
            };
        case COMBINATIONS_REQUEST:
            return {
                ...state,
                isFetchingCombinations: true,
                combinationsResponse: {} as CombinationsResponse,
                combinationsFail: defaultState.combinationsFail
            };
        case COMBINATIONS_SUCCESS:
            return {
                ...state,
                isFetchingCombinations: false,
                combinationsResponse: action.payload,
                combinationsFail: defaultState.combinationsFail
            };
        case COMBINATIONS_FAIL:
            return {
                ...state,
                isFetchingCombinations: false,
                combinationsResponse: {} as CombinationsResponse,
                combinationsFail: action.payload || "errors.infocert.documentSignFail"
            };
        case DELEGATED_COMBINATIONS_REQUEST:
            return {
                ...state,
                delegatedCombinationsIsFetching: true,
                delegatedCombinationsResponse: {} as CombinationsResponse,
                delegatedCombinationsFail: defaultState.combinationsFail
            };
        case DELEGATED_COMBINATIONS_SUCCESS:
            return {
                ...state,
                delegatedCombinationsIsFetching: false,
                delegatedCombinationsResponse: action.payload,
                delegatedCombinationsFail: defaultState.combinationsFail
            };
        case DELEGATED_COMBINATIONS_FAIL:
            return {
                ...state,
                delegatedCombinationsIsFetching: false,
                delegatedCombinationsResponse: {} as CombinationsResponse,
                delegatedCombinationsFail: action.payload
            };
        case SIGN_REQUEST:
            return {
                ...state,
                isFetchingSign: true,
                signResponse: "",
                signFail: ""
            };
        case SIGN_SUCCESS:
            return {
                ...state,
                isFetchingSign: false,
                actionRequestData: "",
                signResponse: action.payload,
                combinationsResponse: {} as CombinationsResponse,
                signFail: ""
            };
        case SIGN_FAIL:
            return {
                ...state,
                isFetchingSign: false,
                signResponse: null,
                signFail: action.payload
            };
        case STATUS_REQUEST:
            return {
                ...state,
                isFetchingSignStatus: true,
                signStatusResponse: {} as SignStatusRequest,
                signStatusFail: ""
            };
        case STATUS_SUCCESS:
            return {
                ...state,
                isFetchingSignStatus: false,
                signStatusResponse: action.payload,
                signStatusFail: "",
            };
        case STATUS_FAIL:
            return {
                ...state,
                isFetchingSignStatus: false,
                signStatusResponse: {} as SignStatusRequest,
                signStatusFail: action.payload
            };
        case INFOCERT_PROCESS_STATUS_REQUEST:
            return {
                ...state,
                isFetchingInfocertProcessStatus: true,
                infocertProcessStatusResponse: null,
                infocertProcessStatusDossierTypeResponse: null,
                infocertProcessStatusFail: ""
            };
        case INFOCERT_PROCESS_STATUS_SUCCESS:
            return {
                ...state,
                isFetchingInfocertProcessStatus: false,
                infocertProcessStatusResponse: action.payload?.dossierStatus,
                infocertProcessStatusDossierTypeResponse: action.payload?.dossierType,
                infocertProcessStatusFail: "",
            };
        case INFOCERT_PROCESS_STATUS_FAIL:
            return {
                ...state,
                isFetchingInfocertProcessStatus: false,
                infocertProcessStatusResponse: null,
                infocertProcessStatusDossierTypeResponse: null,
                infocertProcessStatusFail: action.payload
            };
        case ARCHIVE_REQUEST:
            return {
                ...state,
                archiveIsFetching: true,
                archiveStatusResponse: [] as ArchiveItemDTO[],
                archiveStatusFail: "",

            };
        case ARCHIVE_SUCCESS:
            return {
                ...state,
                archiveIsFetching: false,
                archiveStatusResponse: action.payload,
                archiveStatusFail: "",
            };
        case ARCHIVE_FAIL:
            return {
                ...state,
                archiveIsFetching: false,
                archiveStatusResponse: [] as ArchiveItemDTO[],
                archiveStatusFail: action.payload
            };
        case SET_ACTION_DATA:
            return {
                ...state,
                actionRequestData: action.payload
            };
        case SET_COMBINATION:
            return {
                ...state,
                selectedCombination: action.payload
            };
        case SET_COMBINATIONS_ERROR:
            return {
                ...state,
                combinationsFail: action.payload
            };
        case RESET_ACTIONS:
            return {
                ...state,
                selectedCombination: defaultState.selectedCombination,
                combinationsResponse: defaultState.combinationsResponse,
                actionRequestData: defaultState.actionRequestData
            };
        default:
            return state;
    }
}
