import * as React from "react";
import {useTranslation} from "react-i18next";
import TextInput from "../TextInput/TextInput";
import MyDropdown from "../MyDropdown/MyDropdown";
import {countryUtils} from "../../../utils/countryUtils";
import "../../Common/ContainerRow/ContainerRow.scss";

interface Props {
    itemClassName?: string
    country: string,
    countryOnChange: any,
    countryTitle: any,
    province: any,
    provinceOnChange: any,
    provinceTitle: any,
    place: any,
    placeOnChange: any
    placeOnBlur: any
    placeTitle: any
    placePlaceholder?: any
    editable?: boolean
    enableAllProvince?: boolean
}

const OnboardingPlaceInputs: React.FC<Props> = ({
                                                    itemClassName,
                                                    editable = true,
                                                    country,
                                                    countryOnChange,
                                                    countryTitle,
                                                    province,
                                                    provinceOnChange,
                                                    provinceTitle,
                                                    place,
                                                    placeOnChange,
                                                    placeOnBlur,
                                                    placeTitle,
                                                    placePlaceholder,
                                                    enableAllProvince
                                                }) => {

    const {t} = useTranslation();

    const isItalian = countryUtils.fromNameToISOCode(country) === "IT";
    const allProvinces = enableAllProvince ? [t("common.dropdown.other"), ...countryUtils.getAllItalianProvince()] : countryUtils.getAllItalianProvince()
    return (
        <>
            <div className={itemClassName || "inputContainer"}>
                <MyDropdown
                    links={countryUtils.retrievesCountryName()}
                    title={countryTitle}
                    inputValue={country}
                    onChange={countryOnChange}
                    editable={editable}
                />
            </div>
            <div className={itemClassName || "inputContainer"}>
                <MyDropdown
                    links={isItalian ? allProvinces : [t("common.dropdown.other")]}
                    title={provinceTitle}
                    inputValue={province}
                    onChange={provinceOnChange}
                    editable={editable}
                />
            </div>
            <div className={itemClassName || "inputContainer"}>
                {isItalian && province !== t("common.dropdown.other") ?
                    <MyDropdown
                        title={placeTitle}
                        links={countryUtils.getAllComuni(countryUtils.fromProvinceNameToCode(province))}
                        inputValue={place}
                        onChange={(value) => {
                            placeOnChange(value);
                            placeOnBlur(value);
                        }}
                        editable={editable}
                    />
                    :
                    <TextInput
                        title={placeTitle}
                        placeholder={placePlaceholder}
                        value={place}
                        onBlur={(place) => placeOnBlur(place)}
                        onChange={placeOnChange}
                        maxLength={30}
                        editable={editable}
                        delayedOnChange
                    />
                }
            </div>
        </>
    );
};

export default React.memo(OnboardingPlaceInputs);
