import {ReactElement, ReactText} from "react";

export enum ChartType {
    BUYER_STOCK_PROFIT = "BUYER_STOCK_PROFIT",             // distribuzione dei margini disponibili alla data per divisa
    BUYER_STOCK_PROGRAM_PROFIT = "BUYER_STOCK_PROGRAM_PROFIT",     // margini disponibili alla data per divisa
    BUYER_STOCK_TREND_PROFIT = "BUYER_STOCK_TREND_PROFIT",       // trend dei margini disponibili per divisa
    BUYER_DPO_TREND_FLOW_GLOBAL = "BUYER_DPO_TREND_FLOW_GLOBAL",    // DPO – Trend (globale)
    BUYER_DPO_TREND_FLOW_PROGRAM = "BUYER_DPO_TREND_FLOW_PROGRAM",   // DPO – Trend (per programma SCF)
    BUYER_VOLUME_FLOW_STOCK = "BUYER_VOLUME_FLOW_STOCK",        // Volumi: Flussi e stock (globali/divisa e per programma SCF)
    BUYER_VOLUME_FLOW_STOCK_PROGRAM = "BUYER_VOLUME_FLOW_STOCK_PROGRAM",            // Volumi: Flussi e stock (per programma SCF)
    BUYER_MONEY_LENDER_PROFIT = "BUYER_MONEY_LENDER_PROFIT",      // Trend rendimenti Finanziatori (globali/divisa e per programma SCF)
    BUYER_DYNAMIC_DISCOUNT_USE = "BUYER_DYNAMIC_DISCOUNT_USE",     // utilizzo delle LC alla data per divisa (Buyer/programmi SCF)
    BUYER_SUPPLIER_PORTFOLIO = "BUYER_SUPPLIER_PORTFOLIO",       // Fornitori: Flussi e stock (globali e per programma SCF)
    BUYER_MONEY_LENDER_PROFIT_PROGRAM = "BUYER_MONEY_LENDER_PROFIT_PROGRAM",          // Trend rendimenti Finanziatori (per programma SCF)
    BUYER_SUPPLIER_PORTFOLIO_PROGRAM = "BUYER_SUPPLIER_PORTFOLIO_PROGRAM",           // Fornitori: Flussi e stock (per programma SCF)
    BUYER_ACTIVE_SUPPLIER_PORTFOLIO = "BUYER_ACTIVE_SUPPLIER_PORTFOLIO",            // Stock fornitori attivi – distribuzione per PF (globale)
    BUYER_ACTIVE_SUPPLIER_PORTFOLIO_PROGRAM = "BUYER_ACTIVE_SUPPLIER_PORTFOLIO_PROGRAM",    // Stock fornitori attivi – distribuzione per PF (per programma SCF)
    MONEYLENDER_CREDIT_LINE = "MONEYLENDER_CREDIT_LINE",        // utilizzo delle LC alla data per divisa (Buyer/programmi SCF)
    MONEYLENDER_PROFIT_TREND = "MONEYLENDER_PROFIT_TREND",       // Trend rendimenti (globali/divisa/Buyer/programma SCF)
    MONEYLENDER_VOLUME_FLOW_STOCK = "MONEYLENDER_VOLUME_FLOW_STOCK",  // Volumi: Flussi e stock (globali/divisa e per Buyer/programma SCF)
    MONEYLENDER_SUPPLIER_PORTFOLIO = "MONEYLENDER_SUPPLIER_PORTFOLIO",              // Fornitori: Flussi e stock (per Buyer e per programma SCF)
    MONEYLENDER_SUPPLIER_PORFOLIO = "MONEYLENDER_SUPPLIER_PORFOLIO",  // Fornitori: Flussi e stock (per Buyer e per programma SCF)
    VENDOR_EFFORT_FLOW = "VENDOR_EFFORT_FLOW",             // Fornitori: Flussi e stock per divisa (globali/Buyer)
    VENDOR_INVOICE_APPROVAL = "VENDOR_INVOICE_APPROVAL",        // Tempi medi di approvazione fatture (per Buyer)
    ADMIN_VOLUME_FLOW_STOCK = "ADMIN_VOLUME_FLOW_STOCK",        // Volumi: Flussi e stock (globali/divisa) mese in corso
    ADMIN_VOLUME_TREND = "ADMIN_VOLUME_TREND",             // Trend Volumi: Flussi e stock (globali/divisa)
    ADMIN_SUPPLIER_FLOW_STOCK = "ADMIN_SUPPLIER_FLOW_STOCK",      // Fornitori: Flussi e stock (globali/Buyer/programma SCF)
    ADMIN_SUPPLIER_STOCK = "ADMIN_SUPPLIER_STOCK",           // Stock fornitori – distribuzione per PF (globale/Buyer/programma SCF)

    MONEYLENDER_PROFIT_TREND_CURRENCY = "MONEYLENDER_PROFIT_TREND_CURRENCY",          // Trend rendimenti (globali/divisa)
    MONEYLENDER_PROFIT_TREND_PROGRAM = "MONEYLENDER_PROFIT_TREND_PROGRAM",           // Trend rendimenti (per buyer/programma SCF)
    MONEYLENDER_VOLUME_FLOW_STOCK_PROGRAM = "MONEYLENDER_VOLUME_FLOW_STOCK_PROGRAM",      // Volumi: Flussi e stock (per Buyer/programma SCF)
    ADMIN_STOCK_PROFIT = "ADMIN_STOCK_PROFIT",                         // Programmi SCF: margini disponibili alla data per divisa
    ADMIN_STOCK_TREND_PROFIT = "ADMIN_STOCK_TREND_PROFIT",                   // PF: trend dei margini disponibili per divisa
    ADMIN_STOCK_TREND_PROFIT_USER = "ADMIN_STOCK_TREND_PROFIT_USER",              // PF: trend dei margini disponibili per Buyer
    ADMIN_SUPPLIER_FLOW_STOCK_USER = "ADMIN_SUPPLIER_FLOW_STOCK_USER",             // Fornitori: Flussi e stock (Buyer/programma SCF)
    ADMIN_SUPPLIER_STOCK_USER = "ADMIN_SUPPLIER_STOCK_USER",                  // Stock fornitori – distribuzione per PF (Buyer/programma SCF)
}

export enum ChartGranularity {
    DAILY = "DAILY",
    MONTHLY = "MONTHLY",
    QUARTERLY = "QUARTERLY",
    YEARLY = "YEARLY"
}

export interface ReportRequest {
    reports: ReportConfiguration[]
}

export interface ReportConfiguration {
    reportType: ChartType,
    reportGranularity?: ChartGranularity,
    filter?: string,
    startDate?: Date,
    endDate?: Date
}



export interface ReportItem {
    data: any,

    configuration: any
}

export interface DocumentReport {
    base64: string,
    fileName: string
}

interface LegendType {
    value: any;
    id?: string;
    type?: LegendType;
    color?: string;
    payload?: {
        strokeDasharray: ReactText;
    };
    formatter?: any;
    inactive?: boolean;
    legendIcon?: ReactElement<SVGElement>;
}

export interface ChartDataItem {
    name: string,
    [index: string]: any
}

export enum ComposedGraphType {
    BAR = "BAR",
    LINE = "LINE",
    AREA = "AREA",
}

export enum ChartRenderType {
    PIE = "PIE",
    COMPOSED = "COMPOSED"
}

export interface YAxisItem {
    dataKey: string,
    type: ComposedGraphType,
    barSize?: number
    fill?: string
    stroke?: string
    stackId?: number
    isAnimationActive?: boolean
    yAxisId?: string
}

export enum ChartFilterType {
    NONE = "NONE",
    CURRENCY = "CURRENCY",
    PROGRAM = "PROGRAM",
    BUYER = "BUYER"
}

export enum RoleChartType {
    ADMIN = "ADMIN",
    BUYER = "BUYER",
    MONEYLENDER = "MONEYLENDER",
    VENDOR = "VENDOR"
}

export interface AvailableChartDTO {
    chartFilterType: ChartFilterType
    chartType: ChartType
    filters: string[]
    granularityRequired: boolean

}

export interface ChartDataInfo {
    xaxisKey: string,
    xaxisName?: string,
    yaxisRightKey?: string,
    yaxisRightName?: string,
    yaxisItems: YAxisItem[],
    yaxisItemsLength?: number
}

export interface ChartDataType {
    title?: string
    description?: string
    legend?: LegendType[]
    barSize?: number
    barGap?: number
    barCategoryGap?: number
    height?: number
    width?: number
    type: ChartRenderType
    margin?: {
        top?: number
        right?: number
        bottom?: number
        left?: number
    }
    chartInfo: ChartDataInfo
}

export interface DocumentReport {
    base64: string,
    fileName: string
}
