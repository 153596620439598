import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { inquiryService } from '../../service/InquiryService';
import { handleResponse } from '../reduxUtils';
import { InquiryAction } from './inquiry-interface';
import {
  CLEAN_INQUIRY_DATA,
  GET_INQUIRY_ROLES,
  GET_INQUIRY_ROLES_FAIL,
  GET_INQUIRY_ROLES_SUCCESS,
  INQUIRY_DATA,
  INQUIRY_DATA_FAIL,
  INQUIRY_DATA_SUCCESS,
} from './inquiry-reducers';

export const cleanInquiryData =
  (): ThunkAction<void, {}, {}, InquiryAction> =>
  async (dispatch: ThunkDispatch<{}, {}, InquiryAction>) => {
    dispatch({ type: CLEAN_INQUIRY_DATA, payload: null });
  };

export const getInquiryRoles =
  (): ThunkAction<void, {}, {}, InquiryAction> =>
  async (dispatch: ThunkDispatch<{}, {}, InquiryAction>) => {
    dispatch({ type: GET_INQUIRY_ROLES, payload: null });
    let responsePromise = inquiryService.getInquiryRoles();
    return await handleResponse(
      dispatch,
      GET_INQUIRY_ROLES_SUCCESS,
      GET_INQUIRY_ROLES_FAIL,
      responsePromise
    );
  };

export const inquiryData =
  (
    role: string,
    vatNumber?: string,
    businessName?: string
  ): ThunkAction<void, {}, {}, InquiryAction> =>
  async (dispatch: ThunkDispatch<{}, {}, InquiryAction>) => {
    dispatch({ type: INQUIRY_DATA, payload: null });
    let responsePromise = inquiryService.inquiryData({
      role,
      vatNumber,
      businessName,
    });
    return await handleResponse(
      dispatch,
      INQUIRY_DATA_SUCCESS,
      INQUIRY_DATA_FAIL,
      responsePromise
    );
  };
