import React, { ReactNode } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal, Nav, Navbar, NavDropdown } from "react-bootstrap";
import "./NavBar.scss";
import ProfilePic from "../Common/ProfilePic/ProfilePic";
import { AppState } from "../../redux/store";
import { connect } from "react-redux";
import { roleUtils } from "../../utils/roleUtils";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { setConfigurationPricesStep } from "../../redux/moneylender/moneylender-actions";
import { CompanyRole, CompanyRoleData, Roles } from "../../model/UserDTO";
import Polaris_logo from "../../assets/Images/Polaris_logo.svg";
import { setNewUserStep } from "../../redux/admin";
import { BuyerNewCompanySteps, UsersSteps } from "../../model/AdminDTO";
import { setSellInvoicesStep } from "../../redux/supplier/supplier-actions";
import { SellInvoicesStep } from "../../model/VendorDTO";
import {
  clearInviteCustomerResponse,
  setAdminActionDispatcher,
} from "../../redux/admin/admin-actions";
import { admin_archive_step } from "../../screens/admin/AdminArchive/AdminArchive";
import { configService } from "../../service/ConfigService";
import { switchUser } from "../../redux/auth/auth-actions";
import i18n from "i18next";
import NewUserCard from "../AdminComponent/NewUserCard/NewUserCard";
import { individualIcon } from "../../utils/dynamicSVG";
import { getPrograms } from "../../redux/program";
import { setBuyerNewCompanyStep } from "../../redux/buyer/buyer-actions";

export const rolePaths: any = {
  [Roles.ADMIN]: {
    home: "/admin",
    customers: "/admin/customers",
    newUser: "/admin/users/newUser",
    companyInfo: "/admin/users/companyInfo",
    modifyBlockUser: "/admin/users/modifyBlockUser",
    users: "/admin/users",
    onBoardingStatus: "/admin/onBoardingStatus",
    pendingRequest: "/admin/pendingRequest",
    archive: "/archive",
    archiveBuyer: "/archive/buyer",
    archiveVendor: "/archive/vendor",
    archiveMoneylender: "/archive/moneylender",
    generateReport: "/generateReport",
    sap: "/SAP",
    invoice: "/admin/activeInvoice",
    inquiry: "/inquiry",
    ratingESG: "/admin/ratingESG",
    ESGCertificateList: "/admin/ESGCertificateList",
  },
  [Roles.BUYER]: {
    home: "/buyer",
    users: "/buyer/users",
    program: "/buyer/SCF_Program",
    credit: "/buyer/dynamicDiscount",
    treasury: "/buyer/treasury",
    sign: "/sign",
    delegatedManagement: "/buyer/users/delegatedManagement",
    inviteNewCustomer: "/buyer/users/inviteNewCustomer",
    pendingCustomer: "/buyer/users/requests",
    archive: "/archive",
    generateReport: "/generateReport",
    invoice: "buyer/invoice",
    activeInvoice: "/buyer/invoice/activeInvoice",
    newInvoice: "/buyer/invoice/newInvoice",
    editInvoice: "/buyer/invoice/editInvoice",
    inquiry: "/inquiry",
    registry: "/buyer/registry",
    moneylenderRegistry: "/buyer/registry/moneylender",
    vendorRegistry: "/buyer/registry/vendor",
    operations: "/buyer/operations",
    cession: "/buyer/operations/cession",
    moneylenderInfo: "/buyer/operations/moneylenderInfo",
  },
  [Roles.VENDOR]: {
    home: "/vendor",
    invoice: "/supplier/activeInvoice",
    users: "/supplier/users",
    marketplace: "/supplier/marketplace",
    sell: "/supplier/marketplace/sellInvoices",
    pending: "/supplier/marketplace/pendingInvoices",
    active: "/supplier/marketplace/activeInvoices",
    inviteNewCustomer: "/supplier/users/inviteNewCustomer",
    delegatedManagement: "/supplier/users/delegatedManagement",
    pendingCustomer: "/supplier/users/requests",
    sign: "/sign",
    archive: "/archive",
    incomeOperations: "/supplier/operations/income",
    generateReport: "/generateReport",
    inquiry: "/inquiry",
  },
  [Roles.MONEYLENDER]: {
    home: "/moneylender",
    credit: "/moneylender/creditLines",
    sign: "/moneylender/sign",
    users: "/moneylender/users",
    pending: "/moneylender/marketplace/pendingInvoices",
    archive: "/moneylender/archive",
    vendorRegistry: "/moneylender/vendor/vendorRegistry",
    downloadVendorRegistry: "/moneylender/vendor/downloadVendorRegistry",
    marketplace: "/moneylender/marketplace",
    sell: "/moneylender/marketplace/sellInvoices",
    activeInvoices: "/moneylender/marketplace/activeInvoices",
    inviteNewCustomer: "/moneylender/users/inviteUsers",
    delegatedManagement: "/moneylender/users/delegatedManagement",
    pendingCustomer: "/moneylender/users/requests",
    cessionOperations: "/moneylender/operations/cessions",
    incomeOperations: "/moneylender/operations/income",
    generateReport: "/generateReport",
    inquiry: "/inquiry",
  },
  common: {
    onBoarding: "/onBoarding",
    onBoardingSpid: "/onBoardingSpid",
    onBoardingSpidFail: "/onBoardingSpidFail",
    documentSign: "/documentSign",
    login: "/login",
    logout: "/logout",
    forgotPassword: "/forgotPassword",
    resetPassword: "/resetPassword",
    personalInfo: "/personalInfo",
    contactUs: "/contactUs",
    report: "/report",
    swapUser: "/users/swap",
    documentsManagement: "/users/documentsManagement",
  },
};

const buyerPaths = rolePaths[Roles.BUYER];
const adminPaths = rolePaths[Roles.ADMIN];
const moneylenderPaths = rolePaths[Roles.MONEYLENDER];
const vendorPaths = rolePaths[Roles.VENDOR];
const commonPaths = rolePaths.common;

export const rolePathPermissions = {
  common: Object.keys(commonPaths).map((key) => commonPaths[key]),
  [Roles.BUYER]: [
    buyerPaths.home,
    buyerPaths.sign,
    buyerPaths.archive,
    buyerPaths.generateReport,
    buyerPaths.invoice,
    buyerPaths.activeInvoice,
    buyerPaths.inquiry,
  ],
  [Roles.BUYER_NO_SAP]: [
    buyerPaths.newInvoice,
    buyerPaths.editInvoice,
    buyerPaths.vendorRegistry,
    buyerPaths.moneylenderRegistry,
    buyerPaths.registry,
    buyerPaths.operations,
    buyerPaths.cession,
    buyerPaths.moneylenderInfo,
  ],
  [Roles.BUYER_PROGRAM]: [buyerPaths.program],
  /*    [Roles.BUYER_PAYMENT]: [
        buyerPaths.treasury,
    ],*/
  [Roles.BUYER_DYNAMIC_DISCOUNT]: [buyerPaths.credit],
  [Roles.BUYER_ADMIN]: [
    buyerPaths.users,
    buyerPaths.delegatedManagement,
    buyerPaths.inviteNewCustomer,
    buyerPaths.pendingCustomer,
  ],
  [Roles.MONEYLENDER]: [
    moneylenderPaths.home,
    moneylenderPaths.sign,
    moneylenderPaths.archive,
    moneylenderPaths.generateReport,
    moneylenderPaths.inquiry,
  ],
  [Roles.MONEYLENDER_ADMIN]: [
    moneylenderPaths.users,
    moneylenderPaths.inviteNewCustomer,
    moneylenderPaths.delegatedManagement,
    moneylenderPaths.pendingCustomer,
  ],
  [Roles.MONEYLENDER_CREDIT_LINE]: [moneylenderPaths.credit],
  [Roles.MONEYLENDER_SALE_OF_CREDIT]: [
    moneylenderPaths.marketplace,
    moneylenderPaths.pending,
    moneylenderPaths.activeInvoices,
    moneylenderPaths.sell,
  ],
  [Roles.MONEYLENDER_VENDOR]: [
    moneylenderPaths.vendorRegistry,
    moneylenderPaths.downloadVendorRegistry,
  ],
  [Roles.MONEYLENDER_BUYER_INCOME]: [
    moneylenderPaths.cessionOperations,
    moneylenderPaths.incomeOperations,
  ],
  [Roles.VENDOR]: [
    vendorPaths.home,
    vendorPaths.sign,
    vendorPaths.archive,
    vendorPaths.generateReport,
    vendorPaths.inquiry,
  ],
  [Roles.VENDOR_ADMIN]: [
    vendorPaths.users,
    vendorPaths.inviteNewCustomer,
    vendorPaths.delegatedManagement,
    vendorPaths.pendingCustomer,
  ],
  [Roles.VENDOR_SALE_OF_CREDIT]: [
    vendorPaths.active,
    vendorPaths.pending,
    vendorPaths.sell,
    vendorPaths.marketplace,
    vendorPaths.invoice,
    vendorPaths.incomeOperations,
  ],
};

export const subMenuItemsUsersBuyer = [
  { id: 4, name: "navbar.buyer.subMenuUsers.menu4", to: commonPaths.swapUser },
  {
    id: 1,
    name: "navbar.buyer.subMenuUsers.menu1",
    to: buyerPaths.pendingCustomer,
  },
  {
    id: 2,
    name: "navbar.buyer.subMenuUsers.menu2",
    to: buyerPaths.delegatedManagement,
  },
  {
    id: 6,
    name: "navbar.admin.subMenuUsers.menu6",
    to: commonPaths.documentsManagement,
    showExpiryDocumentsNotification: true,
  },
];

export const subMenuItemsOperationsBuyer = [
  {
    id: 1,
    name: "navbar.buyer.subMenuOperations.menu1",
    to: buyerPaths.cession,
    permit: [Roles.BUYER_NO_SAP],
  },
  // ,
  // {
  //   id:2,
  //   name:'navbar.buyer.subMenuOperations.menu2',
  //   to: buyerPaths.moneylenderInfo,
  //   permit: [Roles.BUYER_NO_SAP],
  // }
];
export const subMenuItemsInvoiceBuyer = [
  {
    id: 1,
    name: "navbar.buyer.subMenuInvoice.menu1",
    to: buyerPaths.activeInvoice,
  },
  {
    id: 2,
    name: "navbar.buyer.subMenuInvoice.menu2",
    to: buyerPaths.newInvoice,
    permit: [Roles.BUYER_NO_SAP],
  },
  {
    id: 3,
    name: "navbar.buyer.subMenuInvoice.menu3",
    to: buyerPaths.editInvoice,
    permit: [Roles.BUYER_NO_SAP],
  },
];

export const subMenuItemsRegistryBuyer = [
  {
    id: 1,
    name: "navbar.buyer.subMenuRegistry.menu1",
    to: buyerPaths.moneylenderRegistry,
    permit: [Roles.BUYER_NO_SAP],
  },
  {
    id: 2,
    name: "navbar.buyer.subMenuRegistry.menu2",
    to: buyerPaths.vendorRegistry,
    permit: [Roles.BUYER_NO_SAP],
  },
];

if (!configService.isDemo())
  subMenuItemsUsersBuyer.push({
    id: 3,
    name: "navbar.buyer.subMenuUsers.menu3",
    to: buyerPaths.inviteNewCustomer,
  });

export const menuItemsBuyer = [
  {
    id: 1,
    name: "navbar.buyer.menu1",
    to: buyerPaths.program,
    permit: [Roles.BUYER_PROGRAM],
  },
  {
    id: 2,
    name: "navbar.buyer.menu4",
    to: buyerPaths.credit,
    permit: [Roles.BUYER_DYNAMIC_DISCOUNT],
  },
  {
    id: 3,
    name: "navbar.buyer.menu5",
    to: buyerPaths.treasury,
    permit: [Roles.BUYER_PAYMENT],
  },
  {
    id: 4,
    name: "navbar.buyer.menu7",
    to: buyerPaths.users,
    subMenus: subMenuItemsUsersBuyer,
    showExpiryDocumentsNotification: true,
    permit: [Roles.BUYER_ADMIN],
  },
  { id: 5, name: "navbar.buyer.menu2", to: buyerPaths.archive },
  { id: 6, name: "navbar.admin.menu5", to: buyerPaths.generateReport },
  {
    id: 7,
    name: "navbar.buyer.menu6",
    to: buyerPaths.sign,
    showActionsNotification: true,
  },
  {
    id: 8,
    name: "navbar.buyer.menu8",
    to: buyerPaths.invoice,
    subMenus: subMenuItemsInvoiceBuyer,
  },
  { id: 9, name: "navbar.buyer.menu9", to: buyerPaths.inquiry },
  {
    id: 10,
    name: "navbar.buyer.menu10",
    to: buyerPaths.registry,
    subMenus: subMenuItemsRegistryBuyer,
    permit: [Roles.BUYER_NO_SAP],
  },
  {
    id: 11,
    name: "navbar.buyer.menu11",
    to: buyerPaths.operations,
    subMenus: subMenuItemsOperationsBuyer,
    permit: [Roles.BUYER_NO_SAP],
  },
];

export const subMenuItemsVendor = [
  { id: 1, name: "navbar.supplier.subMenu.menu1", to: vendorPaths.sell },
  { id: 3, name: "navbar.supplier.subMenu.menu3", to: vendorPaths.pending },
];

export const subMenuUsersVendor = [
  { id: 4, name: "navbar.buyer.subMenuUsers.menu4", to: commonPaths.swapUser },
  {
    id: 2,
    name: "navbar.moneylender.submenuUserMoneylender.menu2",
    to: vendorPaths.delegatedManagement,
  },
  {
    id: 3,
    name: "navbar.buyer.subMenuUsers.menu1",
    to: vendorPaths.pendingCustomer,
  },
  {
    id: 6,
    name: "navbar.admin.subMenuUsers.menu6",
    to: commonPaths.documentsManagement,
    showExpiryDocumentsNotification: true,
  },
];

if (!configService.isDemo())
  subMenuUsersVendor.push({
    id: 1,
    name: "navbar.moneylender.submenuUserMoneylender.menu1",
    to: vendorPaths.inviteNewCustomer,    
  });

export const menuItemsVendor = [
  {
    id: 1,
    name: "navbar.supplier.menu1",
    to: vendorPaths.invoice,
    permit: [Roles.VENDOR_SALE_OF_CREDIT],
  },
  {
    id: 2,
    name: "navbar.supplier.menu2",
    to: vendorPaths.marketplace,
    subMenus: subMenuItemsVendor,
    permit: [Roles.VENDOR_SALE_OF_CREDIT],
  },
  {
    id: 3,
    name: "navbar.supplier.menu4",
    to: vendorPaths.users,
    subMenus: subMenuUsersVendor,
    permit: [Roles.VENDOR_ADMIN],
    showExpiryDocumentsNotification: true,
  },
  {
    id: 6,
    name: "navbar.moneylender.subMenuOperationsMoneylender.menu2",
    to: vendorPaths.incomeOperations,
  },
  { id: 4, name: "navbar.buyer.menu2", to: vendorPaths.archive },
  {
    id: 5,
    name: "navbar.buyer.menu6",
    to: vendorPaths.sign,
    showActionsNotification: true,
  },
  { id: 6, name: "navbar.admin.menu5", to: vendorPaths.generateReport },
  { id: 7, name: "navbar.buyer.menu9", to: vendorPaths.inquiry },
];

export const subMenuISellInvoicesMoneylender = [
  {
    id: 1,
    name: "navbar.moneylender.submenuMoneylender.menu1",
    to: moneylenderPaths.sell,
  },
  {
    id: 2,
    name: "navbar.moneylender.submenuMoneylender.menu2",
    to: moneylenderPaths.activeInvoices,
  },
  {
    id: 3,
    name: "navbar.moneylender.submenuMoneylender.menu3",
    to: moneylenderPaths.pending,
  },
];

export const subMenuUsersMoneylender: any = [
  { id: 4, name: "navbar.buyer.subMenuUsers.menu4", to: commonPaths.swapUser },
  {
    id: 2,
    name: "navbar.moneylender.submenuUserMoneylender.menu2",
    to: moneylenderPaths.delegatedManagement,
  },
  {
    id: 3,
    name: "navbar.buyer.subMenuUsers.menu1",
    to: moneylenderPaths.pendingCustomer,
  },
  {
    id: 6,
    name: "navbar.admin.subMenuUsers.menu6",
    to: commonPaths.documentsManagement,
    showExpiryDocumentsNotification: true,
  },
];

if (!configService.isDemo())
  subMenuUsersMoneylender.push({
    id: 1,
    name: "navbar.moneylender.submenuUserMoneylender.menu1",
    to: moneylenderPaths.inviteNewCustomer,
  });

export const subMenuVendorRegistryMoneylender = [
  {
    id: 1,
    name: "navbar.moneylender.subMenuVendorMoneylender.menu1",
    to: moneylenderPaths.vendorRegistry,
  },
  {
    id: 2,
    name: "navbar.moneylender.subMenuVendorMoneylender.menu2",
    to: moneylenderPaths.downloadVendorRegistry,
  },
];

export const subMenuOperationsMoneylender = [
  {
    id: 1,
    name: "navbar.moneylender.subMenuOperationsMoneylender.menu1",
    to: moneylenderPaths.cessionOperations,
  },
  {
    id: 2,
    name: "navbar.moneylender.subMenuOperationsMoneylender.menu2",
    to: moneylenderPaths.incomeOperations,
  },
];

export const menuItemsMoneylender: linkType[] = [
  {
    id: 1,
    name: "navbar.moneylender.menu1",
    to: moneylenderPaths.credit,
    permit: [Roles.MONEYLENDER_CREDIT_LINE],
  },
  {
    id: 2,
    name: "navbar.moneylender.menu2",
    to: moneylenderPaths.vendor,
    subMenus: subMenuVendorRegistryMoneylender,
    permit: [Roles.MONEYLENDER_VENDOR],
  },
  {
    id: 3,
    name: "navbar.moneylender.menu3",
    to: moneylenderPaths.marketplace,
    subMenus: subMenuISellInvoicesMoneylender,
    permit: [Roles.MONEYLENDER_SALE_OF_CREDIT],
  },
  {
    id: 4,
    name: "navbar.moneylender.menu4",
    to: moneylenderPaths.users,
    subMenus: subMenuUsersMoneylender,    
    permit: [Roles.MONEYLENDER_ADMIN],
    showExpiryDocumentsNotification: true,
  },
  {
    id: 5,
    name: "navbar.moneylender.menu5",
    to: "",
    subMenus: subMenuOperationsMoneylender,
    permit: [Roles.MONEYLENDER_BUYER_INCOME],
  },
  { id: 6, name: "navbar.archive", to: moneylenderPaths.archive },
  {
    id: 7,
    name: "navbar.signatures",
    to: moneylenderPaths.sign,
    showActionsNotification: true,
  },
  { id: 8, name: "navbar.admin.menu5", to: moneylenderPaths.generateReport },
  { id: 9, name: "navbar.buyer.menu9", to: moneylenderPaths.inquiry },
];

export const subMenuItemsUsersAdmin = [
  { id: 1, name: "navbar.admin.subMenuUsers.menu1", to: adminPaths.newUser },
  {
    id: 2,
    name: "navbar.admin.subMenuUsers.menu2",
    to: adminPaths.modifyBlockUser,
  },
  {
    id: 3,
    name: "navbar.admin.subMenuUsers.menu3",
    to: adminPaths.onBoardingStatus,
  },
  {
    id: 4,
    name: "navbar.admin.subMenuUsers.menu4",
    to: adminPaths.pendingRequest,
  },
  {
    id: 5,
    name: "navbar.admin.subMenuUsers.menu5",
    to: adminPaths.companyInfo,
  },
  {
    id: 6,
    name: "navbar.admin.subMenuUsers.menu6",
    to: commonPaths.documentsManagement,
  },
];

export const subMenuArchiveAdmin = [
  { id: 1, name: "navbar.BUYER", to: adminPaths.archiveBuyer },
  { id: 2, name: "navbar.MONEYLENDER", to: adminPaths.archiveMoneylender },
  { id: 3, name: "navbar.VENDOR", to: adminPaths.archiveVendor },
];

export const subMenuEsgAdmin = [
  { id: 1, name: "navbar.admin.menu8", to: adminPaths.ratingESG },
  { id: 2, name: "navbar.admin.menu9", to: adminPaths.ESGCertificateList },
];


export const menuItemsAdmin = [
  {
    id: 1,
    name: "navbar.admin.menu1",
    to: adminPaths.users,
    subMenus: subMenuItemsUsersAdmin,
  },
  {
    id: 2,
    name: "navbar.admin.subMenuCustomers.menu1",
    to: adminPaths.customers,
  },
  {
    id: 3,
    name: "navbar.buyer.menu2",
    to: adminPaths.archive,
    subMenus: subMenuArchiveAdmin,
    permit: [Roles.ADMIN],
  },
  {
    id: 4,
    name: "navbar.admin.menu3",
    to: commonPaths.contactUs,
    showTicketNotification: true,
  },
  { id: 5, name: "navbar.admin.menu4", to: adminPaths.sap },
  { id: 6, name: "navbar.admin.menu5", to: adminPaths.generateReport },
  { id: 6, name: "navbar.admin.menu6", to: adminPaths.invoice },
  { id: 7, name: "navbar.buyer.menu9", to: adminPaths.inquiry },
  {
    id: 8,
    name: "navbar.admin.menu7",
    to: adminPaths.ratingESG,
    subMenus: subMenuEsgAdmin,
  },
];

export const onBoardingMenu = [
  { id: 1, name: "navbar.onBoarding", to: commonPaths.onBoarding },
];

interface Props {
  menuItems: { id: number; name: string; to: string; subMenus?: any }[];
  userRole: Roles;
  setConfigurationPricesStep(dispatch: string): void;
  clearInviteCustomerResponse(dispatch: string): void;
  setSellInvoicesStep(dispatch: SellInvoicesStep): void;
  setAdminActionDispatcher(step: string): void;
  setUserStep: any;
  roleList: Roles[];
  actionsPresented: boolean;
  ticketPresented: boolean;
  [index: string]: any;
  companyRoles?: CompanyRole[];
  setNewVendorStep(dispatch: BuyerNewCompanySteps): void;
}

interface linkType {
  id: number;
  name: string;
  to: string;
  subMenus?: any;
  showActionsNotification?: any;
  showTicketNotification?: any;
  showExpiryDocumentsNotification?: any;
  onClick?: any;
  permit?: any;
}

const NavBar: React.FC<Props> = ({
                                   menuItems,
                                   userRole,
                                   setConfigurationPricesStep,
                                   setUserStep,
                                   setSellInvoicesStep,
                                   clearInviteCustomerResponse,
                                   actionsPresented,
                                   ticketPresented,
                                   setAdminActionDispatcher,
                                   userData,
                                   companyRoles,
                                   switchUser,
                                   getPrograms,
                                     setNewVendorStep,
                                     countExpiringUserDocuments,
                                     countExpiringEsgCertificates,
                                 }) => {
  const [navExpanded, setNavExpanded] = React.useState(false);
  const [rolesModalVisible, setRolesModalVisible] = React.useState(false);
  const [dropdownClicked, setDropdownClicked] = React.useState(-1);
  const [menuItemClicked, setMenuItemClicked] = React.useState(-1);

  // TODO: review the necessity of the additional setConfigurationPricesStep and setSellInvoicesStep
  const clickMenuItem = (link: linkType) => {
    const { id, onClick } = link;
    userRole === Roles.MONEYLENDER &&
      id === 1 &&
      setConfigurationPricesStep("");
    userRole === Roles.BUYER && id === 1 && getPrograms();
    onClick?.(link);
    setMenuItemClicked(id);
    setNavExpanded(false);
  };

  const clickDropdown = ({ id }: linkType) => {
    clearInviteCustomerResponse("");
    setDropdownClicked(id);
  };

  const { t } = useTranslation();
  const history = useHistory();
  const roleDisplay =
    (i18n.exists("navbar." + userRole) && t("navbar." + userRole)) || "";

  const activeCompany = companyRoles?.find(({ active }) => active);
  const renderMenuItems = (links: linkType[], t: Function): ReactNode => {
    return (
      <>
        {links.map((link, id) => {
          let counter = 0;
          if (link.showExpiryDocumentsNotification) {
            counter = countExpiringEsgCertificates + countExpiringUserDocuments;
          }return link.subMenus ? (
            <NavDropdown
              key={id}
              title={
                <div
                  className={`containerNav__containerTriangle ${
                    menuItemClicked === link.id &&
                    "containerNav__containerTriangle--clicked"
                  }`}
                >
                  {t(link.name)}
                  {counter > 0 && (
                    <span
                      className={`containerNav__subMenuBubble ${
                        menuItemClicked === link.id &&
                        "containerNav__subMenuBubble--inverted"
                      }`}
                    >
                      {counter}
                    </span>
                  )}<div className="containerNav__containerTriangleAndItem">
                    <div
                      className={`containerNav__triangle ${
                        menuItemClicked === link.id &&
                        "containerNav__triangle--clicked"
                      }`}
                    />
                  </div>
                </div>
              }
              id=""
              className={`containerNav__li ${
                menuItemClicked === link.id ? "nav-item--active" : ""
              } `}
              active
            >
              {link.subMenus.map(
                (subMenu: {
                  id: number;
                  name: string;
                  to: string;
                  subMenus?: any;
                showExpiryDocumentsNotification?: any;}) => {
                  const active =
                    menuItemClicked === link.id &&
                    dropdownClicked === subMenu.id;let subCounter = 0;
                  if (subMenu.showExpiryDocumentsNotification) {
                    subCounter =
                      countExpiringEsgCertificates + countExpiringUserDocuments;
                  }
                  return (
                    <NavDropdown.Item
                      onClick={() => {
                        if (userRole === Roles.ADMIN) {
                          if (subMenu.to === adminPaths.newUser)
                            setUserStep(UsersSteps.NEW_USER);
                          if (subMenu.to === adminPaths.modifyBlockUser)
                            setUserStep(UsersSteps.MODIFY_USER_TYPE);
                        }
                        if (userRole === Roles.MONEYLENDER) {
                          if (subMenu.to === moneylenderPaths.inviteNewCustomer)
                            setConfigurationPricesStep("");
                        }
                        if (userRole === Roles.VENDOR) {
                          if (subMenu.to === vendorPaths.sell)
                            setSellInvoicesStep(SellInvoicesStep.CHOOSE_BUYER);
                        }
                        if (userRole === Roles.MONEYLENDER) {
                          if (subMenu.to === moneylenderPaths.sell)
                            setSellInvoicesStep(SellInvoicesStep.CHOOSE_BUYER);
                        }
                        if (userRole === Roles.ADMIN) {
                          if (
                            subMenu.to === adminPaths.archiveBuyer ||
                            subMenu.to === adminPaths.archiveMoneylender ||
                            subMenu.to === adminPaths.archiveVendor
                          ) {
                            setAdminActionDispatcher(
                              admin_archive_step.ARCHIVE
                            );
                          }
                        }
                        if (userRole === Roles.BUYER) {
                          if (subMenu.to === buyerPaths.vendorRegistry) {
                            setNewVendorStep(BuyerNewCompanySteps.COMPANY_LIST);
                          }
                        }
                        clickMenuItem(link);
                        clickDropdown(subMenu);
                        setNavExpanded(false);
                        history.push(subMenu.to);
                      }}
                      style={{ width: "100%" }}
                      key={subMenu.id}
                      className={`containerNav__subMenu ${
                        active ? "active" : ""
                      }`}
                    >
                      <div> {t(subMenu.name)}{subCounter > 0 && (
                          <span
                            className={`containerNav__subMenuBubble ${
                              menuItemClicked === subMenu.id &&
                              "containerNav__subMenuBubble--inverted"
                            }`}
                          >
                            {subCounter}
                          </span>
                        )}</div>
                    </NavDropdown.Item>
                  );
                }
              )}
            </NavDropdown>
          ) : (
            <NavLink
              onClick={() => clickMenuItem(link)}
              key={id}
              className={`containerNav__${link.id === 0 ? "logo" : "li "} `}
              activeClassName={`${
                menuItemClicked === link.id && "containerNav__li--activeItem"
              } `}
              to={link.to}
              exact={true}
            >
              {t(link.name)}
              {link.showActionsNotification && !!actionsPresented && (
                <span
                  className={`containerNav__subMenuBubble ${
                    menuItemClicked === link.id &&
                    "containerNav__subMenuBubble--inverted"
                  }`}
                >
                  {actionsPresented}
                </span>
              )}
              {link.showTicketNotification && !!ticketPresented && (
                <span
                  className={`containerNav__subMenuBubble ${
                    menuItemClicked === link.id &&
                    "containerNav__subMenuBubble--inverted"
                  }`}
                >
                  {ticketPresented}
                </span>
              )}
            </NavLink>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div className="containerNav">
        <div className="pageContainer__content">
          <Navbar
            expanded={navExpanded}
            collapseOnSelect={true}
            bg="light"
            expand="xl"
            className="containerNav__navbar d-flex justify-content-start bg-white"
          >
            <div
              className="containerNav__logo"
              onClick={() =>
                history.push(roleUtils.getRedirectToHome(userRole))
              }
            >
              <span>
                <img
                  style={{ verticalAlign: "inherit", cursor: "pointer" }}
                  src={Polaris_logo}
                  alt="logo"
                  width={"100px"}
                />
              </span>
            </div>
            <ProfilePic className="containerNav__boxOnlyTablet" />
            <Navbar.Toggle
              onClick={() => setNavExpanded(!navExpanded)}
              aria-controls="basic-navbar-nav"
            >
              <div className="burger">
                <span />
              </div>
            </Navbar.Toggle>
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="containerNav__collapse justify-content-end"
            >
              <Nav className="containerNav__nav">
                {renderMenuItems(menuItems, t)}
                <ProfilePic className="containerNav__boxOnlyDesktop" />
              </Nav>
            </Navbar.Collapse>
            <div style={{ width: "100%", height: "44px" }}>
              <div
                onClick={() => setRolesModalVisible(!rolesModalVisible)}
                className="personalInfo__title"
              >
                <div className="personalInfo__name">
                  {(userData.name || "") + " " + (userData.surname || "")} (
                  {roleDisplay})
                </div>
                <div className="personalInfo__type">{activeCompany?.label}</div>
              </div>
            </div>
          </Navbar>
        </div>
      </div>
      <Modal
        onHide={() => setRolesModalVisible(false)}
        show={rolesModalVisible}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("navbar.selectRole")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="containerNav__modalRoles">
            {companyRoles?.map((item, index) => (
              <div
                key={index}
                style={{ paddingRight: "20px", paddingLeft: "20px" }}
              >
                <NewUserCard
                  styleCard={`shadowCard`}
                  body={
                    <>
                      <div className={`newUserCard__title`}>
                        {i18n.exists("navbar." + item.role)
                          ? t("navbar." + item.role).toUpperCase()
                          : ""}
                      </div>
                      <div className={`newUserCard__company`}>{item.label}</div>
                    </>
                  }
                  iconElement={individualIcon(item.role)}
                  onClick={async () => {
                    await switchUser(item);
                    setRolesModalVisible(false);
                    if (
                      history.location.pathname ===
                      roleUtils.getRedirectToHome(item.role)
                    ) {
                      window.location.reload();
                    } else {
                      history.push(roleUtils.getRedirectToHome(item.role));
                    }
                  }}
                  selected={item.active}
                />
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (store: AppState) => ({
  userRole: store.auth.role,
  roleList: store.auth.roles,
  actionsPresented: store.infoCert.actionsPresented,
  ticketPresented: store.common.ticketPresented,
  userData: store.auth.onBoardingData,
  companyRoles: store.auth.companyRoles,
  countExpiringUserDocuments: store.company.expiringUserDocuments.length,
  countExpiringEsgCertificates: store.company.expiringEsgCertificates.length,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    setUserStep: (payload: any) => dispatch(setNewUserStep(payload)),
    setConfigurationPricesStep: (step: string) =>
      dispatch(setConfigurationPricesStep(step)),
    setSellInvoicesStep: (step: SellInvoicesStep) =>
      dispatch(setSellInvoicesStep(step)),
    clearInviteCustomerResponse: (payload: string) =>
      dispatch(clearInviteCustomerResponse(payload)),
    setAdminActionDispatcher: (step: string) =>
      dispatch(setAdminActionDispatcher(step)),
    switchUser: (data: CompanyRoleData) => dispatch(switchUser(data)),
    getPrograms: () => dispatch(getPrograms()),
    setNewVendorStep: (payload: any) =>
      dispatch(setBuyerNewCompanyStep(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
