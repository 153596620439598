import {toastUtils} from "../utils/toastUtils";
import i18n from "../i18n";
import {LOGIN_FAIL, LOGOUT, SET_EXPIRATION_SESSION} from "./auth/auth-reducers";
import {MyResponse} from "../model/ServiceDTO";

const timeout = (milliseconds: number) => {
    return new Promise(function (resolve) {
        setTimeout(function () {
            resolve(true);
        }, milliseconds);
    });
};

export const handleResponse = async (dispatch: any,
                                     successAction: string | null,
                                     failAction: string,
                                     responsePromise: Promise<MyResponse<any>>,
                                     errorExtractor?: (obj: any) => string,
                                     onLogout?: () => any,
                                     onFail?: () => any,
                                     successDelay?: number,
                                     noErrorToast?: boolean)
    : Promise<any> => {
    try {
        //EXPIRATION SESSION 1hr
        dispatch({type: SET_EXPIRATION_SESSION, payload: new Date().getTime() + (1000 * 60 * 60)});
        const response = await responsePromise || {};
        const {status, payload} = response;
        if (status && status >= 300) {
            let errorMessage;
            errorMessage = payload && errorExtractor?.(payload);
            errorMessage = errorMessage || (payload?.errorMessage);
            errorMessage = errorMessage || (payload?.errors?.errorMessage || payload?.errors);
            errorMessage = errorMessage || (payload?.message);
            errorMessage = errorMessage || (payload?.errors?.username);
            if (status === 401) {
                if (onLogout) {
                    onLogout();
                } else {
                    errorMessage = errorMessage || "errors.session";
                    dispatch({type: LOGOUT, payload: errorMessage as string});
                    if (failAction !== LOGIN_FAIL)
                        dispatch({type: failAction, payload: ""});
                    else dispatch({type: failAction, payload: errorMessage as string});
                }
            } else {
                const actionErrorKey = `notifications.${failAction}`;
                const messageErrorKey = `notifications.${errorMessage}`;
                i18n.exists(actionErrorKey) && toastUtils.errorToast(actionErrorKey);
                i18n.exists(messageErrorKey) && toastUtils.errorToast(messageErrorKey);
                dispatch({type: failAction, payload: errorMessage as string});
                onFail && onFail();
            }
            return false;
        } else {
            //success
            const successKey = `notifications.${successAction}`;
            i18n.exists(successKey) && toastUtils.successToast(successKey);
            if (successDelay) {
                await timeout(successDelay);
            }
            successAction && dispatch({type: successAction, payload});
            return true;
        }
    } catch (errorMessage) {
        !noErrorToast && toastUtils.errorToast(errorMessage);
        dispatch({type: failAction, payload: errorMessage.toString() as string});
        onFail && onFail();
        return false;
    }
};

export const errorExtractor = (payload: any): string => {
    if (payload.errors) {
        const errors = Object.values(payload.errors);
        return errors[0] as string;
    } else return "";
};
