import * as React from "react";
import ScreenWrapper from "../../../components/AdminComponent/ScreenWrapper/ScreenWrapper";
import { useTranslation } from "react-i18next";
import Table, { TableHeader } from "../../../components/Common/Table/Table";
import "../../../components/Common/Table/Table.scss";
import { ModifyUserDTO } from "../../../model/AdminDTO";
import NoDataBox from "../../../components/Common/NoDataBox/NoDataBox";
import "./UserList.scss";
import Ellapsis from "../../../components/Common/Ellapsis/Ellapsis";
import Button, { BUTTON_TYPE } from "../../../components/Common/Button/Button";
import forward_arrow_right from "../../../assets/Icons/forward_arrow_right.svg";
import { AppState } from "../../../redux/store";
import { connect } from "react-redux";

const styleHeaderAndRow = {
  action: { maxWidth: "1em", fontSize: "20px" },
  email: {},
  role: { width: "9em", flex: "none" },
  companyName: {},
  companyId: { width: "11em", flex: "none" },
  companyCountry: { width: "8em", flex: "none" },
  btn: { width: "150px", flex: "none" },
};

const UserList: React.FC<any> = ({
  title,
  description,
  error,
  users,
  btnRender,
  onPrevious,
  expiringUserDocuments,
}) => {
  const { t } = useTranslation();

  const headers: TableHeader[] = [
    {
      style: styleHeaderAndRow.action,
    },
    {
      label: t("admin.blockUser.email"),
      sortingAccessor: (a: ModifyUserDTO) => a.userId,
      isDefault: true,
      style: styleHeaderAndRow.email,
      isPrimary: true,
    },
    {
      label: t("admin.blockUser.role"),
      sortingAccessor: (a: any) => a.role,
      style: styleHeaderAndRow.role,
    },
    {
      label: t("admin.blockUser.company"),
      sortingAccessor: (a: ModifyUserDTO) => a.companyName,
      style: styleHeaderAndRow.companyName,
    },
    {
      label: t("admin.blockUser.companyId"),
      sortingAccessor: (a: ModifyUserDTO) => a.companyId,
      style: styleHeaderAndRow.companyId,
    },
    {
      label: t("admin.blockUser.country"),
      sortingAccessor: (a: ModifyUserDTO) => a.companyCountry,
      style: styleHeaderAndRow.companyCountry,
    },
    {
      label: "",
      style: styleHeaderAndRow.btn,
    },
  ];

  const tableRow = (item: any, index: number) => {
    console.log("item", item);
    const { role, companyName, companyId, companyCountry, email } = item;
    return (
      <div key={index}>
        <div className="blockUser__tableRow">
          <span
            className="table__item action_dot"
            style={styleHeaderAndRow.action}
          >
            {expiringUserDocuments.includes(item.email) && "•"}
          </span>
          <span className="table__item" style={styleHeaderAndRow.email}>
            <Ellapsis title={email}>{email}</Ellapsis>
          </span>
          <span className="table__item" style={styleHeaderAndRow.role}>
            <Ellapsis title={role}>{role}</Ellapsis>
          </span>
          <span className="table__item" style={styleHeaderAndRow.companyName}>
            <Ellapsis title={companyName}>{companyName}</Ellapsis>
          </span>
          <span className="table__item" style={styleHeaderAndRow.companyId}>
            <Ellapsis title={companyId}>{companyId}</Ellapsis>
          </span>
          <span
            className="table__item"
            style={styleHeaderAndRow.companyCountry}
          >
            <Ellapsis title={companyCountry}>{companyCountry}</Ellapsis>
          </span>
          <span
            className="table__item"
            style={{ justifyContent: "center", ...styleHeaderAndRow.btn }}
          >
            {btnRender?.(item)}
          </span>
        </div>
        <div
          className="table__line table__line--withMargin"
          style={{ opacity: 0.2 }}
        />
      </div>
    );
  };

  return (
    <ScreenWrapper
      title={title || t("admin.blockUser.individualTitle")}
      description={description || t("admin.blockUser.individualDescription")}
    >
      <Table
        searchBarStyle={{ display: "flex", flex: 1 }}
        noItemsRender={
          users?.length === 0 && (
            <NoDataBox noDataTitle={t("admin.blockUser.noUsers")} />
          )
        }
        searchableListToDisplay={users}
        accessorId={(item: ModifyUserDTO) => item.userId}
        defaultSortingFn={(a: ModifyUserDTO, b: ModifyUserDTO) =>
          a.userId > b.userId ? 1 : -1
        }
        renderItem={tableRow}
        headers={headers}
        className="shadowCard"
        error={error}
        itemForPage={15}
      />
      <div className="containerButtons">
        <>
          <div className="containerButtonConfirm containerButtonConfirm--marginRight20">
            <Button
              label={t("onBoarding.previous")}
              onClick={onPrevious}
              icon={forward_arrow_right}
              buttonType={BUTTON_TYPE.primaryInverted}
              iconOnTheLeft
            />
          </div>
        </>
      </div>
    </ScreenWrapper>
  );
};

const mapStateToProps = (store: AppState) => ({
  expiringUserDocuments: store.company.expiringUserDocuments,
});

export default connect(mapStateToProps)(UserList);
