import * as React from 'react';
import ScreenWrapper from "../../../components/AdminComponent/ScreenWrapper/ScreenWrapper";
import {useTranslation} from "react-i18next";
import "../../../components/Common/Table/Table.scss";
import SummaryData from "../../../components/SummaryComponent/SummaryData";
import "./UserSummary.scss";
import {AppState} from "../../../redux/store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect} from "react-redux";
import {UploadUserFiles} from "../../onBoarding/company/addUserData/AddUserData";
import {Roles, UserFilesDTORequest} from "../../../model/UserDTO";
import Button, {BUTTON_TYPE} from "../../../components/Common/Button/Button";
import forward_arrow_right from "../../../assets/Icons/forward_arrow_right.svg";
import {isEmpty} from "../../../utils/commonUtils";

export interface Props {
    data: any,
    title?: any
    description?: any
    [index: string]: any
}

const UserSummary: React.FC<Props> = ({
                                          title,
                                          description,
                                          onPrevious,
                                          onConfirm,
                                          data,
                                          userId,
                                          userEmail,
                                          userRole,
                                          roleList,
                                          additionalFields,
                                          formNotValid,
                                          error
                                      }) => {

    const {t} = useTranslation();
    const [errorLocal, setErrorLocal] = React.useState("");
    const [userFilesToSend, setUserFilesToSend] = React.useState<UserFilesDTORequest>({});

    const hasValues = (data: any) => data && Object.entries(data).find(element => !!element[1]);
    const isAdminUser = userRole === Roles.ADMIN
        || roleList.indexOf(Roles.MONEYLENDER_ADMIN) > 0
        || roleList.indexOf(Roles.BUYER_ADMIN) > 0
        || roleList.indexOf(Roles.VENDOR_ADMIN) > 0;

    const onConfirmButtonClick = async () => {
        const success = await onConfirm?.({userId, userEmail, userFiles: userFilesToSend});
        if (success) setUserFilesToSend({});
    };

    const errorDisplay = error || errorLocal;

    return (
        <ScreenWrapper
            title={title || t("admin.userInfo.title")}
            description={description || t("admin.userInfo.description")}
        >

            <div className="shadowCard " style={{paddingBottom: "20px", marginBottom: "30px"}}>
                <div className="cardContainerWithButtons__contentData">
                    {hasValues(data.userData) &&
                    <>
                        <div className="summaryData__subTitle">
                            {t("onBoarding.personalData")}
                        </div>
                        <SummaryData userDataToDisplay={data.userData}/>
                    </>
                    }
                    {additionalFields}
                    <div className="userCompanySummary__filesContainer">
                        <UploadUserFiles
                            files={data.userFiles}
                            setError={setErrorLocal}
                            onFileChange={setUserFilesToSend}
                        />
                    </div>
                </div>
            </div>
            <div className="cardContainerWithButtons__content">
                <div className="containerButtons">
                    <>
                        <div className="userCompanySummary__error">
                            {typeof errorDisplay === "object" && !isEmpty(errorDisplay) ? Object.keys(errorDisplay).map(key => (t(errorDisplay[key]) + "; ")) : t(errorDisplay)}
                        </div>

                        {isAdminUser &&
                        <div
                            className="containerButtonConfirm containerButtonConfirm--marginRight20"
                        >
                            <Button
                                label={t("onBoarding.previous")}
                                onClick={onPrevious}
                                icon={forward_arrow_right}
                                buttonType={BUTTON_TYPE.primaryInverted}
                                iconOnTheLeft
                            />
                        </div>}
                        <div className="containerButtonConfirm">
                            <Button
                                label={t("onBoarding.confirmData")}
                                onClick={onConfirmButtonClick}
                                icon={forward_arrow_right}
                                disabled={!userFilesToSend || isEmpty(userFilesToSend) || formNotValid}
                            />
                        </div>
                    </>
                </div>
            </div>
        </ScreenWrapper>

    );
};
const mapStateToProps = (store: AppState) => {
    return {
        userEmail: store.auth.onBoardingData.userId,
        userRole: store.auth.role,
        roleList: store.auth.roles,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSummary);


