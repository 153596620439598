import { buyerService } from '../../service/BuyerService';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  GET_ALIGNMENT_PARAMETER,
  GET_ALIGNMENT_PARAMETER_FAIL,
  GET_ALIGNMENT_PARAMETER_SUCCESS,
  GET_BASE_RATE,
  GET_BASE_RATE_FAIL,
  GET_BASE_RATE_SUCCESS,
  GET_CURRENCY_ENABLED,
  GET_CURRENCY_ENABLED_FAIL,
  GET_CURRENCY_ENABLED_SUCCESS,
  GET_PROGRAMS,
  GET_PROGRAMS_AVAILABLE,
  GET_PROGRAMS_AVAILABLE_FAIL,
  GET_PROGRAMS_AVAILABLE_SUCCESS,
  GET_PROGRAMS_FAIL,
  GET_PROGRAMS_SUCCESS,
  SET_PROGRAM_ERROR,
  GET_PROGRAM_TYPES,
  GET_PROGRAM_TYPES_SUCCESS,
  GET_PROGRAM_TYPES_FAIL,
} from './program-reducers';
import { ProgramAction } from './program-interface';
import { handleResponse } from '../reduxUtils';

export const getPrograms =
  (vatNumber?: string): ThunkAction<void, {}, {}, ProgramAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, ProgramAction>
  ): Promise<ProgramAction> => {
    dispatch({ type: GET_PROGRAMS, payload: null });
    const responsePromise = buyerService.getPrograms(vatNumber);
    return await handleResponse(
      dispatch,
      GET_PROGRAMS_SUCCESS,
      GET_PROGRAMS_FAIL,
      responsePromise
    );
  };

export const getProgramsAvailable =
  (
    currency?: string,
    vatNumber?: string
  ): ThunkAction<void, {}, {}, ProgramAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, ProgramAction>
  ): Promise<ProgramAction> => {
    dispatch({ type: GET_PROGRAMS_AVAILABLE, payload: null });
    const responsePromise = buyerService.getProgramsAvailable(
      currency,
      vatNumber
    );
    return await handleResponse(
      dispatch,
      GET_PROGRAMS_AVAILABLE_SUCCESS,
      GET_PROGRAMS_AVAILABLE_FAIL,
      responsePromise
    );
  };

export const getAlignmentParameter =
  (): ThunkAction<void, {}, {}, ProgramAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, ProgramAction>
  ): Promise<ProgramAction> => {
    dispatch({ type: GET_ALIGNMENT_PARAMETER, payload: null });
    const responsePromise = buyerService.getAlignmentParameter();
    return await handleResponse(
      dispatch,
      GET_ALIGNMENT_PARAMETER_SUCCESS,
      GET_ALIGNMENT_PARAMETER_FAIL,
      responsePromise
    );
  };

export const getBaseRate =
  (): ThunkAction<void, {}, {}, ProgramAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, ProgramAction>
  ): Promise<ProgramAction> => {
    dispatch({ type: GET_BASE_RATE, payload: null });
    const responsePromise = buyerService.getBaseRate();
    return await handleResponse(
      dispatch,
      GET_BASE_RATE_SUCCESS,
      GET_BASE_RATE_FAIL,
      responsePromise
    );
  };

export const getProgramTypes =
  (): ThunkAction<void, {}, {}, ProgramAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, ProgramAction>
  ): Promise<ProgramAction> => {
    dispatch({ type: GET_PROGRAM_TYPES, payload: null });
    const responsePromise = buyerService.getProgramTypes();
    return await handleResponse(
      dispatch,
      GET_PROGRAM_TYPES_SUCCESS,
      GET_PROGRAM_TYPES_FAIL,
      responsePromise
    );
  };

export const getCurrencyEnabled =
  (): ThunkAction<void, {}, {}, ProgramAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, ProgramAction>
  ): Promise<ProgramAction> => {
    dispatch({ type: GET_CURRENCY_ENABLED, payload: null });
    const responsePromise = buyerService.getEnabledCurrency();
    return await handleResponse(
      dispatch,
      GET_CURRENCY_ENABLED_SUCCESS,
      GET_CURRENCY_ENABLED_FAIL,
      responsePromise
    );
  };

export const setError = (payload: string) => {
  return { type: SET_PROGRAM_ERROR, payload };
};
