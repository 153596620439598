import * as React from 'react';
import "./ScrollableTable.scss";
import CheckBox from "../CheckBox/CheckBox";

interface Props {
    items: Array<any>,
    description?: string,
    tableLabels?: Array<string>,
    tableCustomStyle?: Array<string>,
    emptyMessage?: string,
    onCheckboxClick?(element: any): void
}

const ScrollableTable: React.FC<Props> = ({
                                              description,
                                              tableLabels,
                                              tableCustomStyle,
                                              items,
                                              emptyMessage,
                                              onCheckboxClick,
                                          }) => {
    return (
        <div className="customScrollableTable">
            {description &&
            <div className="customScrollableTable__description">
                {description}
            </div>
            }
            <div className="customScrollableTable__container">
                <div className="customScrollableTable__rowContainer">
                    {tableLabels && tableLabels.length !== 0 &&
                    <div className="customScrollableTable__rowTitle">
                        <div>
                            {onCheckboxClick&&  <div className="customScrollableTable__emptyRow"/>}
                        </div>
                        {tableLabels.map((title: string, key: number) => {
                            return (
                                <div
                                    key={key + title}
                                    className={`customScrollableTable__title ${tableCustomStyle ? tableCustomStyle[key] : ""}`}
                                >
                                    {title}
                                </div>
                            );
                        })}
                    </div>
                    }
                    {items && items.length === 0 ?
                        <div className="customScrollableTable__emptyMessage">
                            {emptyMessage}
                        </div>
                        :
                        items && items.map((item, key: number) => {
                            return (
                                <div
                                    key={key}
                                    onClick={() => onCheckboxClick && onCheckboxClick(item)}
                                    style={{cursor: "pointer"}}
                                    className="customScrollableTable__row"
                                >
                                    <div className="customScrollableTable__checkboxCell">
                                        {onCheckboxClick && <CheckBox checked={item.selected}/>}
                                    </div>
                                    {Object.keys(item).filter((keys: string) => keys !== "selected" && keys !== "id").map((key: string, index: number) => (
                                        <div
                                            key={index}
                                            className={`customScrollableTable__cell ${tableCustomStyle ? tableCustomStyle[index] : ""}`}
                                        >
                                            {item[key]}
                                        </div>
                                    ))}
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default ScrollableTable;
