import * as React from 'react';
import {toastUtils} from "../../../utils/toastUtils";
import {useTranslation} from "react-i18next";

const copyToClipboard = (title: string | undefined | null, t: any) => {
    if (title) {
        const textField = document.createElement('textarea');
        textField.innerText = title;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        toastUtils.copyToast("", 0, title + t("notifications.copySuccess"));
    }
};

const Ellapsis: React.FC<{ title?: string | null }> = ({
                                                                       children,
                                                                       title
                                                                   }) => {
    const {t} = useTranslation();
    const style: any = {whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"};
    if (title) style.cursor = "copy";
    return (
        <div
            title={title || ""}
            style={style}
            onClick={() => copyToClipboard(title, t)}
        >
            {children || title}
        </div>
    );
};

export default Ellapsis;
