import * as React from 'react';
import {ReactNode, useCallback} from 'react';
import "./TextInput.scss";
import {useTranslation} from "react-i18next";

export enum TextInputType {
    ALL_CHARACTER,
    TEXT_AND_DIGITS,
    PHONE_NUMBER,
    ONLY_TEXT,
    ITALIAN_ZIP_CODE
}


const REGEX_CHECK = {
    [TextInputType.ALL_CHARACTER]: /[^]/g,
    [TextInputType.TEXT_AND_DIGITS]: /^[a-zA-Z0-9'?\s]+$/g,
    [TextInputType.PHONE_NUMBER]: /^[+]?[\d]{0,12}$/g,
    [TextInputType.ONLY_TEXT]: /^[a-zA-Z_ ]+$/,
    [TextInputType.ITALIAN_ZIP_CODE]: /^[\d]{5}$/,
};

interface Props {
    type?: string,
    value?: any,
    placeholder?: string,
    title?: string | ReactNode,
    symbol?: any,
    inputType?: TextInputType
    maxLength?: number
    editable?: boolean,
    disableInput?: boolean | null,
    className?: string,
    titleStyle?: any
    isTextArea?: boolean
    onChange?(value: any): void,
    onBlur?(event: any): any,
    onFocus?(event: any): any,
    onKeyDown?(event: any): any,
    reference?: (input: any) => any
    onFormatError?: (input: boolean | null) => void
    noAccentedLetters?: boolean //introduced to avoid accented letters to be sent to Infocert. Infocert then fails if such a letters presented.
    delayedOnChange?: boolean
}

const handleChange = ({
                          alive,
                          event,
                          inputType,
                          setDisplayValue,
                          onChange,
                          delayedOnChange,
                          onFormatError,
                          setFormatError
                      }: any) => {
    if (alive) {
        let text = event.target.value;
        const pattern = REGEX_CHECK[inputType as TextInputType];
        let textMatchesPattern = text.match(pattern);
        if (text === "" || textMatchesPattern || onFormatError) {
            setDisplayValue(text);
            if (delayedOnChange) {
                timerDelayedInput && clearTimeout(timerDelayedInput);
                timerDelayedInput = setTimeout(() => {
                    onChange?.(text?.trim());
                }, 400);
            } else onChange?.(text?.trim());
        }
        if (onFormatError) {
            const pattern = REGEX_CHECK[inputType as TextInputType];
            let textMatchesPattern = text?.match(pattern);
            onFormatError?.(!textMatchesPattern);
            setFormatError(!textMatchesPattern);
        }
    }
};

const handleOnFocus = ({
                           alive,
                           event,
                           setDisplayValue,
                           setFocused,
                           parentValue,
                           onFocus
                       }: any) => {
    if (alive) {
        setDisplayValue(parentValue);
        onFocus && onFocus(event);
    }
    setFocused(true);
};

const handleOnBlur = ({
                          alive,
                          event,
                          onChange,
                          setFocused,
                          onBlur,
                          noAccentedLetters
                      }: any) => {
    setFocused(false);
    let value = event?.target?.value;
    if (alive) {
        if (noAccentedLetters) value = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "'");
        onBlur?.(value);
        //outside we always save original value
        onChange?.(value);
    }
};
let timerDelayedInput: any;

const TextInput: React.FC<Props> = ({
                                         onBlur,
                                         editable = true,
                                         type = "text",
                                         symbol,
                                         value: parentValue,
                                         title,
                                         placeholder,
                                         inputType = TextInputType.ALL_CHARACTER,
                                         onChange,
                                         maxLength = 255,
                                         onFocus,
                                         onKeyDown,
                                         disableInput = false,
                                         titleStyle = "",
                                         reference,
                                         className,
                                         isTextArea = false,
                                         onFormatError,
                                         noAccentedLetters,
                                         delayedOnChange
                                     }) => {

    const {t} = useTranslation();
    const [editableValue, setEditableValue] = React.useState(!parentValue);
    const [displayValue, setDisplayValue] = React.useState<any>();
    const [formatError, setFormatError] = React.useState<boolean>();
    const [focused, setFocused] = React.useState<boolean>(false);

    React.useEffect(() => {
        //we align the displayValue with formatted parentValue otherwise with raw parentValue
        //we do it only when the input is not focused
        //because while it is focused we handle and show the numeric inputs as numbers
        //and not formatted strings
        if (!focused) {
            setDisplayValue(parentValue);
        }
        // eslint-disable-next-line
    }, [parentValue, displayValue, focused]);

    React.useEffect(() => {
        setDisplayValue(parentValue);
        parentValue?.length === 0 && setEditableValue(true);
        // eslint-disable-next-line
    }, [parentValue]);

    React.useEffect(() => {
        //clear timer garbage
        if (delayedOnChange) return () => clearTimeout(timerDelayedInput);
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        setEditableValue(editable);
        // eslint-disable-next-line
    }, [editable]);

    const alive = disableInput === false && editableValue === true;

    const onBlurCallback = useCallback(
        (event) => handleOnBlur({
            alive,
            inputType,
            onChange,
            setDisplayValue,
            setFocused,
            event,
            onBlur,
            noAccentedLetters
        }),
        [alive, inputType, onChange, setDisplayValue, setFocused, onBlur, noAccentedLetters],
    );
    const onChangeCallback = useCallback(
        (event) => handleChange({
            event,
            alive,
            inputType,
            onChange,
            setDisplayValue,
            delayedOnChange,
            maxLength,
            onFormatError,
            setFormatError
        }),
        [alive, inputType, onChange, setDisplayValue, delayedOnChange, maxLength, onFormatError, setFormatError],
    );
    const onFocusCallback = useCallback(
        (event) => handleOnFocus({
            alive,
            setDisplayValue,
            setFocused,
            parentValue,
            onFocus,
            event
        }),
        [setDisplayValue, setFocused, parentValue, onFocus, alive],
    );
    return <>
        {title && <div className="customInput__titleContainer">
            <div className={`customInput__title ${titleStyle}`}>{title}</div>
        </div>}
        <div className={`customInput__container ${className} ${disableInput === true && "customInput__container--outlineNone"} ${formatError ? "customInput__container--formatError" : ""}`}>
            {isTextArea ?
                <textarea
                    placeholder={placeholder}
                    onBlur={onBlurCallback}
                    onFocus={onFocusCallback}
                    value={displayValue ?? ""}
                    onChange={onChangeCallback}
                    className="customInput__textArea"
                    maxLength={maxLength}
                    onKeyDown={onKeyDown}
                    ref={reference}
                />
                :
                <label className="customInput__inputContainer">
                    <input
                        title={type !== "password" ? displayValue : null}
                        type={type}
                        placeholder={placeholder}
                        onBlur={onBlurCallback}
                        onFocus={onFocusCallback}
                        value={displayValue ?? ""}
                        onChange={onChangeCallback}
                        className={`customInput__input ${disableInput === true && "customInput__input--disabled"}`}
                        maxLength={maxLength}
                        onKeyDown={onKeyDown}
                        ref={reference}
                    />
                    {symbol}
                </label>
            }
            {!editableValue &&
            <div className="customInput__modify" onClick={() => setEditableValue(true)}>
                {t("common.button.modify")}
            </div>
            }
        </div>
    </>;
};

export default React.memo(TextInput);
