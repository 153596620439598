import React from "react";
import {useHistory} from 'react-router-dom';
import defaultAvatar from '../../../assets/Images/img_avatar.png';
import {useTranslation} from "react-i18next";
import {AppState} from "../../../redux/store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect} from "react-redux";
import {setAuthenticated, setOnboardingData} from "../../../redux/auth";
import {NavDropdown} from "react-bootstrap";
import './ProfilePic.scss';
import {logout} from "../../../redux/auth/auth-actions";
import ReactCountryFlag from "react-country-flag";
import LanguageDetector from "i18next-browser-languagedetector";
import {OnBoardingStep, Roles} from "../../../model/UserDTO";
import menuIcon from "../../../assets/Icons/menu.svg";

interface Props {
    setAuthenticatedValue: (value: boolean) => void
    setOnboardingData: (data: any) => void
    logout: () => any
    className?: string
    profilePic?: string
    userData: any
    role: string
    style?: any
    step: string,
    showName?: boolean
}

const ProfilePic: React.FC<Props> = ({
                                         userData,
                                         profilePic,
                                         setAuthenticatedValue,
                                         className,
                                         role,
                                         style,
                                         logout,
                                         step,
                                         setOnboardingData,
                                         showName
                                     }) => {
    const history = useHistory();
    const {i18n, t} = useTranslation();

    const logoutUser = () => {
        logout().then(() => {
            setAuthenticatedValue(false);
            history.push("/login");
        });
    };
    const roleDisplay = (i18n.exists('navbar.' + role) && t('navbar.' + role)) || "";


    return (
        <div className={className}>
            {showName && <div className="personalInfo__rightName">
                <div className="personalInfo__rightTitle">
                    {(userData.name || "") + " " + (userData.surname || "")}
                </div>
                <div className="personalInfo__rightTitle">
                    {roleDisplay}
                </div>
            </div>}

            <NavDropdown
                id="basic-nav-dropdown"
                style={{marginRight: 0}}
                title={<div className="customProfilePic__img customProfilePic__li " style={style}>
                    <img src={profilePic || defaultAvatar} alt="Avatar" className="personalInfo__avatar"/>
                    <img
                        src={menuIcon}
                        alt="menu"
                        width="35px"
                    />
                </div>
                }
            >
                {step === OnBoardingStep.END_ONBOARD &&
                <NavDropdown.Item>
                    <span
                        onClick={async () => {
                            const lang = i18n.languages[0] === "en" ? "it" : "en";
                            setOnboardingData({language: lang});
                            await i18n.changeLanguage(lang);
                        }}
                        style={{cursor: "pointer"}}
                        className="personalInfo__subMenu"
                    >
                        <span style={{marginRight: "10px"}}>
                            {t("common.lng.switch")}
                        </span>
                        <span style={{display: "flex"}}>
                            <ReactCountryFlag
                                countryCode={i18n.use(LanguageDetector).language === "en" ? "it" : "gb"}
                                svg
                                title={"title"}
                                style={{width: '21px', height: '14px'}}
                            />
                        </span>
                    </span>
                </NavDropdown.Item>
                }
                {step === OnBoardingStep.END_ONBOARD && <NavDropdown.Item
                    id="nav_dropdown__dropdownItem"
                    onClick={() => history.push('/personalInfo')}
                >
                    {t('navbar.profilePage')}
                </NavDropdown.Item>
                }
                {role !== Roles.ADMIN && <NavDropdown.Item
                    id="nav_dropdown__dropdownItem"
                    onClick={() => history.push('/contactUs')}
                >
                    {t('navbar.contactUs')}
                </NavDropdown.Item>}
                <NavDropdown.Item
                    id="nav_dropdown__dropdownItem"
                    onClick={logoutUser}
                >
                    {t('navbar.logout')}
                </NavDropdown.Item>
            </NavDropdown>
        </div>
    );
};
const mapStateToProps = (store: AppState) => ({
    userData: store.auth.onBoardingData,
    companyRoles: store.auth.companyRoles,
    profilePic: store.auth.onBoardingData.profilePic,
    role: store.auth.role,
    step: store.auth.onBoardingStep,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    setAuthenticatedValue: (value: boolean) => dispatch(setAuthenticated(value)),
    logout: () => dispatch(logout()),
    setOnboardingData: (data: any) => dispatch(setOnboardingData(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps)
(ProfilePic);
