import * as React from 'react';
import {useTranslation} from "react-i18next";
import "../../../components/Common/Table/Table.scss";
import Button, {BUTTON_TYPE} from "../../../components/Common/Button/Button";
import forward_arrow_right from "../../../assets/Icons/forward_arrow_right.svg";
import UserCompanySummary from "../UserCompanySummary/UserCompanySummary";

const CompanyInfo: React.FC<any> = ({
                                        title,
                                        description,
                                        onPrevious,
                                        userData,
                                        companyId
                                    }) => {

    const {t} = useTranslation();
    const [isUploading, setIsUploading] = React.useState(false);


    return userData
        && <UserCompanySummary
            noLegalFilesEdit
            title={(title || t("admin.companyInfo.titleInfo")) + userData?.companyData?.businessName + ` (${userData?.companyData?.vatNumber})`}
            description={description || t("admin.companyInfo.description")}
            userData={userData}
            setIsUploading={setIsUploading}
            companyId={companyId}
            buttons={<>
                <div
                    className="containerButtonConfirm containerButtonConfirm--marginRight20"
                >
                    <Button
                        label={t("onBoarding.previous")}
                        onClick={onPrevious}
                        icon={forward_arrow_right}
                        buttonType={BUTTON_TYPE.primaryInverted}
                        iconOnTheLeft
                        disabled={isUploading}
                    />
                </div>
            </>}
        />;
};
export default CompanyInfo;


