import * as React from 'react';
import Button from "../Button/Button";
import "./NoDataBox.scss";

interface Props {
    buttonLabel?: string,
    buttonBlockable?: boolean,
    noDataTitle?: string
    description?: string
    styleContainer?: any
    icon?: any
    error?: string
    openBox?(): void,
}

const NoDataBox: React.FC<Props> = ({
                                        openBox,
                                        buttonLabel,
                                        buttonBlockable,
                                        noDataTitle,
                                        error,
                                        icon,
                                        description,
                                        styleContainer
                                    }) => {
    return (
        <div className="noDataBox" style={styleContainer}>
            <div className="shadowCard noDataBox__noCreditBox">
                {noDataTitle && <div className="noDataBox__mainTitle">
                    <div>{noDataTitle}</div>
                </div>
                }
                {description && <div className="noDataBox__text">{description}</div>}
                {buttonLabel &&
                <div className="noDataBox__bottomSection">
                    <div className="noDataBox__error">
                        {error}
                    </div>
                    <div className="noDataBox__button">
                        <Button
                            label={buttonLabel} onClick={openBox ? openBox : () => {
                        }}
                            icon={icon}
                            disabled={!!error}
                            blockable={buttonBlockable}
                        />
                    </div>
                </div>
                }
            </div>
        </div>
    );
};

export default NoDataBox;

