import * as React from 'react';
import UserSummary from "../../../screens/admin/UserSummary/UserSummary";
import DocumentData from "../../../screens/common/documentData/DocumentData";
import {useCallback, useEffect} from "react";

interface Props {
    title: any,
    userId: string | null,
    onPrevious: any
    onConfirm: any
    data: any
    setError: any
    error: any
}

const ManageUserDocument = ({
                                title,
                                userId,
                                onPrevious,
                                onConfirm,
                                data,
                                setError,
                                error
                            }: Props) => {
    const [documentData, setDocumentData] = React.useState<any>({});
    const [userData, setUserData] = React.useState<any>({});
    const [formValid, setFormValid] = React.useState(false);


    useEffect(() => {
        if (data?.userData) {
            const {
                documentType,
                documentNumber,
                documentEmitter,
                documentCityEmitter,
                documentProvinceEmitter,
                documentNationEmitter,
                documentReleaseDate,
                documentExpiryDate,
                residenceCountry, ...rest
            } = data?.userData;

            setDocumentData({
                documentType,
                documentNumber,
                documentEmitter,
                documentCityEmitter,
                documentProvinceEmitter,
                documentNationEmitter,
                documentReleaseDate,
                documentExpiryDate,
                residenceCountry
            });

            setUserData(rest);
        }

    }, [data]);

    const updateComponentState = useCallback((inputData: {}) => {
        const updatedData = {...documentData, ...inputData};
        setDocumentData(updatedData);
        setError(null);
    }, [setDocumentData, documentData, setError]);

    const onConfirmHandler = (idObj: any) => onConfirm?.(idObj, documentData);

    return <UserSummary
        title={title}
        userId={userId}
        onPrevious={onPrevious}
        onConfirm={onConfirmHandler}
        data={{...data, userData}}
        formNotValid={!formValid}
        error={error}
        additionalFields={<>
            <DocumentData
                style={{marginBottom: "40px"}}
                onFormValidChange={setFormValid}
                componentData={documentData}
                onChange={updateComponentState}
                onLocalChange={updateComponentState}
            />
        </>}
    />;
};


export default ManageUserDocument;
