import moment from 'moment';
import 'moment/locale/it';
import {languageUtils} from "./languageUtils";

class TimeUtils {

    getTimestamp = (date: any):number => {
        let _moment = this.getLocale();
        return date ? _moment(date).valueOf() : 0;
    };

    getLocale = () => {
        if (languageUtils.getLocaleLanguage() === "it") {
            moment.locale("it");
        } else {
            moment.locale("en");
        }
        return moment;
    };

    getLocalTime = (): string => {
        let _moment = this.getLocale();
        return _moment(new Date()).format("ll");
    };

    formatToLocaleDate = (date: any, format?: string): string => {
        let _moment = this.getLocale();
        return date ? _moment(date).format(format || "ll") : "";
    };

    formatToLocaleISODate = (date: Date): string => {
        return moment(date).format(languageUtils.getLocaleDateFormat());
    };

    addDays = (date?: Date|moment.Moment, days = 1) => {
        return date && moment(date)?.add(days, "day");
    };

    isInRangeFromToday = (rangeInDays: number, date: any) => moment(date).isBefore(moment().add(rangeInDays, "day"), "day");
}

export const timeUtils = new TimeUtils();
