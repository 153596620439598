import {PaymentStatus} from "./VendorDTO";

export interface BuyerInvoiceDTO {
    invoiceId: string[]
    documentId: string
    id: number[]
    currency: string
    expiryDate: Date
    delayExpiryDate: Date
    effectiveExpireDate: Date
    creditor?: string
    amount: number
    payableAmount: number
    selected: boolean
    delayRate: number
    creditNote?: boolean
}

export enum BuyerInvoicesSelectedMethod {
    SELECTION_METHOD_NORMAL = "NORMAL",
    SELECTION_METHOD_DELAYED = "DELAYED",
    SELECTION_METHOD_AMOUNT = "AMOUNT",
}

export interface BuyerInvoicesRequest {
    currency?: string
    selectedMethod?: BuyerInvoicesSelectedMethod
    amount?: number
}

export interface DynamicDiscountAssociatedDTO {
    type: string
    id: string
    amount: number
    originalAmount: number
    currency: string
}


export interface BuyerActiveInvoiceDTO {
    documentId: string
    id: string[]
    expiryDate: string
    effectiveExpireDate: string
    currency: string
    salable: boolean
    amount: number
    payableAmount: number
    currentCreditor?: string
    status: PaymentStatus
}

export interface VendorRegistryDocumentRequestDTO {
    vendorVatNumber:string
}

export interface BuyerEditableInvoiceDTO{
    installmentId: number
    reference: string
    documentNumber: string
    documentRow: string
    documentActivity: string
    emissionDate: Date
    expiryDate: Date
    lastExpiryDate: Date
    foreignAmount: number
    payableAmount: number
    totalAmount: number
    currency: string
    salable: boolean
    vatAmount: number
    salableVat: boolean
    vendorBusinessName: string
    vendorVatNumber: string
    programCode: string
    editable: boolean
    deletable: boolean
    payable: boolean
    paid: boolean
}

export interface BuyerNewInvoiceDTO {
    installmentId?: number,
    vendorVatNumber: string,
    programCode: string,
    reference: string,
    documentNumber: string,
    documentRow: string,
    documentActivity: string,
    currency: string,
    emissionDate: Date,
    expiryDate: Date,
    foreignAmount: number,
    payableAmount: number,
    totalAmount: number,
    vatAmount: number,
    salableVat?: boolean,
    salable?: boolean,
    paid: boolean,
    paymentDate?: Date,
    deleted: boolean
}

export interface BuyerCessionOperationsDTO{
    operationDate: Date
    country: string 
    targetCompany: string
    operationType: string
    status: String
    amount: number
    amountPaid: number
    amountSold: number
    currency: string
    operationId: string
    downloaded: Date
    selected: boolean
}

export interface BuyerMoneylenderInfoDTO{
    vatNumber: string
    moneylenderName: string
    marketplaceCode: string
    processStatus: boolean
    lastModify: Date
    country: string
}

export interface EditMoneylenderInfoDTO{
    moneylenderVatNumber: string
    marketplaceCode: string
    moneylenderErpId: string
    acceptanceProcess: boolean
}

export interface BuyerMoneyLenderCreditLineDTO {
    creditLineName: string
    iban: string | null
    swift: string | null
}