import * as React from 'react';
import {ReactNode} from 'react';
import "./Table.scss";
import {AppState} from "../../../redux/store";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import Filter from "../Filter/Filter";
import TextInput from "../TextInput/TextInput";
import {searchIcon} from "../../../utils/dynamicSVG";
import {Roles} from "../../../model/UserDTO";
import SortingBar, {SortingObj} from "../SortingBar/SortingBar";

export interface SearchableListItem {
    id: { value: string },
    [index: string]: any
}

export const getSearchableList = (list: any[], accessorId: any) => {
    return list && list.map((item: any) => ({
        id: {value: accessorId(item)},
        ...item
    }));
};

interface Props {
    listToDisplay?: any[]
    searchableListToDisplay?: any[]
    currencyFilters?: string[]
    headers?: TableHeader[]
    className?: string
    classNamePagination?: string
    headerClassNameModifier?: string
    headerItemModifier?: string
    classNameScrollContainer?: string
    errorMessage?: string
    error?: string
    itemForPage?: number
    noItemsRender?: ReactNode
    searchBarStyle?: any
    filterStyle?: any
    role: Roles
    style?: any,
    errorClassName?: any,
    sortingList?: SortingObj[],
    renderItem?(item: any, index: any, array: any[], originalItem?: any): ReactNode
    filterValueAccessor?(value: any, index: number, array: any[]): any
    defaultSortingFn?(a: any, b: any): number
    accessorId?(item: any): string | number
}

export interface TableHeader {
    sortingAccessor?: (el: any) => any,
    sortingDirection?: string,
    isPrimary?: boolean
    isDefault?: boolean
    label?: string | null
    style?: any
    body?: any
}

export const getHighlightedText = (text: string, highlight: string) => {
    //escape special regex characters
    const highlightEscaped = highlight.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlightEscaped})`, 'gi'));
    return <span>
        {parts.map((part, i) =>
            <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? {backgroundColor: 'yellow'} : {}}>
                {part}
            </span>
        )}
    </span>;
};


export const highlightFilterValue = (obj: any, filterQuery: string) => {
    const out = {...obj};
    Object.keys(out).forEach((key) => {
        const value = out[key];
        if (value && typeof value === "string") {
            const itemIncludes = value.toLowerCase().includes(filterQuery.toLowerCase());
            if (itemIncludes) {
                out[key] = getHighlightedText(value, filterQuery);
            }
        }
    });
    return out;
};

export const objectHasFilterValue = (obj: any, filterQuery: string) => {
    let isPositive = false;
    Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (value && typeof value === "string") {
            const itemIncludes = value.toLowerCase().includes(filterQuery.toLowerCase());
            isPositive = isPositive || itemIncludes;
        }
    });
    return isPositive;
};

const Table: React.FC<Props> = ({
                                    listToDisplay,
                                    currencyFilters,
                                    noItemsRender,
                                    headers,
                                    renderItem,
                                    filterValueAccessor,
                                    defaultSortingFn,
                                    className,
                                    errorMessage,
                                    error,
                                    itemForPage,
                                    headerClassNameModifier = "",
                                    headerItemModifier = "",
                                    role,
                                    searchBarStyle,
                                    filterStyle,
                                    style,
                                    searchableListToDisplay,
                                    accessorId,
                                    classNameScrollContainer,
                                    sortingList,
                                    classNamePagination,
                                    errorClassName
                                }) => {
    const rowForPageAvailable = [{value: 3}, {value: 5}, {value: 15}, {value: 50}];
    const searchableList = getSearchableList(searchableListToDisplay || [], accessorId);
    const unifiedList = listToDisplay || searchableList;
    //states
    const [sorting, setSorting] = React.useState({} as any);
    const [primaryAccessor, setPrimaryAccessor] = React.useState<any>();
    const [rowForPage, setRowForPage] = React.useState<number>(itemForPage || rowForPageAvailable[3].value);
    const [startPoint, setStartPoint] = React.useState(0);
    const [endPoint, setEndPoint] = React.useState(rowForPage);
    const [actualPage, setActualPage] = React.useState(1);
    const [numberOfPages, setNumberOfPages] = React.useState((unifiedList && Math.ceil(unifiedList.length / rowForPage)) || 0);
    const [elaboratedDataList, setElaboratedDataList] = React.useState([]);
    const [elaboratedAndFilteredDataList, setElaboratedAndFilteredDataList] = React.useState<any>();
    const [filterQuery, setFilterQuery] = React.useState("");
    const [selectedFilterList, setSelectedFiltersList] = React.useState<string[]>();

    React.useEffect(() => {
        let {sortingFn} = sorting;
        sortingFn = sortingFn || defaultSortingFn || undefined;
        let elaboratedList: any = (listToDisplay && [...listToDisplay]) || (searchableList && [...searchableList]) || [];
        if (sortingFn && elaboratedList.length) {
            elaboratedList = elaboratedList.sort((sortingFn));
        }
        if (selectedFilterList && selectedFilterList.length > 0 && elaboratedList && elaboratedList.length) {
            // @ts-ignore
            elaboratedList = filterValueAccessor ? (elaboratedList as any[]).filter((...props: any) => selectedFilterList.includes(filterValueAccessor(...props))) : elaboratedDataList;
        }
        setElaboratedDataList(elaboratedList);
        setNumberOfPages(elaboratedList && Math.ceil(elaboratedList.length / rowForPage));
        // eslint-disable-next-line
    }, [filterValueAccessor, listToDisplay, selectedFilterList, sorting, rowForPage, searchableListToDisplay]);

    React.useEffect(() => {
        setActualPage(1);
        setStartPoint(0);
        setEndPoint(rowForPage);
        // eslint-disable-next-line
    }, [filterQuery, selectedFilterList, sorting, rowForPage]);

    React.useEffect(() => {
        if (filterQuery) {
            let list: any = (elaboratedDataList && [...elaboratedDataList]) || [];
            list = list.filter((item: any) => objectHasFilterValue(item, filterQuery));
            list = list.map((item: any) => highlightFilterValue(item, filterQuery));
            setElaboratedAndFilteredDataList(list);
            setNumberOfPages(list && Math.ceil(list.length / rowForPage));
        } else {
            setNumberOfPages(elaboratedDataList && Math.ceil(elaboratedDataList.length / rowForPage));
            setElaboratedAndFilteredDataList(null);
        }
        // eslint-disable-next-line
    }, [filterQuery, elaboratedDataList]);


    React.useEffect(() => {
        if (!primaryAccessor && !(sorting && sorting.sortingFn)) {
            headers?.forEach(({isPrimary, isDefault, sortingAccessor, label, sortingDirection}) => {
                if (isPrimary && sortingAccessor) {
                    setPrimaryAccessor({sortingAccessor, sortingDirection});
                }
                if (isDefault && sortingAccessor) {
                    const sortingFn = (a: any, b: any) => {
                        const aValue = sortingAccessor(a);
                        const bValue = sortingAccessor(b);
                        return sortingDirection === "desc" ? (aValue <= bValue ? 1 : -1) : (aValue > bValue ? 1 : -1);
                    };
                    setSorting({label, sortingFn, direction: sortingDirection || "desc"});
                }
            });
        }
        // eslint-disable-next-line
    }, [headers]);

    const {t, i18n} = useTranslation();

    const listToRender = elaboratedAndFilteredDataList || elaboratedDataList;

    const goNextPage = () => {
        if (endPoint < listToRender.length) {
            setActualPage(actualPage + 1);
            setEndPoint(endPoint + rowForPage);
            setStartPoint(startPoint + rowForPage);
        }
    };

    const goPreviousPage = () => {
        if (startPoint > 0) {
            setActualPage(actualPage - 1);
            setEndPoint(endPoint - rowForPage);
            setStartPoint(startPoint - rowForPage);
        }
    };

    const changeSorting = ({label, sortingAccessor}: TableHeader) => {
        if (sortingAccessor) {
            let sortingFn, direction: string;
            const primary = primaryAccessor?.sortingAccessor;
            if (sorting.label === label) {
                direction = sorting.direction === "asc" ? "desc" : "asc";
            } else {
                direction = "desc";
            }
            sortingFn = (a: any, b: any) => {
                const aValue = sortingAccessor(a);
                const bValue = sortingAccessor(b);
                if (primary && aValue === bValue) return primary(a) > primary(b) ? 1 : -1;
                else return direction === "desc" ? (aValue <= bValue ? 1 : -1) : (aValue > bValue ? 1 : -1);
            };
            setSorting({label, direction, sortingFn});
        }
    };

    const changeNumberOfItemsForPage = (e: any) => {
        setRowForPage(parseInt(e.target.value));
    };
    return (
        <>
            {(elaboratedDataList?.length === 0) || (!elaboratedDataList) ? noItemsRender :
                <div className="table__tableWrapper">
                    {(filterValueAccessor || searchableListToDisplay) &&
                    <div className="table__topSection">
                        {searchableList?.length > 0 &&
                        <div className="table__searchBarContainer" style={searchBarStyle}>
                            <TextInput
                                value={filterQuery}
                                placeholder={t("common.button.search")}
                                onChange={setFilterQuery}
                                symbol={searchIcon(role)}
                                className="searchBar"
                            />
                        </div>
                        }
                        {filterValueAccessor &&
                        <div className="table__filterSection" style={filterStyle}>
                            <Filter
                                title={t("SCFProgram.filterBy")}
                                filterList={currencyFilters || []}
                                updateSelectedFilters={setSelectedFiltersList}
                            />
                        </div>
                        }
                        {/*SORTING SECTION*/}
                        {sortingList &&
                        <div className="table__orderBySection" style={style?.orderingSection}>
                            <SortingBar onUpdateSorting={setSorting} sortingList={sortingList}/>
                        </div>
                        }
                    </div>
                    }
                    {/*HEADER*/}
                    <div className={`table__scrollContainer ${className || ""}`}>
                        <div className="table" style={style}>
                            {headers?.length &&
                            <>
                                <div className={"table__header " + headerClassNameModifier}>
                                    {headers?.map(({label, sortingAccessor, style, body}: TableHeader, index: number) => {
                                        if (label) label = i18n.exists(label) ? t(label) : label;
                                        let arrowModifier = '';
                                        if (sorting && (sorting.label === label))
                                            arrowModifier = sorting.direction === "asc" ? "table__triangle table__triangle--downTriangle" : sorting.direction === "desc" ? "table__triangle" : "";
                                        return (
                                            <span
                                                className={"table__headerItem " + headerItemModifier + (sortingAccessor ? "" : " table__headerItem--noSorting")}
                                                onClick={() => changeSorting({label, sortingAccessor})}
                                                key={index}
                                                style={style}
                                            >
                                                {label?.toUpperCase()}
                                                {!!body && body}
                                                {!!arrowModifier && <span className={arrowModifier}/>}
                                            </span>
                                        );
                                    })}
                                </div>
                                <div className="table__line"/>
                            </>
                            }

                            {/*ROWS */}
                            <div className={classNameScrollContainer}>
                                <div>{renderItem && listToRender.slice(startPoint, endPoint).map((item: any, index: number, arr: any) => {
                                    let originalItem = item;
                                    if (searchableList) {
                                        item = item as SearchableListItem;
                                        originalItem = searchableList && searchableList.find((original) => original.id.value === item.id.value);
                                    }
                                    return renderItem(item, index, arr, originalItem);
                                })}
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="table__errorMessageContainer">
                                <span>
                                    {errorMessage &&
                                    <span className="table__rightSide">
                                        <span className="table__errorOnTheLeft"> {t("common.table.itRemains")}</span>
                                        <span className="table__errorOnTheLeft table__errorOnTheLeft--colored">
                                            {errorMessage}
                                        </span>
                                        <span className="table__errorOnTheLeft"> {t("common.table.toAdd")}</span>
                                    </span>}
                                </span>
                            </div>

                            {/*PAGINATION */}
                            {!!itemForPage &&
                            <div className={`table__content ${classNamePagination}`}>
                                <span>
                                    {error &&
                                    <span className={`table__error ${errorClassName}`}>
                                        {error}
                                    </span>}
                                </span>
                                <span className="table__rightSide">
                                    <span className="table__numberOfPage">
                                        {t("common.table.rowsForPage")}
                                        <select className="table__select" onChange={changeNumberOfItemsForPage} value={rowForPage}>
                                            {rowForPageAvailable.map(({value}: any) => <option key={value} value={value}>{value}</option>)}
                                        </select>
                                        <span className="table__triangle table__triangle--upTriangle"/>
                                    </span>
                                    <span className="table__numberOfPage" style={{paddingRight: "5px"}}>
                                        {t("common.table.page")} {actualPage} {numberOfPages >= 1 && t("common.table.of")} {numberOfPages >= 1 && numberOfPages.toString()}
                                    </span>
                                    <span onClick={() => goPreviousPage()} style={{paddingRight: "10px", cursor: "pointer"}}>
                                        <span className="table__triangle table__triangle--left"/>
                                    </span>
                                    <span onClick={() => goNextPage()} style={{cursor: "pointer"}}>
                                        <span className="table__triangle table__triangle--right"/>
                                    </span>
                                </span>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

const mapStateToProps = (store: AppState) => ({
        role: store.auth.role
    }
);

export default connect(mapStateToProps)(Table);
