import * as React from 'react';
import {isEmpty} from "../../../utils/commonUtils";
import Button, {BUTTON_TYPE} from "../Button/Button";
import forward_arrow_right from "../../../assets/Icons/forward_arrow_right.svg";
import {useTranslation} from "react-i18next";
import "./CardContainerWithButtons.scss";
import {ReactNode} from "react";

interface Props {
    title?: string | ReactNode,
    subtitle?: string,
    creationDate?: string,
    description?: string,
    confirmButtonLabel?: string
    confirmButtonDisabled?: boolean,
    loading?: boolean,
    previousButtonLabel?: string,
    error?: any,
    style?: any,
    buttonAbort?: ReactNode
    confirmButton?: ReactNode,
    deleteButton?: ReactNode,
    onPreviousButtonClick?(): void,
    onConfirmButtonClick?(): void,
}

const CardContainerWithButtons: React.FC<Props> = ({
                                                       title,
                                                       subtitle,
                                                       description,
                                                       children,
                                                       deleteButton,
                                                       confirmButtonLabel,
                                                       previousButtonLabel,
                                                       confirmButtonDisabled,
                                                       error,
                                                       style,
                                                       onPreviousButtonClick,
                                                       onConfirmButtonClick,
                                                       buttonAbort,
                                                       confirmButton,
                                                       loading
                                                   }) => {

    const {t} = useTranslation();

    return (
        <div className="shadowCard cardContainerWithButtons__content" style={style}>
            {(typeof (title) === "string" || !title) ?
                <div className="cardContainerWithButtons__title">
                    {title}
                </div> : title
            }

            {subtitle &&
            <div className="cardContainerWithButtons__subtitle">
                {subtitle}
            </div>}

            {description &&
            <div className="cardContainerWithButtons__description">
                {description}
            </div>}
            <div className="cardContainerWithButtons__contentData">
                {children}
            </div>

            {(error || onPreviousButtonClick || onConfirmButtonClick || buttonAbort || confirmButton) &&
            <div className="containerButtons">
                <div className="cardContainerWithButtons__error">
                    {typeof error ==="object" && !isEmpty(error) ? Object.keys(error).map(key => (t(error[key]) + "; ")) : t(error)}
                </div>

                {buttonAbort ||
                (onPreviousButtonClick &&
                    <div className="containerButtonConfirm containerButtonConfirm--marginRight20">
                        <Button
                            label={previousButtonLabel || t("onBoarding.previous")}
                            onClick={onPreviousButtonClick}
                            icon={forward_arrow_right}
                            buttonType={BUTTON_TYPE.primaryInverted}
                            iconOnTheLeft
                            width={"100%"}
                        />
                    </div>)}

                {deleteButton}

                {confirmButton ||
                (onConfirmButtonClick &&
                    <div className="containerButtonConfirm containerButtonConfirm--marginRight80">
                        <Button
                            width={"100%"}
                            label={confirmButtonLabel || t("onBoarding.confirmData")}
                            onClick={onConfirmButtonClick}
                            icon={forward_arrow_right}
                            disabled={confirmButtonDisabled}
                            loading={loading}
                        />
                    </div>)
                }
            </div>}
        </div>
    );
};

export default CardContainerWithButtons;

