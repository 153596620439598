import {SupplierAction, SupplierState} from "./supplier-interface";
import {BankInfoResponseDTO, BuyerDTO, CheckoutData, VendorInvoiceDTO, InvoicesHistoryDTO, MoneylenderDTO, PendingInvoiceDTO, SellInvoicesResponse, SellInvoicesStep, StockHistoryDTO} from "../../model/VendorDTO";
import {onlyUniqueElements} from "../../utils/commonUtils";

export const GET_INVOICES = 'invoices';
export const GET_INVOICES_SUCCESS = 'getInvoicesSuccess';
export const GET_INVOICES_FAIL = 'getInvoicesFailed';
export const GET_INVOICES_SILENT = 'getInvoicesActionSilent';
export const GET_INVOICES_SUCCESS_SILENT = 'getInvoicesSuccessActionSilent';
export const GET_INVOICES_FAIL_SILENT = 'getInvoicesFailActionSilent';
export const GET_BUYERS = 'getBuyers';
export const GET_BUYERS_SUCCESS = 'getBuyersSuccess';
export const GET_BUYERS_FAIL = 'getBuyersFailed';
export const GET_PENDING_REQUEST = 'getPENDINGRequest';
export const GET_PENDING_SUCCESS = 'getPENDINGSuccess';
export const GET_PENDING_FAIL = 'getPENDINGFailed';
export const GET_ELIGIBILITY_REQUEST = 'get_eligibility_request';
export const GET_ELIGIBILITY_SUCCESS = 'get_eligibility_success';
export const GET_ELIGIBILITY_FAIL = 'get_eligibility_fail';
export const CLEAR_ELIGIBILITY_STATUS = 'clear_eligibility_status';
export const SELL_INVOICES_STEP = 'sellInvoicesStep';
export const GET_INVOICES_BY_AMOUNT = 'getInvoicesByAmountAction';
export const GET_INVOICES_SUCCESS_BY_AMOUNT = 'getInvoicesByAmountSuccessAction';
export const GET_INVOICES_FAIL_BY_AMOUNT = 'getInvoicesByAmountFailAction';
export const SAVE_SELECTED_INVOICES = 'saveSelectedInvoices';
export const SAVE_SELECTED_INVOICES_SUCCESS = 'saveSelectedInvoicesSuccess';
export const SAVE_SELECTED_INVOICES_FAIL = 'saveSelectedInvoicesFail';
export const GET_MONEYLENDERS = 'getMoneylenders';
export const GET_MONEYLENDERS_SUCCESS = 'getMoneylendersSuccess';
export const GET_MONEYLENDERS_FAIL = 'getMoneylendersFail';
export const SET_CHECKOUT_DATA = 'setCheckoutData';
export const SAVE_DISPOSAL_INVOICES = 'saveDisposalInvoices';
export const SAVE_DISPOSAL_INVOICES_SUCCESS = 'saveDisposalInvoicesSuccess';
export const SAVE_DISPOSAL_INVOICES_FAIL = 'saveDisposalInvoicesFail';
export const GET_BAR_CHART_DATA = 'getBarChartData';
export const GET_BAR_CHART_DATA_SUCCESS = 'getBarChartDataSuccess';
export const GET_BAR_CHART_DATA_FAIL = 'getBarChartDataFail';
export const GET_BAR_CHART_DATA_SILENT = 'getBarChartDataSilent';
export const GET_BAR_CHART_DATA_SUCCESS_SILENT = 'getBarChartDataSuccessSilent';
export const GET_BAR_CHART_DATA_FAIL_SILENT = 'getBarChartDataFailSilent';
export const GET_STOCK_DATA = 'getStockData';
export const GET_STOCK_DATA_SUCCESS = 'getStockDataSuccess';
export const GET_STOCK_DATA_FAIL = 'getStockDataFail';
export const GET_STOCK_DATA_SILENT = 'getStockDataSilent';
export const GET_STOCK_DATA_SUCCESS_SILENT = 'getStockDataSuccessSilent';
export const GET_STOCK_DATA_FAIL_SILENT = 'getStockDataFailSilent';
export const GET_CREDIT_LINE_HISTORY = 'getCreditLineHistory';
export const GET_CREDIT_LINE_HISTORY_SUCCESS = 'getCreditLineHistorySuccess';
export const GET_CREDIT_LINE_HISTORY_FAIL = 'getCreditLineHistoryFail';
export const GET_CREDIT_LINE_HISTORY_SILENT = 'getCreditLineHistorySilent';
export const GET_CREDIT_LINE_HISTORY_SUCCESS_SILENT = 'getCreditLineHistorySuccessSilent';
export const GET_CREDIT_LINE_HISTORY_FAIL_SILENT = 'getCreditLineHistoryFailSilent';
export const RESET_SUPPLIER = 'resetSupplier';
export const RETRIEVE_BANKS_INFO = 'getBanksInfo';
export const RETRIEVE_BANKS_INFO_SUCCESS = 'getBanksInfoSuccess';
export const RETRIEVE_BANKS_INFO_FAIL = 'getBanksInfoFail';
export const GET_MP_FEE = 'getMPFee';
export const GET_MP_FEE_SUCCESS = 'getMPFeeSuccess';
export const GET_MP_FEE_FAIL = 'getMPFeeFail';
export const GET_CHART_DATA = 'getChartData';
export const GET_CHART_DATA_SUCCESS = 'getChartDataSuccess';
export const GET_CHART_DATA_FAIL = 'getChartDataFail';
export const GET_CHART_DATA_SILENT = 'getChartDataSilent';
export const GET_CHART_DATA_SUCCESS_SILENT = 'getChartDataSuccessSilent';
export const GET_CHART_DATA_FAIL_SILENT = 'getChartDataFailSilent';

const defaultState: SupplierState = {
    invoices: null,
    isFetchingInvoices: false,
    isFetchingInvoicesSilent: false,
    isFetchingEligibility: false,
    computedCurrencyFilters: [],
    isFetchingGetBuyers: false,
    buyersResponse: [] as BuyerDTO[],
    buyersResponseFail: null,
    pendingInvoicesIsFetching: false,
    pendingInvoicesResponse: [] as PendingInvoiceDTO[],
    pendingInvoicesResponseFail: null,
    sellInvoicesStep: "",
    isFetchingInvoicesByAmount: false,
    invoicesByAmountResponse: null,
    invoicesByAmountResponseFail: null,
    isFetchingGetMoneylenders: false,
    getMoneylendersResponse: [],
    getMoneylendersFail: null,
    checkoutData: {} as CheckoutData,
    isFetchingSaveDisposalInvoices: false,
    saveDisposalInvoicesSuccess: [],
    saveDisposalInvoicesFail: "",
    isFetchingGetBarChartData: false,
    getBarChartDataSuccess: null,
    getBarChartDataFail: "",
    isFetchingGetStockData: false,
    getStockDataSuccess: null,
    getStockDataFail: "",
    isFetchingCreditLineHistory: false,
    creditLineHistorySuccess: null,
    creditLineHistoryFail: "",
    isFetchingBanksInfo: null,
    banksInfoResponse: [] as BankInfoResponseDTO[],
    banksInfoFail: null,
    isFetchingMPFee: null,
    MPFeeResponse: null,
    MPFeeFail: "",
    isFetchingChartData: null,
    chartDataResponse: null,
    chartDataFail: "",
    isFetchingGetBarChartDataSilent: null,
    isFetchingGetStockDataSilent: null,
    isFetchingCreditLineHistorySilent: null,
    isFetchingChartDataSilent: null
};

const getCurrenciesFilters = (invoicesData: VendorInvoiceDTO[]) => invoicesData?.map?.((item) => item.currency).filter(onlyUniqueElements) || [];

export function supplierReducer(state = defaultState, action: SupplierAction): SupplierState {
    switch (action.type) {
        case GET_INVOICES:
            return {
                ...state,
                isFetchingInvoices: true
            };
        case GET_INVOICES_SUCCESS:
            return {
                ...state,
                invoices: action.payload,
                computedCurrencyFilters: getCurrenciesFilters(action.payload.invoicesData),
                isFetchingInvoices: false
            };
        case GET_INVOICES_FAIL:
            return {
                ...state,
                invoices: null,
                isFetchingInvoices: false,
            };

        case GET_INVOICES_SILENT:
            return {
                ...state,
                isFetchingInvoicesSilent: true
            };
        case GET_INVOICES_SUCCESS_SILENT:
            return {
                ...state,
                invoices: action.payload,
                computedCurrencyFilters: getCurrenciesFilters(action.payload.invoicesData),
                isFetchingInvoicesSilent: false
            };
        case GET_INVOICES_FAIL_SILENT:
            return {
                ...state,
                invoices: null,
                isFetchingInvoicesSilent: false,
            };

        case GET_ELIGIBILITY_REQUEST:
            return {
                ...state,
                isFetchingEligibility: true
            };
        case GET_ELIGIBILITY_SUCCESS:
            return {
                ...state,
                invoices: {...state.invoices, ...action.payload},
                isFetchingEligibility: false
            };
        case GET_ELIGIBILITY_FAIL:
            return {
                ...state,
                isFetchingEligibility: false,
            };
        case CLEAR_ELIGIBILITY_STATUS:
            return {
                ...state,
                invoices: {...state.invoices, status: null} as SellInvoicesResponse,
                invoicesByAmountResponse: {...state.invoicesByAmountResponse, status: null} as SellInvoicesResponse,
            };
        case GET_BUYERS:
            return {
                ...state,
                isFetchingGetBuyers: true,
                buyersResponse: defaultState.buyersResponse,
                buyersResponseFail: defaultState.buyersResponseFail
            };

        case GET_BUYERS_SUCCESS:
            return {
                ...state,
                isFetchingGetBuyers: false,
                buyersResponse: action.payload as BuyerDTO[],
                buyersResponseFail: defaultState.buyersResponseFail
            };

        case GET_BUYERS_FAIL:
            return {
                ...state,
                isFetchingGetBuyers: false,
                buyersResponse: defaultState.buyersResponse,
                buyersResponseFail: action.payload,
            };

        case GET_PENDING_REQUEST:
            return {
                ...state,
                pendingInvoicesIsFetching: true,
                pendingInvoicesResponse: defaultState.pendingInvoicesResponse,
                pendingInvoicesResponseFail: []
            };

        case GET_PENDING_SUCCESS:
            return {
                ...state,
                pendingInvoicesIsFetching: false,
                pendingInvoicesResponse: action.payload,
                pendingInvoicesResponseFail: defaultState.pendingInvoicesResponseFail
            };

        case GET_PENDING_FAIL:
            return {
                ...state,
                pendingInvoicesIsFetching: false,
                pendingInvoicesResponse: defaultState.pendingInvoicesResponse,
                pendingInvoicesResponseFail: action.payload,
            };

        case GET_MONEYLENDERS:
            return {
                ...state,
                isFetchingGetMoneylenders: true,
                getMoneylendersFail: null,
            };

        case GET_MONEYLENDERS_SUCCESS:
            return {
                ...state,
                isFetchingGetMoneylenders: false,
                getMoneylendersResponse: action.payload as MoneylenderDTO[],
                getMoneylendersFail: []
            };

        case GET_MONEYLENDERS_FAIL:
            return {
                ...state,
                isFetchingGetMoneylenders: false,
                getMoneylendersResponse: [],
                getMoneylendersFail: action.payload,
            };

        case GET_INVOICES_BY_AMOUNT:
            return {
                ...state,
                isFetchingInvoicesByAmount: true,
                invoicesByAmountResponse: null,
                invoicesByAmountResponseFail: []
            };

        case GET_INVOICES_SUCCESS_BY_AMOUNT:
            return {
                ...state,
                isFetchingInvoicesByAmount: false,
                invoicesByAmountResponse: action.payload,
                computedCurrencyFilters: getCurrenciesFilters(action.payload.invoicesData),
                invoicesByAmountResponseFail: []
            };

        case GET_INVOICES_FAIL_BY_AMOUNT:
            return {
                ...state,
                isFetchingInvoicesByAmount: false,
                invoicesByAmountResponse: null,
                invoicesByAmountResponseFail: action.payload,
            };

        case SAVE_DISPOSAL_INVOICES:
            return {
                ...state,
                isFetchingSaveDisposalInvoices: true,
                saveDisposalInvoicesSuccess: [],
                saveDisposalInvoicesFail: null,
            };

        case SAVE_DISPOSAL_INVOICES_SUCCESS:
            return {
                ...state,
                isFetchingSaveDisposalInvoices: false,
                saveDisposalInvoicesSuccess: action.payload,
                saveDisposalInvoicesFail: null,
            };

        case SAVE_DISPOSAL_INVOICES_FAIL:
            return {
                ...state,
                isFetchingSaveDisposalInvoices: false,
                saveDisposalInvoicesSuccess: [],
                saveDisposalInvoicesFail: action.payload,
            };

        case GET_BAR_CHART_DATA:
            return {
                ...state,
                isFetchingGetBarChartData: true,
                getBarChartDataSuccess: null,
                getBarChartDataFail: "",
            };

        case GET_BAR_CHART_DATA_SUCCESS:
            return {
                ...state,
                isFetchingGetBarChartData: false,
                getBarChartDataSuccess: action.payload as InvoicesHistoryDTO,
                getBarChartDataFail: "",
            };

        case GET_BAR_CHART_DATA_FAIL:
            return {
                ...state,
                isFetchingGetBarChartData: false,
                getBarChartDataSuccess: null,
                getBarChartDataFail: action.payload,
            };

        case GET_BAR_CHART_DATA_SILENT:
            return {
                ...state,
                isFetchingGetBarChartDataSilent: true,
            };

        case GET_BAR_CHART_DATA_SUCCESS_SILENT:
            return {
                ...state,
                isFetchingGetBarChartDataSilent: false,
                getBarChartDataSuccess: action.payload as InvoicesHistoryDTO,
            };

        case GET_BAR_CHART_DATA_FAIL_SILENT:
            return {
                ...state,
                isFetchingGetBarChartDataSilent: false,
            };

        case GET_STOCK_DATA:
            return {
                ...state,
                isFetchingGetStockData: true,
                getStockDataSuccess: null,
                getStockDataFail: "",
            };

        case GET_STOCK_DATA_SUCCESS:
            return {
                ...state,
                isFetchingGetStockData: false,
                getStockDataSuccess: action.payload as StockHistoryDTO,
                getStockDataFail: "",
            };

        case GET_STOCK_DATA_FAIL:
            return {
                ...state,
                isFetchingGetStockData: false,
                getStockDataSuccess: null,
                getStockDataFail: action.payload,
            };

        case GET_STOCK_DATA_SILENT:
            return {
                ...state,
                isFetchingGetStockDataSilent: true,
            };

        case GET_STOCK_DATA_SUCCESS_SILENT:
            return {
                ...state,
                isFetchingGetStockDataSilent: false,
                getStockDataSuccess: action.payload as StockHistoryDTO,
            };

        case GET_STOCK_DATA_FAIL_SILENT:
            return {
                ...state,
                isFetchingGetStockDataSilent: false,
            };
        case GET_CREDIT_LINE_HISTORY:
            return {
                ...state,
                isFetchingCreditLineHistory: true,
                creditLineHistorySuccess: null,
                creditLineHistoryFail: "",
            };

        case GET_CREDIT_LINE_HISTORY_SUCCESS:
            return {
                ...state,
                isFetchingCreditLineHistory: false,
                creditLineHistorySuccess: action.payload,
                creditLineHistoryFail: "",
            };

        case GET_CREDIT_LINE_HISTORY_FAIL:
            return {
                ...state,
                isFetchingCreditLineHistory: false,
                creditLineHistorySuccess: null,
                creditLineHistoryFail: action.payload,
            };


        case GET_CREDIT_LINE_HISTORY_SILENT:
            return {
                ...state,
                isFetchingCreditLineHistorySilent: true,
            };

        case GET_CREDIT_LINE_HISTORY_SUCCESS_SILENT:
            return {
                ...state,
                isFetchingCreditLineHistorySilent: false,
                creditLineHistorySuccess: action.payload,
            };

        case GET_CREDIT_LINE_HISTORY_FAIL_SILENT:
            return {
                ...state,
                isFetchingCreditLineHistorySilent: false,
            };

        case SELL_INVOICES_STEP:
            return {
                ...state,
                sellInvoicesStep: action.payload as SellInvoicesStep,
            };

        case SET_CHECKOUT_DATA:
            return {
                ...state,
                checkoutData: {...state.checkoutData, ...action.payload},
            };
        case RETRIEVE_BANKS_INFO:
            return {
                ...state,
                isFetchingBanksInfo: true,
                banksInfoResponse: defaultState.banksInfoResponse,
                banksInfoFail: defaultState.banksInfoFail,
            };

        case RETRIEVE_BANKS_INFO_SUCCESS:
            return {
                ...state,
                isFetchingBanksInfo: false,
                banksInfoResponse: action.payload as BankInfoResponseDTO[],
                banksInfoFail: defaultState.banksInfoFail
            };

        case RETRIEVE_BANKS_INFO_FAIL:
            return {
                ...state,
                isFetchingBanksInfo: false,
                banksInfoResponse: defaultState.banksInfoResponse,
                banksInfoFail: action.payload,
            };

        case GET_MP_FEE:
            return {
                ...state,
                isFetchingMPFee: true,
                MPFeeResponse: defaultState.MPFeeResponse,
                MPFeeFail: defaultState.MPFeeFail,
            };

        case GET_MP_FEE_SUCCESS:
            return {
                ...state,
                isFetchingMPFee: false,
                MPFeeResponse: action.payload,
                MPFeeFail: defaultState.MPFeeFail
            };

        case GET_MP_FEE_FAIL:
            return {
                ...state,
                isFetchingMPFee: false,
                MPFeeResponse: defaultState.MPFeeResponse,
                MPFeeFail: action.payload,
            };


        case GET_CHART_DATA:
            return {
                ...state,
                isFetchingChartData: true,
                chartDataResponse: defaultState.chartDataResponse,
                chartDataFail: defaultState.chartDataFail,
            };

        case GET_CHART_DATA_SUCCESS:
            return {
                ...state,
                isFetchingChartData: false,
                chartDataResponse: action.payload,
                chartDataFail: defaultState.chartDataFail
            };

        case GET_CHART_DATA_FAIL:
            return {
                ...state,
                isFetchingChartData: false,
                chartDataResponse: defaultState.chartDataResponse,
                chartDataFail: action.payload,
            };


        case GET_CHART_DATA_SILENT:
            return {
                ...state,
                isFetchingChartDataSilent: true,
            };

        case GET_CHART_DATA_SUCCESS_SILENT:
            return {
                ...state,
                isFetchingChartDataSilent: false,
                chartDataResponse: action.payload,
            };

        case GET_CHART_DATA_FAIL_SILENT:
            return {
                ...state,
                isFetchingChartDataSilent: false,
            };

        case RESET_SUPPLIER:
            return {
                ...state,
                ...defaultState,
            };
        default:
            return state;
    }
}
