export const COMUNI = [
  ['A001', 'PD', 'ABANO TERME'],
  ['A004', 'LO', 'ABBADIA CERRETO'],
  ['A005', 'LC', 'ABBADIA LARIANA'],
  ['A006', 'SI', 'ABBADIA SAN SALVATORE'],
  ['A007', 'OR', 'ABBASANTA'],
  ['A008', 'PE', 'ABBATEGGIO'],
  ['A010', 'MI', 'ABBIATEGRASSO'],
  ['A012', 'PT', 'ABETONE'],
  ['A013', 'PZ', 'ABRIOLA'],
  ['A014', 'RG', 'ACATE'],
  ['A015', 'FG', 'ACCADIA'],
  ['A016', 'CN', 'ACCEGLIO'],
  ['A017', 'MT', 'ACCETTURA'],
  ['A018', 'AQ', 'ACCIANO'],
  ['A019', 'RI', 'ACCUMOLI'],
  ['A020', 'PZ', 'ACERENZA'],
  ['A023', 'SA', 'ACERNO'],
  ['A024', 'NA', 'ACERRA'],
  ['A025', 'CT', 'ACI BONACCORSI'],
  ['A026', 'CT', 'ACI CASTELLO'],
  ['A027', 'CT', 'ACI CATENA'],
  ['A029', 'CT', 'ACI SANT\'ANTONIO'],
  ['A028', 'CT', 'ACIREALE'],
  ['A031', 'MC', 'ACQUACANINA'],
  ['A032', 'FR', 'ACQUAFONDATA'],
  ['A033', 'CS', 'ACQUAFORMOSA'],
  ['A034', 'BS', 'ACQUAFREDDA'],
  ['A035', 'PU', 'ACQUALAGNA'],
  ['A039', 'CR', 'ACQUANEGRA CREMONESE'],
  ['A038', 'MN', 'ACQUANEGRA SUL CHIESE'],
  ['A040', 'VT', 'ACQUAPENDENTE'],
  ['A041', 'CS', 'ACQUAPPESA'],
  ['A042', 'LE', 'ACQUARICA DEL CAPO'],
  ['A043', 'VV', 'ACQUARO'],
  ['A044', 'AP', 'ACQUASANTA TERME'],
  ['A045', 'TR', 'ACQUASPARTA'],
  ['A050', 'CB', 'ACQUAVIVA COLLECROCE'],
  ['A051', 'IS', 'ACQUAVIVA D\'ISERNIA'],
  ['A048', 'BA', 'ACQUAVIVA DELLE FONTI'],
  ['A047', 'AP', 'ACQUAVIVA PICENA'],
  ['A049', 'CL', 'ACQUAVIVA PLATANI'],
  ['M211', 'ME', 'ACQUEDOLCI'],
  ['A052', 'AL', 'ACQUI TERME'],
  ['A053', 'CS', 'ACRI'],
  ['A054', 'FR', 'ACUTO'],
  ['A055', 'BA', 'ADELFIA'],
  ['A056', 'CT', 'ADRANO'],
  ['A057', 'BG', 'ADRARA SAN MARTINO'],
  ['A058', 'BG', 'ADRARA SAN ROCCO'],
  ['A059', 'RO', 'ADRIA'],
  ['A060', 'BS', 'ADRO'],
  ['A061', 'VR', 'AFFI'],
  ['A062', 'RM', 'AFFILE'],
  ['Z200', 'EE', 'AFGHANISTAN'],
  ['A064', 'NA', 'AFRAGOLA'],
  ['A065', 'RC', 'AFRICO'],
  ['A067', 'PC', 'AGAZZANO'],
  ['A068', 'NA', 'AGEROLA'],
  ['A069', 'SS', 'AGGIUS'],
  ['A070', 'EN', 'AGIRA'],
  ['A071', 'PT', 'AGLIANA'],
  ['A072', 'AT', 'AGLIANO TERME'],
  ['A074', 'TO', 'AGLIE\''],
  ['H848', 'SS', 'AGLIENTU'],
  ['A075', 'PD', 'AGNA'],
  ['A076', 'CR', 'AGNADELLO'],
  ['A077', 'RC', 'AGNANA CALABRA'],
  ['A080', 'IS', 'AGNONE'],
  ['A082', 'BS', 'AGNOSINE'],
  ['A083', 'BL', 'AGORDO'],
  ['A084', 'RM', 'AGOSTA'],
  ['A085', 'VA', 'AGRA'],
  ['A087', 'MB', 'AGRATE BRIANZA'],
  ['A087', 'MI', 'AGRATE BRIANZA'],
  ['A088', 'NO', 'AGRATE CONTURBIA'],
  ['A089', 'AG', 'AGRIGENTO'],
  ['A091', 'SA', 'AGROPOLI'],
  ['A092', 'AN', 'AGUGLIANO'],
  ['A093', 'VI', 'AGUGLIARO'],
  ['A096', 'MB', 'AICURZIO'],
  ['A096', 'MI', 'AICURZIO'],
  ['A097', 'OR', 'AIDOMAGGIORE'],
  ['A098', 'EN', 'AIDONE'],
  ['A100', 'AQ', 'AIELLI'],
  ['A102', 'CS', 'AIELLO CALABRO'],
  ['A103', 'UD', 'AIELLO DEL FRIULI'],
  ['A101', 'AV', 'AIELLO DEL SABATO'],
  ['A105', 'CS', 'AIETA'],
  ['A106', 'CE', 'AILANO'],
  ['A107', 'BI', 'AILOCHE'],
  ['A109', 'TO', 'AIRASCA'],
  ['A110', 'BN', 'AIROLA'],
  ['A111', 'IM', 'AIROLE'],
  ['A112', 'LC', 'AIRUNO'],
  ['A113', 'CN', 'AISONE'],
  ['A116', 'TN', 'ALA'],
  ['A117', 'TO', 'ALA DI STURA'],
  ['A115', 'SS', 'ALA\' DEI SARDI'],
  ['A118', 'PV', 'ALAGNA'],
  ['A119', 'VC', 'ALAGNA VALSESIA'],
  ['A120', 'PE', 'ALANNO'],
  ['A121', 'BL', 'ALANO DI PIAVE'],
  ['A122', 'SV', 'ALASSIO'],
  ['A123', 'FR', 'ALATRI'],
  ['A124', 'CN', 'ALBA'],
  ['A125', 'TE', 'ALBA ADRIATICA'],
  ['A126', 'OR', 'ALBAGIARA'],
  ['A127', 'MI', 'ALBAIRATE'],
  ['A128', 'SA', 'ALBANELLA'],
  ['Z100', 'EE', 'ALBANIA'],
  ['Z105', 'EE', 'CECOSLOVACCHIA'],
  ['A131', 'PZ', 'ALBANO DI LUCANIA'],
  ['A132', 'RM', 'ALBANO LAZIALE'],
  ['A129', 'BG', 'ALBANO SANT\'ALESSANDRO'],
  ['A130', 'VC', 'ALBANO VERCELLESE'],
  ['A134', 'PV', 'ALBAREDO ARNABOLDI'],
  ['A137', 'VR', 'ALBAREDO D\'ADIGE'],
  ['A135', 'SO', 'ALBAREDO PER SAN MARCO'],
  ['A138', 'PR', 'ALBARETO'],
  ['A139', 'CN', 'ALBARETTO DELLA TORRE'],
  ['A143', 'CO', 'ALBAVILLA'],
  ['A145', 'SV', 'ALBENGA'],
  ['A146', 'AL', 'ALBERA LIGURE'],
  ['A149', 'BA', 'ALBEROBELLO'],
  ['A150', 'FG', 'ALBERONA'],
  ['A153', 'CO', 'ALBESE CON CASSANO'],
  ['A154', 'VI', 'ALBETTONE'],
  ['A155', 'CZ', 'ALBI'],
  ['A158', 'TN', 'ALBIANO'],
  ['A157', 'TO', 'ALBIANO D\'IVREA'],
  ['A159', 'MB', 'ALBIATE'],
  ['A159', 'MI', 'ALBIATE'],
  ['A160', 'CS', 'ALBIDONA'],
  ['A161', 'PD', 'ALBIGNASEGO'],
  ['A162', 'RE', 'ALBINEA'],
  ['A163', 'BG', 'ALBINO'],
  ['A164', 'CO', 'ALBIOLO'],
  ['A166', 'SV', 'ALBISOLA SUPERIORE'],
  ['A165', 'SV', 'ALBISSOLA MARINA'],
  ['A167', 'VA', 'ALBIZZATE'],
  ['A171', 'PV', 'ALBONESE'],
  ['A172', 'SO', 'ALBOSAGGIA'],
  ['A173', 'AT', 'ALBUGNANO'],
  ['A175', 'PV', 'ALBUZZANO'],
  ['A176', 'TP', 'ALCAMO'],
  ['A177', 'ME', 'ALCARA LI FUSI'],
  ['A178', 'TN', 'ALDENO'],
  ['A179', 'BZ', 'ALDINO'],
  ['A180', 'OR', 'ALES'],
  ['A182', 'AL', 'ALESSANDRIA'],
  ['A183', 'CS', 'ALESSANDRIA DEL CARRETTO'],
  ['A181', 'AG', 'ALESSANDRIA DELLA ROCCA'],
  ['A184', 'LE', 'ALESSANO'],
  ['A185', 'LE', 'ALEZIO'],
  ['A186', 'SA', 'ALFANO'],
  ['A187', 'AQ', 'ALFEDENA'],
  ['A188', 'BS', 'ALFIANELLO'],
  ['A189', 'AL', 'ALFIANO NATTA'],
  ['A191', 'RA', 'ALFONSINE'],
  ['Z301', 'EE', 'ALGERIA'],
  ['A192', 'SS', 'ALGHERO'],
  ['A193', 'BG', 'ALGUA'],
  ['A194', 'ME', 'ALI\''],
  ['A201', 'ME', 'ALI\' TERME'],
  ['A195', 'PA', 'ALIA'],
  ['A196', 'MT', 'ALIANO'],
  ['A197', 'AL', 'ALICE BEL COLLE'],
  ['A198', 'VC', 'ALICE CASTELLO'],
  ['A199', 'TO', 'ALICE SUPERIORE'],
  ['A200', 'CE', 'ALIFE'],
  ['A202', 'PA', 'ALIMENA'],
  ['A203', 'PA', 'ALIMINUSA'],
  ['A204', 'OR', 'ALLAI'],
  ['A206', 'BL', 'ALLEGHE'],
  ['A205', 'AO', 'ALLEIN'],
  ['A207', 'TR', 'ALLERONA'],
  ['A208', 'LE', 'ALLISTE'],
  ['A210', 'RM', 'ALLUMIERE'],
  ['A211', 'AL', 'ALLUVIONI CAMBIO\''],
  ['A214', 'BG', 'ALME\''],
  ['A216', 'BG', 'ALMENNO SAN BARTOLOMEO'],
  ['A217', 'BG', 'ALMENNO SAN SALVATORE'],
  ['A218', 'TO', 'ALMESE'],
  ['A220', 'VI', 'ALONTE'],
  ['A221', 'TO', 'ALPETTE'],
  ['A222', 'TO', 'ALPIGNANO'],
  ['A223', 'PC', 'ALSENO'],
  ['A224', 'CO', 'ALSERIO'],
  ['A225', 'BA', 'ALTAMURA'],
  ['A226', 'SV', 'ALTARE'],
  ['A228', 'AV', 'ALTAVILLA IRPINA'],
  ['A229', 'PA', 'ALTAVILLA MILICIA'],
  ['A227', 'AL', 'ALTAVILLA MONFERRATO'],
  ['A230', 'SA', 'ALTAVILLA SILENTINA'],
  ['A231', 'VI', 'ALTAVILLA VICENTINA'],
  ['A233', 'FM', 'ALTIDONA'],
  ['A233', 'AP', 'ALTIDONA'],
  ['A234', 'CS', 'ALTILIA'],
  ['A235', 'CH', 'ALTINO'],
  ['A236', 'VI', 'ALTISSIMO'],
  ['A237', 'TV', 'ALTIVOLE'],
  ['A238', 'CN', 'ALTO'],
  ['A239', 'PA', 'ALTOFONTE'],
  ['A240', 'CS', 'ALTOMONTE'],
  ['A241', 'LU', 'ALTOPASCIO'],
  ['A242', 'TR', 'ALVIANO'],
  ['A243', 'CE', 'ALVIGNANO'],
  ['A244', 'FR', 'ALVITO'],
  ['A246', 'BG', 'ALZANO LOMBARDO'],
  ['A245', 'AL', 'ALZANO SCRIVIA'],
  ['A249', 'CO', 'ALZATE BRIANZA'],
  ['A251', 'SA', 'AMALFI'],
  ['A252', 'FM', 'AMANDOLA'],
  ['A252', 'AP', 'AMANDOLA'],
  ['A253', 'CS', 'AMANTEA'],
  ['A254', 'UD', 'AMARO'],
  ['A255', 'CZ', 'AMARONI'],
  ['A256', 'FR', 'AMASENO'],
  ['A257', 'CZ', 'AMATO'],
  ['A258', 'RI', 'AMATRICE'],
  ['A259', 'BG', 'AMBIVERE'],
  ['A260', 'TN', 'AMBLAR'],
  ['A261', 'SP', 'AMEGLIA'],
  ['A262', 'TR', 'AMELIA'],
  ['A263', 'CS', 'AMENDOLARA'],
  ['A264', 'NO', 'AMENO'],
  ['A265', 'BN', 'AMOROSI'],
  ['A267', 'UD', 'AMPEZZO'],
  ['A268', 'NA', 'ANACAPRI'],
  ['A269', 'FR', 'ANAGNI'],
  ['A270', 'TE', 'ANCARANO'],
  ['A271', 'AN', 'ANCONA'],
  ['A272', 'CZ', 'ANDALI'],
  ['A274', 'TN', 'ANDALO'],
  ['A273', 'SO', 'ANDALO VALTELLINO'],
  ['A275', 'TO', 'ANDEZENO'],
  ['A278', 'SV', 'ANDORA'],
  ['A280', 'BI', 'ANDORNO MICCA'],
  ['Z101', 'EE', 'ANDORRA'],
  ['A281', 'LE', 'ANDRANO'],
  ['A282', 'TO', 'ANDRATE'],
  ['A283', 'PN', 'ANDREIS'],
  ['A284', 'AV', 'ANDRETTA'],
  ['A285', 'BA', 'ANDRIA'],
  ['A285', 'BT', 'ANDRIA'],
  ['A286', 'BZ', 'ANDRIANO'],
  ['A287', 'SS', 'ANELA'],
  ['A288', 'BS', 'ANFO'],
  ['A290', 'VA', 'ANGERA'],
  ['A291', 'AR', 'ANGHIARI'],
  ['A292', 'VR', 'ANGIARI'],
  ['Z302', 'EE', 'ANGOLA'],
  ['A293', 'BS', 'ANGOLO TERME'],
  ['A294', 'SA', 'ANGRI'],
  ['A295', 'TO', 'ANGROGNA'],
  ['Z529', 'EE', 'ANGUILLA (ISOLA)'],
  ['A297', 'RM', 'ANGUILLARA SABAZIA'],
  ['A296', 'PD', 'ANGUILLARA VENETA'],
  ['A299', 'CR', 'ANNICCO'],
  ['A301', 'LC', 'ANNONE DI BRIANZA'],
  ['A302', 'VE', 'ANNONE VENETO'],
  ['A303', 'RC', 'ANOIA'],
  ['A304', 'BG', 'ANTEGNATE'],
  ['A306', 'BZ', 'ANTERIVO'],
  ['A305', 'AO', 'ANTEY-SAINT-ANDRE\''],
  ['A309', 'RM', 'ANTICOLI CORRADO'],
  ['A312', 'AT', 'ANTIGNANO'],
  ['Z532', 'EE', 'ANTIGUA E BARBUDA'],
  ['Z501', 'EE', 'ANTILLE OLANDESI'],
  ['A313', 'ME', 'ANTILLO'],
  ['A314', 'RC', 'ANTONIMINA'],
  ['A315', 'RI', 'ANTRODOCO'],
  ['A317', 'VB', 'ANTRONA SCHIERANCO'],
  ['A318', 'AQ', 'ANVERSA DEGLI ABRUZZI'],
  ['A319', 'CO', 'ANZANO DEL PARCO'],
  ['A320', 'FG', 'ANZANO DI PUGLIA'],
  ['A321', 'PZ', 'ANZI'],
  ['A323', 'RM', 'ANZIO'],
  ['A325', 'VB', 'ANZOLA D\'OSSOLA'],
  ['A324', 'BO', 'ANZOLA DELL\'EMILIA'],
  ['A326', 'AO', 'AOSTA'],
  ['A327', 'PU', 'APECCHIO'],
  ['A328', 'BN', 'APICE'],
  ['A329', 'MC', 'APIRO'],
  ['A330', 'BN', 'APOLLOSA'],
  ['A333', 'CO', 'APPIANO GENTILE'],
  ['A332', 'BZ', 'APPIANO SULLA STRADA DEL VINO'],
  ['A334', 'MC', 'APPIGNANO'],
  ['A335', 'AP', 'APPIGNANO DEL TRONTO'],
  ['A337', 'SO', 'APRICA'],
  ['A338', 'IM', 'APRICALE'],
  ['A339', 'FG', 'APRICENA'],
  ['A340', 'CS', 'APRIGLIANO'],
  ['A341', 'LT', 'APRILIA'],
  ['A343', 'SA', 'AQUARA'],
  ['A344', 'IM', 'AQUILA D\'ARROSCIA'],
  ['A346', 'UD', 'AQUILEIA'],
  ['A347', 'AV', 'AQUILONIA'],
  ['A348', 'FR', 'AQUINO'],
  ['Z203', 'EE', 'ARABIA SAUDITA'],
  ['A350', 'LE', 'ARADEO'],
  ['A351', 'AG', 'ARAGONA'],
  ['A352', 'AT', 'ARAMENGO'],
  ['A354', 'PN', 'ARBA'],
  ['A357', 'OR', 'ARBOREA'],
  ['A358', 'VC', 'ARBORIO'],
  ['A359', 'CA', 'ARBUS'],
  ['A360', 'TV', 'ARCADE'],
  ['A363', 'FR', 'ARCE'],
  ['A365', 'BG', 'ARCENE'],
  ['A366', 'AN', 'ARCEVIA'],
  ['A367', 'CH', 'ARCHI'],
  ['A369', 'GR', 'ARCIDOSSO'],
  ['A370', 'RM', 'ARCINAZZO ROMANO'],
  ['A371', 'VA', 'ARCISATE'],
  ['A372', 'TN', 'ARCO'],
  ['A373', 'SP', 'ARCOLA'],
  ['A374', 'VR', 'ARCOLE'],
  ['A375', 'MI', 'ARCONATE'],
  ['A376', 'MB', 'ARCORE'],
  ['A376', 'MI', 'ARCORE'],
  ['A377', 'VI', 'ARCUGNANO'],
  ['A379', 'SS', 'ARDARA'],
  ['A380', 'OR', 'ARDAULI'],
  ['M213', 'RM', 'ARDEA'],
  ['A382', 'SO', 'ARDENNO'],
  ['A383', 'BG', 'ARDESIO'],
  ['A385', 'RC', 'ARDORE'],
  ['A386', 'VV', 'ARENA'],
  ['A387', 'PV', 'ARENA PO'],
  ['A388', 'GE', 'ARENZANO'],
  ['A389', 'MI', 'ARESE'],
  ['A390', 'AR', 'AREZZO'],
  ['A391', 'CO', 'ARGEGNO'],
  ['A392', 'BO', 'ARGELATO'],
  ['A393', 'FE', 'ARGENTA'],
  ['A394', 'CN', 'ARGENTERA'],
  ['Z600', 'EE', 'ARGENTINA'],
  ['A396', 'CN', 'ARGUELLO'],
  ['A397', 'CZ', 'ARGUSTO'],
  ['A398', 'CH', 'ARI'],
  ['A399', 'AV', 'ARIANO IRPINO'],
  ['A400', 'RO', 'ARIANO NEL POLESINE'],
  ['A401', 'RM', 'ARICCIA'],
  ['A402', 'CH', 'ARIELLI'],
  ['A403', 'CE', 'ARIENZO'],
  ['A405', 'TO', 'ARIGNANO'],
  ['A407', 'NU', 'ARITZO'],
  ['A409', 'VB', 'ARIZZANO'],
  ['A412', 'VT', 'ARLENA DI CASTRO'],
  ['A413', 'MI', 'ARLUNO'],
  ['Z252', 'EE', 'ARMENIA'],
  ['A414', 'NO', 'ARMENO'],
  ['A415', 'PZ', 'ARMENTO'],
  ['A418', 'IM', 'ARMO'],
  ['A419', 'CA', 'ARMUNGIA'],
  ['A424', 'AO', 'ARNAD'],
  ['A421', 'FR', 'ARNARA'],
  ['A422', 'SV', 'ARNASCO'],
  ['A425', 'LE', 'ARNESANO'],
  ['A427', 'VB', 'AROLA'],
  ['A429', 'NO', 'ARONA'],
  ['A430', 'CO', 'AROSIO'],
  ['A431', 'BN', 'ARPAIA'],
  ['A432', 'BN', 'ARPAISE'],
  ['A433', 'FR', 'ARPINO'],
  ['A434', 'PD', 'ARQUA\' PETRARCA'],
  ['A435', 'RO', 'ARQUA\' POLESINE'],
  ['A437', 'AP', 'ARQUATA DEL TRONTO'],
  ['A436', 'AL', 'ARQUATA SCRIVIA'],
  ['A438', 'PD', 'ARRE'],
  ['A439', 'TR', 'ARRONE'],
  ['A441', 'VA', 'ARSAGO SEPRIO'],
  ['A443', 'BL', 'ARSIE\''],
  ['A444', 'VI', 'ARSIERO'],
  ['A445', 'TE', 'ARSITA'],
  ['A446', 'RM', 'ARSOLI'],
  ['A447', 'UD', 'ARTA TERME'],
  ['A448', 'UD', 'ARTEGNA'],
  ['A449', 'RM', 'ARTENA'],
  ['A451', 'BS', 'ARTOGNE'],
  ['A452', 'AO', 'ARVIER'],
  ['A453', 'SS', 'ARZACHENA'],
  ['A440', 'BG', 'ARZAGO D\'ADDA'],
  ['A454', 'NU', 'ARZANA'],
  ['A455', 'NA', 'ARZANO'],
  ['A456', 'PN', 'ARZENE'],
  ['A458', 'PD', 'ARZERGRANDE'],
  ['A459', 'VI', 'ARZIGNANO'],
  ['A460', 'SA', 'ASCEA'],
  ['A461', 'SI', 'ASCIANO'],
  ['A462', 'AP', 'ASCOLI PICENO'],
  ['A463', 'FG', 'ASCOLI SATRIANO'],
  ['A464', 'RI', 'ASCREA'],
  ['A465', 'VI', 'ASIAGO'],
  ['A467', 'VI', 'ASIGLIANO VENETO'],
  ['A466', 'VC', 'ASIGLIANO VERCELLESE'],
  ['A470', 'MN', 'ASOLA'],
  ['A471', 'TV', 'ASOLO'],
  ['A473', 'MI', 'ASSAGO'],
  ['A474', 'CA', 'ASSEMINI'],
  ['A475', 'PG', 'ASSISI'],
  ['A476', 'CO', 'ASSO'],
  ['A477', 'OR', 'ASSOLO'],
  ['A478', 'EN', 'ASSORO'],
  ['A479', 'AT', 'ASTI'],
  ['A480', 'OR', 'ASUNI'],
  ['A481', 'AQ', 'ATELETA'],
  ['A482', 'PZ', 'ATELLA'],
  ['A484', 'SA', 'ATENA LUCANA'],
  ['A485', 'CH', 'ATESSA'],
  ['A486', 'FR', 'ATINA'],
  ['A487', 'SA', 'ATRANI'],
  ['A488', 'TE', 'ATRI'],
  ['A489', 'AV', 'ATRIPALDA'],
  ['A490', 'TR', 'ATTIGLIANO'],
  ['A491', 'UD', 'ATTIMIS'],
  ['A492', 'NU', 'ATZARA'],
  ['A493', 'PU', 'AUDITORE'],
  ['A494', 'SR', 'AUGUSTA'],
  ['A495', 'SA', 'AULETTA'],
  ['A496', 'MS', 'AULLA'],
  ['A497', 'VB', 'AURANO'],
  ['A499', 'IM', 'AURIGO'],
  ['A501', 'BL', 'AURONZO DI CADORE'],
  ['A502', 'FR', 'AUSONIA'],
  ['A503', 'NU', 'AUSTIS'],
  ['Z700', 'EE', 'AUSTRALIA'],
  ['Z102', 'EE', 'AUSTRIA'],
  ['A506', 'GE', 'AVEGNO'],
  ['A507', 'BZ', 'AVELENGO'],
  ['A508', 'AV', 'AVELLA'],
  ['A509', 'AV', 'AVELLINO'],
  ['A511', 'BG', 'AVERARA'],
  ['A512', 'CE', 'AVERSA'],
  ['A514', 'TA', 'AVETRANA'],
  ['A515', 'AQ', 'AVEZZANO'],
  ['A516', 'PN', 'AVIANO'],
  ['A517', 'BG', 'AVIATICO'],
  ['A518', 'TO', 'AVIGLIANA'],
  ['A519', 'PZ', 'AVIGLIANO'],
  ['M258', 'TR', 'AVIGLIANO UMBRO'],
  ['A520', 'TN', 'AVIO'],
  ['A521', 'AO', 'AVISE'],
  ['A522', 'SR', 'AVOLA'],
  ['A523', 'AL', 'AVOLASCA'],
  ['A094', 'AO', 'AYAS'],
  ['A108', 'AO', 'AYMAVILLES'],
  ['A525', 'TO', 'AZEGLIO'],
  ['Z253', 'EE', 'AZERBAIGIAN'],
  ['A526', 'CR', 'AZZANELLO'],
  ['A527', 'AT', 'AZZANO D\'ASTI'],
  ['A530', 'PN', 'AZZANO DECIMO'],
  ['A529', 'BS', 'AZZANO MELLA'],
  ['A528', 'BG', 'AZZANO SAN PAOLO'],
  ['A531', 'VA', 'AZZATE'],
  ['A532', 'VA', 'AZZIO'],
  ['A533', 'BG', 'AZZONE'],
  ['A534', 'VB', 'BACENO'],
  ['A535', 'NA', 'BACOLI'],
  ['A536', 'IM', 'BADALUCCO'],
  ['M214', 'SS', 'BADESI'],
  ['A537', 'BZ', 'BADIA'],
  ['A540', 'VR', 'BADIA CALAVENA'],
  ['A538', 'PV', 'BADIA PAVESE'],
  ['A539', 'RO', 'BADIA POLESINE'],
  ['A541', 'AR', 'BADIA TEDALDA'],
  ['A542', 'CZ', 'BADOLATO'],
  ['A544', 'RC', 'BAGALADI'],
  ['A546', 'PA', 'BAGHERIA'],
  ['A547', 'RA', 'BAGNACAVALLO'],
  ['A552', 'RC', 'BAGNARA CALABRA'],
  ['A551', 'RA', 'BAGNARA DI ROMAGNA'],
  ['A550', 'PV', 'BAGNARIA'],
  ['A553', 'UD', 'BAGNARIA ARSA'],
  ['A555', 'CN', 'BAGNASCO'],
  ['A557', 'BG', 'BAGNATICA'],
  ['A560', 'LU', 'BAGNI DI LUCCA'],
  ['A564', 'FI', 'BAGNO A RIPOLI'],
  ['A565', 'FC', 'BAGNO DI ROMAGNA'],
  ['A567', 'IS', 'BAGNOLI DEL TRIGNO'],
  ['A568', 'PD', 'BAGNOLI DI SOPRA'],
  ['A566', 'AV', 'BAGNOLI IRPINO'],
  ['A570', 'CR', 'BAGNOLO CREMASCO'],
  ['A572', 'LE', 'BAGNOLO DEL SALENTO'],
  ['A574', 'RO', 'BAGNOLO DI PO'],
  ['A573', 'RE', 'BAGNOLO IN PIANO'],
  ['A569', 'BS', 'BAGNOLO MELLA'],
  ['A571', 'CN', 'BAGNOLO PIEMONTE'],
  ['A575', 'MN', 'BAGNOLO SAN VITO'],
  ['A576', 'MS', 'BAGNONE'],
  ['A577', 'VT', 'BAGNOREGIO'],
  ['A578', 'BS', 'BAGOLINO'],
  ['Z502', 'EE', 'BAHAMAS'],
  ['Z204', 'EE', 'BAHREIN'],
  ['A579', 'CE', 'BAIA E LATINA'],
  ['A580', 'AV', 'BAIANO'],
  ['A581', 'IM', 'BAIARDO'],
  ['A584', 'TO', 'BAIRO'],
  ['A586', 'RE', 'BAISO'],
  ['A587', 'TO', 'BALANGERO'],
  ['A588', 'AT', 'BALDICHIERI D\'ASTI'],
  ['A590', 'TO', 'BALDISSERO CANAVESE'],
  ['A589', 'CN', 'BALDISSERO D\'ALBA'],
  ['A591', 'TO', 'BALDISSERO TORINESE'],
  ['A592', 'PA', 'BALESTRATE'],
  ['A593', 'SV', 'BALESTRINO'],
  ['A594', 'LC', 'BALLABIO'],
  ['A597', 'CA', 'BALLAO'],
  ['A599', 'TO', 'BALME'],
  ['A600', 'VC', 'BALMUCCIA'],
  ['A601', 'VC', 'BALOCCO'],
  ['A603', 'AQ', 'BALSORANO'],
  ['A604', 'PZ', 'BALVANO'],
  ['A605', 'AL', 'BALZOLA'],
  ['A606', 'SS', 'BANARI'],
  ['A607', 'TO', 'BANCHETTE'],
  ['Z249', 'EE', 'BANGLADESH'],
  ['A610', 'VB', 'BANNIO ANZINO'],
  ['A612', 'PZ', 'BANZI'],
  ['A613', 'PD', 'BAONE'],
  ['A614', 'OR', 'BARADILI'],
  ['A615', 'PZ', 'BARAGIANO'],
  ['A616', 'CB', 'BARANELLO'],
  ['A617', 'NA', 'BARANO D\'ISCHIA'],
  ['A618', 'MI', 'BARANZATE'],
  ['A619', 'VA', 'BARASSO'],
  ['A621', 'OR', 'BARATILI SAN PIETRO'],
  ['Z522', 'EE', 'BARBADOS'],
  ['A625', 'TO', 'BARBANIA'],
  ['A626', 'AN', 'BARBARA'],
  ['A628', 'VT', 'BARBARANO ROMANO'],
  ['A627', 'VI', 'BARBARANO VICENTINO'],
  ['A629', 'CN', 'BARBARESCO'],
  ['A630', 'BS', 'BARBARIGA'],
  ['A631', 'BG', 'BARBATA'],
  ['A632', 'FI', 'BARBERINO DI MUGELLO'],
  ['A633', 'FI', 'BARBERINO VAL D\'ELSA'],
  ['A634', 'PV', 'BARBIANELLO'],
  ['A635', 'BZ', 'BARBIANO'],
  ['A637', 'PD', 'BARBONA'],
  ['A638', 'ME', 'BARCELLONA POZZO DI GOTTO'],
  ['A639', 'PU', 'BARCHI'],
  ['A640', 'PN', 'BARCIS'],
  ['A643', 'AO', 'BARD'],
  ['A645', 'VA', 'BARDELLO'],
  ['A646', 'PR', 'BARDI'],
  ['A647', 'SV', 'BARDINETO'],
  ['A650', 'VR', 'BARDOLINO'],
  ['A651', 'TO', 'BARDONECCHIA'],
  ['A652', 'MI', 'BAREGGIO'],
  ['A653', 'NO', 'BARENGO'],
  ['A655', 'OR', 'BARESSA'],
  ['A656', 'AQ', 'BARETE'],
  ['A657', 'LU', 'BARGA'],
  ['A658', 'GE', 'BARGAGLI'],
  ['A660', 'CN', 'BARGE'],
  ['A661', 'BS', 'BARGHE'],
  ['A662', 'BA', 'BARI'],
  ['A663', 'NU', 'BARI SARDO'],
  ['A664', 'BG', 'BARIANO'],
  ['A665', 'BO', 'BARICELLA'],
  ['A666', 'PZ', 'BARILE'],
  ['A667', 'AQ', 'BARISCIANO'],
  ['A668', 'MB', 'BARLASSINA'],
  ['A668', 'MI', 'BARLASSINA'],
  ['A669', 'BA', 'BARLETTA'],
  ['A669', 'BT', 'BARLETTA'],
  ['A670', 'CO', 'BARNI'],
  ['A671', 'CN', 'BAROLO'],
  ['A673', 'TO', 'BARONE CANAVESE'],
  ['A674', 'SA', 'BARONISSI'],
  ['A676', 'EN', 'BARRAFRANCA'],
  ['A677', 'CA', 'BARRALI'],
  ['A678', 'AQ', 'BARREA'],
  ['A681', 'CA', 'BARUMINI'],
  ['A683', 'LC', 'BARZAGO'],
  ['A684', 'BG', 'BARZANA'],
  ['A686', 'LC', 'BARZANO\''],
  ['A687', 'LC', 'BARZIO'],
  ['A689', 'AL', 'BASALUZZO'],
  ['A690', 'PV', 'BASCAPE\''],
  ['A691', 'TR', 'BASCHI'],
  ['A692', 'TE', 'BASCIANO'],
  ['A694', 'TN', 'BASELGA DI PINE\''],
  ['A696', 'BN', 'BASELICE'],
  ['A697', 'MI', 'BASIANO'],
  ['A698', 'ME', 'BASICO\''],
  ['A699', 'MI', 'BASIGLIO'],
  ['A700', 'UD', 'BASILIANO'],
  ['A702', 'BS', 'BASSANO BRESCIANO'],
  ['A703', 'VI', 'BASSANO DEL GRAPPA'],
  ['A706', 'VT', 'BASSANO IN TEVERINA'],
  ['A704', 'VT', 'BASSANO ROMANO'],
  ['A707', 'LT', 'BASSIANO'],
  ['A708', 'AL', 'BASSIGNANA'],
  ['A709', 'CN', 'BASTIA MONDOVI\''],
  ['A710', 'PG', 'BASTIA UMBRA'],
  ['A711', 'PV', 'BASTIDA DE\' DOSSI'],
  ['A712', 'PV', 'BASTIDA PANCARANA'],
  ['A713', 'MO', 'BASTIGLIA'],
  ['A714', 'PD', 'BATTAGLIA TERME'],
  ['A716', 'CN', 'BATTIFOLLO'],
  ['A717', 'SA', 'BATTIPAGLIA'],
  ['A718', 'PV', 'BATTUDA'],
  ['A719', 'PA', 'BAUCINA'],
  ['A721', 'OR', 'BAULADU'],
  ['A722', 'NU', 'BAUNEI'],
  ['A725', 'VB', 'BAVENO'],
  ['A726', 'BO', 'BAZZANO'],
  ['A728', 'VA', 'BEDERO VALCUVIA'],
  ['A729', 'BS', 'BEDIZZOLE'],
  ['A730', 'TN', 'BEDOLLO'],
  ['A731', 'PR', 'BEDONIA'],
  ['A732', 'BG', 'BEDULITA'],
  ['A733', 'VB', 'BEE'],
  ['A734', 'TO', 'BEINASCO'],
  ['A735', 'CN', 'BEINETTE'],
  ['A736', 'CZ', 'BELCASTRO'],
  ['A737', 'VR', 'BELFIORE'],
  ['A740', 'PU', 'BELFORTE ALL\'ISAURO'],
  ['A739', 'MC', 'BELFORTE DEL CHIENTI'],
  ['A738', 'AL', 'BELFORTE MONFERRATO'],
  ['Z103', 'EE', 'BELGIO'],
  ['A741', 'PV', 'BELGIOIOSO'],
  ['A742', 'VB', 'BELGIRATE'],
  ['Z512', 'EE', 'BELIZE'],
  ['A743', 'PZ', 'BELLA'],
  ['A744', 'CO', 'BELLAGIO'],
  ['A745', 'LC', 'BELLANO'],
  ['A746', 'TE', 'BELLANTE'],
  ['A747', 'RN', 'BELLARIA-IGEA MARINA'],
  ['A749', 'RM', 'BELLEGRA'],
  ['A750', 'CN', 'BELLINO'],
  ['A751', 'MI', 'BELLINZAGO LOMBARDO'],
  ['A752', 'NO', 'BELLINZAGO NOVARESE'],
  ['M294', 'SA', 'BELLIZZI'],
  ['A755', 'CE', 'BELLONA'],
  ['A756', 'SA', 'BELLOSGUARDO'],
  ['A757', 'BL', 'BELLUNO'],
  ['A759', 'MB', 'BELLUSCO'],
  ['A759', 'MI', 'BELLUSCO'],
  ['A762', 'CS', 'BELMONTE CALABRO'],
  ['A763', 'FR', 'BELMONTE CASTELLO'],
  ['A761', 'IS', 'BELMONTE DEL SANNIO'],
  ['A765', 'RI', 'BELMONTE IN SABINA'],
  ['A764', 'PA', 'BELMONTE MEZZAGNO'],
  ['A760', 'FM', 'BELMONTE PICENO'],
  ['A760', 'AP', 'BELMONTE PICENO'],
  ['A766', 'CT', 'BELPASSO'],
  ['A768', 'CS', 'BELSITO'],
  ['A772', 'KR', 'BELVEDERE DI SPINELLO'],
  ['A774', 'CN', 'BELVEDERE LANGHE'],
  ['A773', 'CS', 'BELVEDERE MARITTIMO'],
  ['A769', 'AN', 'BELVEDERE OSTRENSE'],
  ['A770', 'AT', 'BELVEGLIO'],
  ['A776', 'NU', 'BELVI'],
  ['A777', 'SO', 'BEMA'],
  ['A778', 'CO', 'BENE LARIO'],
  ['A779', 'CN', 'BENE VAGIENNA'],
  ['A780', 'RC', 'BENESTARE'],
  ['A781', 'SS', 'BENETUTTI'],
  ['A782', 'CN', 'BENEVELLO'],
  ['A783', 'BN', 'BENEVENTO'],
  ['Z314', 'EE', 'BENIN'],
  ['A784', 'BI', 'BENNA'],
  ['A785', 'BO', 'BENTIVOGLIO'],
  ['A786', 'BG', 'BERBENNO'],
  ['A787', 'SO', 'BERBENNO DI VALTELLINA'],
  ['A788', 'PR', 'BERCETO'],
  ['A789', 'SS', 'BERCHIDDA'],
  ['A791', 'CO', 'BEREGAZZO CON FIGLIARO'],
  ['A792', 'PV', 'BEREGUARDO'],
  ['A793', 'AL', 'BERGAMASCO'],
  ['A794', 'BG', 'BERGAMO'],
  ['A795', 'RO', 'BERGANTINO'],
  ['A796', 'SV', 'BERGEGGI'],
  ['A798', 'CN', 'BERGOLO'],
  ['A799', 'BS', 'BERLINGO'],
  ['Z400', 'EE', 'BERMUDA (ISOLE)'],
  ['A801', 'MT', 'BERNALDA'],
  ['A802', 'MB', 'BERNAREGGIO'],
  ['A802', 'MI', 'BERNAREGGIO'],
  ['A804', 'MI', 'BERNATE TICINO'],
  ['A805', 'CN', 'BERNEZZO'],
  ['A806', 'FE', 'BERRA'],
  ['A808', 'TN', 'BERSONE'],
  ['A809', 'FC', 'BERTINORO'],
  ['A810', 'UD', 'BERTIOLO'],
  ['A811', 'LO', 'BERTONICO'],
  ['A812', 'AT', 'BERZANO DI SAN PIETRO'],
  ['A813', 'AL', 'BERZANO DI TORTONA'],
  ['A816', 'BS', 'BERZO DEMO'],
  ['A817', 'BS', 'BERZO INFERIORE'],
  ['A815', 'BG', 'BERZO SAN FERMO'],
  ['A818', 'MB', 'BESANA IN BRIANZA'],
  ['A818', 'MI', 'BESANA IN BRIANZA'],
  ['A819', 'VA', 'BESANO'],
  ['A820', 'MI', 'BESATE'],
  ['A821', 'TN', 'BESENELLO'],
  ['A823', 'PC', 'BESENZONE'],
  ['A825', 'VA', 'BESNATE'],
  ['A826', 'VA', 'BESOZZO'],
  ['A827', 'SS', 'BESSUDE'],
  ['A831', 'PC', 'BETTOLA'],
  ['A832', 'PG', 'BETTONA'],
  ['A834', 'VB', 'BEURA-CARDEZZA'],
  ['A835', 'PG', 'BEVAGNA'],
  ['A836', 'SP', 'BEVERINO'],
  ['A837', 'VR', 'BEVILACQUA'],
  ['A839', 'TN', 'BEZZECCA'],
  ['Z205', 'EE', 'BHUTAN'],
  ['A841', 'CT', 'BIANCAVILLA'],
  ['A842', 'CS', 'BIANCHI'],
  ['A843', 'RC', 'BIANCO'],
  ['A844', 'NO', 'BIANDRATE'],
  ['A845', 'VA', 'BIANDRONNO'],
  ['A846', 'BG', 'BIANZANO'],
  ['A847', 'VC', 'BIANZE\''],
  ['A848', 'SO', 'BIANZONE'],
  ['A849', 'MB', 'BIASSONO'],
  ['A849', 'MI', 'BIASSONO'],
  ['A850', 'RE', 'BIBBIANO'],
  ['A851', 'AR', 'BIBBIENA'],
  ['A852', 'LI', 'BIBBONA'],
  ['A853', 'TO', 'BIBIANA'],
  ['A854', 'FG', 'BICCARI'],
  ['A855', 'UD', 'BICINICCO'],
  ['A856', 'OR', 'BIDONI\''],
  ['A859', 'BI', 'BIELLA'],
  ['Z139', 'EE', 'BIELORUSSIA'],
  ['A861', 'BS', 'BIENNO'],
  ['A863', 'TN', 'BIENO'],
  ['A864', 'PI', 'BIENTINA'],
  ['A866', 'MN', 'BIGARELLO'],
  ['A870', 'CO', 'BINAGO'],
  ['A872', 'MI', 'BINASCO'],
  ['A874', 'BA', 'BINETTO'],
  ['A876', 'BI', 'BIOGLIO'],
  ['A877', 'AO', 'BIONAZ'],
  ['A878', 'BS', 'BIONE'],
  ['A880', 'NU', 'BIRORI'],
  ['A881', 'AV', 'BISACCIA'],
  ['A882', 'PA', 'BISACQUINO'],
  ['A883', 'BA', 'BISCEGLIE'],
  ['A883', 'BT', 'BISCEGLIE'],
  ['A884', 'AQ', 'BISEGNA'],
  ['A885', 'TE', 'BISENTI'],
  ['A887', 'CS', 'BISIGNANO'],
  ['A889', 'AL', 'BISTAGNO'],
  ['A891', 'VA', 'BISUSCHIO'],
  ['A892', 'BA', 'BITETTO'],
  ['A893', 'BA', 'BITONTO'],
  ['A894', 'BA', 'BITRITTO'],
  ['A895', 'NU', 'BITTI'],
  ['A896', 'AG', 'BIVONA'],
  ['A897', 'RC', 'BIVONGI'],
  ['A898', 'CO', 'BIZZARONE'],
  ['A901', 'TN', 'BLEGGIO INFERIORE'],
  ['A902', 'TN', 'BLEGGIO SUPERIORE'],
  ['A903', 'BG', 'BLELLO'],
  ['A857', 'VT', 'BLERA'],
  ['A904', 'CO', 'BLESSAGNO'],
  ['A905', 'CO', 'BLEVIO'],
  ['M268', 'PA', 'BLUFI'],
  ['A906', 'PD', 'BOARA PISANI'],
  ['A909', 'PC', 'BOBBIO'],
  ['A910', 'TO', 'BOBBIO PELLICE'],
  ['A911', 'NO', 'BOCA'],
  ['A912', 'CS', 'BOCCHIGLIERO'],
  ['A914', 'VC', 'BOCCIOLETO'],
  ['A916', 'TN', 'BOCENAGO'],
  ['A918', 'VA', 'BODIO LOMNAGO'],
  ['A919', 'LO', 'BOFFALORA D\'ADDA'],
  ['A920', 'MI', 'BOFFALORA SOPRA TICINO'],
  ['A922', 'GE', 'BOGLIASCO'],
  ['A925', 'VB', 'BOGNANCO'],
  ['A929', 'NO', 'BOGOGNO'],
  ['A930', 'CB', 'BOIANO'],
  ['A931', 'SV', 'BOISSANO'],
  ['A932', 'SP', 'BOLANO'],
  ['A933', 'TN', 'BOLBENO'],
  ['A937', 'BG', 'BOLGARE'],
  ['Z601', 'EE', 'BOLIVIA'],
  ['A940', 'MI', 'BOLLATE'],
  ['A941', 'TO', 'BOLLENGO'],
  ['A944', 'BO', 'BOLOGNA'],
  ['A945', 'PE', 'BOLOGNANO'],
  ['A946', 'PA', 'BOLOGNETTA'],
  ['A947', 'MC', 'BOLOGNOLA'],
  ['A948', 'NU', 'BOLOTANA'],
  ['A949', 'VT', 'BOLSENA'],
  ['A950', 'BG', 'BOLTIERE'],
  ['A952', 'BZ', 'BOLZANO'],
  ['A953', 'NO', 'BOLZANO NOVARESE'],
  ['A954', 'VI', 'BOLZANO VICENTINO'],
  ['A955', 'VT', 'BOMARZO'],
  ['A956', 'CH', 'BOMBA'],
  ['A957', 'CL', 'BOMPENSIERE'],
  ['A958', 'PA', 'BOMPIETRO'],
  ['A959', 'MO', 'BOMPORTO'],
  ['A960', 'OR', 'BONARCADO'],
  ['A961', 'SP', 'BONASSOLA'],
  ['A963', 'BG', 'BONATE SOPRA'],
  ['A962', 'BG', 'BONATE SOTTO'],
  ['A964', 'VR', 'BONAVIGO'],
  ['A965', 'FE', 'BONDENO'],
  ['A967', 'TN', 'BONDO'],
  ['A968', 'TN', 'BONDONE'],
  ['A970', 'BN', 'BONEA'],
  ['A971', 'CB', 'BONEFRO'],
  ['A972', 'CR', 'BONEMERSE'],
  ['A973', 'CS', 'BONIFATI'],
  ['A975', 'AV', 'BONITO'],
  ['A976', 'SS', 'BONNANARO'],
  ['A977', 'SS', 'BONO'],
  ['A978', 'SS', 'BONORVA'],
  ['A979', 'CN', 'BONVICINO'],
  ['A981', 'RI', 'BORBONA'],
  ['A982', 'BL', 'BORCA DI CADORE'],
  ['A983', 'UD', 'BORDANO'],
  ['A984', 'IM', 'BORDIGHERA'],
  ['A986', 'CR', 'BORDOLANO'],
  ['A987', 'PR', 'BORE'],
  ['A988', 'RE', 'BORETTO'],
  ['A989', 'PV', 'BORGARELLO'],
  ['A990', 'TO', 'BORGARO TORINESE'],
  ['A991', 'PA', 'BORGETTO'],
  ['A993', 'IM', 'BORGHETTO D\'ARROSCIA'],
  ['A998', 'AL', 'BORGHETTO DI BORBERA'],
  ['A992', 'SP', 'BORGHETTO DI VARA'],
  ['A995', 'LO', 'BORGHETTO LODIGIANO'],
  ['A999', 'SV', 'BORGHETTO SANTO SPIRITO'],
  ['B001', 'FC', 'BORGHI'],
  ['B002', 'CZ', 'BORGIA'],
  ['B003', 'TO', 'BORGIALLO'],
  ['B005', 'SV', 'BORGIO VEREZZI'],
  ['B007', 'LU', 'BORGO A MOZZANO'],
  ['B009', 'VC', 'BORGO D\'ALE'],
  ['B010', 'BG', 'BORGO DI TERZO'],
  ['B026', 'PU', 'BORGO PACE'],
  ['B028', 'PV', 'BORGO PRIOLO'],
  ['B033', 'CN', 'BORGO SAN DALMAZZO'],
  ['B035', 'BS', 'BORGO SAN GIACOMO'],
  ['B017', 'LO', 'BORGO SAN GIOVANNI'],
  ['B036', 'FI', 'BORGO SAN LORENZO'],
  ['B037', 'AL', 'BORGO SAN MARTINO'],
  ['B038', 'PV', 'BORGO SAN SIRO'],
  ['B043', 'NO', 'BORGO TICINO'],
  ['B044', 'BO', 'BORGO TOSSIGNANO'],
  ['B042', 'PR', 'BORGO VAL DI TARO'],
  ['B006', 'TN', 'BORGO VALSUGANA'],
  ['A996', 'RI', 'BORGO VELINO'],
  ['B046', 'VC', 'BORGO VERCELLI'],
  ['B011', 'MN', 'BORGOFORTE'],
  ['B015', 'TO', 'BORGOFRANCO D\'IVREA'],
  ['B013', 'MN', 'BORGOFRANCO SUL PO'],
  ['B016', 'NO', 'BORGOLAVEZZARO'],
  ['B018', 'CN', 'BORGOMALE'],
  ['B019', 'NO', 'BORGOMANERO'],
  ['B020', 'IM', 'BORGOMARO'],
  ['B021', 'TO', 'BORGOMASINO'],
  ['B024', 'TO', 'BORGONE SUSA'],
  ['B025', 'PC', 'BORGONOVO VAL TIDONE'],
  ['B029', 'AL', 'BORGORATTO ALESSANDRINO'],
  ['B030', 'PV', 'BORGORATTO MORMOROLO'],
  ['B031', 'PD', 'BORGORICCO'],
  ['B008', 'RI', 'BORGOROSE'],
  ['B040', 'BS', 'BORGOSATOLLO'],
  ['B041', 'VC', 'BORGOSESIA'],
  ['B048', 'SV', 'BORMIDA'],
  ['B049', 'SO', 'BORMIO'],
  ['B051', 'PV', 'BORNASCO'],
  ['B054', 'BS', 'BORNO'],
  ['B055', 'OR', 'BORONEDDU'],
  ['B056', 'NU', 'BORORE'],
  ['B057', 'CH', 'BORRELLO'],
  ['B058', 'BI', 'BORRIANA'],
  ['B061', 'TV', 'BORSO DEL GRAPPA'],
  ['B062', 'NU', 'BORTIGALI'],
  ['B063', 'SS', 'BORTIGIADAS'],
  ['B064', 'SS', 'BORUTTA'],
  ['B067', 'GE', 'BORZONASCA'],
  ['B068', 'NU', 'BOSA'],
  ['B069', 'RO', 'BOSARO'],
  ['B070', 'VR', 'BOSCHI SANT\'ANNA'],
  ['B073', 'VR', 'BOSCO CHIESANUOVA'],
  ['B071', 'AL', 'BOSCO MARENGO'],
  ['B075', 'TO', 'BOSCONERO'],
  ['B076', 'NA', 'BOSCOREALE'],
  ['B077', 'NA', 'BOSCOTRECASE'],
  ['B078', 'TN', 'BOSENTINO'],
  ['B079', 'CN', 'BOSIA'],
  ['B080', 'AL', 'BOSIO'],
  ['B081', 'LC', 'BOSISIO PARINI'],
  ['B082', 'PV', 'BOSNASCO'],
  ['Z153', 'EE', 'BOSNIA ED ERZEGOVINA'],
  ['B083', 'BG', 'BOSSICO'],
  ['B084', 'CN', 'BOSSOLASCO'],
  ['B085', 'CZ', 'BOTRICELLO'],
  ['B086', 'LE', 'BOTRUGNO'],
  ['Z358', 'EE', 'BOTSWANA'],
  ['B088', 'BG', 'BOTTANUCO'],
  ['B091', 'BS', 'BOTTICINO'],
  ['B094', 'SS', 'BOTTIDDA'],
  ['B097', 'RC', 'BOVA'],
  ['B099', 'RC', 'BOVA MARINA'],
  ['B098', 'RC', 'BOVALINO'],
  ['B100', 'BS', 'BOVEGNO'],
  ['B101', 'CN', 'BOVES'],
  ['B102', 'BS', 'BOVEZZO'],
  ['A720', 'FR', 'BOVILLE ERNICA'],
  ['B104', 'FG', 'BOVINO'],
  ['B105', 'MB', 'BOVISIO-MASCIAGO'],
  ['B105', 'MI', 'BOVISIO-MASCIAGO'],
  ['B106', 'PD', 'BOVOLENTA'],
  ['B107', 'VR', 'BOVOLONE'],
  ['B109', 'AL', 'BOZZOLE'],
  ['B110', 'MN', 'BOZZOLO'],
  ['B111', 'CN', 'BRA'],
  ['B112', 'BG', 'BRACCA'],
  ['B114', 'RM', 'BRACCIANO'],
  ['B115', 'SA', 'BRACIGLIANO'],
  ['B116', 'BZ', 'BRAIES'],
  ['B117', 'PV', 'BRALLO DI PREGOLA'],
  ['B118', 'RC', 'BRANCALEONE'],
  ['B120', 'BS', 'BRANDICO'],
  ['B121', 'TO', 'BRANDIZZO'],
  ['B123', 'BG', 'BRANZI'],
  ['B124', 'BS', 'BRAONE'],
  ['Z602', 'EE', 'BRASILE'],
  ['B126', 'VA', 'BREBBIA'],
  ['B128', 'TV', 'BREDA DI PIAVE'],
  ['B131', 'VA', 'BREGANO'],
  ['B132', 'VI', 'BREGANZE'],
  ['B134', 'CO', 'BREGNANO'],
  ['B135', 'TN', 'BREGUZZO'],
  ['B136', 'VC', 'BREIA'],
  ['B137', 'BG', 'BREMBATE'],
  ['B138', 'BG', 'BREMBATE DI SOPRA'],
  ['B140', 'BG', 'BREMBILLA'],
  ['B141', 'LO', 'BREMBIO'],
  ['B142', 'PV', 'BREME'],
  ['B143', 'VI', 'BRENDOLA'],
  ['B144', 'CO', 'BRENNA'],
  ['B145', 'BZ', 'BRENNERO'],
  ['B149', 'BS', 'BRENO'],
  ['B150', 'VA', 'BRENTA'],
  ['B152', 'VR', 'BRENTINO BELLUNO'],
  ['B153', 'TN', 'BRENTONICO'],
  ['B154', 'VR', 'BRENZONE'],
  ['B156', 'RE', 'BRESCELLO'],
  ['B157', 'BS', 'BRESCIA'],
  ['B158', 'TN', 'BRESIMO'],
  ['B159', 'PV', 'BRESSANA BOTTARONE'],
  ['B160', 'BZ', 'BRESSANONE'],
  ['B161', 'VI', 'BRESSANVIDO'],
  ['B162', 'MI', 'BRESSO'],
  ['B165', 'TN', 'BREZ'],
  ['B166', 'VA', 'BREZZO DI BEDERO'],
  ['B167', 'CN', 'BRIAGLIA'],
  ['B169', 'VV', 'BRIATICO'],
  ['B171', 'TO', 'BRICHERASIO'],
  ['B172', 'CO', 'BRIENNO'],
  ['B173', 'PZ', 'BRIENZA'],
  ['B175', 'CN', 'BRIGA ALTA'],
  ['B176', 'NO', 'BRIGA NOVARESE'],
  ['B178', 'BG', 'BRIGNANO GERA D\'ADDA'],
  ['B179', 'AL', 'BRIGNANO-FRASCATA'],
  ['B180', 'BR', 'BRINDISI'],
  ['B181', 'PZ', 'BRINDISI MONTAGNA'],
  ['B182', 'VA', 'BRINZIO'],
  ['B183', 'NO', 'BRIONA'],
  ['B184', 'BS', 'BRIONE'],
  ['B185', 'TN', 'BRIONE'],
  ['B187', 'MB', 'BRIOSCO'],
  ['B187', 'MI', 'BRIOSCO'],
  ['B188', 'RA', 'BRISIGHELLA'],
  ['B191', 'VA', 'BRISSAGO-VALTRAVAGLIA'],
  ['B192', 'AO', 'BRISSOGNE'],
  ['B193', 'PE', 'BRITTOLI'],
  ['B194', 'LC', 'BRIVIO'],
  ['B195', 'FR', 'BROCCOSTELLA'],
  ['B196', 'VI', 'BROGLIANO'],
  ['B197', 'VV', 'BROGNATURO'],
  ['B198', 'ME', 'BROLO'],
  ['B200', 'CN', 'BRONDELLO'],
  ['B201', 'PV', 'BRONI'],
  ['B202', 'CT', 'BRONTE'],
  ['B203', 'BZ', 'BRONZOLO'],
  ['B204', 'CN', 'BROSSASCO'],
  ['B205', 'TO', 'BROSSO'],
  ['B207', 'VB', 'BROVELLO-CARPUGNINO'],
  ['B209', 'TO', 'BROZOLO'],
  ['B212', 'MB', 'BRUGHERIO'],
  ['B212', 'MI', 'BRUGHERIO'],
  ['B213', 'PD', 'BRUGINE'],
  ['B214', 'SP', 'BRUGNATO'],
  ['B215', 'PN', 'BRUGNERA'],
  ['B216', 'TO', 'BRUINO'],
  ['B217', 'BG', 'BRUMANO'],
  ['B218', 'CO', 'BRUNATE'],
  ['Z207', 'EE', 'BRUNEI'],
  ['B219', 'VA', 'BRUNELLO'],
  ['B220', 'BZ', 'BRUNICO'],
  ['B221', 'AT', 'BRUNO'],
  ['B223', 'BG', 'BRUSAPORTO'],
  ['B225', 'TO', 'BRUSASCO'],
  ['B227', 'NA', 'BRUSCIANO'],
  ['B228', 'VA', 'BRUSIMPIANO'],
  ['B229', 'BI', 'BRUSNENGO'],
  ['B230', 'AO', 'BRUSSON'],
  ['B232', 'TO', 'BRUZOLO'],
  ['B234', 'RC', 'BRUZZANO ZEFFIRIO'],
  ['B235', 'MI', 'BUBBIANO'],
  ['B236', 'AT', 'BUBBIO'],
  ['B237', 'SR', 'BUCCHERI'],
  ['B238', 'CH', 'BUCCHIANICO'],
  ['B239', 'BN', 'BUCCIANO'],
  ['B240', 'MI', 'BUCCINASCO'],
  ['B242', 'SA', 'BUCCINO'],
  ['B243', 'AR', 'BUCINE'],
  ['B246', 'SS', 'BUDDUSO\''],
  ['B247', 'PN', 'BUDOIA'],
  ['B248', 'NU', 'BUDONI'],
  ['B249', 'BO', 'BUDRIO'],
  ['B250', 'CA', 'BUGGERRU'],
  ['B251', 'PT', 'BUGGIANO'],
  ['B255', 'SO', 'BUGLIO IN MONTE'],
  ['B256', 'AQ', 'BUGNARA'],
  ['B258', 'VA', 'BUGUGGIATE'],
  ['B259', 'UD', 'BUJA'],
  ['B261', 'LC', 'BULCIAGO'],
  ['Z104', 'EE', 'BULGARIA'],
  ['B262', 'CO', 'BULGAROGRASSO'],
  ['B264', 'SS', 'BULTEI'],
  ['B265', 'SS', 'BULZI'],
  ['B266', 'SA', 'BUONABITACOLO'],
  ['B267', 'BN', 'BUONALBERGO'],
  ['B269', 'SI', 'BUONCONVENTO'],
  ['B270', 'CS', 'BUONVICINO'],
  ['B272', 'MB', 'BURAGO DI MOLGORA'],
  ['B272', 'MI', 'BURAGO DI MOLGORA'],
  ['B274', 'CA', 'BURCEI'],
  ['B275', 'AG', 'BURGIO'],
  ['B276', 'SS', 'BURGOS'],
  ['B278', 'TO', 'BURIASCO'],
  ['Z354', 'EE', 'BURKINA FASO'],
  ['B279', 'TO', 'BUROLO'],
  ['B280', 'VC', 'BURONZO'],
  ['Z305', 'EE', 'BURUNDI'],
  ['B281', 'OR', 'BUSACHI'],
  ['B282', 'GE', 'BUSALLA'],
  ['B283', 'RE', 'BUSANA'],
  ['B284', 'TO', 'BUSANO'],
  ['B285', 'CN', 'BUSCA'],
  ['B286', 'MI', 'BUSCATE'],
  ['B287', 'SR', 'BUSCEMI'],
  ['B288', 'TP', 'BUSETO PALIZZOLO'],
  ['B289', 'MB', 'BUSNAGO'],
  ['B289', 'MI', 'BUSNAGO'],
  ['B292', 'MI', 'BUSSERO'],
  ['B293', 'PR', 'BUSSETO'],
  ['B294', 'PE', 'BUSSI SUL TIRINO'],
  ['B295', 'CB', 'BUSSO'],
  ['B296', 'VR', 'BUSSOLENGO'],
  ['B297', 'TO', 'BUSSOLENO'],
  ['B300', 'VA', 'BUSTO ARSIZIO'],
  ['B301', 'MI', 'BUSTO GAROLFO'],
  ['B302', 'CL', 'BUTERA'],
  ['B303', 'PI', 'BUTI'],
  ['B304', 'VR', 'BUTTAPIETRA'],
  ['B305', 'TO', 'BUTTIGLIERA ALTA'],
  ['B306', 'AT', 'BUTTIGLIERA D\'ASTI'],
  ['B309', 'UD', 'BUTTRIO'],
  ['B320', 'CR', 'CA\' D\'ANDREA'],
  ['B311', 'AL', 'CABELLA LIGURE'],
  ['B313', 'CO', 'CABIATE'],
  ['B314', 'OR', 'CABRAS'],
  ['B315', 'PA', 'CACCAMO'],
  ['B319', 'KR', 'CACCURI'],
  ['B326', 'VA', 'CADEGLIANO-VICONAGO'],
  ['B328', 'RE', 'CADELBOSCO DI SOPRA'],
  ['B332', 'PC', 'CADEO'],
  ['B335', 'TN', 'CADERZONE'],
  ['B345', 'PD', 'CADONEGHE'],
  ['B346', 'CO', 'CADORAGO'],
  ['B347', 'VA', 'CADREZZATE'],
  ['B349', 'TV', 'CAERANO DI SAN MARCO'],
  ['B350', 'TO', 'CAFASSE'],
  ['B351', 'SA', 'CAGGIANO'],
  ['B352', 'PU', 'CAGLI'],
  ['B354', 'CA', 'CAGLIARI'],
  ['B355', 'CO', 'CAGLIO'],
  ['B358', 'AQ', 'CAGNANO AMITERNO'],
  ['B357', 'FG', 'CAGNANO VARANO'],
  ['B359', 'CO', 'CAGNO'],
  ['B360', 'TN', 'CAGNO\''],
  ['B361', 'CE', 'CAIANELLO'],
  ['B362', 'CE', 'CAIAZZO'],
  ['B364', 'BZ', 'CAINES'],
  ['B365', 'BS', 'CAINO'],
  ['B366', 'SO', 'CAIOLO'],
  ['B367', 'AV', 'CAIRANO'],
  ['B368', 'VA', 'CAIRATE'],
  ['B369', 'SV', 'CAIRO MONTENOTTE'],
  ['B371', 'NA', 'CAIVANO'],
  ['B374', 'AV', 'CALABRITTO'],
  ['B375', 'BL', 'CALALZO DI CADORE'],
  ['B376', 'AT', 'CALAMANDRANA'],
  ['B377', 'AG', 'CALAMONACI'],
  ['B378', 'SS', 'CALANGIANUS'],
  ['B379', 'RC', 'CALANNA'],
  ['B380', 'VB', 'CALASCA-CASTIGLIONE'],
  ['B381', 'EN', 'CALASCIBETTA'],
  ['B382', 'AQ', 'CALASCIO'],
  ['B383', 'CA', 'CALASETTA'],
  ['B384', 'CT', 'CALATABIANO'],
  ['B385', 'TP', 'CALATAFIMI'],
  ['B386', 'TN', 'CALAVINO'],
  ['B388', 'VT', 'CALCATA'],
  ['B389', 'TN', 'CALCERANICA AL LAGO'],
  ['B390', 'PI', 'CALCI'],
  ['B391', 'MT', 'CALCIANO'],
  ['B392', 'PI', 'CALCINAIA'],
  ['B393', 'BG', 'CALCINATE'],
  ['B394', 'BS', 'CALCINATO'],
  ['B395', 'BG', 'CALCIO'],
  ['B396', 'LC', 'CALCO'],
  ['B397', 'BZ', 'CALDARO SULLA STRADA DEL VINO'],
  ['B398', 'MC', 'CALDAROLA'],
  ['B399', 'BO', 'CALDERARA DI RENO'],
  ['B400', 'TN', 'CALDES'],
  ['B402', 'VR', 'CALDIERO'],
  ['B403', 'VI', 'CALDOGNO'],
  ['B404', 'TN', 'CALDONAZZO'],
  ['B405', 'PC', 'CALENDASCO'],
  ['B406', 'FI', 'CALENZANO'],
  ['B408', 'PR', 'CALESTANO'],
  ['B410', 'SP', 'CALICE AL CORNOVIGLIO'],
  ['B409', 'SV', 'CALICE LIGURE'],
  ['B413', 'LE', 'CALIMERA'],
  ['B415', 'AV', 'CALITRI'],
  ['B416', 'SV', 'CALIZZANO'],
  ['B417', 'BI', 'CALLABIANA'],
  ['B419', 'TN', 'CALLIANO'],
  ['B418', 'AT', 'CALLIANO'],
  ['B423', 'LC', 'CALOLZIOCORTE'],
  ['B424', 'CS', 'CALOPEZZATI'],
  ['B425', 'AT', 'CALOSSO'],
  ['B426', 'CS', 'CALOVETO'],
  ['B427', 'AG', 'CALTABELLOTTA'],
  ['B428', 'CT', 'CALTAGIRONE'],
  ['B429', 'CL', 'CALTANISSETTA'],
  ['B430', 'PA', 'CALTAVUTURO'],
  ['B431', 'NO', 'CALTIGNAGA'],
  ['B432', 'RO', 'CALTO'],
  ['B433', 'VI', 'CALTRANO'],
  ['B434', 'BG', 'CALUSCO D\'ADDA'],
  ['B435', 'TO', 'CALUSO'],
  ['B436', 'BS', 'CALVAGESE DELLA RIVIERA'],
  ['B437', 'SA', 'CALVANICO'],
  ['B439', 'CR', 'CALVATONE'],
  ['B440', 'PZ', 'CALVELLO'],
  ['B441', 'VI', 'CALVENE'],
  ['B442', 'BG', 'CALVENZANO'],
  ['B443', 'PZ', 'CALVERA'],
  ['B444', 'BN', 'CALVI'],
  ['B446', 'TR', 'CALVI DELL\'UMBRIA'],
  ['B445', 'CE', 'CALVI RISORTA'],
  ['B447', 'PV', 'CALVIGNANO'],
  ['B448', 'MI', 'CALVIGNASCO'],
  ['B450', 'BS', 'CALVISANO'],
  ['B452', 'NA', 'CALVIZZANO'],
  ['B453', 'AL', 'CAMAGNA MONFERRATO'],
  ['B455', 'LU', 'CAMAIORE'],
  ['B456', 'LO', 'CAMAIRAGO'],
  ['B457', 'BI', 'CAMANDONA'],
  ['B460', 'AG', 'CAMASTRA'],
  ['B461', 'MI', 'CAMBIAGO'],
  ['B462', 'TO', 'CAMBIANO'],
  ['B463', 'VB', 'CAMBIASCA'],
  ['Z208', 'EE', 'CAMBOGIA'],
  ['B465', 'BI', 'CAMBURZANO'],
  ['B467', 'CN', 'CAMERANA'],
  ['B468', 'AN', 'CAMERANO'],
  ['B469', 'AT', 'CAMERANO CASASCO'],
  ['B471', 'BG', 'CAMERATA CORNELLO'],
  ['B472', 'RM', 'CAMERATA NUOVA'],
  ['B470', 'AN', 'CAMERATA PICENA'],
  ['B473', 'NO', 'CAMERI'],
  ['B474', 'MC', 'CAMERINO'],
  ['B476', 'SA', 'CAMEROTA'],
  ['Z306', 'EE', 'CAMERUN'],
  ['B477', 'CE', 'CAMIGLIANO'],
  ['B479', 'PC', 'CAMINATA'],
  ['B481', 'RC', 'CAMINI'],
  ['B482', 'AL', 'CAMINO'],
  ['B483', 'UD', 'CAMINO AL TAGLIAMENTO'],
  ['B484', 'CR', 'CAMISANO'],
  ['B485', 'VI', 'CAMISANO VICENTINO'],
  ['B486', 'AG', 'CAMMARATA'],
  ['B489', 'CN', 'CAMO'],
  ['B490', 'GE', 'CAMOGLI'],
  ['B492', 'SA', 'CAMPAGNA'],
  ['B493', 'VE', 'CAMPAGNA LUPIA'],
  ['B496', 'RM', 'CAMPAGNANO DI ROMA'],
  ['B497', 'GR', 'CAMPAGNATICO'],
  ['B498', 'CR', 'CAMPAGNOLA CREMASCA'],
  ['B499', 'RE', 'CAMPAGNOLA EMILIA'],
  ['B500', 'CS', 'CAMPANA'],
  ['B501', 'MB', 'CAMPARADA'],
  ['B501', 'MI', 'CAMPARADA'],
  ['B502', 'RE', 'CAMPEGINE'],
  ['B504', 'PG', 'CAMPELLO SUL CLITUNNO'],
  ['B505', 'VC', 'CAMPERTOGNO'],
  ['B507', 'FI', 'CAMPI BISENZIO'],
  ['B506', 'LE', 'CAMPI SALENTINA'],
  ['B508', 'BI', 'CAMPIGLIA CERVO'],
  ['B511', 'VI', 'CAMPIGLIA DEI BERICI'],
  ['B509', 'LI', 'CAMPIGLIA MARITTIMA'],
  ['B512', 'TO', 'CAMPIGLIONE FENILE'],
  ['B513', 'CO', 'CAMPIONE D\'ITALIA'],
  ['B514', 'TN', 'CAMPITELLO DI FASSA'],
  ['B515', 'TE', 'CAMPLI'],
  ['B516', 'RC', 'CAMPO CALABRO'],
  ['B526', 'AQ', 'CAMPO DI GIOVE'],
  ['B529', 'BZ', 'CAMPO DI TRENS'],
  ['B538', 'GE', 'CAMPO LIGURE'],
  ['B553', 'LI', 'CAMPO NELL\'ELBA'],
  ['B564', 'PD', 'CAMPO SAN MARTINO'],
  ['B570', 'BZ', 'CAMPO TURES'],
  ['B519', 'CB', 'CAMPOBASSO'],
  ['B520', 'AG', 'CAMPOBELLO DI LICATA'],
  ['B521', 'TP', 'CAMPOBELLO DI MAZARA'],
  ['B522', 'CB', 'CAMPOCHIARO'],
  ['B524', 'PD', 'CAMPODARSEGO'],
  ['B525', 'TN', 'CAMPODENNO'],
  ['B527', 'LT', 'CAMPODIMELE'],
  ['B528', 'CB', 'CAMPODIPIETRA'],
  ['B530', 'SO', 'CAMPODOLCINO'],
  ['B531', 'PD', 'CAMPODORO'],
  ['B533', 'PA', 'CAMPOFELICE DI FITALIA'],
  ['B532', 'PA', 'CAMPOFELICE DI ROCCELLA'],
  ['B534', 'FM', 'CAMPOFILONE'],
  ['B534', 'AP', 'CAMPOFILONE'],
  ['B535', 'PA', 'CAMPOFIORITO'],
  ['B536', 'UD', 'CAMPOFORMIDO'],
  ['B537', 'CL', 'CAMPOFRANCO'],
  ['B539', 'MO', 'CAMPOGALLIANO'],
  ['B541', 'BN', 'CAMPOLATTARO'],
  ['B543', 'FR', 'CAMPOLI APPENNINO'],
  ['B542', 'BN', 'CAMPOLI DEL MONTE TABURNO'],
  ['B544', 'CB', 'CAMPOLIETO'],
  ['B545', 'UD', 'CAMPOLONGO AL TORRE'],
  ['B546', 'VE', 'CAMPOLONGO MAGGIORE'],
  ['B547', 'VI', 'CAMPOLONGO SUL BRENTA'],
  ['B549', 'PZ', 'CAMPOMAGGIORE'],
  ['B550', 'CB', 'CAMPOMARINO'],
  ['B551', 'GE', 'CAMPOMORONE'],
  ['B554', 'VE', 'CAMPONOGARA'],
  ['B555', 'SA', 'CAMPORA'],
  ['B556', 'PA', 'CAMPOREALE'],
  ['B557', 'LU', 'CAMPORGIANO'],
  ['B559', 'IM', 'CAMPOROSSO'],
  ['B562', 'MC', 'CAMPOROTONDO DI FIASTRONE'],
  ['B561', 'CT', 'CAMPOROTONDO ETNEO'],
  ['B563', 'PD', 'CAMPOSAMPIERO'],
  ['B565', 'NA', 'CAMPOSANO'],
  ['B566', 'MO', 'CAMPOSANTO'],
  ['B567', 'PV', 'CAMPOSPINOSO'],
  ['B569', 'AQ', 'CAMPOTOSTO'],
  ['B572', 'BO', 'CAMUGNANO'],
  ['Z401', 'EE', 'CANADA'],
  ['B577', 'TN', 'CANAL SAN BOVO'],
  ['B573', 'CN', 'CANALE'],
  ['B574', 'BL', 'CANALE D\'AGORDO'],
  ['B576', 'RM', 'CANALE MONTERANO'],
  ['B578', 'RO', 'CANARO'],
  ['B579', 'TN', 'CANAZEI'],
  ['B580', 'PZ', 'CANCELLARA'],
  ['B581', 'CE', 'CANCELLO ED ARNONE'],
  ['B582', 'RO', 'CANDA'],
  ['B584', 'FG', 'CANDELA'],
  ['B586', 'BI', 'CANDELO'],
  ['B588', 'TO', 'CANDIA CANAVESE'],
  ['B587', 'PV', 'CANDIA LOMELLINA'],
  ['B589', 'PD', 'CANDIANA'],
  ['B590', 'AV', 'CANDIDA'],
  ['B591', 'RC', 'CANDIDONI'],
  ['B592', 'TO', 'CANDIOLO'],
  ['B593', 'MI', 'CANEGRATE'],
  ['B594', 'AT', 'CANELLI'],
  ['B597', 'VT', 'CANEPINA'],
  ['B598', 'PN', 'CANEVA'],
  ['B599', 'PV', 'CANEVINO'],
  ['B602', 'AG', 'CANICATTI\''],
  ['B603', 'SR', 'CANICATTINI BAGNI'],
  ['B604', 'VT', 'CANINO'],
  ['B605', 'TO', 'CANISCHIO'],
  ['B606', 'AQ', 'CANISTRO'],
  ['B607', 'CS', 'CANNA'],
  ['B608', 'SA', 'CANNALONGA'],
  ['B609', 'PG', 'CANNARA'],
  ['B610', 'VB', 'CANNERO RIVIERA'],
  ['B613', 'PV', 'CANNETO PAVESE'],
  ['B612', 'MN', 'CANNETO SULL\'OGLIO'],
  ['B615', 'VB', 'CANNOBIO'],
  ['B616', 'LE', 'CANNOLE'],
  ['B617', 'RC', 'CANOLO'],
  ['B618', 'BG', 'CANONICA D\'ADDA'],
  ['B619', 'BA', 'CANOSA DI PUGLIA'],
  ['B619', 'BT', 'CANOSA DI PUGLIA'],
  ['B620', 'CH', 'CANOSA SANNITA'],
  ['B621', 'CN', 'CANOSIO'],
  ['C669', 'RE', 'CANOSSA'],
  ['B624', 'AQ', 'CANSANO'],
  ['B626', 'PO', 'CANTAGALLO'],
  ['B627', 'RI', 'CANTALICE'],
  ['B628', 'TO', 'CANTALUPA'],
  ['B631', 'RI', 'CANTALUPO IN SABINA'],
  ['B629', 'AL', 'CANTALUPO LIGURE'],
  ['B630', 'IS', 'CANTALUPO NEL SANNIO'],
  ['B633', 'AT', 'CANTARANA'],
  ['B634', 'VA', 'CANTELLO'],
  ['B635', 'RM', 'CANTERANO'],
  ['B636', 'PU', 'CANTIANO'],
  ['B637', 'TO', 'CANTOIRA'],
  ['B639', 'CO', 'CANTU\''],
  ['B640', 'TE', 'CANZANO'],
  ['B641', 'CO', 'CANZO'],
  ['B642', 'VE', 'CAORLE'],
  ['B643', 'PC', 'CAORSO'],
  ['B644', 'SA', 'CAPACCIO'],
  ['B645', 'PA', 'CAPACI'],
  ['B646', 'GR', 'CAPALBIO'],
  ['B647', 'PI', 'CAPANNOLI'],
  ['B648', 'LU', 'CAPANNORI'],
  ['B649', 'RM', 'CAPENA'],
  ['B650', 'CR', 'CAPERGNANICA'],
  ['B651', 'AQ', 'CAPESTRANO'],
  ['B653', 'CO', 'CAPIAGO INTIMIANO'],
  ['B655', 'VV', 'CAPISTRANO'],
  ['B656', 'AQ', 'CAPISTRELLO'],
  ['B658', 'AQ', 'CAPITIGNANO'],
  ['B660', 'ME', 'CAPIZZI'],
  ['B661', 'BG', 'CAPIZZONE'],
  ['B666', 'ME', 'CAPO D\'ORLANDO'],
  ['B664', 'BS', 'CAPO DI PONTE'],
  ['Z307', 'EE', 'CAPO VERDE'],
  ['B663', 'VT', 'CAPODIMONTE'],
  ['B667', 'CE', 'CAPODRISE'],
  ['B669', 'LI', 'CAPOLIVERI'],
  ['B670', 'AR', 'CAPOLONA'],
  ['B671', 'MB', 'CAPONAGO'],
  ['B671', 'MI', 'CAPONAGO'],
  ['B672', 'AQ', 'CAPORCIANO'],
  ['B674', 'AV', 'CAPOSELE'],
  ['B675', 'CA', 'CAPOTERRA'],
  ['B676', 'BS', 'CAPOVALLE'],
  ['B677', 'AQ', 'CAPPADOCIA'],
  ['B679', 'CR', 'CAPPELLA CANTONE'],
  ['B680', 'CR', 'CAPPELLA DE\' PICENARDI'],
  ['B678', 'TV', 'CAPPELLA MAGGIORE'],
  ['B681', 'PE', 'CAPPELLE SUL TAVO'],
  ['B682', 'IS', 'CAPRACOTTA'],
  ['B684', 'FI', 'CAPRAIA E LIMITE'],
  ['B685', 'LI', 'CAPRAIA ISOLA'],
  ['B686', 'CR', 'CAPRALBA'],
  ['B688', 'VT', 'CAPRANICA'],
  ['B687', 'RM', 'CAPRANICA PRENESTINA'],
  ['B690', 'LE', 'CAPRARICA DI LECCE'],
  ['B691', 'VT', 'CAPRAROLA'],
  ['B692', 'CN', 'CAPRAUNA'],
  ['B693', 'AR', 'CAPRESE MICHELANGELO'],
  ['B694', 'VB', 'CAPREZZO'],
  ['B696', 'NA', 'CAPRI'],
  ['B695', 'ME', 'CAPRI LEONE'],
  ['B697', 'TN', 'CAPRIANA'],
  ['B698', 'BS', 'CAPRIANO DEL COLLE'],
  ['B700', 'BS', 'CAPRIANO-AZZANO'],
  ['B701', 'AL', 'CAPRIATA D\'ORBA'],
  ['B703', 'BG', 'CAPRIATE SAN GERVASIO'],
  ['B704', 'CE', 'CAPRIATI A VOLTURNO'],
  ['B705', 'TO', 'CAPRIE'],
  ['B706', 'AV', 'CAPRIGLIA IRPINA'],
  ['B707', 'AT', 'CAPRIGLIO'],
  ['B708', 'BI', 'CAPRILE'],
  ['B710', 'BG', 'CAPRINO BERGAMASCO'],
  ['B709', 'VR', 'CAPRINO VERONESE'],
  ['B711', 'BS', 'CAPRIOLO'],
  ['B712', 'GO', 'CAPRIVA DEL FRIULI'],
  ['B715', 'CE', 'CAPUA'],
  ['B716', 'BA', 'CAPURSO'],
  ['B718', 'RC', 'CARAFFA DEL BIANCO'],
  ['B717', 'CZ', 'CARAFFA DI CATANZARO'],
  ['B719', 'CN', 'CARAGLIO'],
  ['B720', 'CN', 'CARAMAGNA PIEMONTE'],
  ['B722', 'PE', 'CARAMANICO TERME'],
  ['B723', 'TN', 'CARANO'],
  ['B724', 'FG', 'CARAPELLE'],
  ['B725', 'AQ', 'CARAPELLE CALVISIO'],
  ['B726', 'GE', 'CARASCO'],
  ['B727', 'AP', 'CARASSAI'],
  ['B729', 'MB', 'CARATE BRIANZA'],
  ['B729', 'MI', 'CARATE BRIANZA'],
  ['B730', 'CO', 'CARATE URIO'],
  ['B731', 'BG', 'CARAVAGGIO'],
  ['B732', 'VA', 'CARAVATE'],
  ['B733', 'TO', 'CARAVINO'],
  ['B734', 'IM', 'CARAVONICA'],
  ['B735', 'VT', 'CARBOGNANO'],
  ['B741', 'PV', 'CARBONARA AL TICINO'],
  ['B740', 'NA', 'CARBONARA DI NOLA'],
  ['B739', 'MN', 'CARBONARA DI PO'],
  ['B736', 'AL', 'CARBONARA SCRIVIA'],
  ['B742', 'CO', 'CARBONATE'],
  ['B743', 'PZ', 'CARBONE'],
  ['B744', 'TV', 'CARBONERA'],
  ['B745', 'CA', 'CARBONIA'],
  ['B748', 'SV', 'CARCARE'],
  ['B749', 'PD', 'CARCERI'],
  ['B752', 'VC', 'CARCOFORO'],
  ['B754', 'VA', 'CARDANO AL CAMPO'],
  ['B755', 'CN', 'CARDE\''],
  ['M285', 'NU', 'CARDEDU'],
  ['B756', 'RC', 'CARDETO'],
  ['B758', 'CZ', 'CARDINALE'],
  ['B759', 'NA', 'CARDITO'],
  ['B760', 'LU', 'CAREGGINE'],
  ['B762', 'TO', 'CAREMA'],
  ['B763', 'LC', 'CARENNO'],
  ['B765', 'AL', 'CARENTINO'],
  ['B766', 'RC', 'CARERI'],
  ['B767', 'VC', 'CARESANA'],
  ['B768', 'VC', 'CARESANABLOT'],
  ['B769', 'AL', 'CAREZZANO'],
  ['B771', 'KR', 'CARFIZZI'],
  ['B772', 'SS', 'CARGEGHE'],
  ['B774', 'CS', 'CARIATI'],
  ['B776', 'AV', 'CARIFE'],
  ['B777', 'TO', 'CARIGNANO'],
  ['B778', 'CO', 'CARIMATE'],
  ['B779', 'CE', 'CARINARO'],
  ['B780', 'PA', 'CARINI'],
  ['B781', 'CE', 'CARINOLA'],
  ['B782', 'VC', 'CARISIO'],
  ['B783', 'TN', 'CARISOLO'],
  ['B784', 'FG', 'CARLANTINO'],
  ['B785', 'CO', 'CARLAZZO'],
  ['B787', 'SR', 'CARLENTINI'],
  ['B788', 'UD', 'CARLINO'],
  ['B789', 'CA', 'CARLOFORTE'],
  ['B790', 'CZ', 'CARLOPOLI'],
  ['B791', 'TO', 'CARMAGNOLA'],
  ['B792', 'LE', 'CARMIANO'],
  ['B794', 'PO', 'CARMIGNANO'],
  ['B795', 'PD', 'CARMIGNANO DI BRENTA'],
  ['B796', 'VA', 'CARNAGO'],
  ['B798', 'MB', 'CARNATE'],
  ['B798', 'MI', 'CARNATE'],
  ['B801', 'BG', 'CAROBBIO DEGLI ANGELI'],
  ['B802', 'CS', 'CAROLEI'],
  ['B803', 'BG', 'CARONA'],
  ['B804', 'ME', 'CARONIA'],
  ['B805', 'VA', 'CARONNO PERTUSELLA'],
  ['B807', 'VA', 'CARONNO VARESINO'],
  ['B808', 'TA', 'CAROSINO'],
  ['B809', 'BR', 'CAROVIGNO'],
  ['B810', 'IS', 'CAROVILLI'],
  ['B812', 'PC', 'CARPANETO PIACENTINO'],
  ['B813', 'CS', 'CARPANZANO'],
  ['B814', 'IM', 'CARPASIO'],
  ['B816', 'PU', 'CARPEGNA'],
  ['B817', 'BS', 'CARPENEDOLO'],
  ['B818', 'AL', 'CARPENETO'],
  ['B819', 'MO', 'CARPI'],
  ['B820', 'MI', 'CARPIANO'],
  ['B822', 'LE', 'CARPIGNANO SALENTINO'],
  ['B823', 'NO', 'CARPIGNANO SESIA'],
  ['B825', 'RE', 'CARPINETI'],
  ['B827', 'PE', 'CARPINETO DELLA NORA'],
  ['B828', 'RM', 'CARPINETO ROMANO'],
  ['B826', 'CH', 'CARPINETO SINELLO'],
  ['B829', 'FG', 'CARPINO'],
  ['B830', 'IS', 'CARPINONE'],
  ['B832', 'MS', 'CARRARA'],
  ['B835', 'VI', 'CARRE\''],
  ['B836', 'AL', 'CARREGA LIGURE'],
  ['B838', 'SP', 'CARRO'],
  ['B839', 'SP', 'CARRODANO'],
  ['B840', 'AL', 'CARROSIO'],
  ['B841', 'CN', 'CARRU\''],
  ['B842', 'AQ', 'CARSOLI'],
  ['B844', 'VI', 'CARTIGLIANO'],
  ['B845', 'CN', 'CARTIGNANO'],
  ['B846', 'PU', 'CARTOCETO'],
  ['B847', 'AL', 'CARTOSIO'],
  ['B848', 'PD', 'CARTURA'],
  ['B850', 'MI', 'CARUGATE'],
  ['B851', 'CO', 'CARUGO'],
  ['B853', 'CH', 'CARUNCHIO'],
  ['B854', 'BG', 'CARVICO'],
  ['B856', 'TN', 'CARZANO'],
  ['B857', 'KR', 'CASABONA'],
  ['B858', 'CB', 'CASACALENDA'],
  ['B859', 'CH', 'CASACANDITELLA'],
  ['B860', 'CE', 'CASAGIOVE'],
  ['B870', 'AL', 'CASAL CERMELLI'],
  ['B872', 'CE', 'CASAL DI PRINCIPE'],
  ['B895', 'SA', 'CASAL VELINO'],
  ['B861', 'CH', 'CASALANGUIDA'],
  ['B862', 'FR', 'CASALATTICO'],
  ['B864', 'NO', 'CASALBELTRAME'],
  ['B865', 'CH', 'CASALBORDINO'],
  ['B866', 'AV', 'CASALBORE'],
  ['B867', 'TO', 'CASALBORGONE'],
  ['B868', 'SA', 'CASALBUONO'],
  ['B869', 'CR', 'CASALBUTTANO ED UNITI'],
  ['B871', 'CB', 'CASALCIPRANO'],
  ['B873', 'BN', 'CASALDUNI'],
  ['B876', 'VB', 'CASALE CORTE CERRO'],
  ['B881', 'CR', 'CASALE CREMASCO-VIDOLASCO'],
  ['B877', 'PD', 'CASALE DI SCODOSIA'],
  ['B875', 'VA', 'CASALE LITTA'],
  ['B878', 'PI', 'CASALE MARITTIMO'],
  ['B885', 'AL', 'CASALE MONFERRATO'],
  ['B879', 'TV', 'CASALE SUL SILE'],
  ['B880', 'BO', 'CASALECCHIO DI RENO'],
  ['B882', 'AL', 'CASALEGGIO BOIRO'],
  ['B883', 'NO', 'CASALEGGIO NOVARA'],
  ['B886', 'VR', 'CASALEONE'],
  ['B889', 'CR', 'CASALETTO CEREDANO'],
  ['B890', 'CR', 'CASALETTO DI SOPRA'],
  ['B887', 'LO', 'CASALETTO LODIGIANO'],
  ['B888', 'SA', 'CASALETTO SPARTANO'],
  ['B891', 'CR', 'CASALETTO VAPRIO'],
  ['B892', 'BO', 'CASALFIUMANESE'],
  ['B893', 'RE', 'CASALGRANDE'],
  ['B894', 'CN', 'CASALGRASSO'],
  ['B896', 'CH', 'CASALINCONTRADA'],
  ['B897', 'NO', 'CASALINO'],
  ['B898', 'CR', 'CASALMAGGIORE'],
  ['B899', 'LO', 'CASALMAIOCCO'],
  ['B900', 'CR', 'CASALMORANO'],
  ['B901', 'MN', 'CASALMORO'],
  ['B902', 'AL', 'CASALNOCETO'],
  ['B905', 'NA', 'CASALNUOVO DI NAPOLI'],
  ['B904', 'FG', 'CASALNUOVO MONTEROTARO'],
  ['B907', 'MN', 'CASALOLDO'],
  ['B910', 'LO', 'CASALPUSTERLENGO'],
  ['B911', 'MN', 'CASALROMANO'],
  ['B912', 'PD', 'CASALSERUGO'],
  ['B916', 'CE', 'CASALUCE'],
  ['B917', 'FG', 'CASALVECCHIO DI PUGLIA'],
  ['B918', 'ME', 'CASALVECCHIO SICULO'],
  ['B919', 'FR', 'CASALVIERI'],
  ['B920', 'NO', 'CASALVOLONE'],
  ['B921', 'VA', 'CASALZUIGNO'],
  ['B922', 'NA', 'CASAMARCIANO'],
  ['B923', 'BA', 'CASAMASSIMA'],
  ['B924', 'NA', 'CASAMICCIOLA TERME'],
  ['B925', 'NA', 'CASANDRINO'],
  ['B928', 'VC', 'CASANOVA ELVO'],
  ['B927', 'SV', 'CASANOVA LERRONE'],
  ['B929', 'PV', 'CASANOVA LONATI'],
  ['B932', 'RM', 'CASAPE'],
  ['M260', 'CE', 'CASAPESENNA'],
  ['B933', 'BI', 'CASAPINTA'],
  ['B934', 'RI', 'CASAPROTA'],
  ['B935', 'CE', 'CASAPULLA'],
  ['B936', 'LE', 'CASARANO'],
  ['B937', 'LC', 'CASARGO'],
  ['B938', 'MI', 'CASARILE'],
  ['B940', 'PN', 'CASARSA DELLA DELIZIA'],
  ['B939', 'GE', 'CASARZA LIGURE'],
  ['B941', 'AL', 'CASASCO'],
  ['B942', 'CO', 'CASASCO D\'INTELVI'],
  ['B943', 'LC', 'CASATENOVO'],
  ['B945', 'PV', 'CASATISMA'],
  ['B946', 'NA', 'CASAVATORE'],
  ['B947', 'BG', 'CASAZZA'],
  ['B948', 'PG', 'CASCIA'],
  ['B949', 'VA', 'CASCIAGO'],
  ['A559', 'PI', 'CASCIANA TERME'],
  ['B950', 'PI', 'CASCINA'],
  ['B953', 'TO', 'CASCINETTE D\'IVREA'],
  ['B954', 'PV', 'CASEI GEROLA'],
  ['B955', 'TO', 'CASELETTE'],
  ['B956', 'GE', 'CASELLA'],
  ['B959', 'SA', 'CASELLE IN PITTARI'],
  ['B961', 'LO', 'CASELLE LANDI'],
  ['B958', 'LO', 'CASELLE LURANI'],
  ['B960', 'TO', 'CASELLE TORINESE'],
  ['B963', 'CE', 'CASERTA'],
  ['B965', 'TV', 'CASIER'],
  ['B966', 'RC', 'CASIGNANA'],
  ['B967', 'RE', 'CASINA'],
  ['B971', 'BG', 'CASIRATE D\'ADDA'],
  ['B974', 'CO', 'CASLINO D\'ERBA'],
  ['B977', 'CO', 'CASNATE CON BERNATE'],
  ['B978', 'BG', 'CASNIGO'],
  ['B980', 'NA', 'CASOLA DI NAPOLI'],
  ['B979', 'MS', 'CASOLA IN LUNIGIANA'],
  ['B982', 'RA', 'CASOLA VALSENIO'],
  ['B983', 'CS', 'CASOLE BRUZIO'],
  ['B984', 'SI', 'CASOLE D\'ELSA'],
  ['B985', 'CH', 'CASOLI'],
  ['B988', 'PV', 'CASORATE PRIMO'],
  ['B987', 'VA', 'CASORATE SEMPIONE'],
  ['B989', 'MI', 'CASOREZZO'],
  ['B990', 'NA', 'CASORIA'],
  ['B991', 'AT', 'CASORZO'],
  ['A472', 'RI', 'CASPERIA'],
  ['B993', 'SO', 'CASPOGGIO'],
  ['B994', 'UD', 'CASSACCO'],
  ['B996', 'LC', 'CASSAGO BRIANZA'],
  ['C002', 'CS', 'CASSANO ALLO IONIO'],
  ['C003', 'MI', 'CASSANO D\'ADDA'],
  ['B998', 'BA', 'CASSANO DELLE MURGE'],
  ['B997', 'AV', 'CASSANO IRPINO'],
  ['C004', 'VA', 'CASSANO MAGNAGO'],
  ['C005', 'AL', 'CASSANO SPINOLA'],
  ['B999', 'VA', 'CASSANO VALCUVIA'],
  ['C006', 'SR', 'CASSARO'],
  ['C007', 'BG', 'CASSIGLIO'],
  ['C014', 'MI', 'CASSINA DE\' PECCHI'],
  ['C020', 'CO', 'CASSINA RIZZARDI'],
  ['C024', 'LC', 'CASSINA VALSASSINA'],
  ['C022', 'AT', 'CASSINASCO'],
  ['C027', 'AL', 'CASSINE'],
  ['C030', 'AL', 'CASSINELLE'],
  ['C033', 'MI', 'CASSINETTA DI LUGAGNANO'],
  ['C034', 'FR', 'CASSINO'],
  ['C037', 'VI', 'CASSOLA'],
  ['C038', 'PV', 'CASSOLNOVO'],
  ['C041', 'VR', 'CASTAGNARO'],
  ['C044', 'LI', 'CASTAGNETO CARDUCCI'],
  ['C045', 'TO', 'CASTAGNETO PO'],
  ['C046', 'CN', 'CASTAGNITO'],
  ['C049', 'AT', 'CASTAGNOLE DELLE LANZE'],
  ['C047', 'AT', 'CASTAGNOLE MONFERRATO'],
  ['C048', 'TO', 'CASTAGNOLE PIEMONTE'],
  ['C050', 'PV', 'CASTANA'],
  ['C052', 'MI', 'CASTANO PRIMO'],
  ['C053', 'PV', 'CASTEGGIO'],
  ['C055', 'BS', 'CASTEGNATO'],
  ['C056', 'VI', 'CASTEGNERO'],
  ['C058', 'AV', 'CASTEL BARONIA'],
  ['C064', 'AT', 'CASTEL BOGLIONE'],
  ['C065', 'RA', 'CASTEL BOLOGNESE'],
  ['B494', 'CE', 'CASTEL CAMPAGNANO'],
  ['C040', 'TE', 'CASTEL CASTAGNA'],
  ['C071', 'AN', 'CASTEL COLONNA'],
  ['C183', 'TN', 'CASTEL CONDINO'],
  ['C075', 'BO', 'CASTEL D\'AIANO'],
  ['C076', 'MN', 'CASTEL D\'ARIO'],
  ['C078', 'VR', 'CASTEL D\'AZZANO'],
  ['C082', 'IS', 'CASTEL DEL GIUDICE'],
  ['C083', 'AQ', 'CASTEL DEL MONTE'],
  ['C085', 'GR', 'CASTEL DEL PIANO'],
  ['C086', 'BO', 'CASTEL DEL RIO'],
  ['B969', 'BO', 'CASTEL DI CASIO'],
  ['C090', 'AQ', 'CASTEL DI IERI'],
  ['C091', 'CT', 'CASTEL DI IUDICA'],
  ['C093', 'AP', 'CASTEL DI LAMA'],
  ['C094', 'ME', 'CASTEL DI LUCIO'],
  ['C096', 'AQ', 'CASTEL DI SANGRO'],
  ['C097', 'CE', 'CASTEL DI SASSO'],
  ['C098', 'RI', 'CASTEL DI TORA'],
  ['C102', 'AR', 'CASTEL FOCOGNANO'],
  ['C114', 'CH', 'CASTEL FRENTANO'],
  ['C115', 'CR', 'CASTEL GABBIANO'],
  ['C116', 'RM', 'CASTEL GANDOLFO'],
  ['C117', 'TR', 'CASTEL GIORGIO'],
  ['C118', 'MN', 'CASTEL GOFFREDO'],
  ['C121', 'BO', 'CASTEL GUELFO DI BOLOGNA'],
  ['C203', 'RM', 'CASTEL MADAMA'],
  ['C204', 'BO', 'CASTEL MAGGIORE'],
  ['C208', 'BS', 'CASTEL MELLA'],
  ['C211', 'CE', 'CASTEL MORRONE'],
  ['C252', 'PG', 'CASTEL RITALDI'],
  ['C253', 'AT', 'CASTEL ROCCHERO'],
  ['C255', 'BG', 'CASTEL ROZZONE'],
  ['C259', 'SA', 'CASTEL SAN GIORGIO'],
  ['C261', 'PC', 'CASTEL SAN GIOVANNI'],
  ['C262', 'SA', 'CASTEL SAN LORENZO'],
  ['C263', 'AR', 'CASTEL SAN NICCOLO\''],
  ['C266', 'RM', 'CASTEL SAN PIETRO ROMANO'],
  ['C265', 'BO', 'CASTEL SAN PIETRO TERME'],
  ['C270', 'IS', 'CASTEL SAN VINCENZO'],
  ['C268', 'RI', 'CASTEL SANT\'ANGELO'],
  ['C269', 'VT', 'CASTEL SANT\'ELIA'],
  ['C289', 'TR', 'CASTEL VISCARDO'],
  ['C110', 'IM', 'CASTEL VITTORIO'],
  ['C291', 'CE', 'CASTEL VOLTURNO'],
  ['C057', 'PD', 'CASTELBALDO'],
  ['C059', 'MN', 'CASTELBELFORTE'],
  ['C060', 'AN', 'CASTELBELLINO'],
  ['C062', 'BZ', 'CASTELBELLO CIARDES'],
  ['C063', 'SV', 'CASTELBIANCO'],
  ['C066', 'CB', 'CASTELBOTTACCIO'],
  ['C067', 'PA', 'CASTELBUONO'],
  ['C069', 'SA', 'CASTELCIVITA'],
  ['C072', 'BS', 'CASTELCOVATI'],
  ['C073', 'TV', 'CASTELCUCCO'],
  ['C074', 'PA', 'CASTELDACCIA'],
  ['C080', 'PU', 'CASTELDELCI'],
  ['C081', 'CN', 'CASTELDELFINO'],
  ['C089', 'CR', 'CASTELDIDONE'],
  ['C100', 'AN', 'CASTELFIDARDO'],
  ['C101', 'FI', 'CASTELFIORENTINO'],
  ['C103', 'TN', 'CASTELFONDO'],
  ['C104', 'LT', 'CASTELFORTE'],
  ['C105', 'AV', 'CASTELFRANCI'],
  ['C112', 'AR', 'CASTELFRANCO DI SOPRA'],
  ['C113', 'PI', 'CASTELFRANCO DI SOTTO'],
  ['C107', 'MO', 'CASTELFRANCO EMILIA'],
  ['C106', 'BN', 'CASTELFRANCO IN MISCANO'],
  ['C111', 'TV', 'CASTELFRANCO VENETO'],
  ['C119', 'VI', 'CASTELGOMBERTO'],
  ['C120', 'PZ', 'CASTELGRANDE'],
  ['C122', 'RO', 'CASTELGUGLIELMO'],
  ['C123', 'CH', 'CASTELGUIDONE'],
  ['C127', 'AT', 'CASTELL\'ALFERO'],
  ['C145', 'PC', 'CASTELL\'ARQUATO'],
  ['C147', 'GR', 'CASTELL\'AZZARA'],
  ['C051', 'ME', 'CASTELL\'UMBERTO'],
  ['C125', 'SA', 'CASTELLABATE'],
  ['C126', 'AQ', 'CASTELLAFIUME'],
  ['C128', 'TE', 'CASTELLALTO'],
  ['C130', 'TP', 'CASTELLAMMARE DEL GOLFO'],
  ['C129', 'NA', 'CASTELLAMMARE DI STABIA'],
  ['C133', 'TO', 'CASTELLAMONTE'],
  ['C134', 'BA', 'CASTELLANA GROTTE'],
  ['C135', 'PA', 'CASTELLANA SICULA'],
  ['C136', 'TA', 'CASTELLANETA'],
  ['C137', 'AL', 'CASTELLANIA'],
  ['C139', 'VA', 'CASTELLANZA'],
  ['C140', 'CN', 'CASTELLAR'],
  ['C142', 'AL', 'CASTELLAR GUIDOBONO'],
  ['C141', 'RE', 'CASTELLARANO'],
  ['C143', 'IM', 'CASTELLARO'],
  ['C146', 'BL', 'CASTELLAVAZZO'],
  ['C148', 'AL', 'CASTELLAZZO BORMIDA'],
  ['C149', 'NO', 'CASTELLAZZO NOVARESE'],
  ['C153', 'CR', 'CASTELLEONE'],
  ['C152', 'AN', 'CASTELLEONE DI SUASA'],
  ['C154', 'AT', 'CASTELLERO'],
  ['C155', 'BI', 'CASTELLETTO CERVO'],
  ['C156', 'AL', 'CASTELLETTO D\'ERRO'],
  ['C158', 'AL', 'CASTELLETTO D\'ORBA'],
  ['C157', 'PV', 'CASTELLETTO DI BRANDUZZO'],
  ['C160', 'AL', 'CASTELLETTO MERLI'],
  ['C161', 'AT', 'CASTELLETTO MOLINA'],
  ['C162', 'AL', 'CASTELLETTO MONFERRATO'],
  ['C166', 'NO', 'CASTELLETTO SOPRA TICINO'],
  ['C165', 'CN', 'CASTELLETTO STURA'],
  ['C167', 'CN', 'CASTELLETTO UZZONE'],
  ['C169', 'TE', 'CASTELLI'],
  ['C079', 'BG', 'CASTELLI CALEPIO'],
  ['C172', 'SI', 'CASTELLINA IN CHIANTI'],
  ['C174', 'PI', 'CASTELLINA MARITTIMA'],
  ['C173', 'CN', 'CASTELLINALDO'],
  ['C175', 'CB', 'CASTELLINO DEL BIFERNO'],
  ['C176', 'CN', 'CASTELLINO TANARO'],
  ['C177', 'FR', 'CASTELLIRI'],
  ['B312', 'VA', 'CASTELLO CABIAGLIO'],
  ['C184', 'PV', 'CASTELLO D\'AGOGNA'],
  ['C185', 'BO', 'CASTELLO D\'ARGILE'],
  ['C178', 'CE', 'CASTELLO DEL MATESE'],
  ['C186', 'SO', 'CASTELLO DELL\'ACQUA'],
  ['A300', 'AT', 'CASTELLO DI ANNONE'],
  ['C187', 'LC', 'CASTELLO DI BRIANZA'],
  ['C188', 'NA', 'CASTELLO DI CISTERNA'],
  ['C190', 'TV', 'CASTELLO DI GODEGO'],
  ['C191', 'BO', 'CASTELLO DI SERRAVALLE'],
  ['C194', 'TN', 'CASTELLO TESINO'],
  ['C189', 'TN', 'CASTELLO-MOLINA DI FIEMME'],
  ['C195', 'MN', 'CASTELLUCCHIO'],
  ['C198', 'FG', 'CASTELLUCCIO DEI SAURI'],
  ['C199', 'PZ', 'CASTELLUCCIO INFERIORE'],
  ['C201', 'PZ', 'CASTELLUCCIO SUPERIORE'],
  ['C202', 'FG', 'CASTELLUCCIO VALMAGGIORE'],
  ['C205', 'CN', 'CASTELMAGNO'],
  ['C206', 'CO', 'CASTELMARTE'],
  ['C207', 'RO', 'CASTELMASSA'],
  ['C197', 'CB', 'CASTELMAURO'],
  ['C209', 'PZ', 'CASTELMEZZANO'],
  ['C210', 'ME', 'CASTELMOLA'],
  ['C213', 'PV', 'CASTELNOVETTO'],
  ['C215', 'RO', 'CASTELNOVO BARIANO'],
  ['C217', 'PN', 'CASTELNOVO DEL FRIULI'],
  ['C218', 'RE', 'CASTELNOVO DI SOTTO'],
  ['C219', 'RE', 'CASTELNOVO NE\' MONTI'],
  ['C216', 'TN', 'CASTELNUOVO'],
  ['C226', 'AT', 'CASTELNUOVO BELBO'],
  ['C227', 'SI', 'CASTELNUOVO BERARDENGA'],
  ['C228', 'LO', 'CASTELNUOVO BOCCA D\'ADDA'],
  ['C229', 'AL', 'CASTELNUOVO BORMIDA'],
  ['C220', 'CO', 'CASTELNUOVO BOZZENTE'],
  ['C230', 'AT', 'CASTELNUOVO CALCEA'],
  ['C231', 'SA', 'CASTELNUOVO CILENTO'],
  ['C225', 'VR', 'CASTELNUOVO DEL GARDA'],
  ['C222', 'FG', 'CASTELNUOVO DELLA DAUNIA'],
  ['C214', 'CN', 'CASTELNUOVO DI CEVA'],
  ['C235', 'SA', 'CASTELNUOVO DI CONZA'],
  ['C224', 'RI', 'CASTELNUOVO DI FARFA'],
  ['C236', 'LU', 'CASTELNUOVO DI GARFAGNANA'],
  ['C237', 'RM', 'CASTELNUOVO DI PORTO'],
  ['C232', 'AT', 'CASTELNUOVO DON BOSCO'],
  ['C240', 'SP', 'CASTELNUOVO MAGRA'],
  ['C241', 'TO', 'CASTELNUOVO NIGRA'],
  ['C223', 'FR', 'CASTELNUOVO PARANO'],
  ['C242', 'MO', 'CASTELNUOVO RANGONE'],
  ['C243', 'AL', 'CASTELNUOVO SCRIVIA'],
  ['C244', 'PI', 'CASTELNUOVO VAL DI CECINA'],
  ['C245', 'BN', 'CASTELPAGANO'],
  ['C246', 'IS', 'CASTELPETROSO'],
  ['C247', 'IS', 'CASTELPIZZUTO'],
  ['C248', 'AN', 'CASTELPLANIO'],
  ['C250', 'BN', 'CASTELPOTO'],
  ['C251', 'MC', 'CASTELRAIMONDO'],
  ['C254', 'BZ', 'CASTELROTTO'],
  ['C267', 'MC', 'CASTELSANTANGELO SUL NERA'],
  ['C271', 'PZ', 'CASTELSARACENO'],
  ['C272', 'SS', 'CASTELSARDO'],
  ['C273', 'VA', 'CASTELSEPRIO'],
  ['B968', 'KR', 'CASTELSILANO'],
  ['C274', 'AL', 'CASTELSPINA'],
  ['C275', 'AG', 'CASTELTERMINI'],
  ['C181', 'VA', 'CASTELVECCANA'],
  ['C278', 'AQ', 'CASTELVECCHIO CALVISIO'],
  ['C276', 'SV', 'CASTELVECCHIO DI ROCCA BARBENA'],
  ['C279', 'AQ', 'CASTELVECCHIO SUBEQUO'],
  ['C280', 'BN', 'CASTELVENERE'],
  ['B129', 'CR', 'CASTELVERDE'],
  ['C200', 'IS', 'CASTELVERRINO'],
  ['C284', 'BN', 'CASTELVETERE IN VAL FORTORE'],
  ['C283', 'AV', 'CASTELVETERE SUL CALORE'],
  ['C286', 'TP', 'CASTELVETRANO'],
  ['C287', 'MO', 'CASTELVETRO DI MODENA'],
  ['C288', 'PC', 'CASTELVETRO PIACENTINO'],
  ['C290', 'CR', 'CASTELVISCONTI'],
  ['C292', 'BO', 'CASTENASO'],
  ['C293', 'BS', 'CASTENEDOLO'],
  ['M288', 'CA', 'CASTIADAS'],
  ['C318', 'AR', 'CASTIGLION FIBOCCHI'],
  ['C319', 'AR', 'CASTIGLION FIORENTINO'],
  ['C308', 'PE', 'CASTIGLIONE A CASAURIA'],
  ['C302', 'GE', 'CASTIGLIONE CHIAVARESE'],
  ['C301', 'CS', 'CASTIGLIONE COSENTINO'],
  ['C304', 'LO', 'CASTIGLIONE D\'ADDA'],
  ['C299', 'CO', 'CASTIGLIONE D\'INTELVI'],
  ['C313', 'SI', 'CASTIGLIONE D\'ORCIA'],
  ['C296', 'BO', 'CASTIGLIONE DEI PEPOLI'],
  ['C306', 'SA', 'CASTIGLIONE DEL GENOVESI'],
  ['C309', 'PG', 'CASTIGLIONE DEL LAGO'],
  ['C310', 'GR', 'CASTIGLIONE DELLA PESCAIA'],
  ['C312', 'MN', 'CASTIGLIONE DELLE STIVIERE'],
  ['C303', 'LU', 'CASTIGLIONE DI GARFAGNANA'],
  ['C297', 'CT', 'CASTIGLIONE DI SICILIA'],
  ['C314', 'CN', 'CASTIGLIONE FALLETTO'],
  ['C315', 'VT', 'CASTIGLIONE IN TEVERINA'],
  ['C298', 'CH', 'CASTIGLIONE MESSER MARINO'],
  ['C316', 'TE', 'CASTIGLIONE MESSER RAIMONDO'],
  ['C300', 'VA', 'CASTIGLIONE OLONA'],
  ['C317', 'CN', 'CASTIGLIONE TINELLA'],
  ['C307', 'TO', 'CASTIGLIONE TORINESE'],
  ['C321', 'AP', 'CASTIGNANO'],
  ['C322', 'TE', 'CASTILENTI'],
  ['C323', 'CN', 'CASTINO'],
  ['C325', 'SO', 'CASTIONE ANDEVENNO'],
  ['C324', 'BG', 'CASTIONE DELLA PRESOLANA'],
  ['C327', 'UD', 'CASTIONS DI STRADA'],
  ['C329', 'LO', 'CASTIRAGA VIDARDO'],
  ['C330', 'BS', 'CASTO'],
  ['C331', 'AP', 'CASTORANO'],
  ['C332', 'BS', 'CASTREZZATO'],
  ['C334', 'LE', 'CASTRI DI LECCE'],
  ['C335', 'LE', 'CASTRIGNANO DE\' GRECI'],
  ['C336', 'LE', 'CASTRIGNANO DEL CAPO'],
  ['C337', 'BG', 'CASTRO'],
  ['M261', 'LE', 'CASTRO'],
  ['C338', 'FR', 'CASTRO DEI VOLSCI'],
  ['C339', 'FC', 'CASTROCARO TERME E TERRA DEL SOLE'],
  ['C340', 'FR', 'CASTROCIELO'],
  ['C341', 'AG', 'CASTROFILIPPO'],
  ['C108', 'CS', 'CASTROLIBERO'],
  ['C343', 'VA', 'CASTRONNO'],
  ['C344', 'PA', 'CASTRONOVO DI SICILIA'],
  ['C345', 'PZ', 'CASTRONUOVO DI SANT\'ANDREA'],
  ['C346', 'CB', 'CASTROPIGNANO'],
  ['C347', 'ME', 'CASTROREALE'],
  ['C348', 'CS', 'CASTROREGIO'],
  ['C349', 'CS', 'CASTROVILLARI'],
  ['C351', 'CT', 'CATANIA'],
  ['C352', 'CZ', 'CATANZARO'],
  ['C353', 'EN', 'CATENANUOVA'],
  ['C354', 'PE', 'CATIGNANO'],
  ['C357', 'RN', 'CATTOLICA'],
  ['C356', 'AG', 'CATTOLICA ERACLEA'],
  ['C285', 'RC', 'CAULONIA'],
  ['C359', 'BN', 'CAUTANO'],
  ['C361', 'SA', 'CAVA DE\' TIRRENI'],
  ['C360', 'PV', 'CAVA MANARA'],
  ['C362', 'LO', 'CAVACURTA'],
  ['C363', 'BI', 'CAVAGLIA\''],
  ['C364', 'NO', 'CAVAGLIETTO'],
  ['C365', 'NO', 'CAVAGLIO D\'AGOGNA'],
  ['C367', 'VB', 'CAVAGLIO-SPOCCIA'],
  ['C369', 'TO', 'CAVAGNOLO'],
  ['C370', 'VR', 'CAVAION VERONESE'],
  ['C372', 'TN', 'CAVALESE'],
  ['C374', 'CO', 'CAVALLASCA'],
  ['C375', 'CN', 'CAVALLERLEONE'],
  ['C376', 'CN', 'CAVALLERMAGGIORE'],
  ['C377', 'LE', 'CAVALLINO'],
  ['M308', 'VE', 'CAVALLINO-TREPORTI'],
  ['C378', 'NO', 'CAVALLIRIO'],
  ['C380', 'TN', 'CAVARENO'],
  ['C381', 'CO', 'CAVARGNA'],
  ['C382', 'VA', 'CAVARIA CON PREMEZZO'],
  ['C383', 'VE', 'CAVARZERE'],
  ['C384', 'TV', 'CAVASO DEL TOMBA'],
  ['C385', 'PN', 'CAVASSO NUOVO'],
  ['C387', 'AL', 'CAVATORE'],
  ['C389', 'UD', 'CAVAZZO CARNICO'],
  ['C390', 'RM', 'CAVE'],
  ['C392', 'TN', 'CAVEDAGO'],
  ['C393', 'TN', 'CAVEDINE'],
  ['C394', 'LO', 'CAVENAGO D\'ADDA'],
  ['C395', 'MB', 'CAVENAGO DI BRIANZA'],
  ['C395', 'MI', 'CAVENAGO DI BRIANZA'],
  ['C396', 'BG', 'CAVERNAGO'],
  ['C398', 'MO', 'CAVEZZO'],
  ['C400', 'TN', 'CAVIZZANA'],
  ['C404', 'TO', 'CAVOUR'],
  ['C405', 'RE', 'CAVRIAGO'],
  ['C406', 'MN', 'CAVRIANA'],
  ['C407', 'AR', 'CAVRIGLIA'],
  ['Z530', 'EE', 'CAYMAN (ISOLE)'],
  ['C409', 'VA', 'CAZZAGO BRABBIA'],
  ['C408', 'BS', 'CAZZAGO SAN MARTINO'],
  ['C412', 'VR', 'CAZZANO DI TRAMIGNA'],
  ['C410', 'BG', 'CAZZANO SANT\'ANDREA'],
  ['C413', 'FR', 'CECCANO'],
  ['C414', 'PV', 'CECIMA'],
  ['C415', 'LI', 'CECINA'],
  ['C417', 'BS', 'CEDEGOLO'],
  ['C418', 'SO', 'CEDRASCO'],
  ['C420', 'PA', 'CEFALA\' DIANA'],
  ['C421', 'PA', 'CEFALU\''],
  ['C422', 'VE', 'CEGGIA'],
  ['C424', 'BR', 'CEGLIE MESSAPICO'],
  ['C426', 'AQ', 'CELANO'],
  ['C428', 'CH', 'CELENZA SUL TRIGNO'],
  ['C429', 'FG', 'CELENZA VALFORTORE'],
  ['C430', 'CS', 'CELICO'],
  ['C435', 'CR', 'CELLA DATI'],
  ['C432', 'AL', 'CELLA MONTE'],
  ['C436', 'BA', 'CELLAMARE'],
  ['C437', 'CS', 'CELLARA'],
  ['C438', 'AT', 'CELLARENGO'],
  ['C439', 'BS', 'CELLATICA'],
  ['C444', 'SA', 'CELLE DI BULGHERIA'],
  ['C441', 'CN', 'CELLE DI MACRA'],
  ['C440', 'AT', 'CELLE ENOMONDO'],
  ['C443', 'SV', 'CELLE LIGURE'],
  ['C442', 'FG', 'CELLE SAN VITO'],
  ['C446', 'VT', 'CELLENO'],
  ['C447', 'VT', 'CELLERE'],
  ['C449', 'TE', 'CELLINO ATTANASIO'],
  ['C448', 'BR', 'CELLINO SAN MARCO'],
  ['C450', 'VC', 'CELLIO'],
  ['M262', 'CE', 'CELLOLE'],
  ['C452', 'TN', 'CEMBRA'],
  ['C453', 'CZ', 'CENADI'],
  ['C456', 'BG', 'CENATE SOPRA'],
  ['C457', 'BG', 'CENATE SOTTO'],
  ['C458', 'BL', 'CENCENIGHE AGORDINO'],
  ['C459', 'BG', 'CENE'],
  ['C461', 'RO', 'CENESELLI'],
  ['C463', 'SV', 'CENGIO'],
  ['C467', 'TN', 'CENTA SAN NICOLO\''],
  ['C466', 'CN', 'CENTALLO'],
  ['C469', 'FE', 'CENTO'],
  ['C470', 'SA', 'CENTOLA'],
  ['C472', 'CZ', 'CENTRACHE'],
  ['Z308', 'EE', 'CENTRAFRICANA REPUBBLICA'],
  ['C471', 'EN', 'CENTURIPE'],
  ['C474', 'PE', 'CEPAGATTI'],
  ['C476', 'BN', 'CEPPALONI'],
  ['C478', 'VB', 'CEPPO MORELLI'],
  ['C479', 'FR', 'CEPRANO'],
  ['C480', 'EN', 'CERAMI'],
  ['C481', 'GE', 'CERANESI'],
  ['C483', 'NO', 'CERANO'],
  ['C482', 'CO', 'CERANO D\'INTELVI'],
  ['C484', 'PV', 'CERANOVA'],
  ['C485', 'SA', 'CERASO'],
  ['C486', 'CB', 'CERCEMAGGIORE'],
  ['C487', 'TO', 'CERCENASCO'],
  ['C488', 'CB', 'CERCEPICCOLA'],
  ['C489', 'CS', 'CERCHIARA DI CALABRIA'],
  ['C492', 'AQ', 'CERCHIO'],
  ['C493', 'SO', 'CERCINO'],
  ['C494', 'UD', 'CERCIVENTO'],
  ['C495', 'NA', 'CERCOLA'],
  ['C496', 'PA', 'CERDA'],
  ['C498', 'VR', 'CEREA'],
  ['C500', 'RO', 'CEREGNANO'],
  ['C501', 'KR', 'CERENZIA'],
  ['C497', 'TO', 'CERES'],
  ['C502', 'MN', 'CERESARA'],
  ['C503', 'AL', 'CERESETO'],
  ['C504', 'CN', 'CERESOLE ALBA'],
  ['C505', 'TO', 'CERESOLE REALE'],
  ['C506', 'BG', 'CERETE'],
  ['C508', 'PV', 'CERETTO LOMELLINA'],
  ['C509', 'PV', 'CERGNAGO'],
  ['C510', 'SV', 'CERIALE'],
  ['C511', 'IM', 'CERIANA'],
  ['C512', 'MB', 'CERIANO LAGHETTO'],
  ['C512', 'MI', 'CERIANO LAGHETTO'],
  ['C513', 'PC', 'CERIGNALE'],
  ['C514', 'FG', 'CERIGNOLA'],
  ['C515', 'CS', 'CERISANO'],
  ['C516', 'CO', 'CERMENATE'],
  ['A022', 'BZ', 'CERMES'],
  ['C517', 'TE', 'CERMIGNANO'],
  ['C520', 'CO', 'CERNOBBIO'],
  ['C521', 'LC', 'CERNUSCO LOMBARDONE'],
  ['C523', 'MI', 'CERNUSCO SUL NAVIGLIO'],
  ['C526', 'BI', 'CERRETO CASTELLO'],
  ['C528', 'AT', 'CERRETO D\'ASTI'],
  ['C524', 'AN', 'CERRETO D\'ESI'],
  ['C527', 'PG', 'CERRETO DI SPOLETO'],
  ['C507', 'AL', 'CERRETO GRUE'],
  ['C529', 'FI', 'CERRETO GUIDI'],
  ['C518', 'RM', 'CERRETO LAZIALE'],
  ['C525', 'BN', 'CERRETO SANNITA'],
  ['C530', 'CN', 'CERRETTO DELLE LANGHE'],
  ['C531', 'AL', 'CERRINA'],
  ['C532', 'BI', 'CERRIONE'],
  ['C536', 'MI', 'CERRO AL LAMBRO'],
  ['C534', 'IS', 'CERRO AL VOLTURNO'],
  ['C537', 'MI', 'CERRO MAGGIORE'],
  ['C533', 'AT', 'CERRO TANARO'],
  ['C538', 'VR', 'CERRO VERONESE'],
  ['C539', 'PZ', 'CERSOSIMO'],
  ['C540', 'FI', 'CERTALDO'],
  ['C541', 'PV', 'CERTOSA DI PAVIA'],
  ['C542', 'CZ', 'CERVA'],
  ['C543', 'RM', 'CERVARA DI ROMA'],
  ['C544', 'PD', 'CERVARESE SANTA CROCE'],
  ['C545', 'FR', 'CERVARO'],
  ['C547', 'CN', 'CERVASCA'],
  ['C548', 'VC', 'CERVATTO'],
  ['C549', 'BS', 'CERVENO'],
  ['C550', 'CN', 'CERVERE'],
  ['C551', 'PV', 'CERVESINA'],
  ['C552', 'RM', 'CERVETERI'],
  ['C553', 'RA', 'CERVIA'],
  ['C554', 'CS', 'CERVICATI'],
  ['C555', 'LO', 'CERVIGNANO D\'ADDA'],
  ['C556', 'UD', 'CERVIGNANO DEL FRIULI'],
  ['C557', 'AV', 'CERVINARA'],
  ['C558', 'CE', 'CERVINO'],
  ['C559', 'IM', 'CERVO'],
  ['C560', 'CS', 'CERZETO'],
  ['C561', 'CE', 'CESA'],
  ['C563', 'LC', 'CESANA BRIANZA'],
  ['C564', 'TO', 'CESANA TORINESE'],
  ['C565', 'MI', 'CESANO BOSCONE'],
  ['C566', 'MB', 'CESANO MADERNO'],
  ['C566', 'MI', 'CESANO MADERNO'],
  ['C567', 'VB', 'CESARA'],
  ['C568', 'ME', 'CESARO\''],
  ['C569', 'MI', 'CESATE'],
  ['C573', 'FC', 'CESENA'],
  ['C574', 'FC', 'CESENATICO'],
  ['C576', 'AV', 'CESINALI'],
  ['C578', 'IM', 'CESIO'],
  ['C577', 'BL', 'CESIOMAGGIORE'],
  ['C580', 'TV', 'CESSALTO'],
  ['C581', 'VV', 'CESSANITI'],
  ['C582', 'MC', 'CESSAPALOMBO'],
  ['C583', 'AT', 'CESSOLE'],
  ['C584', 'SA', 'CETARA'],
  ['C585', 'BS', 'CETO'],
  ['C587', 'SI', 'CETONA'],
  ['C588', 'CS', 'CETRARO'],
  ['C589', 'CN', 'CEVA'],
  ['C591', 'BS', 'CEVO'],
  ['C593', 'AO', 'CHALLAND-SAINT-ANSELME'],
  ['C594', 'AO', 'CHALLAND-SAINT-VICTOR'],
  ['C595', 'AO', 'CHAMBAVE'],
  ['B491', 'AO', 'CHAMOIS'],
  ['C596', 'AO', 'CHAMPDEPRAZ'],
  ['B540', 'AO', 'CHAMPORCHER'],
  ['C598', 'AO', 'CHARVENSOD'],
  ['C294', 'AO', 'CHATILLON'],
  ['C599', 'CN', 'CHERASCO'],
  ['C600', 'SS', 'CHEREMULE'],
  ['C604', 'TO', 'CHIALAMBERTO'],
  ['C605', 'VI', 'CHIAMPO'],
  ['C606', 'AV', 'CHIANCHE'],
  ['C608', 'SI', 'CHIANCIANO TERME'],
  ['C609', 'PI', 'CHIANNI'],
  ['C610', 'TO', 'CHIANOCCO'],
  ['C612', 'RG', 'CHIARAMONTE GULFI'],
  ['C613', 'SS', 'CHIARAMONTI'],
  ['C614', 'TV', 'CHIARANO'],
  ['C615', 'AN', 'CHIARAVALLE'],
  ['C616', 'CZ', 'CHIARAVALLE CENTRALE'],
  ['C618', 'BS', 'CHIARI'],
  ['C619', 'PZ', 'CHIAROMONTE'],
  ['C620', 'IS', 'CHIAUCI'],
  ['C621', 'GE', 'CHIAVARI'],
  ['C623', 'SO', 'CHIAVENNA'],
  ['C624', 'TO', 'CHIAVERANO'],
  ['C625', 'BZ', 'CHIENES'],
  ['C627', 'TO', 'CHIERI'],
  ['C630', 'BL', 'CHIES D\'ALPAGO'],
  ['C628', 'SO', 'CHIESA IN VALMALENCO'],
  ['C629', 'TO', 'CHIESANUOVA'],
  ['C631', 'PT', 'CHIESINA UZZANESE'],
  ['C632', 'CH', 'CHIETI'],
  ['C633', 'FG', 'CHIEUTI'],
  ['C634', 'CR', 'CHIEVE'],
  ['C635', 'BG', 'CHIGNOLO D\'ISOLA'],
  ['C637', 'PV', 'CHIGNOLO PO'],
  ['C638', 'VE', 'CHIOGGIA'],
  ['C639', 'TO', 'CHIOMONTE'],
  ['C640', 'PN', 'CHIONS'],
  ['C641', 'UD', 'CHIOPRIS VISCONE'],
  ['C648', 'AR', 'CHITIGNANO'],
  ['C649', 'BG', 'CHIUDUNO'],
  ['C650', 'VI', 'CHIUPPANO'],
  ['C651', 'SO', 'CHIURO'],
  ['C652', 'BZ', 'CHIUSA'],
  ['C653', 'CN', 'CHIUSA DI PESIO'],
  ['C655', 'TO', 'CHIUSA DI SAN MICHELE'],
  ['C654', 'PA', 'CHIUSA SCLAFANI'],
  ['C656', 'UD', 'CHIUSAFORTE'],
  ['C657', 'IM', 'CHIUSANICO'],
  ['C658', 'AT', 'CHIUSANO D\'ASTI'],
  ['C659', 'AV', 'CHIUSANO DI SAN DOMENICO'],
  ['C660', 'IM', 'CHIUSAVECCHIA'],
  ['C661', 'SI', 'CHIUSDINO'],
  ['C662', 'SI', 'CHIUSI'],
  ['C663', 'AR', 'CHIUSI DELLA VERNA'],
  ['C665', 'TO', 'CHIVASSO'],
  ['Z702', 'EE', 'CHRISTMAS (ISOLA)'],
  ['Z309', 'EE', 'CIAD'],
  ['M272', 'RM', 'CIAMPINO'],
  ['C668', 'AG', 'CIANCIANA'],
  ['C672', 'BL', 'CIBIANA DI CADORE'],
  ['C673', 'GE', 'CICAGNA'],
  ['C674', 'CZ', 'CICALA'],
  ['C675', 'NA', 'CICCIANO'],
  ['C676', 'SA', 'CICERALE'],
  ['C677', 'RM', 'CICILIANO'],
  ['C678', 'CR', 'CICOGNOLO'],
  ['C679', 'TO', 'CICONIO'],
  ['C680', 'VC', 'CIGLIANO'],
  ['C681', 'CN', 'CIGLIE\''],
  ['C684', 'PV', 'CIGOGNOLA'],
  ['C685', 'BS', 'CIGOLE'],
  ['C686', 'PV', 'CILAVEGNA'],
  ['Z603', 'EE', 'CILE'],
  ['C689', 'TV', 'CIMADOLMO'],
  ['C691', 'BS', 'CIMBERGO'],
  ['C694', 'TN', 'CIMEGO'],
  ['C695', 'RC', 'CIMINA\''],
  ['C696', 'PA', 'CIMINNA'],
  ['C697', 'NA', 'CIMITILE'],
  ['C699', 'PN', 'CIMOLAIS'],
  ['C700', 'TN', 'CIMONE'],
  ['Z210', 'EE', 'CINA REPUBBLICA POPOLARE'],
  ['C701', 'AT', 'CINAGLIO'],
  ['C702', 'RM', 'CINETO ROMANO'],
  ['C703', 'CR', 'CINGIA DE\' BOTTI'],
  ['C704', 'MC', 'CINGOLI'],
  ['C705', 'GR', 'CINIGIANO'],
  ['C707', 'MI', 'CINISELLO BALSAMO'],
  ['C708', 'PA', 'CINISI'],
  ['C709', 'SO', 'CINO'],
  ['C710', 'RC', 'CINQUEFRONDI'],
  ['C711', 'TO', 'CINTANO'],
  ['C712', 'TN', 'CINTE TESINO'],
  ['C714', 'VE', 'CINTO CAOMAGGIORE'],
  ['C713', 'PD', 'CINTO EUGANEO'],
  ['C715', 'TO', 'CINZANO'],
  ['C716', 'CE', 'CIORLANO'],
  ['C718', 'IM', 'CIPRESSA'],
  ['Z211', 'EE', 'CIPRO'],
  ['C719', 'BN', 'CIRCELLO'],
  ['C722', 'TO', 'CIRIE\''],
  ['C723', 'MT', 'CIRIGLIANO'],
  ['C724', 'CO', 'CIRIMIDO'],
  ['C725', 'KR', 'CIRO\''],
  ['C726', 'KR', 'CIRO\' MARINA'],
  ['C727', 'TN', 'CIS'],
  ['C728', 'BG', 'CISANO BERGAMASCO'],
  ['C729', 'SV', 'CISANO SUL NEVA'],
  ['C730', 'BG', 'CISERANO'],
  ['C732', 'VA', 'CISLAGO'],
  ['C733', 'MI', 'CISLIANO'],
  ['C734', 'VI', 'CISMON DEL GRAPPA'],
  ['C735', 'TV', 'CISON DI VALMARINO'],
  ['C738', 'CN', 'CISSONE'],
  ['C739', 'AT', 'CISTERNA D\'ASTI'],
  ['C740', 'LT', 'CISTERNA DI LATINA'],
  ['C741', 'BR', 'CISTERNINO'],
  ['C742', 'PG', 'CITERNA'],
  ['Z106', 'EE', 'CITTA\' DEL VATICANO'],
  ['C744', 'PG', 'CITTA\' DELLA PIEVE'],
  ['C745', 'PG', 'CITTA\' DI CASTELLO'],
  ['C750', 'PE', 'CITTA\' SANT\'ANGELO'],
  ['C743', 'PD', 'CITTADELLA'],
  ['C746', 'RI', 'CITTADUCALE'],
  ['C747', 'RC', 'CITTANOVA'],
  ['C749', 'RI', 'CITTAREALE'],
  ['C751', 'VA', 'CITTIGLIO'],
  ['C752', 'LC', 'CIVATE'],
  ['C754', 'CO', 'CIVENNA'],
  ['C755', 'IM', 'CIVEZZA'],
  ['C756', 'TN', 'CIVEZZANO'],
  ['C757', 'VC', 'CIVIASCO'],
  ['C758', 'UD', 'CIVIDALE DEL FRIULI'],
  ['C759', 'BG', 'CIVIDATE AL PIANO'],
  ['C760', 'BS', 'CIVIDATE CAMUNO'],
  ['C763', 'CS', 'CIVITA'],
  ['C765', 'VT', 'CIVITA CASTELLANA'],
  ['C766', 'AQ', 'CIVITA D\'ANTINO'],
  ['C764', 'CB', 'CIVITACAMPOMARANO'],
  ['C768', 'CH', 'CIVITALUPARELLA'],
  ['C769', 'IS', 'CIVITANOVA DEL SANNIO'],
  ['C770', 'MC', 'CIVITANOVA MARCHE'],
  ['C771', 'PE', 'CIVITAQUANA'],
  ['C773', 'RM', 'CIVITAVECCHIA'],
  ['C778', 'AQ', 'CIVITELLA ALFEDENA'],
  ['C779', 'PE', 'CIVITELLA CASANOVA'],
  ['C780', 'VT', 'CIVITELLA D\'AGLIANO'],
  ['C781', 'TE', 'CIVITELLA DEL TRONTO'],
  ['C777', 'FC', 'CIVITELLA DI ROMAGNA'],
  ['C774', 'AR', 'CIVITELLA IN VAL DI CHIANA'],
  ['C776', 'CH', 'CIVITELLA MESSER RAIMONDO'],
  ['C782', 'GR', 'CIVITELLA PAGANICO'],
  ['C783', 'AQ', 'CIVITELLA ROVETO'],
  ['C784', 'RM', 'CIVITELLA SAN PAOLO'],
  ['C785', 'SO', 'CIVO'],
  ['C787', 'CO', 'CLAINO CON OSTENO'],
  ['C790', 'PN', 'CLAUT'],
  ['C791', 'PN', 'CLAUZETTO'],
  ['C792', 'CN', 'CLAVESANA'],
  ['C793', 'TO', 'CLAVIERE'],
  ['C794', 'TN', 'CLES'],
  ['C795', 'CS', 'CLETO'],
  ['C796', 'VA', 'CLIVIO'],
  ['C797', 'TN', 'CLOZ'],
  ['C800', 'BG', 'CLUSONE'],
  ['C801', 'TO', 'COASSOLO TORINESE'],
  ['C803', 'TO', 'COAZZE'],
  ['C804', 'AT', 'COAZZOLO'],
  ['C806', 'BS', 'COCCAGLIO'],
  ['C807', 'AT', 'COCCONATO'],
  ['Z212', 'EE', 'COCOS (ISOLE)'],
  ['C810', 'VA', 'COCQUIO-TREVISAGO'],
  ['C811', 'AQ', 'COCULLO'],
  ['C812', 'PD', 'CODEVIGO'],
  ['C813', 'PV', 'CODEVILLA'],
  ['C814', 'FE', 'CODIGORO'],
  ['C815', 'TV', 'CODOGNE\''],
  ['C816', 'LO', 'CODOGNO'],
  ['C817', 'UD', 'CODROIPO'],
  ['C818', 'SS', 'CODRONGIANOS'],
  ['C819', 'BI', 'COGGIOLA'],
  ['C820', 'MB', 'COGLIATE'],
  ['C820', 'MI', 'COGLIATE'],
  ['C821', 'AO', 'COGNE'],
  ['C823', 'GE', 'COGOLETO'],
  ['C824', 'VI', 'COGOLLO DEL CENGIO'],
  ['C826', 'GE', 'COGORNO'],
  ['C829', 'NO', 'COLAZZA'],
  ['C830', 'PU', 'COLBORDOLO'],
  ['C835', 'BG', 'COLERE'],
  ['C836', 'FR', 'COLFELICE'],
  ['C838', 'PC', 'COLI'],
  ['C839', 'LC', 'COLICO'],
  ['C840', 'RE', 'COLLAGNA'],
  ['C841', 'RI', 'COLLALTO SABINO'],
  ['C844', 'AQ', 'COLLARMELE'],
  ['C845', 'PG', 'COLLAZZONE'],
  ['C851', 'LC', 'COLLE BRIANZA'],
  ['C854', 'CB', 'COLLE D\'ANCHISE'],
  ['C857', 'RI', 'COLLE DI TORA'],
  ['C847', 'SI', 'COLLE DI VAL D\'ELSA'],
  ['C870', 'FR', 'COLLE SAN MAGNO'],
  ['C846', 'BN', 'COLLE SANNITA'],
  ['C872', 'BL', 'COLLE SANTA LUCIA'],
  ['C848', 'TV', 'COLLE UMBERTO'],
  ['C850', 'BS', 'COLLEBEATO'],
  ['C852', 'PR', 'COLLECCHIO'],
  ['C853', 'PE', 'COLLECORVINO'],
  ['C311', 'TE', 'COLLEDARA'],
  ['C855', 'CH', 'COLLEDIMACINE'],
  ['C856', 'CH', 'COLLEDIMEZZO'],
  ['C858', 'RM', 'COLLEFERRO'],
  ['C859', 'RI', 'COLLEGIOVE'],
  ['C860', 'TO', 'COLLEGNO'],
  ['C862', 'AQ', 'COLLELONGO'],
  ['C864', 'FR', 'COLLEPARDO'],
  ['C865', 'LE', 'COLLEPASSO'],
  ['C866', 'AQ', 'COLLEPIETRO'],
  ['C867', 'TO', 'COLLERETTO CASTELNUOVO'],
  ['C868', 'TO', 'COLLERETTO GIACOSA'],
  ['C869', 'LI', 'COLLESALVETTI'],
  ['C871', 'PA', 'COLLESANO'],
  ['C875', 'CB', 'COLLETORTO'],
  ['C876', 'RI', 'COLLEVECCHIO'],
  ['C878', 'IS', 'COLLI A VOLTURNO'],
  ['C877', 'AP', 'COLLI DEL TRONTO'],
  ['C880', 'RI', 'COLLI SUL VELINO'],
  ['C879', 'SA', 'COLLIANO'],
  ['C882', 'CA', 'COLLINAS'],
  ['C883', 'BS', 'COLLIO'],
  ['C884', 'VC', 'COLLOBIANO'],
  ['C885', 'UD', 'COLLOREDO DI MONTE ALBANO'],
  ['C886', 'MC', 'COLMURANO'],
  ['C888', 'MT', 'COLOBRARO'],
  ['C890', 'VR', 'COLOGNA VENETA'],
  ['C893', 'BS', 'COLOGNE'],
  ['C894', 'BG', 'COLOGNO AL SERIO'],
  ['C895', 'MI', 'COLOGNO MONZESE'],
  ['C897', 'VR', 'COLOGNOLA AI COLLI'],
  ['Z604', 'EE', 'COLOMBIA'],
  ['C900', 'RM', 'COLONNA'],
  ['C901', 'TE', 'COLONNELLA'],
  ['C902', 'CO', 'COLONNO'],
  ['C903', 'SO', 'COLORINA'],
  ['C904', 'PR', 'COLORNO'],
  ['C905', 'CS', 'COLOSIMI'],
  ['C908', 'MI', 'COLTURANO'],
  ['C910', 'BG', 'COLZATE'],
  ['C911', 'VA', 'COMABBIO'],
  ['C912', 'FE', 'COMACCHIO'],
  ['C914', 'MS', 'COMANO'],
  ['C917', 'LO', 'COMAZZO'],
  ['C918', 'UD', 'COMEGLIANS'],
  ['C920', 'BL', 'COMELICO SUPERIORE'],
  ['C922', 'VA', 'COMERIO'],
  ['C925', 'BS', 'COMEZZANO-CIZZAGO'],
  ['C926', 'NO', 'COMIGNAGO'],
  ['C927', 'RG', 'COMISO'],
  ['C928', 'AG', 'COMITINI'],
  ['C929', 'NA', 'COMIZIANO'],
  ['C930', 'MN', 'COMMESSAGGIO'],
  ['C931', 'TN', 'COMMEZZADURA'],
  ['C933', 'CO', 'COMO'],
  ['Z310', 'EE', 'COMORE'],
  ['C934', 'PR', 'COMPIANO'],
  ['C937', 'BG', 'COMUN NUOVO'],
  ['C935', 'AP', 'COMUNANZA'],
  ['C938', 'VE', 'CONA'],
  ['C941', 'IS', 'CONCA CASALE'],
  ['C940', 'SA', 'CONCA DEI MARINI'],
  ['C939', 'CE', 'CONCA DELLA CAMPANIA'],
  ['C943', 'VR', 'CONCAMARISE'],
  ['C944', 'TN', 'CONCEI'],
  ['C946', 'RI', 'CONCERVIANO'],
  ['C948', 'BS', 'CONCESIO'],
  ['C949', 'VI', 'CONCO'],
  ['C950', 'VE', 'CONCORDIA SAGITTARIA'],
  ['C951', 'MO', 'CONCORDIA SULLA SECCHIA'],
  ['C952', 'MB', 'CONCOREZZO'],
  ['C952', 'MI', 'CONCOREZZO'],
  ['C953', 'TN', 'CONDINO'],
  ['C954', 'RC', 'CONDOFURI'],
  ['C955', 'TO', 'CONDOVE'],
  ['C956', 'ME', 'CONDRO\''],
  ['C957', 'TV', 'CONEGLIANO'],
  ['C958', 'PV', 'CONFIENZA'],
  ['C959', 'RI', 'CONFIGNI'],
  ['C960', 'CZ', 'CONFLENTI'],
  ['Z312', 'EE', 'CONGO REPUBBLICA DEMOCRATICA'],
  ['Z311', 'EE', 'CONGO REPUBBLICA POPOLARE'],
  ['C962', 'AL', 'CONIOLO'],
  ['C963', 'RA', 'CONSELICE'],
  ['C964', 'PD', 'CONSELVE'],
  ['C965', 'CO', 'CONSIGLIO DI RUMO'],
  ['C968', 'PA', 'CONTESSA ENTELLINA'],
  ['C969', 'RI', 'CONTIGLIANO'],
  ['C971', 'AV', 'CONTRADA'],
  ['C972', 'TE', 'CONTROGUERRA'],
  ['C973', 'SA', 'CONTRONE'],
  ['C974', 'SA', 'CONTURSI TERME'],
  ['C975', 'BA', 'CONVERSANO'],
  ['C976', 'AV', 'CONZA DELLA CAMPANIA'],
  ['C977', 'AL', 'CONZANO'],
  ['Z703', 'EE', 'COOK (ISOLE)'],
  ['C978', 'LE', 'COPERTINO'],
  ['C979', 'PV', 'COPIANO'],
  ['C980', 'FE', 'COPPARO'],
  ['C982', 'PV', 'CORANA'],
  ['C983', 'BA', 'CORATO'],
  ['C984', 'SA', 'CORBARA'],
  ['C986', 'MI', 'CORBETTA'],
  ['C987', 'RO', 'CORBOLA'],
  ['C988', 'VT', 'CORCHIANO'],
  ['C990', 'PG', 'CORCIANO'],
  ['C991', 'PN', 'CORDENONS'],
  ['C992', 'TV', 'CORDIGNANO'],
  ['C993', 'PN', 'CORDOVADO'],
  ['Z214', 'EE', 'COREA DEL NORD'],
  ['C994', 'TN', 'COREDO'],
  ['C996', 'LU', 'COREGLIA ANTELMINELLI'],
  ['C995', 'GE', 'COREGLIA LIGURE'],
  ['C998', 'FR', 'CORENO AUSONIO'],
  ['C999', 'AQ', 'CORFINIO'],
  ['D003', 'LT', 'CORI'],
  ['D004', 'RN', 'CORIANO'],
  ['D005', 'CS', 'CORIGLIANO CALABRO'],
  ['D006', 'LE', 'CORIGLIANO D\'OTRANTO'],
  ['D007', 'AN', 'CORINALDO'],
  ['D008', 'TO', 'CORIO'],
  ['D009', 'PA', 'CORLEONE'],
  ['D011', 'SA', 'CORLETO MONFORTE'],
  ['D010', 'PZ', 'CORLETO PERTICARA'],
  ['D013', 'MI', 'CORMANO'],
  ['D014', 'GO', 'CORMONS'],
  ['D015', 'BG', 'CORNA IMAGNA'],
  ['D016', 'BG', 'CORNALBA'],
  ['D017', 'PV', 'CORNALE'],
  ['D018', 'MI', 'CORNAREDO'],
  ['D019', 'MB', 'CORNATE D\'ADDA'],
  ['D019', 'MI', 'CORNATE D\'ADDA'],
  ['B799', 'BZ', 'CORNEDO ALL\'ISARCO'],
  ['D020', 'VI', 'CORNEDO VICENTINO'],
  ['D021', 'LO', 'CORNEGLIANO LAUDENSE'],
  ['D022', 'CN', 'CORNELIANO D\'ALBA'],
  ['D026', 'PR', 'CORNIGLIO'],
  ['D027', 'UD', 'CORNO DI ROSAZZO'],
  ['D028', 'LO', 'CORNO GIOVINE'],
  ['D029', 'LO', 'CORNOVECCHIO'],
  ['D030', 'TV', 'CORNUDA'],
  ['D037', 'RE', 'CORREGGIO'],
  ['D038', 'MB', 'CORREZZANA'],
  ['D038', 'MI', 'CORREZZANA'],
  ['D040', 'PD', 'CORREZZOLA'],
  ['D041', 'CO', 'CORRIDO'],
  ['D042', 'MC', 'CORRIDONIA'],
  ['D043', 'TE', 'CORROPOLI'],
  ['D044', 'LE', 'CORSANO'],
  ['D045', 'MI', 'CORSICO'],
  ['D046', 'AT', 'CORSIONE'],
  ['D048', 'BZ', 'CORTACCIA SULLA STRADA DEL VINO'],
  ['D049', 'CZ', 'CORTALE'],
  ['D050', 'AT', 'CORTANDONE'],
  ['D051', 'AT', 'CORTANZE'],
  ['D052', 'AT', 'CORTAZZONE'],
  ['D054', 'PC', 'CORTE BRUGNATELLA'],
  ['D056', 'CR', 'CORTE DE\' CORTESI CON CIGNONE'],
  ['D057', 'CR', 'CORTE DE\' FRATI'],
  ['D058', 'BS', 'CORTE FRANCA'],
  ['D068', 'LO', 'CORTE PALASIO'],
  ['D061', 'PC', 'CORTEMAGGIORE'],
  ['D062', 'CN', 'CORTEMILIA'],
  ['D064', 'BS', 'CORTENO GOLGI'],
  ['D065', 'LC', 'CORTENOVA'],
  ['D066', 'BG', 'CORTENUOVA'],
  ['D067', 'PV', 'CORTEOLONA'],
  ['D072', 'AT', 'CORTIGLIONE'],
  ['A266', 'BL', 'CORTINA D\'AMPEZZO'],
  ['D075', 'BZ', 'CORTINA SULLA STRADA DEL VINO'],
  ['D076', 'TE', 'CORTINO'],
  ['D077', 'AR', 'CORTONA'],
  ['D078', 'PE', 'CORVARA'],
  ['D079', 'BZ', 'CORVARA IN BADIA'],
  ['D081', 'PV', 'CORVINO SAN QUIRICO'],
  ['D082', 'BS', 'CORZANO'],
  ['D085', 'UD', 'COSEANO'],
  ['D086', 'CS', 'COSENZA'],
  ['D087', 'IM', 'COSIO D\'ARROSCIA'],
  ['D088', 'SO', 'COSIO VALTELLINO'],
  ['D089', 'RC', 'COSOLETO'],
  ['D093', 'CN', 'COSSANO BELBO'],
  ['D092', 'TO', 'COSSANO CANAVESE'],
  ['D094', 'BI', 'COSSATO'],
  ['D095', 'SV', 'COSSERIA'],
  ['D096', 'AP', 'COSSIGNANO'],
  ['D099', 'VB', 'COSSOGNO'],
  ['D100', 'SS', 'COSSOINE'],
  ['D101', 'AT', 'COSSOMBRATO'],
  ['Z313', 'EE', 'COSTA D\'AVORIO'],
  ['D109', 'PV', 'COSTA DE\' NOBILI'],
  ['D110', 'BG', 'COSTA DI MEZZATE'],
  ['D105', 'RO', 'COSTA DI ROVIGO'],
  ['D111', 'BG', 'COSTA DI SERINA'],
  ['D112', 'LC', 'COSTA MASNAGA'],
  ['Z503', 'EE', 'COSTA RICA'],
  ['D103', 'BG', 'COSTA VALLE IMAGNA'],
  ['D102', 'AL', 'COSTA VESCOVATO'],
  ['D117', 'BG', 'COSTA VOLPINO'],
  ['D107', 'VI', 'COSTABISSARA'],
  ['D108', 'PG', 'COSTACCIARO'],
  ['D113', 'VC', 'COSTANZANA'],
  ['D114', 'IM', 'COSTARAINERA'],
  ['D118', 'VR', 'COSTERMANO'],
  ['D119', 'AT', 'COSTIGLIOLE D\'ASTI'],
  ['D120', 'CN', 'COSTIGLIOLE SALUZZO'],
  ['D121', 'RA', 'COTIGNOLA'],
  ['D123', 'KR', 'COTRONEI'],
  ['D124', 'RI', 'COTTANELLO'],
  ['D012', 'AO', 'COURMAYEUR'],
  ['D126', 'BG', 'COVO'],
  ['D127', 'PV', 'COZZO'],
  ['D128', 'MT', 'CRACO'],
  ['D131', 'LC', 'CRANDOLA VALSASSINA'],
  ['D132', 'VC', 'CRAVAGLIANA'],
  ['D133', 'CN', 'CRAVANZANA'],
  ['D134', 'VB', 'CRAVEGGIA'],
  ['D136', 'VI', 'CREAZZO'],
  ['D137', 'CH', 'CRECCHIO'],
  ['D139', 'BG', 'CREDARO'],
  ['D141', 'CR', 'CREDERA RUBBIANO'],
  ['D142', 'CR', 'CREMA'],
  ['D143', 'LC', 'CREMELLA'],
  ['D144', 'VA', 'CREMENAGA'],
  ['D145', 'LC', 'CREMENO'],
  ['D147', 'CO', 'CREMIA'],
  ['D149', 'AL', 'CREMOLINO'],
  ['D150', 'CR', 'CREMONA'],
  ['D151', 'CR', 'CREMOSANO'],
  ['D154', 'VC', 'CRESCENTINO'],
  ['D156', 'VI', 'CRESPADORO'],
  ['D157', 'TV', 'CRESPANO DEL GRAPPA'],
  ['D158', 'BO', 'CRESPELLANO'],
  ['D159', 'LO', 'CRESPIATICA'],
  ['D160', 'PI', 'CRESPINA'],
  ['D161', 'RO', 'CRESPINO'],
  ['D162', 'NO', 'CRESSA'],
  ['D165', 'BI', 'CREVACUORE'],
  ['D166', 'BO', 'CREVALCORE'],
  ['D168', 'VB', 'CREVOLADOSSOLA'],
  ['D170', 'NA', 'CRISPANO'],
  ['D171', 'TA', 'CRISPIANO'],
  ['D172', 'CN', 'CRISSOLO'],
  ['Z149', 'EE', 'CROAZIA'],
  ['D175', 'GE', 'CROCEFIESCHI'],
  ['C670', 'TV', 'CROCETTA DEL MONTELLO'],
  ['D177', 'VB', 'CRODO'],
  ['D179', 'TE', 'CROGNALETO'],
  ['D180', 'CS', 'CROPALATI'],
  ['D181', 'CZ', 'CROPANI'],
  ['D182', 'BI', 'CROSA'],
  ['D184', 'CS', 'CROSIA'],
  ['D185', 'VA', 'CROSIO DELLA VALLE'],
  ['D122', 'KR', 'CROTONE'],
  ['D186', 'CR', 'CROTTA D\'ADDA'],
  ['D187', 'VC', 'CROVA'],
  ['D188', 'TN', 'CROVIANA'],
  ['D189', 'KR', 'CRUCOLI'],
  ['D192', 'VA', 'CUASSO AL MONTE'],
  ['Z504', 'EE', 'CUBA'],
  ['D194', 'AL', 'CUCCARO MONFERRATO'],
  ['D195', 'SA', 'CUCCARO VETERE'],
  ['D196', 'CO', 'CUCCIAGO'],
  ['D197', 'TO', 'CUCEGLIO'],
  ['D198', 'MI', 'CUGGIONO'],
  ['D199', 'VA', 'CUGLIATE-FABIASCO'],
  ['D200', 'OR', 'CUGLIERI'],
  ['D201', 'PE', 'CUGNOLI'],
  ['D202', 'TO', 'CUMIANA'],
  ['D203', 'CR', 'CUMIGNANO SUL NAVIGLIO'],
  ['D204', 'VA', 'CUNARDO'],
  ['D205', 'CN', 'CUNEO'],
  ['D206', 'TN', 'CUNEVO'],
  ['D207', 'AT', 'CUNICO'],
  ['D208', 'TO', 'CUORGNE\''],
  ['D209', 'CH', 'CUPELLO'],
  ['D210', 'AP', 'CUPRA MARITTIMA'],
  ['D211', 'AN', 'CUPRAMONTANA'],
  ['B824', 'PV', 'CURA CARPIGNANO'],
  ['D214', 'OR', 'CURCURIS'],
  ['D216', 'NO', 'CUREGGIO'],
  ['D217', 'VA', 'CURIGLIA CON MONTEVIASCO'],
  ['D218', 'CZ', 'CURINGA'],
  ['D219', 'BI', 'CURINO'],
  ['D221', 'BG', 'CURNO'],
  ['D222', 'BZ', 'CURON VENOSTA'],
  ['D223', 'LE', 'CURSI'],
  ['D225', 'VB', 'CURSOLO-ORASSO'],
  ['D226', 'PD', 'CURTAROLO'],
  ['D227', 'MN', 'CURTATONE'],
  ['D228', 'CE', 'CURTI'],
  ['D229', 'MI', 'CUSAGO'],
  ['D231', 'MI', 'CUSANO MILANINO'],
  ['D230', 'BN', 'CUSANO MUTRI'],
  ['D232', 'CO', 'CUSINO'],
  ['D233', 'BG', 'CUSIO'],
  ['D234', 'TP', 'CUSTONACI'],
  ['D235', 'PT', 'CUTIGLIANO'],
  ['D236', 'KR', 'CUTRO'],
  ['D237', 'LE', 'CUTROFIANO'],
  ['D238', 'VA', 'CUVEGLIO'],
  ['D239', 'VA', 'CUVIO'],
  ['D243', 'TN', 'DAIANO'],
  ['D244', 'MI', 'DAIRAGO'],
  ['D245', 'BG', 'DALMINE'],
  ['D246', 'TN', 'DAMBEL'],
  ['Z107', 'EE', 'DANIMARCA'],
  ['D247', 'BL', 'DANTA DI CADORE'],
  ['D248', 'TN', 'DAONE'],
  ['D250', 'TN', 'DARE\''],
  ['D251', 'BS', 'DARFO BOARIO TERME'],
  ['D253', 'VV', 'DASA\''],
  ['D255', 'GE', 'DAVAGNA'],
  ['D256', 'VA', 'DAVERIO'],
  ['D257', 'CZ', 'DAVOLI'],
  ['D258', 'SO', 'DAZIO'],
  ['D259', 'CA', 'DECIMOMANNU'],
  ['D260', 'CA', 'DECIMOPUTZU'],
  ['D261', 'CZ', 'DECOLLATURA'],
  ['D264', 'SV', 'DEGO'],
  ['D265', 'SP', 'DEIVA MARINA'],
  ['D266', 'SO', 'DELEBIO'],
  ['D267', 'CL', 'DELIA'],
  ['D268', 'RC', 'DELIANUOVA'],
  ['D269', 'FG', 'DELICETO'],
  ['D270', 'BS', 'DELLO'],
  ['D271', 'CN', 'DEMONTE'],
  ['D272', 'AL', 'DENICE'],
  ['D273', 'TN', 'DENNO'],
  ['D277', 'AL', 'DERNICE'],
  ['D278', 'CR', 'DEROVERE'],
  ['D279', 'PG', 'DERUTA'],
  ['D280', 'LC', 'DERVIO'],
  ['D281', 'VC', 'DESANA'],
  ['D284', 'BS', 'DESENZANO DEL GARDA'],
  ['D286', 'MB', 'DESIO'],
  ['D286', 'MI', 'DESIO'],
  ['D287', 'NU', 'DESULO'],
  ['D289', 'CS', 'DIAMANTE'],
  ['D293', 'IM', 'DIANO ARENTINO'],
  ['D296', 'IM', 'DIANO CASTELLO'],
  ['D291', 'CN', 'DIANO D\'ALBA'],
  ['D297', 'IM', 'DIANO MARINA'],
  ['D298', 'IM', 'DIANO SAN PIETRO'],
  ['D299', 'FI', 'DICOMANO'],
  ['D300', 'UD', 'DIGNANO'],
  ['D302', 'TN', 'DIMARO'],
  ['D303', 'VV', 'DINAMI'],
  ['Z900', 'EE', 'DIPENDENZE AUSTRALIANE'],
  ['Z901', 'EE', 'DIPENDENZE BRITANNICHE'],
  ['Z800', 'EE', 'DIPENDENZE CANADESI'],
  ['Z902', 'EE', 'DIPENDENZE FRANCESI'],
  ['Z903', 'EE', 'DIPENDENZE NEOZELANDESI'],
  ['Z904', 'EE', 'DIPENDENZE NORVEGESI ANTARTICHE'],
  ['Z801', 'EE', 'DIPENDENZE NORVEGESI ARTICHE'],
  ['Z802', 'EE', 'DIPENDENZE RUSSE'],
  ['Z905', 'EE', 'DIPENDENZE STATUNITENSI'],
  ['Z906', 'EE', 'DIPENDENZE SUDAFRICANE'],
  ['D304', 'CS', 'DIPIGNANO'],
  ['D305', 'LE', 'DISO'],
  ['D309', 'NO', 'DIVIGNANO'],
  ['D310', 'CO', 'DIZZASCO'],
  ['D311', 'BZ', 'DOBBIACO'],
  ['D312', 'GO', 'DOBERDO\' DEL LAGO'],
  ['D314', 'CN', 'DOGLIANI'],
  ['D315', 'CH', 'DOGLIOLA'],
  ['D316', 'UD', 'DOGNA'],
  ['D317', 'VR', 'DOLCE\''],
  ['D318', 'IM', 'DOLCEACQUA'],
  ['D319', 'IM', 'DOLCEDO'],
  ['D321', 'GO', 'DOLEGNA DEL COLLIO'],
  ['D323', 'CA', 'DOLIANOVA'],
  ['D325', 'VE', 'DOLO'],
  ['D327', 'LC', 'DOLZAGO'],
  ['D328', 'CS', 'DOMANICO'],
  ['D329', 'CO', 'DOMASO'],
  ['D330', 'BL', 'DOMEGGE DI CADORE'],
  ['D331', 'AV', 'DOMICELLA'],
  ['Z526', 'EE', 'DOMINICA'],
  ['D332', 'VB', 'DOMODOSSOLA'],
  ['D333', 'CA', 'DOMUS DE MARIA'],
  ['D334', 'CA', 'DOMUSNOVAS'],
  ['D336', 'TN', 'DON'],
  ['D339', 'BI', 'DONATO'],
  ['D341', 'CO', 'DONGO'],
  ['D338', 'AO', 'DONNAS'],
  ['D344', 'CA', 'DONORI'],
  ['D345', 'NU', 'DORGALI'],
  ['D346', 'LC', 'DORIO'],
  ['D347', 'NO', 'DORMELLETTO'],
  ['D348', 'PV', 'DORNO'],
  ['D349', 'TN', 'DORSINO'],
  ['D350', 'BI', 'DORZANO'],
  ['D351', 'MN', 'DOSOLO'],
  ['D352', 'BG', 'DOSSENA'],
  ['D355', 'CO', 'DOSSO DEL LIRO'],
  ['D356', 'AO', 'DOUES'],
  ['D357', 'FC', 'DOVADOLA'],
  ['D358', 'CR', 'DOVERA'],
  ['D360', 'BO', 'DOZZA'],
  ['D361', 'CE', 'DRAGONI'],
  ['D364', 'VV', 'DRAPIA'],
  ['D365', 'TN', 'DRENA'],
  ['D366', 'UD', 'DRENCHIA'],
  ['D367', 'MI', 'DRESANO'],
  ['D369', 'CO', 'DREZZO'],
  ['D370', 'CR', 'DRIZZONA'],
  ['D371', 'TN', 'DRO'],
  ['D372', 'CN', 'DRONERO'],
  ['D373', 'TO', 'DRUENTO'],
  ['D374', 'VB', 'DRUOGNO'],
  ['D376', 'NU', 'DUALCHI'],
  ['D377', 'SO', 'DUBINO'],
  ['M300', 'PD', 'DUE CARRARE'],
  ['D379', 'VI', 'DUEVILLE'],
  ['D380', 'BN', 'DUGENTA'],
  ['D383', 'TS', 'DUINO-AURISINA'],
  ['D384', 'VA', 'DUMENZA'],
  ['D385', 'VA', 'DUNO'],
  ['D386', 'BN', 'DURAZZANO'],
  ['C772', 'CB', 'DURONIA'],
  ['D388', 'AT', 'DUSINO SAN MICHELE'],
  ['D390', 'SA', 'EBOLI'],
  ['Z605', 'EE', 'ECUADOR'],
  ['D391', 'BS', 'EDOLO'],
  ['Z336', 'EE', 'EGITTO'],
  ['D392', 'BZ', 'EGNA'],
  ['Z506', 'EE', 'EL SALVADOR'],
  ['D394', 'PE', 'ELICE'],
  ['D395', 'NU', 'ELINI'],
  ['D398', 'LC', 'ELLO'],
  ['D399', 'CA', 'ELMAS'],
  ['D401', 'CN', 'ELVA'],
  ['D402', 'AO', 'EMARESE'],
  ['Z215', 'EE', 'EMIRATI ARABI UNITI'],
  ['D403', 'FI', 'EMPOLI'],
  ['D406', 'BG', 'ENDINE GAIANO'],
  ['D407', 'VI', 'ENEGO'],
  ['D408', 'UD', 'ENEMONZO'],
  ['C342', 'EN', 'ENNA'],
  ['D410', 'CN', 'ENTRACQUE'],
  ['D411', 'BG', 'ENTRATICO'],
  ['D412', 'CN', 'ENVIE'],
  ['D414', 'PZ', 'EPISCOPIA'],
  ['D415', 'VE', 'ERACLEA'],
  ['D416', 'CO', 'ERBA'],
  ['D419', 'VR', 'ERBE\''],
  ['D420', 'VR', 'ERBEZZO'],
  ['D421', 'BS', 'ERBUSCO'],
  ['D422', 'BR', 'ERCHIE'],
  ['H243', 'NA', 'ERCOLANO'],
  ['D423', 'TP', 'ERICE'],
  ['Z368', 'EE', 'ERITREA'],
  ['D424', 'SV', 'ERLI'],
  ['D426', 'PN', 'ERTO E CASSO'],
  ['M292', 'SS', 'ERULA'],
  ['D428', 'LC', 'ERVE'],
  ['D429', 'MC', 'ESANATOGLIA'],
  ['D430', 'NU', 'ESCALAPLANO'],
  ['D431', 'NU', 'ESCOLCA'],
  ['D434', 'BS', 'ESINE'],
  ['D436', 'LC', 'ESINO LARIO'],
  ['D440', 'FR', 'ESPERIA'],
  ['D441', 'SS', 'ESPORLATU'],
  ['D442', 'PD', 'ESTE'],
  ['D443', 'NU', 'ESTERZILI'],
  ['Z144', 'EE', 'ESTONIA'],
  ['Z315', 'EE', 'ETIOPIA'],
  ['D444', 'AO', 'ETROUBLES'],
  ['D445', 'CO', 'EUPILIO'],
  ['D433', 'TO', 'EXILLES'],
  ['D447', 'AL', 'FABBRICA CURONE'],
  ['D449', 'LU', 'FABBRICHE DI VALLICO'],
  ['D450', 'RE', 'FABBRICO'],
  ['D451', 'AN', 'FABRIANO'],
  ['D452', 'VT', 'FABRICA DI ROMA'],
  ['D453', 'VV', 'FABRIZIA'],
  ['D454', 'TR', 'FABRO'],
  ['D455', 'UD', 'FAEDIS'],
  ['D457', 'TN', 'FAEDO'],
  ['D456', 'SO', 'FAEDO VALTELLINO'],
  ['D458', 'RA', 'FAENZA'],
  ['Z108', 'EE', 'FAER OER (ISOLE)'],
  ['D459', 'FG', 'FAETO'],
  ['D461', 'UD', 'FAGAGNA'],
  ['D462', 'CO', 'FAGGETO LARIO'],
  ['D463', 'TA', 'FAGGIANO'],
  ['D465', 'AQ', 'FAGNANO ALTO'],
  ['D464', 'CS', 'FAGNANO CASTELLO'],
  ['D467', 'VA', 'FAGNANO OLONA'],
  ['D468', 'TN', 'FAI DELLA PAGANELLA'],
  ['D469', 'BN', 'FAICCHIO'],
  ['D470', 'BL', 'FALCADE'],
  ['D471', 'CE', 'FALCIANO DEL MASSICO'],
  ['D473', 'CS', 'FALCONARA ALBANESE'],
  ['D472', 'AN', 'FALCONARA MARITTIMA'],
  ['D474', 'ME', 'FALCONE'],
  ['D475', 'VT', 'FALERIA'],
  ['D476', 'CZ', 'FALERNA'],
  ['D477', 'FM', 'FALERONE'],
  ['D477', 'AP', 'FALERONE'],
  ['Z609', 'EE', 'FALKLAND (ISOLE)'],
  ['D480', 'CH', 'FALLO'],
  ['D481', 'VB', 'FALMENTA'],
  ['D482', 'CO', 'FALOPPIO'],
  ['D483', 'FR', 'FALVATERRA'],
  ['D484', 'BZ', 'FALZES'],
  ['D486', 'MO', 'FANANO'],
  ['D487', 'PN', 'FANNA'],
  ['D488', 'PU', 'FANO'],
  ['D489', 'TE', 'FANO ADRIANO'],
  ['D494', 'CH', 'FARA FILIORUM PETRI'],
  ['D490', 'BG', 'FARA GERA D\'ADDA'],
  ['D493', 'RI', 'FARA IN SABINA'],
  ['D492', 'NO', 'FARA NOVARESE'],
  ['D491', 'BG', 'FARA OLIVANA CON SOLA'],
  ['D495', 'CH', 'FARA SAN MARTINO'],
  ['D496', 'VI', 'FARA VICENTINO'],
  ['D497', 'PZ', 'FARDELLA'],
  ['D499', 'CN', 'FARIGLIANO'],
  ['D501', 'PE', 'FARINDOLA'],
  ['D502', 'PC', 'FARINI'],
  ['D503', 'VT', 'FARNESE'],
  ['D506', 'BL', 'FARRA D\'ALPAGO'],
  ['D504', 'GO', 'FARRA D\'ISONZO'],
  ['D505', 'TV', 'FARRA DI SOLIGO'],
  ['D508', 'BR', 'FASANO'],
  ['D509', 'GE', 'FASCIA'],
  ['D510', 'PI', 'FAUGLIA'],
  ['D511', 'CN', 'FAULE'],
  ['D512', 'GE', 'FAVALE DI MALVARO'],
  ['D514', 'AG', 'FAVARA'],
  ['D516', 'TN', 'FAVER'],
  ['D518', 'TP', 'FAVIGNANA'],
  ['D520', 'TO', 'FAVRIA'],
  ['Z154', 'EE', 'FEDERAZIONE RUSSA'],
  ['D523', 'CN', 'FEISOGLIO'],
  ['D524', 'TO', 'FELETTO'],
  ['D526', 'PR', 'FELINO'],
  ['D527', 'SA', 'FELITTO'],
  ['D528', 'AL', 'FELIZZANO'],
  ['D529', 'MN', 'FELONICA'],
  ['D530', 'BL', 'FELTRE'],
  ['D531', 'CO', 'FENEGRO\''],
  ['D532', 'TO', 'FENESTRELLE'],
  ['D537', 'AO', 'FENIS'],
  ['D538', 'TR', 'FERENTILLO'],
  ['D539', 'FR', 'FERENTINO'],
  ['D540', 'SR', 'FERLA'],
  ['D541', 'PU', 'FERMIGNANO'],
  ['D542', 'FM', 'FERMO'],
  ['D542', 'AP', 'FERMO'],
  ['D543', 'VA', 'FERNO'],
  ['D544', 'CZ', 'FEROLETO ANTICO'],
  ['D545', 'RC', 'FEROLETO DELLA CHIESA'],
  ['D547', 'MT', 'FERRANDINA'],
  ['D548', 'FE', 'FERRARA'],
  ['D549', 'VR', 'FERRARA DI MONTE BALDO'],
  ['D550', 'CB', 'FERRAZZANO'],
  ['D551', 'VA', 'FERRERA DI VARESE'],
  ['D552', 'PV', 'FERRERA ERBOGNONE'],
  ['D554', 'AT', 'FERRERE'],
  ['D555', 'PC', 'FERRIERE'],
  ['D557', 'RC', 'FERRUZZANO'],
  ['D560', 'RI', 'FIAMIGNANO'],
  ['D562', 'TO', 'FIANO'],
  ['D561', 'RM', 'FIANO ROMANO'],
  ['D564', 'MC', 'FIASTRA'],
  ['D565', 'TN', 'FIAVE\''],
  ['D567', 'PA', 'FICARAZZI'],
  ['D568', 'RO', 'FICAROLO'],
  ['D569', 'ME', 'FICARRA'],
  ['D570', 'TR', 'FICULLE'],
  ['B034', 'PR', 'FIDENZA'],
  ['D571', 'BZ', 'FIE\' ALLO SCILIAR'],
  ['D572', 'TN', 'FIERA DI PRIMIERO'],
  ['D573', 'TN', 'FIEROZZO'],
  ['D574', 'CR', 'FIESCO'],
  ['D575', 'FI', 'FIESOLE'],
  ['D576', 'BS', 'FIESSE'],
  ['D578', 'VE', 'FIESSO D\'ARTICO'],
  ['D577', 'RO', 'FIESSO UMBERTIANO'],
  ['D579', 'CO', 'FIGINO SERENZA'],
  ['D583', 'FI', 'FIGLINE VALDARNO'],
  ['D582', 'CS', 'FIGLINE VEGLIATURO'],
  ['D586', 'RM', 'FILACCIANO'],
  ['D587', 'VV', 'FILADELFIA'],
  ['D588', 'BG', 'FILAGO'],
  ['D589', 'VV', 'FILANDARI'],
  ['D590', 'MS', 'FILATTIERA'],
  ['D591', 'FR', 'FILETTINO'],
  ['D592', 'CH', 'FILETTO'],
  ['D593', 'PZ', 'FILIANO'],
  ['D594', 'PV', 'FILIGHERA'],
  ['D595', 'IS', 'FILIGNANO'],
  ['Z216', 'EE', 'FILIPPINE'],
  ['D596', 'VV', 'FILOGASO'],
  ['D597', 'AN', 'FILOTTRANO'],
  ['D599', 'MO', 'FINALE EMILIA'],
  ['D600', 'SV', 'FINALE LIGURE'],
  ['Z109', 'EE', 'FINLANDIA'],
  ['D604', 'BG', 'FINO DEL MONTE'],
  ['D605', 'CO', 'FINO MORNASCO'],
  ['D606', 'BG', 'FIORANO AL SERIO'],
  ['D608', 'TO', 'FIORANO CANAVESE'],
  ['D607', 'MO', 'FIORANO MODENESE'],
  ['D609', 'MC', 'FIORDIMONTE'],
  ['D611', 'PC', 'FIORENZUOLA D\'ARDA'],
  ['D612', 'FI', 'FIRENZE'],
  ['D613', 'FI', 'FIRENZUOLA'],
  ['D614', 'CS', 'FIRMO'],
  ['D615', 'SA', 'FISCIANO'],
  ['A310', 'FR', 'FIUGGI'],
  ['D617', 'MO', 'FIUMALBO'],
  ['D619', 'RC', 'FIUMARA'],
  ['D621', 'PN', 'FIUME VENETO'],
  ['D622', 'ME', 'FIUMEDINISI'],
  ['D624', 'CS', 'FIUMEFREDDO BRUZIO'],
  ['D623', 'CT', 'FIUMEFREDDO DI SICILIA'],
  ['D627', 'UD', 'FIUMICELLO'],
  ['M297', 'RM', 'FIUMICINO'],
  ['D628', 'MC', 'FIUMINATA'],
  ['D629', 'MS', 'FIVIZZANO'],
  ['D630', 'UD', 'FLAIBANO'],
  ['D631', 'TN', 'FLAVON'],
  ['D634', 'BS', 'FLERO'],
  ['D635', 'ME', 'FLORESTA'],
  ['D636', 'SR', 'FLORIDIA'],
  ['D637', 'SS', 'FLORINAS'],
  ['D638', 'AV', 'FLUMERI'],
  ['D639', 'CA', 'FLUMINIMAGGIORE'],
  ['D640', 'NU', 'FLUSSIO'],
  ['D641', 'VC', 'FOBELLO'],
  ['D643', 'FG', 'FOGGIA'],
  ['D644', 'BN', 'FOGLIANISE'],
  ['D645', 'GO', 'FOGLIANO REDIPUGLIA'],
  ['D646', 'TO', 'FOGLIZZO'],
  ['D649', 'AR', 'FOIANO DELLA CHIANA'],
  ['D650', 'BN', 'FOIANO DI VAL FORTORE'],
  ['D651', 'TN', 'FOLGARIA'],
  ['D652', 'AP', 'FOLIGNANO'],
  ['D653', 'PG', 'FOLIGNO'],
  ['D654', 'TV', 'FOLLINA'],
  ['D655', 'SP', 'FOLLO'],
  ['D656', 'GR', 'FOLLONICA'],
  ['D660', 'LO', 'FOMBIO'],
  ['D661', 'ME', 'FONDACHELLI-FANTINA'],
  ['D662', 'LT', 'FONDI'],
  ['D663', 'TN', 'FONDO'],
  ['D665', 'NU', 'FONNI'],
  ['D666', 'AO', 'FONTAINEMORE'],
  ['D667', 'FR', 'FONTANA LIRI'],
  ['D670', 'PN', 'FONTANAFREDDA'],
  ['D671', 'AV', 'FONTANAROSA'],
  ['D668', 'BO', 'FONTANELICE'],
  ['D672', 'BG', 'FONTANELLA'],
  ['D673', 'PR', 'FONTANELLATO'],
  ['D674', 'TV', 'FONTANELLE'],
  ['D675', 'NO', 'FONTANETO D\'AGOGNA'],
  ['D676', 'VC', 'FONTANETTO PO'],
  ['D677', 'GE', 'FONTANIGORDA'],
  ['D678', 'AT', 'FONTANILE'],
  ['D679', 'PD', 'FONTANIVA'],
  ['D680', 'TV', 'FONTE'],
  ['M309', 'RM', 'FONTE NUOVA'],
  ['D681', 'AQ', 'FONTECCHIO'],
  ['D682', 'FR', 'FONTECHIARI'],
  ['D683', 'CE', 'FONTEGRECA'],
  ['D684', 'BG', 'FONTENO'],
  ['D685', 'PR', 'FONTEVIVO'],
  ['D686', 'BL', 'FONZASO'],
  ['D688', 'BG', 'FOPPOLO'],
  ['D689', 'RI', 'FORANO'],
  ['D691', 'AP', 'FORCE'],
  ['D693', 'BN', 'FORCHIA'],
  ['D694', 'SO', 'FORCOLA'],
  ['D695', 'OR', 'FORDONGIANUS'],
  ['D696', 'PZ', 'FORENZA'],
  ['D697', 'BG', 'FORESTO SPARSO'],
  ['D700', 'UD', 'FORGARIA NEL FRIULI'],
  ['D701', 'AV', 'FORINO'],
  ['D702', 'NA', 'FORIO'],
  ['D704', 'FC', 'FORLI\''],
  ['D703', 'IS', 'FORLI\' DEL SANNIO'],
  ['D705', 'FC', 'FORLIMPOPOLI'],
  ['D706', 'VB', 'FORMAZZA'],
  ['D707', 'RM', 'FORMELLO'],
  ['D708', 'LT', 'FORMIA'],
  ['D709', 'CE', 'FORMICOLA'],
  ['D710', 'CR', 'FORMIGARA'],
  ['D711', 'MO', 'FORMIGINE'],
  ['D712', 'VC', 'FORMIGLIANA'],
  ['D713', 'FE', 'FORMIGNANA'],
  ['D714', 'TN', 'FORNACE'],
  ['D715', 'IS', 'FORNELLI'],
  ['D718', 'UD', 'FORNI AVOLTRI'],
  ['D719', 'UD', 'FORNI DI SOPRA'],
  ['D720', 'UD', 'FORNI DI SOTTO'],
  ['D725', 'TO', 'FORNO CANAVESE'],
  ['D726', 'BL', 'FORNO DI ZOLDO'],
  ['D728', 'PR', 'FORNOVO DI TARO'],
  ['D727', 'BG', 'FORNOVO SAN GIOVANNI'],
  ['D730', 'LU', 'FORTE DEI MARMI'],
  ['D731', 'BZ', 'FORTEZZA'],
  ['D732', 'PV', 'FORTUNAGO'],
  ['D733', 'ME', 'FORZA D\'AGRO\''],
  ['D734', 'LU', 'FOSCIANDORA'],
  ['D735', 'MS', 'FOSDINOVO'],
  ['D736', 'AQ', 'FOSSA'],
  ['D738', 'CH', 'FOSSACESIA'],
  ['D740', 'VE', 'FOSSALTA DI PIAVE'],
  ['D741', 'VE', 'FOSSALTA DI PORTOGRUARO'],
  ['D737', 'CB', 'FOSSALTO'],
  ['D742', 'CN', 'FOSSANO'],
  ['D745', 'PG', 'FOSSATO DI VICO'],
  ['D744', 'CZ', 'FOSSATO SERRALTA'],
  ['D748', 'VE', 'FOSSO\''],
  ['D749', 'PU', 'FOSSOMBRONE'],
  ['D750', 'VI', 'FOZA'],
  ['D751', 'CN', 'FRABOSA SOPRANA'],
  ['D752', 'CN', 'FRABOSA SOTTANA'],
  ['D559', 'AL', 'FRACONALTO'],
  ['D754', 'TA', 'FRAGAGNANO'],
  ['D755', 'BN', 'FRAGNETO L\'ABATE'],
  ['D756', 'BN', 'FRAGNETO MONFORTE'],
  ['D757', 'CH', 'FRAINE'],
  ['D758', 'SP', 'FRAMURA'],
  ['D763', 'CH', 'FRANCAVILLA AL MARE'],
  ['D762', 'VV', 'FRANCAVILLA ANGITOLA'],
  ['D759', 'AL', 'FRANCAVILLA BISIO'],
  ['D760', 'FM', 'FRANCAVILLA D\'ETE'],
  ['D760', 'AP', 'FRANCAVILLA D\'ETE'],
  ['D765', 'ME', 'FRANCAVILLA DI SICILIA'],
  ['D761', 'BR', 'FRANCAVILLA FONTANA'],
  ['D766', 'PZ', 'FRANCAVILLA IN SINNI'],
  ['D764', 'CS', 'FRANCAVILLA MARITTIMA'],
  ['Z110', 'EE', 'FRANCIA'],
  ['D767', 'VV', 'FRANCICA'],
  ['D768', 'SR', 'FRANCOFONTE'],
  ['D769', 'CE', 'FRANCOLISE'],
  ['D770', 'AL', 'FRASCARO'],
  ['D771', 'PV', 'FRASCAROLO'],
  ['D773', 'RM', 'FRASCATI'],
  ['D774', 'CS', 'FRASCINETO'],
  ['D775', 'TN', 'FRASSILONGO'],
  ['D776', 'RO', 'FRASSINELLE POLESINE'],
  ['D777', 'AL', 'FRASSINELLO MONFERRATO'],
  ['D780', 'AL', 'FRASSINETO PO'],
  ['D781', 'TO', 'FRASSINETTO'],
  ['D782', 'CN', 'FRASSINO'],
  ['D783', 'MO', 'FRASSINORO'],
  ['D785', 'RI', 'FRASSO SABINO'],
  ['D784', 'BN', 'FRASSO TELESINO'],
  ['D788', 'RO', 'FRATTA POLESINE'],
  ['D787', 'PG', 'FRATTA TODINA'],
  ['D789', 'NA', 'FRATTAMAGGIORE'],
  ['D790', 'NA', 'FRATTAMINORE'],
  ['D791', 'PU', 'FRATTE ROSA'],
  ['D793', 'ME', 'FRAZZANO\''],
  ['D794', 'TV', 'FREGONA'],
  ['D796', 'CH', 'FRESAGRANDINARIA'],
  ['D797', 'AL', 'FRESONARA'],
  ['D798', 'AV', 'FRIGENTO'],
  ['D799', 'CE', 'FRIGNANO'],
  ['D802', 'AT', 'FRINCO'],
  ['D803', 'CH', 'FRISA'],
  ['D804', 'PN', 'FRISANCO'],
  ['D805', 'TO', 'FRONT'],
  ['D807', 'PU', 'FRONTINO'],
  ['D808', 'PU', 'FRONTONE'],
  ['D810', 'FR', 'FROSINONE'],
  ['D811', 'IS', 'FROSOLONE'],
  ['D812', 'TO', 'FROSSASCO'],
  ['D813', 'AL', 'FRUGAROLO'],
  ['D814', 'AL', 'FUBINE'],
  ['D815', 'FI', 'FUCECCHIO'],
  ['D817', 'BG', 'FUIPIANO VALLE IMAGNA'],
  ['D818', 'VR', 'FUMANE'],
  ['D819', 'FR', 'FUMONE'],
  ['D821', 'BZ', 'FUNES'],
  ['D823', 'CH', 'FURCI'],
  ['D824', 'ME', 'FURCI SICULO'],
  ['D825', 'ME', 'FURNARI'],
  ['D826', 'SA', 'FURORE'],
  ['D827', 'CA', 'FURTEI'],
  ['D828', 'CS', 'FUSCALDO'],
  ['D829', 'RA', 'FUSIGNANO'],
  ['D830', 'SO', 'FUSINE'],
  ['D832', 'SA', 'FUTANI'],
  ['D834', 'CR', 'GABBIONETA BINANUOVA'],
  ['D835', 'AL', 'GABIANO'],
  ['D836', 'PU', 'GABICCE MARE'],
  ['Z316', 'EE', 'GABON'],
  ['D839', 'AO', 'GABY'],
  ['D841', 'CR', 'GADESCO PIEVE DELMONA'],
  ['D842', 'NU', 'GADONI'],
  ['D843', 'LT', 'GAETA'],
  ['D844', 'ME', 'GAGGI'],
  ['D845', 'MI', 'GAGGIANO'],
  ['D847', 'BO', 'GAGGIO MONTANO'],
  ['D848', 'BI', 'GAGLIANICO'],
  ['D850', 'AQ', 'GAGLIANO ATERNO'],
  ['D849', 'EN', 'GAGLIANO CASTELFERRATO'],
  ['D851', 'LE', 'GAGLIANO DEL CAPO'],
  ['D852', 'CZ', 'GAGLIATO'],
  ['D853', 'MC', 'GAGLIOLE'],
  ['D854', 'TV', 'GAIARINE'],
  ['D855', 'RO', 'GAIBA'],
  ['D856', 'CN', 'GAIOLA'],
  ['D858', 'SI', 'GAIOLE IN CHIANTI'],
  ['D859', 'NU', 'GAIRO'],
  ['D860', 'BZ', 'GAIS'],
  ['D861', 'ME', 'GALATI MAMERTINO'],
  ['D862', 'LE', 'GALATINA'],
  ['D863', 'LE', 'GALATONE'],
  ['D864', 'RC', 'GALATRO'],
  ['D865', 'LC', 'GALBIATE'],
  ['D867', 'FC', 'GALEATA'],
  ['D868', 'LO', 'GALGAGNANO'],
  ['D869', 'VA', 'GALLARATE'],
  ['D870', 'VT', 'GALLESE'],
  ['D872', 'NO', 'GALLIATE'],
  ['D871', 'VA', 'GALLIATE LOMBARDO'],
  ['D873', 'PV', 'GALLIAVOLA'],
  ['D874', 'LU', 'GALLICANO'],
  ['D875', 'RM', 'GALLICANO NEL LAZIO'],
  ['D876', 'PZ', 'GALLICCHIO'],
  ['D878', 'BO', 'GALLIERA'],
  ['D879', 'PD', 'GALLIERA VENETA'],
  ['D881', 'FR', 'GALLINARO'],
  ['D882', 'VI', 'GALLIO'],
  ['D883', 'LE', 'GALLIPOLI'],
  ['D884', 'CE', 'GALLO MATESE'],
  ['D885', 'ME', 'GALLODORO'],
  ['D886', 'CE', 'GALLUCCIO'],
  ['D888', 'NU', 'GALTELLI'],
  ['D889', 'PD', 'GALZIGNANO TERME'],
  ['D890', 'AL', 'GAMALERO'],
  ['D891', 'BS', 'GAMBARA'],
  ['D892', 'PV', 'GAMBARANA'],
  ['D894', 'CN', 'GAMBASCA'],
  ['D895', 'FI', 'GAMBASSI TERME'],
  ['D896', 'CB', 'GAMBATESA'],
  ['D897', 'VI', 'GAMBELLARA'],
  ['D898', 'CH', 'GAMBERALE'],
  ['D899', 'FC', 'GAMBETTOLA'],
  ['Z317', 'EE', 'GAMBIA'],
  ['D901', 'PV', 'GAMBOLO\''],
  ['D902', 'VI', 'GAMBUGLIANO'],
  ['D903', 'BG', 'GANDELLINO'],
  ['D905', 'BG', 'GANDINO'],
  ['D906', 'BG', 'GANDOSSO'],
  ['D907', 'PA', 'GANGI'],
  ['D909', 'MT', 'GARAGUSO'],
  ['D910', 'AL', 'GARBAGNA'],
  ['D911', 'NO', 'GARBAGNA NOVARESE'],
  ['D912', 'MI', 'GARBAGNATE MILANESE'],
  ['D913', 'LC', 'GARBAGNATE MONASTERO'],
  ['D915', 'VR', 'GARDA'],
  ['D917', 'BS', 'GARDONE RIVIERA'],
  ['D918', 'BS', 'GARDONE VAL TROMPIA'],
  ['D920', 'CN', 'GARESSIO'],
  ['D921', 'NO', 'GARGALLO'],
  ['D923', 'BZ', 'GARGAZZONE'],
  ['D924', 'BS', 'GARGNANO'],
  ['D925', 'PV', 'GARLASCO'],
  ['D926', 'LC', 'GARLATE'],
  ['D927', 'SV', 'GARLENDA'],
  ['D928', 'TN', 'GARNIGA TERME'],
  ['D930', 'CO', 'GARZENO'],
  ['D931', 'TO', 'GARZIGLIANA'],
  ['D932', 'CZ', 'GASPERINA'],
  ['D933', 'TO', 'GASSINO TORINESE'],
  ['D934', 'RE', 'GATTATICO'],
  ['D935', 'FC', 'GATTEO'],
  ['D937', 'NO', 'GATTICO'],
  ['D938', 'VC', 'GATTINARA'],
  ['D940', 'BS', 'GAVARDO'],
  ['D941', 'AL', 'GAVAZZANA'],
  ['D942', 'RO', 'GAVELLO'],
  ['D943', 'BG', 'GAVERINA TERME'],
  ['D944', 'AL', 'GAVI'],
  ['D945', 'RM', 'GAVIGNANO'],
  ['D946', 'VA', 'GAVIRATE'],
  ['D947', 'NU', 'GAVOI'],
  ['D948', 'GR', 'GAVORRANO'],
  ['Z218', 'EE', 'GAZA (TERRITORIO DI)'],
  ['D949', 'MN', 'GAZOLDO DEGLI IPPOLITI'],
  ['D951', 'VA', 'GAZZADA SCHIANNO'],
  ['D952', 'BG', 'GAZZANIGA'],
  ['D956', 'PD', 'GAZZO PADOVANO'],
  ['D957', 'VR', 'GAZZO VERONESE'],
  ['D958', 'PC', 'GAZZOLA'],
  ['D959', 'MN', 'GAZZUOLO'],
  ['D960', 'CL', 'GELA'],
  ['D961', 'RN', 'GEMMANO'],
  ['D962', 'UD', 'GEMONA DEL FRIULI'],
  ['D963', 'VA', 'GEMONIO'],
  ['D964', 'RM', 'GENAZZANO'],
  ['D965', 'AN', 'GENGA'],
  ['D966', 'CR', 'GENIVOLTA'],
  ['D967', 'CN', 'GENOLA'],
  ['D968', 'NU', 'GENONI'],
  ['D969', 'GE', 'GENOVA'],
  ['D970', 'CA', 'GENURI'],
  ['D971', 'PZ', 'GENZANO DI LUCANIA'],
  ['D972', 'RM', 'GENZANO DI ROMA'],
  ['D973', 'PV', 'GENZONE'],
  ['Z254', 'EE', 'GEORGIA'],
  ['D974', 'CO', 'GERA LARIO'],
  ['D975', 'RC', 'GERACE'],
  ['D977', 'PA', 'GERACI SICULO'],
  ['D978', 'RM', 'GERANO'],
  ['D980', 'PV', 'GERENZAGO'],
  ['D981', 'VA', 'GERENZANO'],
  ['D982', 'NU', 'GERGEI'],
  ['D983', 'TO', 'GERMAGNANO'],
  ['D984', 'VB', 'GERMAGNO'],
  ['Z112', 'EE', 'GERMANIA'],
  ['D986', 'CO', 'GERMASINO'],
  ['D987', 'VA', 'GERMIGNAGA'],
  ['D988', 'VV', 'GEROCARNE'],
  ['D990', 'SO', 'GEROLA ALTA'],
  ['D991', 'BG', 'GEROSA'],
  ['D993', 'CR', 'GERRE DE\' CAPRIOLI'],
  ['D994', 'CA', 'GESICO'],
  ['D995', 'MI', 'GESSATE'],
  ['D996', 'CH', 'GESSOPALENA'],
  ['D997', 'CA', 'GESTURI'],
  ['D998', 'AV', 'GESUALDO'],
  ['Z318', 'EE', 'GHANA'],
  ['D999', 'BS', 'GHEDI'],
  ['E001', 'NO', 'GHEMME'],
  ['E003', 'VB', 'GHIFFA'],
  ['E004', 'OR', 'GHILARZA'],
  ['E006', 'BG', 'GHISALBA'],
  ['E007', 'VC', 'GHISLARENGO'],
  ['E008', 'RO', 'GIACCIANO CON BARUCHELLA'],
  ['E009', 'TO', 'GIAGLIONE'],
  ['Z507', 'EE', 'GIAMAICA'],
  ['E010', 'BS', 'GIANICO'],
  ['E012', 'PG', 'GIANO DELL\'UMBRIA'],
  ['E011', 'CE', 'GIANO VETUSTO'],
  ['Z219', 'EE', 'GIAPPONE'],
  ['E013', 'PA', 'GIARDINELLO'],
  ['E014', 'ME', 'GIARDINI-NAXOS'],
  ['E015', 'AL', 'GIAROLE'],
  ['E016', 'RG', 'GIARRATANA'],
  ['E017', 'CT', 'GIARRE'],
  ['E019', 'SS', 'GIAVE'],
  ['E020', 'TO', 'GIAVENO'],
  ['E021', 'TV', 'GIAVERA DEL MONTELLO'],
  ['E022', 'CA', 'GIBA'],
  ['E023', 'TP', 'GIBELLINA'],
  ['Z113', 'EE', 'GIBILTERRA'],
  ['Z361', 'EE', 'GIBUTI'],
  ['E024', 'BI', 'GIFFLENGA'],
  ['E025', 'RC', 'GIFFONE'],
  ['E026', 'SA', 'GIFFONI SEI CASALI'],
  ['E027', 'SA', 'GIFFONI VALLE PIANA'],
  ['E028', 'VB', 'GIGNESE'],
  ['E029', 'AO', 'GIGNOD'],
  ['E030', 'CB', 'GILDONE'],
  ['E031', 'CZ', 'GIMIGLIANO'],
  ['E033', 'PZ', 'GINESTRA'],
  ['E034', 'BN', 'GINESTRA DEGLI SCHIAVONI'],
  ['E036', 'TA', 'GINOSA'],
  ['E037', 'SA', 'GIOI'],
  ['E040', 'AQ', 'GIOIA DEI MARSI'],
  ['E038', 'BA', 'GIOIA DEL COLLE'],
  ['E039', 'CE', 'GIOIA SANNITICA'],
  ['E041', 'RC', 'GIOIA TAURO'],
  ['E044', 'RC', 'GIOIOSA IONICA'],
  ['E043', 'ME', 'GIOIOSA MAREA'],
  ['Z220', 'EE', 'GIORDANIA'],
  ['E045', 'TR', 'GIOVE'],
  ['E047', 'BA', 'GIOVINAZZO'],
  ['E048', 'TN', 'GIOVO'],
  ['E049', 'NU', 'GIRASOLE'],
  ['E050', 'CZ', 'GIRIFALCO'],
  ['E051', 'CO', 'GIRONICO'],
  ['E052', 'CH', 'GISSI'],
  ['E053', 'LE', 'GIUGGIANELLO'],
  ['E054', 'NA', 'GIUGLIANO IN CAMPANIA'],
  ['E055', 'PA', 'GIULIANA'],
  ['E057', 'FR', 'GIULIANO DI ROMA'],
  ['E056', 'CH', 'GIULIANO TEATINO'],
  ['E058', 'TE', 'GIULIANOVA'],
  ['E059', 'LU', 'GIUNCUGNANO'],
  ['E060', 'SA', 'GIUNGANO'],
  ['E061', 'LE', 'GIURDIGNANO'],
  ['E062', 'PV', 'GIUSSAGO'],
  ['E063', 'MB', 'GIUSSANO'],
  ['E063', 'MI', 'GIUSSANO'],
  ['E064', 'SV', 'GIUSTENICE'],
  ['E065', 'TN', 'GIUSTINO'],
  ['E066', 'SV', 'GIUSVALLA'],
  ['E067', 'TO', 'GIVOLETTO'],
  ['E068', 'CZ', 'GIZZERIA'],
  ['E069', 'BZ', 'GLORENZA'],
  ['E071', 'TV', 'GODEGA DI SANT\'URBANO'],
  ['E072', 'PV', 'GODIASCO'],
  ['E074', 'PA', 'GODRANO'],
  ['E078', 'MN', 'GOITO'],
  ['E079', 'VA', 'GOLASECCA'],
  ['E081', 'PV', 'GOLFERENZO'],
  ['M274', 'SS', 'GOLFO ARANCI'],
  ['E082', 'CR', 'GOMBITO'],
  ['E083', 'UD', 'GONARS'],
  ['E084', 'CA', 'GONI'],
  ['E086', 'CA', 'GONNESA'],
  ['E087', 'OR', 'GONNOSCODINA'],
  ['E085', 'CA', 'GONNOSFANADIGA'],
  ['D585', 'OR', 'GONNOSNO\''],
  ['E088', 'OR', 'GONNOSTRAMATZA'],
  ['E089', 'MN', 'GONZAGA'],
  ['E090', 'SO', 'GORDONA'],
  ['E091', 'RM', 'GORGA'],
  ['E092', 'TV', 'GORGO AL MONTICANO'],
  ['E093', 'MT', 'GORGOGLIONE'],
  ['E094', 'MI', 'GORGONZOLA'],
  ['E096', 'AQ', 'GORIANO SICOLI'],
  ['E098', 'GO', 'GORIZIA'],
  ['E101', 'VA', 'GORLA MAGGIORE'],
  ['E102', 'VA', 'GORLA MINORE'],
  ['E100', 'BG', 'GORLAGO'],
  ['E103', 'BG', 'GORLE'],
  ['E104', 'VA', 'GORNATE-OLONA'],
  ['E106', 'BG', 'GORNO'],
  ['E107', 'FE', 'GORO'],
  ['E109', 'GE', 'GORRETO'],
  ['E111', 'CN', 'GORZEGNO'],
  ['E113', 'BL', 'GOSALDO'],
  ['E114', 'PC', 'GOSSOLENGO'],
  ['E115', 'CN', 'GOTTASECCA'],
  ['E116', 'BS', 'GOTTOLENGO'],
  ['E118', 'CN', 'GOVONE'],
  ['E120', 'NO', 'GOZZANO'],
  ['E122', 'PU', 'GRADARA'],
  ['E124', 'GO', 'GRADISCA D\'ISONZO'],
  ['E125', 'GO', 'GRADO'],
  ['E126', 'VT', 'GRADOLI'],
  ['E127', 'LO', 'GRAFFIGNANA'],
  ['E128', 'VT', 'GRAFFIGNANO'],
  ['E130', 'BI', 'GRAGLIA'],
  ['E131', 'NA', 'GRAGNANO'],
  ['E132', 'PC', 'GRAGNANO TREBBIENSE'],
  ['E133', 'CT', 'GRAMMICHELE'],
  ['E134', 'AT', 'GRANA'],
  ['E135', 'BO', 'GRANAGLIONE'],
  ['E136', 'BO', 'GRANAROLO DELL\'EMILIA'],
  ['E138', 'VI', 'GRANCONA'],
  ['E139', 'CO', 'GRANDATE'],
  ['E141', 'CO', 'GRANDOLA ED UNITI'],
  ['E142', 'ME', 'GRANITI'],
  ['E143', 'NO', 'GRANOZZO CON MONTICELLO'],
  ['E144', 'VA', 'GRANTOLA'],
  ['E145', 'PD', 'GRANTORTO'],
  ['E146', 'PD', 'GRANZE'],
  ['E147', 'MT', 'GRASSANO'],
  ['E148', 'BG', 'GRASSOBBIO'],
  ['E149', 'PA', 'GRATTERI'],
  ['E150', 'TN', 'GRAUNO'],
  ['E151', 'CO', 'GRAVEDONA'],
  ['E152', 'PV', 'GRAVELLONA LOMELLINA'],
  ['E153', 'VB', 'GRAVELLONA TOCE'],
  ['E154', 'TO', 'GRAVERE'],
  ['E156', 'CT', 'GRAVINA DI CATANIA'],
  ['E155', 'BA', 'GRAVINA IN PUGLIA'],
  ['E158', 'CE', 'GRAZZANISE'],
  ['E159', 'AT', 'GRAZZANO BADOGLIO'],
  ['E160', 'RI', 'GRECCIO'],
  ['E161', 'AV', 'GRECI'],
  ['Z115', 'EE', 'GRECIA'],
  ['E163', 'VC', 'GREGGIO'],
  ['E164', 'AL', 'GREMIASCO'],
  ['Z524', 'EE', 'GRENADA'],
  ['E165', 'AO', 'GRESSAN'],
  ['E167', 'AO', 'GRESSONEY-LA-TRINITE\''],
  ['E168', 'AO', 'GRESSONEY-SAINT-JEAN'],
  ['E169', 'FI', 'GREVE IN CHIANTI'],
  ['E170', 'MI', 'GREZZAGO'],
  ['E171', 'VR', 'GREZZANA'],
  ['E172', 'CO', 'GRIANTE'],
  ['E173', 'CE', 'GRICIGNANO DI AVERSA'],
  ['E177', 'NO', 'GRIGNASCO'],
  ['E178', 'TN', 'GRIGNO'],
  ['E179', 'UD', 'GRIMACCO'],
  ['E180', 'CS', 'GRIMALDI'],
  ['E182', 'CN', 'GRINZANE CAVOUR'],
  ['E184', 'VI', 'GRISIGNANO DI ZOCCO'],
  ['E185', 'CS', 'GRISOLIA'],
  ['E187', 'BO', 'GRIZZANA MORANDI'],
  ['Z402', 'EE', 'GROENLANDIA'],
  ['E188', 'AL', 'GROGNARDO'],
  ['E189', 'BG', 'GROMO'],
  ['E191', 'AL', 'GRONDONA'],
  ['E192', 'BG', 'GRONE'],
  ['E193', 'CR', 'GRONTARDO'],
  ['E195', 'PV', 'GROPELLO CAIROLI'],
  ['E196', 'PC', 'GROPPARELLO'],
  ['E199', 'TO', 'GROSCAVALLO'],
  ['E200', 'SO', 'GROSIO'],
  ['E201', 'SO', 'GROSOTTO'],
  ['E202', 'GR', 'GROSSETO'],
  ['E203', 'TO', 'GROSSO'],
  ['E204', 'RM', 'GROTTAFERRATA'],
  ['E205', 'TA', 'GROTTAGLIE'],
  ['E206', 'AV', 'GROTTAMINARDA'],
  ['E207', 'AP', 'GROTTAMMARE'],
  ['E208', 'FM', 'GROTTAZZOLINA'],
  ['E208', 'AP', 'GROTTAZZOLINA'],
  ['E209', 'AG', 'GROTTE'],
  ['E210', 'VT', 'GROTTE DI CASTRO'],
  ['E212', 'RC', 'GROTTERIA'],
  ['E213', 'MT', 'GROTTOLE'],
  ['E214', 'AV', 'GROTTOLELLA'],
  ['E215', 'VE', 'GRUARO'],
  ['E216', 'TO', 'GRUGLIASCO'],
  ['E217', 'CR', 'GRUMELLO CREMONESE ED UNITI'],
  ['E219', 'BG', 'GRUMELLO DEL MONTE'],
  ['E221', 'PZ', 'GRUMENTO NOVA'],
  ['E222', 'TN', 'GRUMES'],
  ['E223', 'BA', 'GRUMO APPULA'],
  ['E224', 'NA', 'GRUMO NEVANO'],
  ['E226', 'VI', 'GRUMOLO DELLE ABBADESSE'],
  ['Z508', 'EE', 'GUADALUPA'],
  ['E227', 'LE', 'GUAGNANO'],
  ['E228', 'MC', 'GUALDO'],
  ['E229', 'PG', 'GUALDO CATTANEO'],
  ['E230', 'PG', 'GUALDO TADINO'],
  ['E232', 'RE', 'GUALTIERI'],
  ['E233', 'ME', 'GUALTIERI SICAMINO\''],
  ['Z706', 'EE', 'GUAM (ISOLA)'],
  ['E234', 'CA', 'GUAMAGGIORE'],
  ['E235', 'CO', 'GUANZATE'],
  ['E236', 'FR', 'GUARCINO'],
  ['E240', 'RO', 'GUARDA VENETA'],
  ['E237', 'VC', 'GUARDABOSONE'],
  ['E238', 'LO', 'GUARDAMIGLIO'],
  ['E239', 'CZ', 'GUARDAVALLE'],
  ['E241', 'TR', 'GUARDEA'],
  ['E245', 'AV', 'GUARDIA LOMBARDI'],
  ['E246', 'PZ', 'GUARDIA PERTICARA'],
  ['E242', 'CS', 'GUARDIA PIEMONTESE'],
  ['E249', 'BN', 'GUARDIA SANFRAMONDI'],
  ['E243', 'CH', 'GUARDIAGRELE'],
  ['E244', 'CB', 'GUARDIALFIERA'],
  ['E248', 'CB', 'GUARDIAREGIA'],
  ['E250', 'PI', 'GUARDISTALLO'],
  ['E251', 'CN', 'GUARENE'],
  ['E252', 'CA', 'GUASILA'],
  ['E253', 'RE', 'GUASTALLA'],
  ['Z509', 'EE', 'GUATEMALA'],
  ['E255', 'AL', 'GUAZZORA'],
  ['E256', 'PG', 'GUBBIO'],
  ['E258', 'MI', 'GUDO VISCONTI'],
  ['E259', 'CB', 'GUGLIONESI'],
  ['Z607', 'EE', 'GUIANA FRANCESE'],
  ['E261', 'MN', 'GUIDIZZOLO'],
  ['E263', 'RM', 'GUIDONIA MONTECELIO'],
  ['E264', 'MO', 'GUIGLIA'],
  ['E266', 'CH', 'GUILMI'],
  ['Z319', 'EE', 'GUINEA'],
  ['Z320', 'EE', 'GUINEA BISSAU'],
  ['Z321', 'EE', 'GUINEA EQUATORIALE'],
  ['E269', 'VB', 'GURRO'],
  ['E270', 'CA', 'GUSPINI'],
  ['E271', 'BS', 'GUSSAGO'],
  ['E272', 'CR', 'GUSSOLA'],
  ['Z606', 'EE', 'GUYANA'],
  ['Z510', 'EE', 'HAITI'],
  ['Z511', 'EE', 'HONDURAS'],
  ['E273', 'AO', 'HONE'],
  ['E280', 'BS', 'IDRO'],
  ['E281', 'CA', 'IGLESIAS'],
  ['E282', 'CN', 'IGLIANO'],
  ['E283', 'NU', 'ILBONO'],
  ['E284', 'VR', 'ILLASI'],
  ['E285', 'SS', 'ILLORAI'],
  ['E287', 'LC', 'IMBERSAGO'],
  ['E288', 'TN', 'IMER'],
  ['E289', 'BO', 'IMOLA'],
  ['E290', 'IM', 'IMPERIA'],
  ['E291', 'FI', 'IMPRUNETA'],
  ['E292', 'VA', 'INARZO'],
  ['E296', 'FI', 'INCISA IN VAL D\'ARNO'],
  ['E295', 'AT', 'INCISA SCAPACCINO'],
  ['E297', 'BS', 'INCUDINE'],
  ['Z222', 'EE', 'INDIA'],
  ['Z223', 'EE', 'INDONESIA'],
  ['E299', 'VA', 'INDUNO OLONA'],
  ['E301', 'TO', 'INGRIA'],
  ['E304', 'VB', 'INTRAGNA'],
  ['E305', 'LC', 'INTROBIO'],
  ['E306', 'AO', 'INTROD'],
  ['E307', 'AQ', 'INTRODACQUA'],
  ['E308', 'LC', 'INTROZZO'],
  ['E309', 'CO', 'INVERIGO'],
  ['E310', 'PV', 'INVERNO E MONTELEONE'],
  ['E311', 'TO', 'INVERSO PINASCA'],
  ['E313', 'MI', 'INVERUNO'],
  ['E314', 'NO', 'INVORIO'],
  ['E317', 'MI', 'INZAGO'],
  ['E321', 'VV', 'IONADI'],
  ['Z224', 'EE', 'IRAN'],
  ['Z225', 'EE', 'IRAQ'],
  ['E323', 'NU', 'IRGOLI'],
  ['Z707', 'EE', 'IRIAN OCCIDENTALE'],
  ['Z116', 'EE', 'IRLANDA'],
  ['E325', 'BS', 'IRMA'],
  ['E326', 'MT', 'IRSINA'],
  ['E327', 'CN', 'ISASCA'],
  ['E328', 'CZ', 'ISCA SULLO IONIO'],
  ['E329', 'NA', 'ISCHIA'],
  ['E330', 'VT', 'ISCHIA DI CASTRO'],
  ['E332', 'FG', 'ISCHITELLA'],
  ['E333', 'BS', 'ISEO'],
  ['E334', 'TN', 'ISERA'],
  ['E335', 'IS', 'ISERNIA'],
  ['E336', 'NU', 'ISILI'],
  ['Z117', 'EE', 'ISLANDA'],
  ['E337', 'PA', 'ISNELLO'],
  ['E338', 'AT', 'ISOLA D\'ASTI'],
  ['E341', 'GE', 'ISOLA DEL CANTONE'],
  ['E348', 'GR', 'ISOLA DEL GIGLIO'],
  ['E343', 'TE', 'ISOLA DEL GRAN SASSO D\'ITALIA'],
  ['E340', 'FR', 'ISOLA DEL LIRI'],
  ['E351', 'PU', 'ISOLA DEL PIANO'],
  ['E349', 'VR', 'ISOLA DELLA SCALA'],
  ['E350', 'PA', 'ISOLA DELLE FEMMINE'],
  ['E339', 'KR', 'ISOLA DI CAPO RIZZUTO'],
  ['E353', 'BG', 'ISOLA DI FONDRA'],
  ['E356', 'CR', 'ISOLA DOVARESE'],
  ['E358', 'VR', 'ISOLA RIZZA'],
  ['E360', 'AL', 'ISOLA SANT\'ANTONIO'],
  ['E354', 'VI', 'ISOLA VICENTINA'],
  ['E345', 'TO', 'ISOLABELLA'],
  ['E346', 'IM', 'ISOLABONA'],
  ['Z721', 'EE', 'ISOLE CILENE (PASQUA E SALA Y GOMEZ)'],
  ['Z124', 'EE', 'ISOLE DEL CANALE'],
  ['Z727', 'EE', 'ISOLE DELL\'UNIONE'],
  ['E363', 'FG', 'ISOLE TREMITI'],
  ['E364', 'BS', 'ISORELLA'],
  ['E365', 'SA', 'ISPANI'],
  ['E366', 'RG', 'ISPICA'],
  ['E367', 'VA', 'ISPRA'],
  ['Z226', 'EE', 'ISRAELE'],
  ['E368', 'TO', 'ISSIGLIO'],
  ['E369', 'AO', 'ISSIME'],
  ['E370', 'BG', 'ISSO'],
  ['E371', 'AO', 'ISSOGNE'],
  ['E373', 'TV', 'ISTRANA'],
  ['E374', 'ME', 'ITALA'],
  ['E375', 'LT', 'ITRI'],
  ['E376', 'SS', 'ITTIREDDU'],
  ['E377', 'SS', 'ITTIRI'],
  ['E378', 'TN', 'IVANO FRACENA'],
  ['E379', 'TO', 'IVREA'],
  ['E380', 'CR', 'IZANO'],
  ['E274', 'CZ', 'JACURSO'],
  ['E381', 'CB', 'JELSI'],
  ['E382', 'RM', 'JENNE'],
  ['E386', 'VA', 'JERAGO CON ORAGO'],
  ['E387', 'NU', 'JERZU'],
  ['E388', 'AN', 'JESI'],
  ['C388', 'VE', 'JESOLO'],
  ['E320', 'FE', 'JOLANDA DI SAVOIA'],
  ['E389', 'VV', 'JOPPOLO'],
  ['E390', 'AG', 'JOPPOLO GIANCAXIO'],
  ['E391', 'AO', 'JOVENCAN'],
  ['Z255', 'EE', 'KAZAKHSTAN'],
  ['Z322', 'EE', 'KENYA'],
  ['Z256', 'EE', 'KIRGHIZISTAN'],
  ['Z731', 'EE', 'KIRIBATI'],
  ['Z160', 'EE', 'KOSOVO'],
  ['Z227', 'EE', 'KUWAIT'],
  ['A345', 'AQ', 'L\'AQUILA'],
  ['E394', 'TO', 'LA CASSA'],
  ['E423', 'TO', 'LA LOGGIA'],
  ['E425', 'SS', 'LA MADDALENA'],
  ['A308', 'AO', 'LA MAGDELEINE'],
  ['E430', 'CN', 'LA MORRA'],
  ['Z324', 'EE', 'LA REUNION (ISOLA)'],
  ['E458', 'AO', 'LA SALLE'],
  ['E463', 'SP', 'LA SPEZIA'],
  ['E470', 'AO', 'LA THUILE'],
  ['E491', 'BZ', 'LA VALLE'],
  ['E490', 'BL', 'LA VALLE AGORDINA'],
  ['E392', 'RM', 'LABICO'],
  ['E393', 'RI', 'LABRO'],
  ['E395', 'MI', 'LACCHIARELLA'],
  ['E396', 'NA', 'LACCO AMENO'],
  ['E397', 'AV', 'LACEDONIA'],
  ['E398', 'BZ', 'LACES'],
  ['E400', 'NU', 'LACONI'],
  ['M212', 'RM', 'LADISPOLI'],
  ['E401', 'SS', 'LAERRU'],
  ['E402', 'RC', 'LAGANADI'],
  ['E403', 'VI', 'LAGHI'],
  ['E405', 'CO', 'LAGLIO'],
  ['E406', 'CN', 'LAGNASCO'],
  ['E407', 'CS', 'LAGO'],
  ['E409', 'PZ', 'LAGONEGRO'],
  ['E410', 'FE', 'LAGOSANTO'],
  ['E412', 'BZ', 'LAGUNDO'],
  ['E414', 'SV', 'LAIGUEGLIA'],
  ['E415', 'MI', 'LAINATE'],
  ['E416', 'CO', 'LAINO'],
  ['E417', 'CS', 'LAINO BORGO'],
  ['E419', 'CS', 'LAINO CASTELLO'],
  ['E420', 'BZ', 'LAION'],
  ['E421', 'BZ', 'LAIVES'],
  ['E413', 'PI', 'LAJATICO'],
  ['E422', 'BG', 'LALLIO'],
  ['E424', 'CH', 'LAMA DEI PELIGNI'],
  ['E426', 'MO', 'LAMA MOCOGNO'],
  ['E428', 'CO', 'LAMBRUGO'],
  ['M208', 'CZ', 'LAMEZIA TERME'],
  ['E429', 'BL', 'LAMON'],
  ['E431', 'AG', 'LAMPEDUSA E LINOSA'],
  ['E432', 'PT', 'LAMPORECCHIO'],
  ['E433', 'VC', 'LAMPORO'],
  ['E434', 'BZ', 'LANA'],
  ['E435', 'CH', 'LANCIANO'],
  ['E436', 'NO', 'LANDIONA'],
  ['E437', 'PV', 'LANDRIANO'],
  ['E438', 'PR', 'LANGHIRANO'],
  ['E439', 'PV', 'LANGOSCO'],
  ['E441', 'NU', 'LANUSEI'],
  ['C767', 'RM', 'LANUVIO'],
  ['E443', 'SO', 'LANZADA'],
  ['E444', 'CO', 'LANZO D\'INTELVI'],
  ['E445', 'TO', 'LANZO TORINESE'],
  ['Z228', 'EE', 'LAOS'],
  ['E447', 'FM', 'LAPEDONA'],
  ['E447', 'AP', 'LAPEDONA'],
  ['E448', 'AV', 'LAPIO'],
  ['E450', 'CS', 'LAPPANO'],
  ['E451', 'PT', 'LARCIANO'],
  ['E452', 'TN', 'LARDARO'],
  ['E454', 'PV', 'LARDIRAGO'],
  ['E455', 'PI', 'LARI'],
  ['M207', 'RM', 'LARIANO'],
  ['E456', 'CB', 'LARINO'],
  ['E464', 'CA', 'LAS PLASSAS'],
  ['E457', 'BZ', 'LASA'],
  ['E459', 'PA', 'LASCARI'],
  ['E461', 'TN', 'LASINO'],
  ['E462', 'CO', 'LASNIGO'],
  ['E465', 'VI', 'LASTEBASSE'],
  ['E466', 'FI', 'LASTRA A SIGNA'],
  ['E467', 'VT', 'LATERA'],
  ['E468', 'AR', 'LATERINA'],
  ['E469', 'TA', 'LATERZA'],
  ['E471', 'BR', 'LATIANO'],
  ['E472', 'LT', 'LATINA'],
  ['E473', 'UD', 'LATISANA'],
  ['E474', 'PZ', 'LATRONICO'],
  ['E475', 'CS', 'LATTARICO'],
  ['E476', 'UD', 'LAUCO'],
  ['E480', 'SA', 'LAUREANA CILENTO'],
  ['E479', 'RC', 'LAUREANA DI BORRELLO'],
  ['E481', 'BZ', 'LAUREGNO'],
  ['E482', 'PZ', 'LAURENZANA'],
  ['E483', 'PZ', 'LAURIA'],
  ['E484', 'TO', 'LAURIANO'],
  ['E485', 'SA', 'LAURINO'],
  ['E486', 'SA', 'LAURITO'],
  ['E487', 'AV', 'LAURO'],
  ['E488', 'GE', 'LAVAGNA'],
  ['E489', 'VR', 'LAVAGNO'],
  ['E492', 'TN', 'LAVARONE'],
  ['E493', 'PZ', 'LAVELLO'],
  ['E494', 'VA', 'LAVENA PONTE TRESA'],
  ['E496', 'VA', 'LAVENO-MOMBELLO'],
  ['E497', 'BS', 'LAVENONE'],
  ['E498', 'SA', 'LAVIANO'],
  ['E500', 'TN', 'LAVIS'],
  ['E502', 'VR', 'LAZISE'],
  ['E504', 'MB', 'LAZZATE'],
  ['E504', 'MI', 'LAZZATE'],
  ['E506', 'LE', 'LECCE'],
  ['E505', 'AQ', 'LECCE NEI MARSI'],
  ['E507', 'LC', 'LECCO'],
  ['E509', 'BG', 'LEFFE'],
  ['E510', 'VA', 'LEGGIUNO'],
  ['E512', 'VR', 'LEGNAGO'],
  ['E514', 'MI', 'LEGNANO'],
  ['E515', 'PD', 'LEGNARO'],
  ['E517', 'NU', 'LEI'],
  ['E518', 'TO', 'LEINI'],
  ['E519', 'GE', 'LEIVI'],
  ['E520', 'TO', 'LEMIE'],
  ['E522', 'RO', 'LENDINARA'],
  ['E523', 'ME', 'LENI'],
  ['E524', 'BG', 'LENNA'],
  ['E525', 'CO', 'LENNO'],
  ['E526', 'BS', 'LENO'],
  ['E527', 'LT', 'LENOLA'],
  ['E528', 'VC', 'LENTA'],
  ['E530', 'MB', 'LENTATE SUL SEVESO'],
  ['E530', 'MI', 'LENTATE SUL SEVESO'],
  ['E531', 'CH', 'LENTELLA'],
  ['C562', 'BL', 'LENTIAI'],
  ['E532', 'SR', 'LENTINI'],
  ['E535', 'RI', 'LEONESSA'],
  ['E536', 'EN', 'LEONFORTE'],
  ['E537', 'TA', 'LEPORANO'],
  ['E538', 'LE', 'LEQUILE'],
  ['E540', 'CN', 'LEQUIO BERRIA'],
  ['E539', 'CN', 'LEQUIO TANARO'],
  ['E541', 'PA', 'LERCARA FRIDDI'],
  ['E542', 'SP', 'LERICI'],
  ['E543', 'AL', 'LERMA'],
  ['E544', 'NO', 'LESA'],
  ['E546', 'CN', 'LESEGNO'],
  ['E547', 'PR', 'LESIGNANO DE\' BAGNI'],
  ['E549', 'FG', 'LESINA'],
  ['E550', 'MB', 'LESMO'],
  ['E550', 'MI', 'LESMO'],
  ['Z359', 'EE', 'LESOTHO'],
  ['E551', 'TO', 'LESSOLO'],
  ['E552', 'BI', 'LESSONA'],
  ['E553', 'UD', 'LESTIZZA'],
  ['E554', 'CE', 'LETINO'],
  ['E555', 'ME', 'LETOJANNI'],
  ['E557', 'NA', 'LETTERE'],
  ['E558', 'PE', 'LETTOMANOPPELLO'],
  ['Z145', 'EE', 'LETTONIA'],
  ['E559', 'CH', 'LETTOPALENA'],
  ['E560', 'SP', 'LEVANTO'],
  ['E562', 'BG', 'LEVATE'],
  ['E563', 'LE', 'LEVERANO'],
  ['E564', 'CN', 'LEVICE'],
  ['E565', 'TN', 'LEVICO TERME'],
  ['E566', 'TO', 'LEVONE'],
  ['E569', 'CO', 'LEZZENO'],
  ['Z229', 'EE', 'LIBANO'],
  ['E570', 'CE', 'LIBERI'],
  ['Z325', 'EE', 'LIBERIA'],
  ['Z326', 'EE', 'LIBIA'],
  ['E571', 'ME', 'LIBRIZZI'],
  ['E573', 'AG', 'LICATA'],
  ['E574', 'MS', 'LICCIANA NARDI'],
  ['E576', 'RM', 'LICENZA'],
  ['E578', 'CT', 'LICODIA EUBEA'],
  ['Z119', 'EE', 'LIECHTENSTEIN'],
  ['E581', 'LC', 'LIERNA'],
  ['E583', 'VC', 'LIGNANA'],
  ['E584', 'UD', 'LIGNANO SABBIADORO'],
  ['E585', 'RE', 'LIGONCHIO'],
  ['E586', 'UD', 'LIGOSULLO'],
  ['E587', 'AO', 'LILLIANES'],
  ['E588', 'BL', 'LIMANA'],
  ['E589', 'BN', 'LIMATOLA'],
  ['E590', 'VV', 'LIMBADI'],
  ['E591', 'MB', 'LIMBIATE'],
  ['E591', 'MI', 'LIMBIATE'],
  ['E592', 'PD', 'LIMENA'],
  ['E593', 'CO', 'LIMIDO COMASCO'],
  ['E594', 'ME', 'LIMINA'],
  ['E597', 'CN', 'LIMONE PIEMONTE'],
  ['E596', 'BS', 'LIMONE SUL GARDA'],
  ['E599', 'CB', 'LIMOSANO'],
  ['E600', 'PV', 'LINAROLO'],
  ['E602', 'CT', 'LINGUAGLOSSA'],
  ['E605', 'AV', 'LIONI'],
  ['E606', 'ME', 'LIPARI'],
  ['E607', 'CO', 'LIPOMO'],
  ['E608', 'PV', 'LIRIO'],
  ['E610', 'MI', 'LISCATE'],
  ['E611', 'CH', 'LISCIA'],
  ['E613', 'PG', 'LISCIANO NICCONE'],
  ['E614', 'TN', 'LISIGNAGO'],
  ['E615', 'CN', 'LISIO'],
  ['E617', 'MB', 'LISSONE'],
  ['E617', 'MI', 'LISSONE'],
  ['Z146', 'EE', 'LITUANIA'],
  ['E620', 'NA', 'LIVERI'],
  ['E621', 'SO', 'LIVIGNO'],
  ['E622', 'BL', 'LIVINALLONGO DEL COL DI LANA'],
  ['E623', 'CO', 'LIVO'],
  ['E624', 'TN', 'LIVO'],
  ['E625', 'LI', 'LIVORNO'],
  ['E626', 'VC', 'LIVORNO FERRARIS'],
  ['E627', 'LO', 'LIVRAGA'],
  ['E629', 'LE', 'LIZZANELLO'],
  ['E630', 'TA', 'LIZZANO'],
  ['A771', 'BO', 'LIZZANO IN BELVEDERE'],
  ['E632', 'SV', 'LOANO'],
  ['E633', 'AT', 'LOAZZOLO'],
  ['E635', 'TO', 'LOCANA'],
  ['E639', 'MI', 'LOCATE DI TRIULZI'],
  ['E638', 'CO', 'LOCATE VARESINO'],
  ['E640', 'BG', 'LOCATELLO'],
  ['E644', 'NU', 'LOCERI'],
  ['E645', 'BA', 'LOCOROTONDO'],
  ['D976', 'RC', 'LOCRI'],
  ['E646', 'NU', 'LOCULI'],
  ['E647', 'NU', 'LODE\''],
  ['E648', 'LO', 'LODI'],
  ['E651', 'LO', 'LODI VECCHIO'],
  ['E649', 'NU', 'LODINE'],
  ['E652', 'BS', 'LODRINO'],
  ['E654', 'BS', 'LOGRATO'],
  ['E655', 'BO', 'LOIANO'],
  ['M275', 'SS', 'LOIRI PORTO SAN PAOLO'],
  ['E656', 'LC', 'LOMAGNA'],
  ['E658', 'TN', 'LOMASO'],
  ['E659', 'CO', 'LOMAZZO'],
  ['E660', 'TO', 'LOMBARDORE'],
  ['E661', 'TO', 'LOMBRIASCO'],
  ['E662', 'PV', 'LOMELLO'],
  ['E664', 'TN', 'LONA LASES'],
  ['E665', 'VA', 'LONATE CEPPINO'],
  ['E666', 'VA', 'LONATE POZZOLO'],
  ['E667', 'BS', 'LONATO'],
  ['E668', 'FI', 'LONDA'],
  ['E669', 'IS', 'LONGANO'],
  ['E671', 'VI', 'LONGARE'],
  ['E672', 'BL', 'LONGARONE'],
  ['E673', 'BS', 'LONGHENA'],
  ['E674', 'ME', 'LONGI'],
  ['E675', 'FC', 'LONGIANO'],
  ['E677', 'CS', 'LONGOBARDI'],
  ['E678', 'CS', 'LONGOBUCCO'],
  ['E679', 'CO', 'LONGONE AL SEGRINO'],
  ['E681', 'RI', 'LONGONE SABINO'],
  ['E682', 'VI', 'LONIGO'],
  ['E683', 'TO', 'LORANZE\''],
  ['E684', 'PD', 'LOREGGIA'],
  ['E685', 'VB', 'LOREGLIA'],
  ['E687', 'BL', 'LORENZAGO DI CADORE'],
  ['E688', 'PI', 'LORENZANA'],
  ['E689', 'RO', 'LOREO'],
  ['E690', 'AN', 'LORETO'],
  ['E691', 'PE', 'LORETO APRUTINO'],
  ['E692', 'TV', 'LORIA'],
  ['E693', 'AR', 'LORO CIUFFENNA'],
  ['E694', 'MC', 'LORO PICENO'],
  ['E695', 'GE', 'LORSICA'],
  ['E698', 'BS', 'LOSINE'],
  ['E700', 'NU', 'LOTZORAI'],
  ['E704', 'BG', 'LOVERE'],
  ['E705', 'SO', 'LOVERO'],
  ['E706', 'BS', 'LOZIO'],
  ['E707', 'VA', 'LOZZA'],
  ['E709', 'PD', 'LOZZO ATESTINO'],
  ['E708', 'BL', 'LOZZO DI CADORE'],
  ['E711', 'VC', 'LOZZOLO'],
  ['E712', 'AL', 'LU'],
  ['E713', 'VT', 'LUBRIANO'],
  ['E715', 'LU', 'LUCCA'],
  ['E714', 'AG', 'LUCCA SICULA'],
  ['E716', 'FG', 'LUCERA'],
  ['E718', 'AR', 'LUCIGNANO'],
  ['E719', 'IM', 'LUCINASCO'],
  ['E722', 'CB', 'LUCITO'],
  ['E723', 'AQ', 'LUCO DEI MARSI'],
  ['E724', 'AQ', 'LUCOLI'],
  ['E726', 'PC', 'LUGAGNANO VAL D\'ARDA'],
  ['E727', 'TO', 'LUGNACCO'],
  ['E729', 'TR', 'LUGNANO IN TEVERINA'],
  ['E730', 'RA', 'LUGO'],
  ['E731', 'VI', 'LUGO DI VICENZA'],
  ['E734', 'VA', 'LUINO'],
  ['E735', 'CO', 'LUISAGO'],
  ['E736', 'NU', 'LULA'],
  ['E737', 'GE', 'LUMARZO'],
  ['E738', 'BS', 'LUMEZZANE'],
  ['E742', 'CA', 'LUNAMATRONA'],
  ['E743', 'PU', 'LUNANO'],
  ['B387', 'PV', 'LUNGAVILLA'],
  ['E745', 'CS', 'LUNGRO'],
  ['E746', 'AV', 'LUOGOSANO'],
  ['E747', 'SS', 'LUOGOSANTO'],
  ['E748', 'CB', 'LUPARA'],
  ['E749', 'CO', 'LURAGO D\'ERBA'],
  ['E750', 'CO', 'LURAGO MARINONE'],
  ['E751', 'BG', 'LURANO'],
  ['E752', 'SS', 'LURAS'],
  ['E753', 'CO', 'LURATE CACCIVIO'],
  ['E754', 'CE', 'LUSCIANO'],
  ['E757', 'TN', 'LUSERNA'],
  ['E758', 'TO', 'LUSERNA SAN GIOVANNI'],
  ['E759', 'TO', 'LUSERNETTA'],
  ['E760', 'UD', 'LUSEVERA'],
  ['E761', 'RO', 'LUSIA'],
  ['E762', 'VI', 'LUSIANA'],
  ['E763', 'TO', 'LUSIGLIE\''],
  ['E764', 'BZ', 'LUSON'],
  ['Z120', 'EE', 'LUSSEMBURGO'],
  ['E767', 'SA', 'LUSTRA'],
  ['E769', 'VA', 'LUVINATE'],
  ['E770', 'BG', 'LUZZANA'],
  ['E772', 'RE', 'LUZZARA'],
  ['E773', 'CS', 'LUZZI'],
  ['Z231', 'EE', 'MACAO'],
  ['E775', 'VA', 'MACCAGNO'],
  ['E777', 'LO', 'MACCASTORNA'],
  ['E778', 'IS', 'MACCHIA D\'ISERNIA'],
  ['E780', 'CB', 'MACCHIA VALFORTORE'],
  ['E779', 'IS', 'MACCHIAGODENA'],
  ['Z148', 'EE', 'MACEDONIA'],
  ['E782', 'TO', 'MACELLO'],
  ['E783', 'MC', 'MACERATA'],
  ['E784', 'CE', 'MACERATA CAMPANIA'],
  ['E785', 'PU', 'MACERATA FELTRIA'],
  ['E786', 'MB', 'MACHERIO'],
  ['E786', 'MI', 'MACHERIO'],
  ['E787', 'BS', 'MACLODIO'],
  ['E788', 'NU', 'MACOMER'],
  ['Z708', 'EE', 'MACQUARIE (ISOLE)'],
  ['E789', 'CN', 'MACRA'],
  ['E790', 'VB', 'MACUGNAGA'],
  ['Z327', 'EE', 'MADAGASCAR'],
  ['E791', 'CE', 'MADDALONI'],
  ['E342', 'SO', 'MADESIMO'],
  ['E793', 'CR', 'MADIGNANO'],
  ['E794', 'BG', 'MADONE'],
  ['E795', 'VB', 'MADONNA DEL SASSO'],
  ['E798', 'LT', 'MAENZA'],
  ['E799', 'CB', 'MAFALDA'],
  ['E800', 'BS', 'MAGASA'],
  ['E801', 'MI', 'MAGENTA'],
  ['E803', 'NO', 'MAGGIORA'],
  ['E804', 'PV', 'MAGHERNO'],
  ['E805', 'PG', 'MAGIONE'],
  ['E806', 'CZ', 'MAGISANO'],
  ['E809', 'CN', 'MAGLIANO ALFIERI'],
  ['E808', 'CN', 'MAGLIANO ALPI'],
  ['E811', 'AQ', 'MAGLIANO DE\' MARSI'],
  ['E807', 'FM', 'MAGLIANO DI TENNA'],
  ['E807', 'AP', 'MAGLIANO DI TENNA'],
  ['E810', 'GR', 'MAGLIANO IN TOSCANA'],
  ['E813', 'RM', 'MAGLIANO ROMANO'],
  ['E812', 'RI', 'MAGLIANO SABINA'],
  ['E814', 'SA', 'MAGLIANO VETERE'],
  ['E815', 'LE', 'MAGLIE'],
  ['E816', 'SV', 'MAGLIOLO'],
  ['E817', 'TO', 'MAGLIONE'],
  ['E818', 'MN', 'MAGNACAVALLO'],
  ['E819', 'MI', 'MAGNAGO'],
  ['E821', 'BI', 'MAGNANO'],
  ['E820', 'UD', 'MAGNANO IN RIVIERA'],
  ['E825', 'NU', 'MAGOMADAS'],
  ['E829', 'BZ', 'MAGRE\' SULLA STRADA DEL VINO'],
  ['E830', 'CO', 'MAGREGLIO'],
  ['E834', 'CZ', 'MAIDA'],
  ['E835', 'CS', 'MAIERA\''],
  ['E836', 'VV', 'MAIERATO'],
  ['E837', 'AN', 'MAIOLATI SPONTINI'],
  ['E838', 'PU', 'MAIOLO'],
  ['E839', 'SA', 'MAIORI'],
  ['E840', 'LO', 'MAIRAGO'],
  ['E841', 'BS', 'MAIRANO'],
  ['E842', 'SP', 'MAISSANA'],
  ['E833', 'UD', 'MAJANO'],
  ['E843', 'CR', 'MALAGNINO'],
  ['E844', 'BO', 'MALALBERGO'],
  ['Z328', 'EE', 'MALAWI'],
  ['Z247', 'EE', 'MALAYSIA'],
  ['E847', 'UD', 'MALBORGHETTO-VALBRUNA'],
  ['E848', 'VR', 'MALCESINE'],
  ['Z232', 'EE', 'MALDIVE'],
  ['E850', 'TN', 'MALE\''],
  ['E851', 'BS', 'MALEGNO'],
  ['E852', 'LO', 'MALEO'],
  ['E853', 'VB', 'MALESCO'],
  ['E854', 'CT', 'MALETTO'],
  ['E855', 'ME', 'MALFA'],
  ['E856', 'VA', 'MALGESSO'],
  ['E858', 'LC', 'MALGRATE'],
  ['Z329', 'EE', 'MALI'],
  ['E859', 'CS', 'MALITO'],
  ['E860', 'SV', 'MALLARE'],
  ['E862', 'BZ', 'MALLES VENOSTA'],
  ['E863', 'VA', 'MALNATE'],
  ['E864', 'VI', 'MALO'],
  ['E865', 'BS', 'MALONNO'],
  ['E866', 'TN', 'MALOSCO'],
  ['Z121', 'EE', 'MALTA'],
  ['E868', 'AP', 'MALTIGNANO'],
  ['E869', 'ME', 'MALVAGNA'],
  ['E870', 'AL', 'MALVICINO'],
  ['E872', 'CS', 'MALVITO'],
  ['E873', 'RC', 'MAMMOLA'],
  ['E874', 'NU', 'MAMOIADA'],
  ['Z122', 'EE', 'MAN (ISOLA)'],
  ['E875', 'GR', 'MANCIANO'],
  ['E876', 'ME', 'MANDANICI'],
  ['E877', 'CA', 'MANDAS'],
  ['E878', 'CS', 'MANDATORICCIO'],
  ['B632', 'RM', 'MANDELA'],
  ['E879', 'LC', 'MANDELLO DEL LARIO'],
  ['E880', 'NO', 'MANDELLO VITTA'],
  ['E882', 'TA', 'MANDURIA'],
  ['E883', 'BS', 'MANERBA DEL GARDA'],
  ['E884', 'BS', 'MANERBIO'],
  ['E885', 'FG', 'MANFREDONIA'],
  ['E887', 'CN', 'MANGO'],
  ['E888', 'CS', 'MANGONE'],
  ['M283', 'CT', 'MANIACE'],
  ['E889', 'PN', 'MANIAGO'],
  ['E891', 'AV', 'MANOCALZATI'],
  ['E892', 'PE', 'MANOPPELLO'],
  ['E893', 'TV', 'MANSUE\''],
  ['E894', 'CN', 'MANTA'],
  ['E896', 'SO', 'MANTELLO'],
  ['E897', 'MN', 'MANTOVA'],
  ['E899', 'UD', 'MANZANO'],
  ['E900', 'RM', 'MANZIANA'],
  ['E901', 'BG', 'MAPELLO'],
  ['E902', 'SS', 'MARA'],
  ['E903', 'CA', 'MARACALAGONIS'],
  ['E904', 'MO', 'MARANELLO'],
  ['E906', 'NA', 'MARANO DI NAPOLI'],
  ['E911', 'VR', 'MARANO DI VALPOLICELLA'],
  ['E908', 'RM', 'MARANO EQUO'],
  ['E910', 'UD', 'MARANO LAGUNARE'],
  ['E914', 'CS', 'MARANO MARCHESATO'],
  ['E915', 'CS', 'MARANO PRINCIPATO'],
  ['E905', 'MO', 'MARANO SUL PANARO'],
  ['E907', 'NO', 'MARANO TICINO'],
  ['E912', 'VI', 'MARANO VICENTINO'],
  ['E917', 'AT', 'MARANZANA'],
  ['E919', 'PZ', 'MARATEA'],
  ['E921', 'MI', 'MARCALLO CON CASONE'],
  ['E922', 'MN', 'MARCARIA'],
  ['E923', 'CZ', 'MARCEDUSA'],
  ['E924', 'RM', 'MARCELLINA'],
  ['E925', 'CZ', 'MARCELLINARA'],
  ['E927', 'RI', 'MARCETELLI'],
  ['E928', 'BS', 'MARCHENO'],
  ['E929', 'VA', 'MARCHIROLO'],
  ['E930', 'LI', 'MARCIANA'],
  ['E931', 'LI', 'MARCIANA MARINA'],
  ['E932', 'CE', 'MARCIANISE'],
  ['E933', 'AR', 'MARCIANO DELLA CHIANA'],
  ['E934', 'PV', 'MARCIGNAGO'],
  ['E936', 'VE', 'MARCON'],
  ['E938', 'BZ', 'MAREBBE'],
  ['E939', 'CN', 'MARENE'],
  ['E940', 'TV', 'MARENO DI PIAVE'],
  ['E941', 'TO', 'MARENTINO'],
  ['E944', 'AT', 'MARETTO'],
  ['E945', 'CN', 'MARGARITA'],
  ['E946', 'BT', 'MARGHERITA DI SAVOIA'],
  ['E946', 'FG', 'MARGHERITA DI SAVOIA'],
  ['E947', 'LC', 'MARGNO'],
  ['E949', 'MN', 'MARIANA MANTOVANA'],
  ['Z710', 'EE', 'MARIANNE (ISOLE)'],
  ['E951', 'CO', 'MARIANO COMENSE'],
  ['E952', 'GO', 'MARIANO DEL FRIULI'],
  ['E953', 'CL', 'MARIANOPOLI'],
  ['E954', 'NA', 'MARIGLIANELLA'],
  ['E955', 'NA', 'MARIGLIANO'],
  ['E956', 'RC', 'MARINA DI GIOIOSA IONICA'],
  ['E957', 'PA', 'MARINEO'],
  ['E958', 'RM', 'MARINO'],
  ['E959', 'BZ', 'MARLENGO'],
  ['E960', 'PT', 'MARLIANA'],
  ['E961', 'BS', 'MARMENTINO'],
  ['E962', 'MN', 'MARMIROLO'],
  ['E963', 'CN', 'MARMORA'],
  ['E965', 'VA', 'MARNATE'],
  ['Z330', 'EE', 'MAROCCO'],
  ['E967', 'BS', 'MARONE'],
  ['E968', 'RC', 'MAROPATI'],
  ['E970', 'VI', 'MAROSTICA'],
  ['E971', 'FI', 'MARRADI'],
  ['E972', 'OR', 'MARRUBIU'],
  ['E973', 'CN', 'MARSAGLIA'],
  ['E974', 'TP', 'MARSALA'],
  ['E975', 'PG', 'MARSCIANO'],
  ['Z711', 'EE', 'MARSHALL'],
  ['E976', 'PZ', 'MARSICO NUOVO'],
  ['E977', 'PZ', 'MARSICOVETERE'],
  ['E978', 'VT', 'MARTA'],
  ['E979', 'LE', 'MARTANO'],
  ['E980', 'VE', 'MARTELLAGO'],
  ['E981', 'BZ', 'MARTELLO'],
  ['E982', 'UD', 'MARTIGNACCO'],
  ['E983', 'CR', 'MARTIGNANA DI PO'],
  ['E984', 'LE', 'MARTIGNANO'],
  ['E986', 'TA', 'MARTINA FRANCA'],
  ['E987', 'BG', 'MARTINENGO'],
  ['E988', 'CN', 'MARTINIANA PO'],
  ['Z513', 'EE', 'MARTINICA'],
  ['E989', 'TE', 'MARTINSICURO'],
  ['E990', 'CZ', 'MARTIRANO'],
  ['E991', 'CZ', 'MARTIRANO LOMBARDO'],
  ['E992', 'SS', 'MARTIS'],
  ['E993', 'RC', 'MARTONE'],
  ['E994', 'LO', 'MARUDO'],
  ['E995', 'TA', 'MARUGGIO'],
  ['B689', 'BO', 'MARZABOTTO'],
  ['E999', 'PV', 'MARZANO'],
  ['E998', 'CE', 'MARZANO APPIO'],
  ['E997', 'AV', 'MARZANO DI NOLA'],
  ['F001', 'CS', 'MARZI'],
  ['F002', 'VA', 'MARZIO'],
  ['M270', 'CA', 'MASAINAS'],
  ['F003', 'MI', 'MASATE'],
  ['F004', 'CT', 'MASCALI'],
  ['F005', 'CT', 'MASCALUCIA'],
  ['F006', 'PZ', 'MASCHITO'],
  ['F007', 'VA', 'MASCIAGO PRIMO'],
  ['F009', 'TV', 'MASER'],
  ['F010', 'VB', 'MASERA'],
  ['F011', 'PD', 'MASERA\' DI PADOVA'],
  ['F012', 'TV', 'MASERADA SUL PIAVE'],
  ['F013', 'PD', 'MASI'],
  ['F016', 'FE', 'MASI TORELLO'],
  ['F015', 'AL', 'MASIO'],
  ['F017', 'CO', 'MASLIANICO'],
  ['F019', 'VI', 'MASON VICENTINO'],
  ['F020', 'GE', 'MASONE'],
  ['F023', 'MS', 'MASSA'],
  ['F022', 'AQ', 'MASSA D\'ALBE'],
  ['M289', 'NA', 'MASSA DI SOMMA'],
  ['F025', 'PT', 'MASSA E COZZILE'],
  ['F021', 'FM', 'MASSA FERMANA'],
  ['F021', 'AP', 'MASSA FERMANA'],
  ['F026', 'FE', 'MASSA FISCAGLIA'],
  ['F029', 'RA', 'MASSA LOMBARDA'],
  ['F030', 'NA', 'MASSA LUBRENSE'],
  ['F032', 'GR', 'MASSA MARITTIMA'],
  ['F024', 'PG', 'MASSA MARTANA'],
  ['F027', 'TA', 'MASSAFRA'],
  ['F028', 'LO', 'MASSALENGO'],
  ['F033', 'PD', 'MASSANZAGO'],
  ['F035', 'LU', 'MASSAROSA'],
  ['F037', 'BI', 'MASSAZZA'],
  ['F041', 'TO', 'MASSELLO'],
  ['F042', 'BI', 'MASSERANO'],
  ['F044', 'AP', 'MASSIGNANO'],
  ['F045', 'TN', 'MASSIMENO'],
  ['F046', 'SV', 'MASSIMINO'],
  ['F047', 'NO', 'MASSINO VISCONTI'],
  ['F048', 'VB', 'MASSIOLA'],
  ['F050', 'OR', 'MASULLAS'],
  ['F051', 'MC', 'MATELICA'],
  ['F052', 'MT', 'MATERA'],
  ['F053', 'TO', 'MATHI'],
  ['F054', 'LE', 'MATINO'],
  ['F055', 'CB', 'MATRICE'],
  ['F058', 'TO', 'MATTIE'],
  ['F059', 'FG', 'MATTINATA'],
  ['Z331', 'EE', 'MAURITANIA'],
  ['Z332', 'EE', 'MAURIZIO'],
  ['Z360', 'EE', 'MAYOTTE (ISOLA)'],
  ['F061', 'TP', 'MAZARA DEL VALLO'],
  ['F063', 'BS', 'MAZZANO'],
  ['F064', 'RM', 'MAZZANO ROMANO'],
  ['F065', 'CL', 'MAZZARINO'],
  ['F066', 'ME', 'MAZZARRA\' SANT\'ANDREA'],
  ['M271', 'CT', 'MAZZARRONE'],
  ['F067', 'TO', 'MAZZE\''],
  ['F068', 'TN', 'MAZZIN'],
  ['F070', 'SO', 'MAZZO DI VALTELLINA'],
  ['F074', 'TO', 'MEANA DI SUSA'],
  ['F073', 'NU', 'MEANA SARDO'],
  ['F078', 'MB', 'MEDA'],
  ['F078', 'MI', 'MEDA'],
  ['F080', 'PV', 'MEDE'],
  ['F081', 'GO', 'MEDEA'],
  ['F082', 'PR', 'MEDESANO'],
  ['F083', 'BO', 'MEDICINA'],
  ['F084', 'MI', 'MEDIGLIA'],
  ['F085', 'BG', 'MEDOLAGO'],
  ['F086', 'MN', 'MEDOLE'],
  ['F087', 'MO', 'MEDOLLA'],
  ['F088', 'TV', 'MEDUNA DI LIVENZA'],
  ['F089', 'PN', 'MEDUNO'],
  ['F091', 'PD', 'MEGLIADINO SAN FIDENZIO'],
  ['F092', 'PD', 'MEGLIADINO SAN VITALE'],
  ['F093', 'NO', 'MEINA'],
  ['F094', 'BL', 'MEL'],
  ['F095', 'RO', 'MELARA'],
  ['F096', 'AL', 'MELAZZO'],
  ['F097', 'FC', 'MELDOLA'],
  ['F098', 'GE', 'MELE'],
  ['F100', 'MI', 'MELEGNANO'],
  ['F101', 'LE', 'MELENDUGNO'],
  ['F102', 'LO', 'MELETI'],
  ['F104', 'PZ', 'MELFI'],
  ['F105', 'RC', 'MELICUCCA\''],
  ['F106', 'RC', 'MELICUCCO'],
  ['F107', 'SR', 'MELILLI'],
  ['F108', 'KR', 'MELISSA'],
  ['F109', 'LE', 'MELISSANO'],
  ['F111', 'NA', 'MELITO DI NAPOLI'],
  ['F112', 'RC', 'MELITO DI PORTO SALVO'],
  ['F110', 'AV', 'MELITO IRPINO'],
  ['F113', 'BN', 'MELIZZANO'],
  ['F114', 'CN', 'MELLE'],
  ['F115', 'SO', 'MELLO'],
  ['F117', 'LE', 'MELPIGNANO'],
  ['F118', 'BZ', 'MELTINA'],
  ['F119', 'MI', 'MELZO'],
  ['F120', 'CO', 'MENAGGIO'],
  ['F121', 'SO', 'MENAROLA'],
  ['F122', 'PV', 'MENCONICO'],
  ['F123', 'IM', 'MENDATICA'],
  ['F125', 'CS', 'MENDICINO'],
  ['F126', 'AG', 'MENFI'],
  ['F127', 'RM', 'MENTANA'],
  ['F130', 'VE', 'MEOLO'],
  ['F131', 'AL', 'MERANA'],
  ['F132', 'BZ', 'MERANO'],
  ['F133', 'LC', 'MERATE'],
  ['F134', 'VA', 'MERCALLO'],
  ['F135', 'PU', 'MERCATELLO SUL METAURO'],
  ['F136', 'PU', 'MERCATINO CONCA'],
  ['F138', 'SA', 'MERCATO SAN SEVERINO'],
  ['F139', 'FC', 'MERCATO SARACENO'],
  ['F140', 'TO', 'MERCENASCO'],
  ['F141', 'AV', 'MERCOGLIANO'],
  ['F144', 'UD', 'MERETO DI TOMBA'],
  ['F145', 'AN', 'MERGO'],
  ['F146', 'VB', 'MERGOZZO'],
  ['F147', 'ME', 'MERI\''],
  ['F148', 'PD', 'MERLARA'],
  ['F149', 'LO', 'MERLINO'],
  ['F151', 'CO', 'MERONE'],
  ['F152', 'BR', 'MESAGNE'],
  ['F153', 'SO', 'MESE'],
  ['F154', 'VA', 'MESENZANA'],
  ['F155', 'MI', 'MESERO'],
  ['F156', 'FE', 'MESOLA'],
  ['F157', 'KR', 'MESORACA'],
  ['Z514', 'EE', 'MESSICO'],
  ['F158', 'ME', 'MESSINA'],
  ['F161', 'PD', 'MESTRINO'],
  ['F162', 'NA', 'META'],
  ['F164', 'TO', 'MEUGLIANO'],
  ['F165', 'MB', 'MEZZAGO'],
  ['F165', 'MI', 'MEZZAGO'],
  ['F168', 'TN', 'MEZZANA'],
  ['F170', 'PV', 'MEZZANA BIGLI'],
  ['F167', 'BI', 'MEZZANA MORTIGLIENGO'],
  ['F171', 'PV', 'MEZZANA RABATTONE'],
  ['F172', 'VR', 'MEZZANE DI SOTTO'],
  ['F173', 'GE', 'MEZZANEGO'],
  ['F174', 'PR', 'MEZZANI'],
  ['F175', 'PV', 'MEZZANINO'],
  ['F176', 'TN', 'MEZZANO'],
  ['F181', 'CO', 'MEZZEGRA'],
  ['F182', 'TO', 'MEZZENILE'],
  ['F183', 'TN', 'MEZZOCORONA'],
  ['F184', 'PA', 'MEZZOJUSO'],
  ['F186', 'BG', 'MEZZOLDO'],
  ['F187', 'TN', 'MEZZOLOMBARDO'],
  ['F188', 'NO', 'MEZZOMERICO'],
  ['F189', 'BI', 'MIAGLIANO'],
  ['F190', 'TV', 'MIANE'],
  ['F191', 'NO', 'MIASINO'],
  ['F192', 'VB', 'MIAZZINA'],
  ['F193', 'RI', 'MICIGLIANO'],
  ['Z735', 'EE', 'MICRONESIA STATI FEDERATI'],
  ['Z712', 'EE', 'MIDWAY (ISOLE)'],
  ['F194', 'LE', 'MIGGIANO'],
  ['F196', 'CH', 'MIGLIANICO'],
  ['F198', 'FE', 'MIGLIARINO'],
  ['F199', 'FE', 'MIGLIARO'],
  ['F200', 'CZ', 'MIGLIERINA'],
  ['F201', 'MT', 'MIGLIONICO'],
  ['F202', 'GE', 'MIGNANEGO'],
  ['F203', 'CE', 'MIGNANO MONTE LUNGO'],
  ['F205', 'MI', 'MILANO'],
  ['F206', 'ME', 'MILAZZO'],
  ['E618', 'CL', 'MILENA'],
  ['F207', 'VV', 'MILETO'],
  ['F208', 'OR', 'MILIS'],
  ['F209', 'CT', 'MILITELLO IN VAL DI CATANIA'],
  ['F210', 'ME', 'MILITELLO ROSMARINO'],
  ['F213', 'SV', 'MILLESIMO'],
  ['F214', 'CT', 'MILO'],
  ['F216', 'BS', 'MILZANO'],
  ['F217', 'CT', 'MINEO'],
  ['F218', 'VR', 'MINERBE'],
  ['F219', 'BO', 'MINERBIO'],
  ['F221', 'LE', 'MINERVINO DI LECCE'],
  ['F220', 'BA', 'MINERVINO MURGE'],
  ['F220', 'BT', 'MINERVINO MURGE'],
  ['F223', 'SA', 'MINORI'],
  ['F224', 'LT', 'MINTURNO'],
  ['F225', 'LU', 'MINUCCIANO'],
  ['F226', 'SV', 'MIOGLIA'],
  ['F229', 'VE', 'MIRA'],
  ['F230', 'AV', 'MIRABELLA ECLANO'],
  ['F231', 'CT', 'MIRABELLA IMBACCARI'],
  ['F235', 'FE', 'MIRABELLO'],
  ['F232', 'AL', 'MIRABELLO MONFERRATO'],
  ['F233', 'CB', 'MIRABELLO SANNITICO'],
  ['F238', 'PV', 'MIRADOLO TERME'],
  ['F239', 'IS', 'MIRANDA'],
  ['F240', 'MO', 'MIRANDOLA'],
  ['F241', 'VE', 'MIRANO'],
  ['F242', 'ME', 'MIRTO'],
  ['F244', 'RN', 'MISANO ADRIATICO'],
  ['F243', 'BG', 'MISANO DI GERA D\'ADDA'],
  ['F246', 'PA', 'MISILMERI'],
  ['F247', 'MB', 'MISINTO'],
  ['F247', 'MI', 'MISINTO'],
  ['F248', 'LC', 'MISSAGLIA'],
  ['F249', 'PZ', 'MISSANELLO'],
  ['F250', 'CT', 'MISTERBIANCO'],
  ['F251', 'ME', 'MISTRETTA'],
  ['F254', 'AT', 'MOASCA'],
  ['F256', 'GE', 'MOCONESI'],
  ['F257', 'MO', 'MODENA'],
  ['F258', 'RG', 'MODICA'],
  ['F259', 'FC', 'MODIGLIANA'],
  ['F261', 'NU', 'MODOLO'],
  ['F262', 'BA', 'MODUGNO'],
  ['F263', 'TN', 'MOENA'],
  ['F265', 'LC', 'MOGGIO'],
  ['F266', 'UD', 'MOGGIO UDINESE'],
  ['F267', 'MN', 'MOGLIA'],
  ['F268', 'MC', 'MOGLIANO'],
  ['F269', 'TV', 'MOGLIANO VENETO'],
  ['F270', 'OR', 'MOGORELLA'],
  ['F272', 'OR', 'MOGORO'],
  ['F274', 'BN', 'MOIANO'],
  ['F275', 'UD', 'MOIMACCO'],
  ['F277', 'ME', 'MOIO ALCANTARA'],
  ['F276', 'BG', 'MOIO DE\' CALVI'],
  ['F278', 'SA', 'MOIO DELLA CIVITELLA'],
  ['F279', 'CN', 'MOIOLA'],
  ['F280', 'BA', 'MOLA DI BARI'],
  ['F281', 'AL', 'MOLARE'],
  ['F283', 'LU', 'MOLAZZANA'],
  ['Z140', 'EE', 'MOLDAVIA'],
  ['F284', 'BA', 'MOLFETTA'],
  ['M255', 'AQ', 'MOLINA ATERNO'],
  ['F286', 'TN', 'MOLINA DI LEDRO'],
  ['F287', 'BN', 'MOLINARA'],
  ['F288', 'BO', 'MOLINELLA'],
  ['F290', 'IM', 'MOLINI DI TRIORA'],
  ['F293', 'AL', 'MOLINO DEI TORTI'],
  ['F294', 'CB', 'MOLISE'],
  ['F295', 'PZ', 'MOLITERNO'],
  ['F297', 'VC', 'MOLLIA'],
  ['F301', 'RC', 'MOLOCHIO'],
  ['F304', 'LC', 'MOLTENO'],
  ['F305', 'CO', 'MOLTRASIO'],
  ['F306', 'VI', 'MOLVENA'],
  ['F307', 'TN', 'MOLVENO'],
  ['F308', 'AT', 'MOMBALDONE'],
  ['F309', 'CN', 'MOMBARCARO'],
  ['F310', 'PU', 'MOMBAROCCIO'],
  ['F311', 'AT', 'MOMBARUZZO'],
  ['F312', 'CN', 'MOMBASIGLIO'],
  ['F315', 'TO', 'MOMBELLO DI TORINO'],
  ['F313', 'AL', 'MOMBELLO MONFERRATO'],
  ['F316', 'AT', 'MOMBERCELLI'],
  ['F317', 'NO', 'MOMO'],
  ['F318', 'TO', 'MOMPANTERO'],
  ['F319', 'RI', 'MOMPEO'],
  ['F320', 'AL', 'MOMPERONE'],
  ['F322', 'CB', 'MONACILIONI'],
  ['Z123', 'EE', 'MONACO'],
  ['F323', 'AT', 'MONALE'],
  ['F324', 'RC', 'MONASTERACE'],
  ['F325', 'AT', 'MONASTERO BORMIDA'],
  ['F327', 'TO', 'MONASTERO DI LANZO'],
  ['F326', 'CN', 'MONASTERO DI VASCO'],
  ['F329', 'CN', 'MONASTEROLO CASOTTO'],
  ['F328', 'BG', 'MONASTEROLO DEL CASTELLO'],
  ['F330', 'CN', 'MONASTEROLO DI SAVIGLIANO'],
  ['F332', 'TV', 'MONASTIER DI TREVISO'],
  ['F333', 'CA', 'MONASTIR'],
  ['F335', 'TO', 'MONCALIERI'],
  ['F336', 'AT', 'MONCALVO'],
  ['D553', 'TO', 'MONCENISIO'],
  ['F337', 'AL', 'MONCESTINO'],
  ['F338', 'CN', 'MONCHIERO'],
  ['F340', 'PR', 'MONCHIO DELLE CORTI'],
  ['F341', 'TN', 'MONCLASSICO'],
  ['F342', 'VC', 'MONCRIVELLO'],
  ['F343', 'AT', 'MONCUCCO TORINESE'],
  ['F346', 'RN', 'MONDAINO'],
  ['F347', 'PU', 'MONDAVIO'],
  ['F348', 'PU', 'MONDOLFO'],
  ['F351', 'CN', 'MONDOVI\''],
  ['F352', 'CE', 'MONDRAGONE'],
  ['F354', 'GE', 'MONEGLIA'],
  ['F355', 'CN', 'MONESIGLIO'],
  ['F356', 'GO', 'MONFALCONE'],
  ['F358', 'CN', 'MONFORTE D\'ALBA'],
  ['F359', 'ME', 'MONFORTE SAN GIORGIO'],
  ['F360', 'TV', 'MONFUMO'],
  ['F361', 'AT', 'MONGARDINO'],
  ['F363', 'BO', 'MONGHIDORO'],
  ['F364', 'VV', 'MONGIANA'],
  ['F365', 'AL', 'MONGIARDINO LIGURE'],
  ['F368', 'ME', 'MONGIUFFI MELIA'],
  ['Z233', 'EE', 'MONGOLIA'],
  ['F369', 'BI', 'MONGRANDO'],
  ['F370', 'CS', 'MONGRASSANO'],
  ['F371', 'BZ', 'MONGUELFO'],
  ['F372', 'CO', 'MONGUZZO'],
  ['F373', 'BS', 'MONIGA DEL GARDA'],
  ['F374', 'AL', 'MONLEALE'],
  ['F375', 'BS', 'MONNO'],
  ['F376', 'BA', 'MONOPOLI'],
  ['F377', 'PA', 'MONREALE'],
  ['F378', 'TS', 'MONRUPINO'],
  ['F379', 'FM', 'MONSAMPIETRO MORICO'],
  ['F379', 'AP', 'MONSAMPIETRO MORICO'],
  ['F380', 'AP', 'MONSAMPOLO DEL TRONTO'],
  ['F381', 'AN', 'MONSANO'],
  ['F382', 'PD', 'MONSELICE'],
  ['F383', 'CA', 'MONSERRATO'],
  ['F384', 'PT', 'MONSUMMANO TERME'],
  ['F385', 'CN', 'MONTA\''],
  ['F386', 'AT', 'MONTABONE'],
  ['F387', 'AL', 'MONTACUTO'],
  ['F390', 'AT', 'MONTAFIA'],
  ['F391', 'CB', 'MONTAGANO'],
  ['F392', 'BZ', 'MONTAGNA'],
  ['F393', 'SO', 'MONTAGNA IN VALTELLINA'],
  ['F394', 'PD', 'MONTAGNANA'],
  ['F395', 'ME', 'MONTAGNAREALE'],
  ['F396', 'TN', 'MONTAGNE'],
  ['F397', 'AV', 'MONTAGUTO'],
  ['F398', 'FI', 'MONTAIONE'],
  ['F400', 'ME', 'MONTALBANO ELICONA'],
  ['F399', 'MT', 'MONTALBANO JONICO'],
  ['F402', 'SI', 'MONTALCINO'],
  ['F403', 'AL', 'MONTALDEO'],
  ['F404', 'AL', 'MONTALDO BORMIDA'],
  ['F405', 'CN', 'MONTALDO DI MONDOVI\''],
  ['F408', 'CN', 'MONTALDO ROERO'],
  ['F409', 'AT', 'MONTALDO SCARAMPI'],
  ['F407', 'TO', 'MONTALDO TORINESE'],
  ['F410', 'PT', 'MONTALE'],
  ['F411', 'TO', 'MONTALENGHE'],
  ['F414', 'AG', 'MONTALLEGRO'],
  ['F415', 'AP', 'MONTALTO DELLE MARCHE'],
  ['F419', 'VT', 'MONTALTO DI CASTRO'],
  ['F420', 'TO', 'MONTALTO DORA'],
  ['F406', 'IM', 'MONTALTO LIGURE'],
  ['F417', 'PV', 'MONTALTO PAVESE'],
  ['F416', 'CS', 'MONTALTO UFFUGO'],
  ['F422', 'TO', 'MONTANARO'],
  ['F423', 'LO', 'MONTANASO LOMBARDO'],
  ['F424', 'CN', 'MONTANERA'],
  ['F426', 'SA', 'MONTANO ANTILIA'],
  ['F427', 'CO', 'MONTANO LUCINO'],
  ['F428', 'FM', 'MONTAPPONE'],
  ['F428', 'AP', 'MONTAPPONE'],
  ['F429', 'IS', 'MONTAQUILA'],
  ['F430', 'RI', 'MONTASOLA'],
  ['F432', 'CZ', 'MONTAURO'],
  ['F433', 'CH', 'MONTAZZOLI'],
  ['F437', 'GR', 'MONTE ARGENTARIO'],
  ['F456', 'PG', 'MONTE CASTELLO DI VIBIO'],
  ['F460', 'MC', 'MONTE CAVALLO'],
  ['F467', 'PU', 'MONTE CERIGNONE'],
  ['F476', 'RN', 'MONTE COLOMBO'],
  ['F434', 'CR', 'MONTE CREMASCO'],
  ['F486', 'VI', 'MONTE DI MALO'],
  ['F488', 'NA', 'MONTE DI PROCIDA'],
  ['F517', 'FM', 'MONTE GIBERTO'],
  ['F517', 'AP', 'MONTE GIBERTO'],
  ['F524', 'PU', 'MONTE GRIMANO TERME'],
  ['F532', 'BS', 'MONTE ISOLA'],
  ['F561', 'LC', 'MONTE MARENZO'],
  ['F589', 'PU', 'MONTE PORZIO'],
  ['F590', 'RM', 'MONTE PORZIO CATONE'],
  ['F599', 'FM', 'MONTE RINALDO'],
  ['F599', 'AP', 'MONTE RINALDO'],
  ['F600', 'AN', 'MONTE ROBERTO'],
  ['F603', 'VT', 'MONTE ROMANO'],
  ['F616', 'LT', 'MONTE SAN BIAGIO'],
  ['F618', 'SA', 'MONTE SAN GIACOMO'],
  ['F620', 'FR', 'MONTE SAN GIOVANNI CAMPANO'],
  ['F619', 'RI', 'MONTE SAN GIOVANNI IN SABINA'],
  ['F621', 'MC', 'MONTE SAN GIUSTO'],
  ['F622', 'MC', 'MONTE SAN MARTINO'],
  ['F626', 'FM', 'MONTE SAN PIETRANGELI'],
  ['F626', 'AP', 'MONTE SAN PIETRANGELI'],
  ['F627', 'BO', 'MONTE SAN PIETRO'],
  ['F628', 'AR', 'MONTE SAN SAVINO'],
  ['F634', 'AN', 'MONTE SAN VITO'],
  ['F631', 'FG', 'MONTE SANT\'ANGELO'],
  ['F629', 'PG', 'MONTE SANTA MARIA TIBERINA'],
  ['F653', 'FM', 'MONTE URANO'],
  ['F653', 'AP', 'MONTE URANO'],
  ['F664', 'FM', 'MONTE VIDON COMBATTE'],
  ['F664', 'AP', 'MONTE VIDON COMBATTE'],
  ['F665', 'FM', 'MONTE VIDON CORRADO'],
  ['F665', 'AP', 'MONTE VIDON CORRADO'],
  ['F440', 'PV', 'MONTEBELLO DELLA BATTAGLIA'],
  ['F441', 'PE', 'MONTEBELLO DI BERTONA'],
  ['D746', 'RC', 'MONTEBELLO IONICO'],
  ['B268', 'CH', 'MONTEBELLO SUL SANGRO'],
  ['F442', 'VI', 'MONTEBELLO VICENTINO'],
  ['F443', 'TV', 'MONTEBELLUNA'],
  ['F445', 'GE', 'MONTEBRUNO'],
  ['F446', 'RI', 'MONTEBUONO'],
  ['F450', 'PU', 'MONTECALVO IN FOGLIA'],
  ['F448', 'AV', 'MONTECALVO IRPINO'],
  ['F449', 'PV', 'MONTECALVO VERSIGGIA'],
  ['F452', 'LU', 'MONTECARLO'],
  ['F453', 'AN', 'MONTECAROTTO'],
  ['F454', 'MC', 'MONTECASSIANO'],
  ['F455', 'AL', 'MONTECASTELLO'],
  ['F457', 'TR', 'MONTECASTRILLI'],
  ['A561', 'PT', 'MONTECATINI TERME'],
  ['F458', 'PI', 'MONTECATINI VAL DI CECINA'],
  ['F461', 'VR', 'MONTECCHIA DI CROSARA'],
  ['F462', 'TR', 'MONTECCHIO'],
  ['F463', 'RE', 'MONTECCHIO EMILIA'],
  ['F464', 'VI', 'MONTECCHIO MAGGIORE'],
  ['F465', 'VI', 'MONTECCHIO PRECALCINO'],
  ['F469', 'AL', 'MONTECHIARO D\'ACQUI'],
  ['F468', 'AT', 'MONTECHIARO D\'ASTI'],
  ['F473', 'PR', 'MONTECHIARUGOLO'],
  ['F474', 'PU', 'MONTECICCARDO'],
  ['F475', 'CB', 'MONTECILFONE'],
  ['F477', 'RM', 'MONTECOMPATRI'],
  ['F478', 'PU', 'MONTECOPIOLO'],
  ['F479', 'SA', 'MONTECORICE'],
  ['F480', 'SA', 'MONTECORVINO PUGLIANO'],
  ['F481', 'SA', 'MONTECORVINO ROVELLA'],
  ['F482', 'MC', 'MONTECOSARO'],
  ['F483', 'VB', 'MONTECRESTESE'],
  ['F484', 'MO', 'MONTECRETO'],
  ['F487', 'AP', 'MONTEDINOVE'],
  ['F489', 'CL', 'MONTEDORO'],
  ['F491', 'AV', 'MONTEFALCIONE'],
  ['F492', 'PG', 'MONTEFALCO'],
  ['F493', 'FM', 'MONTEFALCONE APPENNINO'],
  ['F493', 'AP', 'MONTEFALCONE APPENNINO'],
  ['F494', 'BN', 'MONTEFALCONE DI VAL FORTORE'],
  ['F495', 'CB', 'MONTEFALCONE NEL SANNIO'],
  ['F496', 'MC', 'MONTEFANO'],
  ['F497', 'PU', 'MONTEFELCINO'],
  ['F498', 'CH', 'MONTEFERRANTE'],
  ['F499', 'VT', 'MONTEFIASCONE'],
  ['F500', 'TE', 'MONTEFINO'],
  ['F502', 'RN', 'MONTEFIORE CONCA'],
  ['F501', 'AP', 'MONTEFIORE DELL\'ASO'],
  ['F503', 'MO', 'MONTEFIORINO'],
  ['F504', 'RM', 'MONTEFLAVIO'],
  ['F507', 'SA', 'MONTEFORTE CILENTO'],
  ['F508', 'VR', 'MONTEFORTE D\'ALPONE'],
  ['F506', 'AV', 'MONTEFORTE IRPINO'],
  ['F509', 'FM', 'MONTEFORTINO'],
  ['F509', 'AP', 'MONTEFORTINO'],
  ['F510', 'TR', 'MONTEFRANCO'],
  ['F511', 'AV', 'MONTEFREDANE'],
  ['F512', 'AV', 'MONTEFUSCO'],
  ['F513', 'TR', 'MONTEGABBIONE'],
  ['F514', 'VI', 'MONTEGALDA'],
  ['F515', 'VI', 'MONTEGALDELLA'],
  ['F516', 'AP', 'MONTEGALLO'],
  ['F518', 'AL', 'MONTEGIOCO'],
  ['F519', 'CS', 'MONTEGIORDANO'],
  ['F520', 'FM', 'MONTEGIORGIO'],
  ['F520', 'AP', 'MONTEGIORGIO'],
  ['F522', 'FM', 'MONTEGRANARO'],
  ['F522', 'AP', 'MONTEGRANARO'],
  ['F523', 'RN', 'MONTEGRIDOLFO'],
  ['F526', 'VA', 'MONTEGRINO VALTRAVAGLIA'],
  ['F527', 'AT', 'MONTEGROSSO D\'ASTI'],
  ['F528', 'IM', 'MONTEGROSSO PIAN LATTE'],
  ['F529', 'PD', 'MONTEGROTTO TERME'],
  ['F531', 'TA', 'MONTEIASI'],
  ['F533', 'PU', 'MONTELABBATE'],
  ['F534', 'RM', 'MONTELANICO'],
  ['F535', 'CH', 'MONTELAPIANO'],
  ['F543', 'TR', 'MONTELEONE D\'ORVIETO'],
  ['F536', 'FM', 'MONTELEONE DI FERMO'],
  ['F536', 'AP', 'MONTELEONE DI FERMO'],
  ['F538', 'FG', 'MONTELEONE DI PUGLIA'],
  ['F540', 'PG', 'MONTELEONE DI SPOLETO'],
  ['F542', 'SS', 'MONTELEONE ROCCA DORIA'],
  ['F541', 'RI', 'MONTELEONE SABINO'],
  ['F544', 'PA', 'MONTELEPRE'],
  ['F545', 'RM', 'MONTELIBRETTI'],
  ['F546', 'AV', 'MONTELLA'],
  ['F547', 'BG', 'MONTELLO'],
  ['F548', 'CB', 'MONTELONGO'],
  ['F549', 'FM', 'MONTELPARO'],
  ['F549', 'AP', 'MONTELPARO'],
  ['F550', 'CN', 'MONTELUPO ALBESE'],
  ['F551', 'FI', 'MONTELUPO FIORENTINO'],
  ['F552', 'MC', 'MONTELUPONE'],
  ['F555', 'PU', 'MONTEMAGGIORE AL METAURO'],
  ['F553', 'PA', 'MONTEMAGGIORE BELSITO'],
  ['F556', 'AT', 'MONTEMAGNO'],
  ['F558', 'CN', 'MONTEMALE DI CUNEO'],
  ['F559', 'AV', 'MONTEMARANO'],
  ['F560', 'AN', 'MONTEMARCIANO'],
  ['F562', 'AL', 'MONTEMARZINO'],
  ['F563', 'TA', 'MONTEMESOLA'],
  ['F564', 'CO', 'MONTEMEZZO'],
  ['F565', 'AR', 'MONTEMIGNAIO'],
  ['F566', 'AV', 'MONTEMILETTO'],
  ['F568', 'PZ', 'MONTEMILONE'],
  ['F569', 'CB', 'MONTEMITRO'],
  ['F570', 'AP', 'MONTEMONACO'],
  ['F572', 'PO', 'MONTEMURLO'],
  ['F573', 'PZ', 'MONTEMURRO'],
  ['F574', 'UD', 'MONTENARS'],
  ['Z159', 'EE', 'MONTENEGRO'],
  ['F576', 'CB', 'MONTENERO DI BISACCIA'],
  ['F579', 'RI', 'MONTENERO SABINO'],
  ['F580', 'IS', 'MONTENERO VAL COCCHIARA'],
  ['F578', 'CH', 'MONTENERODOMO'],
  ['F582', 'CH', 'MONTEODORISIO'],
  ['F586', 'CZ', 'MONTEPAONE'],
  ['F587', 'TA', 'MONTEPARANO'],
  ['F591', 'AP', 'MONTEPRANDONE'],
  ['F592', 'SI', 'MONTEPULCIANO'],
  ['F593', 'AN', 'MONTERADO'],
  ['F594', 'AR', 'MONTERCHI'],
  ['F595', 'AQ', 'MONTEREALE'],
  ['F596', 'PN', 'MONTEREALE VALCELLINA'],
  ['F597', 'BO', 'MONTERENZIO'],
  ['F598', 'SI', 'MONTERIGGIONI'],
  ['F601', 'IS', 'MONTERODUNI'],
  ['F605', 'SI', 'MONTERONI D\'ARBIA'],
  ['F604', 'LE', 'MONTERONI DI LECCE'],
  ['F606', 'VT', 'MONTEROSI'],
  ['F609', 'SP', 'MONTEROSSO AL MARE'],
  ['F610', 'RG', 'MONTEROSSO ALMO'],
  ['F607', 'VV', 'MONTEROSSO CALABRO'],
  ['F608', 'CN', 'MONTEROSSO GRANA'],
  ['F611', 'RM', 'MONTEROTONDO'],
  ['F612', 'GR', 'MONTEROTONDO MARITTIMO'],
  ['F614', 'FM', 'MONTERUBBIANO'],
  ['F614', 'AP', 'MONTERUBBIANO'],
  ['F623', 'LE', 'MONTESANO SALENTINO'],
  ['F625', 'SA', 'MONTESANO SULLA MARCELLANA'],
  ['F636', 'BN', 'MONTESARCHIO'],
  ['F637', 'MT', 'MONTESCAGLIOSO'],
  ['F638', 'PV', 'MONTESCANO'],
  ['F639', 'VB', 'MONTESCHENO'],
  ['F640', 'PI', 'MONTESCUDAIO'],
  ['F641', 'RN', 'MONTESCUDO'],
  ['F642', 'MO', 'MONTESE'],
  ['F644', 'PV', 'MONTESEGALE'],
  ['F646', 'PE', 'MONTESILVANO'],
  ['F648', 'FI', 'MONTESPERTOLI'],
  ['F651', 'TO', 'MONTEU DA PO'],
  ['F654', 'CN', 'MONTEU ROERO'],
  ['F655', 'AG', 'MONTEVAGO'],
  ['F656', 'AR', 'MONTEVARCHI'],
  ['F657', 'LC', 'MONTEVECCHIA'],
  ['F659', 'BO', 'MONTEVEGLIO'],
  ['F660', 'AV', 'MONTEVERDE'],
  ['F661', 'PI', 'MONTEVERDI MARITTIMO'],
  ['F662', 'VI', 'MONTEVIALE'],
  ['F666', 'CN', 'MONTEZEMOLO'],
  ['F667', 'SS', 'MONTI'],
  ['F668', 'FC', 'MONTIANO'],
  ['F672', 'BS', 'MONTICELLI BRUSATI'],
  ['F671', 'PC', 'MONTICELLI D\'ONGINA'],
  ['F670', 'PV', 'MONTICELLI PAVESE'],
  ['F674', 'LC', 'MONTICELLO BRIANZA'],
  ['F675', 'VI', 'MONTICELLO CONTE OTTO'],
  ['F669', 'CN', 'MONTICELLO D\'ALBA'],
  ['F471', 'BS', 'MONTICHIARI'],
  ['F676', 'SI', 'MONTICIANO'],
  ['F677', 'GR', 'MONTIERI'],
  ['M302', 'AT', 'MONTIGLIO MONFERRATO'],
  ['F679', 'MS', 'MONTIGNOSO'],
  ['F680', 'BS', 'MONTIRONE'],
  ['F367', 'AO', 'MONTJOVET'],
  ['F681', 'CR', 'MONTODINE'],
  ['F682', 'GE', 'MONTOGGIO'],
  ['F685', 'PG', 'MONTONE'],
  ['F687', 'RI', 'MONTOPOLI DI SABINA'],
  ['F686', 'PI', 'MONTOPOLI IN VAL D\'ARNO'],
  ['F688', 'CO', 'MONTORFANO'],
  ['F690', 'TE', 'MONTORIO AL VOMANO'],
  ['F689', 'CB', 'MONTORIO NEI FRENTANI'],
  ['F692', 'RM', 'MONTORIO ROMANO'],
  ['F693', 'AV', 'MONTORO INFERIORE'],
  ['F694', 'AV', 'MONTORO SUPERIORE'],
  ['F696', 'VI', 'MONTORSO VICENTINO'],
  ['F697', 'FM', 'MONTOTTONE'],
  ['F697', 'AP', 'MONTOTTONE'],
  ['F698', 'NU', 'MONTRESTA'],
  ['Z531', 'EE', 'MONTSERRAT'],
  ['F701', 'PV', 'MONTU\' BECCARIA'],
  ['F703', 'VA', 'MONVALLE'],
  ['F704', 'MB', 'MONZA'],
  ['F704', 'MI', 'MONZA'],
  ['F705', 'MN', 'MONZAMBANO'],
  ['F706', 'BO', 'MONZUNO'],
  ['F708', 'CS', 'MORANO CALABRO'],
  ['F707', 'AL', 'MORANO SUL PO'],
  ['F709', 'AT', 'MORANSENGO'],
  ['F710', 'GO', 'MORARO'],
  ['F711', 'VA', 'MORAZZONE'],
  ['F712', 'SO', 'MORBEGNO'],
  ['F713', 'AL', 'MORBELLO'],
  ['F716', 'LE', 'MORCIANO DI LEUCA'],
  ['F715', 'RN', 'MORCIANO DI ROMAGNA'],
  ['F717', 'BN', 'MORCONE'],
  ['F718', 'BO', 'MORDANO'],
  ['F720', 'BG', 'MORENGO'],
  ['F721', 'SS', 'MORES'],
  ['F722', 'FM', 'MORESCO'],
  ['F722', 'AP', 'MORESCO'],
  ['F723', 'CN', 'MORETTA'],
  ['F724', 'PC', 'MORFASSO'],
  ['F725', 'TV', 'MORGANO'],
  ['F726', 'AO', 'MORGEX'],
  ['F727', 'OR', 'MORGONGIORI'],
  ['F728', 'TN', 'MORI'],
  ['F729', 'TV', 'MORIAGO DELLA BATTAGLIA'],
  ['F730', 'RM', 'MORICONE'],
  ['F731', 'SA', 'MORIGERATI'],
  ['D033', 'MI', 'MORIMONDO'],
  ['F732', 'AQ', 'MORINO'],
  ['F733', 'TO', 'MORIONDO TORINESE'],
  ['F734', 'RM', 'MORLUPO'],
  ['F735', 'CS', 'MORMANNO'],
  ['F736', 'VA', 'MORNAGO'],
  ['F737', 'AL', 'MORNESE'],
  ['F738', 'BG', 'MORNICO AL SERIO'],
  ['F739', 'PV', 'MORNICO LOSANA'],
  ['F740', 'FR', 'MOROLO'],
  ['F743', 'CN', 'MOROZZO'],
  ['F744', 'AV', 'MORRA DE SANCTIS'],
  ['F745', 'AN', 'MORRO D\'ALBA'],
  ['F747', 'TE', 'MORRO D\'ORO'],
  ['F746', 'RI', 'MORRO REATINO'],
  ['F748', 'CB', 'MORRONE DEL SANNIO'],
  ['F749', 'MC', 'MORROVALLE'],
  ['F750', 'PN', 'MORSANO AL TAGLIAMENTO'],
  ['F751', 'AL', 'MORSASCO'],
  ['F754', 'PV', 'MORTARA'],
  ['F756', 'UD', 'MORTEGLIANO'],
  ['F758', 'LC', 'MORTERONE'],
  ['F760', 'UD', 'MORUZZO'],
  ['F761', 'CR', 'MOSCAZZANO'],
  ['F762', 'AV', 'MOSCHIANO'],
  ['F764', 'TE', 'MOSCIANO SANT\'ANGELO'],
  ['F765', 'PE', 'MOSCUFO'],
  ['F766', 'BZ', 'MOSO IN PASSIRIA'],
  ['F767', 'GO', 'MOSSA'],
  ['F768', 'VI', 'MOSSANO'],
  ['M304', 'BI', 'MOSSO'],
  ['F771', 'CR', 'MOTTA BALUFFI'],
  ['F772', 'ME', 'MOTTA CAMASTRA'],
  ['F773', 'ME', 'MOTTA D\'AFFERMO'],
  ['F774', 'VC', 'MOTTA DE\' CONTI'],
  ['F770', 'TV', 'MOTTA DI LIVENZA'],
  ['F777', 'FG', 'MOTTA MONTECORVINO'],
  ['F779', 'RC', 'MOTTA SAN GIOVANNI'],
  ['F781', 'CT', 'MOTTA SANT\'ANASTASIA'],
  ['F780', 'CZ', 'MOTTA SANTA LUCIA'],
  ['F783', 'MI', 'MOTTA VISCONTI'],
  ['F775', 'CS', 'MOTTAFOLLONE'],
  ['F776', 'BI', 'MOTTALCIATA'],
  ['B012', 'MN', 'MOTTEGGIANA'],
  ['F784', 'TA', 'MOTTOLA'],
  ['Z333', 'EE', 'MOZAMBICO'],
  ['F785', 'CH', 'MOZZAGROGNA'],
  ['F786', 'BG', 'MOZZANICA'],
  ['F788', 'CO', 'MOZZATE'],
  ['F789', 'VR', 'MOZZECANE'],
  ['F791', 'BG', 'MOZZO'],
  ['F793', 'MC', 'MUCCIA'],
  ['F795', 'TS', 'MUGGIA'],
  ['F797', 'MI', 'MUGGIO\''],
  ['F797', 'MB', 'MUGGI\u201c'],
  ['F798', 'AV', 'MUGNANO DEL CARDINALE'],
  ['F799', 'NA', 'MUGNANO DI NAPOLI'],
  ['F801', 'LO', 'MULAZZANO'],
  ['F802', 'MS', 'MULAZZO'],
  ['F806', 'BS', 'MURA'],
  ['F808', 'CA', 'MURAVERA'],
  ['F809', 'CN', 'MURAZZANO'],
  ['F811', 'CN', 'MURELLO'],
  ['F813', 'SV', 'MURIALDO'],
  ['F814', 'AL', 'MURISENGO'],
  ['F815', 'SI', 'MURLO'],
  ['F816', 'LE', 'MURO LECCESE'],
  ['F817', 'PZ', 'MURO LUCANO'],
  ['F818', 'SS', 'MUROS'],
  ['F820', 'BS', 'MUSCOLINE'],
  ['F822', 'CA', 'MUSEI'],
  ['F826', 'VE', 'MUSILE DI PIAVE'],
  ['F828', 'CO', 'MUSSO'],
  ['F829', 'VI', 'MUSSOLENTE'],
  ['F830', 'CL', 'MUSSOMELI'],
  ['F832', 'UD', 'MUZZANA DEL TURGNANO'],
  ['F833', 'BI', 'MUZZANO'],
  ['Z206', 'EE', 'MYANMAR'],
  ['F835', 'TN', 'NAGO-TORBOLE'],
  ['F836', 'BZ', 'NALLES'],
  ['Z300', 'EE', 'NAMIBIA'],
  ['F837', 'TN', 'NANNO'],
  ['F838', 'VI', 'NANTO'],
  ['F839', 'NA', 'NAPOLI'],
  ['F840', 'OR', 'NARBOLIA'],
  ['F841', 'CA', 'NARCAO'],
  ['F842', 'LE', 'NARDO\''],
  ['F843', 'VV', 'NARDODIPACE'],
  ['F844', 'TR', 'NARNI'],
  ['F845', 'AG', 'NARO'],
  ['F846', 'CN', 'NARZOLE'],
  ['F847', 'SV', 'NASINO'],
  ['F848', 'ME', 'NASO'],
  ['F849', 'BZ', 'NATURNO'],
  ['Z713', 'EE', 'NAURU'],
  ['F851', 'BS', 'NAVE'],
  ['F853', 'TN', 'NAVE SAN ROCCO'],
  ['F852', 'AQ', 'NAVELLI'],
  ['F856', 'BZ', 'NAZ SCIAVES'],
  ['F857', 'RM', 'NAZZANO'],
  ['F858', 'GE', 'NE'],
  ['F859', 'NO', 'NEBBIUNO'],
  ['F861', 'VR', 'NEGRAR'],
  ['F862', 'GE', 'NEIRONE'],
  ['F863', 'CN', 'NEIVE'],
  ['F864', 'BG', 'NEMBRO'],
  ['F865', 'RM', 'NEMI'],
  ['F866', 'PZ', 'NEMOLI'],
  ['F867', 'OR', 'NEONELI'],
  ['Z234', 'EE', 'NEPAL'],
  ['F868', 'VT', 'NEPI'],
  ['F870', 'TE', 'NERETO'],
  ['F871', 'RM', 'NEROLA'],
  ['F872', 'TV', 'NERVESA DELLA BATTAGLIA'],
  ['F874', 'MI', 'NERVIANO'],
  ['F876', 'RI', 'NESPOLO'],
  ['F877', 'CO', 'NESSO'],
  ['F878', 'BI', 'NETRO'],
  ['F880', 'RM', 'NETTUNO'],
  ['F881', 'LE', 'NEVIANO'],
  ['F882', 'PR', 'NEVIANO DEGLI ARDUINI'],
  ['F883', 'CN', 'NEVIGLIE'],
  ['F884', 'BS', 'NIARDO'],
  ['F885', 'PC', 'NIBBIANO'],
  ['F886', 'NO', 'NIBBIOLA'],
  ['F887', 'LC', 'NIBIONNO'],
  ['Z515', 'EE', 'NICARAGUA'],
  ['F889', 'TO', 'NICHELINO'],
  ['F890', 'CT', 'NICOLOSI'],
  ['F891', 'PV', 'NICORVO'],
  ['F892', 'EN', 'NICOSIA'],
  ['F893', 'VV', 'NICOTERA'],
  ['F894', 'CN', 'NIELLA BELBO'],
  ['F895', 'CN', 'NIELLA TANARO'],
  ['Z334', 'EE', 'NIGER'],
  ['Z335', 'EE', 'NIGERIA'],
  ['F898', 'UD', 'NIMIS'],
  ['F899', 'CL', 'NISCEMI'],
  ['F900', 'EN', 'NISSORIA'],
  ['F901', 'ME', 'NIZZA DI SICILIA'],
  ['F902', 'AT', 'NIZZA MONFERRATO'],
  ['F904', 'VE', 'NOALE'],
  ['F906', 'TO', 'NOASCA'],
  ['F907', 'CS', 'NOCARA'],
  ['F908', 'PE', 'NOCCIANO'],
  ['F912', 'SA', 'NOCERA INFERIORE'],
  ['F913', 'SA', 'NOCERA SUPERIORE'],
  ['F910', 'CZ', 'NOCERA TERINESE'],
  ['F911', 'PG', 'NOCERA UMBRA'],
  ['F914', 'PR', 'NOCETO'],
  ['F915', 'BA', 'NOCI'],
  ['F916', 'LE', 'NOCIGLIA'],
  ['F917', 'PZ', 'NOEPOLI'],
  ['F918', 'VR', 'NOGARA'],
  ['F920', 'TN', 'NOGAREDO'],
  ['F921', 'VR', 'NOGAROLE ROCCA'],
  ['F922', 'VI', 'NOGAROLE VICENTINO'],
  ['F923', 'BA', 'NOICATTARO'],
  ['F924', 'NA', 'NOLA'],
  ['F925', 'TO', 'NOLE'],
  ['F926', 'SV', 'NOLI'],
  ['F927', 'TO', 'NOMAGLIO'],
  ['F929', 'TN', 'NOMI'],
  ['F930', 'MO', 'NONANTOLA'],
  ['F931', 'TO', 'NONE'],
  ['F932', 'VB', 'NONIO'],
  ['F933', 'NU', 'NORAGUGUME'],
  ['F934', 'OR', 'NORBELLO'],
  ['F935', 'PG', 'NORCIA'],
  ['Z715', 'EE', 'NORFOLK (ISOLE E ISOLE DEL MAR DEI CORALLI)'],
  ['F937', 'LT', 'NORMA'],
  ['Z125', 'EE', 'NORVEGIA'],
  ['F939', 'MI', 'NOSATE'],
  ['F942', 'TE', 'NOTARESCO'],
  ['F943', 'SR', 'NOTO'],
  ['F949', 'BZ', 'NOVA LEVANTE'],
  ['F944', 'MB', 'NOVA MILANESE'],
  ['F944', 'MI', 'NOVA MILANESE'],
  ['F950', 'BZ', 'NOVA PONENTE'],
  ['A942', 'MT', 'NOVA SIRI'],
  ['F137', 'PU', 'NOVAFELTRIA'],
  ['F947', 'TN', 'NOVALEDO'],
  ['F948', 'TO', 'NOVALESA'],
  ['F952', 'NO', 'NOVARA'],
  ['F951', 'ME', 'NOVARA DI SICILIA'],
  ['F956', 'SO', 'NOVATE MEZZOLA'],
  ['F955', 'MI', 'NOVATE MILANESE'],
  ['F957', 'VI', 'NOVE'],
  ['F958', 'CO', 'NOVEDRATE'],
  ['F960', 'RE', 'NOVELLARA'],
  ['F961', 'CN', 'NOVELLO'],
  ['F963', 'VE', 'NOVENTA DI PIAVE'],
  ['F962', 'PD', 'NOVENTA PADOVANA'],
  ['F964', 'VI', 'NOVENTA VICENTINA'],
  ['F966', 'MO', 'NOVI DI MODENA'],
  ['F965', 'AL', 'NOVI LIGURE'],
  ['F967', 'SA', 'NOVI VELIA'],
  ['F968', 'MI', 'NOVIGLIO'],
  ['F970', 'LE', 'NOVOLI'],
  ['F972', 'CN', 'NUCETTO'],
  ['F975', 'SS', 'NUGHEDU SAN NICOLO\''],
  ['F974', 'OR', 'NUGHEDU SANTA VITTORIA'],
  ['F976', 'SS', 'NULE'],
  ['F977', 'SS', 'NULVI'],
  ['F978', 'AN', 'NUMANA'],
  ['F979', 'NU', 'NUORO'],
  ['Z716', 'EE', 'NUOVA CALEDONIA (ISOLE E DIPENDENZE)'],
  ['Z719', 'EE', 'NUOVA ZELANDA'],
  ['F980', 'OR', 'NURACHI'],
  ['F981', 'NU', 'NURAGUS'],
  ['F982', 'NU', 'NURALLAO'],
  ['F983', 'CA', 'NURAMINIS'],
  ['F985', 'OR', 'NURECI'],
  ['F986', 'NU', 'NURRI'],
  ['F987', 'AO', 'NUS'],
  ['F988', 'AV', 'NUSCO'],
  ['F989', 'BS', 'NUVOLENTO'],
  ['F990', 'BS', 'NUVOLERA'],
  ['F991', 'CA', 'NUXIS'],
  ['F992', 'BI', 'OCCHIEPPO INFERIORE'],
  ['F993', 'BI', 'OCCHIEPPO SUPERIORE'],
  ['F994', 'RO', 'OCCHIOBELLO'],
  ['F995', 'AL', 'OCCIMIANO'],
  ['F996', 'AQ', 'OCRE'],
  ['F997', 'AL', 'ODALENGO GRANDE'],
  ['F998', 'AL', 'ODALENGO PICCOLO'],
  ['F999', 'TV', 'ODERZO'],
  ['G001', 'BS', 'ODOLO'],
  ['G002', 'AQ', 'OFENA'],
  ['G003', 'AN', 'OFFAGNA'],
  ['G004', 'CR', 'OFFANENGO'],
  ['G005', 'AP', 'OFFIDA'],
  ['G006', 'BS', 'OFFLAGA'],
  ['G007', 'VB', 'OGGEBBIO'],
  ['G008', 'VA', 'OGGIONA CON SANTO STEFANO'],
  ['G009', 'LC', 'OGGIONO'],
  ['G010', 'TO', 'OGLIANICO'],
  ['G011', 'SA', 'OGLIASTRO CILENTO'],
  ['G015', 'SS', 'OLBIA'],
  ['G016', 'VC', 'OLCENENGO'],
  ['G018', 'VC', 'OLDENICO'],
  ['G019', 'NO', 'OLEGGIO'],
  ['G020', 'NO', 'OLEGGIO CASTELLO'],
  ['G021', 'PV', 'OLEVANO DI LOMELLINA'],
  ['G022', 'RM', 'OLEVANO ROMANO'],
  ['G023', 'SA', 'OLEVANO SUL TUSCIANO'],
  ['G025', 'CO', 'OLGIATE COMASCO'],
  ['G026', 'LC', 'OLGIATE MOLGORA'],
  ['G028', 'VA', 'OLGIATE OLONA'],
  ['G030', 'LC', 'OLGINATE'],
  ['G031', 'NU', 'OLIENA'],
  ['G032', 'PV', 'OLIVA GESSI'],
  ['G034', 'CZ', 'OLIVADI'],
  ['G036', 'ME', 'OLIVERI'],
  ['G039', 'SA', 'OLIVETO CITRA'],
  ['G040', 'LC', 'OLIVETO LARIO'],
  ['G037', 'MT', 'OLIVETO LUCANO'],
  ['G041', 'IM', 'OLIVETTA SAN MICHELE'],
  ['G042', 'AL', 'OLIVOLA'],
  ['G043', 'OR', 'OLLASTRA'],
  ['G044', 'NU', 'OLLOLAI'],
  ['G045', 'AO', 'OLLOMONT'],
  ['G046', 'SS', 'OLMEDO'],
  ['G047', 'CR', 'OLMENETA'],
  ['G049', 'BG', 'OLMO AL BREMBO'],
  ['G048', 'AT', 'OLMO GENTILE'],
  ['G050', 'BG', 'OLTRE IL COLLE'],
  ['G054', 'BG', 'OLTRESSENDA ALTA'],
  ['G056', 'CO', 'OLTRONA DI SAN MAMETTE'],
  ['G058', 'NU', 'OLZAI'],
  ['Z235', 'EE', 'OMAN'],
  ['G061', 'BS', 'OME'],
  ['G062', 'VB', 'OMEGNA'],
  ['G063', 'SA', 'OMIGNANO'],
  ['G064', 'NU', 'ONANI'],
  ['G065', 'VT', 'ONANO'],
  ['G066', 'CN', 'ONCINO'],
  ['G068', 'BG', 'ONETA'],
  ['G070', 'NU', 'ONIFAI'],
  ['G071', 'NU', 'ONIFERI'],
  ['G074', 'BS', 'ONO SAN PIETRO'],
  ['G075', 'BG', 'ONORE'],
  ['G076', 'SV', 'ONZO'],
  ['G078', 'MI', 'OPERA'],
  ['G079', 'AQ', 'OPI'],
  ['G080', 'VR', 'OPPEANO'],
  ['G081', 'PZ', 'OPPIDO LUCANO'],
  ['G082', 'RC', 'OPPIDO MAMERTINA'],
  ['G083', 'BZ', 'ORA'],
  ['G084', 'NU', 'ORANI'],
  ['G086', 'CB', 'ORATINO'],
  ['G087', 'TO', 'ORBASSANO'],
  ['G088', 'GR', 'ORBETELLO'],
  ['G089', 'PU', 'ORCIANO DI PESARO'],
  ['G090', 'PI', 'ORCIANO PISANO'],
  ['D522', 'SV', 'ORCO FEGLINO'],
  ['M266', 'FG', 'ORDONA'],
  ['G093', 'GE', 'ORERO'],
  ['G095', 'VI', 'ORGIANO'],
  ['G097', 'NU', 'ORGOSOLO'],
  ['G098', 'BR', 'ORIA'],
  ['G102', 'AQ', 'ORICOLA'],
  ['G103', 'VA', 'ORIGGIO'],
  ['G105', 'VA', 'ORINO'],
  ['G108', 'BG', 'ORIO AL SERIO'],
  ['G109', 'TO', 'ORIO CANAVESE'],
  ['G107', 'LO', 'ORIO LITTA'],
  ['G110', 'CS', 'ORIOLO'],
  ['G111', 'VT', 'ORIOLO ROMANO'],
  ['G113', 'OR', 'ORISTANO'],
  ['G114', 'CN', 'ORMEA'],
  ['G115', 'TV', 'ORMELLE'],
  ['G116', 'MB', 'ORNAGO'],
  ['G116', 'MI', 'ORNAGO'],
  ['G117', 'VB', 'ORNAVASSO'],
  ['G118', 'BG', 'ORNICA'],
  ['G119', 'NU', 'OROSEI'],
  ['G120', 'NU', 'OROTELLI'],
  ['G121', 'SA', 'ORRIA'],
  ['G122', 'NU', 'ORROLI'],
  ['G123', 'TV', 'ORSAGO'],
  ['G124', 'AL', 'ORSARA BORMIDA'],
  ['G125', 'FG', 'ORSARA DI PUGLIA'],
  ['G126', 'CO', 'ORSENIGO'],
  ['G128', 'CH', 'ORSOGNA'],
  ['G129', 'CS', 'ORSOMARSO'],
  ['G130', 'CE', 'ORTA DI ATELLA'],
  ['G131', 'FG', 'ORTA NOVA'],
  ['G134', 'NO', 'ORTA SAN GIULIO'],
  ['G133', 'CA', 'ORTACESUS'],
  ['G135', 'VT', 'ORTE'],
  ['G136', 'LE', 'ORTELLE'],
  ['G137', 'FM', 'ORTEZZANO'],
  ['G137', 'AP', 'ORTEZZANO'],
  ['G139', 'AR', 'ORTIGNANO RAGGIOLO'],
  ['G140', 'BZ', 'ORTISEI'],
  ['G141', 'CH', 'ORTONA'],
  ['G142', 'AQ', 'ORTONA DEI MARSI'],
  ['G143', 'SP', 'ORTONOVO'],
  ['G144', 'SV', 'ORTOVERO'],
  ['G145', 'AQ', 'ORTUCCHIO'],
  ['G146', 'NU', 'ORTUERI'],
  ['G147', 'NU', 'ORUNE'],
  ['G148', 'TR', 'ORVIETO'],
  ['B595', 'RI', 'ORVINIO'],
  ['G149', 'BS', 'ORZINUOVI'],
  ['G150', 'BS', 'ORZIVECCHI'],
  ['G151', 'TO', 'OSASCO'],
  ['G152', 'TO', 'OSASIO'],
  ['G153', 'SS', 'OSCHIRI'],
  ['G154', 'NU', 'OSIDDA'],
  ['G155', 'SV', 'OSIGLIA'],
  ['G156', 'SS', 'OSILO'],
  ['G157', 'AN', 'OSIMO'],
  ['G158', 'NU', 'OSINI'],
  ['G159', 'BG', 'OSIO SOPRA'],
  ['G160', 'BG', 'OSIO SOTTO'],
  ['E529', 'VA', 'OSMATE'],
  ['G161', 'LC', 'OSNAGO'],
  ['G163', 'UD', 'OSOPPO'],
  ['G164', 'IM', 'OSPEDALETTI'],
  ['G168', 'TN', 'OSPEDALETTO'],
  ['G165', 'AV', 'OSPEDALETTO D\'ALPINOLO'],
  ['G167', 'PD', 'OSPEDALETTO EUGANEO'],
  ['G166', 'LO', 'OSPEDALETTO LODIGIANO'],
  ['G169', 'BL', 'OSPITALE DI CADORE'],
  ['G170', 'BS', 'OSPITALETTO'],
  ['G171', 'LO', 'OSSAGO LODIGIANO'],
  ['G173', 'TN', 'OSSANA'],
  ['G178', 'SS', 'OSSI'],
  ['G179', 'BS', 'OSSIMO'],
  ['G181', 'MI', 'OSSONA'],
  ['G182', 'CO', 'OSSUCCIO'],
  ['G183', 'CN', 'OSTANA'],
  ['G184', 'FE', 'OSTELLATO'],
  ['G185', 'CR', 'OSTIANO'],
  ['G186', 'MN', 'OSTIGLIA'],
  ['F401', 'AN', 'OSTRA'],
  ['F581', 'AN', 'OSTRA VETERE'],
  ['G187', 'BR', 'OSTUNI'],
  ['G188', 'LE', 'OTRANTO'],
  ['G189', 'TR', 'OTRICOLI'],
  ['G191', 'NU', 'OTTANA'],
  ['G192', 'SA', 'OTTATI'],
  ['G190', 'NA', 'OTTAVIANO'],
  ['G193', 'AL', 'OTTIGLIO'],
  ['G194', 'PV', 'OTTOBIANO'],
  ['G195', 'PC', 'OTTONE'],
  ['G196', 'TO', 'OULX'],
  ['G197', 'AL', 'OVADA'],
  ['G198', 'UD', 'OVARO'],
  ['G199', 'AL', 'OVIGLIO'],
  ['G200', 'AQ', 'OVINDOLI'],
  ['G201', 'NU', 'OVODDA'],
  ['G012', 'AO', 'OYACE'],
  ['G202', 'TO', 'OZEGNA'],
  ['G203', 'SS', 'OZIERI'],
  ['G205', 'BO', 'OZZANO DELL\'EMILIA'],
  ['G204', 'AL', 'OZZANO MONFERRATO'],
  ['G206', 'MI', 'OZZERO'],
  ['G207', 'CA', 'PABILLONIS'],
  ['G209', 'ME', 'PACE DEL MELA'],
  ['G208', 'TP', 'PACECO'],
  ['G210', 'AQ', 'PACENTRO'],
  ['G211', 'SR', 'PACHINO'],
  ['G212', 'PG', 'PACIANO'],
  ['G213', 'BS', 'PADENGHE SUL GARDA'],
  ['G214', 'TN', 'PADERGNONE'],
  ['G215', 'AL', 'PADERNA'],
  ['G218', 'LC', 'PADERNO D\'ADDA'],
  ['G221', 'TV', 'PADERNO DEL GRAPPA'],
  ['G220', 'MI', 'PADERNO DUGNANO'],
  ['G217', 'BS', 'PADERNO FRANCIACORTA'],
  ['G222', 'CR', 'PADERNO PONCHIELLI'],
  ['G224', 'PD', 'PADOVA'],
  ['G225', 'SS', 'PADRIA'],
  ['M301', 'SS', 'PADRU'],
  ['G226', 'SA', 'PADULA'],
  ['G227', 'BN', 'PADULI'],
  ['G228', 'CN', 'PAESANA'],
  ['G229', 'TV', 'PAESE'],
  ['Z126', 'EE', 'PAESI BASSI'],
  ['G230', 'SA', 'PAGANI'],
  ['G232', 'RI', 'PAGANICO SABINO'],
  ['G233', 'BG', 'PAGAZZANO'],
  ['G234', 'ME', 'PAGLIARA'],
  ['G237', 'CH', 'PAGLIETA'],
  ['G238', 'UD', 'PAGNACCO'],
  ['G240', 'CN', 'PAGNO'],
  ['G241', 'LC', 'PAGNONA'],
  ['G242', 'AV', 'PAGO DEL VALLO DI LAURO'],
  ['G243', 'BN', 'PAGO VEIANO'],
  ['G247', 'BS', 'PAISCO LOVENO'],
  ['G248', 'BS', 'PAITONE'],
  ['Z236', 'EE', 'PAKISTAN'],
  ['G249', 'BG', 'PALADINA'],
  ['G250', 'MO', 'PALAGANO'],
  ['G251', 'TA', 'PALAGIANELLO'],
  ['G252', 'TA', 'PALAGIANO'],
  ['G253', 'CT', 'PALAGONIA'],
  ['G254', 'PI', 'PALAIA'],
  ['G255', 'PR', 'PALANZANO'],
  ['G257', 'CB', 'PALATA'],
  ['Z734', 'EE', 'PALAU'],
  ['G258', 'SS', 'PALAU'],
  ['G259', 'BG', 'PALAZZAGO'],
  ['G263', 'PA', 'PALAZZO ADRIANO'],
  ['G262', 'TO', 'PALAZZO CANAVESE'],
  ['G260', 'CR', 'PALAZZO PIGNANO'],
  ['G261', 'PZ', 'PALAZZO SAN GERVASIO'],
  ['G267', 'SR', 'PALAZZOLO ACREIDE'],
  ['G268', 'UD', 'PALAZZOLO DELLO STELLA'],
  ['G264', 'BS', 'PALAZZOLO SULL\'OGLIO'],
  ['G266', 'VC', 'PALAZZOLO VERCELLESE'],
  ['G270', 'FI', 'PALAZZUOLO SUL SENIO'],
  ['G271', 'CH', 'PALENA'],
  ['G272', 'CZ', 'PALERMITI'],
  ['G273', 'PA', 'PALERMO'],
  ['G274', 'RM', 'PALESTRINA'],
  ['G275', 'PV', 'PALESTRO'],
  ['G276', 'FR', 'PALIANO'],
  ['G277', 'RC', 'PALIZZI'],
  ['G278', 'KR', 'PALLAGORIO'],
  ['G280', 'VB', 'PALLANZENO'],
  ['G281', 'SV', 'PALLARE'],
  ['G283', 'NA', 'PALMA CAMPANIA'],
  ['G282', 'AG', 'PALMA DI MONTECHIARO'],
  ['G284', 'UD', 'PALMANOVA'],
  ['G285', 'LE', 'PALMARIGGI'],
  ['G286', 'OR', 'PALMAS ARBOREA'],
  ['G288', 'RC', 'PALMI'],
  ['G289', 'AP', 'PALMIANO'],
  ['G290', 'CH', 'PALMOLI'],
  ['G291', 'BA', 'PALO DEL COLLE'],
  ['G293', 'RM', 'PALOMBARA SABINA'],
  ['G294', 'CH', 'PALOMBARO'],
  ['G292', 'SA', 'PALOMONTE'],
  ['G295', 'BG', 'PALOSCO'],
  ['G297', 'VR', 'PALU\''],
  ['G296', 'TN', 'PALU\' DEL FERSINA'],
  ['G298', 'CS', 'PALUDI'],
  ['G300', 'UD', 'PALUZZA'],
  ['G302', 'CN', 'PAMPARATO'],
  ['Z516', 'EE', 'PANAMA'],
  ['Z517', 'EE', 'PANAMA ZONA DEL CANALE'],
  ['G303', 'TO', 'PANCALIERI'],
  ['G304', 'PV', 'PANCARANA'],
  ['G305', 'TN', 'PANCHIA\''],
  ['G306', 'CR', 'PANDINO'],
  ['G307', 'CS', 'PANETTIERI'],
  ['G308', 'PG', 'PANICALE'],
  ['G311', 'BN', 'PANNARANO'],
  ['G312', 'FG', 'PANNI'],
  ['G315', 'TP', 'PANTELLERIA'],
  ['G316', 'MI', 'PANTIGLIATE'],
  ['G317', 'CS', 'PAOLA'],
  ['G318', 'BN', 'PAOLISI'],
  ['G320', 'CS', 'PAPASIDERO'],
  ['G323', 'RO', 'PAPOZZE'],
  ['Z730', 'EE', 'PAPUA NUOVA GUINEA'],
  ['G324', 'MI', 'PARABIAGO'],
  ['G325', 'LE', 'PARABITA'],
  ['Z610', 'EE', 'PARAGUAY'],
  ['G327', 'BS', 'PARATICO'],
  ['G328', 'BZ', 'PARCINES'],
  ['G329', 'CO', 'PARE\''],
  ['G330', 'TO', 'PARELLA'],
  ['G331', 'CS', 'PARENTI'],
  ['G333', 'CE', 'PARETE'],
  ['G334', 'AL', 'PARETO'],
  ['G335', 'VV', 'PARGHELIA'],
  ['G336', 'LC', 'PARLASCO'],
  ['G337', 'PR', 'PARMA'],
  ['G338', 'AL', 'PARODI LIGURE'],
  ['G339', 'CN', 'PAROLDO'],
  ['G340', 'AV', 'PAROLISE'],
  ['G342', 'PV', 'PARONA'],
  ['G344', 'TR', 'PARRANO'],
  ['G346', 'BG', 'PARRE'],
  ['G347', 'TP', 'PARTANNA'],
  ['G348', 'PA', 'PARTINICO'],
  ['G349', 'NO', 'PARUZZARO'],
  ['G350', 'BG', 'PARZANICA'],
  ['G352', 'UD', 'PASIAN DI PRATO'],
  ['G353', 'PN', 'PASIANO DI PORDENONE'],
  ['G354', 'BS', 'PASPARDO'],
  ['G358', 'AT', 'PASSERANO MARMORITO'],
  ['G359', 'PG', 'PASSIGNANO SUL TRASIMENO'],
  ['G361', 'BS', 'PASSIRANO'],
  ['G362', 'FR', 'PASTENA'],
  ['G364', 'CE', 'PASTORANO'],
  ['G365', 'VR', 'PASTRENGO'],
  ['G367', 'AL', 'PASTURANA'],
  ['G368', 'LC', 'PASTURO'],
  ['M269', 'PZ', 'PATERNO'],
  ['G372', 'CS', 'PATERNO CALABRO'],
  ['G371', 'CT', 'PATERNO\''],
  ['G370', 'AV', 'PATERNOPOLI'],
  ['G374', 'FR', 'PATRICA'],
  ['G376', 'SS', 'PATTADA'],
  ['G377', 'ME', 'PATTI'],
  ['G378', 'LE', 'PATU\''],
  ['G379', 'OR', 'PAU'],
  ['G381', 'UD', 'PAULARO'],
  ['G382', 'CA', 'PAULI ARBAREI'],
  ['G384', 'OR', 'PAULILATINO'],
  ['G385', 'MI', 'PAULLO'],
  ['G386', 'BN', 'PAUPISI'],
  ['G387', 'TO', 'PAVAROLO'],
  ['G388', 'PV', 'PAVIA'],
  ['G389', 'UD', 'PAVIA DI UDINE'],
  ['G392', 'TO', 'PAVONE CANAVESE'],
  ['G391', 'BS', 'PAVONE DEL MELLA'],
  ['G393', 'MO', 'PAVULLO NEL FRIGNANO'],
  ['G394', 'RC', 'PAZZANO'],
  ['G395', 'PI', 'PECCIOLI'],
  ['G396', 'TO', 'PECCO'],
  ['G397', 'AL', 'PECETTO DI VALENZA'],
  ['G398', 'TO', 'PECETTO TORINESE'],
  ['G399', 'PC', 'PECORARA'],
  ['G400', 'CS', 'PEDACE'],
  ['G402', 'CT', 'PEDARA'],
  ['G403', 'FM', 'PEDASO'],
  ['G403', 'AP', 'PEDASO'],
  ['G404', 'BL', 'PEDAVENA'],
  ['G406', 'VI', 'PEDEMONTE'],
  ['G408', 'TV', 'PEDEROBBA'],
  ['G410', 'SO', 'PEDESINA'],
  ['G411', 'CS', 'PEDIVIGLIANO'],
  ['G412', 'BG', 'PEDRENGO'],
  ['G416', 'PU', 'PEGLIO'],
  ['G415', 'CO', 'PEGLIO'],
  ['G417', 'MN', 'PEGOGNAGA'],
  ['G418', 'BG', 'PEIA'],
  ['G419', 'TN', 'PEJO'],
  ['G420', 'FI', 'PELAGO'],
  ['G421', 'NO', 'PELLA'],
  ['G424', 'PR', 'PELLEGRINO PARMENSE'],
  ['G426', 'SA', 'PELLEZZANO'],
  ['G427', 'CO', 'PELLIO INTELVI'],
  ['G428', 'TN', 'PELLIZZANO'],
  ['G429', 'TN', 'PELUGO'],
  ['G430', 'AT', 'PENANGO'],
  ['G432', 'TR', 'PENNA IN TEVERINA'],
  ['G436', 'MC', 'PENNA SAN GIOVANNI'],
  ['G437', 'TE', 'PENNA SANT\'ANDREA'],
  ['G433', 'PU', 'PENNABILLI'],
  ['G434', 'CH', 'PENNADOMO'],
  ['G435', 'CH', 'PENNAPIEDIMONTE'],
  ['G438', 'PE', 'PENNE'],
  ['G439', 'CZ', 'PENTONE'],
  ['G441', 'CH', 'PERANO'],
  ['G442', 'BL', 'PERAROLO DI CADORE'],
  ['G443', 'BZ', 'PERCA'],
  ['G444', 'RM', 'PERCILE'],
  ['G445', 'NU', 'PERDASDEFOGU'],
  ['G446', 'CA', 'PERDAXIUS'],
  ['G447', 'SA', 'PERDIFUMO'],
  ['G448', 'LC', 'PEREGO'],
  ['G449', 'AQ', 'PERETO'],
  ['G450', 'SS', 'PERFUGAS'],
  ['G451', 'AR', 'PERGINE VALDARNO'],
  ['G452', 'TN', 'PERGINE VALSUGANA'],
  ['G453', 'PU', 'PERGOLA'],
  ['G454', 'IM', 'PERINALDO'],
  ['G455', 'SA', 'PERITO'],
  ['G456', 'LC', 'PERLEDO'],
  ['G457', 'CN', 'PERLETTO'],
  ['G458', 'CN', 'PERLO'],
  ['G459', 'AO', 'PERLOZ'],
  ['G461', 'PD', 'PERNUMIA'],
  ['C013', 'MI', 'PERO'],
  ['G463', 'TO', 'PEROSA ARGENTINA'],
  ['G462', 'TO', 'PEROSA CANAVESE'],
  ['G465', 'TO', 'PERRERO'],
  ['G469', 'CR', 'PERSICO DOSIMO'],
  ['G471', 'VC', 'PERTENGO'],
  ['G474', 'BS', 'PERTICA ALTA'],
  ['G475', 'BS', 'PERTICA BASSA'],
  ['G476', 'SA', 'PERTOSA'],
  ['G477', 'TO', 'PERTUSIO'],
  ['Z611', 'EE', 'PERU\''],
  ['G478', 'PG', 'PERUGIA'],
  ['G479', 'PU', 'PESARO'],
  ['G480', 'LU', 'PESCAGLIA'],
  ['G481', 'VR', 'PESCANTINA'],
  ['G482', 'PE', 'PESCARA'],
  ['G483', 'CR', 'PESCAROLO ED UNITI'],
  ['G484', 'AQ', 'PESCASSEROLI'],
  ['G485', 'LC', 'PESCATE'],
  ['G486', 'IS', 'PESCHE'],
  ['G487', 'FG', 'PESCHICI'],
  ['G488', 'MI', 'PESCHIERA BORROMEO'],
  ['G489', 'VR', 'PESCHIERA DEL GARDA'],
  ['G491', 'PT', 'PESCIA'],
  ['G492', 'AQ', 'PESCINA'],
  ['G494', 'BN', 'PESCO SANNITA'],
  ['G493', 'AQ', 'PESCOCOSTANZO'],
  ['G495', 'IS', 'PESCOLANCIANO'],
  ['G496', 'PZ', 'PESCOPAGANO'],
  ['G497', 'IS', 'PESCOPENNATARO'],
  ['G498', 'RI', 'PESCOROCCHIANO'],
  ['G499', 'PE', 'PESCOSANSONESCO'],
  ['G500', 'FR', 'PESCOSOLIDO'],
  ['G502', 'MI', 'PESSANO CON BORNAGO'],
  ['G504', 'CR', 'PESSINA CREMONESE'],
  ['G505', 'TO', 'PESSINETTO'],
  ['G506', 'CB', 'PETACCIATO'],
  ['G508', 'KR', 'PETILIA POLICASTRO'],
  ['G509', 'SA', 'PETINA'],
  ['G510', 'PA', 'PETRALIA SOPRANA'],
  ['G511', 'PA', 'PETRALIA SOTTANA'],
  ['G513', 'RI', 'PETRELLA SALTO'],
  ['G512', 'CB', 'PETRELLA TIFERNINA'],
  ['G514', 'PU', 'PETRIANO'],
  ['G515', 'MC', 'PETRIOLO'],
  ['G516', 'FM', 'PETRITOLI'],
  ['G516', 'AP', 'PETRITOLI'],
  ['G517', 'CZ', 'PETRIZZI'],
  ['G518', 'CZ', 'PETRONA\''],
  ['M281', 'TP', 'PETROSINO'],
  ['G519', 'AV', 'PETRURO IRPINO'],
  ['G520', 'NO', 'PETTENASCO'],
  ['G521', 'BI', 'PETTINENGO'],
  ['G522', 'ME', 'PETTINEO'],
  ['G523', 'IS', 'PETTORANELLO DEL MOLISE'],
  ['G524', 'AQ', 'PETTORANO SUL GIZIO'],
  ['G525', 'RO', 'PETTORAZZA GRIMANI'],
  ['G526', 'CN', 'PEVERAGNO'],
  ['G528', 'VC', 'PEZZANA'],
  ['G529', 'BS', 'PEZZAZE'],
  ['G532', 'CN', 'PEZZOLO VALLE UZZONE'],
  ['G535', 'PC', 'PIACENZA'],
  ['G534', 'PD', 'PIACENZA D\'ADIGE'],
  ['G536', 'CR', 'PIADENA'],
  ['G537', 'PU', 'PIAGGE'],
  ['G538', 'SA', 'PIAGGINE'],
  ['G546', 'BS', 'PIAN CAMUNO'],
  ['G552', 'AR', 'PIAN DI SCO'],
  ['G542', 'SV', 'PIANA CRIXIA'],
  ['G543', 'PA', 'PIANA DEGLI ALBANESI'],
  ['G541', 'CE', 'PIANA DI MONTE VERNA'],
  ['G547', 'SI', 'PIANCASTAGNAIO'],
  ['G549', 'BS', 'PIANCOGNO'],
  ['G551', 'PU', 'PIANDIMELETO'],
  ['G553', 'CS', 'PIANE CRATI'],
  ['G555', 'PE', 'PIANELLA'],
  ['G556', 'CO', 'PIANELLO DEL LARIO'],
  ['G557', 'PC', 'PIANELLO VAL TIDONE'],
  ['G558', 'CR', 'PIANENGO'],
  ['G559', 'TO', 'PIANEZZA'],
  ['G560', 'VI', 'PIANEZZE'],
  ['G561', 'CN', 'PIANFEI'],
  ['G564', 'BG', 'PIANICO'],
  ['G565', 'VE', 'PIANIGA'],
  ['G568', 'NA', 'PIANO DI SORRENTO'],
  ['D546', 'CZ', 'PIANOPOLI'],
  ['G570', 'BO', 'PIANORO'],
  ['G571', 'VT', 'PIANSANO'],
  ['G572', 'SO', 'PIANTEDO'],
  ['G574', 'BG', 'PIARIO'],
  ['G575', 'CN', 'PIASCO'],
  ['G576', 'SO', 'PIATEDA'],
  ['G577', 'BI', 'PIATTO'],
  ['G582', 'LU', 'PIAZZA AL SERCHIO'],
  ['G580', 'EN', 'PIAZZA ARMERINA'],
  ['G579', 'BG', 'PIAZZA BREMBANA'],
  ['G583', 'BG', 'PIAZZATORRE'],
  ['G587', 'PD', 'PIAZZOLA SUL BRENTA'],
  ['G588', 'BG', 'PIAZZOLO'],
  ['G589', 'PE', 'PICCIANO'],
  ['G590', 'PZ', 'PICERNO'],
  ['G591', 'FR', 'PICINISCO'],
  ['G592', 'FR', 'PICO'],
  ['G593', 'AT', 'PIEA'],
  ['G594', 'BI', 'PIEDICAVALLO'],
  ['G597', 'CT', 'PIEDIMONTE ETNEO'],
  ['G596', 'CE', 'PIEDIMONTE MATESE'],
  ['G598', 'FR', 'PIEDIMONTE SAN GERMANO'],
  ['G600', 'VB', 'PIEDIMULERA'],
  ['G601', 'PG', 'PIEGARO'],
  ['G602', 'SI', 'PIENZA'],
  ['G603', 'CR', 'PIERANICA'],
  ['G612', 'PV', 'PIETRA DE\' GIORGI'],
  ['G605', 'SV', 'PIETRA LIGURE'],
  ['G619', 'AL', 'PIETRA MARAZZI'],
  ['G606', 'IS', 'PIETRABBONDANTE'],
  ['G607', 'IM', 'PIETRABRUNA'],
  ['G608', 'TE', 'PIETRACAMELA'],
  ['G609', 'CB', 'PIETRACATELLA'],
  ['G610', 'CB', 'PIETRACUPA'],
  ['G611', 'AV', 'PIETRADEFUSI'],
  ['G613', 'CH', 'PIETRAFERRAZZANA'],
  ['G615', 'CS', 'PIETRAFITTA'],
  ['G616', 'PZ', 'PIETRAGALLA'],
  ['G618', 'PG', 'PIETRALUNGA'],
  ['G620', 'CE', 'PIETRAMELARA'],
  ['G604', 'FG', 'PIETRAMONTECORVINO'],
  ['G621', 'PE', 'PIETRANICO'],
  ['G622', 'CS', 'PIETRAPAOLA'],
  ['G623', 'PZ', 'PIETRAPERTOSA'],
  ['G624', 'EN', 'PIETRAPERZIA'],
  ['G625', 'CN', 'PIETRAPORZIO'],
  ['G626', 'BN', 'PIETRAROJA'],
  ['G627', 'PU', 'PIETRARUBBIA'],
  ['G628', 'LU', 'PIETRASANTA'],
  ['G629', 'AV', 'PIETRASTORNINA'],
  ['G630', 'CE', 'PIETRAVAIRANO'],
  ['G631', 'BN', 'PIETRELCINA'],
  ['G636', 'PT', 'PIEVE A NIEVOLE'],
  ['G635', 'PV', 'PIEVE ALBIGNOLA'],
  ['G638', 'BL', 'PIEVE D\'ALPAGO'],
  ['G647', 'CR', 'PIEVE D\'OLMI'],
  ['G639', 'PV', 'PIEVE DEL CAIRO'],
  ['G641', 'TN', 'PIEVE DI BONO'],
  ['G642', 'BL', 'PIEVE DI CADORE'],
  ['G643', 'BO', 'PIEVE DI CENTO'],
  ['G633', 'MN', 'PIEVE DI CORIANO'],
  ['G644', 'TN', 'PIEVE DI LEDRO'],
  ['G645', 'TV', 'PIEVE DI SOLIGO'],
  ['G632', 'IM', 'PIEVE DI TECO'],
  ['G634', 'MI', 'PIEVE EMANUELE'],
  ['G096', 'LO', 'PIEVE FISSIRAGA'],
  ['G648', 'LU', 'PIEVE FOSCIANA'],
  ['G646', 'GE', 'PIEVE LIGURE'],
  ['G650', 'PV', 'PIEVE PORTO MORONE'],
  ['G651', 'CR', 'PIEVE SAN GIACOMO'],
  ['G653', 'AR', 'PIEVE SANTO STEFANO'],
  ['G656', 'TN', 'PIEVE TESINO'],
  ['G657', 'MC', 'PIEVE TORINA'],
  ['G658', 'VB', 'PIEVE VERGONTE'],
  ['G637', 'MC', 'PIEVEBOVIGLIANA'],
  ['G649', 'MO', 'PIEVEPELAGO'],
  ['G659', 'FR', 'PIGLIO'],
  ['G660', 'IM', 'PIGNA'],
  ['G662', 'FR', 'PIGNATARO INTERAMNA'],
  ['G661', 'CE', 'PIGNATARO MAGGIORE'],
  ['G663', 'PZ', 'PIGNOLA'],
  ['G664', 'SP', 'PIGNONE'],
  ['G665', 'CO', 'PIGRA'],
  ['G666', 'VC', 'PILA'],
  ['G669', 'CA', 'PIMENTEL'],
  ['G670', 'NA', 'PIMONTE'],
  ['G671', 'PV', 'PINAROLO PO'],
  ['G672', 'TO', 'PINASCA'],
  ['G673', 'RO', 'PINCARA'],
  ['G674', 'TO', 'PINEROLO'],
  ['F831', 'TE', 'PINETO'],
  ['G676', 'AT', 'PINO D\'ASTI'],
  ['G677', 'VA', 'PINO SULLA SPONDA DEL LAGO MAGGIORE'],
  ['G678', 'TO', 'PINO TORINESE'],
  ['G680', 'PN', 'PINZANO AL TAGLIAMENTO'],
  ['G681', 'TN', 'PINZOLO'],
  ['G682', 'PU', 'PIOBBICO'],
  ['G683', 'CN', 'PIOBESI D\'ALBA'],
  ['G684', 'TO', 'PIOBESI TORINESE'],
  ['G685', 'VC', 'PIODE'],
  ['G686', 'MI', 'PIOLTELLO'],
  ['G687', 'LI', 'PIOMBINO'],
  ['G688', 'PD', 'PIOMBINO DESE'],
  ['G690', 'MC', 'PIORACO'],
  ['G691', 'TO', 'PIOSSASCO'],
  ['G692', 'AT', 'PIOVA\' MASSAIA'],
  ['G693', 'PD', 'PIOVE DI SACCO'],
  ['G694', 'VI', 'PIOVENE ROCCHETTE'],
  ['G695', 'AL', 'PIOVERA'],
  ['G696', 'PC', 'PIOZZANO'],
  ['G697', 'CN', 'PIOZZO'],
  ['G699', 'ME', 'PIRAINO'],
  ['G702', 'PI', 'PISA'],
  ['G703', 'NO', 'PISANO'],
  ['G705', 'TO', 'PISCINA'],
  ['M291', 'CA', 'PISCINAS'],
  ['G707', 'SA', 'PISCIOTTA'],
  ['G710', 'BS', 'PISOGNE'],
  ['G704', 'RM', 'PISONIANO'],
  ['G712', 'MT', 'PISTICCI'],
  ['G713', 'PT', 'PISTOIA'],
  ['Z722', 'EE', 'PITCAIRN (E DIPENDENZE)'],
  ['G715', 'PT', 'PITEGLIO'],
  ['G716', 'GR', 'PITIGLIANO'],
  ['G717', 'MN', 'PIUBEGA'],
  ['G718', 'SO', 'PIURO'],
  ['G719', 'TO', 'PIVERONE'],
  ['G720', 'PV', 'PIZZALE'],
  ['G721', 'CR', 'PIZZIGHETTONE'],
  ['G722', 'VV', 'PIZZO'],
  ['G724', 'CH', 'PIZZOFERRATO'],
  ['G726', 'AQ', 'PIZZOLI'],
  ['G727', 'IS', 'PIZZONE'],
  ['G728', 'VV', 'PIZZONI'],
  ['G729', 'RC', 'PLACANICA'],
  ['G733', 'CS', 'PLATACI'],
  ['G734', 'CZ', 'PLATANIA'],
  ['G735', 'RC', 'PLATI\''],
  ['G299', 'BZ', 'PLAUS'],
  ['G737', 'CO', 'PLESIO'],
  ['G740', 'SS', 'PLOAGHE'],
  ['G741', 'SV', 'PLODIO'],
  ['G742', 'CN', 'POCAPAGLIA'],
  ['G743', 'UD', 'POCENIA'],
  ['G746', 'MS', 'PODENZANA'],
  ['G747', 'PC', 'PODENZANO'],
  ['G749', 'FR', 'POFI'],
  ['G751', 'LE', 'POGGIARDO'],
  ['G752', 'SI', 'POGGIBONSI'],
  ['G754', 'PO', 'POGGIO A CAIANO'],
  ['G755', 'RN', 'POGGIO BERNI'],
  ['G756', 'RI', 'POGGIO BUSTONE'],
  ['G757', 'RI', 'POGGIO CATINO'],
  ['G761', 'FG', 'POGGIO IMPERIALE'],
  ['G763', 'RI', 'POGGIO MIRTETO'],
  ['G764', 'RI', 'POGGIO MOIANO'],
  ['G765', 'RI', 'POGGIO NATIVO'],
  ['G766', 'AQ', 'POGGIO PICENZE'],
  ['G768', 'FE', 'POGGIO RENATICO'],
  ['G753', 'MN', 'POGGIO RUSCO'],
  ['G770', 'RI', 'POGGIO SAN LORENZO'],
  ['G771', 'AN', 'POGGIO SAN MARCELLO'],
  ['D566', 'MC', 'POGGIO SAN VICINO'],
  ['B317', 'IS', 'POGGIO SANNITA'],
  ['G758', 'PG', 'POGGIODOMO'],
  ['G760', 'CH', 'POGGIOFIORITO'],
  ['G762', 'NA', 'POGGIOMARINO'],
  ['G767', 'TP', 'POGGIOREALE'],
  ['G769', 'BA', 'POGGIORSINI'],
  ['G431', 'SO', 'POGGIRIDENTI'],
  ['G772', 'MI', 'POGLIANO MILANESE'],
  ['G773', 'CO', 'POGNANA LARIO'],
  ['G774', 'BG', 'POGNANO'],
  ['G775', 'NO', 'POGNO'],
  ['G776', 'VI', 'POIANA MAGGIORE'],
  ['G777', 'TO', 'POIRINO'],
  ['G779', 'BS', 'POLAVENO'],
  ['G780', 'PN', 'POLCENIGO'],
  ['G782', 'RO', 'POLESELLA'],
  ['G783', 'PR', 'POLESINE PARMENSE'],
  ['G784', 'RM', 'POLI'],
  ['G785', 'VV', 'POLIA'],
  ['G786', 'MT', 'POLICORO'],
  ['G787', 'BA', 'POLIGNANO A MARE'],
  ['G789', 'MO', 'POLINAGO'],
  ['Z723', 'EE', 'POLINESIA FRANCESE (ISOLE)'],
  ['G790', 'TR', 'POLINO'],
  ['G791', 'RC', 'POLISTENA'],
  ['G792', 'PA', 'POLIZZI GENEROSA'],
  ['G793', 'SA', 'POLLA'],
  ['G794', 'AO', 'POLLEIN'],
  ['G795', 'NA', 'POLLENA TROCCHIA'],
  ['F567', 'MC', 'POLLENZA'],
  ['G796', 'SA', 'POLLICA'],
  ['G797', 'PA', 'POLLINA'],
  ['G798', 'BI', 'POLLONE'],
  ['G799', 'CH', 'POLLUTRI'],
  ['G800', 'CN', 'POLONGHERA'],
  ['Z127', 'EE', 'POLONIA'],
  ['G801', 'BS', 'POLPENAZZE DEL GARDA'],
  ['G802', 'PD', 'POLVERARA'],
  ['G803', 'AN', 'POLVERIGI'],
  ['G804', 'PI', 'POMARANCE'],
  ['G805', 'TO', 'POMARETTO'],
  ['G806', 'MT', 'POMARICO'],
  ['G807', 'AL', 'POMARO MONFERRATO'],
  ['G808', 'TN', 'POMAROLO'],
  ['G809', 'NO', 'POMBIA'],
  ['G811', 'RM', 'POMEZIA'],
  ['G812', 'NA', 'POMIGLIANO D\'ARCO'],
  ['G813', 'NA', 'POMPEI'],
  ['G814', 'IM', 'POMPEIANA'],
  ['G815', 'BS', 'POMPIANO'],
  ['G816', 'MN', 'POMPONESCO'],
  ['G817', 'OR', 'POMPU'],
  ['G818', 'BS', 'PONCARALE'],
  ['G820', 'BI', 'PONDERANO'],
  ['G821', 'CO', 'PONNA'],
  ['G822', 'PI', 'PONSACCO'],
  ['G823', 'PD', 'PONSO'],
  ['G826', 'TO', 'PONT CANAVESE'],
  ['G854', 'AO', 'PONT-SAINT-MARTIN'],
  ['G825', 'FI', 'PONTASSIEVE'],
  ['G545', 'AO', 'PONTBOSET'],
  ['G827', 'BN', 'PONTE'],
  ['G833', 'PT', 'PONTE BUGGIANESE'],
  ['G842', 'PC', 'PONTE DELL\'OLIO'],
  ['G844', 'BS', 'PONTE DI LEGNO'],
  ['G846', 'TV', 'PONTE DI PIAVE'],
  ['G830', 'BZ', 'PONTE GARDENA'],
  ['G829', 'SO', 'PONTE IN VALTELLINA'],
  ['G847', 'CO', 'PONTE LAMBRO'],
  ['B662', 'BL', 'PONTE NELLE ALPI'],
  ['G851', 'PV', 'PONTE NIZZA'],
  ['F941', 'BG', 'PONTE NOSSA'],
  ['G855', 'PD', 'PONTE SAN NICOLO\''],
  ['G856', 'BG', 'PONTE SAN PIETRO'],
  ['G831', 'UD', 'PONTEBBA'],
  ['G834', 'SA', 'PONTECAGNANO FAIANO'],
  ['G836', 'RO', 'PONTECCHIO POLESINE'],
  ['G837', 'CN', 'PONTECHIANALE'],
  ['G838', 'FR', 'PONTECORVO'],
  ['G839', 'AL', 'PONTECURONE'],
  ['G840', 'IM', 'PONTEDASSIO'],
  ['G843', 'PI', 'PONTEDERA'],
  ['G848', 'BN', 'PONTELANDOLFO'],
  ['G849', 'CE', 'PONTELATONE'],
  ['G850', 'PD', 'PONTELONGO'],
  ['G852', 'PC', 'PONTENURE'],
  ['G853', 'BG', 'PONTERANICA'],
  ['G858', 'AL', 'PONTESTURA'],
  ['G859', 'BS', 'PONTEVICO'],
  ['G860', 'AO', 'PONTEY'],
  ['G861', 'AL', 'PONTI'],
  ['G862', 'MN', 'PONTI SUL MINCIO'],
  ['G864', 'BG', 'PONTIDA'],
  ['G865', 'LT', 'PONTINIA'],
  ['G866', 'SV', 'PONTINVREA'],
  ['G867', 'BG', 'PONTIROLO NUOVO'],
  ['G869', 'BS', 'PONTOGLIO'],
  ['G870', 'MS', 'PONTREMOLI'],
  ['G871', 'LT', 'PONZA'],
  ['G873', 'FM', 'PONZANO DI FERMO'],
  ['G873', 'AP', 'PONZANO DI FERMO'],
  ['G872', 'AL', 'PONZANO MONFERRATO'],
  ['G874', 'RM', 'PONZANO ROMANO'],
  ['G875', 'TV', 'PONZANO VENETO'],
  ['G877', 'AL', 'PONZONE'],
  ['G878', 'PE', 'POPOLI'],
  ['G879', 'AR', 'POPPI'],
  ['G881', 'TR', 'PORANO'],
  ['G882', 'LU', 'PORCARI'],
  ['G886', 'PN', 'PORCIA'],
  ['G888', 'PN', 'PORDENONE'],
  ['G889', 'CO', 'PORLEZZA'],
  ['G890', 'IM', 'PORNASSIO'],
  ['G891', 'UD', 'PORPETTO'],
  ['A558', 'BO', 'PORRETTA TERME'],
  ['G894', 'AT', 'PORTACOMARO'],
  ['G895', 'PV', 'PORTALBERA'],
  ['G900', 'TO', 'PORTE'],
  ['G902', 'NA', 'PORTICI'],
  ['G903', 'CE', 'PORTICO DI CASERTA'],
  ['G904', 'FC', 'PORTICO E SAN BENEDETTO'],
  ['G905', 'RC', 'PORTIGLIOLA'],
  ['E680', 'LI', 'PORTO AZZURRO'],
  ['G906', 'VA', 'PORTO CERESIO'],
  ['M263', 'LE', 'PORTO CESAREO'],
  ['F299', 'AG', 'PORTO EMPEDOCLE'],
  ['G917', 'MN', 'PORTO MANTOVANO'],
  ['G919', 'MC', 'PORTO RECANATI'],
  ['G920', 'FM', 'PORTO SAN GIORGIO'],
  ['G920', 'AP', 'PORTO SAN GIORGIO'],
  ['G921', 'FM', 'PORTO SANT\'ELPIDIO'],
  ['G921', 'AP', 'PORTO SANT\'ELPIDIO'],
  ['G923', 'RO', 'PORTO TOLLE'],
  ['G924', 'SS', 'PORTO TORRES'],
  ['G907', 'VA', 'PORTO VALTRAVAGLIA'],
  ['G926', 'RO', 'PORTO VIRO'],
  ['G909', 'TV', 'PORTOBUFFOLE\''],
  ['G910', 'CB', 'PORTOCANNONE'],
  ['G912', 'LI', 'PORTOFERRAIO'],
  ['G913', 'GE', 'PORTOFINO'],
  ['Z128', 'EE', 'PORTOGALLO'],
  ['G914', 'VE', 'PORTOGRUARO'],
  ['G916', 'FE', 'PORTOMAGGIORE'],
  ['M257', 'SR', 'PORTOPALO DI CAPO PASSERO'],
  ['G922', 'CA', 'PORTOSCUSO'],
  ['G925', 'SP', 'PORTOVENERE'],
  ['G927', 'BI', 'PORTULA'],
  ['G929', 'NU', 'POSADA'],
  ['G931', 'VI', 'POSINA'],
  ['G932', 'SA', 'POSITANO'],
  ['G933', 'TV', 'POSSAGNO'],
  ['G934', 'RI', 'POSTA'],
  ['G935', 'FR', 'POSTA FIBRENO'],
  ['G936', 'BZ', 'POSTAL'],
  ['G937', 'SO', 'POSTALESIO'],
  ['G939', 'SA', 'POSTIGLIONE'],
  ['G940', 'VC', 'POSTUA'],
  ['G942', 'PZ', 'POTENZA'],
  ['F632', 'MC', 'POTENZA PICENA'],
  ['G943', 'VI', 'POVE DEL GRAPPA'],
  ['G944', 'TV', 'POVEGLIANO'],
  ['G945', 'VR', 'POVEGLIANO VERONESE'],
  ['G947', 'RE', 'POVIGLIO'],
  ['G949', 'UD', 'POVOLETTO'],
  ['G950', 'TN', 'POZZA DI FASSA'],
  ['G951', 'RI', 'POZZAGLIA SABINA'],
  ['B914', 'CR', 'POZZAGLIO ED UNITI'],
  ['G953', 'RG', 'POZZALLO'],
  ['G954', 'IS', 'POZZILLI'],
  ['G955', 'MI', 'POZZO D\'ADDA'],
  ['G960', 'AL', 'POZZOL GROPPO'],
  ['G959', 'BS', 'POZZOLENGO'],
  ['G957', 'VI', 'POZZOLEONE'],
  ['G961', 'AL', 'POZZOLO FORMIGARO'],
  ['G962', 'SS', 'POZZOMAGGIORE'],
  ['G963', 'PD', 'POZZONOVO'],
  ['G964', 'NA', 'POZZUOLI'],
  ['G966', 'UD', 'POZZUOLO DEL FRIULI'],
  ['G965', 'MI', 'POZZUOLO MARTESANA'],
  ['G968', 'BG', 'PRADALUNGA'],
  ['G969', 'UD', 'PRADAMANO'],
  ['G970', 'CN', 'PRADLEVES'],
  ['G973', 'TO', 'PRAGELATO'],
  ['G975', 'CS', 'PRAIA A MARE'],
  ['G976', 'SA', 'PRAIANO'],
  ['G977', 'BS', 'PRALBOINO'],
  ['G978', 'TO', 'PRALI'],
  ['G979', 'TO', 'PRALORMO'],
  ['G980', 'BI', 'PRALUNGO'],
  ['G981', 'VE', 'PRAMAGGIORE'],
  ['G982', 'TO', 'PRAMOLLO'],
  ['G985', 'VC', 'PRAROLO'],
  ['G986', 'TO', 'PRAROSTINO'],
  ['G987', 'AL', 'PRASCO'],
  ['G988', 'TO', 'PRASCORSANO'],
  ['G989', 'TN', 'PRASO'],
  ['G993', 'SO', 'PRATA CAMPORTACCIO'],
  ['G992', 'AQ', 'PRATA D\'ANSIDONIA'],
  ['G994', 'PN', 'PRATA DI PORDENONE'],
  ['G990', 'AV', 'PRATA DI PRINCIPATO ULTRA'],
  ['G991', 'CE', 'PRATA SANNITA'],
  ['G995', 'CE', 'PRATELLA'],
  ['G997', 'TO', 'PRATIGLIONE'],
  ['G999', 'PO', 'PRATO'],
  ['H004', 'BZ', 'PRATO ALLO STELVIO'],
  ['H002', 'UD', 'PRATO CARNICO'],
  ['H001', 'NO', 'PRATO SESIA'],
  ['H007', 'AQ', 'PRATOLA PELIGNA'],
  ['H006', 'AV', 'PRATOLA SERRA'],
  ['H008', 'AR', 'PRATOVECCHIO'],
  ['H010', 'PN', 'PRAVISDOMINI'],
  ['G974', 'BI', 'PRAY'],
  ['H011', 'CN', 'PRAZZO'],
  ['H042', 'AO', 'PRE\'-SAINT-DIDIER'],
  ['H014', 'UD', 'PRECENICCO'],
  ['H015', 'PG', 'PRECI'],
  ['H017', 'FC', 'PREDAPPIO'],
  ['H018', 'TN', 'PREDAZZO'],
  ['H019', 'BZ', 'PREDOI'],
  ['H020', 'BG', 'PREDORE'],
  ['H021', 'AL', 'PREDOSA'],
  ['H022', 'TV', 'PREGANZIOL'],
  ['H026', 'MI', 'PREGNANA MILANESE'],
  ['H027', 'IM', 'PRELA\''],
  ['H028', 'LC', 'PREMANA'],
  ['H029', 'UD', 'PREMARIACCO'],
  ['H030', 'VB', 'PREMENO'],
  ['H033', 'VB', 'PREMIA'],
  ['H034', 'FC', 'PREMILCUORE'],
  ['H036', 'BG', 'PREMOLO'],
  ['H037', 'VB', 'PREMOSELLO-CHIOVENDA'],
  ['H038', 'UD', 'PREONE'],
  ['H039', 'TN', 'PREORE'],
  ['H040', 'UD', 'PREPOTTO'],
  ['H043', 'BS', 'PRESEGLIE'],
  ['H045', 'CE', 'PRESENZANO'],
  ['H046', 'BG', 'PRESEZZO'],
  ['H047', 'LE', 'PRESICCE'],
  ['H048', 'VR', 'PRESSANA'],
  ['H050', 'BS', 'PRESTINE'],
  ['H052', 'CH', 'PRETORO'],
  ['H055', 'BS', 'PREVALLE'],
  ['H056', 'AQ', 'PREZZA'],
  ['H057', 'TN', 'PREZZO'],
  ['H059', 'CN', 'PRIERO'],
  ['H062', 'SA', 'PRIGNANO CILENTO'],
  ['H061', 'MO', 'PRIGNANO SULLA SECCHIA'],
  ['H063', 'LC', 'PRIMALUNA'],
  ['H068', 'CN', 'PRIOCCA'],
  ['H069', 'CN', 'PRIOLA'],
  ['M279', 'SR', 'PRIOLO GARGALLO'],
  ['G698', 'LT', 'PRIVERNO'],
  ['H070', 'PA', 'PRIZZI'],
  ['H071', 'VT', 'PROCENO'],
  ['H072', 'NA', 'PROCIDA'],
  ['H073', 'GE', 'PROPATA'],
  ['H074', 'CO', 'PROSERPIO'],
  ['H076', 'LT', 'PROSSEDI'],
  ['H078', 'BS', 'PROVAGLIO D\'ISEO'],
  ['H077', 'BS', 'PROVAGLIO VAL SABBIA'],
  ['H081', 'BZ', 'PROVES'],
  ['H083', 'CB', 'PROVVIDENTI'],
  ['H085', 'CN', 'PRUNETTO'],
  ['H086', 'BS', 'PUEGNAGO SUL GARDA'],
  ['Z518', 'EE', 'PUERTO RICO'],
  ['H087', 'BN', 'PUGLIANELLO'],
  ['H088', 'CA', 'PULA'],
  ['H089', 'UD', 'PULFERO'],
  ['H090', 'TA', 'PULSANO'],
  ['H091', 'BG', 'PUMENENGO'],
  ['H092', 'BL', 'PUOS D\'ALPAGO'],
  ['H094', 'CO', 'PUSIANO'],
  ['H095', 'SS', 'PUTIFIGARI'],
  ['H096', 'BA', 'PUTIGNANO'],
  ['Z237', 'EE', 'QATAR'],
  ['H097', 'AV', 'QUADRELLE'],
  ['H098', 'CH', 'QUADRI'],
  ['H100', 'TO', 'QUAGLIUZZO'],
  ['H101', 'NA', 'QUALIANO'],
  ['H102', 'AT', 'QUARANTI'],
  ['H103', 'BI', 'QUAREGNA'],
  ['H104', 'AL', 'QUARGNENTO'],
  ['H106', 'VB', 'QUARNA SOPRA'],
  ['H107', 'VB', 'QUARNA SOTTO'],
  ['H108', 'VC', 'QUARONA'],
  ['H109', 'PT', 'QUARRATA'],
  ['H110', 'AO', 'QUART'],
  ['H114', 'NA', 'QUARTO'],
  ['H117', 'VE', 'QUARTO D\'ALTINO'],
  ['H118', 'CA', 'QUARTU SANT\'ELENA'],
  ['H119', 'CA', 'QUARTUCCIU'],
  ['H120', 'TO', 'QUASSOLO'],
  ['H121', 'AL', 'QUATTORDIO'],
  ['H122', 'RE', 'QUATTRO CASTELLA'],
  ['H124', 'BL', 'QUERO'],
  ['H126', 'SV', 'QUILIANO'],
  ['H127', 'TO', 'QUINCINETTO'],
  ['H128', 'AV', 'QUINDICI'],
  ['H129', 'MN', 'QUINGENTOLE'],
  ['H130', 'CR', 'QUINTANO'],
  ['H131', 'TV', 'QUINTO DI TREVISO'],
  ['H132', 'VC', 'QUINTO VERCELLESE'],
  ['H134', 'VI', 'QUINTO VICENTINO'],
  ['H140', 'BS', 'QUINZANO D\'OGLIO'],
  ['H143', 'MN', 'QUISTELLO'],
  ['H145', 'BI', 'QUITTENGO'],
  ['H146', 'TN', 'RABBI'],
  ['H147', 'LE', 'RACALE'],
  ['H148', 'AG', 'RACALMUTO'],
  ['H150', 'CN', 'RACCONIGI'],
  ['H151', 'ME', 'RACCUJA'],
  ['H152', 'BZ', 'RACINES'],
  ['H153', 'SI', 'RADDA IN CHIANTI'],
  ['H154', 'CT', 'RADDUSA'],
  ['H156', 'SI', 'RADICOFANI'],
  ['H157', 'SI', 'RADICONDOLI'],
  ['H159', 'AG', 'RAFFADALI'],
  ['M287', 'CT', 'RAGALNA'],
  ['H161', 'UD', 'RAGOGNA'],
  ['H162', 'TN', 'RAGOLI'],
  ['H163', 'RG', 'RAGUSA'],
  ['H166', 'AQ', 'RAIANO'],
  ['H168', 'CT', 'RAMACCA'],
  ['G654', 'RE', 'RAMISETO'],
  ['H171', 'CO', 'RAMPONIO VERNA'],
  ['H173', 'VA', 'RANCIO VALCUVIA'],
  ['H174', 'VA', 'RANCO'],
  ['H175', 'CT', 'RANDAZZO'],
  ['H176', 'BG', 'RANICA'],
  ['H177', 'BG', 'RANZANICO'],
  ['H180', 'IM', 'RANZO'],
  ['H182', 'FM', 'RAPAGNANO'],
  ['H182', 'AP', 'RAPAGNANO'],
  ['H183', 'GE', 'RAPALLO'],
  ['H184', 'CH', 'RAPINO'],
  ['H185', 'SI', 'RAPOLANO TERME'],
  ['H186', 'PZ', 'RAPOLLA'],
  ['H187', 'PZ', 'RAPONE'],
  ['H188', 'VC', 'RASSA'],
  ['H189', 'BZ', 'RASUN ANTERSELVA'],
  ['H192', 'SO', 'RASURA'],
  ['H194', 'AG', 'RAVANUSA'],
  ['H195', 'MO', 'RAVARINO'],
  ['H196', 'UD', 'RAVASCLETTO'],
  ['H198', 'SA', 'RAVELLO'],
  ['H199', 'RA', 'RAVENNA'],
  ['H200', 'UD', 'RAVEO'],
  ['H202', 'CE', 'RAVISCANINA'],
  ['H203', 'VB', 'RE'],
  ['H204', 'PV', 'REA'],
  ['H205', 'AG', 'REALMONTE'],
  ['H206', 'UD', 'REANA DEL ROIALE'],
  ['H207', 'TO', 'REANO'],
  ['H210', 'CE', 'RECALE'],
  ['H211', 'MC', 'RECANATI'],
  ['H212', 'GE', 'RECCO'],
  ['H213', 'NO', 'RECETTO'],
  ['H214', 'VI', 'RECOARO TERME'],
  ['H216', 'PV', 'REDAVALLE'],
  ['H218', 'MN', 'REDONDESCO'],
  ['H219', 'AT', 'REFRANCORE'],
  ['H220', 'TV', 'REFRONTOLO'],
  ['H221', 'EN', 'REGALBUTO'],
  ['H222', 'FI', 'REGGELLO'],
  ['H224', 'RC', 'REGGIO DI CALABRIA'],
  ['H223', 'RE', 'REGGIO NELL\'EMILIA'],
  ['H225', 'RE', 'REGGIOLO'],
  ['Z114', 'EE', 'REGNO UNITO'],
  ['H227', 'BN', 'REINO'],
  ['H228', 'ME', 'REITANO'],
  ['H229', 'UD', 'REMANZACCO'],
  ['H230', 'BS', 'REMEDELLO'],
  ['H233', 'MB', 'RENATE'],
  ['H233', 'MI', 'RENATE'],
  ['H235', 'CS', 'RENDE'],
  ['H236', 'BZ', 'RENON'],
  ['Z156', 'EE', 'REPUBBLICA CECA'],
  ['Z213', 'EE', 'REPUBBLICA DI COREA'],
  ['Z505', 'EE', 'REPUBBLICA DOMINICANA'],
  ['H238', 'TV', 'RESANA'],
  ['H240', 'MI', 'RESCALDINA'],
  ['H242', 'UD', 'RESIA'],
  ['H244', 'UD', 'RESIUTTA'],
  ['H245', 'CL', 'RESUTTANO'],
  ['H246', 'PV', 'RETORBIDO'],
  ['H247', 'CN', 'REVELLO'],
  ['H248', 'MN', 'REVERE'],
  ['H250', 'AT', 'REVIGLIASCO D\'ASTI'],
  ['H253', 'TV', 'REVINE LAGO'],
  ['H254', 'TN', 'REVO\''],
  ['H255', 'CO', 'REZZAGO'],
  ['H256', 'BS', 'REZZATO'],
  ['H257', 'IM', 'REZZO'],
  ['H258', 'GE', 'REZZOAGLIO'],
  ['H262', 'AO', 'RHEMES-NOTRE-DAME'],
  ['H263', 'AO', 'RHEMES-SAINT-GEORGES'],
  ['H264', 'MI', 'RHO'],
  ['H265', 'RC', 'RIACE'],
  ['H266', 'SV', 'RIALTO'],
  ['H267', 'RM', 'RIANO'],
  ['H268', 'CE', 'RIARDO'],
  ['H269', 'AG', 'RIBERA'],
  ['H270', 'TO', 'RIBORDONE'],
  ['H271', 'VV', 'RICADI'],
  ['H272', 'AL', 'RICALDONE'],
  ['H273', 'CB', 'RICCIA'],
  ['H274', 'RN', 'RICCIONE'],
  ['H275', 'SP', 'RICCO\' DEL GOLFO DI SPEZIA'],
  ['H276', 'CR', 'RICENGO'],
  ['H277', 'SA', 'RICIGLIANO'],
  ['H280', 'TV', 'RIESE PIO X'],
  ['H281', 'CL', 'RIESI'],
  ['H282', 'RI', 'RIETI'],
  ['H284', 'BZ', 'RIFIANO'],
  ['H285', 'CN', 'RIFREDDO'],
  ['H288', 'RM', 'RIGNANO FLAMINIO'],
  ['H287', 'FG', 'RIGNANO GARGANICO'],
  ['H286', 'FI', 'RIGNANO SULL\'ARNO'],
  ['H289', 'UD', 'RIGOLATO'],
  ['H291', 'VC', 'RIMA SAN GIUSEPPE'],
  ['H292', 'VC', 'RIMASCO'],
  ['H293', 'VC', 'RIMELLA'],
  ['H294', 'RN', 'RIMINI'],
  ['H299', 'BZ', 'RIO DI PUSTERIA'],
  ['H305', 'LI', 'RIO MARINA'],
  ['H297', 'LI', 'RIO NELL\'ELBA'],
  ['H298', 'RE', 'RIO SALICETO'],
  ['H300', 'RM', 'RIOFREDDO'],
  ['H301', 'OR', 'RIOLA SARDO'],
  ['H302', 'RA', 'RIOLO TERME'],
  ['H303', 'MO', 'RIOLUNATO'],
  ['H304', 'SP', 'RIOMAGGIORE'],
  ['H307', 'PZ', 'RIONERO IN VULTURE'],
  ['H308', 'IS', 'RIONERO SANNITICO'],
  ['H320', 'CH', 'RIPA TEATINA'],
  ['H311', 'CB', 'RIPABOTTONI'],
  ['H312', 'PZ', 'RIPACANDIDA'],
  ['H313', 'CB', 'RIPALIMOSANI'],
  ['H314', 'CR', 'RIPALTA ARPINA'],
  ['H315', 'CR', 'RIPALTA CREMASCA'],
  ['H316', 'CR', 'RIPALTA GUERINA'],
  ['H319', 'PI', 'RIPARBELLA'],
  ['H321', 'AP', 'RIPATRANSONE'],
  ['H322', 'AN', 'RIPE'],
  ['H323', 'MC', 'RIPE SAN GINESIO'],
  ['H324', 'FR', 'RIPI'],
  ['H325', 'CT', 'RIPOSTO'],
  ['H326', 'CN', 'RITTANA'],
  ['H330', 'TN', 'RIVA DEL GARDA'],
  ['H331', 'BG', 'RIVA DI SOLTO'],
  ['H328', 'IM', 'RIVA LIGURE'],
  ['H337', 'TO', 'RIVA PRESSO CHIERI'],
  ['H329', 'VC', 'RIVA VALDOBBIA'],
  ['H333', 'TO', 'RIVALBA'],
  ['H334', 'AL', 'RIVALTA BORMIDA'],
  ['H335', 'TO', 'RIVALTA DI TORINO'],
  ['H327', 'BL', 'RIVAMONTE AGORDINO'],
  ['H336', 'PV', 'RIVANAZZANO'],
  ['H338', 'TO', 'RIVARA'],
  ['H340', 'TO', 'RIVAROLO CANAVESE'],
  ['H341', 'CR', 'RIVAROLO DEL RE ED UNITI'],
  ['H342', 'MN', 'RIVAROLO MANTOVANO'],
  ['H343', 'AL', 'RIVARONE'],
  ['H344', 'TO', 'RIVAROSSA'],
  ['H346', 'VC', 'RIVE'],
  ['H347', 'UD', 'RIVE D\'ARCANO'],
  ['H348', 'PZ', 'RIVELLO'],
  ['H350', 'PC', 'RIVERGARO'],
  ['H352', 'UD', 'RIVIGNANO'],
  ['H353', 'AQ', 'RIVISONDOLI'],
  ['H354', 'RI', 'RIVODUTRI'],
  ['H355', 'TO', 'RIVOLI'],
  ['H356', 'VR', 'RIVOLI VERONESE'],
  ['H357', 'CR', 'RIVOLTA D\'ADDA'],
  ['H359', 'RC', 'RIZZICONI'],
  ['H360', 'FE', 'RO'],
  ['H361', 'VI', 'ROANA'],
  ['H362', 'CN', 'ROASCHIA'],
  ['H363', 'CN', 'ROASCIO'],
  ['H365', 'VC', 'ROASIO'],
  ['H366', 'AT', 'ROATTO'],
  ['H367', 'TO', 'ROBASSOMERO'],
  ['G223', 'LC', 'ROBBIATE'],
  ['H369', 'PV', 'ROBBIO'],
  ['H371', 'MI', 'ROBECCHETTO CON INDUNO'],
  ['H372', 'CR', 'ROBECCO D\'OGLIO'],
  ['H375', 'PV', 'ROBECCO PAVESE'],
  ['H373', 'MI', 'ROBECCO SUL NAVIGLIO'],
  ['H376', 'AT', 'ROBELLA'],
  ['H377', 'CN', 'ROBILANTE'],
  ['H378', 'CN', 'ROBURENT'],
  ['H386', 'TO', 'ROCCA CANAVESE'],
  ['H387', 'RM', 'ROCCA CANTERANO'],
  ['H391', 'CN', 'ROCCA CIGLIE\''],
  ['H392', 'AT', 'ROCCA D\'ARAZZO'],
  ['H398', 'CE', 'ROCCA D\'EVANDRO'],
  ['H395', 'CN', 'ROCCA DE\' BALDI'],
  ['H396', 'PV', 'ROCCA DE\' GIORGI'],
  ['H399', 'AQ', 'ROCCA DI BOTTE'],
  ['H400', 'AQ', 'ROCCA DI CAMBIO'],
  ['H401', 'RM', 'ROCCA DI CAVE'],
  ['H402', 'AQ', 'ROCCA DI MEZZO'],
  ['H403', 'KR', 'ROCCA DI NETO'],
  ['H404', 'RM', 'ROCCA DI PAPA'],
  ['H414', 'AL', 'ROCCA GRIMALDA'],
  ['H416', 'CS', 'ROCCA IMPERIALE'],
  ['H421', 'LT', 'ROCCA MASSIMA'],
  ['H429', 'AQ', 'ROCCA PIA'],
  ['H379', 'BL', 'ROCCA PIETORE'],
  ['H432', 'RM', 'ROCCA PRIORA'],
  ['H437', 'FC', 'ROCCA SAN CASCIANO'],
  ['H438', 'AV', 'ROCCA SAN FELICE'],
  ['H439', 'CH', 'ROCCA SAN GIOVANNI'],
  ['H440', 'TE', 'ROCCA SANTA MARIA'],
  ['H441', 'RM', 'ROCCA SANTO STEFANO'],
  ['H446', 'RI', 'ROCCA SINIBALDA'],
  ['H450', 'PV', 'ROCCA SUSELLA'],
  ['H382', 'AV', 'ROCCABASCERANA'],
  ['H383', 'KR', 'ROCCABERNARDA'],
  ['H384', 'PR', 'ROCCABIANCA'],
  ['H385', 'CN', 'ROCCABRUNA'],
  ['H389', 'AQ', 'ROCCACASALE'],
  ['H393', 'FR', 'ROCCADARCE'],
  ['H394', 'SA', 'ROCCADASPIDE'],
  ['H405', 'ME', 'ROCCAFIORITA'],
  ['H390', 'AP', 'ROCCAFLUVIONE'],
  ['H408', 'RC', 'ROCCAFORTE DEL GRECO'],
  ['H406', 'AL', 'ROCCAFORTE LIGURE'],
  ['H407', 'CN', 'ROCCAFORTE MONDOVI\''],
  ['H409', 'TA', 'ROCCAFORZATA'],
  ['H410', 'BS', 'ROCCAFRANCA'],
  ['H411', 'RM', 'ROCCAGIOVINE'],
  ['H412', 'SA', 'ROCCAGLORIOSA'],
  ['H413', 'LT', 'ROCCAGORGA'],
  ['H417', 'GR', 'ROCCALBEGNA'],
  ['H418', 'ME', 'ROCCALUMERA'],
  ['H420', 'IS', 'ROCCAMANDOLFI'],
  ['H422', 'PA', 'ROCCAMENA'],
  ['H423', 'CE', 'ROCCAMONFINA'],
  ['H424', 'CH', 'ROCCAMONTEPIANO'],
  ['H425', 'PE', 'ROCCAMORICE'],
  ['H426', 'PZ', 'ROCCANOVA'],
  ['H427', 'RI', 'ROCCANTICA'],
  ['H428', 'PA', 'ROCCAPALUMBA'],
  ['H431', 'SA', 'ROCCAPIEMONTE'],
  ['H433', 'NA', 'ROCCARAINOLA'],
  ['H434', 'AQ', 'ROCCARASO'],
  ['H436', 'CE', 'ROCCAROMANA'],
  ['H442', 'CH', 'ROCCASCALEGNA'],
  ['H443', 'FR', 'ROCCASECCA'],
  ['H444', 'LT', 'ROCCASECCA DEI VOLSCI'],
  ['H445', 'IS', 'ROCCASICURA'],
  ['H447', 'CN', 'ROCCASPARVERA'],
  ['H448', 'CH', 'ROCCASPINALVETI'],
  ['H449', 'GR', 'ROCCASTRADA'],
  ['H380', 'ME', 'ROCCAVALDINA'],
  ['H451', 'AT', 'ROCCAVERANO'],
  ['H452', 'SV', 'ROCCAVIGNALE'],
  ['H453', 'CN', 'ROCCAVIONE'],
  ['H454', 'CB', 'ROCCAVIVARA'],
  ['H456', 'RC', 'ROCCELLA IONICA'],
  ['H455', 'ME', 'ROCCELLA VALDEMONE'],
  ['H458', 'IS', 'ROCCHETTA A VOLTURNO'],
  ['H462', 'CN', 'ROCCHETTA BELBO'],
  ['H461', 'SP', 'ROCCHETTA DI VARA'],
  ['H459', 'CE', 'ROCCHETTA E CROCE'],
  ['H465', 'AL', 'ROCCHETTA LIGURE'],
  ['H460', 'IM', 'ROCCHETTA NERVINA'],
  ['H466', 'AT', 'ROCCHETTA PALAFEA'],
  ['H467', 'FG', 'ROCCHETTA SANT\'ANTONIO'],
  ['H468', 'AT', 'ROCCHETTA TANARO'],
  ['H470', 'MI', 'RODANO'],
  ['H472', 'CN', 'RODDI'],
  ['H473', 'CN', 'RODDINO'],
  ['H474', 'CN', 'RODELLO'],
  ['H475', 'BZ', 'RODENGO'],
  ['H477', 'BS', 'RODENGO-SAIANO'],
  ['H478', 'CO', 'RODERO'],
  ['H480', 'FG', 'RODI GARGANICO'],
  ['H479', 'ME', 'RODI\' MILICI'],
  ['H481', 'MN', 'RODIGO'],
  ['H484', 'BS', 'ROE\' VOLCIANO'],
  ['H485', 'SA', 'ROFRANO'],
  ['H486', 'LC', 'ROGENO'],
  ['H488', 'CS', 'ROGGIANO GRAVINA'],
  ['H489', 'RC', 'ROGHUDI'],
  ['H490', 'CS', 'ROGLIANO'],
  ['H491', 'PV', 'ROGNANO'],
  ['H492', 'BG', 'ROGNO'],
  ['H493', 'SO', 'ROGOLO'],
  ['H494', 'RM', 'ROIATE'],
  ['H495', 'CH', 'ROIO DEL SANGRO'],
  ['H497', 'AO', 'ROISAN'],
  ['H498', 'TO', 'ROLETTO'],
  ['H500', 'RE', 'ROLO'],
  ['H501', 'RM', 'ROMA'],
  ['H503', 'SA', 'ROMAGNANO AL MONTE'],
  ['H502', 'NO', 'ROMAGNANO SESIA'],
  ['H505', 'PV', 'ROMAGNESE'],
  ['H506', 'TN', 'ROMALLO'],
  ['H507', 'SS', 'ROMANA'],
  ['H508', 'CR', 'ROMANENGO'],
  ['Z129', 'EE', 'ROMANIA'],
  ['H511', 'TO', 'ROMANO CANAVESE'],
  ['H512', 'VI', 'ROMANO D\'EZZELINO'],
  ['H509', 'BG', 'ROMANO DI LOMBARDIA'],
  ['H514', 'GO', 'ROMANS D\'ISONZO'],
  ['H516', 'VV', 'ROMBIOLO'],
  ['H517', 'TN', 'ROMENO'],
  ['H518', 'NO', 'ROMENTINO'],
  ['H519', 'ME', 'ROMETTA'],
  ['H521', 'CO', 'RONAGO'],
  ['H522', 'VR', 'RONCA\''],
  ['H523', 'TV', 'RONCADE'],
  ['H525', 'BS', 'RONCADELLE'],
  ['H527', 'PV', 'RONCARO'],
  ['H528', 'TN', 'RONCEGNO'],
  ['H529', 'MB', 'RONCELLO'],
  ['H529', 'MI', 'RONCELLO'],
  ['H531', 'GO', 'RONCHI DEI LEGIONARI'],
  ['H532', 'TN', 'RONCHI VALSUGANA'],
  ['H533', 'UD', 'RONCHIS'],
  ['H534', 'VT', 'RONCIGLIONE'],
  ['H540', 'VR', 'RONCO ALL\'ADIGE'],
  ['H538', 'BI', 'RONCO BIELLESE'],
  ['H537', 'MB', 'RONCO BRIANTINO'],
  ['H537', 'MI', 'RONCO BRIANTINO'],
  ['H539', 'TO', 'RONCO CANAVESE'],
  ['H536', 'GE', 'RONCO SCRIVIA'],
  ['H535', 'BG', 'RONCOBELLO'],
  ['H541', 'MN', 'RONCOFERRARO'],
  ['H542', 'FC', 'RONCOFREDDO'],
  ['H544', 'BG', 'RONCOLA'],
  ['H545', 'TN', 'RONCONE'],
  ['H546', 'GE', 'RONDANINA'],
  ['H547', 'TO', 'RONDISSONE'],
  ['H549', 'VC', 'RONSECCO'],
  ['M303', 'TN', 'RONZO-CHIENIS'],
  ['H552', 'TN', 'RONZONE'],
  ['H553', 'BI', 'ROPPOLO'],
  ['H554', 'TO', 'RORA\''],
  ['H556', 'VI', 'ROSA\''],
  ['H558', 'RC', 'ROSARNO'],
  ['H559', 'PV', 'ROSASCO'],
  ['H560', 'MI', 'ROSATE'],
  ['H561', 'BI', 'ROSAZZA'],
  ['H562', 'PE', 'ROSCIANO'],
  ['H564', 'SA', 'ROSCIGNO'],
  ['H565', 'CS', 'ROSE'],
  ['H566', 'CH', 'ROSELLO'],
  ['H572', 'CS', 'ROSETO CAPO SPULICO'],
  ['F585', 'TE', 'ROSETO DEGLI ABRUZZI'],
  ['H568', 'FG', 'ROSETO VALFORTORE'],
  ['H570', 'LI', 'ROSIGNANO MARITTIMO'],
  ['H569', 'AL', 'ROSIGNANO MONFERRATO'],
  ['H573', 'RO', 'ROSOLINA'],
  ['H574', 'SR', 'ROSOLINI'],
  ['H575', 'AN', 'ROSORA'],
  ['H577', 'VC', 'ROSSA'],
  ['H578', 'CN', 'ROSSANA'],
  ['H579', 'CS', 'ROSSANO'],
  ['H580', 'VI', 'ROSSANO VENETO'],
  ['H581', 'GE', 'ROSSIGLIONE'],
  ['H583', 'TO', 'ROSTA'],
  ['H584', 'BG', 'ROTA D\'IMAGNA'],
  ['H585', 'CS', 'ROTA GRECA'],
  ['H588', 'AP', 'ROTELLA'],
  ['H589', 'CB', 'ROTELLO'],
  ['H590', 'PZ', 'ROTONDA'],
  ['H591', 'MT', 'ROTONDELLA'],
  ['H592', 'AV', 'ROTONDI'],
  ['H593', 'PC', 'ROTTOFRENO'],
  ['H594', 'VI', 'ROTZO'],
  ['H555', 'TO', 'ROURE'],
  ['H596', 'LC', 'ROVAGNATE'],
  ['H364', 'VC', 'ROVASENDA'],
  ['H598', 'BS', 'ROVATO'],
  ['H599', 'GE', 'ROVEGNO'],
  ['H601', 'CO', 'ROVELLASCA'],
  ['H602', 'CO', 'ROVELLO PORRO'],
  ['H604', 'MN', 'ROVERBELLA'],
  ['H606', 'VR', 'ROVERCHIARA'],
  ['H607', 'TN', 'ROVERE\' DELLA LUNA'],
  ['H608', 'VR', 'ROVERE\' VERONESE'],
  ['H610', 'VR', 'ROVEREDO DI GUA\''],
  ['H609', 'PN', 'ROVEREDO IN PIANO'],
  ['H612', 'TN', 'ROVERETO'],
  ['H614', 'PV', 'ROVESCALA'],
  ['H615', 'BG', 'ROVETTA'],
  ['H618', 'RM', 'ROVIANO'],
  ['H620', 'RO', 'ROVIGO'],
  ['H621', 'CS', 'ROVITO'],
  ['H622', 'PD', 'ROVOLON'],
  ['H623', 'MI', 'ROZZANO'],
  ['Z338', 'EE', 'RUANDA'],
  ['H625', 'PD', 'RUBANO'],
  ['H627', 'TO', 'RUBIANA'],
  ['H628', 'RE', 'RUBIERA'],
  ['H629', 'UD', 'RUDA'],
  ['H630', 'BS', 'RUDIANO'],
  ['H631', 'TO', 'RUEGLIO'],
  ['H632', 'LE', 'RUFFANO'],
  ['H633', 'CN', 'RUFFIA'],
  ['H634', 'TN', 'RUFFRE\''],
  ['H635', 'FI', 'RUFINA'],
  ['F271', 'OR', 'RUINAS'],
  ['H637', 'PV', 'RUINO'],
  ['H639', 'TN', 'RUMO'],
  ['H641', 'PZ', 'RUOTI'],
  ['H642', 'RA', 'RUSSI'],
  ['H643', 'BA', 'RUTIGLIANO'],
  ['H644', 'SA', 'RUTINO'],
  ['H165', 'CE', 'RUVIANO'],
  ['H646', 'PZ', 'RUVO DEL MONTE'],
  ['H645', 'BA', 'RUVO DI PUGLIA'],
  ['H647', 'LT', 'SABAUDIA'],
  ['H648', 'VC', 'SABBIA'],
  ['H650', 'BS', 'SABBIO CHIESE'],
  ['H652', 'MN', 'SABBIONETA'],
  ['H654', 'SA', 'SACCO'],
  ['H655', 'PD', 'SACCOLONGO'],
  ['H657', 'PN', 'SACILE'],
  ['H658', 'RM', 'SACROFANO'],
  ['H659', 'NU', 'SADALI'],
  ['H661', 'NU', 'SAGAMA'],
  ['H662', 'BI', 'SAGLIANO MICCA'],
  ['H665', 'GO', 'SAGRADO'],
  ['H666', 'TN', 'SAGRON MIS'],
  ['Z533', 'EE', 'SAINT KITTS E NEVIS'],
  ['Z527', 'EE', 'SAINT LUCIA'],
  ['Z403', 'EE', 'SAINT PIERRE ET MIQUELON (ISOLE)'],
  ['Z528', 'EE', 'SAINT VINCENT E GRENADINE'],
  ['H669', 'AO', 'SAINT-CHRISTOPHE'],
  ['H670', 'AO', 'SAINT-DENIS'],
  ['H671', 'AO', 'SAINT-MARCEL'],
  ['H672', 'AO', 'SAINT-NICOLAS'],
  ['H673', 'AO', 'SAINT-OYEN'],
  ['H674', 'AO', 'SAINT-PIERRE'],
  ['H675', 'AO', 'SAINT-RHEMY-EN-BOSSES'],
  ['H676', 'AO', 'SAINT-VINCENT'],
  ['H682', 'PR', 'SALA BAGANZA'],
  ['H681', 'BI', 'SALA BIELLESE'],
  ['H678', 'BO', 'SALA BOLOGNESE'],
  ['H679', 'CO', 'SALA COMACINA'],
  ['H683', 'SA', 'SALA CONSILINA'],
  ['H677', 'AL', 'SALA MONFERRATO'],
  ['H687', 'MT', 'SALANDRA'],
  ['H688', 'TP', 'SALAPARUTA'],
  ['H689', 'RO', 'SALARA'],
  ['H690', 'VC', 'SALASCO'],
  ['H691', 'TO', 'SALASSA'],
  ['H684', 'TO', 'SALBERTRAND'],
  ['F810', 'VI', 'SALCEDO'],
  ['H693', 'CB', 'SALCITO'],
  ['H694', 'AL', 'SALE'],
  ['H695', 'CN', 'SALE DELLE LANGHE'],
  ['H699', 'BS', 'SALE MARASINO'],
  ['H704', 'CN', 'SALE SAN GIOVANNI'],
  ['H700', 'TP', 'SALEMI'],
  ['H686', 'SA', 'SALENTO'],
  ['H702', 'TO', 'SALERANO CANAVESE'],
  ['H701', 'LO', 'SALERANO SUL LAMBRO'],
  ['H703', 'SA', 'SALERNO'],
  ['H705', 'PD', 'SALETTO'],
  ['H706', 'TV', 'SALGAREDA'],
  ['H707', 'VC', 'SALI VERCELLESE'],
  ['H708', 'LE', 'SALICE SALENTINO'],
  ['H710', 'CN', 'SALICETO'],
  ['H713', 'RI', 'SALISANO'],
  ['H714', 'VR', 'SALIZZOLE'],
  ['H715', 'PE', 'SALLE'],
  ['H716', 'CN', 'SALMOUR'],
  ['H717', 'BS', 'SALO\''],
  ['Z724', 'EE', 'SALOMONE'],
  ['H719', 'BZ', 'SALORNO'],
  ['H720', 'PR', 'SALSOMAGGIORE TERME'],
  ['H721', 'PU', 'SALTARA'],
  ['H723', 'VA', 'SALTRIO'],
  ['H724', 'RN', 'SALUDECIO'],
  ['H725', 'VC', 'SALUGGIA'],
  ['H726', 'BI', 'SALUSSOLA'],
  ['H727', 'CN', 'SALUZZO'],
  ['H729', 'LE', 'SALVE'],
  ['H731', 'CR', 'SALVIROLA'],
  ['H732', 'SA', 'SALVITELLE'],
  ['H734', 'TO', 'SALZA DI PINEROLO'],
  ['H733', 'AV', 'SALZA IRPINA'],
  ['H735', 'VE', 'SALZANO'],
  ['H736', 'VA', 'SAMARATE'],
  ['H738', 'CA', 'SAMASSI'],
  ['H739', 'CA', 'SAMATZAI'],
  ['H743', 'AG', 'SAMBUCA DI SICILIA'],
  ['H744', 'PT', 'SAMBUCA PISTOIESE'],
  ['H745', 'RM', 'SAMBUCI'],
  ['H746', 'CN', 'SAMBUCO'],
  ['H749', 'BA', 'SAMMICHELE DI BARI'],
  ['H013', 'RC', 'SAMO'],
  ['Z726', 'EE', 'SAMOA'],
  ['Z725', 'EE', 'SAMOA AMERICANE (ISOLE)'],
  ['H752', 'SO', 'SAMOLACO'],
  ['H754', 'TN', 'SAMONE'],
  ['H753', 'TO', 'SAMONE'],
  ['H755', 'CN', 'SAMPEYRE'],
  ['H756', 'OR', 'SAMUGHEO'],
  ['H763', 'IM', 'SAN BARTOLOMEO AL MARE'],
  ['H764', 'BN', 'SAN BARTOLOMEO IN GALDO'],
  ['H760', 'CO', 'SAN BARTOLOMEO VAL CAVARGNA'],
  ['H765', 'CS', 'SAN BASILE'],
  ['H766', 'CA', 'SAN BASILIO'],
  ['H767', 'CR', 'SAN BASSANO'],
  ['H768', 'RO', 'SAN BELLINO'],
  ['H770', 'CN', 'SAN BENEDETTO BELBO'],
  ['H772', 'AQ', 'SAN BENEDETTO DEI MARSI'],
  ['H769', 'AP', 'SAN BENEDETTO DEL TRONTO'],
  ['H773', 'AQ', 'SAN BENEDETTO IN PERILLIS'],
  ['H771', 'MN', 'SAN BENEDETTO PO'],
  ['H774', 'CS', 'SAN BENEDETTO ULLANO'],
  ['G566', 'BO', 'SAN BENEDETTO VAL DI SAMBRO'],
  ['H775', 'TO', 'SAN BENIGNO CANAVESE'],
  ['H777', 'VB', 'SAN BERNARDINO VERBANO'],
  ['H780', 'IM', 'SAN BIAGIO DELLA CIMA'],
  ['H781', 'TV', 'SAN BIAGIO DI CALLALTA'],
  ['H778', 'AG', 'SAN BIAGIO PLATANI'],
  ['H779', 'FR', 'SAN BIAGIO SARACINISCO'],
  ['H782', 'CB', 'SAN BIASE'],
  ['H783', 'VR', 'SAN BONIFACIO'],
  ['H784', 'CH', 'SAN BUONO'],
  ['H785', 'VV', 'SAN CALOGERO'],
  ['H786', 'BZ', 'SAN CANDIDO'],
  ['H787', 'GO', 'SAN CANZIAN D\'ISONZO'],
  ['H789', 'TO', 'SAN CARLO CANAVESE'],
  ['H790', 'SI', 'SAN CASCIANO DEI BAGNI'],
  ['H791', 'FI', 'SAN CASCIANO IN VAL DI PESA'],
  ['M264', 'LE', 'SAN CASSIANO'],
  ['H792', 'CL', 'SAN CATALDO'],
  ['M295', 'RM', 'SAN CESAREO'],
  ['H793', 'LE', 'SAN CESARIO DI LECCE'],
  ['H794', 'MO', 'SAN CESARIO SUL PANARO'],
  ['H795', 'PZ', 'SAN CHIRICO NUOVO'],
  ['H796', 'PZ', 'SAN CHIRICO RAPARO'],
  ['H797', 'PA', 'SAN CIPIRELLO'],
  ['H798', 'CE', 'SAN CIPRIANO D\'AVERSA'],
  ['H800', 'SA', 'SAN CIPRIANO PICENTINO'],
  ['H799', 'PV', 'SAN CIPRIANO PO'],
  ['H801', 'RN', 'SAN CLEMENTE'],
  ['H803', 'MI', 'SAN COLOMBANO AL LAMBRO'],
  ['H804', 'TO', 'SAN COLOMBANO BELMONTE'],
  ['H802', 'GE', 'SAN COLOMBANO CERTENOLI'],
  ['H805', 'CT', 'SAN CONO'],
  ['H806', 'CS', 'SAN COSMO ALBANESE'],
  ['H808', 'PZ', 'SAN COSTANTINO ALBANESE'],
  ['H807', 'VV', 'SAN COSTANTINO CALABRO'],
  ['H809', 'PU', 'SAN COSTANZO'],
  ['H810', 'AL', 'SAN CRISTOFORO'],
  ['H814', 'PV', 'SAN DAMIANO AL COLLE'],
  ['H811', 'AT', 'SAN DAMIANO D\'ASTI'],
  ['H812', 'CN', 'SAN DAMIANO MACRA'],
  ['H816', 'UD', 'SAN DANIELE DEL FRIULI'],
  ['H815', 'CR', 'SAN DANIELE PO'],
  ['H818', 'CS', 'SAN DEMETRIO CORONE'],
  ['H819', 'AQ', 'SAN DEMETRIO NE\' VESTINI'],
  ['H820', 'TO', 'SAN DIDERO'],
  ['H823', 'VE', 'SAN DONA\' DI PIAVE'],
  ['H822', 'BR', 'SAN DONACI'],
  ['H826', 'LE', 'SAN DONATO DI LECCE'],
  ['H825', 'CS', 'SAN DONATO DI NINEA'],
  ['H827', 'MI', 'SAN DONATO MILANESE'],
  ['H824', 'FR', 'SAN DONATO VAL DI COMINO'],
  ['D324', 'TS', 'SAN DORLIGO DELLA VALLE'],
  ['H830', 'CO', 'SAN FEDELE INTELVI'],
  ['H831', 'PZ', 'SAN FELE'],
  ['H834', 'CE', 'SAN FELICE A CANCELLO'],
  ['H836', 'LT', 'SAN FELICE CIRCEO'],
  ['H838', 'BS', 'SAN FELICE DEL BENACO'],
  ['H833', 'CB', 'SAN FELICE DEL MOLISE'],
  ['H835', 'MO', 'SAN FELICE SUL PANARO'],
  ['M277', 'RC', 'SAN FERDINANDO'],
  ['H839', 'BT', 'SAN FERDINANDO DI PUGLIA'],
  ['H839', 'FG', 'SAN FERDINANDO DI PUGLIA'],
  ['H840', 'CO', 'SAN FERMO DELLA BATTAGLIA'],
  ['H841', 'CS', 'SAN FILI'],
  ['H842', 'ME', 'SAN FILIPPO DEL MELA'],
  ['H843', 'TV', 'SAN FIOR'],
  ['H844', 'LO', 'SAN FIORANO'],
  ['H845', 'GO', 'SAN FLORIANO DEL COLLIO'],
  ['H846', 'CZ', 'SAN FLORO'],
  ['H847', 'TO', 'SAN FRANCESCO AL CAMPO'],
  ['H850', 'ME', 'SAN FRATELLO'],
  ['H856', 'CA', 'SAN GAVINO MONREALE'],
  ['H857', 'TR', 'SAN GEMINI'],
  ['H858', 'BZ', 'SAN GENESIO ATESINO'],
  ['H859', 'PV', 'SAN GENESIO ED UNITI'],
  ['H860', 'NA', 'SAN GENNARO VESUVIANO'],
  ['H862', 'TO', 'SAN GERMANO CHISONE'],
  ['H863', 'VI', 'SAN GERMANO DEI BERICI'],
  ['H861', 'VC', 'SAN GERMANO VERCELLESE'],
  ['H865', 'BS', 'SAN GERVASIO BRESCIANO'],
  ['H867', 'CB', 'SAN GIACOMO DEGLI SCHIAVONI'],
  ['H870', 'MN', 'SAN GIACOMO DELLE SEGNATE'],
  ['H868', 'SO', 'SAN GIACOMO FILIPPO'],
  ['B952', 'VC', 'SAN GIACOMO VERCELLESE'],
  ['H873', 'TO', 'SAN GILLIO'],
  ['H875', 'SI', 'SAN GIMIGNANO'],
  ['H876', 'MC', 'SAN GINESIO'],
  ['H892', 'NA', 'SAN GIORGIO A CREMANO'],
  ['H880', 'FR', 'SAN GIORGIO A LIRI'],
  ['H881', 'CS', 'SAN GIORGIO ALBANESE'],
  ['H890', 'TO', 'SAN GIORGIO CANAVESE'],
  ['H894', 'BN', 'SAN GIORGIO DEL SANNIO'],
  ['H891', 'PN', 'SAN GIORGIO DELLA RICHINVELDA'],
  ['H893', 'PD', 'SAN GIORGIO DELLE PERTICHE'],
  ['H885', 'PV', 'SAN GIORGIO DI LOMELLINA'],
  ['H883', 'MN', 'SAN GIORGIO DI MANTOVA'],
  ['H895', 'UD', 'SAN GIORGIO DI NOGARO'],
  ['H886', 'PU', 'SAN GIORGIO DI PESARO'],
  ['H896', 'BO', 'SAN GIORGIO DI PIANO'],
  ['H897', 'PD', 'SAN GIORGIO IN BOSCO'],
  ['H882', 'TA', 'SAN GIORGIO IONICO'],
  ['H898', 'BN', 'SAN GIORGIO LA MOLARA'],
  ['H888', 'MT', 'SAN GIORGIO LUCANO'],
  ['H878', 'AL', 'SAN GIORGIO MONFERRATO'],
  ['H889', 'RC', 'SAN GIORGIO MORGETO'],
  ['H887', 'PC', 'SAN GIORGIO PIACENTINO'],
  ['H899', 'AT', 'SAN GIORGIO SCARAMPI'],
  ['H884', 'MI', 'SAN GIORGIO SU LEGNANO'],
  ['H900', 'TO', 'SAN GIORIO DI SUSA'],
  ['H907', 'SA', 'SAN GIOVANNI A PIRO'],
  ['H906', 'UD', 'SAN GIOVANNI AL NATISONE'],
  ['H910', 'BG', 'SAN GIOVANNI BIANCO'],
  ['H911', 'SI', 'SAN GIOVANNI D\'ASSO'],
  ['H912', 'MN', 'SAN GIOVANNI DEL DOSSO'],
  ['H903', 'RC', 'SAN GIOVANNI DI GERACE'],
  ['H914', 'AG', 'SAN GIOVANNI GEMINI'],
  ['H916', 'VR', 'SAN GIOVANNI ILARIONE'],
  ['H918', 'CR', 'SAN GIOVANNI IN CROCE'],
  ['H919', 'CS', 'SAN GIOVANNI IN FIORE'],
  ['H920', 'CB', 'SAN GIOVANNI IN GALDO'],
  ['H921', 'RN', 'SAN GIOVANNI IN MARIGNANO'],
  ['G467', 'BO', 'SAN GIOVANNI IN PERSICETO'],
  ['H917', 'FR', 'SAN GIOVANNI INCARICO'],
  ['H922', 'CT', 'SAN GIOVANNI LA PUNTA'],
  ['H923', 'CH', 'SAN GIOVANNI LIPIONI'],
  ['H924', 'VR', 'SAN GIOVANNI LUPATOTO'],
  ['H926', 'FG', 'SAN GIOVANNI ROTONDO'],
  ['G287', 'CA', 'SAN GIOVANNI SUERGIU'],
  ['D690', 'CH', 'SAN GIOVANNI TEATINO'],
  ['H901', 'AR', 'SAN GIOVANNI VALDARNO'],
  ['H928', 'CB', 'SAN GIULIANO DEL SANNIO'],
  ['H929', 'CB', 'SAN GIULIANO DI PUGLIA'],
  ['H930', 'MI', 'SAN GIULIANO MILANESE'],
  ['A562', 'PI', 'SAN GIULIANO TERME'],
  ['H933', 'PA', 'SAN GIUSEPPE JATO'],
  ['H931', 'NA', 'SAN GIUSEPPE VESUVIANO'],
  ['H935', 'PG', 'SAN GIUSTINO'],
  ['H936', 'TO', 'SAN GIUSTO CANAVESE'],
  ['H937', 'FI', 'SAN GODENZO'],
  ['H941', 'VV', 'SAN GREGORIO D\'IPPONA'],
  ['H942', 'RM', 'SAN GREGORIO DA SASSOLA'],
  ['H940', 'CT', 'SAN GREGORIO DI CATANIA'],
  ['H943', 'SA', 'SAN GREGORIO MAGNO'],
  ['H939', 'CE', 'SAN GREGORIO MATESE'],
  ['H938', 'BL', 'SAN GREGORIO NELLE ALPI'],
  ['H945', 'BO', 'SAN LAZZARO DI SAVENA'],
  ['H949', 'PU', 'SAN LEO'],
  ['H951', 'UD', 'SAN LEONARDO'],
  ['H952', 'BZ', 'SAN LEONARDO IN PASSIRIA'],
  ['H953', 'BN', 'SAN LEUCIO DEL SANNIO'],
  ['H955', 'BN', 'SAN LORENZELLO'],
  ['H959', 'RC', 'SAN LORENZO'],
  ['H957', 'IM', 'SAN LORENZO AL MARE'],
  ['H961', 'CS', 'SAN LORENZO BELLIZZI'],
  ['H962', 'CS', 'SAN LORENZO DEL VALLO'],
  ['H956', 'BZ', 'SAN LORENZO DI SEBATO'],
  ['H966', 'TN', 'SAN LORENZO IN BANALE'],
  ['H958', 'PU', 'SAN LORENZO IN CAMPO'],
  ['H964', 'GO', 'SAN LORENZO ISONTINO'],
  ['H967', 'BN', 'SAN LORENZO MAGGIORE'],
  ['H969', 'VT', 'SAN LORENZO NUOVO'],
  ['H970', 'RC', 'SAN LUCA'],
  ['H971', 'CS', 'SAN LUCIDO'],
  ['H973', 'BN', 'SAN LUPO'],
  ['H976', 'CZ', 'SAN MANGO D\'AQUINO'],
  ['H977', 'SA', 'SAN MANGO PIEMONTE'],
  ['H975', 'AV', 'SAN MANGO SUL CALORE'],
  ['H978', 'CE', 'SAN MARCELLINO'],
  ['H979', 'AN', 'SAN MARCELLO'],
  ['H980', 'PT', 'SAN MARCELLO PISTOIESE'],
  ['H981', 'CS', 'SAN MARCO ARGENTANO'],
  ['H982', 'ME', 'SAN MARCO D\'ALUNZIO'],
  ['H984', 'BN', 'SAN MARCO DEI CAVOTI'],
  ['F043', 'CE', 'SAN MARCO EVANGELISTA'],
  ['H985', 'FG', 'SAN MARCO IN LAMIS'],
  ['H986', 'FG', 'SAN MARCO LA CATOLA'],
  ['Z130', 'EE', 'SAN MARINO'],
  ['H999', 'PN', 'SAN MARTINO AL TAGLIAMENTO'],
  ['H987', 'AT', 'SAN MARTINO ALFIERI'],
  ['I003', 'VR', 'SAN MARTINO BUON ALBERGO'],
  ['H997', 'TO', 'SAN MARTINO CANAVESE'],
  ['H994', 'PZ', 'SAN MARTINO D\'AGRI'],
  ['I005', 'MN', 'SAN MARTINO DALL\'ARGINE'],
  ['I007', 'CR', 'SAN MARTINO DEL LAGO'],
  ['H992', 'CS', 'SAN MARTINO DI FINITA'],
  ['I008', 'PD', 'SAN MARTINO DI LUPARI'],
  ['H996', 'RO', 'SAN MARTINO DI VENEZZE'],
  ['H988', 'BZ', 'SAN MARTINO IN BADIA'],
  ['H989', 'BZ', 'SAN MARTINO IN PASSIRIA'],
  ['H990', 'CB', 'SAN MARTINO IN PENSILIS'],
  ['I011', 'RE', 'SAN MARTINO IN RIO'],
  ['I012', 'LO', 'SAN MARTINO IN STRADA'],
  ['I002', 'BN', 'SAN MARTINO SANNITA'],
  ['I014', 'PV', 'SAN MARTINO SICCOMARIO'],
  ['H991', 'CH', 'SAN MARTINO SULLA MARRUCINA'],
  ['I016', 'AV', 'SAN MARTINO VALLE CAUDINA'],
  ['I018', 'TA', 'SAN MARZANO DI SAN GIUSEPPE'],
  ['I017', 'AT', 'SAN MARZANO OLIVETO'],
  ['I019', 'SA', 'SAN MARZANO SUL SARNO'],
  ['I023', 'CB', 'SAN MASSIMO'],
  ['I024', 'TO', 'SAN MAURIZIO CANAVESE'],
  ['I025', 'NO', 'SAN MAURIZIO D\'OPAGLIO'],
  ['I028', 'PA', 'SAN MAURO CASTELVERDE'],
  ['I031', 'SA', 'SAN MAURO CILENTO'],
  ['H712', 'VR', 'SAN MAURO DI SALINE'],
  ['I029', 'MT', 'SAN MAURO FORTE'],
  ['I032', 'SA', 'SAN MAURO LA BRUCA'],
  ['I026', 'KR', 'SAN MAURO MARCHESATO'],
  ['I027', 'FC', 'SAN MAURO PASCOLI'],
  ['I030', 'TO', 'SAN MAURO TORINESE'],
  ['I040', 'VE', 'SAN MICHELE AL TAGLIAMENTO'],
  ['I042', 'TN', 'SAN MICHELE ALL\'ADIGE'],
  ['I035', 'CT', 'SAN MICHELE DI GANZARIA'],
  ['I034', 'AV', 'SAN MICHELE DI SERINO'],
  ['I037', 'CN', 'SAN MICHELE MONDOVI\''],
  ['I045', 'BR', 'SAN MICHELE SALENTINO'],
  ['I046', 'PI', 'SAN MINIATO'],
  ['I047', 'VI', 'SAN NAZARIO'],
  ['I049', 'BN', 'SAN NAZZARO'],
  ['I052', 'NO', 'SAN NAZZARO SESIA'],
  ['I051', 'CO', 'SAN NAZZARO VAL CAVARGNA'],
  ['I060', 'CS', 'SAN NICOLA ARCELLA'],
  ['I061', 'AV', 'SAN NICOLA BARONIA'],
  ['I058', 'VV', 'SAN NICOLA DA CRISSA'],
  ['I057', 'KR', 'SAN NICOLA DELL\'ALTO'],
  ['I056', 'CE', 'SAN NICOLA LA STRADA'],
  ['I062', 'BN', 'SAN NICOLA MANFREDI'],
  ['A368', 'OR', 'SAN NICOLO\' D\'ARCIDANO'],
  ['I063', 'BL', 'SAN NICOLO\' DI COMELICO'],
  ['G383', 'CA', 'SAN NICOLO\' GERREI'],
  ['I065', 'BZ', 'SAN PANCRAZIO'],
  ['I066', 'BR', 'SAN PANCRAZIO SALENTINO'],
  ['G407', 'BS', 'SAN PAOLO'],
  ['B906', 'PZ', 'SAN PAOLO ALBANESE'],
  ['I073', 'NA', 'SAN PAOLO BEL SITO'],
  ['I074', 'BI', 'SAN PAOLO CERVO'],
  ['B310', 'BG', 'SAN PAOLO D\'ARGON'],
  ['I072', 'FG', 'SAN PAOLO DI CIVITATE'],
  ['I071', 'AN', 'SAN PAOLO DI JESI'],
  ['I076', 'AT', 'SAN PAOLO SOLBRITO'],
  ['I079', 'BG', 'SAN PELLEGRINO TERME'],
  ['I082', 'GO', 'SAN PIER D\'ISONZO'],
  ['I084', 'ME', 'SAN PIER NICETO'],
  ['I085', 'FI', 'SAN PIERO A SIEVE'],
  ['I086', 'ME', 'SAN PIERO PATTI'],
  ['I093', 'CZ', 'SAN PIETRO A MAIDA'],
  ['I092', 'UD', 'SAN PIETRO AL NATISONE'],
  ['I089', 'SA', 'SAN PIETRO AL TANAGRO'],
  ['I095', 'CZ', 'SAN PIETRO APOSTOLO'],
  ['I096', 'IS', 'SAN PIETRO AVELLANA'],
  ['I098', 'CT', 'SAN PIETRO CLARENZA'],
  ['I088', 'BL', 'SAN PIETRO DI CADORE'],
  ['I102', 'RC', 'SAN PIETRO DI CARIDA\''],
  ['I103', 'TV', 'SAN PIETRO DI FELETTO'],
  ['I105', 'VR', 'SAN PIETRO DI MORUBIO'],
  ['I108', 'CS', 'SAN PIETRO IN AMANTEA'],
  ['I109', 'VR', 'SAN PIETRO IN CARIANO'],
  ['I110', 'BO', 'SAN PIETRO IN CASALE'],
  ['G788', 'PC', 'SAN PIETRO IN CERRO'],
  ['I107', 'PD', 'SAN PIETRO IN GU'],
  ['I114', 'CS', 'SAN PIETRO IN GUARANO'],
  ['I115', 'LE', 'SAN PIETRO IN LAMA'],
  ['I113', 'CE', 'SAN PIETRO INFINE'],
  ['I116', 'NO', 'SAN PIETRO MOSEZZO'],
  ['I117', 'VI', 'SAN PIETRO MUSSOLINO'],
  ['I090', 'TO', 'SAN PIETRO VAL LEMINA'],
  ['I119', 'BR', 'SAN PIETRO VERNOTICO'],
  ['I120', 'PD', 'SAN PIETRO VIMINARIO'],
  ['I121', 'AQ', 'SAN PIO DELLE CAMERE'],
  ['I123', 'RE', 'SAN POLO D\'ENZA'],
  ['I125', 'RM', 'SAN POLO DEI CAVALIERI'],
  ['I124', 'TV', 'SAN POLO DI PIAVE'],
  ['I122', 'CB', 'SAN POLO MATESE'],
  ['I126', 'TO', 'SAN PONSO'],
  ['I128', 'MO', 'SAN POSSIDONIO'],
  ['I130', 'CE', 'SAN POTITO SANNITICO'],
  ['I129', 'AV', 'SAN POTITO ULTRA'],
  ['I131', 'CE', 'SAN PRISCO'],
  ['I132', 'RC', 'SAN PROCOPIO'],
  ['I133', 'MO', 'SAN PROSPERO'],
  ['I135', 'SI', 'SAN QUIRICO D\'ORCIA'],
  ['I136', 'PN', 'SAN QUIRINO'],
  ['I137', 'TO', 'SAN RAFFAELE CIMENA'],
  ['I138', 'IM', 'SAN REMO'],
  ['I139', 'RC', 'SAN ROBERTO'],
  ['I140', 'LO', 'SAN ROCCO AL PORTO'],
  ['I142', 'LU', 'SAN ROMANO IN GARFAGNANA'],
  ['I143', 'SA', 'SAN RUFO'],
  ['I147', 'ME', 'SAN SALVATORE DI FITALIA'],
  ['I144', 'AL', 'SAN SALVATORE MONFERRATO'],
  ['I145', 'BN', 'SAN SALVATORE TELESINO'],
  ['I148', 'CH', 'SAN SALVO'],
  ['I151', 'NA', 'SAN SEBASTIANO AL VESUVIO'],
  ['I150', 'AL', 'SAN SEBASTIANO CURONE'],
  ['I152', 'TO', 'SAN SEBASTIANO DA PO'],
  ['I154', 'TO', 'SAN SECONDO DI PINEROLO'],
  ['I153', 'PR', 'SAN SECONDO PARMENSE'],
  ['I157', 'PZ', 'SAN SEVERINO LUCANO'],
  ['I156', 'MC', 'SAN SEVERINO MARCHE'],
  ['I158', 'FG', 'SAN SEVERO'],
  ['I162', 'CO', 'SAN SIRO'],
  ['I163', 'AV', 'SAN SOSSIO BARONIA'],
  ['I164', 'CZ', 'SAN SOSTENE'],
  ['I165', 'CS', 'SAN SOSTI'],
  ['I166', 'CA', 'SAN SPERATE'],
  ['I261', 'CE', 'SAN TAMMARO'],
  ['I328', 'ME', 'SAN TEODORO'],
  ['I329', 'NU', 'SAN TEODORO'],
  ['I347', 'BL', 'SAN TOMASO AGORDINO'],
  ['I376', 'PE', 'SAN VALENTINO IN ABRUZZO CITERIORE'],
  ['I377', 'SA', 'SAN VALENTINO TORIO'],
  ['I381', 'TR', 'SAN VENANZO'],
  ['I382', 'TV', 'SAN VENDEMIANO'],
  ['I384', 'OR', 'SAN VERO MILIS'],
  ['I390', 'LI', 'SAN VINCENZO'],
  ['I388', 'CS', 'SAN VINCENZO LA COSTA'],
  ['I389', 'AQ', 'SAN VINCENZO VALLE ROVETO'],
  ['I391', 'NA', 'SAN VITALIANO'],
  ['I402', 'CA', 'SAN VITO'],
  ['I403', 'PN', 'SAN VITO AL TAGLIAMENTO'],
  ['I404', 'UD', 'SAN VITO AL TORRE'],
  ['I394', 'CH', 'SAN VITO CHIETINO'],
  ['I396', 'BR', 'SAN VITO DEI NORMANNI'],
  ['I392', 'BL', 'SAN VITO DI CADORE'],
  ['I405', 'UD', 'SAN VITO DI FAGAGNA'],
  ['I401', 'VI', 'SAN VITO DI LEGUZZANO'],
  ['I407', 'TP', 'SAN VITO LO CAPO'],
  ['I400', 'RM', 'SAN VITO ROMANO'],
  ['I393', 'CZ', 'SAN VITO SULLO IONIO'],
  ['I408', 'FR', 'SAN VITTORE DEL LAZIO'],
  ['I409', 'MI', 'SAN VITTORE OLONA'],
  ['I414', 'VR', 'SAN ZENO DI MONTAGNA'],
  ['I412', 'BS', 'SAN ZENO NAVIGLIO'],
  ['I415', 'MI', 'SAN ZENONE AL LAMBRO'],
  ['I416', 'PV', 'SAN ZENONE AL PO'],
  ['I417', 'TV', 'SAN ZENONE DEGLI EZZELINI'],
  ['H757', 'LE', 'SANARICA'],
  ['H821', 'BI', 'SANDIGLIANO'],
  ['H829', 'VI', 'SANDRIGO'],
  ['H851', 'CN', 'SANFRE\''],
  ['H852', 'CN', 'SANFRONT'],
  ['H855', 'TO', 'SANGANO'],
  ['H872', 'VA', 'SANGIANO'],
  ['H877', 'CS', 'SANGINETO'],
  ['H944', 'VR', 'SANGUINETTO'],
  ['H974', 'CA', 'SANLURI'],
  ['I048', 'PV', 'SANNAZZARO DE\' BURGONDI'],
  ['I053', 'BA', 'SANNICANDRO DI BARI'],
  ['I054', 'FG', 'SANNICANDRO GARGANICO'],
  ['I059', 'LE', 'SANNICOLA'],
  ['I155', 'AR', 'SANSEPOLCRO'],
  ['I189', 'IS', 'SANT\'AGAPITO'],
  ['I191', 'BO', 'SANT\'AGATA BOLOGNESE'],
  ['I197', 'BN', 'SANT\'AGATA DE\' GOTI'],
  ['I198', 'RC', 'SANT\'AGATA DEL BIANCO'],
  ['I192', 'CS', 'SANT\'AGATA DI ESARO'],
  ['I199', 'ME', 'SANT\'AGATA DI MILITELLO'],
  ['I193', 'FG', 'SANT\'AGATA DI PUGLIA'],
  ['I201', 'PU', 'SANT\'AGATA FELTRIA'],
  ['I190', 'AL', 'SANT\'AGATA FOSSILI'],
  ['I202', 'CT', 'SANT\'AGATA LI BATTIATI'],
  ['I196', 'RA', 'SANT\'AGATA SUL SANTERNO'],
  ['I208', 'NA', 'SANT\'AGNELLO'],
  ['I209', 'FE', 'SANT\'AGOSTINO'],
  ['I210', 'CN', 'SANT\'ALBANO STURA'],
  ['I213', 'PV', 'SANT\'ALESSIO CON VIALONE'],
  ['I214', 'RC', 'SANT\'ALESSIO IN ASPROMONTE'],
  ['I215', 'ME', 'SANT\'ALESSIO SICULO'],
  ['I216', 'CT', 'SANT\'ALFIO'],
  ['I258', 'TO', 'SANT\'AMBROGIO DI TORINO'],
  ['I259', 'VR', 'SANT\'AMBROGIO DI VALPOLICELLA'],
  ['I256', 'FR', 'SANT\'AMBROGIO SUL GARIGLIANO'],
  ['I262', 'NA', 'SANT\'ANASTASIA'],
  ['I263', 'PG', 'SANT\'ANATOLIA DI NARCO'],
  ['I266', 'CZ', 'SANT\'ANDREA APOSTOLO DELLO IONIO'],
  ['I265', 'FR', 'SANT\'ANDREA DEL GARIGLIANO'],
  ['I264', 'AV', 'SANT\'ANDREA DI CONZA'],
  ['I271', 'CA', 'SANT\'ANDREA FRIUS'],
  ['I277', 'BN', 'SANT\'ANGELO A CUPOLO'],
  ['I278', 'SA', 'SANT\'ANGELO A FASANELLA'],
  ['I280', 'AV', 'SANT\'ANGELO A SCALA'],
  ['I279', 'AV', 'SANT\'ANGELO ALL\'ESCA'],
  ['I273', 'CE', 'SANT\'ANGELO D\'ALIFE'],
  ['I281', 'AV', 'SANT\'ANGELO DEI LOMBARDI'],
  ['I282', 'IS', 'SANT\'ANGELO DEL PESCO'],
  ['I283', 'ME', 'SANT\'ANGELO DI BROLO'],
  ['I275', 'PD', 'SANT\'ANGELO DI PIOVE DI SACCO'],
  ['I285', 'PU', 'SANT\'ANGELO IN LIZZOLA'],
  ['I286', 'MC', 'SANT\'ANGELO IN PONTANO'],
  ['I287', 'PU', 'SANT\'ANGELO IN VADO'],
  ['I288', 'PZ', 'SANT\'ANGELO LE FRATTE'],
  ['I289', 'CB', 'SANT\'ANGELO LIMOSANO'],
  ['I274', 'LO', 'SANT\'ANGELO LODIGIANO'],
  ['I276', 'PV', 'SANT\'ANGELO LOMELLINA'],
  ['I290', 'AG', 'SANT\'ANGELO MUXARO'],
  ['I284', 'RM', 'SANT\'ANGELO ROMANO'],
  ['M209', 'CA', 'SANT\'ANNA ARRESI'],
  ['I292', 'VR', 'SANT\'ANNA D\'ALFAEDO'],
  ['I293', 'NA', 'SANT\'ANTIMO'],
  ['I294', 'CA', 'SANT\'ANTIOCO'],
  ['I296', 'TO', 'SANT\'ANTONINO DI SUSA'],
  ['I300', 'NA', 'SANT\'ANTONIO ABATE'],
  ['M276', 'SS', 'SANT\'ANTONIO DI GALLURA'],
  ['I302', 'FR', 'SANT\'APOLLINARE'],
  ['I305', 'PZ', 'SANT\'ARCANGELO'],
  ['F557', 'BN', 'SANT\'ARCANGELO TRIMONTE'],
  ['I306', 'CE', 'SANT\'ARPINO'],
  ['I307', 'SA', 'SANT\'ARSENIO'],
  ['I318', 'TE', 'SANT\'EGIDIO ALLA VIBRATA'],
  ['I317', 'SA', 'SANT\'EGIDIO DEL MONTE ALBINO'],
  ['I319', 'PD', 'SANT\'ELENA'],
  ['Z340', 'EE', 'SANT\'ELENA (ISOLA)'],
  ['B466', 'IS', 'SANT\'ELENA SANNITA'],
  ['I320', 'CB', 'SANT\'ELIA A PIANISI'],
  ['I321', 'FR', 'SANT\'ELIA FIUMERAPIDO'],
  ['I324', 'FM', 'SANT\'ELPIDIO A MARE'],
  ['I324', 'AP', 'SANT\'ELPIDIO A MARE'],
  ['I332', 'PE', 'SANT\'EUFEMIA A MAIELLA'],
  ['I333', 'RC', 'SANT\'EUFEMIA D\'ASPROMONTE'],
  ['I335', 'CH', 'SANT\'EUSANIO DEL SANGRO'],
  ['I336', 'AQ', 'SANT\'EUSANIO FORCONESE'],
  ['I342', 'RE', 'SANT\'ILARIO D\'ENZA'],
  ['I341', 'RC', 'SANT\'ILARIO DELLO IONIO'],
  ['I344', 'PU', 'SANT\'IPPOLITO'],
  ['I346', 'GE', 'SANT\'OLCESE'],
  ['I348', 'TE', 'SANT\'OMERO'],
  ['I349', 'BG', 'SANT\'OMOBONO IMAGNA'],
  ['I350', 'VV', 'SANT\'ONOFRIO'],
  ['I352', 'RM', 'SANT\'ORESTE'],
  ['I354', 'TN', 'SANT\'ORSOLA TERME'],
  ['I375', 'PD', 'SANT\'URBANO'],
  ['I168', 'BG', 'SANTA BRIGIDA'],
  ['I171', 'CS', 'SANTA CATERINA ALBANESE'],
  ['I170', 'CZ', 'SANTA CATERINA DELLO IONIO'],
  ['I169', 'CL', 'SANTA CATERINA VILLARMOSA'],
  ['I172', 'LE', 'SANTA CESAREA TERME'],
  ['I176', 'RC', 'SANTA CRISTINA D\'ASPROMONTE'],
  ['I175', 'PV', 'SANTA CRISTINA E BISSONE'],
  ['I174', 'PA', 'SANTA CRISTINA GELA'],
  ['I173', 'BZ', 'SANTA CRISTINA VALGARDENA'],
  ['I178', 'RG', 'SANTA CROCE CAMERINA'],
  ['I179', 'BN', 'SANTA CROCE DEL SANNIO'],
  ['I181', 'CB', 'SANTA CROCE DI MAGLIANO'],
  ['I177', 'PI', 'SANTA CROCE SULL\'ARNO'],
  ['I183', 'CS', 'SANTA DOMENICA TALAO'],
  ['I184', 'ME', 'SANTA DOMENICA VITTORIA'],
  ['I185', 'AG', 'SANTA ELISABETTA'],
  ['I187', 'GR', 'SANTA FIORA'],
  ['I188', 'PA', 'SANTA FLAVIA'],
  ['I203', 'PV', 'SANTA GIULETTA'],
  ['I205', 'OR', 'SANTA GIUSTA'],
  ['I206', 'BL', 'SANTA GIUSTINA'],
  ['I207', 'PD', 'SANTA GIUSTINA IN COLLE'],
  ['I217', 'PI', 'SANTA LUCE'],
  ['I220', 'ME', 'SANTA LUCIA DEL MELA'],
  ['I221', 'TV', 'SANTA LUCIA DI PIAVE'],
  ['I219', 'AV', 'SANTA LUCIA DI SERINO'],
  ['I226', 'PD', 'SANTA MARGHERITA D\'ADIGE'],
  ['I224', 'AG', 'SANTA MARGHERITA DI BELICE'],
  ['I230', 'PV', 'SANTA MARGHERITA DI STAFFORA'],
  ['I225', 'GE', 'SANTA MARGHERITA LIGURE'],
  ['I232', 'PI', 'SANTA MARIA A MONTE'],
  ['I233', 'CE', 'SANTA MARIA A VICO'],
  ['I234', 'CE', 'SANTA MARIA CAPUA VETERE'],
  ['M284', 'SS', 'SANTA MARIA COGHINAS'],
  ['C717', 'CS', 'SANTA MARIA DEL CEDRO'],
  ['I238', 'IS', 'SANTA MARIA DEL MOLISE'],
  ['I237', 'PV', 'SANTA MARIA DELLA VERSA'],
  ['I240', 'CT', 'SANTA MARIA DI LICODIA'],
  ['I242', 'VE', 'SANTA MARIA DI SALA'],
  ['I243', 'LC', 'SANTA MARIA HOE\''],
  ['I244', 'CH', 'SANTA MARIA IMBARO'],
  ['M273', 'NA', 'SANTA MARIA LA CARITA\''],
  ['I247', 'CE', 'SANTA MARIA LA FOSSA'],
  ['I248', 'UD', 'SANTA MARIA LA LONGA'],
  ['I249', 'VB', 'SANTA MARIA MAGGIORE'],
  ['I251', 'AN', 'SANTA MARIA NUOVA'],
  ['I253', 'SA', 'SANTA MARINA'],
  ['I254', 'ME', 'SANTA MARINA SALINA'],
  ['I255', 'RM', 'SANTA MARINELLA'],
  ['I291', 'TP', 'SANTA NINFA'],
  ['I301', 'AV', 'SANTA PAOLINA'],
  ['I308', 'KR', 'SANTA SEVERINA'],
  ['I310', 'FC', 'SANTA SOFIA'],
  ['I309', 'CS', 'SANTA SOFIA D\'EPIRO'],
  ['I311', 'ME', 'SANTA TERESA DI RIVA'],
  ['I312', 'SS', 'SANTA TERESA GALLURA'],
  ['I314', 'CT', 'SANTA VENERINA'],
  ['I316', 'CN', 'SANTA VITTORIA D\'ALBA'],
  ['I315', 'FM', 'SANTA VITTORIA IN MATENANO'],
  ['I315', 'AP', 'SANTA VITTORIA IN MATENANO'],
  ['I182', 'CA', 'SANTADI'],
  ['I304', 'RN', 'SANTARCANGELO DI ROMAGNA'],
  ['I326', 'AQ', 'SANTE MARIE'],
  ['I327', 'TO', 'SANTENA'],
  ['I330', 'BA', 'SANTERAMO IN COLLE'],
  ['I337', 'VC', 'SANTHIA\''],
  ['I339', 'LT', 'SANTI COSMA E DAMIANO'],
  ['I365', 'IM', 'SANTO STEFANO AL MARE'],
  ['I367', 'CN', 'SANTO STEFANO BELBO'],
  ['I368', 'GE', 'SANTO STEFANO D\'AVETO'],
  ['I357', 'AV', 'SANTO STEFANO DEL SOLE'],
  ['C919', 'BL', 'SANTO STEFANO DI CADORE'],
  ['I370', 'ME', 'SANTO STEFANO DI CAMASTRA'],
  ['I363', 'SP', 'SANTO STEFANO DI MAGRA'],
  ['I359', 'CS', 'SANTO STEFANO DI ROGLIANO'],
  ['I360', 'AQ', 'SANTO STEFANO DI SESSANIO'],
  ['I371', 'RC', 'SANTO STEFANO IN ASPROMONTE'],
  ['I362', 'LO', 'SANTO STEFANO LODIGIANO'],
  ['I356', 'AG', 'SANTO STEFANO QUISQUINA'],
  ['I372', 'CN', 'SANTO STEFANO ROERO'],
  ['I361', 'MI', 'SANTO STEFANO TICINO'],
  ['I373', 'VE', 'SANTO STINO DI LIVENZA'],
  ['I260', 'SA', 'SANTOMENNA'],
  ['I351', 'FR', 'SANTOPADRE'],
  ['I353', 'VI', 'SANTORSO'],
  ['I374', 'OR', 'SANTU LUSSURGIU'],
  ['I410', 'SA', 'SANZA'],
  ['I411', 'TN', 'SANZENO'],
  ['Z341', 'EE', 'SAO TOME\' E PRINCIPE'],
  ['I418', 'PD', 'SAONARA'],
  ['I420', 'ME', 'SAPONARA'],
  ['I421', 'BL', 'SAPPADA'],
  ['I422', 'SA', 'SAPRI'],
  ['I423', 'CS', 'SARACENA'],
  ['I424', 'RM', 'SARACINESCO'],
  ['I425', 'VI', 'SARCEDO'],
  ['I426', 'PZ', 'SARCONI'],
  ['I428', 'CA', 'SARDARA'],
  ['I429', 'AL', 'SARDIGLIANO'],
  ['I430', 'VI', 'SAREGO'],
  ['I431', 'BZ', 'SARENTINO'],
  ['I432', 'AL', 'SAREZZANO'],
  ['I433', 'BS', 'SAREZZO'],
  ['I434', 'PC', 'SARMATO'],
  ['I435', 'TV', 'SARMEDE'],
  ['I436', 'MC', 'SARNANO'],
  ['I437', 'BG', 'SARNICO'],
  ['I438', 'SA', 'SARNO'],
  ['I439', 'TN', 'SARNONICO'],
  ['I441', 'VA', 'SARONNO'],
  ['I442', 'AO', 'SARRE'],
  ['I443', 'CA', 'SARROCH'],
  ['I444', 'FC', 'SARSINA'],
  ['I445', 'SI', 'SARTEANO'],
  ['I447', 'PV', 'SARTIRANA LOMELLINA'],
  ['I448', 'NU', 'SARULE'],
  ['I449', 'SP', 'SARZANA'],
  ['I451', 'SA', 'SASSANO'],
  ['I452', 'SS', 'SASSARI'],
  ['I453', 'SV', 'SASSELLO'],
  ['I454', 'LI', 'SASSETTA'],
  ['I455', 'BN', 'SASSINORO'],
  ['I457', 'PZ', 'SASSO DI CASTALDA'],
  ['G972', 'BO', 'SASSO MARCONI'],
  ['I459', 'PU', 'SASSOCORVARO'],
  ['I460', 'PU', 'SASSOFELTRIO'],
  ['I461', 'AN', 'SASSOFERRATO'],
  ['I462', 'MO', 'SASSUOLO'],
  ['I463', 'CZ', 'SATRIANO'],
  ['G614', 'PZ', 'SATRIANO DI LUCANIA'],
  ['I464', 'UD', 'SAURIS'],
  ['I466', 'TO', 'SAUZE D\'OULX'],
  ['I465', 'TO', 'SAUZE DI CESANA'],
  ['I467', 'TA', 'SAVA'],
  ['Z714', 'EE', 'SAVAGE (ISOLE)'],
  ['I468', 'KR', 'SAVELLI'],
  ['I469', 'NA', 'SAVIANO'],
  ['I470', 'CN', 'SAVIGLIANO'],
  ['I471', 'AV', 'SAVIGNANO IRPINO'],
  ['I473', 'MO', 'SAVIGNANO SUL PANARO'],
  ['I472', 'FC', 'SAVIGNANO SUL RUBICONE'],
  ['I474', 'BO', 'SAVIGNO'],
  ['I475', 'GE', 'SAVIGNONE'],
  ['I476', 'BS', 'SAVIORE DELL\'ADAMELLO'],
  ['I477', 'ME', 'SAVOCA'],
  ['I478', 'UD', 'SAVOGNA'],
  ['I479', 'GO', 'SAVOGNA D\'ISONZO'],
  ['H730', 'PZ', 'SAVOIA DI LUCANIA'],
  ['I480', 'SV', 'SAVONA'],
  ['I482', 'PE', 'SCAFA'],
  ['I483', 'SA', 'SCAFATI'],
  ['I484', 'CN', 'SCAGNELLO'],
  ['I486', 'SA', 'SCALA'],
  ['I485', 'CS', 'SCALA COELI'],
  ['I487', 'PV', 'SCALDASOLE'],
  ['I489', 'CS', 'SCALEA'],
  ['I490', 'TO', 'SCALENGHE'],
  ['I492', 'ME', 'SCALETTA ZANCLEA'],
  ['I493', 'AV', 'SCAMPITELLA'],
  ['I494', 'KR', 'SCANDALE'],
  ['I496', 'RE', 'SCANDIANO'],
  ['B962', 'FI', 'SCANDICCI'],
  ['I497', 'CR', 'SCANDOLARA RAVARA'],
  ['I498', 'CR', 'SCANDOLARA RIPA D\'OGLIO'],
  ['I499', 'RI', 'SCANDRIGLIA'],
  ['I501', 'AQ', 'SCANNO'],
  ['I503', 'OR', 'SCANO DI MONTIFERRO'],
  ['I504', 'GR', 'SCANSANO'],
  ['M256', 'MT', 'SCANZANO JONICO'],
  ['I506', 'BG', 'SCANZOROSCIATE'],
  ['I507', 'IS', 'SCAPOLI'],
  ['I510', 'GR', 'SCARLINO'],
  ['I511', 'TO', 'SCARMAGNO'],
  ['I512', 'CN', 'SCARNAFIGI'],
  ['I514', 'FI', 'SCARPERIA'],
  ['I519', 'BZ', 'SCENA'],
  ['I520', 'CH', 'SCERNI'],
  ['I522', 'PG', 'SCHEGGIA E PASCELUPO'],
  ['I523', 'PG', 'SCHEGGINO'],
  ['I526', 'CH', 'SCHIAVI DI ABRUZZO'],
  ['I527', 'VI', 'SCHIAVON'],
  ['I529', 'CO', 'SCHIGNANO'],
  ['I530', 'BG', 'SCHILPARIO'],
  ['I531', 'VI', 'SCHIO'],
  ['I532', 'MN', 'SCHIVENOGLIA'],
  ['I533', 'AG', 'SCIACCA'],
  ['I534', 'PA', 'SCIARA'],
  ['I535', 'RG', 'SCICLI'],
  ['I536', 'RC', 'SCIDO'],
  ['D290', 'CS', 'SCIGLIANO'],
  ['I537', 'RC', 'SCILLA'],
  ['I538', 'PA', 'SCILLATO'],
  ['I539', 'TO', 'SCIOLZE'],
  ['I540', 'NA', 'SCISCIANO'],
  ['I541', 'PA', 'SCLAFANI BAGNI'],
  ['I543', 'AQ', 'SCONTRONE'],
  ['I544', 'VC', 'SCOPA'],
  ['I545', 'VC', 'SCOPELLO'],
  ['I546', 'AQ', 'SCOPPITO'],
  ['I548', 'CT', 'SCORDIA'],
  ['I549', 'LE', 'SCORRANO'],
  ['I551', 'VE', 'SCORZE\''],
  ['I553', 'AQ', 'SCURCOLA MARSICANA'],
  ['I554', 'TN', 'SCURELLE'],
  ['I555', 'AT', 'SCURZOLENGO'],
  ['I556', 'IM', 'SEBORGA'],
  ['I558', 'AQ', 'SECINARO'],
  ['I559', 'LE', 'SECLI\''],
  ['I561', 'LO', 'SECUGNAGO'],
  ['I562', 'UD', 'SEDEGLIANO'],
  ['I563', 'BL', 'SEDICO'],
  ['I564', 'OR', 'SEDILO'],
  ['I565', 'SS', 'SEDINI'],
  ['I566', 'MI', 'SEDRIANO'],
  ['I567', 'BG', 'SEDRINA'],
  ['I569', 'MC', 'SEFRO'],
  ['I570', 'CA', 'SEGARIU'],
  ['I571', 'GR', 'SEGGIANO'],
  ['I573', 'RM', 'SEGNI'],
  ['I576', 'TN', 'SEGONZANO'],
  ['I577', 'MI', 'SEGRATE'],
  ['I578', 'TV', 'SEGUSINO'],
  ['Z342', 'EE', 'SEICELLE'],
  ['I580', 'CA', 'SELARGIUS'],
  ['I581', 'RI', 'SELCI'],
  ['I582', 'CA', 'SELEGAS'],
  ['I585', 'PG', 'SELLANO'],
  ['I588', 'BS', 'SELLERO'],
  ['I589', 'CZ', 'SELLIA'],
  ['I590', 'CZ', 'SELLIA MARINA'],
  ['I593', 'BZ', 'SELVA DEI MOLINI'],
  ['I592', 'BL', 'SELVA DI CADORE'],
  ['I594', 'VR', 'SELVA DI PROGNO'],
  ['I591', 'BZ', 'SELVA DI VAL GARDENA'],
  ['I595', 'PD', 'SELVAZZANO DENTRO'],
  ['I596', 'BI', 'SELVE MARCONE'],
  ['I597', 'BG', 'SELVINO'],
  ['I598', 'SS', 'SEMESTENE'],
  ['I599', 'PV', 'SEMIANA'],
  ['I600', 'RC', 'SEMINARA'],
  ['I601', 'GR', 'SEMPRONIANO'],
  ['I602', 'MI', 'SENAGO'],
  ['I603', 'BZ', 'SENALE-SAN FELICE'],
  ['H837', 'BZ', 'SAN FELICE'],
  ['I604', 'BZ', 'SENALES'],
  ['Z343', 'EE', 'SENEGAL'],
  ['I605', 'OR', 'SENEGHE'],
  ['I606', 'AV', 'SENERCHIA'],
  ['I607', 'BS', 'SENIGA'],
  ['I608', 'AN', 'SENIGALLIA'],
  ['I609', 'OR', 'SENIS'],
  ['I610', 'PZ', 'SENISE'],
  ['I611', 'CO', 'SENNA COMASCO'],
  ['I612', 'LO', 'SENNA LODIGIANA'],
  ['I613', 'OR', 'SENNARIOLO'],
  ['I614', 'SS', 'SENNORI'],
  ['I615', 'CA', 'SENORBI\''],
  ['I618', 'CB', 'SEPINO'],
  ['I619', 'VB', 'SEPPIANA'],
  ['I621', 'PN', 'SEQUALS'],
  ['I622', 'LU', 'SERAVEZZA'],
  ['I624', 'CA', 'SERDIANA'],
  ['Z158', 'EE', 'SERBIA'],
  ['I625', 'MB', 'SEREGNO'],
  ['I625', 'MI', 'SEREGNO'],
  ['I626', 'BL', 'SEREN DEL GRAPPA'],
  ['I627', 'CR', 'SERGNANO'],
  ['I628', 'BG', 'SERIATE'],
  ['I629', 'BG', 'SERINA'],
  ['I630', 'AV', 'SERINO'],
  ['I631', 'BS', 'SERLE'],
  ['I632', 'MN', 'SERMIDE'],
  ['I634', 'LT', 'SERMONETA'],
  ['I635', 'TV', 'SERNAGLIA DELLA BATTAGLIA'],
  ['I636', 'SO', 'SERNIO'],
  ['I637', 'AT', 'SEROLE'],
  ['I642', 'CS', 'SERRA D\'AIELLO'],
  ['I643', 'AN', 'SERRA DE\' CONTI'],
  ['I650', 'CS', 'SERRA PEDACE'],
  ['I640', 'GE', 'SERRA RICCO\''],
  ['I639', 'VV', 'SERRA SAN BRUNO'],
  ['I653', 'AN', 'SERRA SAN QUIRICO'],
  ['I654', 'PU', 'SERRA SANT\'ABBONDIO'],
  ['I641', 'FG', 'SERRACAPRIOLA'],
  ['I644', 'CL', 'SERRADIFALCO'],
  ['I646', 'CN', 'SERRALUNGA D\'ALBA'],
  ['I645', 'AL', 'SERRALUNGA DI CREA'],
  ['I647', 'CA', 'SERRAMANNA'],
  ['F357', 'MO', 'SERRAMAZZONI'],
  ['I648', 'SA', 'SERRAMEZZANA'],
  ['I649', 'PE', 'SERRAMONACESCA'],
  ['I651', 'MC', 'SERRAPETRONA'],
  ['I652', 'NA', 'SERRARA FONTANA'],
  ['I655', 'CZ', 'SERRASTRETTA'],
  ['I656', 'RC', 'SERRATA'],
  ['I662', 'MN', 'SERRAVALLE A PO'],
  ['I661', 'MC', 'SERRAVALLE DI CHIENTI'],
  ['I659', 'CN', 'SERRAVALLE LANGHE'],
  ['I660', 'PT', 'SERRAVALLE PISTOIESE'],
  ['I657', 'AL', 'SERRAVALLE SCRIVIA'],
  ['I663', 'VC', 'SERRAVALLE SESIA'],
  ['I666', 'SA', 'SERRE'],
  ['I667', 'CA', 'SERRENTI'],
  ['I668', 'NU', 'SERRI'],
  ['I669', 'FR', 'SERRONE'],
  ['I670', 'PU', 'SERRUNGARINA'],
  ['I671', 'CZ', 'SERSALE'],
  ['C070', 'FM', 'SERVIGLIANO'],
  ['C070', 'AP', 'SERVIGLIANO'],
  ['I676', 'CE', 'SESSA AURUNCA'],
  ['I677', 'SA', 'SESSA CILENTO'],
  ['I678', 'AT', 'SESSAME'],
  ['I679', 'IS', 'SESSANO DEL MOLISE'],
  ['E070', 'SP', 'SESTA GODANO'],
  ['I681', 'AR', 'SESTINO'],
  ['I687', 'BZ', 'SESTO'],
  ['I686', 'PN', 'SESTO AL REGHENA'],
  ['I688', 'VA', 'SESTO CALENDE'],
  ['I682', 'IS', 'SESTO CAMPANO'],
  ['I683', 'CR', 'SESTO ED UNITI'],
  ['I684', 'FI', 'SESTO FIORENTINO'],
  ['I690', 'MI', 'SESTO SAN GIOVANNI'],
  ['I689', 'MO', 'SESTOLA'],
  ['I693', 'GE', 'SESTRI LEVANTE'],
  ['I692', 'TO', 'SESTRIERE'],
  ['I695', 'CA', 'SESTU'],
  ['I696', 'MI', 'SETTALA'],
  ['I697', 'FR', 'SETTEFRATI'],
  ['I698', 'AT', 'SETTIME'],
  ['I700', 'MI', 'SETTIMO MILANESE'],
  ['I701', 'TO', 'SETTIMO ROTTARO'],
  ['I699', 'CA', 'SETTIMO SAN PIETRO'],
  ['I703', 'TO', 'SETTIMO TORINESE'],
  ['I702', 'TO', 'SETTIMO VITTONE'],
  ['I704', 'CZ', 'SETTINGIANO'],
  ['I705', 'CA', 'SETZU'],
  ['I706', 'NU', 'SEUI'],
  ['I707', 'NU', 'SEULO'],
  ['I709', 'MB', 'SEVESO'],
  ['I709', 'MI', 'SEVESO'],
  ['I711', 'AL', 'SEZZADIO'],
  ['I712', 'LT', 'SEZZE'],
  ['I714', 'TN', 'SFRUZ'],
  ['I715', 'TS', 'SGONICO'],
  ['I716', 'FR', 'SGURGOLA'],
  ['I717', 'OR', 'SIAMAGGIORE'],
  ['I718', 'OR', 'SIAMANNA'],
  ['I720', 'SA', 'SIANO'],
  ['I721', 'OR', 'SIAPICCIA'],
  ['M253', 'SA', 'SICIGNANO DEGLI ALBURNI'],
  ['I723', 'AG', 'SICULIANA'],
  ['I724', 'CA', 'SIDDI'],
  ['I725', 'RC', 'SIDERNO'],
  ['I726', 'SI', 'SIENA'],
  ['Z344', 'EE', 'SIERRA LEONE'],
  ['I727', 'PG', 'SIGILLO'],
  ['I728', 'FI', 'SIGNA'],
  ['I729', 'BZ', 'SILANDRO'],
  ['I730', 'NU', 'SILANUS'],
  ['F116', 'TV', 'SILEA'],
  ['I732', 'SS', 'SILIGO'],
  ['I734', 'CA', 'SILIQUA'],
  ['I735', 'CA', 'SILIUS'],
  ['I737', 'LU', 'SILLANO'],
  ['I736', 'NO', 'SILLAVENGO'],
  ['I738', 'AL', 'SILVANO D\'ORBA'],
  ['I739', 'PV', 'SILVANO PIETRA'],
  ['I741', 'TE', 'SILVI'],
  ['I742', 'OR', 'SIMALA'],
  ['I743', 'OR', 'SIMAXIS'],
  ['I744', 'VV', 'SIMBARIO'],
  ['I745', 'CZ', 'SIMERI CRICHI'],
  ['I747', 'ME', 'SINAGRA'],
  ['A468', 'SI', 'SINALUNGA'],
  ['I748', 'NU', 'SINDIA'],
  ['Z248', 'EE', 'SINGAPORE'],
  ['I749', 'OR', 'SINI'],
  ['I750', 'CN', 'SINIO'],
  ['I751', 'NU', 'SINISCOLA'],
  ['I752', 'CA', 'SINNAI'],
  ['I753', 'RC', 'SINOPOLI'],
  ['I754', 'SR', 'SIRACUSA'],
  ['Z240', 'EE', 'SIRIA'],
  ['I756', 'AV', 'SIRIGNANO'],
  ['I757', 'OR', 'SIRIS'],
  ['I633', 'BS', 'SIRMIONE'],
  ['I758', 'AN', 'SIROLO'],
  ['I759', 'LC', 'SIRONE'],
  ['I760', 'TN', 'SIROR'],
  ['I761', 'LC', 'SIRTORI'],
  ['I763', 'PR', 'SISSA'],
  ['I765', 'CA', 'SIURGUS DONIGALA'],
  ['E265', 'PV', 'SIZIANO'],
  ['I767', 'NO', 'SIZZANO'],
  ['Z155', 'EE', 'SLOVACCHIA'],
  ['Z150', 'EE', 'SLOVENIA'],
  ['I771', 'BZ', 'SLUDERNO'],
  ['I772', 'TN', 'SMARANO'],
  ['I774', 'FM', 'SMERILLO'],
  ['I774', 'AP', 'SMERILLO'],
  ['I775', 'VR', 'SOAVE'],
  ['I777', 'UD', 'SOCCHIEVE'],
  ['I778', 'OR', 'SODDI\''],
  ['I779', 'FC', 'SOGLIANO AL RUBICONE'],
  ['I780', 'LE', 'SOGLIANO CAVOUR'],
  ['I781', 'AT', 'SOGLIO'],
  ['I782', 'BS', 'SOIANO DEL LAGO'],
  ['I783', 'VI', 'SOLAGNA'],
  ['I785', 'SR', 'SOLARINO'],
  ['I786', 'MI', 'SOLARO'],
  ['I787', 'RA', 'SOLAROLO'],
  ['I790', 'CR', 'SOLAROLO RAINERIO'],
  ['I791', 'OR', 'SOLARUSSA'],
  ['I792', 'CO', 'SOLBIATE'],
  ['I793', 'VA', 'SOLBIATE ARNO'],
  ['I794', 'VA', 'SOLBIATE OLONA'],
  ['I796', 'IM', 'SOLDANO'],
  ['I797', 'CA', 'SOLEMINIS'],
  ['I798', 'AL', 'SOLERO'],
  ['I799', 'PD', 'SOLESINO'],
  ['I800', 'LE', 'SOLETO'],
  ['I801', 'MN', 'SOLFERINO'],
  ['I802', 'MO', 'SOLIERA'],
  ['I803', 'PR', 'SOLIGNANO'],
  ['I805', 'AV', 'SOLOFRA'],
  ['I808', 'AL', 'SOLONGHELLO'],
  ['I809', 'BN', 'SOLOPACA'],
  ['I812', 'BG', 'SOLTO COLLINA'],
  ['I813', 'BG', 'SOLZA'],
  ['I815', 'LO', 'SOMAGLIA'],
  ['Z345', 'EE', 'SOMALIA'],
  ['I817', 'CN', 'SOMANO'],
  ['I819', 'VA', 'SOMMA LOMBARDO'],
  ['I820', 'NA', 'SOMMA VESUVIANA'],
  ['I821', 'VR', 'SOMMACAMPAGNA'],
  ['I822', 'CN', 'SOMMARIVA DEL BOSCO'],
  ['I823', 'CN', 'SOMMARIVA PERNO'],
  ['I824', 'CL', 'SOMMATINO'],
  ['I825', 'PV', 'SOMMO'],
  ['I826', 'VR', 'SONA'],
  ['I827', 'CR', 'SONCINO'],
  ['I828', 'SO', 'SONDALO'],
  ['I829', 'SO', 'SONDRIO'],
  ['I830', 'BG', 'SONGAVAZZO'],
  ['I831', 'BS', 'SONICO'],
  ['I832', 'LT', 'SONNINO'],
  ['I835', 'BI', 'SOPRANA'],
  ['I838', 'FR', 'SORA'],
  ['I839', 'TN', 'SORAGA'],
  ['I840', 'PR', 'SORAGNA'],
  ['I841', 'GR', 'SORANO'],
  ['I844', 'CZ', 'SORBO SAN BASILE'],
  ['I843', 'AV', 'SORBO SERPICO'],
  ['I845', 'PR', 'SORBOLO'],
  ['I847', 'BI', 'SORDEVOLO'],
  ['I848', 'LO', 'SORDIO'],
  ['I849', 'CR', 'SORESINA'],
  ['I850', 'VR', 'SORGA\''],
  ['I851', 'NU', 'SORGONO'],
  ['I852', 'GE', 'SORI'],
  ['I853', 'VV', 'SORIANELLO'],
  ['I854', 'VV', 'SORIANO CALABRO'],
  ['I855', 'VT', 'SORIANO NEL CIMINO'],
  ['I856', 'CO', 'SORICO'],
  ['I857', 'NO', 'SORISO'],
  ['I858', 'BG', 'SORISOLE'],
  ['I860', 'CO', 'SORMANO'],
  ['I861', 'OR', 'SORRADILE'],
  ['I862', 'NA', 'SORRENTO'],
  ['I863', 'SS', 'SORSO'],
  ['I864', 'SR', 'SORTINO'],
  ['I865', 'CR', 'SOSPIRO'],
  ['I866', 'BL', 'SOSPIROLO'],
  ['I867', 'VI', 'SOSSANO'],
  ['I868', 'BI', 'SOSTEGNO'],
  ['I869', 'BG', 'SOTTO IL MONTE GIOVANNI XXIII'],
  ['I871', 'TN', 'SOVER'],
  ['I872', 'CZ', 'SOVERATO'],
  ['I873', 'BG', 'SOVERE'],
  ['I874', 'CZ', 'SOVERIA MANNELLI'],
  ['I875', 'CZ', 'SOVERIA SIMERI'],
  ['I876', 'BL', 'SOVERZENE'],
  ['I877', 'SI', 'SOVICILLE'],
  ['I878', 'MB', 'SOVICO'],
  ['I878', 'MI', 'SOVICO'],
  ['I879', 'VI', 'SOVIZZO'],
  ['I673', 'BL', 'SOVRAMONTE'],
  ['I880', 'NO', 'SOZZAGO'],
  ['I881', 'ME', 'SPADAFORA'],
  ['I884', 'VV', 'SPADOLA'],
  ['Z131', 'EE', 'SPAGNA'],
  ['I885', 'CE', 'SPARANISE'],
  ['I886', 'TO', 'SPARONE'],
  ['I887', 'LE', 'SPECCHIA'],
  ['I888', 'PG', 'SPELLO'],
  ['I889', 'TN', 'SPERA'],
  ['I891', 'EN', 'SPERLINGA'],
  ['I892', 'LT', 'SPERLONGA'],
  ['I893', 'AV', 'SPERONE'],
  ['I894', 'PV', 'SPESSA'],
  ['I895', 'CS', 'SPEZZANO ALBANESE'],
  ['I896', 'CS', 'SPEZZANO DELLA SILA'],
  ['I898', 'CS', 'SPEZZANO PICCOLO'],
  ['I899', 'TN', 'SPIAZZO'],
  ['I901', 'AL', 'SPIGNO MONFERRATO'],
  ['I902', 'LT', 'SPIGNO SATURNIA'],
  ['I903', 'MO', 'SPILAMBERTO'],
  ['I904', 'PN', 'SPILIMBERGO'],
  ['I905', 'VV', 'SPILINGA'],
  ['I906', 'CR', 'SPINADESCO'],
  ['I907', 'BA', 'SPINAZZOLA'],
  ['I907', 'BT', 'SPINAZZOLA'],
  ['I908', 'VE', 'SPINEA'],
  ['I909', 'CR', 'SPINEDA'],
  ['I910', 'CB', 'SPINETE'],
  ['I911', 'AL', 'SPINETO SCRIVIA'],
  ['I912', 'AP', 'SPINETOLI'],
  ['I914', 'CR', 'SPINO D\'ADDA'],
  ['I916', 'BG', 'SPINONE AL LAGO'],
  ['I917', 'PZ', 'SPINOSO'],
  ['I919', 'BG', 'SPIRANO'],
  ['I921', 'PG', 'SPOLETO'],
  ['I922', 'PE', 'SPOLTORE'],
  ['I923', 'LE', 'SPONGANO'],
  ['I924', 'TN', 'SPORMAGGIORE'],
  ['I925', 'TN', 'SPORMINORE'],
  ['I926', 'SV', 'SPOTORNO'],
  ['I927', 'TV', 'SPRESIANO'],
  ['I928', 'SO', 'SPRIANA'],
  ['I929', 'CZ', 'SQUILLACE'],
  ['I930', 'LE', 'SQUINZANO'],
  ['Z209', 'EE', 'SRI LANKA'],
  ['I932', 'AN', 'STAFFOLO'],
  ['I935', 'CR', 'STAGNO LOMBARDO'],
  ['I936', 'RC', 'STAITI'],
  ['I937', 'CZ', 'STALETTI'],
  ['I938', 'PD', 'STANGHELLA'],
  ['I939', 'GO', 'STARANZANO'],
  ['Z404', 'EE', 'STATI UNITI D\'AMERICA'],
  ['M298', 'TA', 'STATTE'],
  ['I941', 'AL', 'STAZZANO'],
  ['I942', 'LU', 'STAZZEMA'],
  ['I943', 'CO', 'STAZZONA'],
  ['I945', 'VV', 'STEFANACONI'],
  ['I946', 'SV', 'STELLA'],
  ['G887', 'SA', 'STELLA CILENTO'],
  ['I947', 'SV', 'STELLANELLO'],
  ['I948', 'BZ', 'STELVIO'],
  ['I949', 'TN', 'STENICO'],
  ['I950', 'LE', 'STERNATIA'],
  ['I951', 'BG', 'STEZZANO'],
  ['I952', 'AR', 'STIA'],
  ['I953', 'RO', 'STIENTA'],
  ['I954', 'MT', 'STIGLIANO'],
  ['I955', 'RC', 'STIGNANO'],
  ['I956', 'RC', 'STILO'],
  ['I959', 'RI', 'STIMIGLIANO'],
  ['M290', 'SS', 'STINTINO'],
  ['I960', 'SA', 'STIO'],
  ['I962', 'FG', 'STORNARA'],
  ['I963', 'FG', 'STORNARELLA'],
  ['I964', 'TN', 'STORO'],
  ['I965', 'VE', 'STRA'],
  ['I968', 'PV', 'STRADELLA'],
  ['I969', 'TO', 'STRAMBINELLO'],
  ['I970', 'TO', 'STRAMBINO'],
  ['I973', 'FR', 'STRANGOLAGALLI'],
  ['I974', 'UD', 'STREGNA'],
  ['I975', 'TN', 'STREMBO'],
  ['I976', 'VB', 'STRESA'],
  ['I977', 'AL', 'STREVI'],
  ['I978', 'NA', 'STRIANO'],
  ['I979', 'TN', 'STRIGNO'],
  ['I980', 'BI', 'STRONA'],
  ['I981', 'TR', 'STRONCONE'],
  ['I982', 'KR', 'STRONGOLI'],
  ['I984', 'VC', 'STROPPIANA'],
  ['I985', 'CN', 'STROPPO'],
  ['I986', 'BG', 'STROZZA'],
  ['I990', 'AV', 'STURNO'],
  ['B014', 'PV', 'SUARDI'],
  ['I991', 'AR', 'SUBBIANO'],
  ['I992', 'RM', 'SUBIACO'],
  ['I993', 'CE', 'SUCCIVO'],
  ['Z907', 'EE', 'SUD SUDAN'],
  ['Z347', 'EE', 'SUDAFRICANA REPUBBLICA'],
  ['Z348', 'EE', 'SUDAN'],
  ['I994', 'LC', 'SUEGLIO'],
  ['I995', 'CA', 'SUELLI'],
  ['I996', 'LC', 'SUELLO'],
  ['I997', 'BG', 'SUISIO'],
  ['I998', 'MB', 'SULBIATE'],
  ['I998', 'MI', 'SULBIATE'],
  ['I804', 'AQ', 'SULMONA'],
  ['L002', 'BS', 'SULZANO'],
  ['L003', 'VA', 'SUMIRAGO'],
  ['L004', 'AV', 'SUMMONTE'],
  ['L006', 'NU', 'SUNI'],
  ['L007', 'NO', 'SUNO'],
  ['L008', 'LE', 'SUPERSANO'],
  ['L009', 'FR', 'SUPINO'],
  ['L010', 'LE', 'SURANO'],
  ['L011', 'LE', 'SURBO'],
  ['Z608', 'EE', 'SURINAME'],
  ['L013', 'TO', 'SUSA'],
  ['L014', 'TV', 'SUSEGANA'],
  ['L015', 'MN', 'SUSTINENTE'],
  ['L016', 'CL', 'SUTERA'],
  ['L017', 'VT', 'SUTRI'],
  ['L018', 'UD', 'SUTRIO'],
  ['L019', 'LI', 'SUVERETO'],
  ['L020', 'MN', 'SUZZARA'],
  ['Z132', 'EE', 'SVEZIA'],
  ['Z133', 'EE', 'SVIZZERA'],
  ['Z349', 'EE', 'SWAZILAND'],
  ['L022', 'LC', 'TACENO'],
  ['L023', 'OR', 'TADASUNI'],
  ['L024', 'IM', 'TAGGIA'],
  ['Z257', 'EE', 'TAGIKISTAN'],
  ['L025', 'AQ', 'TAGLIACOZZO'],
  ['L026', 'RO', 'TAGLIO DI PO'],
  ['L027', 'AL', 'TAGLIOLO MONFERRATO'],
  ['L030', 'BL', 'TAIBON AGORDINO'],
  ['L032', 'VA', 'TAINO'],
  ['L033', 'TN', 'TAIO'],
  ['G736', 'UD', 'TAIPANA'],
  ['Z217', 'EE', 'TAIWAN'],
  ['L034', 'PU', 'TALAMELLO'],
  ['L035', 'SO', 'TALAMONA'],
  ['L036', 'NU', 'TALANA'],
  ['L037', 'BG', 'TALEGGIO'],
  ['L038', 'AR', 'TALLA'],
  ['L039', 'UD', 'TALMASSONS'],
  ['L040', 'BL', 'TAMBRE'],
  ['Z357', 'EE', 'TANZANIA'],
  ['L042', 'ME', 'TAORMINA'],
  ['L044', 'UD', 'TAPOGLIANO'],
  ['L046', 'RI', 'TARANO'],
  ['L047', 'CH', 'TARANTA PELIGNA'],
  ['L048', 'CN', 'TARANTASCA'],
  ['L049', 'TA', 'TARANTO'],
  ['L050', 'UD', 'TARCENTO'],
  ['D024', 'VT', 'TARQUINIA'],
  ['L055', 'CS', 'TARSIA'],
  ['L056', 'SO', 'TARTANO'],
  ['L057', 'UD', 'TARVISIO'],
  ['L058', 'TV', 'TARZO'],
  ['L059', 'AL', 'TASSAROLO'],
  ['L060', 'TN', 'TASSULLO'],
  ['L061', 'AV', 'TAURANO'],
  ['L062', 'AV', 'TAURASI'],
  ['L063', 'RC', 'TAURIANOVA'],
  ['L064', 'LE', 'TAURISANO'],
  ['L065', 'UD', 'TAVAGNACCO'],
  ['L066', 'TO', 'TAVAGNASCO'],
  ['L067', 'FI', 'TAVARNELLE VAL DI PESA'],
  ['F260', 'LO', 'TAVAZZANO CON VILLAVESCO'],
  ['L069', 'CB', 'TAVENNA'],
  ['L070', 'CZ', 'TAVERNA'],
  ['L071', 'CO', 'TAVERNERIO'],
  ['L073', 'BG', 'TAVERNOLA BERGAMASCA'],
  ['C698', 'BS', 'TAVERNOLE SUL MELLA'],
  ['L074', 'LE', 'TAVIANO'],
  ['L075', 'BI', 'TAVIGLIANO'],
  ['L078', 'PU', 'TAVOLETO'],
  ['L081', 'PU', 'TAVULLIA'],
  ['L082', 'PZ', 'TEANA'],
  ['L083', 'CE', 'TEANO'],
  ['D292', 'SA', 'TEGGIANO'],
  ['L084', 'SO', 'TEGLIO'],
  ['L085', 'VE', 'TEGLIO VENETO'],
  ['L086', 'BN', 'TELESE TERME'],
  ['L087', 'BG', 'TELGATE'],
  ['L088', 'SS', 'TELTI'],
  ['L089', 'TN', 'TELVE'],
  ['L090', 'TN', 'TELVE DI SOPRA'],
  ['L093', 'SS', 'TEMPIO PAUSANIA'],
  ['L094', 'BS', 'TEMU\''],
  ['L096', 'TN', 'TENNA'],
  ['L097', 'TN', 'TENNO'],
  ['L100', 'PD', 'TEOLO'],
  ['L101', 'UD', 'TEOR'],
  ['L102', 'AV', 'TEORA'],
  ['L103', 'TE', 'TERAMO'],
  ['L104', 'NO', 'TERDOBBIATE'],
  ['L105', 'FR', 'TERELLE'],
  ['L106', 'BZ', 'TERENTO'],
  ['E548', 'PR', 'TERENZO'],
  ['M282', 'SS', 'TERGU'],
  ['L107', 'TN', 'TERLAGO'],
  ['L108', 'BZ', 'TERLANO'],
  ['L109', 'BA', 'TERLIZZI'],
  ['M210', 'ME', 'TERME VIGLIATORE'],
  ['L111', 'BZ', 'TERMENO SULLA STRADA DEL VINO'],
  ['L112', 'PA', 'TERMINI IMERESE'],
  ['L113', 'CB', 'TERMOLI'],
  ['L115', 'VA', 'TERNATE'],
  ['L116', 'BI', 'TERNENGO'],
  ['L117', 'TR', 'TERNI'],
  ['L118', 'BG', 'TERNO D\'ISOLA'],
  ['L120', 'LT', 'TERRACINA'],
  ['L121', 'TN', 'TERRAGNOLO'],
  ['L122', 'OR', 'TERRALBA'],
  ['L124', 'CS', 'TERRANOVA DA SIBARI'],
  ['L125', 'LO', 'TERRANOVA DEI PASSERINI'],
  ['L126', 'PZ', 'TERRANOVA DI POLLINO'],
  ['L127', 'RC', 'TERRANOVA SAPPO MINULIO'],
  ['L123', 'AR', 'TERRANUOVA BRACCIOLINI'],
  ['L131', 'PA', 'TERRASINI'],
  ['L132', 'PD', 'TERRASSA PADOVANA'],
  ['L134', 'CS', 'TERRAVECCHIA'],
  ['L136', 'VR', 'TERRAZZO'],
  ['L137', 'TN', 'TERRES'],
  ['L138', 'PI', 'TERRICCIOLA'],
  ['Z161', 'EE', 'TERRITORI PALESTINESI'],
  ['L139', 'AL', 'TERRUGGIA'],
  ['L140', 'NU', 'TERTENIA'],
  ['L142', 'NA', 'TERZIGNO'],
  ['L143', 'AL', 'TERZO'],
  ['L144', 'UD', 'TERZO DI AQUILEIA'],
  ['L145', 'TN', 'TERZOLAS'],
  ['L146', 'IM', 'TERZORIO'],
  ['L147', 'TN', 'TESERO'],
  ['L149', 'BZ', 'TESIMO'],
  ['L150', 'VT', 'TESSENNANO'],
  ['L152', 'SV', 'TESTICO'],
  ['L153', 'NU', 'TETI'],
  ['L154', 'CA', 'TEULADA'],
  ['L155', 'CE', 'TEVEROLA'],
  ['L156', 'VI', 'TEZZE SUL BRENTA'],
  ['Z241', 'EE', 'THAILANDIA'],
  ['L157', 'VI', 'THIENE'],
  ['L158', 'SS', 'THIESI'],
  ['L160', 'NU', 'TIANA'],
  ['L162', 'TN', 'TIARNO DI SOPRA'],
  ['L163', 'TN', 'TIARNO DI SOTTO'],
  ['L164', 'CR', 'TICENGO'],
  ['L165', 'AL', 'TICINETO'],
  ['L166', 'LE', 'TIGGIANO'],
  ['L167', 'GE', 'TIGLIETO'],
  ['L168', 'AT', 'TIGLIOLE'],
  ['L169', 'BS', 'TIGNALE'],
  ['L172', 'NU', 'TINNURA'],
  ['L173', 'AQ', 'TIONE DEGLI ABRUZZI'],
  ['L174', 'TN', 'TIONE DI TRENTO'],
  ['L175', 'SO', 'TIRANO'],
  ['L176', 'BZ', 'TIRES'],
  ['L177', 'CZ', 'TIRIOLO'],
  ['L178', 'BZ', 'TIROLO'],
  ['L180', 'SS', 'TISSI'],
  ['L181', 'PZ', 'TITO'],
  ['L182', 'RM', 'TIVOLI'],
  ['L183', 'PR', 'TIZZANO VAL PARMA'],
  ['L184', 'RE', 'TOANO'],
  ['L185', 'BN', 'TOCCO CAUDIO'],
  ['L186', 'PE', 'TOCCO DA CASAURIA'],
  ['L187', 'VB', 'TOCENO'],
  ['L188', 'PG', 'TODI'],
  ['L189', 'RI', 'TOFFIA'],
  ['Z351', 'EE', 'TOGO'],
  ['L190', 'SV', 'TOIRANO'],
  ['L191', 'MC', 'TOLENTINO'],
  ['L192', 'RM', 'TOLFA'],
  ['L193', 'BI', 'TOLLEGNO'],
  ['L194', 'CH', 'TOLLO'],
  ['L195', 'UD', 'TOLMEZZO'],
  ['L197', 'PZ', 'TOLVE'],
  ['L199', 'PD', 'TOMBOLO'],
  ['L200', 'TN', 'TON'],
  ['L201', 'TN', 'TONADICO'],
  ['L202', 'NU', 'TONARA'],
  ['L203', 'AT', 'TONCO'],
  ['L204', 'AT', 'TONENGO'],
  ['D717', 'VI', 'TONEZZA DEL CIMONE'],
  ['Z728', 'EE', 'TONGA'],
  ['L205', 'CE', 'TORA E PICCILLI'],
  ['L206', 'CS', 'TORANO CASTELLO'],
  ['L207', 'TE', 'TORANO NUOVO'],
  ['L210', 'BS', 'TORBOLE CASAGLIA'],
  ['L211', 'TN', 'TORCEGNO'],
  ['L212', 'SA', 'TORCHIARA'],
  ['L213', 'BR', 'TORCHIAROLO'],
  ['L214', 'AV', 'TORELLA DEI LOMBARDI'],
  ['L215', 'CB', 'TORELLA DEL SANNIO'],
  ['L216', 'PG', 'TORGIANO'],
  ['L217', 'AO', 'TORGNON'],
  ['L219', 'TO', 'TORINO'],
  ['L218', 'CH', 'TORINO DI SANGRO'],
  ['L220', 'BA', 'TORITTO'],
  ['L221', 'CR', 'TORLINO VIMERCATI'],
  ['L223', 'NO', 'TORNACO'],
  ['L224', 'CH', 'TORNARECCIO'],
  ['L225', 'CR', 'TORNATA'],
  ['L227', 'AQ', 'TORNIMPARTE'],
  ['L228', 'CO', 'TORNO'],
  ['L229', 'PR', 'TORNOLO'],
  ['L230', 'CB', 'TORO'],
  ['L231', 'NU', 'TORPE\''],
  ['L233', 'SA', 'TORRACA'],
  ['L235', 'SS', 'TORRALBA'],
  ['L237', 'PV', 'TORRAZZA COSTE'],
  ['L238', 'TO', 'TORRAZZA PIEMONTE'],
  ['L239', 'BI', 'TORRAZZO'],
  ['L245', 'NA', 'TORRE ANNUNZIATA'],
  ['L250', 'PV', 'TORRE BERETTI E CASTELLARO'],
  ['L251', 'BG', 'TORRE BOLDONE'],
  ['L252', 'CN', 'TORRE BORMIDA'],
  ['L243', 'FR', 'TORRE CAJETANI'],
  ['L247', 'TO', 'TORRE CANAVESE'],
  ['L256', 'PV', 'TORRE D\'ARESE'],
  ['L269', 'PV', 'TORRE D\'ISOLA'],
  ['L257', 'LC', 'TORRE DE\' BUSI'],
  ['L262', 'PV', 'TORRE DE\' NEGRI'],
  ['L263', 'PE', 'TORRE DE\' PASSERI'],
  ['L258', 'CR', 'TORRE DE\' PICENARDI'],
  ['L265', 'BG', 'TORRE DE\' ROVERI'],
  ['L259', 'NA', 'TORRE DEL GRECO'],
  ['L267', 'VE', 'TORRE DI MOSTO'],
  ['L240', 'CZ', 'TORRE DI RUGGIERO'],
  ['L244', 'SO', 'TORRE DI SANTA MARIA'],
  ['L272', 'AV', 'TORRE LE NOCELLE'],
  ['L241', 'CN', 'TORRE MONDOVI\''],
  ['L274', 'SA', 'TORRE ORSAIA'],
  ['L276', 'BG', 'TORRE PALLAVICINA'],
  ['L277', 'TO', 'TORRE PELLICE'],
  ['L278', 'CN', 'TORRE SAN GIORGIO'],
  ['L279', 'FM', 'TORRE SAN PATRIZIO'],
  ['L279', 'AP', 'TORRE SAN PATRIZIO'],
  ['L280', 'BR', 'TORRE SANTA SUSANNA'],
  ['L246', 'UD', 'TORREANO'],
  ['L248', 'VI', 'TORREBELVICINO'],
  ['L253', 'CH', 'TORREBRUNA'],
  ['L254', 'BN', 'TORRECUSO'],
  ['L270', 'PD', 'TORREGLIA'],
  ['L271', 'ME', 'TORREGROTTA'],
  ['L273', 'FG', 'TORREMAGGIORE'],
  ['M286', 'ME', 'TORRENOVA'],
  ['L281', 'CN', 'TORRESINA'],
  ['L282', 'PA', 'TORRETTA'],
  ['L285', 'PV', 'TORREVECCHIA PIA'],
  ['L284', 'CH', 'TORREVECCHIA TEATINA'],
  ['L287', 'VR', 'TORRI DEL BENACO'],
  ['L297', 'VI', 'TORRI DI QUARTESOLO'],
  ['L286', 'RI', 'TORRI IN SABINA'],
  ['I550', 'RN', 'TORRIANA'],
  ['L290', 'FR', 'TORRICE'],
  ['L294', 'TA', 'TORRICELLA'],
  ['L296', 'CR', 'TORRICELLA DEL PIZZO'],
  ['L293', 'RI', 'TORRICELLA IN SABINA'],
  ['L291', 'CH', 'TORRICELLA PELIGNA'],
  ['L295', 'TE', 'TORRICELLA SICURA'],
  ['L292', 'PV', 'TORRICELLA VERZATE'],
  ['L298', 'GE', 'TORRIGLIA'],
  ['L299', 'PR', 'TORRILE'],
  ['L301', 'AV', 'TORRIONI'],
  ['L303', 'SI', 'TORRITA DI SIENA'],
  ['L302', 'RM', 'TORRITA TIBERINA'],
  ['A355', 'NU', 'TORTOLI\''],
  ['L304', 'AL', 'TORTONA'],
  ['L305', 'CS', 'TORTORA'],
  ['L306', 'SA', 'TORTORELLA'],
  ['L307', 'TE', 'TORTORETO'],
  ['L308', 'ME', 'TORTORICI'],
  ['L309', 'UD', 'TORVISCOSA'],
  ['L312', 'BS', 'TOSCOLANO-MADERNO'],
  ['L314', 'TE', 'TOSSICIA'],
  ['L316', 'SO', 'TOVO DI SANT\'AGATA'],
  ['L315', 'SV', 'TOVO SAN GIACOMO'],
  ['L317', 'PA', 'TRABIA'],
  ['L319', 'VA', 'TRADATE'],
  ['L321', 'OR', 'TRAMATZA'],
  ['L322', 'TN', 'TRAMBILENO'],
  ['L323', 'SA', 'TRAMONTI'],
  ['L324', 'PN', 'TRAMONTI DI SOPRA'],
  ['L325', 'PN', 'TRAMONTI DI SOTTO'],
  ['L326', 'PZ', 'TRAMUTOLA'],
  ['L327', 'TO', 'TRANA'],
  ['L328', 'BA', 'TRANI'],
  ['L328', 'BT', 'TRANI'],
  ['L329', 'TN', 'TRANSACQUA'],
  ['L330', 'SO', 'TRAONA'],
  ['L331', 'TP', 'TRAPANI'],
  ['L332', 'PA', 'TRAPPETO'],
  ['L333', 'VB', 'TRAREGO VIGGIONA'],
  ['L334', 'AQ', 'TRASACCO'],
  ['L335', 'UD', 'TRASAGHIS'],
  ['L336', 'VB', 'TRASQUERA'],
  ['L337', 'CA', 'TRATALIAS'],
  ['L338', 'TO', 'TRAUSELLA'],
  ['I236', 'PV', 'TRAVACO\' SICCOMARIO'],
  ['L339', 'BS', 'TRAVAGLIATO'],
  ['L342', 'VA', 'TRAVEDONA-MONATE'],
  ['L345', 'TO', 'TRAVERSELLA'],
  ['L346', 'PR', 'TRAVERSETOLO'],
  ['L340', 'TO', 'TRAVES'],
  ['L347', 'PN', 'TRAVESIO'],
  ['L348', 'PC', 'TRAVO'],
  ['L349', 'PD', 'TREBASELEGHE'],
  ['L353', 'CS', 'TREBISACCE'],
  ['L354', 'PR', 'TRECASALI'],
  ['M280', 'NA', 'TRECASE'],
  ['L355', 'CT', 'TRECASTAGNI'],
  ['L356', 'NO', 'TRECATE'],
  ['L357', 'PZ', 'TRECCHINA'],
  ['L359', 'RO', 'TRECENTA'],
  ['L361', 'FC', 'TREDOZIO'],
  ['L363', 'CH', 'TREGLIO'],
  ['L364', 'VR', 'TREGNAGO'],
  ['L366', 'MC', 'TREIA'],
  ['L367', 'CN', 'TREISO'],
  ['L368', 'LC', 'TREMENICO'],
  ['L369', 'CT', 'TREMESTIERI ETNEO'],
  ['L371', 'CO', 'TREMEZZO'],
  ['L372', 'BS', 'TREMOSINE'],
  ['L375', 'CS', 'TRENTA'],
  ['L377', 'SA', 'TRENTINARA'],
  ['L378', 'TN', 'TRENTO'],
  ['L379', 'CE', 'TRENTOLA DUCENTA'],
  ['L380', 'BS', 'TRENZANO'],
  ['L381', 'UD', 'TREPPO CARNICO'],
  ['L382', 'UD', 'TREPPO GRANDE'],
  ['L383', 'LE', 'TREPUZZI'],
  ['L384', 'SI', 'TREQUANDA'],
  ['L385', 'TN', 'TRES'],
  ['L386', 'MS', 'TRESANA'],
  ['L388', 'BG', 'TRESCORE BALNEARIO'],
  ['L389', 'CR', 'TRESCORE CREMASCO'],
  ['L390', 'FE', 'TRESIGALLO'],
  ['L392', 'SO', 'TRESIVIO'],
  ['L393', 'OR', 'TRESNURAGHES'],
  ['L396', 'VR', 'TREVENZUOLO'],
  ['L397', 'PG', 'TREVI'],
  ['L398', 'FR', 'TREVI NEL LAZIO'],
  ['L399', 'AV', 'TREVICO'],
  ['L400', 'BG', 'TREVIGLIO'],
  ['L402', 'TV', 'TREVIGNANO'],
  ['L401', 'RM', 'TREVIGNANO ROMANO'],
  ['L403', 'AL', 'TREVILLE'],
  ['L404', 'BG', 'TREVIOLO'],
  ['L407', 'TV', 'TREVISO'],
  ['L406', 'BS', 'TREVISO BRESCIANO'],
  ['L408', 'MI', 'TREZZANO ROSA'],
  ['L409', 'MI', 'TREZZANO SUL NAVIGLIO'],
  ['L411', 'MI', 'TREZZO SULL\'ADDA'],
  ['L410', 'CN', 'TREZZO TINELLA'],
  ['L413', 'CO', 'TREZZONE'],
  ['L414', 'PD', 'TRIBANO'],
  ['L415', 'MI', 'TRIBIANO'],
  ['L416', 'GE', 'TRIBOGNA'],
  ['L418', 'MT', 'TRICARICO'],
  ['L419', 'LE', 'TRICASE'],
  ['L420', 'VC', 'TRICERRO'],
  ['L421', 'UD', 'TRICESIMO'],
  ['L422', 'BL', 'TRICHIANA'],
  ['L423', 'NU', 'TRIEI'],
  ['L424', 'TS', 'TRIESTE'],
  ['L425', 'BA', 'TRIGGIANO'],
  ['L426', 'CR', 'TRIGOLO'],
  ['Z612', 'EE', 'TRINIDAD E TOBAGO'],
  ['L427', 'CN', 'TRINITA\''],
  ['L428', 'SS', 'TRINITA\' D\'AGULTU E VIGNOLA'],
  ['B915', 'BT', 'TRINITAPOLI'],
  ['B915', 'FG', 'TRINITAPOLI'],
  ['L429', 'VC', 'TRINO VERCELLESE'],
  ['L430', 'IM', 'TRIORA'],
  ['L431', 'ME', 'TRIPI'],
  ['L432', 'AL', 'TRISOBBIO'],
  ['L433', 'VI', 'TRISSINO'],
  ['L434', 'MB', 'TRIUGGIO'],
  ['L434', 'MI', 'TRIUGGIO'],
  ['L435', 'CB', 'TRIVENTO'],
  ['L436', 'BI', 'TRIVERO'],
  ['L437', 'FR', 'TRIVIGLIANO'],
  ['L438', 'UD', 'TRIVIGNANO UDINESE'],
  ['L439', 'PZ', 'TRIVIGNO'],
  ['L440', 'PV', 'TRIVOLZIO'],
  ['L444', 'BZ', 'TRODENA'],
  ['L445', 'TO', 'TROFARELLO'],
  ['L447', 'FG', 'TROIA'],
  ['L448', 'EN', 'TROINA'],
  ['L449', 'PV', 'TROMELLO'],
  ['L450', 'VB', 'TRONTANO'],
  ['A705', 'VA', 'TRONZANO LAGO MAGGIORE'],
  ['L451', 'VC', 'TRONZANO VERCELLESE'],
  ['L452', 'VV', 'TROPEA'],
  ['L453', 'PV', 'TROVO'],
  ['L454', 'MI', 'TRUCCAZZANO'],
  ['L455', 'BZ', 'TUBRE'],
  ['L457', 'TN', 'TUENNO'],
  ['L458', 'CB', 'TUFARA'],
  ['L459', 'CH', 'TUFILLO'],
  ['L460', 'NA', 'TUFINO'],
  ['L461', 'AV', 'TUFO'],
  ['L462', 'LE', 'TUGLIE'],
  ['L463', 'CA', 'TUILI'],
  ['L464', 'SS', 'TULA'],
  ['Z352', 'EE', 'TUNISIA'],
  ['L466', 'PG', 'TUORO SUL TRASIMENO'],
  ['G507', 'RI', 'TURANIA'],
  ['L469', 'LO', 'TURANO LODIGIANO'],
  ['L470', 'CO', 'TURATE'],
  ['L471', 'MI', 'TURBIGO'],
  ['Z243', 'EE', 'TURCHIA'],
  ['L472', 'BA', 'TURI'],
  ['Z258', 'EE', 'TURKMENISTAN'],
  ['Z519', 'EE', 'TURKS E CAICOS (ISOLE)'],
  ['L473', 'CA', 'TURRI'],
  ['L474', 'GO', 'TURRIACO'],
  ['L475', 'PE', 'TURRIVALIGNANI'],
  ['L477', 'MT', 'TURSI'],
  ['L478', 'ME', 'TUSA'],
  ['L310', 'VT', 'TUSCANIA'],
  ['Z732', 'EE', 'TUVALU'],
  ['C789', 'BG', 'UBIALE CLANEZZO'],
  ['L480', 'VA', 'UBOLDO'],
  ['Z138', 'EE', 'UCRAINA'],
  ['L482', 'ME', 'UCRIA'],
  ['L483', 'UD', 'UDINE'],
  ['Z353', 'EE', 'UGANDA'],
  ['L484', 'LE', 'UGENTO'],
  ['L485', 'LE', 'UGGIANO LA CHIESA'],
  ['L487', 'CO', 'UGGIATE-TREVANO'],
  ['L488', 'OR', 'ULA\' TIRSO'],
  ['L489', 'NU', 'ULASSAI'],
  ['L490', 'BZ', 'ULTIMO'],
  ['D786', 'PG', 'UMBERTIDE'],
  ['L492', 'KR', 'UMBRIATICO'],
  ['Z134', 'EE', 'UNGHERIA'],
  ['L494', 'BS', 'URAGO D\'OGLIO'],
  ['L496', 'OR', 'URAS'],
  ['L497', 'PD', 'URBANA'],
  ['L498', 'PU', 'URBANIA'],
  ['L499', 'SV', 'URBE'],
  ['L500', 'PU', 'URBINO'],
  ['L501', 'MC', 'URBISAGLIA'],
  ['L502', 'BG', 'URGNANO'],
  ['L503', 'SS', 'URI'],
  ['Z613', 'EE', 'URUGUAY'],
  ['L505', 'CB', 'URURI'],
  ['L506', 'NU', 'URZULEI'],
  ['L507', 'GE', 'USCIO'],
  ['L508', 'OR', 'USELLUS'],
  ['L509', 'SS', 'USINI'],
  ['L511', 'MB', 'USMATE VELATE'],
  ['L511', 'MI', 'USMATE VELATE'],
  ['L512', 'CA', 'USSANA'],
  ['L513', 'CA', 'USSARAMANNA'],
  ['L514', 'NU', 'USSASSAI'],
  ['L515', 'TO', 'USSEAUX'],
  ['L516', 'TO', 'USSEGLIO'],
  ['L517', 'MC', 'USSITA'],
  ['L519', 'PA', 'USTICA'],
  ['L521', 'CA', 'UTA'],
  ['Z259', 'EE', 'UZBEKISTAN'],
  ['L522', 'PT', 'UZZANO'],
  ['L524', 'CS', 'VACCARIZZO ALBANESE'],
  ['L525', 'RI', 'VACONE'],
  ['L526', 'CH', 'VACRI'],
  ['L527', 'BZ', 'VADENA'],
  ['L528', 'SV', 'VADO LIGURE'],
  ['L533', 'LU', 'VAGLI SOTTO'],
  ['L529', 'FI', 'VAGLIA'],
  ['L532', 'PZ', 'VAGLIO BASILICATA'],
  ['L531', 'AT', 'VAGLIO SERRA'],
  ['L537', 'PO', 'VAIANO'],
  ['L535', 'CR', 'VAIANO CREMASCO'],
  ['L538', 'TO', 'VAIE'],
  ['L539', 'CR', 'VAILATE'],
  ['L540', 'CE', 'VAIRANO PATENORA'],
  ['M265', 'PN', 'VAJONT'],
  ['L555', 'TO', 'VAL DELLA TORRE'],
  ['L562', 'PV', 'VAL DI NIZZA'],
  ['L564', 'BZ', 'VAL DI VIZZE'],
  ['L638', 'SO', 'VAL MASINO'],
  ['H259', 'CO', 'VAL REZZO'],
  ['L544', 'BG', 'VALBONDIONE'],
  ['L545', 'BG', 'VALBREMBO'],
  ['L546', 'GE', 'VALBREVENNA'],
  ['L547', 'CO', 'VALBRONA'],
  ['L550', 'TN', 'VALDA'],
  ['L551', 'VI', 'VALDAGNO'],
  ['L552', 'BZ', 'VALDAORA'],
  ['L554', 'VI', 'VALDASTICO'],
  ['L556', 'BI', 'VALDENGO'],
  ['G319', 'TP', 'VALDERICE'],
  ['L557', 'SO', 'VALDIDENTRO'],
  ['L558', 'CN', 'VALDIERI'],
  ['L561', 'ME', 'VALDINA'],
  ['L563', 'SO', 'VALDISOTTO'],
  ['L565', 'TV', 'VALDOBBIADENE'],
  ['L566', 'VC', 'VALDUGGIA'],
  ['L568', 'PV', 'VALEGGIO'],
  ['L567', 'VR', 'VALEGGIO SUL MINCIO'],
  ['L569', 'VT', 'VALENTANO'],
  ['L570', 'AL', 'VALENZA'],
  ['L571', 'BA', 'VALENZANO'],
  ['L572', 'LO', 'VALERA FRATTA'],
  ['L573', 'PG', 'VALFABBRICA'],
  ['L574', 'AT', 'VALFENERA'],
  ['L575', 'TN', 'VALFLORIANA'],
  ['L576', 'SO', 'VALFURVA'],
  ['L577', 'VA', 'VALGANNA'],
  ['L578', 'TO', 'VALGIOIE'],
  ['L579', 'BG', 'VALGOGLIO'],
  ['L580', 'CN', 'VALGRANA'],
  ['L581', 'LC', 'VALGREGHENTINO'],
  ['L582', 'AO', 'VALGRISENCHE'],
  ['L583', 'EN', 'VALGUARNERA CAROPEPE'],
  ['L584', 'BL', 'VALLADA AGORDINA'],
  ['L586', 'BI', 'VALLANZENGO'],
  ['L588', 'TN', 'VALLARSA'],
  ['L589', 'AV', 'VALLATA'],
  ['L594', 'CE', 'VALLE AGRICOLA'],
  ['L595', 'BZ', 'VALLE AURINA'],
  ['L597', 'TE', 'VALLE CASTELLANA'],
  ['G540', 'SA', 'VALLE DELL\'ANGELO'],
  ['L590', 'BL', 'VALLE DI CADORE'],
  ['L601', 'BZ', 'VALLE DI CASIES'],
  ['L591', 'CE', 'VALLE DI MADDALONI'],
  ['L593', 'PV', 'VALLE LOMELLINA'],
  ['L606', 'BI', 'VALLE MOSSO'],
  ['L617', 'PV', 'VALLE SALIMBENE'],
  ['L620', 'BI', 'VALLE SAN NICOLAO'],
  ['L596', 'IM', 'VALLEBONA'],
  ['L598', 'FR', 'VALLECORSA'],
  ['L599', 'IM', 'VALLECROSIA'],
  ['L603', 'PA', 'VALLEDOLMO'],
  ['L604', 'SS', 'VALLEDORIA'],
  ['I322', 'CZ', 'VALLEFIORITA'],
  ['L607', 'VV', 'VALLELONGA'],
  ['L609', 'CL', 'VALLELUNGA PRATAMENO'],
  ['L605', 'FR', 'VALLEMAIO'],
  ['L611', 'RM', 'VALLEPIETRA'],
  ['L612', 'VT', 'VALLERANO'],
  ['L613', 'CA', 'VALLERMOSA'],
  ['L614', 'FR', 'VALLEROTONDA'],
  ['L616', 'AV', 'VALLESACCARDA'],
  ['L623', 'BG', 'VALLEVE'],
  ['L624', 'VI', 'VALLI DEL PASUBIO'],
  ['L625', 'RM', 'VALLINFREDA'],
  ['L626', 'BS', 'VALLIO TERME'],
  ['L628', 'SA', 'VALLO DELLA LUCANIA'],
  ['L627', 'PG', 'VALLO DI NERA'],
  ['L629', 'TO', 'VALLO TORINESE'],
  ['L631', 'CN', 'VALLORIATE'],
  ['L633', 'AL', 'VALMACCA'],
  ['L634', 'LC', 'VALMADRERA'],
  ['L636', 'CN', 'VALMALA'],
  ['L639', 'RM', 'VALMONTONE'],
  ['L640', 'CO', 'VALMOREA'],
  ['L641', 'PR', 'VALMOZZOLA'],
  ['L642', 'BG', 'VALNEGRA'],
  ['L643', 'AO', 'VALPELLINE'],
  ['L644', 'TO', 'VALPERGA'],
  ['B510', 'TO', 'VALPRATO SOANA'],
  ['L647', 'AO', 'VALSAVARENCHE'],
  ['L649', 'BG', 'VALSECCA'],
  ['D513', 'MT', 'VALSINNI'],
  ['C936', 'CO', 'VALSOLDA'],
  ['L650', 'VI', 'VALSTAGNA'],
  ['L651', 'VB', 'VALSTRONA'],
  ['L653', 'PG', 'VALTOPINA'],
  ['L655', 'BG', 'VALTORTA'],
  ['L654', 'AO', 'VALTOURNENCHE'],
  ['L656', 'SA', 'VALVA'],
  ['L657', 'PN', 'VALVASONE'],
  ['L659', 'PV', 'VALVERDE'],
  ['L658', 'CT', 'VALVERDE'],
  ['L468', 'BS', 'VALVESTINO'],
  ['L660', 'BZ', 'VANDOIES'],
  ['Z733', 'EE', 'VANUATU'],
  ['L664', 'MI', 'VANZAGHELLO'],
  ['L665', 'MI', 'VANZAGO'],
  ['L666', 'VB', 'VANZONE CON SAN CARLO'],
  ['L667', 'MI', 'VAPRIO D\'ADDA'],
  ['L668', 'NO', 'VAPRIO D\'AGOGNA'],
  ['L669', 'VC', 'VARALLO'],
  ['L670', 'NO', 'VARALLO POMBIA'],
  ['L671', 'VA', 'VARANO BORGHI'],
  ['L672', 'PR', 'VARANO DE\' MELEGARI'],
  ['L673', 'RC', 'VARAPODIO'],
  ['L675', 'SV', 'VARAZZE'],
  ['L676', 'RI', 'VARCO SABINO'],
  ['L677', 'MB', 'VAREDO'],
  ['L677', 'MI', 'VAREDO'],
  ['L678', 'TN', 'VARENA'],
  ['L680', 'LC', 'VARENNA'],
  ['L682', 'VA', 'VARESE'],
  ['L681', 'SP', 'VARESE LIGURE'],
  ['L685', 'TO', 'VARISELLA'],
  ['L686', 'UD', 'VARMO'],
  ['L687', 'BZ', 'VARNA'],
  ['L689', 'PR', 'VARSI'],
  ['L690', 'PV', 'VARZI'],
  ['L691', 'VB', 'VARZO'],
  ['L692', 'BL', 'VAS'],
  ['A701', 'VT', 'VASANELLO'],
  ['L693', 'IM', 'VASIA'],
  ['E372', 'CH', 'VASTO'],
  ['L696', 'IS', 'VASTOGIRARDI'],
  ['L697', 'TN', 'VATTARO'],
  ['L698', 'TO', 'VAUDA CANAVESE'],
  ['L699', 'VV', 'VAZZANO'],
  ['L700', 'TV', 'VAZZOLA'],
  ['L702', 'PI', 'VECCHIANO'],
  ['L704', 'MB', 'VEDANO AL LAMBRO'],
  ['L704', 'MI', 'VEDANO AL LAMBRO'],
  ['L703', 'VA', 'VEDANO OLONA'],
  ['L705', 'VA', 'VEDDASCA'],
  ['L706', 'TV', 'VEDELAGO'],
  ['L707', 'BG', 'VEDESETA'],
  ['L709', 'MB', 'VEDUGGIO CON COLZANO'],
  ['L709', 'MI', 'VEDUGGIO CON COLZANO'],
  ['L710', 'PD', 'VEGGIANO'],
  ['L711', 'LE', 'VEGLIE'],
  ['L712', 'BI', 'VEGLIO'],
  ['L713', 'VT', 'VEJANO'],
  ['L715', 'CO', 'VELESO'],
  ['L716', 'PV', 'VELEZZO LOMELLINA'],
  ['L719', 'RM', 'VELLETRI'],
  ['L720', 'PV', 'VELLEZZO BELLINI'],
  ['L723', 'VI', 'VELO D\'ASTICO'],
  ['L722', 'VR', 'VELO VERONESE'],
  ['L724', 'BZ', 'VELTURNO'],
  ['L725', 'IS', 'VENAFRO'],
  ['L727', 'TO', 'VENARIA REALE'],
  ['L728', 'AP', 'VENAROTTA'],
  ['L729', 'CN', 'VENASCA'],
  ['L726', 'TO', 'VENAUS'],
  ['L730', 'SV', 'VENDONE'],
  ['L731', 'LC', 'VENDROGNO'],
  ['L733', 'VA', 'VENEGONO INFERIORE'],
  ['L734', 'VA', 'VENEGONO SUPERIORE'],
  ['L735', 'ME', 'VENETICO'],
  ['L736', 'VE', 'VENEZIA'],
  ['Z614', 'EE', 'VENEZUELA'],
  ['L737', 'CO', 'VENIANO'],
  ['L738', 'PZ', 'VENOSA'],
  ['L739', 'AV', 'VENTICANO'],
  ['L741', 'IM', 'VENTIMIGLIA'],
  ['L740', 'PA', 'VENTIMIGLIA DI SICILIA'],
  ['L742', 'LT', 'VENTOTENE'],
  ['L743', 'UD', 'VENZONE'],
  ['L745', 'BZ', 'VERANO'],
  ['L744', 'MB', 'VERANO BRIANZA'],
  ['L744', 'MI', 'VERANO BRIANZA'],
  ['L746', 'VB', 'VERBANIA'],
  ['L747', 'CS', 'VERBICARO'],
  ['L748', 'CO', 'VERCANA'],
  ['L749', 'SO', 'VERCEIA'],
  ['L750', 'VC', 'VERCELLI'],
  ['L751', 'LC', 'VERCURAGO'],
  ['L752', 'BG', 'VERDELLINO'],
  ['L753', 'BG', 'VERDELLO'],
  ['L755', 'LC', 'VERDERIO INFERIORE'],
  ['L756', 'LC', 'VERDERIO SUPERIORE'],
  ['L758', 'CN', 'VERDUNO'],
  ['L762', 'BO', 'VERGATO'],
  ['L763', 'LU', 'VERGEMOLI'],
  ['L764', 'FC', 'VERGHERETO'],
  ['L765', 'VA', 'VERGIATE'],
  ['Z520', 'EE', 'VERGINI AMERICANE (ISOLE)'],
  ['Z525', 'EE', 'VERGINI BRITANNICHE (ISOLE)'],
  ['L768', 'MI', 'VERMEZZO'],
  ['L769', 'TN', 'VERMIGLIO'],
  ['L771', 'CN', 'VERNANTE'],
  ['L772', 'PC', 'VERNASCA'],
  ['L773', 'MI', 'VERNATE'],
  ['L774', 'SP', 'VERNAZZA'],
  ['L775', 'PO', 'VERNIO'],
  ['L776', 'LE', 'VERNOLE'],
  ['L777', 'BS', 'VEROLANUOVA'],
  ['L778', 'BS', 'VEROLAVECCHIA'],
  ['L779', 'TO', 'VEROLENGO'],
  ['L780', 'FR', 'VEROLI'],
  ['L781', 'VR', 'VERONA'],
  ['D193', 'VR', 'VERONELLA'],
  ['L783', 'AO', 'VERRAYES'],
  ['C282', 'AO', 'VERRES'],
  ['L784', 'PV', 'VERRETTO'],
  ['L785', 'BI', 'VERRONE'],
  ['L788', 'PV', 'VERRUA PO'],
  ['L787', 'TO', 'VERRUA SAVOIA'],
  ['L792', 'CO', 'VERTEMATE CON MINOPRIO'],
  ['L795', 'BG', 'VERTOVA'],
  ['L797', 'RN', 'VERUCCHIO'],
  ['L798', 'NO', 'VERUNO'],
  ['L799', 'SO', 'VERVIO'],
  ['L800', 'TN', 'VERVO\''],
  ['L801', 'UD', 'VERZEGNIS'],
  ['L802', 'KR', 'VERZINO'],
  ['L804', 'CN', 'VERZUOLO'],
  ['L805', 'PD', 'VESCOVANA'],
  ['L806', 'CR', 'VESCOVATO'],
  ['L807', 'AT', 'VESIME'],
  ['L808', 'NO', 'VESPOLATE'],
  ['L809', 'IM', 'VESSALICO'],
  ['L810', 'VR', 'VESTENANOVA'],
  ['L811', 'TO', 'VESTIGNE\''],
  ['L812', 'BS', 'VESTONE'],
  ['L813', 'LC', 'VESTRENO'],
  ['L814', 'VT', 'VETRALLA'],
  ['L815', 'RE', 'VETTO'],
  ['L817', 'CN', 'VEZZA D\'ALBA'],
  ['L816', 'BS', 'VEZZA D\'OGLIO'],
  ['L821', 'TN', 'VEZZANO'],
  ['L819', 'SP', 'VEZZANO LIGURE'],
  ['L820', 'RE', 'VEZZANO SUL CROSTOLO'],
  ['L823', 'SV', 'VEZZI PORTIO'],
  ['L826', 'MN', 'VIADANA'],
  ['L827', 'BG', 'VIADANICA'],
  ['L828', 'CT', 'VIAGRANDE'],
  ['L829', 'AT', 'VIALE D\'ASTI'],
  ['L830', 'TO', 'VIALFRE\''],
  ['L831', 'RE', 'VIANO'],
  ['L833', 'LU', 'VIAREGGIO'],
  ['L834', 'AT', 'VIARIGI'],
  ['F537', 'VV', 'VIBO VALENTIA'],
  ['L835', 'SA', 'VIBONATI'],
  ['L836', 'FR', 'VICALVI'],
  ['L837', 'PA', 'VICARI'],
  ['L838', 'FI', 'VICCHIO'],
  ['L840', 'VI', 'VICENZA'],
  ['L548', 'TO', 'VICO CANAVESE'],
  ['L842', 'FG', 'VICO DEL GARGANO'],
  ['L845', 'NA', 'VICO EQUENSE'],
  ['L843', 'FR', 'VICO NEL LAZIO'],
  ['L841', 'CN', 'VICOFORTE'],
  ['L846', 'PE', 'VICOLI'],
  ['L847', 'NO', 'VICOLUNGO'],
  ['L850', 'PI', 'VICOPISANO'],
  ['L851', 'RM', 'VICOVARO'],
  ['M259', 'SS', 'VIDDALBA'],
  ['L854', 'PV', 'VIDIGULFO'],
  ['L856', 'TV', 'VIDOR'],
  ['L857', 'TO', 'VIDRACCO'],
  ['L858', 'FG', 'VIESTE'],
  ['Z251', 'EE', 'VIETNAM'],
  ['L859', 'PZ', 'VIETRI DI POTENZA'],
  ['L860', 'SA', 'VIETRI SUL MARE'],
  ['L864', 'VB', 'VIGANELLA'],
  ['L865', 'BG', 'VIGANO SAN MARTINO'],
  ['L866', 'LC', 'VIGANO\''],
  ['L868', 'FE', 'VIGARANO MAINARDA'],
  ['L869', 'VR', 'VIGASIO'],
  ['L872', 'PV', 'VIGEVANO'],
  ['L873', 'PZ', 'VIGGIANELLO'],
  ['L874', 'PZ', 'VIGGIANO'],
  ['L876', 'VA', 'VIGGIU\''],
  ['L878', 'PD', 'VIGHIZZOLO D\'ESTE'],
  ['L880', 'BI', 'VIGLIANO BIELLESE'],
  ['L879', 'AT', 'VIGLIANO D\'ASTI'],
  ['L881', 'AL', 'VIGNALE MONFERRATO'],
  ['L882', 'VT', 'VIGNANELLO'],
  ['L883', 'MI', 'VIGNATE'],
  ['L885', 'MO', 'VIGNOLA'],
  ['L886', 'TN', 'VIGNOLA FALESINA'],
  ['L887', 'AL', 'VIGNOLE BORBERA'],
  ['L888', 'CN', 'VIGNOLO'],
  ['L889', 'VB', 'VIGNONE'],
  ['L890', 'BL', 'VIGO DI CADORE'],
  ['L893', 'TN', 'VIGO DI FASSA'],
  ['L903', 'TN', 'VIGO RENDENA'],
  ['L892', 'PD', 'VIGODARZERE'],
  ['L894', 'BG', 'VIGOLO'],
  ['L896', 'TN', 'VIGOLO VATTARO'],
  ['L897', 'PC', 'VIGOLZONE'],
  ['L898', 'TO', 'VIGONE'],
  ['L899', 'VE', 'VIGONOVO'],
  ['L900', 'PD', 'VIGONZA'],
  ['L904', 'AL', 'VIGUZZOLO'],
  ['L910', 'TN', 'VILLA AGNEDO'],
  ['L912', 'VR', 'VILLA BARTOLOMEA'],
  ['L913', 'LU', 'VILLA BASILICA'],
  ['L917', 'PV', 'VILLA BISCOSSI'],
  ['L919', 'BS', 'VILLA CARCINA'],
  ['L920', 'BR', 'VILLA CASTELLI'],
  ['L922', 'PE', 'VILLA CELIERA'],
  ['L926', 'LU', 'VILLA COLLEMANDINA'],
  ['L928', 'MI', 'VILLA CORTESE'],
  ['L929', 'BG', 'VILLA D\'ADDA'],
  ['A215', 'BG', 'VILLA D\'ALME\''],
  ['L938', 'BG', 'VILLA D\'OGNA'],
  ['L933', 'BI', 'VILLA DEL BOSCO'],
  ['L934', 'PD', 'VILLA DEL CONTE'],
  ['D801', 'CE', 'VILLA DI BRIANO'],
  ['L907', 'SO', 'VILLA DI CHIAVENNA'],
  ['L936', 'BG', 'VILLA DI SERIO'],
  ['L908', 'SO', 'VILLA DI TIRANO'],
  ['L937', 'PD', 'VILLA ESTENSE'],
  ['L943', 'IM', 'VILLA FARALDI'],
  ['L956', 'CO', 'VILLA GUARDIA'],
  ['L957', 'TN', 'VILLA LAGARINA'],
  ['A081', 'FR', 'VILLA LATINA'],
  ['L844', 'CE', 'VILLA LITERNO'],
  ['L969', 'RE', 'VILLA MINOZZO'],
  ['F804', 'MN', 'VILLA POMA'],
  ['M006', 'TN', 'VILLA RENDENA'],
  ['M018', 'RC', 'VILLA SAN GIOVANNI'],
  ['H913', 'VT', 'VILLA SAN GIOVANNI IN TUSCIA'],
  ['I118', 'CA', 'VILLA SAN PIETRO'],
  ['M019', 'AT', 'VILLA SAN SECONDO'],
  ['M023', 'AQ', 'VILLA SANT\'ANGELO'],
  ['I298', 'OR', 'VILLA SANT\'ANTONIO'],
  ['L905', 'FR', 'VILLA SANTA LUCIA'],
  ['M021', 'AQ', 'VILLA SANTA LUCIA DEGLI ABRUZZI'],
  ['M022', 'CH', 'VILLA SANTA MARIA'],
  ['L909', 'UD', 'VILLA SANTINA'],
  ['I364', 'FR', 'VILLA SANTO STEFANO'],
  ['A609', 'OR', 'VILLA VERDE'],
  ['M034', 'UD', 'VILLA VICENTINA'],
  ['L915', 'BZ', 'VILLABASSA'],
  ['L916', 'PA', 'VILLABATE'],
  ['L923', 'BS', 'VILLACHIARA'],
  ['L924', 'CA', 'VILLACIDRO'],
  ['L931', 'AL', 'VILLADEATI'],
  ['L939', 'RO', 'VILLADOSE'],
  ['L906', 'VB', 'VILLADOSSOLA'],
  ['L942', 'CN', 'VILLAFALLETTO'],
  ['L945', 'AT', 'VILLAFRANCA D\'ASTI'],
  ['L949', 'VR', 'VILLAFRANCA DI VERONA'],
  ['L946', 'MS', 'VILLAFRANCA IN LUNIGIANA'],
  ['L947', 'PD', 'VILLAFRANCA PADOVANA'],
  ['L948', 'TO', 'VILLAFRANCA PIEMONTE'],
  ['L944', 'AG', 'VILLAFRANCA SICULA'],
  ['L950', 'ME', 'VILLAFRANCA TIRRENA'],
  ['L951', 'PA', 'VILLAFRATI'],
  ['L952', 'VI', 'VILLAGA'],
  ['L953', 'NU', 'VILLAGRANDE STRISAILI'],
  ['L958', 'AQ', 'VILLALAGO'],
  ['L959', 'CL', 'VILLALBA'],
  ['L961', 'CH', 'VILLALFONSINA'],
  ['L963', 'AL', 'VILLALVERNIA'],
  ['L964', 'CH', 'VILLAMAGNA'],
  ['L965', 'AV', 'VILLAMAINA'],
  ['L966', 'CA', 'VILLAMAR'],
  ['L967', 'RO', 'VILLAMARZANA'],
  ['L968', 'CA', 'VILLAMASSARGIA'],
  ['L970', 'AL', 'VILLAMIROGLIO'],
  ['L971', 'BZ', 'VILLANDRO'],
  ['L978', 'BI', 'VILLANOVA BIELLESE'],
  ['L982', 'TO', 'VILLANOVA CANAVESE'],
  ['L975', 'SV', 'VILLANOVA D\'ALBENGA'],
  ['L983', 'PV', 'VILLANOVA D\'ARDENGHI'],
  ['L984', 'AT', 'VILLANOVA D\'ASTI'],
  ['L973', 'AV', 'VILLANOVA DEL BATTISTA'],
  ['L985', 'RO', 'VILLANOVA DEL GHEBBO'],
  ['L977', 'LO', 'VILLANOVA DEL SILLARO'],
  ['L979', 'PD', 'VILLANOVA DI CAMPOSAMPIERO'],
  ['L988', 'RO', 'VILLANOVA MARCHESANA'],
  ['L974', 'CN', 'VILLANOVA MONDOVI\''],
  ['L972', 'AL', 'VILLANOVA MONFERRATO'],
  ['L989', 'SS', 'VILLANOVA MONTELEONE'],
  ['L990', 'CN', 'VILLANOVA SOLARO'],
  ['L980', 'PC', 'VILLANOVA SULL\'ARDA'],
  ['L991', 'OR', 'VILLANOVA TRUSCHEDU'],
  ['L986', 'CA', 'VILLANOVAFORRU'],
  ['L987', 'CA', 'VILLANOVAFRANCA'],
  ['L992', 'NU', 'VILLANOVATULO'],
  ['L994', 'PV', 'VILLANTERIO'],
  ['L995', 'BS', 'VILLANUOVA SUL CLISI'],
  ['M278', 'CA', 'VILLAPERUCCIO'],
  ['B903', 'CS', 'VILLAPIANA'],
  ['L998', 'CA', 'VILLAPUTZU'],
  ['L999', 'TO', 'VILLAR DORA'],
  ['M007', 'TO', 'VILLAR FOCCHIARDO'],
  ['M013', 'TO', 'VILLAR PELLICE'],
  ['M014', 'TO', 'VILLAR PEROSA'],
  ['M015', 'CN', 'VILLAR SAN COSTANZO'],
  ['M002', 'TO', 'VILLARBASSE'],
  ['M003', 'VC', 'VILLARBOIT'],
  ['M004', 'TO', 'VILLAREGGIA'],
  ['G309', 'NA', 'VILLARICCA'],
  ['M009', 'AL', 'VILLAROMAGNANO'],
  ['M011', 'EN', 'VILLAROSA'],
  ['M016', 'CA', 'VILLASALTO'],
  ['M017', 'MB', 'VILLASANTA'],
  ['M017', 'MI', 'VILLASANTA'],
  ['B738', 'CA', 'VILLASIMIUS'],
  ['M025', 'CA', 'VILLASOR'],
  ['M026', 'CA', 'VILLASPECIOSA'],
  ['M027', 'TO', 'VILLASTELLONE'],
  ['M028', 'VC', 'VILLATA'],
  ['M030', 'OR', 'VILLAURBANA'],
  ['M031', 'AQ', 'VILLAVALLELONGA'],
  ['M032', 'VI', 'VILLAVERLA'],
  ['L981', 'AO', 'VILLENEUVE'],
  ['M043', 'GO', 'VILLESSE'],
  ['M041', 'AQ', 'VILLETTA BARREA'],
  ['M042', 'VB', 'VILLETTE'],
  ['M044', 'MN', 'VILLIMPENTA'],
  ['M045', 'BG', 'VILLONGO'],
  ['M048', 'TV', 'VILLORBA'],
  ['M050', 'BG', 'VILMINORE DI SCALVE'],
  ['M052', 'MB', 'VIMERCATE'],
  ['M052', 'MI', 'VIMERCATE'],
  ['M053', 'MI', 'VIMODRONE'],
  ['M055', 'CN', 'VINADIO'],
  ['M057', 'CB', 'VINCHIATURO'],
  ['M058', 'AT', 'VINCHIO'],
  ['M059', 'FI', 'VINCI'],
  ['M060', 'TO', 'VINOVO'],
  ['M062', 'NO', 'VINZAGLIO'],
  ['M063', 'CN', 'VIOLA'],
  ['M065', 'BS', 'VIONE'],
  ['M067', 'BZ', 'VIPITENO'],
  ['H123', 'MN', 'VIRGILIO'],
  ['M069', 'TO', 'VIRLE PIEMONTE'],
  ['M070', 'BS', 'VISANO'],
  ['M071', 'TO', 'VISCHE'],
  ['M072', 'NA', 'VISCIANO'],
  ['M073', 'UD', 'VISCO'],
  ['M077', 'AL', 'VISONE'],
  ['M078', 'MC', 'VISSO'],
  ['M079', 'PV', 'VISTARINO'],
  ['M080', 'TO', 'VISTRORIO'],
  ['M081', 'TP', 'VITA'],
  ['M082', 'VT', 'VITERBO'],
  ['Z704', 'EE', 'VITI'],
  ['M083', 'FR', 'VITICUSO'],
  ['M085', 'PN', 'VITO D\'ASIO'],
  ['M086', 'VT', 'VITORCHIANO'],
  ['M088', 'RG', 'VITTORIA'],
  ['M089', 'TV', 'VITTORIO VENETO'],
  ['M090', 'AQ', 'VITTORITO'],
  ['M091', 'MI', 'VITTUONE'],
  ['M093', 'BN', 'VITULANO'],
  ['M092', 'CE', 'VITULAZIO'],
  ['M094', 'TO', 'VIU\''],
  ['M096', 'PN', 'VIVARO'],
  ['M095', 'RM', 'VIVARO ROMANO'],
  ['M098', 'BI', 'VIVERONE'],
  ['M100', 'CT', 'VIZZINI'],
  ['M101', 'VA', 'VIZZOLA TICINO'],
  ['M102', 'MI', 'VIZZOLO PREDABISSI'],
  ['M103', 'PD', 'VO'],
  ['M104', 'BS', 'VOBARNO'],
  ['M105', 'GE', 'VOBBIA'],
  ['M106', 'VC', 'VOCCA'],
  ['M108', 'BL', 'VODO DI CADORE'],
  ['M109', 'PV', 'VOGHERA'],
  ['M110', 'FE', 'VOGHIERA'],
  ['M111', 'VB', 'VOGOGNA'],
  ['M113', 'TN', 'VOLANO'],
  ['M115', 'NA', 'VOLLA'],
  ['M116', 'CR', 'VOLONGO'],
  ['M118', 'TV', 'VOLPAGO DEL MONTELLO'],
  ['M119', 'PV', 'VOLPARA'],
  ['M120', 'AL', 'VOLPEDO'],
  ['M121', 'AL', 'VOLPEGLINO'],
  ['M122', 'TO', 'VOLPIANO'],
  ['M125', 'MN', 'VOLTA MANTOVANA'],
  ['M123', 'AL', 'VOLTAGGIO'],
  ['M124', 'BL', 'VOLTAGO AGORDINO'],
  ['M126', 'PI', 'VOLTERRA'],
  ['M127', 'CR', 'VOLTIDO'],
  ['M131', 'FG', 'VOLTURARA APPULA'],
  ['M130', 'AV', 'VOLTURARA IRPINA'],
  ['M132', 'FG', 'VOLTURINO'],
  ['M133', 'TO', 'VOLVERA'],
  ['M136', 'CN', 'VOTTIGNASCO'],
  ['Z729', 'EE', 'WALLIS E FUTUNA (ISOLE)'],
  ['Z246', 'EE', 'YEMEN'],
  ['M138', 'VV', 'ZACCANOPOLI'],
  ['M139', 'CT', 'ZAFFERANA ETNEA'],
  ['M140', 'CZ', 'ZAGARISE'],
  ['M141', 'RM', 'ZAGAROLO'],
  ['M142', 'TN', 'ZAMBANA'],
  ['Z355', 'EE', 'ZAMBIA'],
  ['M143', 'VV', 'ZAMBRONE'],
  ['M144', 'BG', 'ZANDOBBIO'],
  ['M145', 'VI', 'ZANE\''],
  ['M147', 'BG', 'ZANICA'],
  ['M267', 'FG', 'ZAPPONETA'],
  ['M150', 'PV', 'ZAVATTARELLO'],
  ['M152', 'PV', 'ZECCONE'],
  ['M153', 'OR', 'ZEDDIANI'],
  ['M156', 'CO', 'ZELBIO'],
  ['M158', 'LO', 'ZELO BUON PERSICO'],
  ['M160', 'MI', 'ZELO SURRIGONE'],
  ['M161', 'PV', 'ZEME'],
  ['M162', 'PV', 'ZENEVREDO'],
  ['M163', 'TV', 'ZENSON DI PIAVE'],
  ['M165', 'PC', 'ZERBA'],
  ['M166', 'PV', 'ZERBO'],
  ['M167', 'PV', 'ZERBOLO\''],
  ['M168', 'OR', 'ZERFALIU'],
  ['M169', 'MS', 'ZERI'],
  ['M170', 'VI', 'ZERMEGHEDO'],
  ['M171', 'TV', 'ZERO BRANCO'],
  ['M172', 'VR', 'ZEVIO'],
  ['M173', 'TN', 'ZIANO DI FIEMME'],
  ['L848', 'PC', 'ZIANO PIACENTINO'],
  ['M174', 'PR', 'ZIBELLO'],
  ['M176', 'MI', 'ZIBIDO SAN GIACOMO'],
  ['M177', 'SP', 'ZIGNAGO'],
  ['Z337', 'EE', 'ZIMBABWE'],
  ['M178', 'VR', 'ZIMELLA'],
  ['M179', 'BI', 'ZIMONE'],
  ['M180', 'PV', 'ZINASCO'],
  ['M182', 'GE', 'ZOAGLI'],
  ['M183', 'MO', 'ZOCCA'],
  ['M184', 'BG', 'ZOGNO'],
  ['M185', 'BO', 'ZOLA PREDOSA'],
  ['I345', 'BL', 'ZOLDO ALTO'],
  ['M187', 'LE', 'ZOLLINO'],
  ['M188', 'BS', 'ZONE'],
  ['M189', 'BL', 'ZOPPE\' DI CADORE'],
  ['M190', 'PN', 'ZOPPOLA'],
  ['M194', 'VI', 'ZOVENCEDO'],
  ['M196', 'BI', 'ZUBIENA'],
  ['M197', 'SV', 'ZUCCARELLO'],
  ['M198', 'TN', 'ZUCLO'],
  ['M199', 'VI', 'ZUGLIANO'],
  ['M200', 'UD', 'ZUGLIO'],
  ['M201', 'BI', 'ZUMAGLIA'],
  ['M202', 'CS', 'ZUMPANO'],
  ['M203', 'AV', 'ZUNGOLI'],
  ['M204', 'VV', 'ZUNGRI'],
  ['Z118', 'EE', 'IUGOSLAVIA']
]

export const PROVINCE = {
  AG: 'Agrigento',
  AL: 'Alessandria',
  AN: 'Ancona',
  AO: 'Aosta',
  AP: 'Ascoli Piceno',
  AQ: 'L\'Aquila',
  AR: 'Arezzo',
  AT: 'Asti',
  AV: 'Avellino',
  BA: 'Bari',
  BG: 'Bergamo',
  BI: 'Biella',
  BL: 'Belluno',
  BN: 'Benevento',
  BO: 'Bologna',
  BR: 'Brindisi',
  BS: 'Brescia',
  BT: 'Barletta-Andria-Trani',
  BZ: 'Bolzano',
  CA: 'Cagliari',
  CB: 'Campobasso',
  CE: 'Caserta',
  CH: 'Chieti',
  CI: 'Carbonia-Iglesias',
  CL: 'Caltanissetta',
  CN: 'Cuneo',
  CO: 'Como',
  CR: 'Cremona',
  CS: 'Cosenza',
  CT: 'Catania',
  CZ: 'Catanzaro',
  EN: 'Enna',
  FC: 'Forlì-Cesena',
  FE: 'Ferrara',
  FG: 'Foggia',
  FI: 'Firenze',
  FM: 'Fermo',
  FR: 'Frosinone',
  GE: 'Genova',
  GO: 'Gorizia',
  GR: 'Grosseto',
  IM: 'Imperia',
  IS: 'Isernia',
  KR: 'Crotone',
  LC: 'Lecco',
  LE: 'Lecce',
  LI: 'Livorno',
  LO: 'Lodi',
  LT: 'Latina',
  LU: 'Lucca',
  MB: 'Monza e della Brianza',
  MC: 'Macerata',
  ME: 'Messina',
  MI: 'Milano',
  MN: 'Mantova',
  MO: 'Modena',
  MS: 'Massa-Carrara',
  MT: 'Matera',
  NA: 'Napoli',
  NO: 'Novara',
  NU: 'Nuoro',
  OG: 'Ogliastra',
  OR: 'Oristano',
  OT: 'Olbia-Tempio',
  PA: 'Palermo',
  PC: 'Piacenza',
  PD: 'Padova',
  PE: 'Pescara',
  PG: 'Perugia',
  PI: 'Pisa',
  PN: 'Pordenone',
  PO: 'Prato',
  PR: 'Parma',
  PT: 'Pistoia',
  PU: 'Pesaro e Urbino',
  PV: 'Pavia',
  PZ: 'Potenza',
  RA: 'Ravenna',
  RC: 'Reggio Calabria',
  RE: 'Reggio Emilia',
  RG: 'Ragusa',
  RI: 'Rieti',
  RM: 'Roma',
  RN: 'Rimini',
  RO: 'Rovigo',
  SA: 'Salerno',
  SI: 'Siena',
  SO: 'Sondrio',
  SP: 'La Spezia',
  SR: 'Siracusa',
  SS: 'Sassari',
  SV: 'Savona',
  TA: 'Taranto',
  TE: 'Teramo',
  TN: 'Trento',
  TO: 'Torino',
  TP: 'Trapani',
  TR: 'Terni',
  TS: 'Trieste',
  TV: 'Treviso',
  UD: 'Udine',
  VA: 'Varese',
  VB: 'Verbano-Cusio-Ossola',
  VC: 'Vercelli',
  VE: 'Venezia',
  VI: 'Vicenza',
  VR: 'Verona',
  VS: 'Medio Campidano',
  VT: 'Viterbo',
  VV: 'Vibo Valentia',
  EE: 'Estero'
}
