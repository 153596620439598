import currencyJSON from "../assets/Currency/Common-Currency.json";
import {currencyType} from "../model/UtilsDTO";

export const ratesEuribor = [
    "EURIBOR_EONIA",
    "EURIBOR_1_MONTH",
    "EURIBOR_3_MONTHS",
    "EURIBOR_6_MONTHS",
    "EURIBOR_12_MONTHS"
];
export const ratesLibor = [
    "LIBOR_1_WEEK",
    "LIBOR_1_MONTH",
    "LIBOR_2_MONTHS",
    "LIBOR_3_MONTHS",
    "LIBOR_6_MONTHS",
    "LIBOR_12_MONTHS"
];

export const ratesSofr = [
    "SOFR_1_MONTH",
    "SOFR_3_MONTHS",
    "SOFR_6_MONTHS"
];


class CurrencyUtils {

    getAllCurrency = (): string[] => {
        return Object.keys(currencyJSON as currencyType);
    };

    getSymbolFromCurrencyISO = (currency?: string): string => {
        let currencyDict = currencyJSON as currencyType;
        if (currency && currencyDict[currency] !== undefined) {
            return currencyDict[currency].symbol_native;
        }
        return "";
    };
}

export const currencyUtils = new CurrencyUtils();
