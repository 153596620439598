import * as React from 'react';
import "./CheckBox.scss";
import {Roles} from "../../../model/UserDTO";
import {white_tick} from "../../../utils/dynamicSVG";
import tick from "../../../assets/Icons/tick_white.svg";

interface Props {
    checked?: boolean,
    radioButton?: boolean,
    description?: string,
    icon?: any,
    disabled?: boolean,
    descriptionStyle?: any
    colorsInverted?: boolean
    onClickCheckbox?(element: object): void,
}

const CheckBox: React.FC<Props> = ({
                                       checked,
                                       onClickCheckbox,
                                       description,
                                       radioButton,
                                       descriptionStyle,
                                       disabled,
                                       colorsInverted = false,
                                       icon
                                   }) => {

    const rounded = icon ? "customCheckbox__body--rounded" : null;
    const roundedChecked = icon && checked ? "customCheckbox__body--roundedChecked" : null;
    const checkMod = checked && !colorsInverted ? "customCheckbox__body--checked" : null;
    const radioMod = radioButton ? "customCheckbox__body--radio" : null;
    const disabledStyle = disabled && "customCheckbox__body--disabled";
    const disabledDescription = disabled && "customCheckbox__description--disabled";
    const inverted = colorsInverted === true ? "customCheckbox__body--inverted" : "";

    return (
        <div className="customCheckbox">
            <div className="customCheckbox__containerCheck">
                <div onClick={onClickCheckbox} className={`customCheckbox__body ${checkMod} ${inverted} ${radioMod} ${rounded} ${roundedChecked} ${disabledStyle}`}>
                    {(checked && !colorsInverted) && <img src={icon || tick} alt="checked" style={{height: icon && "26px"}}/>}
                    {checked && (colorsInverted ? white_tick(Roles.MONEYLENDER, "30px", "30px") : white_tick())}
                </div>
            </div>
            {description &&
            <div className={`customCheckbox__description ${disabledDescription}`} onClick={onClickCheckbox} style={descriptionStyle}>
                {description}
            </div>
            }
        </div>
    );
};

export default CheckBox;
