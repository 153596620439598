import {networkService} from "./NetworkService";
import {MyResponse} from "../model/ServiceDTO";
import {configService} from "./ConfigService";
import {
    ActionDTO,
    ActionSigningStatus,
    ActionTypes,
    ArchiveItemDTO,
    CombinationsRequest,
    CombinationsResponse,
    CreateDossierResponse,
    InfoCertDeleteDossierResponse,
    InfocertDossierType,
    InfoCertLogoutResponse,
    InfocertStatusDTO,
    OneShotIssueSignRequest,
    OneShotIssueSignResponse,
    OneShotRegisterRequest,
    OneShotRegisterResponse,
    OneShotSendOTPResponse,
    OneShotStartResponse,
    ProcessStartResponse,
    RemoteSignPrepareSignatureResponse,
    RemoteSignSendOTPResponse,
    RemoteSignSignContractsRequest,
    RemoteSignSignContractsResponse,
    RemoteSignStartResponse,
    SignDocumentRequestDTO,
    SignStatusRequest,
    WebIdDossierResponse,
    WebIdQueueResponse,
    WebIdStartResponse
} from "../model/InfoCertDTO";

const BASE_URL = configService.getApiPath();
const INFOCERT_AUTH = configService.getInfoCertAuthorization();
export const INFO_CERT_URL = configService.getInfoCertPath();
export const INFO_CERT_SPID_URL = configService.getInfoCertSPIDPath();

class InfoCertService {

    createDossier = (dossierType: string): Promise<MyResponse<CreateDossierResponse>> => {
        return networkService.get<CreateDossierResponse>(BASE_URL + "/infocert/" + dossierType, [],undefined,240000);
    };

    getNewToken = (dossierType: string): Promise<MyResponse<CreateDossierResponse>> => {
        let dossierTypeEnum = dossierType !== null ? dossierType : InfocertDossierType.NONE
        return networkService.get<CreateDossierResponse>(BASE_URL + "/infocert/getNewToken/" + dossierTypeEnum, []);
    };

    getContractPdf = (): Promise<MyResponse<any>> => {
        return networkService.get<any>(BASE_URL + "/pdf", []);
    };

    addOnBoardingDocuments = (dossierType: string): Promise<MyResponse<number>> => {
        return networkService.post<null, number>(BASE_URL + "/infocert/addOnBoardingDocument/" + dossierType, null, []);
    };

    signDocuments = (): Promise<MyResponse<CreateDossierResponse>> => {
        return networkService.get<CreateDossierResponse>(BASE_URL + "/infocert/signDocument/1", []);
    };

    processStart = (dossierToken: string, dossierType: string): Promise<MyResponse<ProcessStartResponse>> => {
        const headers = {
            'Authorization': INFOCERT_AUTH,
        };
        return dossierType === "SPID" ? networkService.post<null, ProcessStartResponse>(INFO_CERT_SPID_URL + "process/" + dossierToken + "/start", null, headers, true)
            : networkService.post<null, ProcessStartResponse>(INFO_CERT_URL + "process/" + dossierToken + "/start", null, headers, true);
    };

    getAuthToken = (): Promise<MyResponse<ProcessStartResponse>> => {
        return networkService.get<ProcessStartResponse>(BASE_URL + "/infocert/getAuthToken", []);
    };

    webIdStart = (sessionKey: string, taskId: string): Promise<MyResponse<WebIdStartResponse>> => {
        const headers = {
            'Authorization': 'COP ' + sessionKey,
        };
        return networkService.post<null, WebIdStartResponse>(INFO_CERT_URL + "webid/" + taskId + "/start", null, headers, true);
    };

    webIdQueue = (sessionKey: string, taskId: string): Promise<MyResponse<WebIdQueueResponse>> => {
        const headers = {
            'Authorization': 'COP ' + sessionKey,
        };
        return networkService.post<null, WebIdQueueResponse>(INFO_CERT_URL + "webid/" + taskId + "/queue", null, headers, true);
    };

    webIdDossier = (sessionKey: string, taskId: string): Promise<MyResponse<WebIdDossierResponse>> => {
        const headers = {
            'Authorization': 'COP ' + sessionKey,
        };
        return networkService.get<WebIdDossierResponse>(INFO_CERT_URL + "webid/" + taskId + "/dossier", headers, true);
    };

    oneShotStart = (sessionKey: string, taskId: string, dossierType: string): Promise<MyResponse<OneShotStartResponse>> => {
        const headers = {
            'Authorization': 'COP ' + sessionKey,
        };
        return dossierType === "SPID" ?  networkService.post<null, OneShotStartResponse>(INFO_CERT_SPID_URL + "oneshot/" + taskId + "/start", null, headers, true)
            : networkService.post<null, OneShotStartResponse>(INFO_CERT_URL + "oneshot/" + taskId + "/start", null, headers, true);
    };

    oneShotRegister = (sessionKey: string, taskId: string, oneShotRegisterRequest: OneShotRegisterRequest, dossierType: string): Promise<MyResponse<OneShotRegisterResponse>> => {
        const headers = {
            'Authorization': 'COP ' + sessionKey,
        };
        return dossierType === "SPID" ? networkService.post<OneShotRegisterRequest, OneShotRegisterResponse>(INFO_CERT_SPID_URL + "oneshot/" + taskId + "/register", oneShotRegisterRequest, headers, true)
            : networkService.post<OneShotRegisterRequest, OneShotRegisterResponse>(INFO_CERT_URL + "oneshot/" + taskId + "/register", oneShotRegisterRequest, headers, true);
    };

    oneShotIssueSign = (sessionKey: string, taskId: string, oneShotIssueSignRequest: OneShotIssueSignRequest, dossierType: string): Promise<MyResponse<OneShotIssueSignResponse>> => {
        const headers = {
            'Authorization': 'COP ' + sessionKey,
        };
        return dossierType === "SPID" ? networkService.post<OneShotIssueSignRequest, OneShotIssueSignResponse>(INFO_CERT_SPID_URL + "oneshot/" + taskId + "/issueandsign", oneShotIssueSignRequest, headers, true)
            : networkService.post<OneShotIssueSignRequest, OneShotIssueSignResponse>(INFO_CERT_URL + "oneshot/" + taskId + "/issueandsign", oneShotIssueSignRequest, headers, true);
    };

    oneShotSendOTP = (sessionKey: string, taskId: string, dossierType: string): Promise<MyResponse<OneShotSendOTPResponse>> => {
        const headers = {
            'Authorization': 'COP ' + sessionKey,
        };
        return dossierType === "SPID" ? networkService.post<null, OneShotSendOTPResponse>(INFO_CERT_SPID_URL + "oneshot/" + taskId + "/sendotp", null, headers, true)
            : networkService.post<null, OneShotSendOTPResponse>(INFO_CERT_URL + "oneshot/" + taskId + "/sendotp", null, headers, true);
    };

    remoteSignStart = (sessionKey: string, taskId: string): Promise<MyResponse<RemoteSignStartResponse>> => {
        const headers = {
            'Authorization': 'COP ' + sessionKey,
        };
        return networkService.post<null, RemoteSignStartResponse>(INFO_CERT_URL + "remotesign/" + taskId + "/start", null, headers, true);
    };

    remoteSignPrepareSignature = (sessionKey: string, taskId: string): Promise<MyResponse<RemoteSignPrepareSignatureResponse>> => {
        const headers = {
            'Authorization': 'COP ' + sessionKey,
        };
        return networkService.post<null, RemoteSignPrepareSignatureResponse>(INFO_CERT_URL + "remotesign/" + taskId + "/preparesignature", null, headers, true);
    };

    remoteSignSignContracts = (sessionKey: string, taskId: string, request: RemoteSignSignContractsRequest): Promise<MyResponse<RemoteSignSignContractsResponse>> => {
        const headers = {
            'Authorization': 'COP ' + sessionKey,
        };
        return networkService.post<RemoteSignSignContractsRequest, RemoteSignSignContractsResponse>(INFO_CERT_URL + "remotesign/" + taskId + "/signcontracts", request, headers, true);
    };

    remoteSignSendOTP = (sessionKey: string, taskId: string): Promise<MyResponse<RemoteSignSendOTPResponse>> => {
        const headers = {
            'Authorization': 'COP ' + sessionKey,
        };
        return networkService.post<null, RemoteSignSendOTPResponse>(INFO_CERT_URL + "remotesign/" + taskId + "/sendotp", null, headers, true);
    };

    logout = (): Promise<MyResponse<InfoCertLogoutResponse>> => {
        return networkService.get<InfoCertLogoutResponse>(BASE_URL + "/infocert/logout", []);
    };

    deleteDossier = (): Promise<MyResponse<InfoCertDeleteDossierResponse>> => {
        return networkService.get<InfoCertDeleteDossierResponse>(BASE_URL + "/infocert/deleteDossier", []);
    };

    getActions = (): Promise<MyResponse<ActionDTO[]>> => {
        return networkService.get<ActionDTO[]>(BASE_URL + "/infocert/signActionRequests");
    };

    getSignersCombinations = (type: ActionTypes, actionData: string, referenceId?: string,): Promise<MyResponse<CombinationsResponse>> => {
        const requestData = {actionData} as CombinationsRequest;
        if (referenceId) requestData.referenceId = referenceId;
        return networkService.post<CombinationsRequest, CombinationsResponse>(BASE_URL + "/infocert/signer/" + type, requestData);
    };

    signDocument = (data: any, combinationId: string): Promise<MyResponse<number>> => {
        const requestData = {
            actionData: JSON.stringify(data)
        } as SignDocumentRequestDTO;
        return networkService.post<SignDocumentRequestDTO, number>(BASE_URL + "/infocert/signDocument/" + combinationId, requestData);
    };

    updateSignStatus = (token: string, signingStatus: ActionSigningStatus): Promise<MyResponse<string>> => {
        const requestData = {signingStatus} as SignStatusRequest;
        return networkService.put<SignStatusRequest, string>(BASE_URL + "/infocert/signActionRequests/status/" + token, requestData);
    };

    getStatus = (): Promise<MyResponse<InfocertStatusDTO>> => {
        return networkService.get<InfocertStatusDTO>(BASE_URL + "/infocert/status");
    };

    actionRequestDocument = (id: string): Promise<MyResponse<string>> => {
        return networkService.get<string>(BASE_URL + "/infocert/actionRequestDocument/" + id);
    };

    actionRequestLegalDocument = (id: string): Promise<MyResponse<string>> => {
        return networkService.get<string>(BASE_URL + "/infocert/actionRequestLegalDocument/" + id);
    };

    getArchiveDocumentsAdmin = (companyId: string, actionType?: string): Promise<MyResponse<ActionDTO[]>> => {
        let url = `${BASE_URL}/infocert/documents/admin/${companyId}`;
        if (actionType) url += "/" + actionType;
        return networkService.get<ActionDTO[]>(url);
    };

    getArchive = (actionId?: string, referenceId?: string): Promise<MyResponse<ArchiveItemDTO[]>> => {
        let url = `${BASE_URL}/infocert/documents`;
        if (actionId) url += "/" + actionId;
        if (referenceId) url += "/" + referenceId;
        return networkService.get<ArchiveItemDTO[]>(url);
    };
}

export const infoCertService = new InfoCertService();
