import * as React from 'react';
import {useTranslation} from "react-i18next";
import "./SignWrapper.scss";
import {AppState} from "../../../redux/store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect} from "react-redux";
import ScreenWrapper from "../../AdminComponent/ScreenWrapper/ScreenWrapper";
import NoDataBox from "../NoDataBox/NoDataBox";
import Table from "../Table/Table";
import CheckBox from "../CheckBox/CheckBox";
import Button, {BUTTON_TYPE} from "../Button/Button";
import forward_arrow_right from "../../../assets/Icons/forward_arrow_right.svg";
import forward_arrow_left from "../../../assets/Icons/forward_arrow_left.svg";
import {clearFailError, getActions, getCombinations, signDocument} from "../../../redux/infocert/infocert-actions";
import {RESET_ACTIONS, SET_ACTION_DATA, SET_COMBINATION, SET_COMBINATIONS_ERROR} from "../../../redux/infocert/infocert-reducers";
import {ActionTypes, CombinationDTO} from "../../../model/InfoCertDTO";
import ProcessComplete from "../../../screens/admin/ProcessComplete/ProcessComplete";
import {useHistory} from "react-router-dom";
import {isEmpty} from "../../../utils/commonUtils";
import tick from "../../../assets/Icons/tick.svg";
import warning from "../../../assets/Icons/warning_yellow.svg";
import {rolePaths} from "../../NavBar/NavBar";
import "../Table/Table.scss";
import {Roles} from "../../../model/UserDTO";

const styles = {
    id: {width: "4em"},
    signer1: {flex: 1},
    signer2: {flex: 1},
    signer3: {flex: 1},
};

export interface WrappedComponentProps {
    onConfirm: any
    error: any
    resetError: any
    [propName: string]: any;
}

interface Props {
    onCloseCombinations?: any
    actionRequestData?: any
    isFetchingCombinations?: any
    combinationsFail?: any
    createAction?: any
    setActionData?: any
    getCombinations?: any
    setCombination?: any
    selectedCombination?: any
    signersCombination?: any
    wrapComponent: any
    setCombinationsError: any,
    actionType: ActionTypes,
    referenceId?: string
    [propName: string]: any,
}

const SignWrapper: React.FC<Props> = ({
                                          onCloseCombinations,
                                          actionRequestData,
                                          isFetchingCombinations,
                                          combinationsFail,
                                          createAction,
                                          setActionData,
                                          getCombinations,
                                          setCombination,
                                          selectedCombination,
                                          signersCombination,
                                          setCombinationsError,
                                          wrapComponent: Wrap,
                                          actionType,
                                          resetCombinations,
                                          signatureStatus,
                                          actionData,
                                          referenceId,
                                          userRole,
                                          onSuccess,
                                          getActionsSilent,
                                          signFail,
                                          clearFailError,
                                          style = {},
                                          ...additionalProps
                                      }) => {
    const history = useHistory();

    const [actionDataDefined, setActionDataDefined] = React.useState(false);
    const [noSigners, setNoSigners] = React.useState(false);
    const [actionCreated, setActionCreated] = React.useState(false);
    const [signatureBlocked, setSignatureBlocked] = React.useState(false);
    const [signError, setSignError] = React.useState<string>("");
    const {t, i18n} = useTranslation();

    React.useEffect(() => {
        resetCombinations();
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        setSignError(signFail);
    }, [signFail]);

    React.useEffect(() => {
        if (signersCombination) {
            if (signersCombination.length)
                setActionDataDefined(!isEmpty(actionRequestData) && signatureStatus === "OK" && !combinationsFail);
            else if (signersCombination.length === 0) {
                setNoSigners(true);
            }
        } else {
            setNoSigners(false);
            setActionDataDefined(false);
        }
        clearFailError();
        // eslint-disable-next-line
    }, [actionRequestData, signersCombination, signatureStatus, combinationsFail]);

    React.useEffect(() => {
        setSignatureBlocked(signatureStatus === "BLOCKED");
        // eslint-disable-next-line
    }, [signatureStatus]);

    React.useEffect(() => {
        !isEmpty(actionData) && setActionDataAndGetCombinations(actionData, referenceId);
        // eslint-disable-next-line
    }, [actionData]);

    const setActionDataAndGetCombinations = async (actionData: any, referenceId?: string) => {
        //we call combinations with action data to validate. If we receive success we go on
        //otherwise the validation error is retrieved from combinations response and shown
        setActionData(actionData);
        return await getCombinations(actionType, JSON.stringify(actionData), referenceId);
    };
    const headers = [
        {
            style: styles.id
        },
        {
            label: t("common.request.header1"),
            style: styles.signer1
        },
        {
            label: t("common.request.header2"),
            style: styles.signer2
        },
        {
            label: t("common.request.header3"),
            style: styles.signer3
        },

    ];

    const confirmHandler = async () => {
        if (actionRequestData && selectedCombination) {
            const success = await createAction(actionRequestData, selectedCombination.id);
            setActionCreated(success);
            success && getActionsSilent();
            success && onSuccess && onSuccess();
        }
    };

    const closeHandler = () => {
        resetCombinations();
        getActionsSilent();
        onCloseCombinations?.();
        setActionDataDefined(false);
        setNoSigners(false);
        setActionCreated(false);
        setSignatureBlocked(false);
        setCombinationsError("");
    };

    const backHandler = () => {
        resetCombinations();
        getActionsSilent();
        setActionDataDefined(false);
        setNoSigners(false);
        setActionCreated(false);
        setSignatureBlocked(false);
        setCombinationsError("");
    };

    const renderRow = (item: CombinationDTO, index: any) => (
        <div key={index} className="combinations__tableRow" onClick={() => setCombination(item)}>
            <span style={styles.id} className="table__item table__item--actions">
                <CheckBox
                    checked={selectedCombination && item.id === selectedCombination.id}
                    radioButton
                />
            </span>
            <span style={styles.signer1} className="table__item table__item--actions">{item.signer1Name || ""}</span>
            <span style={styles.signer2} className="table__item table__item--actions">{item.signer2Name || ""}</span>
            <span style={styles.signer3} className="table__item table__item--actions table__item--requests">{item.signer3Name || ""}</span>
        </div>);


    const onAbort = () => history.push(rolePaths[userRole].sign);

    const completeComponent = <ProcessComplete
        title={t("common.request.successTitle")}
        icon={tick}
    >
        <div className="combinations__successDescription1">
            {t("common.request.successSubTitle1")}
        </div>
        {selectedCombination &&
        <>
            {selectedCombination.signer1 && <div className="combinations__successEmail">
                {selectedCombination.signer1}
            </div>}
            {selectedCombination.signer2 && <div className="combinations__successEmail">
                {selectedCombination.signer2}
            </div>}
            {selectedCombination.signer3 && <div className="combinations__successEmail">
                {selectedCombination.signer3}
            </div>}
        </>
        }
        <div className="combinations__successDescription2">
            {t("common.request.successSubTitle2")}
        </div>
        <div className="combinations__successButtonContainer">
            {userRole !== Roles.ADMIN &&
            <div className="combinations__successButton">
                <Button
                    onClick={onAbort}
                    label={t("common.request.successButtonSign")}
                />
            </div>
            }
            <div className="combinations__successButton">
                <Button
                    onClick={closeHandler}
                    label={t("common.request.successButton")}
                />
            </div>
        </div>
    </ProcessComplete>;

    const signBlockedComponent = <ProcessComplete
        title={t("common.request.blockedTitle")}
        icon={warning}
    >
        <div className="combinations__successDescription1">
            {t("common.request.blockedSubTitle1")}
        </div>
        <div className="combinations__successButtonContainer">
            {userRole !== Roles.ADMIN &&
            <div className="combinations__successButton">
                <Button
                    onClick={onAbort}
                    label={t("common.request.successButtonSign")}
                />
            </div>
            }
            <div className="combinations__successButton">
                <Button
                    onClick={closeHandler}
                    label={t("common.request.successButton")}
                />
            </div>
        </div>
    </ProcessComplete>;

    const noSignersComponent = <ProcessComplete
        title={t("common.request.noSignersTitle")}
        icon={warning}
    >
        <div className="combinations__successDescription1">
            {t("common.request.noSignersDesr")}
        </div>
        <div className="combinations__successButtonContainer">
            <div className="combinations__successButton">
                <Button
                    onClick={closeHandler}
                    label={t("common.request.successButton")}
                />
            </div>
        </div>
    </ProcessComplete>;

    const failSignComponent = <ProcessComplete
        title={t("common.request.title")}
        icon={warning}
    >
        <div className="combinations__successDescription1">
            {combinationsFail && i18n.exists(combinationsFail) ? t(combinationsFail) : t("errors.infocert.documentSignFail")}
        </div>
        <div className="combinations__successButtonContainer">
            <div className="combinations__successButton">
                <Button
                    onClick={backHandler}
                    label={t("common.button.back")}
                />
            </div>
            <div className="combinations__successButton">
                <Button
                    onClick={closeHandler}
                    label={t("common.request.successButton")}
                />
            </div>
        </div>
    </ProcessComplete>;

    const showNoDataBox = ((signersCombination && signersCombination.length === 0) && isFetchingCombinations === false);

    const combinationsComponent = <ScreenWrapper
        title={t("common.request.title")}
        description={t("common.request.description")}
    >
        <Table
            noItemsRender={showNoDataBox && <NoDataBox description={t("common.request.noCombinations")}/>}
            listToDisplay={signersCombination}
            defaultSortingFn={(a: CombinationDTO, b: CombinationDTO) => {
                const countA = (a.signer1 ? 1 : 0) + (a.signer2 ? 1 : 0) + (a.signer3 ? 1 : 0);
                const countB = (b.signer1 ? 1 : 0) + (b.signer2 ? 1 : 0) + (b.signer3 ? 1 : 0);
                return countB > countA ? 1 : -1;
            }}
            renderItem={renderRow}
            headers={headers}
            className="shadowCard"
            error={combinationsFail || t(signError)}
            headerClassNameModifier="combinations__header"
            headerItemModifier="table__headerItem--actions"
            itemForPage={15}
        />
        <div className="combinations__buttonsContainer">
            <div className="combinations__backButton">
                <Button
                    buttonType={BUTTON_TYPE.primaryInverted}
                    label={t("common.button.back")}
                    icon={forward_arrow_left}
                    onClick={() => {
                        if (!Wrap) closeHandler();
                        else setActionDataDefined(false);
                    }}
                    iconOnTheLeft
                />
            </div>
            <Button
                buttonType={BUTTON_TYPE.greenButton}
                label={t("common.request.selectAndSend")}
                onClick={confirmHandler}
                icon={forward_arrow_right}
                disabled={!selectedCombination}
            />
        </div>
    </ScreenWrapper>;
    let out = null;
    if (actionDataDefined) out = combinationsComponent;
    if (actionCreated) out = completeComponent;
    if (signatureBlocked) out = signBlockedComponent;
    if (noSigners) out = noSignersComponent;
    if (!!combinationsFail) out = failSignComponent;
    const styleWrap: any = {};
    if (out) styleWrap.display = "none";
    return <div style={style}>
        <div className="wrap" style={styleWrap}>
            {Wrap && <Wrap
                onConfirm={setActionDataAndGetCombinations}
                error={combinationsFail}
                resetError={() => setCombinationsError("")}
                onClose={closeHandler}
                {...additionalProps}
            />}
        </div>
        {out}
    </div>;
};

const mapStateToProps = (store: AppState) => {
    return {
        isFetchingCombinations: store.infoCert.isFetchingCombinations,
        combinationsFail: store.infoCert.combinationsFail,
        actionRequestData: store.infoCert.actionRequestData,
        selectedCombination: store.infoCert.selectedCombination,
        signersCombination: store.infoCert.combinationsResponse.signersCombination,
        signatureStatus: store.infoCert.combinationsResponse.status,
        userRole: store.auth.role,
        signFail: store?.infoCert?.signFail
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        createAction: (data: any, id: string) => dispatch(signDocument(data, id)),
        getActionsSilent: () => dispatch(getActions(true)),
        clearFailError: () => dispatch(clearFailError()),
        setCombination: (payload: CombinationDTO) => dispatch({type: SET_COMBINATION, payload}),
        setActionData: (payload: string) => dispatch({type: SET_ACTION_DATA, payload}),
        getCombinations: (type: ActionTypes, actionData: string, referenceId?: string) => dispatch(getCombinations(type, actionData, referenceId)),
        setCombinationsError: (payload: string) => dispatch({type: SET_COMBINATIONS_ERROR, payload}),
        resetCombinations: () => dispatch({type: RESET_ACTIONS}),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignWrapper);
