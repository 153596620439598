import i18n from "../i18n";
import {Bounce, toast} from "react-toastify";

class ToastUtils {

    successToast = (key: string, delay?: number, label?: string) => {
        if (!label) {
            try {
                label = i18n.exists(key) ? i18n.t(key) : i18n.t('notifications.defaultSuccess');
            } catch {
                label = i18n.t('notifications.defaultSuccess');
            }
        }
        toast.success(label, {position: toast.POSITION.BOTTOM_RIGHT, delay, containerId: "default"});
    };

    copyToast = (key: string, delay?: number, label?: string) => {

        if (!label) {
            try {
                label = i18n.exists(key) ? i18n.t(key) : i18n.t('notifications.defaultSuccess');
            } catch {
                label = i18n.t('notifications.defaultSuccess');
            }
        }
        toast.info(label, {
            position: toast.POSITION.BOTTOM_CENTER,
            delay,
            hideProgressBar: true,
            autoClose: 3000,
            transition: Bounce,
            closeButton: false,
            containerId: "copyToClipboard",
        });
    };

    errorToast = (key: string, delay?: number) => {
        let label;
        try {
            label = i18n.exists(key) ? i18n.t(key) : i18n.t('notifications.defaultError');
        } catch {
            label = i18n.t('notifications.defaultError');
        }
        toast.error(label, {position: toast.POSITION.BOTTOM_RIGHT, delay, containerId: "default"});
    };

    warningToast = (keyOrLabel: string, delay?: number) => {
        const label = i18n.exists(keyOrLabel) ? i18n.t(keyOrLabel) : keyOrLabel;
        toast.warn(label, {position: toast.POSITION.BOTTOM_RIGHT, delay, containerId: "default"});
    };
}

export const toastUtils = new ToastUtils();
