import * as React from 'react';
import ScreenWrapper from "../../../components/AdminComponent/ScreenWrapper/ScreenWrapper";
import {useTranslation} from "react-i18next";
import individual from "../../../assets/Images/individual.svg";
import {AppState} from "../../../redux/store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {setNewUserStep} from "../../../redux/admin";
import {connect} from "react-redux";
import NewUserCard from "../../../components/AdminComponent/NewUserCard/NewUserCard";
import "./ChooseTypeOfUser.scss";
import {UsersSteps} from "../../../model/AdminDTO";
import {setRole} from "../../../redux/admin/admin-actions";
import {Roles} from "../../../model/UserDTO";
import {configService} from "../../../service/ConfigService";

const ChooseTypeOfUser: React.FC<any> = ({setUserStep, isCompany, setRole, title, description, onConfirm, showAllOptions, buttons}) => {

    const {t} = useTranslation();

    const inviteDispatcher = (role: Roles) => {
        setRole(role);
        if (onConfirm)
            onConfirm();
        else
            isCompany ? setUserStep(UsersSteps.GET_COMPANY_DATA) : setUserStep(UsersSteps.INVITE_SELECT_COMPANY);
    };

    return (
        <ScreenWrapper
            title={title || t(isCompany ? "admin.chooseTypeOfUser.titleCompany" : "admin.chooseTypeOfUser.title")}
            description={description || t("admin.chooseTypeOfUser.description")}
        >
            <div className="chooseTypeOfUser">
                <div className="chooseTypeOfUser__cardContainer">
                    <NewUserCard
                        styleCard="shadowCard"
                        title={t("admin.chooseTypeOfUser.buyer")}
                        icon={individual}
                        goToNextPage={() => inviteDispatcher(Roles.BUYER)}
                    />
                </div>
                {(!configService.isProdEnvironment() || configService.isDemo() || showAllOptions) &&
                <div className="chooseTypeOfUser__cardContainer">
                    <NewUserCard
                        styleCard="shadowCard"
                        title={t("admin.chooseTypeOfUser.supplier")}
                        icon={individual}
                        goToNextPage={() => inviteDispatcher(Roles.VENDOR)}
                    />
                </div>
                }
                <div className="chooseTypeOfUser__cardContainer">
                    <NewUserCard
                        styleCard="shadowCard"
                        title={t("admin.chooseTypeOfUser.moneylender")}
                        icon={individual}
                        goToNextPage={() => inviteDispatcher(Roles.MONEYLENDER)}
                    />
                </div>
                <div className="chooseTypeOfUser__cardContainer">
                    <div className="chooseTypeOfUser__buttonPreviousContainer">

                    </div>
                </div>
                {buttons}
            </div>
        </ScreenWrapper>
    );
};

const mapStateToProps = (store: AppState) => {
    return {newUserStep: store.admin.newUserStep};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        setUserStep: (payload: any) => dispatch(setNewUserStep(payload)),
        setRole: (payload: string) => dispatch(setRole(payload)),
    };
};

export default
connect(mapStateToProps, mapDispatchToProps)(ChooseTypeOfUser);
