import moment from "moment";

const validateFiscalCode = (fiscalCode: any, fiscalCodeInput: string) => {
    const cfGenerated = fiscalCode.cf;
    const omocodie = fiscalCode.omocodie();
    const arrCFAndOmocodie = [...omocodie, cfGenerated];
    return (fiscalCodeInput && arrCFAndOmocodie.find((item: any) => item === fiscalCodeInput)) || !fiscalCodeInput;
};

export const generateOrValidateFiscalCode = (name: string,
                                             surname: string,
                                             birthDate: string,
                                             birthPlace: string,
                                             sex: string,
                                             region: string,
                                             country: string,
                                             cf: string): string | null => {

    const isItalian = country === "IT";
    const FiscalCode = require('codice-fiscale-js');
    try {
        const momentBD = moment(birthDate);
        if (name && surname && birthDate && birthPlace && sex && region) {
            if (isItalian) {
                let fiscalCode = new FiscalCode({
                    name: name,
                    surname: surname,
                    gender: sex,
                    day: momentBD.date(),
                    month: momentBD.month() + 1,
                    year: momentBD.year(),
                    birthplace: birthPlace,
                    birthplaceProvincia: region // Optional
                });
                const generatedCF = fiscalCode.cf;
                if (validateFiscalCode(fiscalCode, cf)) return cf || generatedCF;
                else return null;

                //FOREIGN NATIONALITY
            } else if (country && cf) {

                const reverseCF = FiscalCode.computeInverse(cf);
                const nationality = reverseCF.birthplace;
                let generatedFiscalCode = new FiscalCode({
                    name: name,
                    surname: surname,
                    gender: sex,
                    day: momentBD && momentBD.date(),
                    month: momentBD && momentBD.month() + 1,
                    year: momentBD && momentBD.year(),
                    birthplace: nationality,
                    birthplaceProvincia: "EE"
                });
                if (validateFiscalCode(generatedFiscalCode, cf)) return cf;
                else return null;

            } else return null;
        }
    } catch (err) {
        console.log("err: ", err);
        return null;
    }
    return null;
};
