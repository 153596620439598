import * as React from 'react';
import {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import "../../../components/Common/Table/Table.scss";
import {AppState} from "../../../redux/store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect} from "react-redux";
import {getUsers} from "../../../redux/admin/admin-actions";
import {countryUtils} from "../../../utils/countryUtils";
import Button, {BUTTON_TYPE} from "../../../components/Common/Button/Button";
import {ModifyUserDTO} from "../../../model/AdminDTO";
import {roleUtils} from "../../../utils/roleUtils";
import "./UserInfoList.scss";
import UserList from "../UserList/UserList";


const UserInfoList: React.FC<any> = ({
                                         getUsers,
                                         getUsersFail,
                                         usersList = [],
                                         companyId,
                                         onSelect,
                                         onPrevious,
                                         ...props
                                     }) => {

    const {t} = useTranslation();
    const [users, setUsers] = React.useState([]);
    const [error, setError] = React.useState("");

    useEffect(() => {
        getUsers(companyId);
    }, [getUsers, companyId]);

    useEffect(() => {
        setError(getUsersFail);
    }, [getUsersFail]);

    React.useEffect(() => {
        usersList && setUsers(usersList.map(({userId, roles, companyName, companyId, companyCountry, ...rest}: ModifyUserDTO) =>
            ({
                email: userId,
                userId: {userId},
                roles,
                role: t(`navbar.${roleUtils.extractMainRole(roles)}`),
                companyName: companyName || "---",
                companyId: companyId || "---",
                companyCountry: companyCountry ? countryUtils.fromCodeToCountryName(companyCountry) : "---",
                ...rest
            })));
        // eslint-disable-next-line
    }, [usersList]);

    return <UserList
        {...props}
        users={users}
        btnRender={({userId: {userId}}: any) => {
            return (<Button
                label={t("common.button.modify")}
                onClick={() => onSelect?.(userId)}
                buttonType={BUTTON_TYPE.greenButton}
            />);
        }}
        onPrevious = {onPrevious}
        error={error}
    />;
};

const mapStateToProps = (store: AppState) => {
    return {
        usersList: store.admin.getUsersSuccess,
        getUsersIsFetching: store.admin.getUsersIsFetching,
        getUsersFail: store.admin.getUsersFail,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        getUsers: (vat?: string) => dispatch(getUsers(vat)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps)
(UserInfoList);


