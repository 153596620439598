import * as React from 'react';
import {useTranslation} from "react-i18next";
import "./UploadLegalFiles.scss";
import {LegalFilesDTO} from "../../../model/UserDTO";
import pdf_icon from "../../../assets/Icons/pdf_icon.svg";
import moment from "moment";
import {uploadImage} from "../../../utils/dynamicSVG";
import OnBoardingUploadPdf from "../../../components/OnBoardingComponent/onBoardingUploadPdf/OnBoardingUploadPdf";
import DownloadPDF from "../../../components/Common/DownloadPDF/DownloadPDF";

const DisplayLegalFiles: React.FC<any> = ({
                                              companyFiles,
                                              error,
                                              removeDocument,
                                              setError,
                                              uploadPdf,
                                              setIsUploading,
                                              noEdit
                                          }) => {

    const {t} = useTranslation();

    const [showUploadSection, setShowUploadSection] = React.useState(false);
    const [showSummary, setShowSummary] = React.useState(true);

    const cancelUpload = async () => {
        setError("");
        setShowUploadSection(false);
        setShowSummary(true);
        setIsUploading?.(false);
    };
    return (<>

            {showUploadSection && !showSummary &&
            <>
                <div className="setCompanyAndRateData__titleRateData">
                    {t("onBoarding.company.definitionRole.uploadDocument")}
                </div>

                <OnBoardingUploadPdf
                    uploadPdf={async (...props) => {
                        const success = await uploadPdf(...props);
                        if (success) {
                            setError("");
                            setShowSummary(true);
                            setIsUploading?.(false);
                        }

                    }}
                    cancelUpload={cancelUpload}
                    error={error}
                    resetError={(value: string) => setError(value)}
                />
            </>
            }
            {showSummary && !error && (noEdit ? !!companyFiles?.length : true) &&
            <>
                <div className="definitionRole__titleSection">
                    {t("onBoarding.company.definitionRole.companyData")}
                </div>
                {!noEdit && <span className="definitionRole__descriptionContainer">
                        <span className="definitionRole__description">{t("onBoarding.company.definitionRole.description")}</span>
                            <span onClick={() => {
                                setShowUploadSection(true);
                                setIsUploading?.(true);
                                setError("");
                                setShowSummary(false);
                            }} className="definitionRole__description definitionRole__description--clickable"
                            >
                            {t("onBoarding.company.definitionRole.addDocument")}{uploadImage("18px", "19px", {marginLeft: "5px"}, "OnBoardingEffectiveOwner__colorFill")}
                            </span>
                    </span>}
            </>
            }
            <div style={{display: "flex", flexWrap: "wrap"}}>
                {showSummary && !error && companyFiles.map((item: LegalFilesDTO, index: number) => {
                    return <div className="definitionRole__summarySection" key={index}>
                        <div className="definitionRole__summarySectionRow">
                            {noEdit
                                ? <DownloadPDF fileData={item}/>
                                : <>
                                    <img
                                        src={pdf_icon}
                                        alt="pdf_icon"
                                        className="definitionRole__pdfImageSummary"
                                    />
                                    <div className="definitionRole__pdfName" style={{marginLeft: "20px"}}>
                                        {item?.name}
                                    </div>
                                </>
                            }
                        </div>
                        <div className="definitionRole__summarySectionRow" style={{marginTop: "20px"}}>
                            <div className="definitionRole__summarySectionItem">
                                <div className="definitionRole__summarySectionItemTitle">
                                    {t("onBoarding.company.definitionRole.documentType")}
                                </div>
                                <div className="definitionRole__summarySectionItemValue">
                                    {t("onBoarding.company.definitionRole.docTypes." + item.documentType)}
                                </div>
                            </div>
                        </div>

                        <div className="definitionRole__summarySectionRow">
                            <div className="definitionRole__summarySectionItem">
                                <div className="definitionRole__summarySectionItemTitle">
                                    {t("onBoarding.company.definitionRole.documentData")}
                                </div>
                                <div className="definitionRole__summarySectionItemValue">
                                    {moment(item.documentDate).format('DD-MM-YYYY')}
                                </div>
                            </div>
                            <div className="definitionRole__summarySectionItem">
                                <div className="definitionRole__summarySectionItemTitle">
                                    {t("onBoarding.company.definitionRole.documentDataDeadline")}
                                </div>
                                <div className="definitionRole__summarySectionItemValue">
                                    {item.documentExpiryDate ? moment(item.documentExpiryDate).format('DD-MM-YYYY') : "-"}
                                </div>
                            </div>
                        </div>
                        {!noEdit && <div
                            className="definitionRole__removeDocument"
                            onClick={() => removeDocument(item)}
                        >
                            {t("onBoarding.company.definitionRole.removeDocument")}
                        </div>}
                    </div>;
                })}
            </div>
        </>
    );
};

export default DisplayLegalFiles;
