import * as React from 'react';
import {AppState} from "../../../redux/store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import CardContainerWithButtons from "../../../components/Common/CardContainerWithButtons/CardContainerWithButtons";
import {timeUtils} from "../../../utils/timeUtils";
import Button from "../../../components/Common/Button/Button";
import {
    configuration_prices_steps,
    CreateCreditLineMoneylenderDTO,
    DurationPayablesType
} from "../../../model/MoneylenderDTO";
import {setConfigurationPricesStep} from "../../../redux/moneylender/moneylender-actions";
import "../../../components/CreateCreditLine/CreateCreditLine.scss";
import ContainerRow from "../../../components/Common/ContainerRow/ContainerRow";
import TextInput from "../../../components/Common/TextInput/TextInput";
import {numberUtils} from "../../../utils/numberUtils";
import ReactMarkdown from "react-markdown";
import ScrollableTable from "../../../components/Common/ScrollableTable/ScrollableTable";
import {countryUtils} from "../../../utils/countryUtils";
import Table from "../../../components/Common/Table/Table";
import "../../../components/MoneylenderVendorInfo/MoneylenderVendorInfo.scss";
import Ellapsis from "../../../components/Common/Ellapsis/Ellapsis";

export const stylesCreditLineTable = {
    checkbox: {width: "40px", display: "block", flex: "none"},
    id: {width: "180px", display: "block", flex: "none"},
    description: {},
    currency: {width: "100px", display: "block", flex: "none"},
    delay: {width: "100px", display: "block", flex: "none"},
    delayRate: {width: "100px", display: "block", flex: "none"},
    tax: {width: "100px", display: "block", flex: "none"},
    spread: {width: "100px", flex: "none"},
};

export const headersProgramTable = [
    {label: "dynamicDiscount.newDynamicDiscount.programTable.headers.id", style: stylesCreditLineTable.id},
    {label: "dynamicDiscount.newDynamicDiscount.programTable.headers.description", style: stylesCreditLineTable.description},
    {label: "dynamicDiscount.newDynamicDiscount.programTable.headers.currency", style: stylesCreditLineTable.currency},
    {label: "dynamicDiscount.newDynamicDiscount.programTable.headers.delayRate", style: stylesCreditLineTable.delayRate},
    {label: "dynamicDiscount.newDynamicDiscount.programTable.headers.delay", style: stylesCreditLineTable.delay},
    {label: "dynamicDiscount.newDynamicDiscount.programTable.headers.tax", style: stylesCreditLineTable.tax},
    {label: "dynamicDiscount.newDynamicDiscount.programTable.headers.spread", style: stylesCreditLineTable.spread},
];

export const getCreditLineProgramsRow = ({programName, description, currency, daysOfDelay, delayRate, fixedBaseRate, variableDelayRate, variableSpreadRate}: any, index: number, array: any, t: any) => {
    const daysOfDelayToDisplay = (daysOfDelay === 1 ? daysOfDelay + " " + t("moneylender.creditLine.day") : daysOfDelay + " " + t("moneylender.creditLine.days"));
    const delayRateValue = variableDelayRate ? t(`rates.${variableDelayRate}`) : numberUtils.reverseAndFormatToLocaleNumber(fixedBaseRate) + "%";
    const spread = variableSpreadRate ? numberUtils.formatToPercent(variableSpreadRate) : "-";
    return (
        <div key={index}>
            <div key={index} className="createCreditLine__tableRow">
                <span title={programName} className="table__item" style={stylesCreditLineTable.id}>{programName}</span>
                <span title={description || "-"} className="table__item" style={stylesCreditLineTable.description}>
                        <Ellapsis>{description || "-"}</Ellapsis>
                    </span>
                <span title={currency} className="table__item" style={stylesCreditLineTable.currency}>{currency}</span>
                <span title={t(`SCFProgram.modal.${delayRate}`)} className="table__item" style={stylesCreditLineTable.delayRate}>{t(`SCFProgram.modal.${delayRate}`)}</span>
                <span title={daysOfDelayToDisplay} className="table__item" style={stylesCreditLineTable.delay}>{daysOfDelayToDisplay}</span>
                <span title={delayRateValue} className="table__item" style={stylesCreditLineTable.tax}>{delayRateValue}</span>
                <span title={spread} className="table__item" style={stylesCreditLineTable.spread}>{spread}</span>
            </div>
            <div key={index + "line"} className={`table__line ${index === array.length - 1 ? "table__line--last" : ""}`}/>
        </div>
    );
};

const CreditLineDetails: React.FC<any> = ({creditLineDetails, setConfigurationPricesStep}) => {

    const {t} = useTranslation();
    const [data] = React.useState(creditLineDetails as CreateCreditLineMoneylenderDTO);
    const [countries, setCountries] = React.useState<any[]>([]);

    React.useEffect(() => {
        const countriesWithoutDuplicates = Array.from(new Set<string>(data.moneylenderWhiteList));
        let countriesToDisplay: { countryName: string }[] = [];
        countriesWithoutDuplicates.forEach((countryName: string) =>
            countriesToDisplay.push({countryName: countryUtils.fromCodeToCountryName(countryName)}));
        setCountries(countriesToDisplay);
    }, [data]);

    // noinspection SuspiciousTypeOfGuard
    return (
        <div className="createCreditLine">
            <CardContainerWithButtons
                title={
                    <div style={{marginLeft: "40px", marginRight: "40px", marginBottom: "20px"}}>
                        <span className="moneylenderVendorInfo__topSection">
                            <span className="moneylenderVendorInfo__title">
                                {t("moneylender.creditLine.titleCard") + data.creditLineName}
                            </span>
                            <span className="moneylenderVendorInfo__rightSide" style={{alignItems: "center"}}>
                                <div className="moneylenderVendorInfo__rightSideSub">
                                <div className="moneylenderVendorInfo__firstChild">
                                    {t("moneylender.creditLine.creditLineCode")}
                                </div>
                                <div className="moneylenderVendorInfo__secondChild">
                                   <Ellapsis title={data.creditLineCode || "-"}/>
                                </div>
                                </div>
                                <div className="moneylenderVendorInfo__rightSideSub">
                                <div className="moneylenderVendorInfo__firstChild">
                                    {t("SCFProgram.creationDate")}
                                </div>
                                <div className="moneylenderVendorInfo__secondChild">
                                    {(timeUtils.formatToLocaleDate(data.creationDate)) || "-"}
                                </div>
                                </div>
                                <div className="moneylenderVendorInfo__rightSideSub">
                                <div className="moneylenderVendorInfo__firstChild">
                                    {t("dynamicDiscount.creditLineList.residual") + ": "}
                                </div>
                                <div className="moneylenderVendorInfo__title">
                                    {numberUtils.formatToLocaleNumber(data.residualAmount) || "-"} {data.currency}
                                </div>
                                </div>
                            </span>
                        </span>
                        <div className="line"/>
                    </div>
                }
                onPreviousButtonClick={() => setConfigurationPricesStep("")}
                confirmButton={
                    <div className="containerButtonConfirm containerButtonConfirm--marginRight80">
                        <Button
                            label={t("moneylender.configurationPrices.goToConfigurationsPrices")}
                            onClick={() => setConfigurationPricesStep(configuration_prices_steps.LIST_CONFIGURATION)}
                            className="moneylenderVendorInfo__button"
                        />
                    </div>
                }
                style={{marginTop: "20px"}}
            >
                <ContainerRow>
                    <div className="inputContainer">
                        <div className="inputContainer">
                            <TextInput
                                title={t("moneylender.creditLine.buyer")}
                                value={data.businessName}
                                disableInput
                            />
                        </div>
                    </div>
                    <div className="inputContainer">
                        <TextInput
                            title={t("moneylender.creditLine.idBuyer")}
                            value={data.buyerId}
                            disableInput
                        />
                    </div>
                    <div className="inputContainer">
                        <TextInput
                            title={t("moneylender.creditLine.idRegisteredToBuyer")}
                            value={data.buyerRelationId}
                            disableInput
                        />
                    </div>
                </ContainerRow>
                <ContainerRow>
                    <div className="inputContainer">
                        <TextInput
                            title={t("moneylender.creditLine.currency")}
                            value={data.currency}
                            disableInput
                        />
                    </div>
                    <div className="inputContainer"/>
                    <div className="inputContainer"/>
                </ContainerRow>
                <Table
                    renderItem={(item, index, array) => getCreditLineProgramsRow(item, index, array, t)}
                    headers={headersProgramTable.map((header: any) => {
                        return {label: t(header.label), style: header.style};
                    })}
                    listToDisplay={data.programs}
                    headerClassNameModifier="table__header--noPadding"
                />
                <ContainerRow>
                    <div className="inputContainer">
                        <TextInput
                            title={t("moneylender.creditLine.amountOfCreditLine")}
                            value={numberUtils.reverseAndFormatToLocaleNumber(data.amount)}
                            symbol={data.currency}
                            disableInput
                        />
                    </div>
                    <div className="inputContainer">
                        <TextInput
                            title={t("moneylender.creditLine.originalAmountOfCreditLine")}
                            value={numberUtils.reverseAndFormatToLocaleNumber(data.originalAmount)}
                            symbol={data.currency}
                            disableInput
                        />
                    </div>
                    <div className="inputContainer"/>
                </ContainerRow>
                <ContainerRow>
                    <div className="inputContainer" style={{marginTop: "20px"}}>
                        <TextInput
                            title={t(data.iban ? "dynamicDiscount.newDynamicDiscount.iban" : "dynamicDiscount.newDynamicDiscount.swift")}
                            value={data.iban || data.swift}
                            disableInput
                        />
                    </div>
                    <div className="inputContainer"/>
                </ContainerRow>
                <div className="customDropdown__titleContainer customDropdown__titleContainer--marginTop20">
                    <div className="customDropdown__title">
                        {t("moneylender.creditLine.flagRevolving")}
                    </div>
                </div>
                <div className="createCreditLine__descriptionInput">
                    {t("moneylender.creditLine.flagRevolvingDescription")}
                </div>
                <ContainerRow>
                    <div className="inputContainer">
                        <TextInput
                            disableInput
                            value={(typeof data.revolving === "boolean" && (data.revolving ? t("common.button.yes") : t("common.button.no"))) || ""}
                        />
                    </div>
                    <div className="inputContainer"/>
                    <div className="inputContainer"/>
                </ContainerRow>
                <ContainerRow>
                    <div className="inputContainer">
                        <TextInput
                            disableInput
                            title={t("moneylender.creditLine.flagWarnings")}
                            value={data.warnings === 1 ? t("moneylender.warningDropdown.value1") :
                                data.warnings === 2 ? t("moneylender.warningDropdown.value2") : ""}
                        />
                    </div>
                </ContainerRow>
                <div className="customDropdown__titleContainer customDropdown__titleContainer--marginTop20">
                    <div className="customDropdown__title">
                        {t("moneylender.creditLine.discountCalculation")}
                    </div>
                </div>
                <div className="createCreditLine__descriptionInput">
                    <ReactMarkdown
                        source={t("moneylender.creditLine.discountCalculationDescription")}
                        escapeHtml={false}
                    />
                </div>
                <ContainerRow>
                    <div className="inputContainer">
                        <TextInput
                            disableInput
                            value={data.discount === 1 ? t("moneylender.creditLine.mode1") : data.discount === 2 ? t("moneylender.creditLine.mode2") : ""}
                        />
                    </div>
                    <div className="inputContainer"/>
                    <div className="inputContainer"/>
                </ContainerRow>
                {data.expiryDate &&
                <>
                    <div className="customDropdown__titleContainer customDropdown__titleContainer--marginTop20">
                        <div className="customDropdown__title">
                            {t("moneylender.creditLine.deadline")}
                        </div>
                    </div>
                    <div className="createCreditLine__descriptionInput">
                        {t("moneylender.creditLine.deadlineDesc")}
                    </div>
                    <ContainerRow>
                        <div className="inputContainer">
                            <TextInput
                                disableInput
                                value={timeUtils.formatToLocaleDate(data.expiryDate)}
                            />
                        </div>
                        <div className="inputContainer"/>
                        <div className="inputContainer"/>
                    </ContainerRow>
                </>
                }
                <div className="customDropdown__titleContainer customDropdown__titleContainer--marginTop20">
                    <div className="customDropdown__title">
                        {t("moneylender.creditLine.payables")}
                    </div>
                </div>
                <div className="createCreditLine__descriptionInput">
                    {t("moneylender.creditLine.payablesDesc")}
                </div>
                <ContainerRow>
                    <div className="inputContainer">
                        <TextInput
                            disableInput
                            symbol={t("moneylender.creditLine.days")}
                            value={data?.durationPayables?.toString()}
                        />
                    </div>
                    <div className="inputContainer">
                        <div className="customDropdown__title">
                            {t("moneylender.payablesDropdown.title")}
                        </div>
                        <TextInput
                            disableInput
                            value={data?.durationPayablesType === DurationPayablesType.MAX_RESIDUAL_DURATION ? t("moneylender.payablesDropdown.value1") : t("moneylender.payablesDropdown.value2")}
                        />
                    </div>
                    <div className="inputContainer"/>
                </ContainerRow>
                <div className="customDropdown__titleContainer customDropdown__titleContainer--marginTop20">
                    <div className="customDropdown__title">
                        {t("moneylender.creditLine.operations")}
                    </div>
                </div>
                <div className="createCreditLine__descriptionInput">
                    <ReactMarkdown source={t("moneylender.creditLine.operationDesc")} escapeHtml={false}/>
                </div>
                <ContainerRow>
                    <div className="inputContainer">
                        <TextInput
                            disableInput
                            value={
                                data.secondaryMarket === 1 ? t("moneylender.operativityDropdown.value1") :
                                    data.secondaryMarket === 2 ? t("moneylender.operativityDropdown.value2") :
                                        data.secondaryMarket === 3 ? t("moneylender.operativityDropdown.value3") : ""}
                        />
                    </div>
                    <div className="inputContainer"/>
                    <div className="inputContainer"/>
                </ContainerRow>
                <div className="customDropdown__titleContainer customDropdown__titleContainer--marginTop20">
                    <div className="customDropdown__title">
                        {t("moneylender.creditLine.amountForMoneylender") + "*"}
                    </div>
                </div>
                <div className="createCreditLine__descriptionInput">
                    {t("moneylender.creditLine.amountForMoneylenderDesc")}
                </div>
                <ContainerRow>
                    <div className="inputContainer">
                        <TextInput
                            disableInput
                            symbol={data.currency}
                            value={numberUtils.reverseAndFormatToLocaleNumber(data.sublimit)}
                        />
                    </div>
                    <div className="inputContainer"/>
                    <div className="inputContainer"/>
                </ContainerRow>
                <div className="customDropdown__titleContainer customDropdown__titleContainer--marginTop20">
                    <div className="customDropdown__title">
                        {t("moneylender.creditLine.geographic") + "*"}
                    </div>
                </div>
                <div className="createCreditLine__descriptionInput">
                    {t("moneylender.creditLine.geographicDesc")}
                </div>
                <ContainerRow>
                    <div className="inputContainer">
                        <TextInput
                            disableInput
                            value={(typeof data.visibility === "boolean" && (data.visibility ? t("common.button.yes") : t("common.button.no"))) || ""}
                        />
                    </div>
                    <div className="inputContainer"/>
                    <div className="inputContainer"/>
                </ContainerRow>
                {data.visibility === true &&
                <ContainerRow style={{flexDirection: "column"}}>
                    <ScrollableTable
                        items={countries}
                        description={t("dynamicDiscount.newDynamicDiscount.moneylendersTableDescription")}
                        emptyMessage={t("SCFProgram.table.noCountryAvailable")}
                        tableLabels={[t("dynamicDiscount.moneylendersTable.country").toUpperCase()]}
                    />
                </ContainerRow>
                }
            </CardContainerWithButtons>
        </div>
    );
};

const mapStateToProps = (store: AppState) => {
    return {creditLineDetails: store.moneylender.creditLineDetailsResponse};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {setConfigurationPricesStep: (step: string) => dispatch(setConfigurationPricesStep(step))};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditLineDetails);
