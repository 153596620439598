import * as React from 'react';
import {useTranslation} from "react-i18next";
import Button, {BUTTON_TYPE} from "../Button/Button";
import "./Filter.scss";

export interface Props {
    title: string
    filterList: string[]
    updateSelectedFilters(selectedFilters: string[]): void
}

const Filter: React.FC<Props> = ({title, filterList, updateSelectedFilters}) => {

    const [selectedFilterList, setSelectedFiltersList] = React.useState<string[]>();
    const {t} = useTranslation();

    React.useEffect(() => {
        setSelectedFiltersList([]);
        updateSelectedFilters([]);
        // eslint-disable-next-line
    }, [filterList]);

    const onToggleFilter = (item: string | null) => {
        const newSelectedFilterList: any[] = [];
        if (!item) {
            setSelectedFiltersList([]);
            updateSelectedFilters([...newSelectedFilterList]);
        } else {
            const index = newSelectedFilterList.indexOf(item);
            if (index > -1) {
                newSelectedFilterList.splice(index, 1);
            } else {
                newSelectedFilterList.push(item);
            }
            setSelectedFiltersList([...newSelectedFilterList]);
            updateSelectedFilters([...newSelectedFilterList]);
        }
    };

    return (
        <div className="filter__container">
            <div className="filter__title">
                {title}
            </div>
            <div className="filter__buttonsContainer">
                <div key="noFilter" className="filter__filterButton">
                    <Button
                        buttonType={selectedFilterList?.length === 0 ? BUTTON_TYPE.buttonFilterClicked : BUTTON_TYPE.buttonFilter}
                        width="100%"
                        label={t("common.filter.noFilter")}
                        onClick={() => onToggleFilter(null)}
                    />
                </div>
                {filterList
                    ?.sort((a: string, b: string) => a > b ? 1 : -1)
                    ?.map((item: string) => {
                        return (
                            <div key={item} className="filter__filterButton">
                                <Button
                                    buttonType={selectedFilterList && (selectedFilterList.indexOf(item) > -1) ? BUTTON_TYPE.buttonFilterClicked : BUTTON_TYPE.buttonFilter}
                                    width="100%"
                                    label={<div className="filter__label" title={item}>{item}</div>}
                                    onClick={() => onToggleFilter(item)}
                                />
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default Filter;
