import * as React from 'react';
import {useEffect, useState} from 'react';
import {AppState} from "../../../redux/store";
import {connect} from "react-redux";
import ChooseTypeOfUser from "../../../screens/admin/ChooseTypeOfUser/ChooseTypeOfUser";
import {CompanyInfoSteps} from "../../../model/AdminDTO";
import NewUserSetCompany from "../../../screens/admin/NewUserSetCompany/NewUserSetCompany";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {useTranslation} from "react-i18next";
import {setCompanyInfoStep} from "../../../redux/admin/admin-actions";
import CompanyInfo from "../../../screens/admin/CompanyInfo/CompanyInfo";
import {getCompanyInfo} from "../../../redux/company/company-actions";
import UserInfoList from "../../../screens/admin/UserInfoList/UserInfoList";
import {OtherFilesDTORequest, Roles, UpdateOtherDataRequest, UserFilesDTORequest} from "../../../model/UserDTO";
import {getUserData} from "../../../redux/common/common-actions";
import {SET_COMPANY_ID} from "../../../redux/company/company-reducers";
import ManageUserDocument from "../ManageUserDocument/ManageUserDocument";
import {setOnboardingData, uploadOtherUserData, uploadOtherUserFiles} from "../../../redux/auth/auth-actions";
import {setUserFilesData} from "../../../redux/user";
import ChooseUserDocumentsType from '../../SupplierComponent/chooseUserDocumentsType/ChooseUserDocumentsType';
import EsgCertificateList from '../../SupplierComponent/esgCertificatesManagement/EsgCertificateList';
import AddEsgCertificate from '../../SupplierComponent/esgCertificatesManagement/AddEsgCertificate';

const DocumentsManagementDispatcher: React.FC<any> = ({
                                                          companyInfo,
                                                          companyInfoStep,
                                                          setCompanyInfoStep,
                                                          getCompanyData,
                                                          companyId,
                                                          resetStep,
                                                          userRole,
                                                          getUserData,
                                                          userEmail,
                                                          roleList,
                                                          userData,
                                                          uploadOtherUserFiles,
                                                          updateUserFiles,
                                                          updateFileError
                                                      }) => {
    const {t} = useTranslation();
    const [userId, setUserId] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (resetStep) setCompanyInfoStep(null);
    }, [resetStep, setCompanyInfoStep]);

    useEffect(() => {
        if (updateFileError) setError(updateFileError);
    }, [updateFileError]);

    const userList = <UserInfoList
        title={t("admin.companyInfo.titleDocsUser")}
        description={t("admin.companyInfo.titleDocsUserDesc")}
        onSelect={async (id: string) => {
            await getUserData(id);
            setUserId(id);
            setCompanyInfoStep(CompanyInfoSteps.USER_INFO);
        }}
        companyId={companyId}
        onPrevious={() => {
            if(userRole === Roles.VENDOR){
                setCompanyInfoStep(null);
            }else{                
                setCompanyInfoStep(CompanyInfoSteps.COMPANY_LIST);
            }
            
        }}
    />;

    const isAdminUser = userRole === Roles.ADMIN
        || roleList.indexOf(Roles.MONEYLENDER_ADMIN) > 0
        || roleList.indexOf(Roles.BUYER_ADMIN) > 0
        || roleList.indexOf(Roles.VENDOR_ADMIN) > 0;

    const userSummary = <ManageUserDocument
        title={t("admin.userInfo.title")}
        userId={userId}
        onPrevious={() => {
            setUserId(null);
            setCompanyInfoStep(CompanyInfoSteps.USERS);
        }}
        onConfirm={async ({userId, userEmail, userFiles}: any, documentData: any) => {
            let successUploadFiles;
            if (isAdminUser && userEmail !== userId) {
                successUploadFiles = await uploadOtherUserFiles({userId: userId || userEmail, userFiles, ...documentData});
            } else {
                successUploadFiles = await updateUserFiles({...userFiles, ...documentData});
            }
            getUserData(userId || userEmail);
            return successUploadFiles;
        }}
        data={userData}
        setError={setError}
        error={error}
    />;

    switch (companyInfoStep) {
        case CompanyInfoSteps.COMPANY_LIST:
            return <NewUserSetCompany title={t("admin.companyInfo.titleDocsCompany")}
                                      description={t("admin.companyInfo.descriptionCompany")}
                                      onConfirm={async (vat: string) => {
                                          vat && await getCompanyData(vat);
                                          setCompanyInfoStep(CompanyInfoSteps.INFO);
                                      }}
                                      onShowUsers={async (vat: string) => {
                                          await getCompanyData(vat);
                                          setCompanyInfoStep(CompanyInfoSteps.USERS);
                                      }}
                                      onPrevious={() => setCompanyInfoStep(null)}
            />;
        case CompanyInfoSteps.INFO:
            const {companyData, documents} = companyInfo || {};
            return <CompanyInfo
                title={t("admin.companyInfo.titleDocs")}
                userData={{companyData, documents}}
                onPrevious={() => setCompanyInfoStep(CompanyInfoSteps.COMPANY_LIST)}
                companyId={companyId}
            />;
        case CompanyInfoSteps.USERS:
            return userList;
        case CompanyInfoSteps.USER_INFO:
            return userSummary;
        case CompanyInfoSteps.ESG_CERTIFICATE_LIST:
            return <EsgCertificateList 
                onPrevious={() => setCompanyInfoStep(null)} 
                onConfirm={() => setCompanyInfoStep(CompanyInfoSteps.ADD_ESG_CERTIFICATE)} />;
        case CompanyInfoSteps.ADD_ESG_CERTIFICATE:
            return <AddEsgCertificate onPrevious={() => setCompanyInfoStep(CompanyInfoSteps.ESG_CERTIFICATE_LIST)}/>;
        default:
            if (userRole === Roles.ADMIN) {
                return <ChooseTypeOfUser title={t("admin.companyInfo.titleDoscRole")}
                                         description={t("admin.companyInfo.description")}
                                         onConfirm={() => setCompanyInfoStep(CompanyInfoSteps.COMPANY_LIST)}
                                         showAllOptions
                />;
            }else if(userRole === Roles.VENDOR){
                return <ChooseUserDocumentsType />
            } 
            else if (roleList.indexOf(Roles.MONEYLENDER_ADMIN) > -1
                || roleList.indexOf(Roles.BUYER_ADMIN) > -1
                || roleList.indexOf(Roles.VENDOR_ADMIN) > -1) {
                return userList;
            } else {
                getUserData(userEmail);
                return userSummary;
            }
    }

};

const mapStateToProps = (store: AppState) => ({
    companyInfoStep: store.admin.companyInfoStep,
    companyInfo: store.company.getCompanyInfoResponse,
    updateFileError: store.auth.updateOtherUserFileError || store.user.error,
    companyId: store.company.companyId,
    userRole: store.auth.role,
    userEmail: store.auth.onBoardingData.userId,
    roleList: store.auth.roles,
    userData: store.common.getUserDataSuccess,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        setCompanyInfoStep: (payload: CompanyInfoSteps) => dispatch(setCompanyInfoStep(payload)),
        getCompanyData: (vat: string) => dispatch(getCompanyInfo(vat)),
        getUserData: (id: string) => dispatch(getUserData(id)),
        setCompanyId: (companyId: string) => dispatch({type: SET_COMPANY_ID, payload: companyId}),
        uploadOtherUserData: (payload: UpdateOtherDataRequest) => dispatch(uploadOtherUserData(payload)),
        setOnboardingData: (payload: {}) => dispatch(setOnboardingData(payload)),
        uploadOtherUserFiles: (payload: OtherFilesDTORequest) => dispatch(uploadOtherUserFiles(payload)),
        updateUserFiles: (payload: UserFilesDTORequest) => dispatch(setUserFilesData(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsManagementDispatcher);
