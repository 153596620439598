import {documentTypeList} from "../screens/onBoarding/onBoardingDocumentData/OnBoardingDocumentData";
import {ProgramDTO} from "../model/ProgramDTO";
import i18n from "i18next";
import {ActionTypes} from "../model/InfoCertDTO";
import {DocumentTypes, Roles} from "../model/UserDTO";
import {infoCertService} from "../service/InfoCertService";
import {numberUtils} from "./numberUtils";
import {DocumentZippedDTO} from "../model/MoneylenderDTO";
import {MyResponse} from "../model/ServiceDTO";
import {timeUtils} from "./timeUtils";
import {FileToDownloadDTO} from "../model/AdminDTO";
import {companyService} from "../service/CompanyService";

export const getActionTypeList = (role: Roles, isAdminArchive = false) => {
    switch (role) {
        case (Roles.BUYER):
            const arr = [
                {key: null, label: i18n.t("common.dropdown.all")},
                {key: ActionTypes.PROGRAM, label: i18n.t("common.actions.types." + ActionTypes.PROGRAM)},
                {key: ActionTypes.DYNAMIC_DISCOUNT_LINE, label: i18n.t("common.actions.types." + ActionTypes.DYNAMIC_DISCOUNT_LINE)},
                {key: ActionTypes.DYNAMIC_DISCOUNT_CONFIRM, label: i18n.t("common.actions.types." + ActionTypes.DYNAMIC_DISCOUNT_CONFIRM)},
                {key: ActionTypes.DELEGATION, label: i18n.t("common.actions.types." + ActionTypes.DELEGATION)},
                {key: ActionTypes.BUYER_TARIFF, label: i18n.t("common.actions.types." + ActionTypes.BUYER_TARIFF)},
                {key: ActionTypes.BUYER_REPORT, label: i18n.t("common.actions.types." + ActionTypes.BUYER_REPORT)},
                {key: ActionTypes.CREDIT_LINE, label: i18n.t("common.actions.types." + ActionTypes.CREDIT_LINE)},
                {key: ActionTypes.CREDIT_LINE_PRICING, label: i18n.t("common.actions.types." + ActionTypes.CREDIT_LINE_PRICING)}
            ];
            if (isAdminArchive) arr.splice(1, 0, {key: ActionTypes.ONBOARDING, label: i18n.t("common.actions.types." + ActionTypes.ONBOARDING)});
            return arr;
        case (Roles.MONEYLENDER):
            const moneylenderArr = [
                {key: null, label: i18n.t("common.dropdown.all")},
                {key: ActionTypes.CREDIT_LINE, label: i18n.t("common.actions.types." + ActionTypes.CREDIT_LINE)},
                {key: ActionTypes.CREDIT_LINE_PRICING, label: i18n.t("common.actions.types." + ActionTypes.CREDIT_LINE_PRICING)},
                {key: ActionTypes.CREDIT_CESSION, label: i18n.t("common.actions.types." + ActionTypes.CREDIT_CESSION)},
                {key: ActionTypes.BUYER_INCOME, label: i18n.t("common.actions.types." + ActionTypes.BUYER_INCOME)},
                {key: ActionTypes.DELEGATION, label: i18n.t("common.actions.types." + ActionTypes.DELEGATION)},
                {key: ActionTypes.MONEYLENDER_TARIFF, label: i18n.t("common.actions.types." + ActionTypes.MONEYLENDER_TARIFF)},
                {key: ActionTypes.PAYMENT_RECEIPT, label: i18n.t("common.actions.types." + ActionTypes.PAYMENT_RECEIPT)},

            ];
            if (isAdminArchive) moneylenderArr.splice(1, 0, {key: ActionTypes.ONBOARDING, label: i18n.t("common.actions.types." + ActionTypes.ONBOARDING)});
            return moneylenderArr;
        case (Roles.VENDOR):
            const vendorArr = [
                {key: null, label: i18n.t("common.dropdown.all")},
                {key: ActionTypes.CREDIT_CESSION, label: i18n.t("common.actions.types." + ActionTypes.CREDIT_CESSION)},
                {key: ActionTypes.DYNAMIC_DISCOUNT_CONFIRM, label: i18n.t("common.actions.types." + ActionTypes.DYNAMIC_DISCOUNT_CONFIRM)},
                {key: ActionTypes.DELEGATION, label: i18n.t("common.actions.types." + ActionTypes.DELEGATION)},
                {key: ActionTypes.PAYMENT_RECEIPT, label: i18n.t("common.actions.types." + ActionTypes.PAYMENT_RECEIPT)},
                {key: ActionTypes.ESG_CERTIFICATE, label: i18n.t("common.actions.types." + ActionTypes.ESG_CERTIFICATE)}
            ];
            if (isAdminArchive) vendorArr.splice(1, 0, {key: ActionTypes.ONBOARDING, label: i18n.t("common.actions.types." + ActionTypes.ONBOARDING)});
            return vendorArr;
        default:
            return [];        
    }
    
};

export const dwonloadEsgCertificate = async (id: any) => {
    let downloadFunction = companyService.dwonloadEsgCertificate;
    const {payload: data} = await downloadFunction(id);
    const link = document.createElement('a');
    link.download = data?.certificateName ?? 'certificate.pdf';
    link.href = data?.base64 || "";
    link.click();
    link.remove();
};

export const download = async (id: string, type: string, actionType: string = "") => {
    let downloadFunction = infoCertService.actionRequestDocument;
    if ([DocumentTypes.STATUTE,
        DocumentTypes.COMMERCE_CERTIFICATE,
        DocumentTypes.DELEGATION_POWERS,
        DocumentTypes.ATTORNEY_POWERS].indexOf(actionType as DocumentTypes) > -1) {
        downloadFunction = infoCertService.actionRequestLegalDocument;
    }
    const {payload: href} = await downloadFunction(id);
    let fileName = type + "_" + id;
    const link = document.createElement('a');
    link.download = fileName + '.pdf';
    link.href = href || "";
    link.click();
    link.remove();
};


export const downloadFile = async (downloadHandler: () => Promise<MyResponse<any>>, defaultFileName: string = "archive.zip") => {
    const {payload} = await downloadHandler();
    const link = document.createElement('a');
    const extension = payload?.fileName && /[^.]*$/.exec(payload?.fileName)?.[0];
    const time = timeUtils.formatToLocaleDate(Date.now(), "YY-MM-DD_HH-mm");
    link.download = (payload?.fileName?.split('.')?.[0] + " " + time + '.' + extension) || defaultFileName;
    link.href = payload?.base64 || "";
    link.click();
    link.remove();
};

export const downloadZipFile = async (downloadHandler: () => Promise<MyResponse<DocumentZippedDTO>>) => {
    return await downloadFile(downloadHandler);
};

export const downloadDocument = async (file: FileToDownloadDTO) => {
    const handler = async () => await companyService.getDocument(file);
    await downloadFile(handler)
};

export const isEmpty = (obj: object) => {
    for (let key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
};

export const extractFromKeyToLabel = (key: string): string => {
    const result = documentTypeList().find((item) => item.key === key);
    return result?.label || "";
};

export const getNested = (obj: any, ...args: any) => typeof obj === "object" ? args.reduce((obj: any, level: string) => obj && obj[level] as object, obj) : obj;

export const isAllPropsDefined = (obj: any) =>
    Object.keys(obj).reduce((acc, currentKey) => acc && obj[currentKey] !== undefined, true);

export const onProgramCheckboxClick = (programSelected: ProgramDTO, programs: ProgramDTO[]) => {
    const newItem = {...programSelected, selected: true} as any;
    let programList = [...programs];
    programList.forEach((program: ProgramDTO, index: number) => {
        if (program.programName === newItem.programName) {
            programList[index].selected ? programList[index].selected = false : programList[index].selected = true;
        }
    });
    return programList;
};

export const isAtLeastOneProgramSelected = (programs: any[]): boolean =>
    programs && programs.some((item: any) => item.selected === true);


export const removeSpecificItemFromArray = (list: any[], value: any) => {
    const newList = [...list];
    return newList.splice(list.findIndex(item => item === value) - 1, 1);
};

export const getFormattedRate = (variableBaseRate: string, fixedBaseRate: string) => {
    if (isNumber(fixedBaseRate)) {
        return numberUtils.formatToPercent(fixedBaseRate, true, "-");
    } else {
        return variableBaseRate ? i18n.t(`rates.${variableBaseRate}`) : "-";
    }
};

export const isNumber = (value: string): boolean => {
    const floatValue = parseFloat(value);
    return !isNaN(floatValue);
};

export const onlyUniqueElements = (value: string, index: number, self: string[]) => (self.indexOf(value as string) === index);

export const hasValues = (data: any) => data && Object.entries(data).find(element => !!element[1]);

export const removeAllSpaces = (str: string): string => str.replace(/\s/g, '');
