import { BuyerNewCompanySteps } from "../../model/AdminDTO";
import {
  BuyerActiveInvoiceDTO,
  BuyerInvoiceDTO,
  DynamicDiscountAssociatedDTO,
  BuyerEditableInvoiceDTO,
  BuyerCessionOperationsDTO,
  BuyerMoneylenderInfoDTO,
  EditMoneylenderInfoDTO,
  BuyerMoneyLenderCreditLineDTO,
} from "../../model/BuyerDTO";
export const GET_CURRENCIES = "getInvoicesCurrencies";
export const GET_CURRENCIES_REQUEST = "getInvoicesCurrenciesRequest";
export const GET_CURRENCIES_FAIL = "getInvoicesCurrenciesFail";
export const GET_BUYERS_INVOICES = "getInvoicesBuyer";
export const GET_BUYERS_INVOICES_DELAYED = "getInvoicesDelayedBuyer";
export const GET_BUYERS_INVOICES_DELAYED_REQUEST =
  "getInvoicesDelayedBuyerRequest";
export const GET_BUYERS_AMOUNT_INVOICES = "getAmountInvoicesBuyer";
export const GET_BUYERS_AMOUNT_INVOICES_REQUEST =
  "getAmountInvoicesBuyerRequest";
export const GET_BUYERS_INVOICES_REQUEST = "getInvoicesBuyerRequest";
export const GET_BUYERS_INVOICES_FAIL = "getInvoicesBuyerFail";
export const GET_DYNAMIC_DISCOUNT_ASSOCIATED = "getDynamicDiscountAssociated";
export const GET_DYNAMIC_DISCOUNT_ASSOCIATED_SUCCESS =
  "getDynamicDiscountAssociatedSuccess";
export const GET_DYNAMIC_DISCOUNT_ASSOCIATED_FAIL =
  "getDynamicDiscountAssociatedFail";
export const GET_BUYER_ACTIVE_INVOICES = "buyerActiveInvoices";
export const GET_BUYER_ACTIVE_INVOICES_SUCCESS =
  "getBuyerActiveInvoicesSuccess";
export const GET_BUYER_ACTIVE_INVOICES_FAIL = "getBuyerActiveInvoicesFailed";
export const GET_BUYER_ACTIVE_INVOICES_SILENT =
  "getBuyerActiveInvoicesActionSilent";
export const GET_BUYER_ACTIVE_INVOICES_SUCCESS_SILENT =
  "getBuyerActiveInvoicesSuccessActionSilent";
export const GET_BUYER_ACTIVE_INVOICES_FAIL_SILENT =
  "getBuyerActiveInvoicesFailActionSilent";
export const BUYER_NEW_COMPANY_STEP = "buyerNewCompanyStep";
export const EDITABLE_INVOICES_REQUEST = "editableInvoicesRequest";
export const EDITABLE_INVOICES_SUCCESS = "editableInvoicesSuccess";
export const EDITABLE_INVOICES_FAIL = "editableInvoicesFail";
export const CESSION_OPERATIONS_REQUEST = "cessionOperationsRequest";
export const CESSION_OPERATIONS_SUCCESS = "cessionOperationsSuccess";
export const CESSION_OPERATIONS_FAIL = "cessionOperationsFail";
export const RETRIEVE_MONEYLENDERS_REQUEST = "retrieveMoneylendersRequest";
export const RETRIEVE_MONEYLENDERS_SUCCESS = "retrieveMoneylendersSuccess";
export const RETRIEVE_MONEYLENDERS_FAIL = "retrieveMoneylendersFail";
export const RETRIEVE_MONEYLENDER_INFO_REQUEST =
  "retrieveMoneylenderInfoRequest";
export const RETRIEVE_MONEYLENDER_INFO_SUCCESS =
  "retrieveMoneylenderInfoSuccess";
export const RETRIEVE_MONEYLENDER_INFO_FAIL = "retrieveMoneylenderInfoFail";
export const RETRIEVE_MONEYLENDER_CREDIT_LINE_REQUEST =
  "retrieveMoneylenderCreditLineRequest";
export const RETRIEVE_MONEYLENDER_CREDIT_LINE_SUCCESS =
  "retrieveMoneylenderCreditLineSuccess";
export const RETRIEVE_MONEYLENDER_CREDIT_LINE_FAIL = "retrieveMoneylenderCreditLineFail";
export const EDIT_MONEYLENDER_INFO_REQUEST = "editMoneylenderInfoRequest";
export const EDIT_MONEYLENDER_INFO_SUCCESS = "editMoneylenderInfoSuccess";
export const EDIT_MONEYLENDER_INFO_FAIL = "editMoneylenderInfoFail";

export interface BuyerState {
  invoicesCurrencies: string[];
  invoicesCurrenciesIsFetching: boolean;
  invoicesCurrenciesFail: any;
  invoices: BuyerInvoiceDTO[] | null;
  invoicesSelected: BuyerInvoiceDTO[] | null;
  invoicesDelayed: BuyerInvoiceDTO[] | null;
  computedInvoicesCurrencies: string[];
  invoicesIsFetching: boolean | null;
  invoicesFail: any;
  isFetchingDynamicDiscountAssociated: any;
  getDynamicDiscountAssociatedSuccess: DynamicDiscountAssociatedDTO | null;
  getDynamicDiscountAssociatedFail: any;
  activeInvoice: BuyerActiveInvoiceDTO[] | null;
  isFetchingActiveInvoices: boolean;
  isFetchingActiveInvoicesSilent: boolean;
  computedCurrencyFilters: string[];
  buyerNewCompanyStep: BuyerNewCompanySteps | null;
  isFetchingEditableInvoices: boolean | null;
  editableInvoices: BuyerEditableInvoiceDTO[] | null;
  editableInvoicesFail: any;
  isFetchingCessionOperations: boolean | null;
  cessionOperations: BuyerCessionOperationsDTO[] | null;
  cessionOperationsFail: any;
  isFetchingRetrieveMoneylenders: boolean | null;
  retrieveMoneylenders: BuyerMoneylenderInfoDTO[];
  retrieveMoneylendersFail: any;
  isFetchingRetrieveMoneylenderInfo: boolean | null;
  retrieveMoneylenderInfo: EditMoneylenderInfoDTO | null;
  retrieveMoneylenderInfoFail: any;
  isFetchingRetrieveMoneylenderCreditLine: boolean | null;
  retrieveMoneylenderCreditLine: BuyerMoneyLenderCreditLineDTO[] | null;
  retrieveMoneylenderCreditLineFail: any;
  isFetchingEditMoneylenderInfo: boolean;
  editMoneylenderInfoResponse: any;
  editMoneylenderInfoFail: any;
}

export interface getInvoiceCurrencies {
  type:
    | typeof GET_CURRENCIES
    | typeof GET_CURRENCIES_REQUEST
    | typeof GET_CURRENCIES_FAIL
    | typeof GET_BUYERS_INVOICES_DELAYED_REQUEST
    | typeof GET_BUYERS_AMOUNT_INVOICES_REQUEST;
  payload: string[] | boolean | any;
}

export interface getInvoices {
  type:
    | typeof GET_BUYERS_INVOICES
    | typeof GET_BUYERS_INVOICES_REQUEST
    | typeof GET_BUYERS_INVOICES_FAIL
    | typeof GET_BUYERS_AMOUNT_INVOICES
    | typeof GET_BUYERS_INVOICES_DELAYED;
  payload: BuyerInvoiceDTO[] | boolean | any;
}

export interface getDynamicDiscountAssociated {
  type: typeof GET_DYNAMIC_DISCOUNT_ASSOCIATED;
  payload: boolean | null;
}

export interface getDynamicDiscountAssociatedSuccess {
  type: typeof GET_DYNAMIC_DISCOUNT_ASSOCIATED_SUCCESS;
  payload: DynamicDiscountAssociatedDTO | null;
}

export interface getDynamicDiscountAssociatedFail {
  type: typeof GET_DYNAMIC_DISCOUNT_ASSOCIATED_FAIL;
  payload: any;
}

export interface getBuyerActiveInvoicesAction {
  type: typeof GET_BUYER_ACTIVE_INVOICES;
  payload: null;
}

export interface getBuyerActiveInvoicesSuccessAction {
  type: typeof GET_BUYER_ACTIVE_INVOICES_SUCCESS;
  payload: any;
}

export interface getBuyerActiveInvoicesFailAction {
  type: typeof GET_BUYER_ACTIVE_INVOICES_FAIL;
  payload: string;
}

export interface getBuyerActiveInvoicesActionSilent {
  type: typeof GET_BUYER_ACTIVE_INVOICES_SILENT;
  payload: null;
}

export interface getBuyerActiveInvoicesSuccessActionSilent {
  type: typeof GET_BUYER_ACTIVE_INVOICES_SUCCESS_SILENT;
  payload: any;
}

export interface getBuyerActiveInvoicesFailActionSilent {
  type: typeof GET_BUYER_ACTIVE_INVOICES_FAIL_SILENT;
  payload: string;
}

export interface buyerNewCompanyStep {
  type: typeof BUYER_NEW_COMPANY_STEP;
  payload: BuyerNewCompanySteps | null;
}

export interface buyerEditableInvoicesRequest {
  type: typeof EDITABLE_INVOICES_REQUEST;
  payload?: any;
}

export interface buyerEditableInvoicesSuccess {
  type: typeof EDITABLE_INVOICES_SUCCESS;
  payload: BuyerEditableInvoiceDTO[];
}

export interface buyerEditableInvoicesFail {
  type: typeof EDITABLE_INVOICES_FAIL;
  payload: any;
}

export interface buyerCessionOperationsRequest {
  type: typeof CESSION_OPERATIONS_REQUEST;
  payload?: any;
}

export interface buyerCessionOperationsSuccess {
  type: typeof CESSION_OPERATIONS_SUCCESS;
  payload: BuyerCessionOperationsDTO[];
}

export interface buyerCessionOperationsFail {
  type: typeof CESSION_OPERATIONS_FAIL;
  payload: any;
}

export interface buyerRetrieveMoneylendersRequest {
  type: typeof RETRIEVE_MONEYLENDERS_REQUEST;
  payload: boolean;
}

export interface buyerRetrieveMoneylendersSuccess {
  type: typeof RETRIEVE_MONEYLENDERS_SUCCESS;
  payload: BuyerMoneylenderInfoDTO[] | null;
}

export interface buyerRetrieveMoneylendersFail {
  type: typeof RETRIEVE_MONEYLENDERS_FAIL;
  payload: any;
}

export interface buyerRetrieveMoneylenderInfoRequest {
  type: typeof RETRIEVE_MONEYLENDER_INFO_REQUEST;
  payload?: any;
}

export interface buyerRetrieveMoneylenderInfoSuccess {
  type: typeof RETRIEVE_MONEYLENDER_INFO_SUCCESS;
  payload: EditMoneylenderInfoDTO;
}

export interface buyerRetrieveMoneylenderInfoFail {
  type: typeof RETRIEVE_MONEYLENDER_INFO_FAIL;
  payload: any;
}

export interface buyerRetrieveMoneylenderCreditLineRequest {
  type: typeof RETRIEVE_MONEYLENDER_CREDIT_LINE_REQUEST;
  payload?: any;
}

export interface buyerRetrieveMoneylenderCreditLineSuccess {
  type: typeof RETRIEVE_MONEYLENDER_CREDIT_LINE_SUCCESS;
  payload: BuyerMoneyLenderCreditLineDTO[];
}

export interface buyerRetrieveMoneylenderCreditLineFail {
  type: typeof RETRIEVE_MONEYLENDER_CREDIT_LINE_FAIL;
  payload: any;
}

export interface editMoneylenderInfoRequest {
  type: typeof EDIT_MONEYLENDER_INFO_REQUEST;
  payload: any;
}

export interface editMoneylenderInfoSuccess {
  type: typeof EDIT_MONEYLENDER_INFO_SUCCESS;
  payload: any;
}

export interface editMoneylenderInfoFail {
  type: typeof EDIT_MONEYLENDER_INFO_FAIL;
  payload: any;
}

export type BuyerAction =
  | getInvoiceCurrencies
  | getInvoices
  | getDynamicDiscountAssociated
  | getDynamicDiscountAssociatedSuccess
  | getDynamicDiscountAssociatedFail
  | getBuyerActiveInvoicesAction
  | getBuyerActiveInvoicesSuccessAction
  | getBuyerActiveInvoicesFailAction
  | getBuyerActiveInvoicesActionSilent
  | getBuyerActiveInvoicesSuccessActionSilent
  | getBuyerActiveInvoicesFailActionSilent
  | buyerNewCompanyStep
  | buyerEditableInvoicesRequest
  | buyerEditableInvoicesSuccess
  | buyerEditableInvoicesFail
  | buyerCessionOperationsFail
  | buyerCessionOperationsSuccess
  | buyerCessionOperationsRequest
  | buyerRetrieveMoneylendersRequest
  | buyerRetrieveMoneylendersSuccess
  | buyerRetrieveMoneylendersFail
  | buyerRetrieveMoneylenderInfoRequest
  | buyerRetrieveMoneylenderInfoSuccess
  | buyerRetrieveMoneylenderInfoFail
  | buyerRetrieveMoneylenderCreditLineRequest
  | buyerRetrieveMoneylenderCreditLineSuccess
  | buyerRetrieveMoneylenderCreditLineFail
  | editMoneylenderInfoRequest
  | editMoneylenderInfoSuccess
  | editMoneylenderInfoFail;
