import * as React from 'react';
import "./ProgressNavBar.scss";
import {useTranslation} from "react-i18next";
import {tick} from "../../../utils/dynamicSVG";
import {Roles} from "../../../model/UserDTO";

interface Props {
    items: any
    activeItem: any
    translationPrefix: string
}

const ProgressNavBar: React.FC<Props> = ({items, activeItem, translationPrefix}) => {

    const {t} = useTranslation();

    const renderNavBar = () => {
        const navBar = [];
        for (let enumMember in items) {
            const isValueProperty = parseInt(enumMember, 10) >= 0;
            if (isValueProperty) {
                const styleButton = t(translationPrefix + items[enumMember]) === t(translationPrefix + items[activeItem]) ? "customProgressNavBar__menuItem--active" : "";
                navBar.push(activeItem > enumMember ?
                    <div className="customProgressNavBar__labelBox" key={enumMember}>
                        {tick(Roles.NONE, "16px", "16px")}
                        <div
                            key={enumMember}
                            className={`customProgressNavBar__menuItem customProgressNavBar__menuItem--completed`}
                        >
                            {t(translationPrefix + items[enumMember])}
                        </div>
                    </div>
                    :
                    <div
                        key={enumMember}
                        className={`customProgressNavBar__menuItem " ${styleButton}`}
                    >
                        {t(translationPrefix + items[enumMember])}
                    </div>);
            }
        }
        return navBar;
    };

    return (
        <div className="customProgressNavBar__navBar">
            {renderNavBar()}
        </div>
    );
};

export default ProgressNavBar;
